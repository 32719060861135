import React, { useState, useEffect, useContext } from 'react'
import { Form as AntForm } from 'antd'

import Wrapper from '../Wrapper/Wrapper'
import { useInput, axios } from '../../../utils'
import Form from './Form'
import { columns } from './columns'
import { addData, deleteData, editData } from '../../../services/api/requests'
import { getProdCatKindRawMatLabel } from '@berry/common-functions/cross-project-functions'
import { SyncDepsContext } from '../../../contexts'

const vocUrl = '/voc/editable/raw-mat-shelf-life'

const RawMatShelfLife = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, setState] = useState([])
	const [prodCat, setProdCat] = useState([])
	const [vocMeasures, setVocMeasures] = useState([])
	useEffect(() => {
		const getData = async () => {
			const { data } = await axios(vocUrl)
			const prodCats = await axios('/product-catalogs/all')
			const vocMeas = await axios('/voc/common/measure')
			setProdCat(prodCats.data.result)
			syncDepsCtx.setDepsInfo({ deps: data.syncDeps })
			setVocMeasures(vocMeas.data.result)
			setState(data.mainData)
		}
		getData()
	}, [syncDepsCtx.state.reloadUuids['office-ms']])
	const [form] = AntForm.useForm()
	const [recordId, setRecordId] = useState(null)
	const [productVal, setProductVal] = useState()
	const [shelfLife, resetShelfLife] = useInput('')
	const [measureVal, setMeasureVal] = useState()

	const prodCatKindRawMat = {
		onChange: (e) => {
			let product = { id: e?.target?.value ? e.target?.value?.id : e }
			if (e.target?.value?.id)
				product = {
					...product,
					productVal: `${e.target.value.prodCat?.label} ${getProdCatKindRawMatLabel(
						e.target?.value
					)}`,
				}
			setProductVal(product)
		},
		value: productVal,
	}

	const vocMeasure = {
		onChange: (e) => {
			let measureVal
			if (e.target?.value?.id) measureVal = e.target.value.labelShort
			setMeasureVal(e?.target?.value ? measureVal : e)
		},
		value: measureVal,
	}

	const resetFields = () => {
		resetShelfLife()
		setProductVal()
		setMeasureVal()
	}

	const addItem = async (successFn = (fn) => fn) => {
		const data = {
			prodCatKindRawMat: prodCat
				.map((p) => p.kindRawMats)
				.flat()
				.find((el) => el.id === prodCatKindRawMat.value.id),
			shelfLife: shelfLife.value,
			vocMeasure: vocMeasures.find((el) => el.id === vocMeasure.value),
		}
		const response =
			(await addData(vocUrl, {
				data: data,
			})) || {}
		if (response?.data.result.id) {
			setState((prev) => [...prev, response.data.result])
			successFn()
		}
	}

	const editItem = async (successFn = (fn) => fn) => {
		const response = await editData(vocUrl, {
			recordId,
			data: {
				id: recordId,
				prodCatKindRawMat: prodCat
					.map((p) => p.kindRawMats)
					.flat()
					.find((el) => el.id === prodCatKindRawMat.value.id),
				shelfLife: shelfLife.value,
				vocMeasure: vocMeasures.find((el) => el.id === vocMeasure.value),
			},
		})
		if (response.data.result) {
			setState((prev) => {
				const foundIndex = prev.findIndex((el) => el.id === response.data.result.id)
				const newEl = { ...state[foundIndex], ...response.data.result }
				return [...prev.slice(0, foundIndex), newEl, ...prev.slice(foundIndex + 1)]
			})
			successFn()
		}
		successFn()
	}

	const deleteItem = async (id) => {
		const response = await deleteData(vocUrl, {
			id,
		})
		if (response.data?.result?.data?.id)
			setState((prev) => {
				const foundIndex = prev.findIndex((el) => el.id === id)
				if (!foundIndex) new Error('foundIndex undefined')
				return [...prev.slice(0, foundIndex), ...prev.slice(foundIndex + 1)]
			})
		setRecordId(null)
	}

	return (
		<Wrapper
			rights={{
				Справочники: {
					'Срок годности сырья': {
						edit: true,
					},
				},
			}}
			name="rawMatShelfLife"
			breadcrumbsTitle="Срок годности сырья"
			model="voc_editable_raw_mat_shelf_life"
			canCreate
			canUpdate
			canDelete
			data={state}
			recordId={recordId}
			setRecordId={setRecordId}
			addData={addItem}
			editData={editItem}
			deleteData={deleteItem}
			columns={columns(state)}
			resetFields={resetFields}
			fields={[{ shelfLife }, { prodCatKindRawMat }, { vocMeasure }]}
			validateFields={[{ shelfLife }, { prodCatKindRawMat }, { vocMeasure }]}
			form={
				<Form
					form={form}
					shelfLife={shelfLife}
					prodCatKindRawMat={prodCatKindRawMat}
					vocMeasure={vocMeasure}
					prodCat={prodCat}
					vocMeasures={vocMeasures}
					state={state}
				/>
			}
			vocUrl={vocUrl}
		/>
	)
}

export default RawMatShelfLife
