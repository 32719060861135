import React, { useState, useContext } from 'react'

import Wrapper from '../Wrapper/Wrapper'
import { useInput } from '../../../utils'
import { DataServerContext } from '../../../contexts'

import Form from './Form'
import { columns } from './columns'
import { addData, deleteData, editData } from '../../../services/api/requests'

const vocUrl = '/voc/editable/warehouse-type'

const WarehouseType = () => {
	const { state } = useContext(DataServerContext)

	const [, setData] = useState(state[vocUrl].data)
	const [recordId, setRecordId] = useState(null)

	const [label, resetLabel] = useInput('')

	const resetFields = () => {
		resetLabel()
	}

	const addItem = async (successFn = (fn) => fn) => {
		const { status, data } = await addData(vocUrl, {
			data: {
				label: label.value,
			},
		})

		if (status === 200) {
			setData((prev) => [
				...prev,
				{
					id: data.result.id,
					label: label.value,
				},
			])

			successFn()
		}
	}

	const editItem = async (successFn) => {
		const { status } = await editData(vocUrl, {
			recordId,
			data: {
				id: recordId,
				label: label.value,
			},
		})

		if (status === 200) {
			setData((prev) =>
				prev.map((element) =>
					element.id === recordId ? { ...element, label: label.value } : element
				)
			)

			setRecordId(null)
			successFn()
		}
	}

	const deleteItem = async (id) => {
		const { status } = await deleteData(vocUrl, {
			id,
		})

		if (status === 200) {
			setData((prev) => prev.filter((element) => element.id !== id))
			setRecordId(null)
		}
	}

	return (
		<Wrapper
			rights={{
				Справочники: {
					'Типы складов': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Типы складов"
			model="voc_editable_warehouse_type"
			canCreate
			canUpdate
			canDelete
			data={state[vocUrl].data}
			recordId={recordId}
			setRecordId={setRecordId}
			addData={addItem}
			editData={editItem}
			deleteData={deleteItem}
			columns={columns}
			resetFields={resetFields}
			fields={[{ label }]}
			validateFields={[{ label }]}
			uniqueFields={[{ label }]}
			form={<Form label={label} />}
			vocUrl={vocUrl}
		/>
	)
}

export default WarehouseType
