import React, { useContext } from 'react'
import { ModContext, ModProvider, AuthContext, UserDataContext } from '../../../contexts'
import { Main } from '../../../layouts'
import { Card, Form, Row, Col } from 'antd'
import {
	ActionButtons,
	CustomForm,
	TabList,
	showConfirmModal,
	HeaderMenu,
} from '../../../components'
import { actBtnOnClose, actBtnOnSaveV2, modes } from '../../../utils'
import { getFormItems, getMinorTabs } from './contract-customer-item'
import { useHistory } from 'react-router'
import { ContractItemMainContext, Provider } from '../provider/item'
import { isContractActive } from '../utils'
import { getBreadcrumbsText } from '../../../utils/helpers/generators'

export const pageUrl = '/rp/contracts-customers'

const ContractCustomerItem = (props) => {
	return (
		<ModProvider>
			<Provider params={{ id: props.match.params.id, contractType: 'customer' }}>
				<Main title="Договоры. Заказчики">
					<InfoComponent></InfoComponent>
				</Main>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(ContractItemMainContext)
	let history = useHistory()
	const authCtx = useContext(AuthContext)
	const [form] = Form.useForm()
	const breadCrumb = getBreadcrumbsText('/contract', mainCtx.state, { customer: true })
	const userDataCtx = useContext(UserDataContext)

	return (
		<>
			<HeaderMenu breadcrumbsTitle={breadCrumb.head} recordLabel={breadCrumb.label}>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						rights={{
							'АРМ офиса': {
								'Договоры. Заказчики': {
									edit: true,
								},
							},
						}}
						formName={'contractCustomer'}
						onClose={() => {
							actBtnOnClose({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							await actBtnOnSaveV2({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								isShowErrorModal: true,
								showConfirmModal,
							})
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						onCancel={() => {
							form.resetFields()
							mainCtx.reset()
						}}
					/>
				</Row>
			</HeaderMenu>

			<Card style={{ marginBottom: '10px' }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							form={form}
							name="contractCustomer"
							items={getFormItems({ mainCtx, authCtx, modCtx, userDataCtx })}
							fields={[
								{ name: 'num', value: mainCtx.state.num },
								{ name: 'dateStart', value: mainCtx.state.dateStart },
								{ name: 'customerLabel', value: mainCtx.state.customer },
								{ name: 'ramochniy', value: mainCtx.state.frame },
								{ name: 'isProtDisagr', value: mainCtx.state.isProtDisagr },
								{ name: 'dateEnd', value: mainCtx.state.dateEnd },
								{
									name: 'status',
									value: isContractActive(mainCtx.state) ? 'Действующий' : 'Закрытый',
								},
								{ name: 'platform', value: mainCtx.state.platform },
								{ name: 'vocDeliverCondit', value: mainCtx.state.vocDeliverCondit },
								{ name: 'vocPayCondit', value: mainCtx.state.vocPayCondit },
							]}
						/>
					</Col>
				</Row>
			</Card>
			<TabList tabs={getMinorTabs(mainCtx)} />
		</>
	)
}
export default ContractCustomerItem
