import { EditDelBtnsLoading } from '../..'
import { openNewTab } from '../../../utils'
import { getDepsList } from '../../../utils/helpers/for-deps'

export const tableColumns = (
	handleEditRow,
	handleDeleteRow,
	hoverRecord = {},
	authCtx,
	vocUrl,
	model,
	isDevAdmin
) => {
	const { id = null, canUpdate = false, canDelete = false } = hoverRecord || {}
	return [
		{
			key: 'edit',
			width: '6%',
			render: (record) => (
				<EditDelBtnsLoading
					record={record}
					canDelete={canDelete}
					canUpdate={canUpdate}
					onDelete={handleDeleteRow}
					onEdit={handleEditRow}
					withMouseDetect={true}
					selectedId={id}
					getDeps={isDevAdmin ? () => [] : () => getDepsList({ id: record.id, url: vocUrl, model })}
					openNewPage={(url) => {
						return openNewTab(url, {
							authCtx: authCtx.state,
						})
					}}
				/>
			),
		},
	]
}
