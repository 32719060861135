import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Button, Card, Col, DatePicker, Row, Space, Typography } from 'antd'
import { Provider, ProductionTaskListMainContext } from './provider/main'
import { Main } from '../../../layouts'
import { goToItem } from '../../../utils'
import { CommonSelect, HeaderMenu, StatefulTable } from '../../../components'
import { getColumns } from './production-task-list'
import moment from 'moment'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { ListContext } from '../../../contexts'
import { ResetFilter } from '../../../assets'

export const pageUrl = '/production/tasks'

const ProductionSupply = () => (
	<Provider>
		<Main title="Задачи на производство">
			<ProductionTaskTable />
		</Main>
	</Provider>
)

const ProductionTaskTable = () => {
	const mainCtx = useContext(ProductionTaskListMainContext)
	const {
		state: { dateFrom, dateTo, posType, taskKind },
		setFilters,
		setSorter,
		setDateFrom,
		setDateTo,
		setTextFilter,
		setPosType,
		setTaskKind,
		setMainFilter,
	} = useContext(ListContext)
	const history = useHistory()
	const displayData = mainCtx.toDisplayDataList()

	return (
		<>
			<HeaderMenu
				rights={{
					'АРМ офиса': {
						'Задачи на производство': {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle="Задачи на производство"
				search={mainCtx.setSearch}
				canCreate
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<Card>
					<Row>
						<Col>
							<Space>
								<Typography.Text strong>Период с</Typography.Text>
								<DatePicker
									style={{ width: '120px' }}
									placeholder="ДД.ММ.ГГГГ"
									showToday={false}
									format="DD.MM.YYYY"
									allowClear={false}
									suffixIcon={null}
									value={dateFrom ? moment(dateFrom) : null}
									onChange={setDateFrom}
								/>
							</Space>
							<Space>
								<Typography.Text strong style={{ paddingLeft: '8px' }}>
									по
								</Typography.Text>
								<DatePicker
									style={{ width: '120px' }}
									placeholder="ДД.ММ.ГГГГ"
									showToday={false}
									format="DD.MM.YYYY"
									allowClear={false}
									suffixIcon={null}
									value={dateTo ? moment(dateTo) : null}
									onChange={setDateTo}
								/>
							</Space>
						</Col>
						<Col offset={1}>
							<Space>
								<Typography.Text strong>Вид работ</Typography.Text>
								<CommonSelect
									style={{ width: 240 }}
									value={taskKind}
									setValue={setTaskKind}
									dataArr={mainCtx.selectors.allTaskKinds}
								/>
							</Space>
						</Col>
						<Col offset={1}>
							<Space>
								<Typography.Text strong>Ответственный за согласование</Typography.Text>
								<CommonSelect
									style={{ width: 240 }}
									value={posType}
									setValue={setPosType}
									dataArr={mainCtx.selectors.allPosTypes}
								/>
							</Space>
						</Col>
						<Col>
							<Button
								title="Очистить период, все фильтры и сортировки"
								type="text"
								icon={<ResetFilter className={'pictogram'} style={{ fontSize: 24 }} />}
								onClick={() => {
									setDateFrom(null)
									setDateTo(null)
									setTextFilter(null)
									setFilters({})
									setSorter({})
									setTaskKind(null)
									setPosType(null)
									setMainFilter({})
								}}
							/>
						</Col>
						<Col
							style={{
								alignSelf: 'center',
								marginLeft: 'auto',
							}}
						>
							<Typography.Text strong>
								Общая масса, кг: {numToFixed(mainCtx.fromServerTotalSum, 1)}
							</Typography.Text>
						</Col>
					</Row>
				</Card>
				<StatefulTable
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					dataSource={displayData}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					url={pageUrl}
				/>
			</Card>
		</>
	)
}

export default ProductionSupply
