import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

/**
 * вызов функции показывает модалку с текстом и 2 кнопками
 * @param {Object} params
 * @param {string} params.title - заготовок
 * @param {string} okText
 * @param {string} cancelText
 * @param {Component} params.content
 */
const asyncShowConfirmModal = async (params) => {
	const {
		title,
		onOk = () => {},
		onCancel = () => {},
		okText = 'Выйти',
		cancelText = 'Отмена',
		showCancel = true,
		content,
		isCancelBtnPrimary = false,
		width,
		...modalProps
	} = params
	const cancelButtonProps = {}
	if (isCancelBtnPrimary) {
		cancelButtonProps.className = 'ant-btn-primary'
	}

	if (!showCancel) {
		cancelButtonProps.style = { display: 'none' }
	}
	return new Promise((resolve, reject) => {
		Modal.confirm({
			...modalProps,
			width,
			title: title,
			centered: true,
			icon: <ExclamationCircleOutlined />,
			okText: okText,
			cancelText: cancelText,
			content: content || null,
			onOk: () => {
				resolve(true)
				onOk()
			},
			onCancel: () => {
				resolve(false)
				onCancel()
			},
			cancelButtonProps,
			autoFocusButton: null,
		})
	})
}

export default asyncShowConfirmModal
