import { useCallback, useContext, useEffect, useLayoutEffect, useRef } from 'react'

function useEvent(handler) {
	const handlerRef = useRef(null)

	useLayoutEffect(() => {
		handlerRef.current = handler
	})

	return useCallback((...args) => {
		return handlerRef.current(...args)
	}, [])
}

const useCloseOrReloadTab = (callback, sensitiveParams, optional = {}) => {
	const handler = useEvent(callback)
	useEffect(async () => {
		window.removeEventListener('beforeunload', handler, false)
		window.addEventListener('beforeunload', handler, false)

		return () => {
			window.removeEventListener('beforeunload', handler, false)
		}
	}, sensitiveParams)
}

export default useCloseOrReloadTab
