import { Button, Space } from 'antd'
import React, { useContext } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg'
import { ModContext, UserDataContext } from '../../contexts'
import { modes } from '../../utils'
import LoadingButton from '../LoadingButton/LoadingButton'
const { checkRole } = require('@berry/common-functions/role')

const ActionButtons = (props) => {
	const {
		formName,
		isPendingReq = false,
		onSave = () => {},
		onEdit = () => {},
		onCancel = () => {},
		onDelete = () => {},
		onClose = () => {},
		onCopy,
		canEdit = true,
		canDelete,
		canDeleteOnly,
		canCancel = true,
		rights = {},
		additionalButtonsPre = [],
		additionalButtonsPost = [],
	} = props
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)

	const modCtx = useContext(ModContext)
	return (
		<Space size="middle">
			{additionalButtonsPre}
			{checkRole(rights, currentRights) && [modes.view].includes(modCtx.mod) && onCopy && (
				<Button type={'primary'} onClick={onCopy} disabled={isPendingReq}>
					Создать копию
				</Button>
			)}
			{[modes.edit, modes.new].includes(modCtx.mod) && (
				<LoadingButton
					onClick={onSave}
					htmlType="submit"
					form={formName}
					isPendingReq={isPendingReq}
				>
					Сохранить
				</LoadingButton>
			)}
			{checkRole(rights, currentRights) && modCtx.mod === modes.view && canEdit && (
				<Button type={'primary'} onClick={onEdit} disabled={isPendingReq}>
					Редактировать
				</Button>
			)}
			{checkRole(rights, currentRights) &&
				[modes.view].includes(modCtx.mod) &&
				((canEdit && canDelete) || canDeleteOnly) && (
					<LoadingButton onClick={onDelete} isPendingReq={isPendingReq}>
						Удалить
					</LoadingButton>
				)}
			{modCtx.mod === modes.edit && canCancel && (
				<Button type={'primary'} onClick={onCancel} disabled={isPendingReq}>
					Отмена
				</Button>
			)}

			{[modes.view, modes.new].includes(modCtx.mod) && (
				<Button
					type="text"
					style={{ lineHeight: 0.8 }}
					icon={<CloseIcon />}
					onClick={onClose}
					disabled={isPendingReq}
				/>
			)}
			{additionalButtonsPost}
			{props.children || null}
		</Space>
	)
}

export default ActionButtons
