import { Status } from '../../../components'
import { getColumnSearchProps, sorter } from '../../../utils'
import { sliceStringAndAddThreeDots } from '@berry/front-common'

export const getColumns = (props) => {
	const { fromServerFilters } = props

	return [
		{
			title: 'Фамилия',
			dataIndex: 'surname',
			key: 'surname',
			sorter: sorter('string', 'surname'),
			width: '25%',
			render: (_, record) => {
				return sliceStringAndAddThreeDots(record.surname, 39)
			},
		},
		{
			title: 'Имя',
			dataIndex: 'name',
			key: 'name',
			width: '15%',
			render: (_, record) => {
				return sliceStringAndAddThreeDots(record.name, 25)
			},
		},
		{
			title: 'Отчество',
			dataIndex: 'patronymic',
			key: 'patronymic',
			width: '18%',
			render: (_, record) => {
				return sliceStringAndAddThreeDots(record.patronymic, 27)
			},
		},
		{
			title: 'Должность',
			dataIndex: 'posType',
			...getColumnSearchProps(fromServerFilters.posType),
			key: 'posType',
			width: '12%',
		},
		{
			title: 'Статус',
			dataIndex: 'statusLabel',
			key: 'statusLabel',
			width: '20%',
			...getColumnSearchProps(fromServerFilters.statusLabel),
			render: (status) => <Status status={status} />,
		},
		{
			title: 'Роль',
			dataIndex: 'role',
			key: 'role',
			width: '10%',
			...getColumnSearchProps(fromServerFilters.role),
		},
	]
}
