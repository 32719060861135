import { Table } from 'antd'
import React, { useContext } from 'react'
import { ConsolidatedReportItemMainContext } from '../provider/main'
import { getDisplayColumns } from './supply-tab'
import { TabItem } from '../../../../components'

const SupplyTab = () => {
	const mainCtx = useContext(ConsolidatedReportItemMainContext)
	const dataSource = mainCtx.state.supplies
	return (
		<TabItem
			addButton={{ disabled: true }}
			table={() => {
				return (
					<Table
						size="small"
						rowKey="_uuid_"
						columns={getDisplayColumns(mainCtx)}
						dataSource={dataSource}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)
			}}
		/>
	)
}

export default SupplyTab
