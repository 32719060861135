import React, { useContext, useEffect, useRef } from 'react'
import { ListContext, SyncDepsContext } from '../../../../../contexts'
import moment from 'moment'
import lodash from 'lodash'
import { getCommonProviderModalFunctions } from '../../../../../utils/helpers/generators'
import {
	getDataFromSupplyProdRmProvProd,
	getStockCalculatedParams,
	numToFixed,
} from '@berry/common-functions/cross-project-functions'
import {
	getProdCatPackageLabel,
	calcProdTaskIsTolling,
	prepareParamsForStockRelocationModal,
	getTaskWithStockRawMatCompl,
} from '../../../../../utils/helpers/cross-pages-funcs'
import {
	everyOtherTimeUseEffectHandlerForList,
	startUseEffectHandlerForList,
} from '../../../../../utils/helpers/for-provider'
import { axios } from '../../../../../utils'
import { getStatus } from '../../../RawMat/RawMatList/provider/main'

const dataUrl = '/stock/ready-prod-resales'

export const reducer = (state) => {
	return {
		...state,
	}
}

const StockReadyProdResaleListMainContext = React.createContext()
StockReadyProdResaleListMainContext.displayName = 'StockReadyProdResaleListMainContext'

const Provider = (props) => {
	const {
		state: { page, pageSize, mainFilter, filters, sorter, isInStock, weightFrom, weightTo },
		setTotal,
	} = useContext(ListContext)
	const syncDepsCtx = useContext(SyncDepsContext)

	const { children } = props
	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: {
			idContrOrder: [],
			partyNum: [],
			fabricatedNumVal: [],
			articul: [],
			articul1C: [],
			prodCat: [],
			prodCatKind: [],
			roomNum: [],
			customer: [],
			status: [],
			_1c: [],
		},
		isInitialized: false,
		isLoading: false,
		removeRegisterModal: { __isOpen: false },
		changeStatusModal: { __isOpen: false },
		relocationModal: { __isOpen: false },
		repackModal: { __isOpen: false },
		fromServerSelectors: {},
		isModalsServerEditSend: false,
		stockNotify: {},
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = newState
		dispatch({ ...state })
	}

	const resetModals = () => {
		executeDispatch({
			...stateRef.current,
			removeRegisterModal: { __isOpen: false },
			relocationModal: { __isOpen: false },
			changeStatusModal: { __isOpen: false },
			repackModal: { __isOpen: false },
		})
	}

	const modalSetOpen = (val, modalName) => {
		executeDispatch({ ...stateRef.current, [modalName]: { isOpen: val } })
	}

	const selectors = {
		removeRegisterModal: {
			customer: lodash.sortBy(stateRef.current.fromServerSelectors.customers, ['label']),
		},
		relocationModal: {
			roomNum: stateRef.current.fromServerSelectors.room,
		},
		repackModal: {
			roomNum: stateRef.current.fromServerSelectors.room?.filter((room) => {
				return room.storage.includes('Сырье') && room.type === 'Складское'
			}),
			vocContType: stateRef.current.fromServerSelectors.vocContType,
		},
		changeStatusModal: {
			blockCauses: stateRef.current.fromServerSelectors.blockCause,
		},
	}
	useEffect(() => {
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				isInStock,
				sorter,
				mainFilter,
				filters,
				offset: pageSize * page - pageSize,
				limit: pageSize,
				weightFrom,
				weightTo,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [])

	const everyOtherTimeUseEffectHandlerForListFunc = () =>
		everyOtherTimeUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				offset: pageSize * page - pageSize,
				limit: pageSize,
				mainFilter,
				sorter,
				filters,
				search: stateRef.current.search,
				isInStock,
				isSelectorsLoaded: true,
				weightFrom,
				weightTo,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	useEffect(() => {
		everyOtherTimeUseEffectHandlerForListFunc()
	}, [
		page,
		sorter,
		mainFilter,
		filters,
		stateRef.current.search,
		isInStock,
		syncDepsCtx.state.reloadUuids['office-ms'],
		stateRef.current.search,
		weightFrom,
		weightTo,
	])

	const modalsServerEdit = async () => {
		let options = { url: dataUrl, method: 'PUT' }
		const { removeRegisterModal, relocationModal, repackModal, changeStatusModal } =
			stateRef.current
		let currentModal = null
		if (removeRegisterModal.storage) currentModal = removeRegisterModal
		if (changeStatusModal.storage) currentModal = changeStatusModal
		if (relocationModal.storage) currentModal = relocationModal
		if (repackModal.storage) currentModal = repackModal
		const item = currentModal.storage.storages.find(
			(el) => el.id === currentModal.storage.idStorage
		)
		let body = {}
		if (removeRegisterModal.storage) {
			if (removeRegisterModal.weight === removeRegisterModal.storage.weight.toString()) {
				body = {
					id: removeRegisterModal.storage.id,
					storages: [
						{
							id: removeRegisterModal.storage.idStorage,
							weight: removeRegisterModal.weight,
							status: 'На оформлении в 1С',
							oldStatus: item.status,
							newStatus: removeRegisterModal.sale ? 'Продажа' : 'Возврат',
							date: moment(),
						},
					],
					to1cData: {
						counteragent: removeRegisterModal.sale
							? removeRegisterModal.customer
							: removeRegisterModal.storage._fullProvider,
						isSale: removeRegisterModal.sale,
					},
				}
			}
			if (+removeRegisterModal.weight < +removeRegisterModal.storage.weight) {
				body = {
					id: removeRegisterModal.storage.id,
					storages: [
						{
							id: removeRegisterModal.storage.idStorage,
							weight: removeRegisterModal.storage.weight - removeRegisterModal.weight,
						},
						{
							...item,
							id: null,
							idParent: removeRegisterModal.storage.idStorage,
							weight: removeRegisterModal.weight,
							date: moment(),
							newStatus: removeRegisterModal.sale ? 'Продажа' : 'Возврат',
							isPermanent: false,
						},
					],
					to1cData: {
						counteragent: removeRegisterModal.sale
							? removeRegisterModal.customer
							: removeRegisterModal.storage._fullProvider,
						isSale: removeRegisterModal.sale,
					},
				}
				delete body.storages[1].id
			}
			body.articul = getDataFromSupplyProdRmProvProd(removeRegisterModal.storage.supplProd)?.articul
			options = { dataUrl, url: '/stock/ready-prod-resales/remove-register', method: 'PUT' }
		}
		if (relocationModal.storage) {
			const preparedParams = prepareParamsForStockRelocationModal(
				relocationModal,
				{
					dataUrl,
					wasteKey: 'idStockReadyProdResale',
					status: getStatus(relocationModal, item),
				},
				stateRef.current.fromServerSelectors.vocProduct1C
			)
			options = preparedParams.options
			body = preparedParams.body
			body.articul = getDataFromSupplyProdRmProvProd(relocationModal.storage.supplProd)?.articul
			body.isNeedCreateIntegrHistRec =
				item.room.roomType === 'Внутренний' && relocationModal.roomNum.roomType === 'Внешний'
					? false
					: true
		}
		if (repackModal.storage) {
			if (Number(repackModal.weight) === Number(repackModal.storage.weight)) {
				body = {
					id: repackModal.storage.id,
					storages: [
						{
							id: repackModal.storage.idStorage,
							vocContType: repackModal.vocContType,
							nettoPkgWeight: repackModal.nettoPkgWeight,
							date: moment(),
						},
					],
				}
			}
			if (Number(repackModal.weight) < Number(repackModal.storage.weight)) {
				body = {
					id: repackModal.storage.id,
					storages: [
						{
							id: repackModal.storage.idStorage,
							weight: repackModal.storage.weight - repackModal.weight,
						},
						{
							...item,
							id: null,
							weight: repackModal.weight,
							vocContType: repackModal.vocContType,
							isStandPkg: repackModal.isStandPkg ? false : true,
							nettoPkgWeight: repackModal.nettoPkgWeight,
							date: moment(),
						},
					],
				}
			}
		}
		if (changeStatusModal.storage) {
			if (Number(changeStatusModal.weight) === Number(changeStatusModal.storage.weight)) {
				body = {
					id: changeStatusModal.storage.id,
					storages: [
						{
							id: changeStatusModal.storage.idStorage,
							status: changeStatusModal.status,
							date: moment(),
							comment: changeStatusModal.comment,
							blockCauses: changeStatusModal.blockCauses?.map((bk) => {
								return {
									blockCause: { ...bk },
								}
							}),
						},
					],
					isChangeStatus: true,
				}
			}
			if (Number(changeStatusModal.weight) < Number(changeStatusModal.storage.weight)) {
				body = {
					id: changeStatusModal.storage.id,
					storages: [
						{
							id: changeStatusModal.storage.idStorage,
							weight: changeStatusModal.storage.weight - changeStatusModal.weight,
						},
						{
							...item,
							id: null,
							weight: changeStatusModal.weight,
							comment: changeStatusModal.comment,
							status: changeStatusModal.status,
							date: moment(),
							blockCauses: changeStatusModal.blockCauses?.map((bk) => {
								return {
									blockCause: { ...bk },
								}
							}),
						},
					],
					isChangeStatus: true,
				}
			}
		}
		executeDispatch({ ...stateRef.current, isModalsServerEditSend: true })
		const resp = await axios.put(options.url, body)
		executeDispatch({ ...stateRef.current, isModalsServerEditSend: false })
		everyOtherTimeUseEffectHandlerForListFunc()
		resetModals()
		return { method: options.method, id: resp }
	}

	const toDisplayDataList = () => {
		return stateRef.current.fromServer
	}

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}

	const dropStatus = async (el) => {
		let storages = []
		if (el.parent) {
			storages = [
				{
					id: el.idStorage,
					__toDelete: true,
				},
				{
					id: el.parent.id,
					weight: +el.weight + +el.parent.weight,
				},
			]
		} else {
			storages = [
				{
					id: el.idStorage,
					status: el.oldStatus,
					oldStatus: null,
				},
			]
		}
		const resp = await axios.put(dataUrl, {
			id: el.id,
			storages,
		})
		if (resp.data.data.id) everyOtherTimeUseEffectHandlerForListFunc()
		return { method: 'PUT', id: resp.data.data.id }
	}

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		selectors,
		[
			{ field: 'sale', updateVal: 'common', modalName: 'removeRegisterModal' },
			{ field: 'refund', updateVal: 'common', modalName: 'removeRegisterModal' },
			{ field: 'customer', updateVal: 'obj', modalName: 'removeRegisterModal' },
			{ field: 'weight', updateVal: 'common', modalName: 'removeRegisterModal' },
			{ field: 'isForSaleDepartment', updateVal: 'common', modalName: 'removeRegisterModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'removeRegisterModal' },

			{ field: 'isWriteOff', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'roomNum', updateVal: 'obj', modalName: 'relocationModal' },
			{ field: 'weight', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'relocationModal' },

			{ field: 'vocContType', updateVal: 'obj', modalName: 'repackModal' },
			{ field: 'nettoPkgWeight', updateVal: 'common', modalName: 'repackModal' },
			{ field: 'weight', updateVal: 'common', modalName: 'repackModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'repackModal' },

			{ field: 'status', updateVal: 'common', modalName: 'changeStatusModal' },
			{ field: 'weight', updateVal: 'common', modalName: 'changeStatusModal' },
			{ field: 'blockCauses', updateVal: 'common', modalName: 'changeStatusModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'changeStatusModal' },
			{ field: 'comment', updateVal: 'common', modalName: 'changeStatusModal' },
		]
	)

	const value = {
		state: stateRef.current,
		toDisplayDataList: toDisplayDataList(),
		fromServerTotalSum: stateRef.current.fromServerTotalSum,
		isModalsServerEditSend: stateRef.current.isModalsServerEditSend,
		selectors,
		removeRegisterModal: lodash.cloneDeep(stateRef.current.removeRegisterModal),
		relocationModal: lodash.cloneDeep(stateRef.current.relocationModal),
		repackModal: lodash.cloneDeep(stateRef.current.repackModal),
		changeStatusModal: lodash.cloneDeep(stateRef.current.changeStatusModal),
		modalsServerEdit,
		resetModals,
		modalSetOpen,
		modalFunctions,
		dropStatus,
		setSearch,
	}

	return (
		<StockReadyProdResaleListMainContext.Provider value={value}>
			{children}
		</StockReadyProdResaleListMainContext.Provider>
	)
}

export const prepareList = (data) => {
	let preparedData = []

	data.forEach((it) => {
		it.storages.forEach((el) => {
			if (+el.weight === 0) return
			const { articul, articul1C, prodCatKind } = getStockCalculatedParams(it)
			it = {
				...it,
				nettoPkgWeight: numToFixed(el.nettoPkgWeight, 2),
				weight: numToFixed(Number(el.weight), 2),
				partyNum: it.taskRep?.partyNum,
				partyNumForSorter: it.taskRep?.partyNum.slice(5, 9),
				fabricatedNumValSorter:
					it.taskRep?.task?.fabricatedNumVal?.length === 10
						? it.taskRep?.task?.fabricatedNumVal?.slice(6, 10)
						: it.taskRep?.task?.fabricatedNumVal?.slice(5, 9),
				fabricatedNumVal: it.taskRep?.task?.fabricatedNumVal,
				articul: articul,
				articul1C: articul1C,
				prodCat: it.taskRep.task.prodCat.label,
				_idProduct: it.taskRep.task.prodCat.id,
				prodCatKind: prodCatKind,
				isPermanent: el.isPermanent,
				idStorage: el.id,
				prodType: getProdCatPackageLabel(it.taskRep.task.prodCatPkg),
				pkgType: el.vocContType?.labelShort,
				idOrder:
					it.idContrOrder ||
					getTaskWithStockRawMatCompl(it.taskRep?.task?.complectations?.[0], {})?.stockRawMatStor
						?.stockRawMat.supplProd?.order?.id ||
					null,
				isTolling: calcProdTaskIsTolling(it.taskRep?.task),
				_1C: ['Утверждена', 'Принято в 1С'].includes(it.taskRep.task.status)
					? 'Зарегистрирован в 1С'
					: 'Не зарегистрирован в 1С',
				roomNum: el.room?.displayCode,
				roomType: el.room?.roomType,
				status: el.status,
				customer: it.taskRep.task.customer?.label,
				quality: it.taskRep.prodCatPkg?.vocQuality?.label,
			}
			preparedData = [...preparedData, it]
		})
	})
	return preparedData
}
export { Provider, StockReadyProdResaleListMainContext }
