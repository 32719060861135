import { Button, Card, Col, Row } from 'antd'
import React, { useContext } from 'react'

import { ModContext } from '../../contexts'
import { modes } from '../../utils'

/**
 * Обертка для тела вкладки записи
 * @param {Object} props
 * @param {function} props.table - функция возвращающая компонент который будет отображаться в теле
 * @param {function} props.modal - функция возвращающая компонент модального окна
 * @param {{disabled: boolean,onClick: function, show: boolean }} props.delButton - кнопка Удалить
 * @param {function} props.afterButtonComponent - компонент который должекн быть справа от кнопки добавить
 * @param {{disabled: boolean,onClick: function, show: boolean }} props.addButton - компоненты которые обернуты
 */
const TabItem = (props) => {
	const {
		table,
		modal = () => null,
		delButton = { disabled: false, onClick: () => {}, show: false },
		afterButtonComponent,
		addButton = { showOnView: false, disabled: false, onClick: () => {}, show: true },
	} = props
	const modCtx = useContext(ModContext)
	return (
		<Card>
			<Row gutter={[16, 16]}>
				<Row align="middle" style={{ width: '100%', padding: '0px 8px' }} span="24">
					{modCtx.mod !== modes.view && addButton.show && <AddButton {...addButton} />}
					{modCtx.mod !== modes.view && delButton.show && (
						<Col span="2">
							<Button disabled={delButton.disabled} onClick={delButton.onClick}>
								Удалить
							</Button>
						</Col>
					)}
					{afterButtonComponent && (
						<Col span={modCtx.mod !== modes.view && addButton.show ? 22 : 24}>
							{afterButtonComponent}
						</Col>
					)}
				</Row>
				<Col span="24">{table()}</Col>
				{modal && modal()}
			</Row>
		</Card>
	)
}

const AddButton = (props) => {
	const { show, showOnView, disabled, onClick } = props
	const modCtx = useContext(ModContext)
	if (!show) return null
	if (showOnView || modCtx.mod !== modes.view)
		return (
			<Col span="2">
				<Button disabled={disabled} onClick={onClick}>
					Добавить
				</Button>
			</Col>
		)
	return null
}
export default TabItem
