import React from 'react'
import VocabulariesList from '../../components/Vocabulary/VocabulariesList'
import { Main } from '../../layouts'
import { useParams } from 'react-router-dom'

//todo Сделать импорт из индекса
import Quality from '../../components/Vocabulary/Quality/Quality'
import Delivery from '../../components/Vocabulary/Delivery/Delivery'
import Payment from '../../components/Vocabulary/Payment/Payment'
import RawManufacturer from '../../components/Vocabulary/RawManufacturer/RawManufacturer'
import PurposeManufactory from '../../components/Vocabulary/PurposeManufactory/PurposeManufactory'
import ProductionArea from '../../components/Vocabulary/ProductionArea/ProductionArea'
import RawCountry from '../../components/Vocabulary/RawCountry/RawCountry'
import PackageType from '../../components/Vocabulary/PackageType/PackageType'
import StaffStatus from '../../components/Vocabulary/StaffStatus/StaffStatus'
import FacilityType from '../../components/Vocabulary/FacilityType/FacilityType'
import TypeBreak from '../../components/Vocabulary/TypeBreak/TypeBreak'
import ProductType from '../../components/Vocabulary/ProductType/ProductType'
import SupplyStatus from '../../components/Vocabulary/SupplyStatus/SupplyStatus'
import WarehouseType from '../../components/Vocabulary/WarehouseType/WarehouseType'
import MatchingStatus from '../../components/Vocabulary/MatchingStatus/MatchingStatus'
import ContainerType from '../../components/Vocabulary/ContainerType/ContainerType'
import Product1c from '../../components/Vocabulary/Product-1c/Product-1c'
import Process from '../../components/Vocabulary/Process/Process'
import ProductGroup from '../../components/Vocabulary/ProductGroup/ProductGroup'
import AdditionalSemiType from '../../components/Vocabulary/AdditionalSemiType/AdditionalSemiType'
import RawMatState from '../../components/Vocabulary/RawMatState/RawMatState'
import ReadyProductForm from '../../components/Vocabulary/ReadyProductForm/ReadyProductForm'
import TemperatureRegime from '../../components/Vocabulary/TemperatureRegime/TemperatureRegime'
import WasteType from '../../components/Vocabulary/WasteType/WasteType'
import Measure from '../../components/Vocabulary/Measure'
import CarState from '../../components/Vocabulary/CarState/CarState'
import Documentation from '../../components/Vocabulary/Documentation/Documentation'
import Room from '../../components/Vocabulary/Room/Room'
import RawMatShelfLife from '../../components/Vocabulary/RawMatShelfLife/RawMatShelfLife'
import CauseOfRejectRep from './../../components/Vocabulary/CauseOfRejectRep/CauseOfRejectRep'
import BlockCause from '../../components/Vocabulary/BlockCause/BlockCause'
import OutdoorWarehouse from '../../components/Vocabulary/OutdoorWarehouses/OutdoorWarehouses'

const getVocabularyPage = (url) => {
	switch (url) {
		case 'quality':
			return <Quality />
		case 'delivery-condition':
			return <Delivery />
		case 'outdoor-warehouse':
			return <OutdoorWarehouse />
		case 'payment-condition':
			return <Payment />
		case 'cause-of-reject-rep':
			return <CauseOfRejectRep />
		case 'block-cause':
			return <BlockCause />
		case 'raw-material-manufacturer':
			return <RawManufacturer />
		case 'manufactory-purpose':
			return <PurposeManufactory />
		case 'production-area':
			return <ProductionArea />
		case 'raw-material-country':
			return <RawCountry />
		case 'package-type':
			return <PackageType />
		case 'staff-status':
			return <StaffStatus />
		case 'facility-type':
			return <FacilityType />
		case 'type-reason-break':
			return <TypeBreak />
		case 'product-type':
			return <ProductType />
		case 'supply-status':
			return <SupplyStatus />
		case 'warehouse-type':
			return <WarehouseType />
		case 'matching-status':
			return <MatchingStatus />
		case 'container-type':
			return <ContainerType />
		case 'product-1c':
			return <Product1c />
		case 'process':
			return <Process />
		case 'product-group':
			return <ProductGroup />
		case 'additional-semi-type':
			return <AdditionalSemiType />
		case 'raw-mat-state':
			return <RawMatState />
		case 'ready-product-form':
			return <ReadyProductForm />
		case 'temperature-regime':
			return <TemperatureRegime />
		case 'waste-type':
			return <WasteType />
		case 'measure':
			return <Measure />
		case 'car-state':
			return <CarState />
		case 'documentation':
			return <Documentation />
		case 'room':
			return <Room />
		case 'raw-mat-shelf-life':
			return <RawMatShelfLife />
		default:
			return <VocabulariesList />
	}
}

const Router = () => {
	const { url } = useParams()

	return (
		<Main title="Справочники" requiredRights={{ vocabulary: { read: true } }}>
			{getVocabularyPage(url)}
		</Main>
	)
}

export default Router
