import { isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'
import { Checkbox, Input, Select, Col, Typography } from 'antd'
import { CommonSelect, EditDelBtns } from '../../../../../components'
import { modes, openNewTab } from '../../../../../utils'
import { getDeviation, getEtalon } from '../../spec-param'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 *  @param {Object} modCtx
 *  @param {function} editHandler
 */
export const getDisplayColumns = (mainCtx, modCtx, authCtx, editHandler) => {
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.params
	return [
		{
			title: 'Параметр',
			dataIndex: 'label',
			key: 'label',
			width: '30%',
		},
		{
			title: 'ЕИ',
			dataIndex: ['vocMeasure', 'displayVal'],
			key: 'vocMeasure',
			width: '5%',
		},
		{
			title: 'Цель',
			key: 'etalon',
			render: (value, record) => {
				return getEtalon(record)
			},
			width: '15%',
		},
		{
			title: 'Допустимое отклонение',
			key: 'deviation',
			render: (value, record) => {
				return getDeviation(record)
			},
			width: '40%',
		},
		{
			title: null,
			key: 'action',
			width: '6.88%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={!isDisabled}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
						onEdit={editHandler}
						deps={mainCtx.delEditDeps?.params?.[record.id]}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					/>
				)
			},
		},
	]
}

const layout = {
	labelCol: { md: { span: 10 }, xl: { span: 6 } },
	wrapperCol: { md: { span: 10 }, xl: { span: 12 } },
}
const layoutCheckbox = {
	labelCol: { span: 14 },
	wrapperCol: { span: 10 },
}
const layoutSelect = {
	wrapperCol: { span: 18 },
}
const dashLabel = {
	labelCol: { offset: 8, span: 12 },
}
const colSpanInputRow = 3
const colSpanSelectRow = 6

/**
 * поля формы в модальном окне
 * @param {Object} mainCtx - главный контекст
 * @param {Object} modCtx - контекст мода
 * @param {{_uuid_: string}} params - отбьект с функциями изменения состояния вкладки
 */
export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []
	const { _uuid_ } = params
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.params
	const found = stateFunctions.get(_uuid_)
	const devItem1 = {
		colSpan: colSpanInputRow,
		name: 'deviationVal1',
		required: found.deviationSymbol === '-',
		rules: [
			{ required: found.deviationSymbol === '-' },
			({ getFieldValue, setFields }) => ({
				validator(_, value) {
					const symbol = getFieldValue('deviationSymbol')
					setFields([{ name: 'deviationVal2', errors: [] }])
					if (symbol === '-') {
						if (found.deviationVal2 && +found.deviationVal1 >= found.deviationVal2) {
							return Promise.reject(new Error('Значение "от" должно быть меньше значения "до"'))
						}
					}
					return Promise.resolve()
				},
			}),
		],
		field: (
			<Input
				style={{ width: '100%' }}
				value={found.deviationVal1}
				disabled={isDisabled}
				onChange={(e) => {
					if (
						e.target.value !== '-' &&
						(!isValidNum(e.target.value) || !isValidNumberDigits(e.target.value, 'float', [14, 5]))
					) {
						return stateFunctions.setDeviationVal1(_uuid_, found.deviationVal1)
					}
					stateFunctions.setDeviationVal1(_uuid_, e.target.value)
				}}
			/>
		),
	}
	const devItem2 = {
		colSpan: colSpanInputRow,
		name: 'deviationVal2',
		required: ['>', '<', '-', '='].includes(found.deviationSymbol),
		rules: [
			{ required: ['>', '<', '-', '='].includes(found.deviationSymbol) },
			({ getFieldValue, setFields }) => ({
				validator(_, value) {
					const symbol = getFieldValue('deviationSymbol')
					setFields([{ name: 'deviationVal1', errors: [] }])
					if (symbol === '-') {
						if (found.deviationVal2 && +found.deviationVal2 <= found.deviationVal1) {
							return Promise.reject(new Error('Значение "от" должно быть меньше значения "до"'))
						}
					}
					return Promise.resolve()
				},
			}),
		],
		field: (
			<Input
				style={{ width: '100%' }}
				value={found.deviationVal2}
				disabled={isDisabled}
				onChange={(e) => {
					if (
						e.target.value !== '-' &&
						(!isValidNum(e.target.value) || !isValidNumberDigits(e.target.value, 'float', [14, 5]))
					) {
						return stateFunctions.setDeviationVal2(_uuid_, found.deviationVal2)
					}
					stateFunctions.setDeviationVal2(_uuid_, e.target.value)
				}}
			/>
		),
	}
	const etalonItem1 = {
		name: 'etalonVal1',
		colSpan: colSpanInputRow,
		required: found.etalonSymbol === '-',
		rules: [
			{ required: found.etalonSymbol === '-' },
			({ getFieldValue, setFields }) => ({
				validator(_, value) {
					const symbol = getFieldValue('etalonSymbol')
					setFields([{ name: 'etalonVal2', errors: [] }])
					if (symbol === '-') {
						if (found.etalonVal2 && +found.etalonVal1 >= found.etalonVal2) {
							return Promise.reject(new Error('Значение "от" должно быть меньше значения "до"'))
						}
					}
					return Promise.resolve()
				},
			}),
		],
		field: (
			<Input
				style={{ width: '100%' }}
				value={found.etalonVal1}
				disabled={isDisabled}
				onChange={(e) => {
					if (
						e.target.value !== '-' &&
						(!isValidNum(e.target.value) || !isValidNumberDigits(e.target.value, 'float', [14, 5]))
					) {
						return stateFunctions.setEtalonVal1(_uuid_, found.etalonVal1)
					}
					stateFunctions.setEtalonVal1(_uuid_, e.target.value)
				}}
			/>
		),
	}
	const etalonItem2 = {
		label: '',
		name: 'etalonVal2',
		required: found.etalonSymbol !== 'не доп.',
		colSpan: colSpanInputRow,
		rules: [
			{ required: ['>', '<', '-', '='].includes(found.etalonSymbol) },
			({ getFieldValue, setFields }) => ({
				validator(_, value) {
					const symbol = getFieldValue('etalonSymbol')
					setFields([{ name: 'etalonVal1', errors: [] }])
					if (symbol === '-') {
						if (found.etalonVal2 && found.etalonVal1 && +found.etalonVal2 <= found.etalonVal1) {
							return Promise.reject(new Error('Значение "от" должно быть меньше значения "до"'))
						}
					}
					return Promise.resolve()
				},
			}),
		],
		field: (
			<Input
				style={{ width: '100%' }}
				value={found.etalonVal2}
				disabled={isDisabled}
				onChange={(e) => {
					if (
						e.target.value !== '-' &&
						(!isValidNum(e.target.value) || !isValidNumberDigits(e.target.value, 'float', [14, 5]))
					) {
						return stateFunctions.setEtalonVal2(_uuid_, found.etalonVal2)
					}
					stateFunctions.setEtalonVal2(_uuid_, e.target.value)
				}}
			/>
		),
	}

	const result = [
		[
			{
				layout: {
					labelCol: { span: 6 },
					wrapperCol: { span: 12 },
				},
				label: 'Наименование',
				name: 'label',
				colSpan: 5,
				required: true,
				dependencies: ['isText', 'isNumber'],
				validateStatus: mainCtx.formErrors?.['param.label'] && 'error',
				help: mainCtx.formErrors?.['param.label'] && mainCtx.formErrors['param.label'],
				rules: [
					({ getFieldValue }) => ({
						validator(_, value) {
							if (getFieldValue('isText')) {
								const whereToFind = mainCtx.state.params.filter(
									(p) => p._uuid_ !== found._uuid_ && found.isText && found.isText === p.isText
								)
								if (whereToFind.some((e) => e.label.toLowerCase() === value?.toLowerCase())) {
									return Promise.reject('Параметр не уникален')
								}
								return Promise.resolve()
							}
						},
					}),
				],
				field: (
					<Input
						maxLength={1000}
						style={{ width: '100%' }}
						value={found.label}
						disabled={isDisabled}
						onChange={(e) => {
							stateFunctions.setLabel(_uuid_, e.target.value)
						}}
					/>
				),
			},
			[
				{
					layout: layoutCheckbox,
					label: 'Текстовое',
					colSpan: 5,
					field: (
						<Checkbox
							disabled={isDisabled}
							checked={found.isText}
							onChange={(e) => stateFunctions.setIsText(_uuid_, e.target.checked)}
						></Checkbox>
					),
					name: 'isText',
				},
				{
					layout: layoutCheckbox,
					label: 'Числовое',
					colSpan: 5,
					field: (
						<Checkbox
							disabled={isDisabled}
							checked={found.isNumber}
							onChange={(e) => stateFunctions.setIsNumber(_uuid_, e.target.checked)}
						></Checkbox>
					),
					name: 'isNumber',
				},
			],
		],
	]

	if (!found.isText) {
		result[0].push(
			...[
				{
					layout: {
						labelCol: { span: 6 },
						wrapperCol: { span: 3 },
					},
					label: 'ЕИ',
					name: 'vocMeasure',
					validateStatus: mainCtx.formErrors?.['param.vocMeasure'] && 'error',
					help: mainCtx.formErrors?.['param.vocMeasure'] && mainCtx.formErrors['param.vocMeasure'],
					required: true,
					field: (
						<CommonSelect
							value={found.vocMeasure}
							setValue={(value) => stateFunctions.setVocMeasure(found._uuid_, value)}
							isDisabled={isDisabled}
							showSearch
							dataArr={mainCtx.selectors.params.vocMeasure}
						/>
					),
				},
				[
					{
						layout: {
							labelCol: { span: 23 },
							wrapperCol: { span: 1 },
						},
						label: 'Допустимое отклонение',
						name: 'deviation',
						colSpan: 6,
						tooltip:
							'Это поле можно будет заполнить или отредактировать в карточке спецификации. Вы можете ввести значения, которые будут по умолчанию добавляться вместе с этим параметром.',
						field: null,
					},
					{
						layout: layoutSelect,
						colSpan: colSpanSelectRow,
						name: 'deviationSymbol',
						rules: [
							({ getFieldValue, setFields }) => ({
								validator(_, value) {
									setFields([
										{ name: 'deviationVal1', errors: [] },
										{ name: 'deviationVal2', errors: [] },
									])
								},
							}),
						],
						validateStatus: mainCtx.formErrors?.['param.deviationSymbol'] && 'error',
						help:
							mainCtx.formErrors?.['param.deviationSymbol'] &&
							mainCtx.formErrors['param.deviationSymbol'],
						field: (
							<Select
								value={found.deviationSymbol}
								style={{ width: '100%' }}
								onChange={(el) => {
									stateFunctions.setDeviationSymbol(_uuid_, el)
								}}
								disabled={isDisabled}
								notFoundContent={<Col>Не найдено</Col>}
							>
								{mainCtx.selectors.params.deviationSymbol(_uuid_).map((it) => {
									return (
										<Select.Option key={it.value} value={it.value}>
											{it.value ? it.text : <Typography.Text italic>{it.text}</Typography.Text>}
										</Select.Option>
									)
								}) || []}
							</Select>
						),
					},
					...(found.deviationSymbol === '-'
						? [
								devItem1,
								{
									layout: dashLabel,
									label: <>&#8212;</>,
									colSpan: 2,
									name: 'deviationSymbol',
									colon: false,
								},
								devItem2,
						  ]
						: []),
					...(['>', '<', '='].includes(found.deviationSymbol) ? [devItem2] : []),
				],
				[
					{
						layout: {
							labelCol: { span: 15 },
							wrapperCol: { span: 1 },
						},
						label: 'Цель',
						colSpan: 6,
						tooltip:
							'Это поле можно будет заполнить или отредактировать в карточке спецификации. Вы можете ввести значения, которые будут по умолчанию добавляться вместе с этим параметром.',
						name: 'etalon',
						field: null,
					},
					{
						layout: layoutSelect,
						name: 'etalonSymbol',
						colSpan: colSpanSelectRow,
						rules: [
							({ getFieldValue, setFields }) => ({
								validator(_, value) {
									setFields([
										{ name: 'etalonVal1', errors: [] },
										{ name: 'etalonVal2', errors: [] },
									])
								},
							}),
						],
						field: (
							<Select
								value={found.etalonSymbol}
								style={{ width: '100%' }}
								onChange={(el) => stateFunctions.setEtalonSymbol(_uuid_, el)}
								disabled={isDisabled}
								notFoundContent={<Col>Не найдено</Col>}
							>
								{mainCtx.selectors.params.etalonSymbol(_uuid_).map((it) => {
									return (
										<Select.Option key={it.value} value={it.value}>
											{it.value ? it.text : <Typography.Text italic>{it.text}</Typography.Text>}
										</Select.Option>
									)
								}) || []}
							</Select>
						),
					},
					...(found.etalonSymbol === '-'
						? [
								etalonItem1,
								{
									layout: dashLabel,
									label: <>&#8212;</>,
									colSpan: 2,
									colon: false,
									name: 'etalonSymbol',
								},
								etalonItem2,
						  ]
						: []),
					...(['>', '<', '='].includes(found.etalonSymbol) ? [etalonItem2] : []),
				],
			]
		)
	}
	if (!found.isNumber) {
		result[0].push(
			...[
				{
					layout: layout,
					label: 'Допустимое отклонение',
					name: 'deviationText',
					tooltip:
						'Это поле можно будет заполнить или отредактировать в карточке спецификации. Вы можете указать текст, который будет по умолчанию добавляться вместе с этим параметром.',

					field: (
						<Input
							style={{ width: '100%' }}
							value={found.deviationText}
							disabled={isDisabled}
							onChange={(e) => {
								stateFunctions.setDeviationText(_uuid_, e.target.value)
							}}
						/>
					),
				},
				{
					layout: layout,
					label: 'Цель',
					name: 'etalonText',
					tooltip:
						'Это поле можно будет заполнить или отредактировать в карточке спецификации. Вы можете указать текст, который будет по умолчанию добавляться вместе с этим параметром.',
					field: (
						<Input
							maxLength={5000}
							style={{ width: '100%' }}
							value={found.etalonText}
							disabled={isDisabled}
							onChange={(e) => {
								stateFunctions.setEtalonText(_uuid_, e.target.value)
							}}
						/>
					),
				},
			]
		)
	}
	return result
}
