import jwt_decode from 'jwt-decode'
import { useContext } from 'react'
import { UserDataContext } from '../../contexts'

export const getUserFromToken = () => {
	const token = localStorage.getItem('token')
	if (!token) {
		return false
	}
	const user = jwt_decode(token)
	return user
}
const GetFullName = () => {
	const {
		state: { fullName = {} },
	} = useContext(UserDataContext)

	return Object.keys(fullName).length
		? `${fullName.surname} ${fullName.name} ${fullName.patronymic || ''}`
		: 'Пользователь'
}

export const getUserRole = () => {
	const token = localStorage.getItem('token')
	if (!token) {
		return false
	}
	const data = jwt_decode(token)
	if (data.idVocEditablePositionType?.includes(9)) {
		return 'Администратор системы'
	} else if (data.idVocEditablePositionType?.includes(3)) {
		return 'Сотрудник отдела качества'
	} else if (data.idVocEditablePositionType?.includes(2)) {
		return 'Технолог'
	} else if (data.idVocEditablePositionType?.includes(10)) {
		return 'Сотрудник отдела планирования'
	} else if (data.idVocEditablePositionType?.includes(1)) {
		return 'Руководитель производства'
	} else {
		return <GetFullName />
	}
}
