import React, { useContext } from 'react'
import { AuthContext, ModContext, ModProvider, UserDataContext } from '../../../../contexts'
import { Main } from '../../../../layouts'
import { PosTypeContext, Provider } from './provider/main'
import { Card, Row, Col, Form } from 'antd'
import {
	ActionButtons,
	CustomForm,
	HeaderMenu,
	TabList,
	showConfirmModal,
} from '../../../../components'
import { actBtnOnClose, actBtnOnDelete, actBtnOnSaveV2, modes, openNewTab } from '../../../../utils'
import { getFormItems } from './pos-type-item'
import { useHistory } from 'react-router'
import ItemTab from './ItemTab/ItemTab'
import VocTab from './VocTab/VocTab'

import { pageUrl } from '../position-type'

const PosTypeItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Типы должностей">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}
const InfoComponent = () => {
	const mainCtx = useContext(PosTypeContext)
	const modCtx = useContext(ModContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	let history = useHistory()
	if (mainCtx.state.__notFound) {
		history.replace(pageUrl)
	}
	const authCtx = useContext(AuthContext)
	const [form] = Form.useForm()
	return (
		<>
			<HeaderMenu
				breadcrumbsTitle="Типы должностей"
				recordLabel={mainCtx.state?.label || 'Новая должность'}
			>
				<Row>
					<ActionButtons
						formName="position-type"
						rights={{
							Справочники: {
								'Типы должностей': {
									edit: true,
								},
							},
						}}
						onClose={() => {
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							const { errors = [] } = await form.validateFields()
							if (errors.length) return
							const err =
								mainCtx.state.isUser && !Object.keys(mainCtx.state.params).length
									? {
											content:
												'У этой должности нет доступа ни к одному объекту системы. Снимите флаг "Пользователь" или настройте доступ.',
											width: 640,
									  }
									: null
							if (err) {
								await showConfirmModal({
									...err,
									title: null,
									okText: 'Ок',
									showCancel: false,
									centered: true,
								})
							} else {
								const res = await actBtnOnSaveV2({
									serverEdit: mainCtx.serverEdit,
									validate: mainCtx.validate,
									history,
									pageUrl,
									showConfirmModal,
									isShowErrorModal: true,
								})
								if (res) {
									const allFields = form.getFieldsError()
									allFields.forEach((f) => {
										f.errors = []
									})
									form.setFields(allFields)
								}
							}
						}}
						onEdit={() => modCtx.set(modes.edit)}
						canDelete={modCtx.mod !== modes.new}
						onDelete={async () => {
							await actBtnOnDelete({
								deleteFun: mainCtx.serverDelete,
								showConfirmModal,
								history,
								pageUrl,
								deps: mainCtx.delEditDeps?.['voc_editable_position_type'][mainCtx.state.id],
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
								currentRights,
							})
						}}
						onCancel={() => {
							const allFields = form.getFieldsError()
							allFields.forEach((f) => {
								f.errors = []
							})
							form.setFields(allFields)
							mainCtx.reset()
						}}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							name="position-type"
							form={form}
							fields={[
								{ name: 'label', value: mainCtx.state.label },
								{ name: 'isUser', value: mainCtx.state.isUser },
							]}
							columns={[
								{ size: { xs: 24, md: 12, xl: 8 }, num: 0 },
								{ size: { xs: 24, md: 12, xl: 8 }, num: 1 },
								{ size: { xs: 24, md: 12, xl: 8 }, num: 2 },
							]}
							items={getFormItems(mainCtx, modCtx)}
						/>
					</Col>
				</Row>
			</Card>
			{mainCtx.state.isUser && (
				<TabList
					tabs={[
						{ key: 0, title: 'Карточки', component: <ItemTab /> },
						{ key: 1, title: 'Справочники', component: <VocTab /> },
					]}
				/>
			)}
		</>
	)
}
export default PosTypeItem
