import { Select, Col, Row, Button, Space, Typography } from 'antd'
import { getColumnSearchProps, getRowSpan, modes, openNewTab } from '../../../utils'
import { getPlanSumDiffQuota, renderPlanFactCellV2, getRemainder } from '../utils'
import { ClearOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { CommonSelect, InputError, showConfirmModal } from '../../../components'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { showHasDepsModal } from '../../../utils/constants/for-components'
import { setNumField } from '@berry/front-common/for-fields'
import { sliceStringAndAddThreeDots } from '@berry/front-common'
import { pageUrl as providerUrl } from '../ContractsProvidersList/ContractProviderList'
import { pageUrl as customerUrl } from '../ContractsCustomersItem/ContractCustomerItem'

const styleIcons = {
	fontSize: '20px',
	color: '#000',
}
const CustomTooltip = (props) => {
	return (
		<Tooltip title={props.title}>
			<QuestionCircleOutlined style={{ paddingLeft: 5 }}></QuestionCircleOutlined>
		</Tooltip>
	)
}
/**
 * Столбцы для таблицы Распределение квоты пм Сырье/Договоры.Поставщики
 * @param {Object} mainCtx - главный контекст
 * @param {Object} modCtx - контекст мода
 * @param {Array<Object>} currentDataSource - данные которые идут на вход таблице
 */
export const getDisplayColumnsV2 = (
	mainCtx,
	modCtx,
	authCtx,
	provOrCustKey,
	currentDataSource,
	filters
) => {
	const isDisabled = modCtx.mod === modes.view
	const result = []
	if (mainCtx.state.frame) {
		result.push({
			title: '№ ДС',
			dataIndex: ['application', 'displayCode'],
			key: 'applicationNum',
			width: '8%',
			render: (text, record) => (
				<InputError
					hasError={mainCtx.formErrors?.[`${provOrCustKey}.application.${record._uuid_}`]}
					component={
						<Select
							value={text}
							onChange={(el) => {
								mainCtx.stateFunctions.setApplication(record._uuid_, el, provOrCustKey)
								if (record.rmProvProd) {
									mainCtx.stateFunctions.setRmProvProd(record._uuid_, null)
								}
							}}
							style={{ width: '100%' }}
							disabled={isDisabled}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors[provOrCustKey].application.map((it) => (
								<Select.Option key={it.id} value={it.id}>
									{it.displayCode}
								</Select.Option>
							)) || []}
						</Select>
					}
				/>
			),
			onCell: (record) => ({
				rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
			}),
			...getColumnSearchProps(
				Array.from(
					new Set(
						currentDataSource.filter((p) => p.application).map((p) => p.application.displayCode)
					)
				)
			),
			onFilter: (val, record) => val === record?.application.displayCode,
			filteredValue: filters['applicationNum'],
		})
	}
	if (provOrCustKey === 'customerQuotas') {
		result.push({
			title: 'Продукт',
			key: 'prodCat',
			width: '8%',
			ellipsis: true,
			render: (_, record) => (
				<InputError
					hasError={mainCtx.formErrors?.[`${provOrCustKey}.prodCat.${record._uuid_}`]}
					component={
						<CommonSelect
							value={record.prodCat}
							setValue={(val) => {
								mainCtx.stateFunctions[provOrCustKey].setProdCat(record._uuid_, val)
								mainCtx.stateFunctions[provOrCustKey].setProdCatPkg(record._uuid_, null)
							}}
							isDisabled={isDisabled}
							showSearch
							dataArr={mainCtx.additional.allProdCats}
						/>
					}
				/>
			),
			onCell: (record) => ({
				rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
			}),
			...getColumnSearchProps(
				Array.from(
					new Set(currentDataSource.filter((p) => p.prodCat).map((p) => p.prodCat.displayVal))
				)
			),
			onFilter: (val, record) => val === record.prodCat.displayVal,
			filteredValue: filters['prodCat'],
		})
		result.push({
			title: 'Вид',
			key: 'prodCatPkg',
			width: '20%',
			ellipsis: true,
			render: (_, record) => (
				<InputError
					hasError={mainCtx.formErrors?.[`${provOrCustKey}.prodCatPkg.${record._uuid_}`]}
					component={
						<CommonSelect
							value={record.prodCatPkg}
							setValue={(val) =>
								mainCtx.stateFunctions[provOrCustKey].setProdCatPkg(record._uuid_, val, true)
							}
							isDisabled={isDisabled}
							showSearch
							dataArr={mainCtx.additional.allProdCatPkgs.filter(
								(pkg) => record.prodCat?.id === pkg.prodCat.id
							)}
						/>
					}
				/>
			),
			onCell: (record) => ({
				rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
			}),
			...getColumnSearchProps(
				Array.from(
					new Set(currentDataSource.filter((p) => p.prodCatPkg).map((p) => p.prodCatPkg.displayVal))
				)
			),
			onFilter: (val, record) => val === record.prodCatPkg.displayVal,
			filteredValue: filters['prodCatPkg'],
		})
	}
	if (provOrCustKey === 'providerQuotas') {
		result.push({
			title: 'Продукт',
			dataIndex: ['rmProvProd', 'displayVal'],
			width: '17%',
			ellipsis: true,
			render: (_, record) => {
				const dataArr = mainCtx.additional.allProducts.filter((it) => {
					if (mainCtx.state.frame) {
						if (!record.application) {
							return false
						}
						return !mainCtx.state.providerQuotas.some((pq) => {
							if (!pq.rmProvProd?.prodCatPkg) {
								return (
									record.application.id === pq.application?.id &&
									pq.rmProvProd &&
									pq.rmProvProd.id === it.id
								)
							}
							return false
						})
					}
					return !mainCtx.state.providerQuotas.some(
						(pq) => pq.rmProvProd && pq.rmProvProd.id === it.id
					)
				})
				return (
					<InputError
						hasError={!!mainCtx.formErrors?.[`${provOrCustKey}.rmProvProd.${record._uuid_}`]}
						showCaption={record?.rmProvProd?.isArchive}
						captionStyle={{ color: 'red' }}
						message="Артикул в архиве, невозможно добавить в заявку"
						component={
							<CommonSelect
								value={record.rmProvProd}
								setValue={(val) =>
									mainCtx.stateFunctions[provOrCustKey].setRmProvProd(record._uuid_, val)
								}
								isDisabled={
									isDisabled ||
									(mainCtx.state.frame && !record.application) ||
									mainCtx.delEditDeps['providerQuotas'][record.id]?.length
								}
								showSearch
								dataArr={dataArr}
							/>
						}
					/>
				)
			},
			onCell: (record) => ({
				rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
			}),
			...getColumnSearchProps(
				Array.from(
					new Set(currentDataSource.filter((p) => p.rmProvProd).map((p) => p.rmProvProd.displayVal))
				)
			),
			onFilter: (val, record) => val === record.rmProvProd.displayVal,
			filteredValue: filters['rmProvProd.displayVal'],
		})
	}
	result.push({
		title: 'Квота, кг',
		dataIndex: 'quota',
		key: 'quota',
		width: '7%',
		render: (quota, record, index) => (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<InputError
					hasError={mainCtx.formErrors?.[`${provOrCustKey}.quota.${record._uuid_}`]}
					value={quota}
					disabled={isDisabled}
					onChange={(e) =>
						setNumField(
							e.target.value,
							quota,
							mainCtx.stateFunctions[provOrCustKey].setQuota,
							'float',
							[9, 2],
							{
								_uuid_: record._uuid_,
							}
						)
					}
				/>
				{!isDisabled && (
					<CustomTooltip title={`Нераспределенная квота = ${getPlanSumDiffQuota(record)}`} />
				)}
			</div>
		),
		onCell: (record) => ({
			rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
		}),
	})
	result.push({
		title: 'Остаток, кг',
		key: 'remainder',
		width: '6%',
		render: (_, record, index) => numToFixed(getRemainder(record), 2),
		onCell: (record, index) => ({
			rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
		}),
	})
	result.push(
		...[
			{
				title: 'Год',
				dataIndex: 'year',
				key: 'year',
				width: '4%',
				onCell: (record) => ({
					rowSpan: record.isDiplayedRowBySpan ? 2 : 0,
				}),
				...getColumnSearchProps(
					Array.from(
						new Set(
							currentDataSource
								.filter((p) => p.monthlyListings)
								.flatMap((p) => p.monthlyListings.map((l) => l.year))
						)
					)
				),
				onFilter: (val, record) => +val === record.year,
				filteredValue: filters['year'],
			},
			{
				title: null,
				key: 'monthlyStart',
				width: '3%',
				render: (_, record) => {
					if (record.isPlan) return 'П'
					if (record.isFact) return 'Ф'
				},
			},
			{
				title: 'Янв',
				width: '4%',
				dataIndex: ['monthlyListing', 'month0'],
				key: 'monthlyListingMonth0',
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '0')
				},
			},
			{
				title: 'Фев',
				width: '4%',
				dataIndex: ['monthlyListing', 'month1'],
				key: 'monthlyListingMonth1',
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '1')
				},
			},
			{
				title: 'Мар',
				width: '4%',
				key: 'monthlyListingMonth2',
				dataIndex: ['monthlyListing', 'month2'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '2')
				},
			},
			{
				title: 'Апр',
				width: '4%',
				key: 'monthlyListingMonth3',
				dataIndex: ['monthlyListing', 'month3'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '3')
				},
			},
			{
				title: 'Май',
				width: '4%',
				key: 'monthlyListingMonth4',
				dataIndex: ['monthlyListing', 'month4'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '4')
				},
			},
			{
				title: 'Июн',
				width: '4%',
				key: 'monthlyListingMonth5',
				dataIndex: ['monthlyListing', 'month5'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '5')
				},
			},
			{
				title: 'Июл',
				width: '4%',
				key: 'monthlyListingMonth6',
				dataIndex: ['monthlyListing', 'month6'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '6')
				},
			},
			{
				title: 'Авг',
				width: '4%',
				key: 'monthlyListingMonth7',
				dataIndex: ['monthlyListing', 'month7'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '7')
				},
			},
			{
				title: 'Сен',
				width: '4%',
				key: 'monthlyListingMonth8',
				dataIndex: ['monthlyListing', 'month8'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '8')
				},
			},
			{
				title: 'Окт',
				width: '4%',
				key: 'monthlyListingMonth9',
				dataIndex: ['monthlyListing', 'month9'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '9')
				},
			},
			{
				title: 'Ноя',
				width: '4%',
				key: 'monthlyListingMonth10',
				dataIndex: ['monthlyListing', 'month10'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '10')
				},
			},
			{
				title: 'Дек',
				width: '4%',
				key: 'monthlyListingMonth11',
				dataIndex: ['monthlyListing', 'month11'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, modCtx, provOrCustKey, '11')
				},
			},
			{
				title: null,
				key: 'action',
				width: '5%',
				align: 'center',
				render: (text, record, index) => (
					<Row align="middle" justify="space-between">
						<ClearOutlined
							style={{
								...styleIcons,

								visibility: isDisabled ? 'hidden' : 'visible',
							}}
							onClick={() =>
								mainCtx.stateFunctions[provOrCustKey].monthlyListings.dropPlan(record._uuid_)
							}
						/>
						<DeleteOutlined
							style={{
								...styleIcons,
								visibility: isDisabled ? 'hidden' : 'visible',
							}}
							onClick={async () => {
								const pageUrl = provOrCustKey === 'providerQuotas' ? providerUrl : customerUrl
								if (record.id) {
									const deps = mainCtx.delEditDeps[provOrCustKey][record.id]
									if (deps?.length) {
										return showConfirmModal({
											width: '40%',
											title: 'Для того, чтобы удалить данный элемент необходимо удалить все связи',
											onOk: () => {},
											...showHasDepsModal,
											showCancel: false,
											content: (
												<Space direction="vertical">
													{deps.slice(0, 10).map((e) => (
														<Button
															key={e.title}
															type="link"
															onClick={() => openNewTab(pageUrl, { authCtx })}
														>
															{sliceStringAndAddThreeDots(e.title, 50)}
														</Button>
													))}
													{deps.length > 10 && (
														<Typography.Text>...связей еще {deps.length - 10} шт</Typography.Text>
													)}
												</Space>
											),
										})
									}
								}
								mainCtx.stateFunctions[provOrCustKey].delete(record._uuid_)
							}}
						/>
					</Row>
				),
				onCell: (record, index) => ({
					rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
				}),
			},
		]
	)
	return result
}
