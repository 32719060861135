import React, { useState, useContext, useEffect } from 'react'
import { Form as AntForm } from 'antd'

import Wrapper from '../Wrapper/Wrapper'
import { useInput } from '../../../utils'
import { DataServerContext } from '../../../contexts'

import Form from './Form'
import { columns } from './columns'
import { addData, deleteData, editData } from '../../../services/api/requests'

const vocUrl = '/voc/editable/documentation'

const Documentations = () => {
	const { state, updateData } = useContext(DataServerContext)

	const [form] = AntForm.useForm()
	const isFormValid = () => form.getFieldsError().some((item) => item.errors.length > 0)

	const [data, setData] = useState(state[vocUrl].data)
	const [recordId, setRecordId] = useState(null)

	const [label, resetLabel] = useInput('')
	const [isSupplyRawVal, setIsSupplyRawVal] = useState(false)
	const [isMandatAvailVal, setIsMandatAvailVal] = useState(false)

	const [typeVal, setTypeVal] = useState('')

	const isMandatAvail = {
		onChange: (e) => {
			setIsMandatAvailVal(e.target.checked)
		},
		value: isMandatAvailVal,
	}
	const isSupplyRaw = {
		onChange: (e) => {
			setIsSupplyRawVal(e.target.checked)
		},
		value: isSupplyRawVal,
	}
	const type = {
		onChange: (e) => {
			setTypeVal(e?.target?.value ? e.target.value : e)
		},
		value: typeVal,
	}

	const resetFields = () => {
		resetLabel()
		setIsSupplyRawVal()
		setIsMandatAvailVal()
		setTypeVal()
	}

	const addItem = async (successFn = (fn) => fn) => {
		if (isFormValid()) return

		const { status, data } = await addData(vocUrl, {
			data: {
				label: label.value,
				type: type.value,
				status: 'Действующий',
				isSupplyRaw: isSupplyRaw.value,
				isMandatAvail: isMandatAvail.value,
			},
		})

		if (status === 200) {
			setData((prev) => [
				...prev,
				{
					id: data.result.id,
					label: label.value,
					isSupplyRaw: isSupplyRaw.value,
					status: 'Действующий',
					type: type.value,
					isMandatAvail: isMandatAvail.value,
				},
			])

			successFn()
		}
	}

	const editItem = async (successFn) => {
		if (isFormValid()) return

		const { status } = await editData(vocUrl, {
			recordId,
			data: {
				id: recordId,
				label: label.value,
				isSupplyRaw: isSupplyRaw.value,
				isMandatAvail: isMandatAvail.value,
				status: 'Действующий',
				type: type.value,
			},
		})

		if (status === 200) {
			setData((prev) =>
				prev.map((element) =>
					element.id === recordId
						? {
								...element,
								label: label.value,
								isSupplyRaw: isSupplyRaw.value,
								isMandatAvail: isMandatAvail.value,
								status: 'Действующий',
								type: type.value,
						  }
						: element
				)
			)

			setRecordId(null)
			successFn()
		}
	}

	const deleteItem = async (id) => {
		const { status } = await deleteData(vocUrl, {
			id,
		})

		if (status === 200) {
			setData((prev) => prev.filter((element) => element.id !== id))
			setRecordId(null)
		}
	}

	useEffect(() => {
		if (data !== state[vocUrl].data) {
			updateData(data, { url: vocUrl })
		}
	}, [data, state, updateData])

	return (
		<Wrapper
			name="documentation"
			breadcrumbsTitle="Документация"
			model="voc_documentation"
			canCreate
			canUpdate
			canDelete
			data={data}
			recordId={recordId}
			setRecordId={setRecordId}
			addData={addItem}
			editData={editItem}
			deleteData={deleteItem}
			columns={columns()}
			resetFields={resetFields}
			fields={[{ label }, { isSupplyRaw }, { isMandatAvail }, { type }]}
			validateFields={[{ label }, { type }]}
			uniqueFields={[{ label }]}
			form={
				<Form
					form={form}
					label={label}
					isMandatAvail={isMandatAvail}
					isSupplyRaw={isSupplyRaw}
					type={type}
				/>
			}
			vocUrl={vocUrl}
		/>
	)
}
export default Documentations
