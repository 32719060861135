import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

/**
 * вызов функции показывает модалку с текстом и 2 кнопками
 * @param {Object} params
 * @param {string} params.title - заготовок
 * @param {string} params.onOk - обработчик нажатия на ок
 * @param {string} params.onCancel - обработчик нажатия на отмена
 * @param {string} okText
 * @param {string} cancelText
 * @param {Component} params.content
 */
const showConfirmModal = (params) => {
	const {
		title,
		onOk = () => {},
		onCancel = () => {},
		okText = 'Выйти',
		cancelText = 'Отмена',
		showCancel = true,
		content,
		isCancelBtnPrimary = false,
		width,
		...modalProps
	} = params
	const cancelButtonProps = {}
	if (isCancelBtnPrimary) {
		cancelButtonProps.className = 'ant-btn-primary'
	}

	if (!showCancel) {
		cancelButtonProps.style = { display: 'none' }
	}
	return Modal.confirm({
		...modalProps,
		width,
		title: title,
		centered: true,
		icon: <ExclamationCircleOutlined />,
		okText: okText,
		cancelText: cancelText,
		content: content || null,
		onOk,
		onCancel,
		cancelButtonProps,
		autoFocusButton: null,
	})
}

export default showConfirmModal
