import { useContext, useState } from 'react'
import { Button, Card, Col, Modal, Row, Table, Typography } from 'antd'
import { RequestItemMainContext } from '../provider/main'
import { ReactComponent as CloseIcon } from '../../../../assets/svg/close.svg'
import {
	filterDisplayData,
	getValidDate,
	patchYearsProperty,
	prepareDataV2,
} from '../../../Contract/utils'
import { prepareDataOld } from '../../../../utils'
import { sortDataBySelected } from '../../../Contract/ContractsProvidersItem/SupplyRequestModal/utils'
import moment from 'moment'
import { getDisplayColumnsV2 } from './select-contr-quota-modal'
import { getProdNameByProviderQuota } from '@berry/common-functions/cross-project-functions'

const getContractYearsV2Local = (inContract, inQuota) => {
	let dateStart = inContract.dateStart
	let dateEnd = inContract.dateEnd
	if (inContract.frame) {
		dateStart = inQuota.application.dateStart
		dateEnd = inQuota.application.dateEnd
	}
	let momentDateEnd = getValidDate(dateEnd)
	let diff = momentDateEnd.endOf('year').diff(moment(dateStart), 'year', true)
	if (diff < 0) diff = diff * -1
	const res = Array(Math.ceil(diff))
		.fill()
		.map((_, i) => moment(dateStart).year() + i)
	return res
}
const getQuotasYearsLocal = (inContract) => {
	const result = {}
	inContract.providerQuotas.forEach((e) => {
		result[e._uuid_] = getContractYearsV2Local(inContract, e)
	})
	return result
}
const SelectContrQuotaModal = () => {
	const mainCtx = useContext(RequestItemMainContext)
	let tableData = []
	let columns = []
	const [filters, setFilters] = useState({
		applicationNum: [],
		rmProvProd: [],
		year: [],
		prodCat: [],
	})
	if (mainCtx.addContrQuota.__isOpen) {
		const foundProd = mainCtx.state.products.find(
			(e) => e._uuid_ === mainCtx.addContrQuota.uuidProd
		)
		if (!foundProd) return null
		const quotasYears = getQuotasYearsLocal(foundProd.contract)
		const dataWithYearsProp = foundProd.contract.providerQuotas.map((e) => {
			return { ...e, years: quotasYears[e._uuid_] }
		})
		const dataByYears = prepareDataOld(dataWithYearsProp, 'years', 'year')
		const toDisplayData = prepareDataV2(dataByYears)?.map((d) => {
			d.prodLabel = getProdNameByProviderQuota(d.rmProvProd)
			return d
		})

		columns = getDisplayColumnsV2(mainCtx, toDisplayData, filters)
		let filteredToDisplayData = filterDisplayData(toDisplayData, filters)
		patchYearsProperty(filteredToDisplayData, filters.year)
		filteredToDisplayData = sortDataBySelected(
			filteredToDisplayData,
			mainCtx.addContrQuota.addContrQuotaContrQuotas
		)
		tableData = filteredToDisplayData
	}

	return (
		<Modal
			visible={mainCtx.addContrQuota.__isOpen}
			destroyOnClose
			centered
			bodyStyle={{ paddingTop: '10px' }}
			footer={null}
			closable={false}
			width="90%"
		>
			<Card
				bodyStyle={{
					margin: 0,
					padding: 0,
				}}
				bordered={false}
			>
				<Row gutter={[16, 16]} style={{ padding: '0 0 10px 0' }}>
					<Col flex={1}>
						<Typography.Title level={4} style={{ margin: 0 }}>
							Заявка
						</Typography.Title>
					</Col>
					<Col>
						<Button
							type="primary"
							onClick={() => {
								try {
									mainCtx.validateContrQuota()
									for (const [
										index,
										quota,
									] of mainCtx.addContrQuota.addContrQuotaContrQuotas.entries()) {
										const foundProduct = mainCtx.state.products.find(
											(prod) => prod._uuid_ === mainCtx.addContrQuota.uuidProd
										)
										if (index === 0) {
											mainCtx.stateFunctions.products.edit(foundProduct._uuid_, {
												...foundProduct,
												contrQuota: quota,
												weight: quota.weight,
											})
										} else {
											mainCtx.stateFunctions.products.create({
												...foundProduct,
												contrQuota: quota,
												weight: quota.weight,
											})
										}
									}
									mainCtx.modalFunctions.contrQuotaModalFunctions.resetAddContrQuota()
								} catch (err) {
									console.log(err.message)
								}
							}}
						>
							Сохранить
						</Button>
					</Col>
					<Col>
						<Button
							type="text"
							style={{ lineHeight: 0.8 }}
							icon={<CloseIcon />}
							onClick={() => {
								mainCtx.modalFunctions.contrQuotaModalFunctions.resetAddContrQuota()
							}}
						/>
					</Col>
				</Row>
			</Card>
			<Table
				rowKey={(rec) => {
					return `${rec.id || rec._uuid_}_${rec.indxMain}_${
						rec.isDiplayedRowBySpan ? 'displ' : 'not_displ'
					}`
				}}
				dataSource={tableData}
				columns={columns}
				pagination={false}
				scroll={{ x: 800 }}
				size="small"
				onChange={(_, filters, __) => {
					setFilters(filters)
				}}
			/>
		</Modal>
	)
}
export default SelectContrQuotaModal
