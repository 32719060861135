import { Table } from 'antd'
import React, { useState, useContext } from 'react'
import lodash from 'lodash'
import { ProviderItemMainContext } from '../provider/main'
import { RecordItemModal, TabItem, CustomForm } from '../../../../components'
import { AuthContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns, getFormItems } from './product-tab'
import { prepareForOpenedModal } from '../../../../utils/helpers/for-modal'

const ProductTab = () => {
	const mainCtx = useContext(ProviderItemMainContext)
	const stateFunctions = mainCtx.stateFunctions.products
	const authCtx = useContext(AuthContext)
	const modCtx = useContext(ModContext)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [itemForModal, setItemForModal] = useState(null)
	const stateData = prepareForOpenedModal(mainCtx.state.products, itemForModal)
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const newUuid = stateFunctions.create()
					setItemForModal({ _uuid_: newUuid, isNew: true })
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						isOpen={isModalOpen}
						formName="product"
						title="Продукт"
						item={itemForModal}
						deleteFun={stateFunctions.delete}
						setItem={setItemForModal}
						setModalState={setIsModalOpen}
						editFun={stateFunctions.edit}
						validate={() => mainCtx.validators.products(itemForModal._uuid_)}
						form={() => {
							if (!itemForModal) return null
							return (
								<CustomForm
									onFinish={(values) => {
										for (const [key, value] of Object.entries(values)) {
											mainCtx.commonDeepFieldUpdate(
												['products'],
												[itemForModal._inUuid_],
												key,
												value
											)
										}
									}}
									name="product"
									fields={[
										{
											name: 'prodCat',
											value: stateFunctions.get(itemForModal._uuid_).prodCat?.displayVal,
										},
										{
											name: 'prodCatKind',
											value:
												stateFunctions.get(itemForModal._uuid_)?.prodCatKindRawMat?.displayVal ||
												stateFunctions.get(itemForModal._uuid_)?.prodCatPkg?.displayVal,
										},
									]}
									columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
									items={getFormItems(mainCtx, modCtx, itemForModal)}
								/>
							)
						}}
					/>
				)
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(mainCtx, modCtx, authCtx, (record) => {
							if (modCtx.mod === modes.view) return
							setItemForModal({ _uuid_: record._uuid_, prevData: lodash.cloneDeep(record) })
							setIsModalOpen(true)
						})}
						dataSource={stateData}
						pagination={false}
						scroll={{ x: 800 }}
					/>
				)
			}}
		></TabItem>
	)
}

export default ProductTab
