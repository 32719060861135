import { Form, Table } from 'antd'
import React, { useContext, useState } from 'react'
import { RecordItemModal, TabItem, CustomForm } from '../../../../components'
import { AuthContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns, getFormItems } from './kind-semif-tab'
import { ProductCatalogItemMainContext } from '../provider/main'
import { getNullSeletValue } from '../../../../utils/helpers/for-form'
import { prepareForOpenedModal } from '../../../../utils/helpers/for-modal'

export const KindSemifTab = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(ProductCatalogItemMainContext)

	const stateFunctions = mainCtx.stateFunctions.kindSemifs
	let stateData = mainCtx.state.kindSemifs

	const authCtx = useContext(AuthContext)
	let [selectedVocMainSemiKind, setSelectedVocMainSemiKind] = useState(null)
	let [selectedVocMainSemiKind2, setSelectedVocMainSemiKind2] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [itemForModal, setItemForModal] = useState(null)
	const [form] = Form.useForm()
	stateData = prepareForOpenedModal(stateData, itemForModal)
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const newUuid = stateFunctions.create()
					setItemForModal({ _uuid_: newUuid, isNew: true })
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						showErrorModal
						formName="kindSemif"
						formInstance={form}
						isOpen={isModalOpen}
						title="Вид ПФ или отхода"
						width={'70%'}
						item={itemForModal}
						deleteFun={stateFunctions.delete}
						setItem={(newEl) => {
							setItemForModal(newEl)
							setSelectedVocMainSemiKind(null)
							setSelectedVocMainSemiKind2(null)
						}}
						setModalState={setIsModalOpen}
						editFun={stateFunctions.edit}
						validate={() => mainCtx.validators.kindSemifs(itemForModal._uuid_)}
						form={() => {
							if (!itemForModal) return null
							let kpField = null
							const found = stateFunctions.get(itemForModal._uuid_)
							switch (found.vocProdType?.labelShort) {
								case 'ПФ':
									kpField = {
										name: 'kpCaliber',
									}
									break
								case 'ДП':
									kpField = {
										name: 'kpVocAdditSemiType',
										value: found.kpVocAdditSemiType?.displayVal,
									}
									break
								case 'ВО':
									break
								case 'НО':
									kpField = {
										name: 'kpVocWaseType',
									}
									break
								default:
									break
							}
							let vocMainSemiKindKind2Field = null
							let selectedVocMainSemiKind2Field = null
							if (found.isReturnWaste) {
								vocMainSemiKindKind2Field = {
									name: 'vocMainSemiKindKind2',
									value: found.vocMainSemiKindKind2?.label,
								}
								selectedVocMainSemiKind2Field = {
									name: 'selectedVocMainSemiKind2',
									value: selectedVocMainSemiKind2?.vocProcess?.label,
								}
							}
							return (
								<CustomForm
									form={form}
									name="kindSemif"
									fields={[
										{
											name: 'selectedVocMainSemiKind',
											value: selectedVocMainSemiKind?.vocProcess?.label,
										},
										{ name: 'quality', value: found.quality?.label },
										{
											name: 'vocProdType',
											value: found.vocProdType?.labelShort,
										},
										{
											name: 'isReturnWaste',
											value: found.isReturnWaste,
										},
										vocMainSemiKindKind2Field,
										{
											name: 'vocMainSemiKindKind',
											value: found.vocMainSemiKindKind?.label,
										},
										kpField,
										{
											name: 'additArrt',
											value: getNullSeletValue(found.additArrt, found.additArrt?.label),
										},
										selectedVocMainSemiKind2Field,
									].filter((e) => e)}
									columns={[
										{ size: { xs: 12, lg: 12 }, num: 0 },
										{ size: { xs: 12, lg: 12 }, num: 1 },
									]}
									items={getFormItems(mainCtx, modCtx, {
										...itemForModal,
										setSelectedVocMainSemiKind,
										setSelectedVocMainSemiKind2,
										selectedVocMainSemiKind,
										selectedVocMainSemiKind2,
									})}
								/>
							)
						}}
					/>
				)
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(mainCtx, modCtx, authCtx)}
						dataSource={stateData}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)
			}}
		/>
	)
}
