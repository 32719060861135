import React, { createContext, useEffect, useReducer } from 'react'

export const MenuContext = createContext()
MenuContext.displayName = 'MenuContext'

export const menuPositions = {
	left: 'left',
	top: 'top',
}

const reducer = (state, callback) => {
	return {
		...callback(state),
	}
}

const initialState = {
	position: menuPositions.left,
	collapsed: false,
}

const getInitialState = () => {
	const savedState = localStorage.getItem('theme')
	if (!savedState) {
		return initialState
	}
	return JSON.parse(savedState)
}

export const MenuProvider = ({ children }) => {
	const [value, dispatch] = useReducer(reducer, getInitialState())

	useEffect(() => {
		localStorage.setItem('theme', JSON.stringify(value))
	}, [value])

	value.toggleCollapsed = () => {
		dispatch((state) => ({ ...state, collapsed: !state.collapsed }))
	}

	value.togglePosition = (pos) => {
		dispatch((state) => ({ ...state, position: menuPositions[pos] }))
	}

	return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}
