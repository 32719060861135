import React, { useContext, useState } from 'react'
import { AuthContext, ModContext, ModProvider, UserDataContext } from '../../../contexts'
import { Main } from '../../../layouts'
import { ProductCatalogItemMainContext, Provider } from './provider/main'
import { Row, Form } from 'antd'
import { ActionButtons, HeaderMenu } from '../../../components'
import { actBtnOnClose, actBtnOnDelete, actBtnOnSaveV2, modes, openNewTab } from '../../../utils'
import { showConfirmModal, TabList } from '../../../components'
import { useHistory } from 'react-router'
import { getMinorTabs } from './product-catalog-item'
import { RawTab, SemifinishedTab, ReadyProductTab } from './MainTabs'

const pageUrl = '/pv/product-catalog'

const ProductCatalogItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Продуктовый каталог">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(ProductCatalogItemMainContext)
	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const history = useHistory()
	if (mainCtx.state.__notFound) {
		history.replace(pageUrl)
	}
	const [form] = Form.useForm()
	const [mainTabKey, setMainTabKey] = useState('0')
	const [minorTabKey, setMinorTabKey] = useState('0')
	return (
		<>
			<HeaderMenu
				breadcrumbsTitle="Продуктовый каталог"
				recordLabel={mainCtx.state.label || 'Новый продукт'}
			>
				<Row>
					<ActionButtons
						formName="rawTab"
						isPendingReq={mainCtx.isPendingReq}
						rights={{
							'АРМ офиса': {
								'Продуктовый каталог': {
									edit: true,
								},
							},
						}}
						onClose={() => {
							actBtnOnClose({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							await actBtnOnSaveV2({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								pageUrl: pageUrl,
								// history: history,
								isShowErrorModal: true,
								showConfirmModal,
								form,
							})
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						canDelete={modCtx.mod !== modes.new}
						onDelete={async () => {
							await actBtnOnDelete({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								deps: mainCtx.delEditDeps?.['product_catalog'][mainCtx.state.id],
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
								currentRights,
							})
						}}
						onCancel={() => {
							const allFields = form.getFieldsError()
							allFields.forEach((f) => {
								f.errors = []
							})
							form.setFields(allFields)
							mainCtx.reset()
						}}
					/>
				</Row>
			</HeaderMenu>

			<TabList
				onTabClick={(el) => {
					setMainTabKey(el)
					setMinorTabKey('0')
				}}
				tabs={[
					{ key: 0, title: 'Сырье', component: <RawTab form={form} /> },
					{ key: 1, title: 'Полуфабрикаты и отходы', component: <SemifinishedTab /> },
					{ key: 2, title: 'Готовая продукция', component: <ReadyProductTab /> },
				]}
				style={{ marginBottom: '10px' }}
			/>

			<TabList
				tabs={getMinorTabs(mainCtx, mainTabKey)}
				onTabClick={setMinorTabKey}
				activeKey={minorTabKey}
			/>
		</>
	)
}

export default ProductCatalogItem
