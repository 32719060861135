import { Col, Row } from 'antd'
import React, { useContext } from 'react'
import { StockSemifItemMainContext } from '../provider/main'
import { CustomForm } from '../../../../../components'
import { getFormItems } from './peresort-modal'
import { getProdCatKindRawMatLabel } from '@berry/common-functions/cross-project-functions'

const PeresortModal = (props) => {
	const mainCtx = useContext(StockSemifItemMainContext)
	let qualityVal = null
	if (mainCtx.selectors.peresort.spec.length) {
		qualityVal = mainCtx.peresort.spec?.vocQuality?.label
	} else qualityVal = 'Отсутствует'
	return (
		<Row>
			<Col span="24">
				<CustomForm
					name="peresort"
					fields={[
						{ name: 'prodCat', value: mainCtx.peresort?.prodCat?.label },
						{
							name: 'prodCatKindRawMat',
							value: getProdCatKindRawMatLabel(mainCtx.peresort?.prodCatKindRawMat),
						},
						{ name: 'spec', value: qualityVal },
						{
							name: 'weight',
							value: mainCtx.peresort?.weight,
						},
					]}
					columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
					items={getFormItems(mainCtx)}
				/>
			</Col>
		</Row>
	)
}

export default PeresortModal
