import {
	getProdCatCaliber,
	getProdCatKindSemifArticul,
	getProdCatKindSemifDisplayVal,
} from '@berry/common-functions/cross-project-functions'
import { Checkbox } from 'antd'
import { CommonSelect, EditDelBtns, InputError } from '../../../../components'
import { getColumnSearchProps, modes, openNewTab, frontSorter } from '../../../../utils'
import { getNullSeletValue } from '../../../../utils/helpers/for-form'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} mainCtx - основной контекст
 * @param {Object} modCtx
 */
export const getDisplayColumns = (mainCtx, modCtx, authCtx) => {
	const stateFunctions = mainCtx.stateFunctions.kindSemifs
	const isDisabled = modCtx.mod === modes.view
	const isDisabledByArchive = (record) => {
		const sameArrs = mainCtx.state.kindSemifs.filter(
			(e) =>
				e.vocMainSemiKindKind?.id === record.vocMainSemiKindKind?.id &&
				e.vocProdType?.id === record.vocProdType?.id &&
				e.kpCaliber?.id === record.kpCaliber?.id &&
				e.vocProdTypeReturnWaste?.id === record.vocProdTypeReturnWaste?.id &&
				e.kpVocAdditSemiType?.id === record.kpVocAdditSemiType?.id &&
				e.kpVocWaseType?.id === record.kpVocWaseType?.id &&
				!!e.isReturnWaste === !!record.isReturnWaste &&
				e.vocMainSemiKindKind2?.id === record.vocMainSemiKindKind2?.id &&
				e.additArrt?.id === record.additArrt?.id &&
				e.quality?.id === record.quality?.id
		)
		if (sameArrs.length > 1) {
			if (sameArrs.some((el) => !el.isArchive)) {
				return sameArrs
					.filter((el) => el.isArchive)
					.map((el) => el._uuid_)
					.includes(record._uuid_)
			}
			return sameArrs.at(-1)._uuid_ !== record._uuid_
		}
		return false
	}
	return [
		{
			title: 'Артикул',
			dataIndex: 'articul',
			key: 'articul',
			width: '8.75%',
			render: (_, record) =>
				getProdCatKindSemifArticul(record, mainCtx.state, mainCtx.additional.allVocMainSemifKinds),
		},
		{
			title: 'Наименование',
			dataIndex: 'displayVal',
			key: 'displayVal',
			width: '29.52%',
			render: (label, record) => label || getProdCatKindSemifDisplayVal(record),
			sorter: frontSorter('string', 'label'),
		},
		{
			title: 'Артикул 1С',
			dataIndex: ['vocProd1c', 'vendorCode'],
			key: 'displayCode1c',
			width: '8.75%',
		},
		{
			title: 'Наименование 1С',
			dataIndex: '',
			key: 'label1c',
			width: '41.63%',
			render: (record) => {
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`vocProd1c.${record._uuid_}`]}
						component={
							<CommonSelect
								value={record.vocProd1c}
								setValue={(data) => {
									stateFunctions.setVocProd1c(record._uuid_, data)
								}}
								isDisabled={
									isDisabled ||
									mainCtx.delEditDeps?.kindSemifs?.[
										mainCtx.state.kindSemifs.find((kind) => kind._uuid_ === record._uuid_)?.id
									]
								}
								showSearch
								dataArr={[
									{ id: null, displayVal: '', isEmpty: true },
									...mainCtx.selectors.allVocProd1CsFiltered,
								]}
							/>
						}
					/>
				)
			},
		},
		{
			title: 'Тип продукта',
			dataIndex: ['vocProdType', 'labelShort'],
			key: 'label',
			width: '7%',
			...getColumnSearchProps(
				[
					...new Set(
						mainCtx.state.kindSemifs
							?.filter((e) => e.vocProdType)
							.map((e) => e.vocProdType.labelShort)
					),
				],
				undefined,
				'vocProdType.labelShort'
			),
		},
		{
			title: 'Архив',
			dataIndex: 'isArchive',
			key: 'isArchiveForFilter',
			width: '4.35%',
			render: (_, record) => (
				<Checkbox
					disabled={isDisabled || isDisabledByArchive(record)}
					checked={record.isArchive}
					onChange={(e) => {
						stateFunctions.setIsArchive(record._uuid_, e.target.checked)
					}}
				/>
			),
			...getColumnSearchProps(
				[...new Set(mainCtx.state.kindSemifs?.map((e) => (e.isArchive ? 'Да' : 'Нет')))],
				undefined,
				'isArchive'
			),
			onFilter: (value, record) => (value === 'Да' ? record.isArchive : !record.isArchive),
		},
		{
			title: null,
			key: 'action',
			width: '4%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={false}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
						deps={mainCtx.delEditDeps?.kindSemifs?.[record.id] || []}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					/>
				)
			},
		},
	]
}

const layoutCol1 = {
	labelCol: { span: 10 },
	wrapperCol: { span: 12 },
}

const layoutCol2 = {
	labelCol: { span: 8, offset: 2 },
	wrapperCol: { span: 12 },
}

const getVocMainSemiKindSelect = (mainCtx, inProc) => {
	if ([null, undefined, ''].includes(inProc)) {
		return mainCtx.additional.allVocMainSemifKinds.map((e) => e.kinds).flat()
	}
	return mainCtx.additional.allVocMainSemifKinds.find((e) => e.id === inProc.id)?.kinds || []
}

export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []

	const { _uuid_ } = params
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.kindSemifs
	let kpField = null
	const foundEl = stateFunctions.get(_uuid_)
	switch (foundEl.vocProdType?.labelShort) {
		case 'ПФ':
			if (foundEl.vocMainSemiKindKind?.isCaliber)
				kpField = {
					layout: layoutCol2,
					label: 'Вид продукта',
					name: 'kpCaliber',
					required: true,
					rules: [{ required: true }],
					field: (
						<CommonSelect
							plainValue={getNullSeletValue(
								foundEl.kpCaliber,
								getProdCatCaliber(foundEl.kpCaliber)
							)}
							setValue={(data) => stateFunctions.setKpCaliber(_uuid_, data)}
							isDisabled={isDisabled}
							showSearch
							dataArr={[
								{ id: null, displayVal: 'Не выбрано', isEmpty: true },
								...mainCtx.selectors.kindSemifs.kpCaliber,
							]}
						/>
					),
				}

			break
		case 'ДП':
			kpField = {
				layout: layoutCol2,
				label: 'Вид продукта',
				name: 'kpVocAdditSemiType',
				rules: [{ required: true }],
				required: true,
				field: (
					<CommonSelect
						value={foundEl.kpVocAdditSemiType}
						setValue={(data) => stateFunctions.setKpVocAdditSemiType(_uuid_, data)}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allVocAdditSemiTypes}
					/>
				),
			}

			break
		case 'ВО':
			kpField = {}
			break
		case 'НО':
			kpField = {
				layout: layoutCol2,
				label: 'Вид продукта',
				name: 'kpVocWaseType',
				required: true,
				field: (
					<CommonSelect
						plainValue={getNullSeletValue(foundEl.kpVocWaseType, foundEl.kpVocWaseType?.displayVal)}
						setValue={(data) => stateFunctions.setKpVocWaseType(_uuid_, data)}
						isDisabled={isDisabled}
						showSearch
						dataArr={[
							{ id: null, displayVal: 'Не выбрано', isEmpty: true },
							...mainCtx.additional.allVocWasteTypes,
						]}
					/>
				),
			}
			break
		default:
			break
	}

	return [
		[
			...(mainCtx.state.isAdditAttr
				? [
						{
							layout: layoutCol1,
							label: 'Доп. признак',
							name: 'additArrt',
							field: (
								<CommonSelect
									plainValue={getNullSeletValue(foundEl.additArrt, foundEl.additArrt?.label)}
									setValue={(data) => stateFunctions.setAdditArrt(_uuid_, data)}
									isDisabled={isDisabled}
									showSearch
									dataArr={[
										{ id: null, displayVal: 'Не выбрано', isEmpty: true },
										...mainCtx.selectors.kindSemifs.additArrt,
									]}
								/>
							),
						},
				  ]
				: []),
			...(!mainCtx.state.isAdditAttr && mainCtx.state.isKindPfByQuality
				? [
						{
							layout: layoutCol1,
							label: 'Качество',
							name: 'quality',
							field: (
								<CommonSelect
									plainValue={foundEl.quality?.label}
									setValue={(data) => stateFunctions.setQuality(_uuid_, data)}
									isDisabled={isDisabled}
									showSearch
									dataArr={(mainCtx.state.qualities || []).map((el) => ({
										...el,
										displayVal: el.label,
									}))}
								/>
							),
						},
				  ]
				: []),
			{
				layout: layoutCol1,
				label: 'Процесс',
				name: 'selectedVocMainSemiKind',
				rules: [{ required: true }],
				required: true,
				field: (
					<CommonSelect
						plainValue={
							mainCtx.additional.allVocMainSemifKinds.find(
								(e) => +e.id === +params.selectedVocMainSemiKind
							)?.vocProcess?.label
						}
						setValue={(data) => {
							params.setSelectedVocMainSemiKind(data)
							if (foundEl.vocMainSemiKindKind) {
								stateFunctions.setVocMainSemiKindKind(_uuid_, null)
							}
						}}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allVocMainSemifKinds.map((e) => ({
							...e,
							displayVal: e?.vocProcess.label,
						}))}
					/>
				),
			},
			{
				layout: layoutCol1,
				label: 'Тип продукта',
				name: 'vocProdType',
				rules: [{ required: true }],
				required: true,
				field: (
					<CommonSelect
						value={foundEl.vocProdType}
						setValue={(data) => stateFunctions.setVocProdType(_uuid_, data)}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allVocProdTypes}
					/>
				),
			},
			...(foundEl.vocProdType?.displayVal === 'ВО'
				? [
						{
							layout: layoutCol1,
							label: 'Обработка возвратного отхода',
							name: 'isReturnWaste',
							field: (
								<Checkbox
									disabled={isDisabled}
									checked={foundEl.isReturnWaste}
									onChange={(e) => stateFunctions.setIsReturnWaste(_uuid_, e.target.checked)}
								/>
							),
						},
				  ]
				: []),
			...(foundEl.isReturnWaste
				? [
						{
							layout: layoutCol1,
							label: 'Процесс ВО',
							name: 'selectedVocMainSemiKind2',
							rules: [{ required: true }],
							required: true,
							field: (
								<CommonSelect
									plainValue={
										mainCtx.additional.allVocMainSemifKinds.find(
											(e) => +e.id === +params.selectedVocMainSemiKind2?.id
										)?.vocProcess?.label
									}
									setValue={(data) => {
										params.setSelectedVocMainSemiKind2(data)
										if (foundEl.vocMainSemiKindKind2) {
											stateFunctions.setVocMainSemiKindKind2(_uuid_, null)
										}
									}}
									isDisabled={isDisabled}
									showSearch
									dataArr={mainCtx.additional.allVocMainSemifKinds.map((e) => ({
										...e,
										displayVal: e?.vocProcess.label,
									}))}
								/>
							),
						},
						{
							layout: layoutCol1,
							label: 'Тип продукта ВО',
							name: 'vocProdTypeReturnWaste',
							rules: [{ required: true }],
							required: true,
							field: (
								<CommonSelect
									value={foundEl.vocProdTypeReturnWaste}
									setValue={(data) => stateFunctions.setVocProdTypeReturnWaste(_uuid_, data)}
									isDisabled={isDisabled}
									showSearch
									dataArr={mainCtx.additional.allVocProdTypes.filter((t) =>
										['ПФ', 'ВО'].includes(t.displayVal)
									)}
								/>
							),
						},
				  ]
				: []),
		],
		[
			...(mainCtx.state.isAdditAttr
				? mainCtx.state.isKindPfByQuality
					? [
							{
								layout: layoutCol2,
								label: 'Качество',
								name: 'quality',
								field: (
									<CommonSelect
										plainValue={foundEl.quality?.label}
										setValue={(data) => stateFunctions.setQuality(_uuid_, data)}
										isDisabled={isDisabled}
										showSearch
										dataArr={(mainCtx.state.qualities || []).map((el) => ({
											...el,
											displayVal: el.label,
										}))}
									/>
								),
							},
					  ]
					: [{}]
				: []),
			...(!mainCtx.state.isAdditAttr && mainCtx.state.isKindPfByQuality ? [{}] : []),
			{
				layout: layoutCol2,
				label: 'Вид осн. ПФ',
				name: 'vocMainSemiKindKind',
				rules: [{ required: true }],
				required: true,
				field: (
					<CommonSelect
						value={foundEl.vocMainSemiKindKind}
						setValue={(data) => stateFunctions.setVocMainSemiKindKind(_uuid_, data)}
						isDisabled={isDisabled}
						showSearch
						dataArr={getVocMainSemiKindSelect(mainCtx, params.selectedVocMainSemiKind)}
					/>
				),
			},
			kpField,
			...(foundEl.vocProdType?.displayVal === 'ВО' ? [{}] : []),
			...(foundEl.isReturnWaste
				? [
						{
							layout: layoutCol2,
							label: 'Вид ПФ',
							name: 'vocMainSemiKindKind2',
							rules: [{ required: true }],
							required: true,
							field: (
								<CommonSelect
									value={foundEl.vocMainSemiKindKind2}
									setValue={(data) => stateFunctions.setVocMainSemiKindKind2(_uuid_, data)}
									isDisabled={isDisabled}
									showSearch
									dataArr={getVocMainSemiKindSelect(mainCtx, params.selectedVocMainSemiKind2)}
								/>
							),
						},
				  ]
				: []),
		],
	]
}
