import { Col, Row } from 'antd'
import React, { useContext } from 'react'
import { StockReadyProdListMainContext } from './provider/main'
import { CustomForm } from '../../../../components'
import { getFormItems } from './modals-for-list'
const ModalsForList = () => {
	const mainCtx = useContext(StockReadyProdListMainContext)
	let key = null
	if (mainCtx.saleModal.__isOpen) {
		key = 'saleModal'
	}
	if (mainCtx.relocationModal.__isOpen) {
		key = 'relocationModal'
	}
	if (mainCtx.repackingModal.__isOpen) {
		key = 'repackingModal'
	}
	if (mainCtx.changeStatusModal.__isOpen) {
		key = 'changeStatusModal'
	}
	return (
		<Row>
			<Col span="24">
				<CustomForm
					onFinish={mainCtx.modalsServerEdit}
					name="sale"
					fields={[
						{ name: 'refund', value: mainCtx.saleModal?.refund },
						{ name: 'sale', value: mainCtx.saleModal?.sale },
						{ name: 'prodCat', value: mainCtx[key]?.storage.prodCat },
						{ name: 'partyNum', value: mainCtx[key]?.storage.partyNum },
						{ name: 'articul', value: mainCtx[key]?.storage.articul },
						{
							name: 'weight',
							value:
								mainCtx.saleModal?.weight ||
								mainCtx.relocationModal?.weight ||
								mainCtx.repackingModal?.weight ||
								mainCtx.changeStatusModal?.weight,
						},
						{ name: 'roomNums', value: mainCtx.relocationModal?.roomNum?.displayCode },
						{ name: 'containerType', value: mainCtx.repackingModal?.containerType?.labelShort },
						{
							name: 'comment',
							value: mainCtx.changeStatusModal?.comment,
						},
					]}
					columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
					items={getFormItems(mainCtx)}
				/>
			</Col>
		</Row>
	)
}

export default ModalsForList
