import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'

import { axios } from '../../utils'

/**
 * запрос на сервер и возврат данных
 */
const DisplayDataFromServer = (props) => {
	const { url, params, rerenderId, field } = props
	const [dataFromServer, setDataFromServer] = useState(null)
	useEffect(() => {
		let mounted = true
		const fetchData = async () => {
			const fromServerResp = await axios(`${url}${params.id ? `/${params.id}` : ''}`, {
				params: { ...(params || {}) },
			})
			if (mounted) {
				setDataFromServer(fromServerResp.data.mainData)
			}
		}
		if (params && Object.keys(params).length) {
			fetchData()
		}
		return () => (mounted = false)
	}, [params.id, rerenderId, url])
	if (!dataFromServer) return <Spin />
	return field ? dataFromServer[field] || '' : dataFromServer || ''
}

export default DisplayDataFromServer
