import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../Wrapper/Wrapper'
import { getColumns } from './columns'
import { applyFilterForList, axios } from '../../../utils'
import { Form } from 'antd'
import { SyncDepsContext } from '../../../contexts'

const vocUrl = '/voc/editable/product-1c'
const searchFields = ['description', 'vendorCode', 'group', 'recordStatus']

const addIsBindedField = (prodCat, prod1c) =>
	prod1c.map((prod) => {
		const isBinded = prodCat.some((pc) =>
			prodCat.some(
				(pc) =>
					pc.kindRawMats.some((rawMat) => rawMat?.vocProd1c?.id === prod.id) ||
					pc.kindSemifs.some((rawMat) => rawMat?.vocProd1c?.id === prod.id) ||
					pc.packages.some((p) => p.vocProd1c?.id === prod.id)
			)
		)
		return { ...prod, isBinded }
	})

const getData = async (setState, setDepsInfo) => {
	const {
		data: { result, syncDeps },
	} = await axios(vocUrl)
	setDepsInfo({ deps: syncDeps })
	const { data } = await axios('/product-catalogs/all')
	const newRes = addIsBindedField(data.result, result)
	setState((prev) => {
		return { ...prev, fromServer: newRes }
	})
}

const Product1c = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	useEffect(() => {
		getData(setState, syncDepsCtx.setDepsInfo)
	}, [syncDepsCtx.state.reloadUuids['office-ms']])
	const [state, setState] = useState({
		search: null,
		fromServer: [],
	})
	const setSearch = (value) => {
		const newState = { ...state, search: value?.toString() || '' }
		setState(newState)
	}
	const toDisplayDataList = () => {
		let result = applyFilterForList(state.fromServer, {
			search: state.search,
			searchFields: searchFields,
		})
		return result
	}
	return (
		<Wrapper
			rights={{
				Справочники: {
					'Продукция из 1С': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Продукция из 1С"
			data={toDisplayDataList()}
			columns={getColumns(toDisplayDataList())}
			search={setSearch}
			vocUrl={vocUrl}
			form={<Form label={''} />}
		/>
	)
}

export default Product1c
