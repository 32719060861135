import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../WrapperTemp/Wrapper'
import { getColumns } from './columns'
import Form from './Form'
import { getDataForVocs } from '../AdditionalSemiType/AdditionalSemiType'
import { SyncDepsContext } from '../../../contexts'
import { commonCheckIsBlocked } from '../../../utils/helpers/for-block-unblock'

const vocUrl = '/voc/editable/car-state'

const CarState = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, setState] = useState([])
	useEffect(() => {
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
	}, [syncDepsCtx.state.reloadUuids['office-ms']])

	const checkIsBlocked = async (id) => {
		const result = await commonCheckIsBlocked([{ entity: 'vocCarState', id }])
		return result[0]?.isBlocked
	}
	return (
		<Wrapper
			breadcrumbsTitle="Состояние машины"
			model="voc_car_state"
			data={state}
			canCreate
			canUpdate
			canDelete
			apiUrl={vocUrl}
			vocUrl={vocUrl}
			entity="vocCarState"
			checkIsBlocked={checkIsBlocked}
			setState={setState}
			getDataForVocs={getDataForVocs}
			columns={getColumns()}
			getForm={(data, recordId, form, action, options) => (
				<Form data={data} recordId={recordId} form={form} action={action} options={options} />
			)}
			rights={{
				Справочники: {
					'Состояние машины': {
						edit: true,
					},
				},
			}}
		/>
	)
}

export default CarState
