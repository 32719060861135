import { Table } from 'antd'
import React, { useContext } from 'react'
import { StockOpsDisposalItemMainContext } from '../provider/main'
import { TabItem } from '../../../../../components'
import { ModContext } from '../../../../../contexts'
import { modes } from '../../../../../utils'
import { getDisplayColumns } from './production-tab'
import SelectProdModal from './SelectProdModal'

const ProductTab = () => {
	const mainCtx = useContext(StockOpsDisposalItemMainContext)
	const modCtx = useContext(ModContext)
	const productionFunctions = mainCtx.stateFunctions.productions
	return (
		<TabItem
			addButton={{
				show: true,
				onClick: () => {
					mainCtx.modalFunctions.openAddProd()
					mainCtx.modalFunctions.setAddProd__name('waste')
				},
			}}
			modal={() => <SelectProdModal />}
			table={() => (
				<Table
					size="small"
					rowKey="_uuid_"
					rowClassName={modCtx.mod !== modes.view && 'clickable'}
					columns={getDisplayColumns(modCtx, mainCtx, productionFunctions)}
					dataSource={mainCtx.state.productions}
					pagination={false}
					scroll={{ x: 800 }}
				/>
			)}
		/>
	)
}

export default ProductTab
