import { Table, Form } from 'antd'
import React, { useContext, useState } from 'react'
import { RecordItemModal, TabItem, CustomForm } from '../../../../components'
import { AuthContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns, getFormItems } from './package-tab'
import { ProductCatalogItemMainContext } from '../provider/main'
import lodash from 'lodash'
import { prepareForOpenedModal } from '../../../../utils/helpers/for-modal'
import { getProdCatKindReadyProdLabel } from '@berry/common-functions/cross-project-functions'

export const PackageTab = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(ProductCatalogItemMainContext)
	const stateFunctions = mainCtx.stateFunctions.packages

	const authCtx = useContext(AuthContext)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [itemForModal, setItemForModal] = useState(null)
	const [form] = Form.useForm()
	const stateData = prepareForOpenedModal(mainCtx.state.packages, itemForModal)
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const newUuid = stateFunctions.create()
					setItemForModal({ _uuid_: newUuid, isNew: true })
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						showErrorModal={true}
						isOpen={isModalOpen}
						title="Упаковка"
						item={itemForModal}
						deleteFun={stateFunctions.delete}
						setItem={setItemForModal}
						setModalState={setIsModalOpen}
						editFun={stateFunctions.edit}
						beforeClose={() => {
							mainCtx.errorFields.packages.forEach((e) => {
								mainCtx.setError([], [], e, undefined)
							})
						}}
						validate={() => mainCtx.validators.package(itemForModal._uuid_)}
						formName="package"
						form={() => {
							if (!itemForModal) return null
							const found = stateFunctions.get(itemForModal._uuid_)
							return (
								<CustomForm
									form={form}
									name="package"
									fields={[
										{ name: 'product', value: mainCtx.state.label },
										{
											name: 'kindReadyProd',
											value: !found.kindReadyProd
												? ''
												: getProdCatKindReadyProdLabel(found.kindReadyProd),
										},
										{
											name: 'vocQuality',
											value: found.vocQuality?.label,
										},
										{
											name: 'vocContType',
											value: found.vocContType?.labelShort,
										},
										{ name: 'weight', value: found.weight },
									]}
									columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
									items={getFormItems(mainCtx, modCtx, itemForModal)}
								/>
							)
						}}
					/>
				)
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowKey={'_uuid_'}
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(
							mainCtx,
							modCtx,
							(record) => {
								if (modCtx.mod === modes.view) return
								setItemForModal({ _uuid_: record._uuid_, prevData: lodash.cloneDeep(record) })
								setIsModalOpen(true)
							},
							authCtx
						)}
						dataSource={stateData}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)
			}}
		/>
	)
}
