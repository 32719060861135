import { Table } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { RecordItemModal, TabItem, CustomForm } from '../../../../components'
import { AuthContext, DataServerContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import {
	getDisplayColumns,
	getFormItems,
	getDisplayModalProdFormColumns,
	getDisplayModalCaliberColumns,
	prepareData,
} from './kind-ready-prod-tab'
import { ProductCatalogItemMainContext } from '../provider/main'
import lodash from 'lodash'
import { getNullSeletValue } from '../../../../utils/helpers/for-form'

const KindReadyProdTab = () => {
	const modCtx = useContext(ModContext)
	const dataFromServerCtx = useContext(DataServerContext)
	const mainCtx = useContext(ProductCatalogItemMainContext)

	const authCtx = useContext(AuthContext)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [itemForModal, setItemForModal] = useState(lodash.cloneDeep(mainCtx.state.kindReadyProds))
	const [_additAttr, setAdditAttr] = useState(mainCtx.state?.additAttrs?.find((e) => e?.id) || null)
	let toDisplayData = mainCtx.state.kindReadyProds
	if (itemForModal?.prevData?.kindReadyProds) toDisplayData = itemForModal.prevData.kindReadyProds
	useEffect(() => {
		if (!_additAttr?.id) return
		let found = mainCtx.state?.additAttrs?.find((e) => e?.id === _additAttr.id)
		if (found && found._uuid_ !== _additAttr._uuid_) {
			setAdditAttr(found)
		}
	}, [_additAttr, mainCtx])
	const additAttr = mainCtx.state?.additAttrs?.find((e) => e?.id === _additAttr?.id) || null
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					setItemForModal({
						prevData: {
							kindReadyProds: mainCtx.state.kindReadyProds,
						},
					})
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						width={'60%'}
						showErrorModal={true}
						isOpen={isModalOpen}
						title="Вид ГП"
						item={itemForModal}
						deleteFun={() => {}}
						setItem={setItemForModal}
						setModalState={setIsModalOpen}
						editFun={(_, newData) => {
							mainCtx.stateFunctions.kindReadyProds.editAll(newData.kindReadyProds)
						}}
						validate={() => {
							return mainCtx.validators.kindReadyProds()
						}}
						form={() => {
							if (!itemForModal) return null

							const data = prepareData(mainCtx, additAttr)

							return (
								<>
									<CustomForm
										name="kindReadyProd"
										fields={[
											{ name: 'additAttr', value: getNullSeletValue(additAttr, additAttr?.label) },
										]}
										columns={[
											{ size: { sm: 24, lg: 12 }, num: 0 },
											{ size: { sm: 24, lg: 12 }, num: 1 },
										]}
										items={getFormItems(mainCtx, setAdditAttr, additAttr)}
									/>
									<Table
										size="small"
										rowClassName={modCtx.mod !== modes.view && 'clickable'}
										columns={getDisplayModalProdFormColumns(mainCtx, modCtx)}
										dataSource={data.form}
										pagination={false}
										scroll={{ x: 800 }}
										onRow={(record) => ({
											onDoubleClick: () => {},
										})}
									/>
									{mainCtx.stateFunctions.kindReadyProds.isBindCaliber(additAttr) &&
										!!mainCtx.state.isCalibration &&
										!!mainCtx.state.calibers?.length && (
											<Table
												size="small"
												rowClassName={modCtx.mod !== modes.view && 'clickable'}
												columns={getDisplayModalCaliberColumns(mainCtx, additAttr)}
												dataSource={data.caliber}
												scroll={{ x: 800 }}
												pagination={false}
												onRow={(record) => ({
													onDoubleClick: () => {},
												})}
											/>
										)}
								</>
							)
						}}
					/>
				)
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(mainCtx, modCtx, dataFromServerCtx, authCtx)}
						dataSource={toDisplayData}
						scroll={{ x: 800 }}
						pagination={false}
						onRow={(record) => ({
							onDoubleClick: () => {},
						})}
					/>
				)
			}}
		/>
	)
}

export default KindReadyProdTab
