import React, { useEffect, useState } from 'react'
import { Modal as ModalAntd, Button } from 'antd'
import { modes, useVocModalTitle } from '../../../utils'
import {
	checkUniqueFields,
	checkValidate,
	differentFields,
	checkCustomValidate,
} from '../vocabularies'

const needValidate = () => {
	return ModalAntd.warning({
		title: 'Не заполнены обязательные поля',
		centered: true,
	})
}

const needUnique = () => {
	return ModalAntd.warning({
		title: 'Введены неуникальные значения',
		centered: true,
	})
}

const displayValidateModal = (text) => {
	return ModalAntd.warning({
		title: text,
		centered: true,
	})
}

const Modal = (props) => {
	const {
		isShow,
		closeModal,
		form,
		addData,
		editData,
		vocMode,
		fields,
		data,
		recordId,
		validateFields = [],
		customValidateFields = [],
		uniqueFields = [],
		maskClosable = false,
		dataDeps,
		isShowValidateModal = true,
	} = props
	const [isCloseModal, setIsCloseModal] = useState(false)

	const [title, changeTitle] = useVocModalTitle()

	const successFn = () => {
		closeModal()
	}

	const handleMainOk = () => {
		const validate = checkValidate(validateFields)
		const unique = checkUniqueFields(uniqueFields, data, recordId)
		const customValidate = checkCustomValidate(customValidateFields)

		if (!validate) {
			return needValidate()
		}
		if (!unique) {
			if (isShowValidateModal) return needUnique()
			return
		}
		if (customValidate !== '') {
			return displayValidateModal(customValidate)
		}

		if (recordId && !differentFields(fields, data, recordId)) {
			closeModal()
			vocMode.mod = modes.view
			return
		}
		vocMode.mod === modes.new ? addData(successFn) : editData(successFn)
	}

	const handleMainClose = () => {
		return vocMode.mod === modes.edit && differentFields(fields, data, recordId)
			? setIsCloseModal(true)
			: closeModal()
	}

	const handleOkClose = () => {
		setIsCloseModal(false)
		closeModal()
	}

	const handleCancelClose = () => {
		setIsCloseModal(false)
	}

	useEffect(() => {
		changeTitle(vocMode?.mod)
	}, [changeTitle, vocMode?.mod])

	return (
		<ModalAntd
			maskClosable={maskClosable}
			destroyOnClose
			title={title}
			visible={isShow}
			onCancel={handleMainClose}
			centered
			width="40%"
			footer={
				<Button type="primary" onClick={handleMainOk}>
					OK
				</Button>
			}
		>
			{form
				? React.cloneElement(form, {
						dataDeps,
						id: recordId,
				  })
				: null}
			<ModalAntd
				maskClosable={maskClosable}
				title={null}
				visible={isCloseModal}
				closable={false}
				centered
				onOk={handleOkClose}
				onCancel={handleCancelClose}
			>
				Выйти без сохранения изменений?
			</ModalAntd>
		</ModalAntd>
	)
}

export default Modal
