export const columns = [
	{
		title: 'Причина блокировки',
		dataIndex: 'label',
		key: 'label',
		width: '65%',
	},
	{
		title: 'Статус',
		dataIndex: 'status',
		key: 'status',
		width: '30%',
	},
]
