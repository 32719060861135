import React, { useContext, useEffect, useRef } from 'react'
import { axios, services } from '../../utils'
import { AuthContext } from '../index'
import { showConfirmModal } from '../../components'
import { showUpdateModal } from '../../utils/constants/for-components'
import { v4 } from 'uuid'
import { Space, Typography, notification, Button } from 'antd'
import { useLocation } from 'react-router-dom'
import { isValidNum } from '@berry/common-functions/validators'

export const reducer = (state) => {
	return {
		...state,
	}
}

const SyncDepsContext = React.createContext()
SyncDepsContext.displayName = 'SyncDepsContext'

const Provider = (props) => {
	const { children } = props
	const authCtx = useContext(AuthContext)
	const location = useLocation()
	useEffect(() => {
		locationPathNameRef.current = location.pathname
	}, [location.pathname])
	const locationPathNameRef = useRef(location.pathname)
	const [notifyApi, notifyContext] = notification.useNotification()
	const [state, dispatch] = React.useReducer(reducer, {
		sse: {
			'office-ms': null,
		},
		reloadUuids: {
			'office-ms': v4(),
		},
	})
	const stateRef = useRef(state)
	const confirmModalRef = useRef(null)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(stateRef.current)
	}

	const subscribe = async () => {
		if (!stateRef.current.sse['office-ms']) {
			let uuidUser
			if (window.name) {
				uuidUser = window.name
			} else {
				uuidUser = v4()
				window.name = uuidUser
			}

			const newSse = new EventSource(
				services['office-ms'] +
					`/pub/add-sse-client?uuidUser=${uuidUser}&token=${localStorage.getItem('token')}`
			)
			newSse.onopen = () => {
				console.log(`subscribe onopen`)
			}
			newSse.onmessage = async (e) => {
				try {
					const serverMessage = JSON.parse(e.data)
					if (serverMessage.isNeedReload) {
						if (confirmModalRef.current) {
							if(confirmModalRef.current.destroy) {
								confirmModalRef.current.destroy()
							}
							notification.destroy(confirmModalRef.current)
							confirmModalRef.current = null
						}

						if (!isValidNum(locationPathNameRef.current.split('/').at(-1))) {
							const key = `open${Date.now()}`
							confirmModalRef.current = key
							notifyApi.info({
								message: `Внесены изменения другим пользователем`,
								duration: null,
								key,
								description: (
									<Button
										type={'primary'}
										onClick={(e) => {
											executeDispatch({
												...stateRef.current,
												reloadUuids: { ...stateRef.current.reloadUuids, 'office-ms': v4() },
											})
											notification.destroy(key)
										}}
									>
										Обновить данные
									</Button>
								),
								placement: 'bottomRight',
							})
						} else {
							confirmModalRef.current = showConfirmModal({
								...showUpdateModal,
							})
							executeDispatch({
								...stateRef.current,
								reloadUuids: { ...stateRef.current.reloadUuids, 'office-ms': v4() },
							})
						}

						return
					}
					if (serverMessage.err) {
						console.log(serverMessage.err)
					} else {
						if (serverMessage.type === 'close') {
							newSse.close()
						}
					}
				} catch (err) {
					console.log(`subscribe message error: ${err.message}`)
				}
			}
			newSse.onerror = async (err) => {
				console.log(err)
				stateRef.current.sse['office-ms'].close()
				if (process.env.REACT_APP_STAND !== 'develop') {
					showConfirmModal({
						title: (
							<Space direction="vertical">
								<Typography.Text>Невозможно подключиться к серверу</Typography.Text>
								<Typography.Text>
									Для продолжения работы необходимо войти в систему заново
								</Typography.Text>
							</Space>
						),
						onOk: () => {
							authCtx.signOut()
							localStorage.setItem('needToUpdate', true)
						},
						okText: 'Ок',
						showCancel: false,
						width: 650,
						closable: false,
					})
				}
			}
			executeDispatch({
				...stateRef.current,
				sse: { ...stateRef.current.sse, 'office-ms': newSse },
			})
		}
	}

	useEffect(() => {
		if (stateRef.current.sse['office-ms']) {
			if (!authCtx?.state?.isAuthenticated) {
				stateRef.current.sse['office-ms'].close()
				executeDispatch({
					...stateRef.current,
					sse: { ...stateRef.current.sse, 'office-ms': null },
				})
			}
		} else {
			if (authCtx?.state?.isAuthenticated) {
				subscribe()
			}
		}
	}, [authCtx?.state?.isAuthenticated])

	const setDepsInfo = async (inParams) => {
		await axios.put('/sync-deps', {
			deps: inParams.deps,
			isMerge: !!inParams.isMerge,
			isForList: !!inParams.isForList,
		})
	}

	const closeNotifyWindow = () => {
		if (confirmModalRef.current) {
			notification.destroy(confirmModalRef.current)
			confirmModalRef.current = null
		}
	}
	const value = {
		state: stateRef.current,
		setDepsInfo,
		closeNotifyWindow,
	}

	return (
		<>
			{notifyContext}
			<SyncDepsContext.Provider value={value}>{children}</SyncDepsContext.Provider>
		</>
	)
}

export { Provider, SyncDepsContext }
