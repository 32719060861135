import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Card } from 'antd'
import { Provider, RawMaterialSupplyListMainContext } from './provider/main'
import { Main } from '../../../layouts'
import { HeaderMenu, StatefulTable } from '../../../components'
import * as utils from './raw-material-supply-list'
import { goToItem } from '../../../utils'

const RawMaterialSupplyList = () => (
	<Provider>
		<Main title="Поставки сырья">
			<RawMaterialSupplyListTable />
		</Main>
	</Provider>
)

const RawMaterialSupplyListTable = () => {
	const mainCtx = useContext(RawMaterialSupplyListMainContext)
	const history = useHistory()
	const { getColumns, rights, url } = utils

	return (
		<>
			<HeaderMenu
				rights={rights}
				breadcrumbsTitle="Поставки сырья"
				search
				canCreate
				handleAddOnMenu={() => goToItem(history, { url, id: 'new' })}
			/>
			<Card>
				<StatefulTable
					rowKey="id"
					columns={getColumns}
					url={url}
					prepareDataFns={[]}
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columnProps={{ mainCtx, fromServerFilters: mainCtx.state.fromServerFilters }}
				/>
			</Card>
		</>
	)
}

export default RawMaterialSupplyList
