import React, { useEffect, useState, useContext } from 'react'
import { Image, Modal } from 'antd'
import Buttons from './Buttons'
import { fallback, getTargetElement } from './utils'
import { FileContext } from '../../contexts'
import './index.css'
import { Spinner } from '..'

const Carousel = ({
	title,
	files,
	delFn,
	disabled,
	path,
	mainCtx,
	previewStyle,
	...modalProps
}) => {
	const [img, setImg] = useState(null)
	const [rotate, setRotate] = useState(0)
	const [zoom, setZoom] = useState(1)
	const [isVisible, setIsVisible] = useState(false)
	const [style, setStyle] = useState('')

	const fileCtx = useContext(FileContext)
	const { getActualState, getFilesWithBase64Path } = fileCtx

	let filesWithBase64Path = getFilesWithBase64Path(files, path)
	if (!filesWithBase64Path?.length) filesWithBase64Path = files

	const previewPhoto = (file) => {
		if (
			['.jpg', '.png', '.bmp', '.gif', '.tiff'].some((item) =>
				file[path]?.toLowerCase().includes(item)
			)
		) {
			return file.srcPath
		} else {
			return file[path]
		}
	}

	useEffect(() => {
		setStyle(`rotate(${rotate}deg) scale(${zoom})`)
	}, [rotate, zoom])

	useEffect(() => {
		const el = getTargetElement('ant-image-preview-img')
		if (el) {
			el.style.transform = style
		}
	}, [style])

	const handleRotate = (e) => setRotate(rotate + 90)
	const handleZoomIn = (e) => (zoom === 2 ? setZoom(zoom) : setZoom(zoom + 0.2))
	const handleZoomOut = (e) => (zoom === 1 ? setZoom(zoom) : setZoom(zoom - 0.2))
	const clickLeft = () => {
		const currentIndex = filesWithBase64Path.findIndex((i) =>
			i[path] ? i[path] === img?.[path] : i._uuid_ === img?._uuid_
		)
		if (currentIndex > 0) {
			setImg(filesWithBase64Path[currentIndex - 1])
		}
	}
	const clickRight = () => {
		const currentIndex = filesWithBase64Path.findIndex((i) =>
			i[path] ? i[path] === img?.[path] : i._uuid_ === img?._uuid_
		)
		if (currentIndex < filesWithBase64Path.length + 1) {
			setImg(filesWithBase64Path[currentIndex + 1])
		}
	}
	const handleClose = () => {
		setIsVisible(false)
		setImg(null)
		setRotate(0)
		setZoom(1)
		setStyle('')
	}
	const handleDownload = () => {
		fileCtx.downloadFiles(img, path)
	}

	const handleDelete = () => {
		Modal.warning({
			width: 500,
			centered: true,
			okCancel: true,
			content: 'Вы действительно хотите удалить данный объект?',
			keyboard: true,
			zIndex: 9999,
			cancelButtonProps: {
				style: {
					backgroundColor: 'white',
					color: '#1890ff',
				},
			},
			onOk: () => {
				const currentIndex = filesWithBase64Path.findIndex((i) =>
					i[path] ? i[path] === img?.[path] : i[path] === img?.[path]
				)
				delFn(img)
				setImg(
					filesWithBase64Path[currentIndex - 1] || filesWithBase64Path[currentIndex + 1] || null
				)
				if (!filesWithBase64Path.length) {
					setIsVisible(false)
				}
			},
		})
	}
	return (
		<>
			<div style={previewStyle}>
				{fileCtx
					.getActualState()
					.loading?.some((item) => item[path] === filesWithBase64Path[0][path]) ? (
					<Spinner center={true} />
				) : (
					filesWithBase64Path.slice(-3).map((file, index) => {
						return (
							<Image
								key={index}
								src={previewPhoto(file)}
								width="100%"
								height="100%"
								onClick={async () => {
									if (getActualState()?.data.some((item) => item[path] === files[0][path])) {
										setImg(
											filesWithBase64Path.find((i) =>
												i[path] ? i[path] === file[path] : i._uuid_ === file._uuid_
											)
										)
										setIsVisible(true)
										return
									}
									await fileCtx.addFiles(files, path)
									setImg(
										filesWithBase64Path.find((i) =>
											i[path] ? i[path] === file[path] : i._uuid_ === file._uuid_
										)
									)
									setIsVisible(true)
								}}
								preview={{
									visible: false,
								}}
								fallback={fallback}
								style={{
									objectFit: 'contain',
									paddingRight: '2px',
								}}
							/>
						)
					})
				)}
			</div>
			<div style={{ display: 'none' }}>
				<Image.PreviewGroup
					preview={{
						current:
							filesWithBase64Path?.findIndex((i) =>
								i[path] ? i[path] === img?.[path] : i._uuid_ === img?._uuid_
							) === -1
								? 0
								: filesWithBase64Path?.findIndex((i) =>
										i[path] ? i[path] === img?.[path] : i._uuid_ === img?._uuid_
								  ),
						src: img ? img.srcPath : '',
						onVisibleChange: (isVisible) => setIsVisible(isVisible),
						visible: isVisible,
						maskClosable: false,
					}}
				>
					{filesWithBase64Path?.map((file, index) => (
						<Image
							key={index}
							src={file.srcPath}
							onClick={() => setImg(filesWithBase64Path.find((i) => i.id === file.id))}
							fallback={fallback}
						/>
					))}
					<Buttons
						handleRotate={handleRotate}
						handleZoomIn={handleZoomIn}
						handleZoomOut={handleZoomOut}
						handleClose={handleClose}
						deleteFn={handleDelete}
						downloadFn={handleDownload}
						setIsVisible={setIsVisible}
						isVisible={isVisible}
						isLeftArrowDisabled={
							filesWithBase64Path.findIndex((i) =>
								i[path] ? i[path] === img?.[path] : i._uuid_ === img?._uuid_
							) === 0
						}
						isRightArrowDisabled={
							filesWithBase64Path.findIndex((i) =>
								i[path] ? i[path] === img?.[path] : i._uuid_ === img?._uuid_
							) >=
							filesWithBase64Path.length - 1
						}
						isZoomInDisabled={zoom >= 2}
						isZoomOutDisabled={zoom <= 1}
						isDelDisabled={disabled}
						title={title}
						clickLeft={clickLeft}
						clickRight={clickRight}
					/>
				</Image.PreviewGroup>
			</div>
		</>
	)
}

export default Carousel
