import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import lodash from 'lodash'
import { ModContext, AuthContext, SyncDepsContext } from '../../../../contexts'
import {
	prepareObjFromServer,
	axios,
	modes,
	getArrDiff,
	isEdited,
	openNewTab,
	getUserFromToken,
} from '../../../../utils'
import { getObjDiff } from '../../../../utils/helpers'
import { showConfirmModal } from '../../../../components'
import { Button, Space, Typography } from 'antd'
import { checkRole } from '@berry/common-functions/role'

const dataUrl = '/voc/editable/position-type'

export const reducer = (state) => {
	return {
		...state,
	}
}

const MatchSpecSettingsItemMainContext = React.createContext()
MatchSpecSettingsItemMainContext.displayName = 'MatchSpecSettingsItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const authCtx = useContext(AuthContext)
	const modCtx = useContext(ModContext)
	const syncDepsCtx = useContext(SyncDepsContext)
	const [oldData, setOldData] = useState([])
	const [matchItems, setMatchItems] = useState([])
	useEffect(() => {
		const getData = async () => {
			const res = await axios(dataUrl, {
				params: { pageLimit: 16 },
			})
			if (res?.data.data) {
				syncDepsCtx.setDepsInfo({ deps: res.data.syncDeps })
				setOldData(res.data.data)
			}
		}
		getData()
	}, [syncDepsCtx.state.reloadUuids['office-ms']])
	useEffect(() => {
		const getMatchItems = async () => {
			const result = await axios(dataUrl + '/addit-for-match-spec')
			if (result.data) {
				setMatchItems(result.data.additional.matchItems)
			}
		}
		getMatchItems()
	}, [])
	const recordFromDataSrvCtx = lodash.cloneDeep(oldData).filter((el) =>
		checkRole(
			{
				'АРМ офиса': {
					'Протоколы контроля': {
						match: true,
					},
				},
			},
			JSON.parse(el.params)
		)
	)
	prepareObjFromServer(recordFromDataSrvCtx)

	const [state, dispatch] = React.useReducer(reducer, { fromServer: recordFromDataSrvCtx })
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	const commonFieldInArrUpdate = (inUuid, inField, value) => {
		let newState = lodash.cloneDeep(stateRef.current)
		const indx = newState.fromServer.findIndex((e) => String(e._uuid_) === String(inUuid))
		if (indx === -1) throw Error('indx is -1 ')
		newState.fromServer[indx][inField] = value
		executeDispatch(newState)
	}

	const setError = (inFields, inUuids, field, text) => {
		if (!inFields.length && !inUuids.length) {
			executeDispatch({
				...stateRef.current,
				formErrors: { ...stateRef.current.formErrors, [field]: text },
			})
			return
		}
	}

	const validate = () => {
		const state = lodash.cloneDeep(stateRef.current.fromServer)
		if (!state.some((i) => i.isInitiatorSpec) || state.every((i) => i.lvlApproveSpec === '-')) {
			return 'Не выбран Инициатор и/или Утверждающий'
		}
		const arrNumbers = (field) => {
			return lodash
				.cloneDeep(stateRef.current.fromServer)
				.filter((i) => i.isUser)
				.map((item) => {
					if (item[field] > 0) return item[field]
					return 0
				})
				.sort()
		}
		let arrOfnumsLvlApproveSpec = []
		let arrOfnumsLvlMatchSpec = []
		const numsOfLvlApproveOrMatch = (numField, field) => {
			arrNumbers([field]).forEach((n, idx) => {
				if (arrNumbers([field])[idx + 1] - n !== 1 && arrNumbers([field])[idx + 1] - n !== 0) {
					for (let i = +n + 1; i < arrNumbers([field])[idx + 1]; i++) {
						numField.push(i)
					}
				}
			})
		}
		arrNumbers('lvlApproveSpec')
		numsOfLvlApproveOrMatch(arrOfnumsLvlApproveSpec, 'lvlApproveSpec')
		arrNumbers('lvlMatchSpec')
		numsOfLvlApproveOrMatch(arrOfnumsLvlMatchSpec, 'lvlMatchSpec')
		if (arrOfnumsLvlApproveSpec.length && arrOfnumsLvlMatchSpec.length) {
			return `Не выбран Согласующий ${arrOfnumsLvlApproveSpec.join(
				','
			)} уровня и Утверждающий ${arrOfnumsLvlMatchSpec.join(',')} уровня`
		}
		if (arrOfnumsLvlApproveSpec.length) {
			return `Не выбран Утверждающий ${arrOfnumsLvlApproveSpec.join(',')} уровня`
		}
		if (arrOfnumsLvlMatchSpec.length) {
			return `Не выбран Согласующий ${arrOfnumsLvlMatchSpec.join(',')} уровня`
		}
		if (
			stateRef.current.fromServer.some((row, index) =>
				isEdited(getObjDiff(oldData[index], stateRef.current.fromServer[index]))
			)
		) {
			if (matchItems.length) {
				return new Promise((resolve, reject) => {
					return showConfirmModal({
						title: (
							<Space direction="vertical">
								<Typography.Text>По текущему алгоритму проходит согласование</Typography.Text>
								<Space direction="vertical">
									{matchItems.map((i) => (
										<Button
											key={i.link}
											type="link"
											onClick={() => {
												openNewTab(i.link, {
													authCtx: authCtx.state,
												})
											}}
										>
											{i.title}
										</Button>
									))}
								</Space>
								<Typography.Text>
									При сохранении изменений в данной таблице данные объекты будут переведены в статус
									имеющий значение Не утверждено, процесс согласования необходимо будет повторить
									заново.
								</Typography.Text>
							</Space>
						),
						onOk: async () => {
							const idRegStaff = getUserFromToken().id
							if (!idRegStaff) {
								reset()
								return resolve('Вы не можете участвовать в процессе согласования')
							}
							const promises = []
							for (const item of matchItems) {
								promises.push(
									axios.put(item.link.slice(0, item.link.lastIndexOf('/')), {
										status: 'Не утверждено',
										event: 'Отмена согласования',
										id: item.id,
										idRegStaff,
										cause: 'Изменение настроек согласования',
									})
								)
							}
							await Promise.all(promises).catch((err) => {
								resolve('Произошла ошибка, перезагрузите страницу и попробуйте еще раз')
							})
							resolve()
						},
						onCancel: () => {
							reset()
							resolve()
						},
						width: '45%',
						okText: 'Ок',
						isCancelBtnPrimary: true,
					})
				})
			}
		}
		return ''
	}

	const stateFunctions = {
		setIsInitiatorSpec: (inUuid, val) => {
			commonFieldInArrUpdate(inUuid, 'isInitiatorSpec', val)
			lodash
				.cloneDeep(stateRef.current.fromServer)
				.filter((user) => user.isUser)
				.forEach((u) => {
					if (u._uuid_ !== inUuid) {
						commonFieldInArrUpdate(u._uuid_, 'isInitiatorSpec', false)
					}
					if (u._uuid_ === inUuid) {
						commonFieldInArrUpdate(u._uuid_, 'lvlMatchSpec', '-')
						commonFieldInArrUpdate(u._uuid_, 'lvlApproveSpec', '-')
					}
				})
		},
		setLvlApproveSpec: (inUuid, val) => {
			commonFieldInArrUpdate(inUuid, 'lvlApproveSpec', val)

			lodash
				.cloneDeep(stateRef.current.fromServer)
				.filter((user) => user.isUser)
				.forEach((u) => {
					if (u._uuid_ !== inUuid) {
						if (String(val) === String(u.lvlApproveSpec))
							commonFieldInArrUpdate(u._uuid_, 'lvlApproveSpec', '-')
					}
					if (u._uuid_ === inUuid) {
						commonFieldInArrUpdate(u._uuid_, 'isInitiatorSpec', false)
						commonFieldInArrUpdate(u._uuid_, 'lvlMatchSpec', '-')
					}
				})
		},
		setLvlMatchSpec: (inUuid, val) => {
			commonFieldInArrUpdate(inUuid, 'lvlMatchSpec', val)
			lodash.cloneDeep(stateRef.current.fromServer).forEach((i) => {
				if (i._uuid_ === inUuid) {
					commonFieldInArrUpdate(i._uuid_, 'isInitiatorSpec', false)
					commonFieldInArrUpdate(i._uuid_, 'lvlApproveSpec', '-')
				}
			})
		},
	}

	const serverEdit = async () => {
		const err = await validate()
		if (err) {
			return showConfirmModal({
				title: err,
				showCancel: false,
			})
		}
		let body = {}
		body = [...getEditedData().filter((el) => !!el && isEdited(el))]
		if (!body.length) {
			modCtx.set(modes.view)
			return
		}
		executeDispatch({ ...stateRef.current, isPendingReq: true })
		await axios.put(dataUrl, body)
		executeDispatch({ ...stateRef.current, isPendingReq: false })
		modCtx.set(modes.view)
	}

	const getEditedData = () => {
		return getArrDiff(recordFromDataSrvCtx, stateRef.current.fromServer)
	}
	const isItemEdited = () => {
		if (modCtx.mod === modes.view) {
			return false
		}
		const editedFields = getEditedData()
		return isEdited(editedFields)
	}
	/**
	 * Валидация всех полей в соответствии с конфлюенсом
	 * @returns {string} - если вернулась строка и не пустая значит есть ошибка валидации
	 */

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */

	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(oldData).filter((el) =>
			checkRole(
				{
					'АРМ офиса': {
						'Протоколы контроля': {
							match: true,
						},
					},
				},
				JSON.parse(el.params)
			)
		)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({ fromServer: recordFromDataSrvCtx })
	}, [oldData])

	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, oldData, params.id])

	/**
	 * подготавливает данные которые нужно выбират
	 */

	const getActualState = () => {
		const obj = lodash.cloneDeep(stateRef.current.fromServer)
		return obj
	}

	const value = {
		state: getActualState(),
		isPendingReq: stateRef.current.isPendingReq,
		stateFunctions,
		serverEdit,
		isEdited: isItemEdited,
		reset,
		setError,
		formErrors: stateRef.current.formErrors,
	}

	return (
		<MatchSpecSettingsItemMainContext.Provider value={value}>
			{children}
		</MatchSpecSettingsItemMainContext.Provider>
	)
}

export { Provider, MatchSpecSettingsItemMainContext }
