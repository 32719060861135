import { Table } from 'antd'
import React, { useContext } from 'react'
import { ConsolidatedReportItemMainContext } from '../provider/main'
import { getDisplayColumns } from './to-balance-tab'
import { TabItem } from '../../../../components'
import { prepareData } from '../../../../utils'

const ToBalanceTab = () => {
	const mainCtx = useContext(ConsolidatedReportItemMainContext)
	const preparedDataForPage = prepareData(mainCtx.state.balances, 'prods', 'prod')
	return (
		<TabItem
			addButton={{ disabled: true }}
			table={() => {
				return (
					<Table
						rowKey="_uuid_"
						size="small"
						columns={getDisplayColumns(mainCtx)}
						dataSource={preparedDataForPage}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)
			}}
		/>
	)
}

export default ToBalanceTab
