import React, { useContext, useEffect, useRef } from 'react'
import { ListContext, SyncDepsContext } from '../../../../../contexts'
import moment from 'moment'
import lodash from 'lodash'
import { getCommonProviderModalFunctions } from '../../../../../utils/helpers/generators'
import {
	getStockCalculatedParams,
	numToFixed,
} from '@berry/common-functions/cross-project-functions'
import { calcProdTaskIsTolling } from '../../../../../utils/helpers/cross-pages-funcs'
import { getTaskWithStockRawMatCompl } from '../../../../../utils/helpers/cross-pages-funcs'
import {
	startUseEffectHandlerForList,
	everyOtherTimeUseEffectHandlerForList,
	axios,
} from '../../../../../utils'
const dataUrl = '/stock/samples'

export const reducer = (state) => {
	return {
		...state,
	}
}

const StockSampleListMainContext = React.createContext()
StockSampleListMainContext.displayName = 'StockSampleListMainContext'

const Provider = (props) => {
	const {
		state: { page, pageSize, mainFilter, filters, sorter, isInStock },
		setTotal,
	} = useContext(ListContext)
	const { children } = props
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: {
			idOrder: [],
			partyNum: [],
			articul: [],
			articul1C: [],
			prodCat: [],
			prodCatKind: [],
			pkgType: [],
			contType: [],
			nettoPkgWeight: [],
			roomNum: [],
			status: [],
			isModalsServerEditSend: false,
		},
		isInitialized: false,
		isLoading: false,
		saleModal: { __isOpen: false },
		relocationModal: { __isOpen: false },
		utilizationModal: { __isOpen: false },
		fromServerSelectors: {},
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = newState
		dispatch({ ...state })
	}

	const resetModals = () => {
		executeDispatch({
			...stateRef.current,
			saleModal: { __isOpen: false },
			relocationModal: { __isOpen: false },
			utilizationModal: { __isOpen: false },
		})
	}

	const modalSetOpen = (val, modalName) => {
		executeDispatch({ ...stateRef.current, [modalName]: { isOpen: val } })
	}

	const selectors = {
		saleModal: {
			customer: lodash.sortBy(stateRef.current.fromServerSelectors.customers, ['label']),
		},
		relocationModal: {
			room: stateRef.current.fromServerSelectors.room?.filter((room) => {
				return room.type === 'Складское'
			}),
		},
	}

	useEffect(() => {
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				isInStock,
				sorter,
				mainFilter,
				filters,
				offset: pageSize * page - pageSize,
				limit: pageSize,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [])
	const everyOtherTimeUseEffectHandlerForListFunc = () =>
		everyOtherTimeUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				offset: pageSize * page - pageSize,
				limit: pageSize,
				sorter,
				mainFilter,
				filters,
				search: stateRef.current.search,
				isInStock,
				isSelectorsLoaded: true,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	useEffect(() => {
		everyOtherTimeUseEffectHandlerForListFunc()
	}, [
		page,
		sorter,
		mainFilter,
		filters,
		stateRef.current.search,
		syncDepsCtx.state.reloadUuids['office-ms'],
		isInStock,
		stateRef.current.search,
	])

	const modalsServerEdit = async () => {
		let options = { url: dataUrl, method: 'PUT' }
		let body = {}

		if (stateRef.current.saleModal.storage) {
			let item = stateRef.current.saleModal
			let storage = stateRef.current.saleModal.storage.storages.find(
				(el) => el.id === stateRef.current.saleModal.storage.idStorage
			)
			if (+item.weight === +item.storage.weight) {
				body = {
					id: item.storage.id,
					storages: [
						{
							id: item.storage.idStorage,
							status: 'Продажа',
							date: moment(),
						},
					],
					to1cData: { customer: item.customer },
				}
			}
			if (+item.weight < +item.storage.weight) {
				body = {
					id: item.storage.id,
					storages: [
						{
							id: item.storage.idStorage,
							weight: item.storage.weight - item.weight,
						},
						{
							...storage,
							weight: item.weight,
							date: moment(),
							status: 'Продажа',
						},
					],
					to1cData: { customer: item.customer },
				}
				delete body.storages[1].id
			}
		}

		if (stateRef.current.relocationModal.storage) {
			let item = stateRef.current.relocationModal
			body = {
				id: item.storage.id,
				storages: [
					{
						id: item.storage.idStorage,
						room: item.room,
						date: moment(),
					},
				],
			}
		}

		executeDispatch({ ...stateRef.current, isModalsServerEditSend: true })
		const resp = await axios.put(options.url, body)
		executeDispatch({ ...stateRef.current, isModalsServerEditSend: false })
		everyOtherTimeUseEffectHandlerForListFunc()
		resetModals()
		return { method: options.method, resp: resp }
	}

	const sendUtilizationToServer = async () => {
		let options = { dataUrl, url: `${dataUrl}/utilization`, method: 'PUT' }
		const groupedByIds = stateRef.current.utilizationModal.items.reduce((acc, cur) => {
			if (acc[String(cur.id)]) {
				acc[String(cur.id)].push(cur)
			} else {
				acc[String(cur.id)] = [cur]
			}
			return acc
		}, {})
		let body = []
		Object.entries(groupedByIds).forEach(([id, storages]) => {
			body.push({
				id: +id,
				articul: stateRef.current.utilizationModal.items.find((e) => +e.id === +id).articul,
				storages: storages.map((e) => {
					return {
						id: e.idStorage,
						oldStatus: e.status,
						status: 'На оформлении в 1С',
						newStatus: 'Утилизировано',
					}
				}),
			})
		})
		const resp = await axios.put(options.url, body)
		everyOtherTimeUseEffectHandlerForListFunc()
		resetModals()
		return { method: options.method, id: resp }
	}

	const dropStatus = async (el) => {
		const resp = await axios.put(
			dataUrl,
			{
				id: el.id,
				storages: [
					{
						id: el.idStorage,
						status: el.storages.find((s) => s.id === el.idStorage).oldStatus,
						oldStatus: null,
					},
				],
			},
			{
				method: 'PUT',
				url: dataUrl,
			}
		)
		return { method: 'PUT', resp }
	}

	const toDisplayDataList = () => {
		return stateRef.current.fromServer
	}

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		selectors,
		[
			{ field: 'weight', updateVal: 'common', modalName: 'saleModal' },
			{ field: 'customer', updateVal: 'obj', modalName: 'saleModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'saleModal' },

			{ field: 'items', updateVal: 'common', modalName: 'utilizationModal' },

			{ field: 'weight', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'room', updateVal: 'obj', modalName: 'relocationModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'relocationModal' },
		]
	)

	const value = {
		state: stateRef.current,
		toDisplayDataList,
		selectors,
		fromServerTotalSum: stateRef.current.fromServerTotalSum,
		isModalsServerEditSend: stateRef.current.isModalsServerEditSend,
		saleModal: lodash.cloneDeep(stateRef.current.saleModal),
		relocationModal: lodash.cloneDeep(stateRef.current.relocationModal),
		utilizationModal: lodash.cloneDeep(stateRef.current.utilizationModal),
		modalsServerEdit,
		resetModals,
		modalSetOpen,
		sendUtilizationToServer,
		modalFunctions,
		dropStatus,
		setSearch,
	}

	return (
		<StockSampleListMainContext.Provider value={value}>
			{children}
		</StockSampleListMainContext.Provider>
	)
}

export const prepareList = (data) => {
	let preparedData = []

	data.forEach((it) => {
		const { articul, articul1C, prodCatKind } = getStockCalculatedParams(it)
		it.storages.forEach((el) => {
			if (+el.weight === 0) return
			preparedData.push({
				...it,

				weight: numToFixed(+el.weight, 2),
				partyNum: it.taskRep?.partyNum,
				partyNumForSorter: it.taskRep?.partyNum.slice(5, 9),
				articul: articul,
				articul1C: articul1C,
				prodCat: it.taskRep.task.prodCat.label,
				prodCatKind: prodCatKind,
				contType: el.contType?.labelShort,
				isTolling: calcProdTaskIsTolling(it.taskRep?.task),
				roomNum: el.room?.displayCode,
				status: el.status,
				idStorage: el.id,
				idOrder:
					it.idContrOrder ||
					getTaskWithStockRawMatCompl(it.taskRep?.task?.complectations?.[0], {})?.stockRawMatStor
						?.stockRawMat.supplProd?.order?.id ||
					'',
			})
		})
	})
	return preparedData
}
export { Provider, StockSampleListMainContext }
