import React, { useContext, useEffect, useState } from 'react'
import { SyncDepsContext } from '../../../contexts'
import { getDataForVocs } from '../AdditionalSemiType/AdditionalSemiType'
import Wrapper from '../Wrapper/Wrapper'
import { columns } from './columns'

const vocUrl = '/voc/static/product-type'

const ProductType = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, setState] = useState([])

	useEffect(() => {
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
	}, [syncDepsCtx.state.reloadUuids['office-ms']])
	return (
		<Wrapper
			rights={{
				Справочники: {
					'Типы продукта': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Типы продукта"
			data={state}
			columns={columns}
			vocUrl={vocUrl}
		/>
	)
}

export default ProductType
