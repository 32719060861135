import React from 'react'
import { Tag } from 'antd'

const getStatusColor = (status) => {
	if (status === 'Черновик') {
		return '#A9D5D6'
	}
	if (
		[
			'Ожидает отправки',
			'Ожидает отправки в 1С',
			'Удержано',
			'Заблокировано',
			'Не синхронизирована',
			'Не утверждено',
		].includes(status)
	) {
		return '#DB6D52'
	}
	if (
		[
			'Утверждено',
			'Работает',
			'Действующий',
			'Синхронизирована',
			'Принято в 1С',
			'Утверждена',
			'Принято из 1С',
			'Допущено',
			'Дефростация',
			'Хранение',
			'Выполнена',
		].includes(status)
	) {
		return '#7FBE89'
	}
	if (
		['Отклонено 1С', 'Ошибка отправки в 1С', 'Болеет', 'В отпуске', 'В декрете'].includes(status)
	) {
		return '#F5D576'
	}
	if (
		[
			'Возврат',
			'Списано',
			'Продажа',
			'Сработано',
			'Утилизировано',
			'Закрытый',
			'В архиве',
			'Уволен',
		].includes(status)
	) {
		return '#C7C6BB'
	}
	if (['На согласовании', 'На утверждении'].includes(status)) {
		return '#407CAE'
	}
}

const Status = ({ status, style = {} }) => {
	const color = getStatusColor(status)
	if (!color) {
		return <span style={style}>{status}</span> || null
	}
	return (
		<Tag
			className={
				['Бронь', 'Продажа', 'Сработано', 'Утилизировано'].includes(status)
					? 'pictogram tab-link-pictogram'
					: ''
			}
			style={{
				borderRadius: 8,
				...style,
			}}
			color={color}
		>
			{status}
		</Tag>
	)
}

export default Status
