const math = require('./math')

class ErrorToFront extends Error {
	constructor(message) {
		super(message)
		this.name = 'ErrorToFront'
		this.isHumanReadable = true
	}
}

const stockModelToNumMap = {
	stock_raw_mat_storage: 1,
	stock_ready_prod_storage: 2,
	stock_semif_storage: 3,
	stock_sample_storage: 4,
	stock_waste_storage: 5,
}

module.exports.ErrorToFront = ErrorToFront
module.exports.math = math
module.exports.stockModelToNumMap = stockModelToNumMap
