import { getProdCatKindRawMatLabel } from '@berry/common-functions/cross-project-functions'
import { getColumnSearchProps, sorter } from '../../../utils'

export const getProduct = (prod) =>
	`${prod.prodCatKindRawMat.prodCat.label} ${getProdCatKindRawMatLabel(prod.prodCatKindRawMat)}`

export const columns = (data) => [
	{
		title: 'Продукт',
		key: 'prodCatKindRawMat',
		sorter: sorter('string', 'prodCatKindRawMat.prodCat.label'),
		width: '30%',
		...getColumnSearchProps(data?.map((prod) => getProduct(prod))),
		onFilter: (value, record) => value === getProduct(record),
		render: (_, record) => getProduct(record),
	},
	{
		title: 'Срок годности',
		dataIndex: 'shelfLife',
		key: 'shelfLife',
		width: '30%',
	},
	{
		title: 'ЕИ',
		dataIndex: ['vocMeasure', 'labelShort'],
		key: 'measure',
		width: '30%',
	},
]
