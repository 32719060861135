import { Checkbox } from 'antd'
import { calcSpan, sortWithSpec } from '../utils'
import { getColumnSearchProps } from '../../../../../utils'
import { getEtalon } from '../../specification-item'
import { getSpecParamDeviation } from '@berry/common-functions/cross-project-functions'
import '../../../../../index.css'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (mainCtx, dataSource, params, setParams) => {
	const {
		selectors: {
			parameters: {
				vocSpecParamParam: vocSpecParamParamSelectors,
				vocSpecParam: vocSpecParamSelectors,
			},
		},
		state: {
			data: { parameters },
		},
		additional,
	} = mainCtx
	return [
		{
			title: null,
			key: 'sectionCheckbox',
			width: '3%',
			className: 'elemAlignTop',
			render: (record) => {
				const { params: specParams } =
					vocSpecParamSelectors.find((p) =>
						p.params.some((param) => param?.id === record.vocSpecParamParam?.id)
					) || {}
				return (
					<Checkbox
						checked={
							dataSource.every((ds) => params.includes(ds.id)) ||
							specParams?.every((spParam) => params.includes(spParam.id))
						}
						onChange={(e) =>
							e.target.checked
								? setParams([
										...params,
										...specParams
											.filter((spParam) => !params.includes(spParam.id))
											.map((p) => p.id),
								  ])
								: setParams(params.filter((p) => !specParams.map((param) => param.id).includes(p)))
						}
					/>
				)
			},
			onCell: (record) => ({
				rowSpan: calcSpan(dataSource, 'vocSpecParamParam.spec.displayVal', record),
			}),
		},
		{
			title: 'Раздел',
			dataIndex: ['vocSpecParamParam', 'spec', 'displayVal'],
			key: 'displayVal',
			width: '20%',
			className: 'elemAlignTop',
			onCell: (record) => ({
				rowSpan: calcSpan(dataSource, 'vocSpecParamParam.spec.displayVal', record),
			}),
			...getColumnSearchProps(
				additional.allSpecParams
					.filter((p) => parameters.some((param) => param.vocSpecParamParam.spec.id === p.id))
					.map((spec) => spec.displayVal),
				undefined,
				'vocSpecParamParam.spec.displayVal'
			),
		},
		{
			title: null,
			dataIndex: ['vocSpecParamParam', 'id'],
			key: 'paramCheckbox',
			width: '3%',
			render: (id, record) => (
				<Checkbox
					checked={params.includes(id)}
					onChange={(e) =>
						e.target.checked
							? setParams([...params, vocSpecParamParamSelectors.find((p) => p.id === id)?.id])
							: setParams(params.filter((paramId) => paramId !== id))
					}
				/>
			),
		},
		{
			title: 'Параметр',
			dataIndex: ['vocSpecParamParam', 'displayVal'],
			key: 'section',
			width: '30%',
			sorter: sortWithSpec,
		},
		{
			title: 'ЕИ',
			dataIndex: ['vocSpecParamParam', 'vocMeasure', 'displayVal'],
			key: 'vocMeasure',
			width: '5%',
		},
		{
			title: 'Цель',
			key: 'etalon',
			width: '15%',
			render: (_, record) => {
				return (
					<div style={{ wordWrap: 'break-word', wordBreak: 'break-word', ellipsis: true }}>
						{getEtalon(record)}
					</div>
				)
			},
		},
		{
			title: 'Допустимое отклонение',
			key: 'deviation',
			render: (_, record) => {
				return getSpecParamDeviation(record)
			},
			width: '25%',
		},
	]
}
