import moment from 'moment'
import { checkIsNot } from '@berry/common-functions/obj-arr'
import { InputError, RecordInfoItemDelBtn } from '../../../components'
import { InputNumber, Space } from 'antd'
import { modes } from '../../../utils'
import { basicValidator, isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'
import { getProdNameByCustomerQuota } from '../../common-functions.js'
import {
	getProdNameByProviderQuota,
	numToFixed,
} from '@berry/common-functions/cross-project-functions'
/**
 * действует ли договор на данный момент
 * @param {Object} inParams
 * @param {String} inParams.status1C - статус договора в 1С
 * @returns {boolean}
 */
export const isContractActive = (inParams) => {
	return inParams.status1C === 'Действует'
}

/**
 * возвращает строку если свойство dateEnd объекта равно "0001-01-01", по умолчанию - входящее значение inParams.dateEnd
 * @param {Object} inParams
 * @param {Date} inParams.dateEnd - дата окончания
 * @param {String} value - значение для вывода
 * @returns {boolean}
 */
export const changeDateOutput = (inParams, value) =>
	inParams.dateEnd !== '0001-01-01' ? inParams.dateEnd : value ? value : inParams.dateEnd

/**
 * получить продукты договора
 * @param {Object} inState
 * @param {('customer'|'provider')} type - дата окончания
 * @returns {String}
 */
export const getProducts = (inState, type) => {
	if (!['customer', 'provider'].includes(type)) throw Error('Неверный тип контракта')

	if (type === 'customer') {
		return inState.customerQuotas
			.map((e) => getProdNameByCustomerQuota(e))
			.filter((e, index, self) => self.indexOf(e) === index)
			.join(', ')
	}
	if (type === 'provider') {
		return inState.providerQuotas
			.map((e) => getProdNameByProviderQuota(e.rmProvProd))
			.filter((e, index, self) => self.indexOf(e) === index)
			.join(', ')
	}
}

/**
 * получить остаток для распределения  квоты
 * @param {Object} inParams
 * @param {number} inParams.quota - дата окончания
 * @param {Array<{fact: number}>} inParams.monthlyListings - дата окончания
 * @returns {('Закрытый'|'Действующий')}
 */
export const getRemainder = (inParams) => {
	if (!checkIsNot(inParams, ['quota', 'monthlyListings'], [null, undefined])) return 0
	return +(
		+inParams.quota - inParams.monthlyListings.reduce((acc, cur) => acc + (+cur.fact || 0), 0)
	).toFixed(2)
}

/**
 * возвращает массив годов, в которые действует контракт
 * @param {Date} date1 - дата заключения договора
 * @param {Date} date2 - дата окончания действия договора
 * @returns {Array<String>}
 */
export const getQuotasYears = (mainCtx, provOrCustKey) => {
	const result = {}
	mainCtx.state[provOrCustKey]?.forEach((e) => {
		result[e._uuid_] = getContractYearsV2(mainCtx, provOrCustKey, e._uuid_)
	})
	return result
}

/**
 * применяет фильтры для квот к массиву данных
 * @param {Array<Object} inArr -
 * @param {Array<String>} filters -
 */
export const filterDisplayData = (inArr, filters) => {
	return inArr.filter((e) => {
		if (filters?.year?.length) {
			if (!filters.year.includes(String(e.year))) return false
		}
		if (filters?.applicationNum?.length) {
			if (!filters.applicationNum.includes(e.application?.displayCode)) return false
		}
		if (filters?.rmProvProd?.length) {
			const label = getProdNameByProviderQuota(e?.rmProvProd)
			if (!filters.rmProvProd.includes(label)) return false
		}
		return true
	})
}

/**
 * когда мы отфильтровали дату
 * то мы должны и подрекдактировать года для
 * правильного отображения роу спанов
 * @param {Array<Object} arr -
 * @param {Array<String>} years -
 */
export const patchYearsProperty = (arr, years) => {
	if (!years?.length) return
	if (!arr.length) return
	let oldUuid = null
	arr.forEach((e) => {
		e.years = e.years.filter((y) => years.includes(String(y)))
		if (e._uuid_ !== oldUuid) {
			oldUuid = e._uuid_
			e.indxMain = 0
		}
	})
}

/**
 * пытается преобразовать стролку с датой в момент обьект,
 * или возвращает текущий момент
 * @param {String} date - дата
 * @returns {Moment}
 */
export const getValidDate = (date) => {
	let momentDate = moment(date)
	if (!momentDate.isValid() || moment('1999-01-01').isAfter(momentDate)) {
		momentDate = moment()
	}
	return momentDate
}

/**
 * возвращает массив годов, в которые действует контракт
 * @param {Date} date1 - дата заключения договора
 * @param {Date} date2 - дата окончания действия договора
 * @returns {Array<String>}
 */
export const getContractYearsV2 = (mainCtx, provOrCustKey, uuidQuota) => {
	let dateStart = mainCtx.state.dateStart
	let dateEnd = mainCtx.state.dateEnd
	if (mainCtx.state.frame) {
		const foundQuota = mainCtx.stateFunctions[provOrCustKey].get(uuidQuota)
		if (!foundQuota.application) {
			return [moment().get('year')]
		} else {
			dateStart = foundQuota.application.dateStart
			dateEnd = foundQuota.application.dateEnd
		}
	}
	let momentDateEnd = getValidDate(dateEnd)
	let diff = momentDateEnd.endOf('year').diff(moment(dateStart), 'year', true)
	if (diff < 0) diff = diff * -1
	const res = Array(Math.ceil(diff))
		.fill()
		.map((_, i) => moment(dateStart).year() + i)
	return res
}
/**
 * возвращает массив годов, в которые действует контракт
 * @param {Date} date1 - дата заключения договора
 * @param {Date} date2 - дата окончания действия договора
 * @returns {Array<String>}
 */
export const getContractYears = (date1, date2) => {
	return Array(Math.ceil(moment().endOf('year').diff(moment(date1), 'year', true)))
		.fill()
		.map((_, i) => moment(date1).year() + i)
}

/**
 * рассчитать помесячное распределение квоты для всего срока действия контракта
 * @param {Date} date1 - дата заключения договора
 * @param {Date} date2 - дата окончания действия договора
 * @param {number} quota - поле квота
 * @typedef {Object} MonthlyListing
 * @property {number} montNum - номер месяца [0..11]
 * @property {number} plan -план на месяц
 * @property {number} fact - факт на месяц
 * @property {string} year - год
 *
 * @returns {Array<MonthlyListing>}
 */
export const genMonthlyListing = (date1, date2, quota, listing = []) => {
	const momentDate1 = moment(date1)
	const momentDate2 = getValidDate(date2)
	let contractDurationInMonths = moment(momentDate2).diff(moment(momentDate1), 'months', true)
	let contractMonthsCount = Math.ceil(
		moment(momentDate2).startOf('month').diff(moment(momentDate1).startOf('month'), 'months', true)
	)

	if (contractDurationInMonths <= 1) {
		if (momentDate1.month() === momentDate2.month()) {
			return [
				{
					montNum: moment(momentDate1).month(),
					plan: quota,
					fact: listing
						? listing.find(
								(l) =>
									l.montNum === moment(date1).month() &&
									String(l.year) === String(moment(date1).year())
						  )?.fact
						: 0,
					year: moment(momentDate1).year(),
				},
			]
		}
	}
	// если у нас дни в последнем месяце не единица, то нужно прибавить доп 1 месяц
	if (momentDate2.date() !== 1 /* && Number.isInteger(contractDurationInMonths) */) {
		contractDurationInMonths = contractDurationInMonths + 1
		contractMonthsCount = contractMonthsCount + 1
	}
	// котракт меньше месяца
	if (contractDurationInMonths < 1) {
		// сроки контракта разбиты на 2 разных месяца
		if (contractMonthsCount === 2) {
			const days1Month = moment(momentDate1).endOf('month').diff(moment(momentDate1), 'day') + 1
			const days2Month = moment(momentDate2).diff(moment(momentDate2).startOf('month'), 'day') + 1
			const allDays = days1Month + days2Month
			return [
				{
					montNum: moment(momentDate1).month(),
					plan: (days1Month / allDays) * quota,
					fact: listing
						? listing.find(
								(l) =>
									l.montNum === moment(date1).month() &&
									String(l.year) === String(moment(date1).year())
						  )?.fact
						: 0,
					year: moment(momentDate1).year(),
				},
				{
					montNum: moment(momentDate1).add(1, 'month').month(),
					plan: (days2Month / allDays) * quota,
					fact:
						listing.find(
							(l) =>
								l.montNum === moment(date2).month() &&
								String(l.year) === String(moment(date2).year())
						)?.fact || 0,
					year: moment(momentDate1).add(1, 'month').year(),
				},
			]
		}
	}
	const quotaMonthly = quota / contractDurationInMonths
	const arr = Array(contractMonthsCount).fill()
	const result = arr.map((el, i) => {
		const year = moment(momentDate1).add(i, 'month').year()
		const month = moment(momentDate1).add(i, 'month').month()

		let plan
		const isStartMonth =
			moment(momentDate1).year() === year && moment(momentDate1).month() === month
		const isEndMonth = moment(momentDate2).year() === year && moment(momentDate2).month() === month
		const isFullMonth = !isStartMonth && !isEndMonth
		let coef
		if (isFullMonth) {
			coef = 1
		} else if (isStartMonth) {
			const days = moment(momentDate1).endOf('month').diff(moment(momentDate1), 'day') + 1
			coef = days / moment(momentDate1).daysInMonth()
		} else if (isEndMonth) {
			const days = moment(momentDate2).diff(moment(momentDate2).startOf('month'), 'day')
			coef = days / moment(momentDate2).daysInMonth()
		}
		plan = Math.round(quotaMonthly * coef * 10000) / 10000
		return {
			montNum: month,
			plan,
			fact: listing
				? listing.find((l) => l.montNum === month && String(l.year) === String(year))?.fact
				: 0,
			year,
			id: el?.id,
			_uuid_: el?._uuid_,
		}
	})
	// TODO научить без погрешностей сразу распределеять плановую квоту
	// тут происходит распределение нераспределенного остатка епо всем месяцам
	// поровну, далее ищется новый остаток и он добавляется
	// последнему месяцу в распределении
	if (result.length > 1) {
		const sumDiffQuota = getPlanSumDiffQuota({ quota: quota, monthlyListings: result })
		const forEveryMonthRemainder = Math.round((sumDiffQuota / result.length) * 10000) / 10000
		result.forEach((m) => {
			m.plan = m.plan + forEveryMonthRemainder
		})
		const afterRemainderSumDiffQuota = getPlanSumDiffQuota({
			quota: quota,
			monthlyListings: result,
		})
		if (afterRemainderSumDiffQuota < 0) {
			result[result.length - 1].plan =
				result[result.length - 1].plan - -1 * afterRemainderSumDiffQuota - 0.0001
		}
		if (afterRemainderSumDiffQuota > 0) {
			result[result.length - 1].plan = result[result.length - 1].plan + afterRemainderSumDiffQuota
		}
	}
	result.forEach((m) => {
		m.plan = Math.round(m.plan * 10000) / 10000
	})
	return result
}

/**
 * подготавливает данные для вывода в таблице распределение квоты
 * @typedef {Object} Year
 * @property {number} provProdId - Id продукта
 * @property {number} year - выбранный год
 * @param {Array<Quota>} quotas - массив квот
 * @param {number | Array<Year> } year - год
 */
export const prepareData = (data, years = {}) =>
	data.reduce((acc, curQuota) => {
		return [
			...acc,
			{
				...curQuota.monthlyListings.reduce((acc, curListing) => {
					const year = years[curQuota.id] || years[curQuota._uuid_] || moment().year()
					return +curListing.year === +year ? { ...acc, [curListing['montNum']]: curListing } : acc
				}, curQuota),
			},
			{
				...curQuota.monthlyListings.reduce((acc, curListing) => {
					const year = years[curQuota.id] || years[curQuota._uuid_] || moment().year()
					return +curListing.year === +year ? { ...acc, [curListing['montNum']]: curListing } : acc
				}, curQuota),
			},
		]
	}, [])

/**
 * подготавливает данные для вывода в таблице распределение квоты
 * @typedef {Object} Year
 * @property {number} provProdId - Id продукта
 * @property {number} year - выбранный год
 * @param {Array<Quota>} quotas - массив квот
 * @param {number | Array<Year> } year - год
 */
export const prepareDataV2 = (inArr) => {
	const result = inArr.reduce((acc, cur) => {
		const monthlyListingFilteredByYear =
			cur['monthlyListings']?.filter((e) => {
				return +e.year === cur.year
			}) || []
		const monthlyListingObject = {}
		monthlyListingFilteredByYear.forEach((e) => {
			monthlyListingObject[`month${e.montNum}`] = e
		})
		return [
			...acc,
			{ ...cur, monthlyListing: monthlyListingObject, isPlan: true, isDiplayedRowBySpan: true },
			{ ...cur, monthlyListing: monthlyListingObject, isFact: true },
		]
	}, [])
	return result
}
export const addProdLabel = (inArr) => {
	inArr.forEach((e) => {})
}

export const renderPlanFactCellV2 = (
	montlyListing,
	record,
	mainCtx,
	modCtx,
	provOrCustKey,
	componentKey,
	options = {}
) => {
	const isDisabled = modCtx?.mod === modes.view
	if (montlyListing === undefined) {
		return <Space key={`SpaveVal1${componentKey}`}>-</Space>
	}
	const message = mainCtx.formErrors?.[montlyListing._uuid_] || mainCtx.formErrors?.[record._uuid_]
	const value = numToFixed(record.isFact ? montlyListing.fact : montlyListing.plan, 2)
	if (record.isFact)
		return <Space key={`SpaveVal2${montlyListing._uuid_}`}>{value ? String(value) : '0'}</Space>
	if (options.onlyView) {
		return <Space key={`SpaveValPlan${montlyListing._uuid_}`}>{value}</Space>
	}
	return (
		<InputError
			hasError={!!message}
			min={0}
			key={`Input${montlyListing._uuid_}`}
			className="ant-input-number-without-padding"
			max={record.quota}
			disabled={isDisabled}
			style={{ width: '100%' }}
			value={value}
			onChange={(e) => {
				if (!isValidNum(e.target.value)) return
				if (!isValidNumberDigits(e.target.value, 'float', [9, 2])) return
				mainCtx.stateFunctions[provOrCustKey].monthlyListings.fieldSetters.setPlan(
					{ quotaUuid: record._uuid_, monthUuid: montlyListing._uuid_ },
					e.target.value
				)
			}}
		/>
	)
}

export const getQuotaColumns = (values, setters, isDisabled, years = {}, counteragent) => {
	let { dateStart } = values
	const { setPlan, setQuota, delRecord } = setters
	const renderPlanFactCell = (text, record, index, key) => {
		if (!text) {
			return <Space key={`SpaceEmpty${key}${record._uuid_}`}>-</Space>
		}
		const { plan, fact, _uuid_ } = text
		const month =
			Object.keys(record).find(
				(key) => record[key]?.['_uuid_'] && record[key]?.['_uuid_'] === _uuid_
			) || moment().month()
		const isContractMonth = moment([moment().year(), month]).isAfter(
			moment(dateStart).startOf('month'),
			'month',
			'[]'
		)
		const isFactRow = !!(index % 2)
		const value = numToFixed(isFactRow ? fact : plan, 2)
		if (isFactRow || !isContractMonth) {
			return <Space key={`spaceStatic${text._uuid_}`}>{value || 0}</Space>
		}
		return (
			<InputNumber
				min={0}
				disabled={isDisabled}
				max={record.quota}
				key={`inputStatic${text._uuid_}`}
				controls={false}
				style={{ width: 50, padding: 0 }}
				value={value}
				onChange={(value) => {
					setPlan({ quotaUuid: record._uuid_, monthUuid: _uuid_ }, value)
				}}
			/>
		)
	}
	return [
		{
			title: 'Квота, кг',
			key: 'quota',
			width: 100,
			render: (text, record, index) => (
				<InputNumber
					style={{ width: '100%' }}
					controls={false}
					value={record.quota}
					disabled={isDisabled}
					onChange={(value) => {
						setQuota(record._uuid_, value)
					}}
				/>
			),
			onCell: (record, index) => ({
				rowSpan: index % 2 ? 0 : 2,
			}),
		},
		{
			title: 'Остаток, кг',
			key: 'remainder',
			width: 100,
			render: (_, record, index) => numToFixed(getRemainder(record), 2),
			onCell: (record, index) => ({
				rowSpan: index % 2 ? 0 : 2,
			}),
		},
		{
			title: null,
			key: 'monthlyStart',
			render: (_, record, index) => (index % 2 ? 'Ф' : 'П'),
		},
		{
			title: 'Янв',
			key: 'monthlyListingMonth0',
			dataIndex: 0,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '0')
			},
		},
		{
			title: 'Фев',
			key: 'monthlyListingMonth1',
			dataIndex: 1,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '1')
			},
		},
		{
			title: 'Мар',
			key: 'monthlyListingMonth2',
			dataIndex: 2,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '2')
			},
		},
		{
			title: 'Апр',
			key: 'monthlyListingMonth3',
			dataIndex: 3,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '3')
			},
		},
		{
			title: 'Май',
			key: 'monthlyListingMonth4',
			dataIndex: 4,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '4')
			},
		},
		{
			title: 'Июн',
			key: 'monthlyListingMonth5',
			dataIndex: 5,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '5')
			},
		},
		{
			title: 'Июл',
			key: 'monthlyListingMonth6',
			dataIndex: 6,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '6')
			},
		},
		{
			title: 'Авг',
			key: 'monthlyListingMonth7',
			dataIndex: 7,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '7')
			},
		},
		{
			title: 'Сен',
			key: 'monthlyListingMonth8',
			dataIndex: 8,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '8')
			},
		},
		{
			title: 'Окт',
			key: 'monthlyListingMonth9',
			dataIndex: 9,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '9')
			},
		},
		{
			title: 'Ноя',
			key: 'monthlyListingMonth10',
			dataIndex: 10,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '10')
			},
		},
		{
			title: 'Дек',
			key: 'monthlyListingMonth11',
			dataIndex: 11,
			render: (val, rec, indx) => {
				return renderPlanFactCell(val, rec, indx, '11')
			},
		},
		{
			title: null,
			key: 'action',
			width: 100,
			align: 'center',
			render: (text, record, index) => {
				const canDelete = record
					? Object.keys(record).some(
							(key) => record[key] && record[key].montNum !== undefined && !!record[key].fact
					  )
					: false
				return canDelete ? (
					<RecordInfoItemDelBtn
						delFun={() => {
							delRecord(record._uuid_)
						}}
					/>
				) : null
			},
			onCell: (record, index) => ({ rowSpan: index % 2 ? 0 : 2 }),
		},
	]
}

/**
 * данные для тултипа у квоты
 * @param {Object } inData
 */
export const getPlanSumDiffQuota = (inData) => {
	if (!basicValidator(inData)) throw Error('неправильный параметр')
	const planSum =
		inData.monthlyListings?.reduce((acc, cur) => {
			let plan = 0
			if (basicValidator(cur.plan) && isValidNum(cur.plan)) {
				plan = +cur.plan
			}
			return acc + plan
		}, 0) || 0
	let quota = 0
	if (basicValidator(inData.quota) && isValidNum(inData.quota)) {
		quota = +inData.quota
	}
	if (inData.monthlyListings.every((el) => !el.plan)) return quota
	const result = Math.round(10000 * (quota - planSum)) / 10000
	// TODO: сумма планов не может быть больше квоты
	return result
}
