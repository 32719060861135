import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import { ModContext, FileContext, UserDataContext } from '../../../../../contexts'
import {
	getCommonProviderFunctions,
	getCommonProviderModalFunctions,
} from '../../../../../utils/helpers/generators'
import {
	prepareObjFromServer,
	modes,
	appendFiles,
	validateRequired,
	useItemFetchers,
	axios,
} from '../../../../../utils'
import { basicValidator } from '@berry/common-functions/validators'
import { message } from 'antd'

import { addFile as addNewFile } from '../../../../../utils/helpers/for-files'
import { useHistory } from 'react-router-dom'

const dataUrl = '/stock/semifs'
const tabs = {
	'event-histories': 'eventHistories',
	storages: 'storages',
	costs: 'costs',
}

export const reducer = (state) => {
	return {
		...state,
	}
}

const initialData = {
	storages: [],
	eventHistories: [],
	costs: [],
	parents: [],
	__notFound: false,
}

const StockSemifItemMainContext = React.createContext()
StockSemifItemMainContext.displayName = 'StockSemifItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const fileCtx = useContext(FileContext)
	const {
		state: { isDevAdmin },
	} = useContext(UserDataContext)

	const modCtx = useContext(ModContext)
	const [state, dispatch] = React.useReducer(reducer, {
		data: initialData,
		oldData: initialData,
		additional: {
			allSelectBlockCauses: [],
			allSelectProdCats: [],
			allSelectSpecs: [],
			allSelectsProdTasks: [],
			allSemifs: [],
		},
		formErrors: [],
		isInitializedMain: false,
		isInitializedAdditional: false,
		tabsLoading: {
			parents: false,
			eventHistories: false,
			storages: false,
		},
		peresort: { __isOpen: false },
	})
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))

	const requiredFields = {
		storages: ['status'],
	}

	const blockCauseHasError = (storage) =>
		['Удержано', 'Заблокировано'].includes(storage.status) && !storage.blockCauses.length

	const validate = () => {
		for (const obj in requiredFields) {
			for (const el of stateRef.current.data[obj]) {
				if (requiredFields[obj]?.some((f) => !basicValidator(el[f]))) return 'error'
			}
		}
		if (stateRef.current.data.storages.some(blockCauseHasError)) {
			for (const storage of stateRef.current.data.storages) {
				if (blockCauseHasError(storage)) setError([], [], `blockCause.${storage._uuid_}`, true)
			}
			return 'Не заполнены обязательные поля'
		}
		return ''
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			formErrors: {},
			peresort: {
				__isOpen: false,
			},
		})
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * подготавливает данные которые нужно выбират
	 */

	const selectors = {
		storages: {
			blockCause: stateRef.current.additional.allSelectBlockCauses.filter(
				(bc) => bc.status === 'Действующая'
			),
			status: [
				{ label: 'Допущено' },
				{ label: 'Заблокировано' },
				{ label: 'Удержано' },
				...(isDevAdmin ? [{ label: 'Сработано' }] : []),
			],
		},
		labIndics: {
			factText: ['C', 'NC'],
		},
		peresort: {
			prodCat: stateRef.current.additional.allSelectProdCats,
			prodCatKindRawMat:
				stateRef.current.additional?.allSelectProdCats.find(
					(e) => String(e.id) === String(stateRef.current.peresort.prodCat?.id)
				)?.kindRawMats || [],
			spec: stateRef.current.additional.allSelectSpecs.filter((e) => {
				if (e.vocProdType?.label !== 'Сырье') return false
				if (String(stateRef.current.peresort.prodCatKindRawMat?.id) !== String(e.kindRawMat?.id))
					return false
				if (e.status !== 'Утверждено') return false
				return true
			}),
		},
	}

	const { getEditedData, commonDeepFieldUpdate, isEdited, stateFunctions, serverDelete, setError } =
		getCommonProviderFunctions(
			stateRef,
			stateRef.current.oldData,
			executeDispatch,
			{ modCtx, dataUrl, params, pageUrl: '/stock/semif', history },
			selectors,
			{},
			{
				labIndics: {
					factVal: 'common',
					factText: 'common',
				},
				storages: {
					blockCause: 'common',
					blockCauses: {
						blockCause: 'common',
					},
				},
			}
		)
	stateFunctions.storages.setStatus = (inUuid, val) => {
		if (val === 'Допущено') {
			commonDeepFieldUpdate(['storages'], [inUuid], 'blockCauses', [])
		}
		commonDeepFieldUpdate(['storages'], [inUuid], 'status', val)
	}

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		selectors,
		[
			{
				field: 'prodCat',
				updateVal: 'obj',
				modalName: 'peresort',
			},
			{ field: 'prodCatKindRawMat', updateVal: 'obj', modalName: 'peresort' },
			{ field: 'spec', updateVal: 'obj', modalName: 'peresort' },
			{ field: 'weight', updateVal: 'common', modalName: 'peresort' },
		]
	)

	stateFunctions.labIndics.addPhoto = (inUuid, vals) => {
		addNewFile(vals, 'photoPath', 'lab_ind')
		commonDeepFieldUpdate(['ctrlLabIndicators'], [inUuid], 'photos', vals)
		fileCtx.addFiles(lodash.cloneDeep(vals), 'photoPath')
	}
	stateFunctions.labIndics.removePhoto = (inUuid, photo) => {
		let newFiles = stateFunctions.ctrlLabIndicators.get(inUuid)?.photos.filter((p) => {
			return photo.photoPath
				? p.photoPath !== photo.photoPath
				: p.photoPath !== photo.originFileObj?.photoPath
		})
		commonDeepFieldUpdate(['ctrlLabIndicators'], [inUuid], 'photos', newFiles)
	}
	stateFunctions.storages.setBlockCause = (inUuid, val) => {
		commonDeepFieldUpdate(['storages'], [inUuid], 'blockCause', val)
		if (!['Допущено', 'Сработано'].includes(stateFunctions.storages.get(inUuid)?.status)) {
			validateRequired(setError, `blockCause.${inUuid}`, undefined, val)
		} else {
			setError([], [], `blockCause.${inUuid}`, false)
		}
	}
	const serverEdit = async () => {
		try {
			const formData = appendFiles(
				stateRef.current.data.ctrlLabIndicators,
				'photos',
				'_uuid_',
				'lab_ind',
				{
					key: 'photoPath',
				}
			)
			let body
			let options = { url: dataUrl }
			if (params.id === 'new') {
				body = lodash.cloneDeep(stateRef.current.data)
				options.method = 'POST'
			} else {
				body = getEditedData()
				options.method = 'PUT'
			}
			formData.append('data', JSON.stringify(body))
			executeDispatch({ ...stateRef.current, isPendingReq: true })
			const res = (await axios[options.method.toLowerCase()](dataUrl, body))?.data || {}
			executeDispatch({ ...stateRef.current, isPendingReq: false })
			if (res?.data?.id) {
				if (options.method === 'POST') {
					executeDispatch({
						...stateRef.current,
						data: {
							...stateRef.current.data,
							id: res.data.id,
						},
						oldData: lodash.cloneDeep({
							...stateRef.current.data,
							id: res.data.id,
						}),
					})
				}
			}
			modCtx.set(modes.view)

			return { method: options.method, id: res?.data.id }
		} catch (err) {
			message.error('Ошибка при сохранении')
			console.log(err.message)
		}
	}

	const value = {
		state: lodash.cloneDeep(stateRef.current.data),
		additional: stateRef.current.additional,
		tabsLoading: stateRef.current.tabsLoading,
		isPendingReq: stateRef.current.isPendingReq,
		stateFunctions,
		serverEdit,
		serverDelete,
		isEdited,
		validate,
		reset,
		formErrors: stateRef.current.formErrors,
		selectors,
		setError,
		modalFunctions,
		peresort: lodash.cloneDeep(stateRef.current.peresort),
	}

	return (
		<StockSemifItemMainContext.Provider value={value}>
			{children}
		</StockSemifItemMainContext.Provider>
	)
}

export { Provider, StockSemifItemMainContext }
