import React from 'react'
import { Table } from 'antd'
import CustomExpandIcon from './CustomExpandIcon'

const ExpandableTable = ({ dataSource = [], columns = [] }) => (
	<Table
		size="small"
		rowKey="label"
		rowClassName="clickable"
		columns={columns}
		dataSource={dataSource}
		pagination={false}
		scroll={{ x: 800 }}
		expandIcon={(props) => <CustomExpandIcon {...props} />}
		expandable={{ defaultExpandAllRows: true }}
	/>
)

export default ExpandableTable
