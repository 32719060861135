import React from 'react'
import { Form as FormAntd, Input, Select } from 'antd'
import { getProdCatKindRawMatLabel } from '@berry/common-functions/cross-project-functions'
import { forSearchInFieldProps } from '../../../utils'
import { isValidNum } from '@berry/common-functions/validators'

const formItemLayout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

const Form = (props) => {
	const { shelfLife, prodCatKindRawMat, vocMeasure, prodCat, vocMeasures, state } = props

	return (
		<FormAntd labelAlign="left">
			<FormAntd.Item
				{...formItemLayout}
				label="Продукт"
				getValueProps={(value) => value}
				name="prodCatKindRawMat"
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Select
					{...forSearchInFieldProps}
					value={
						prodCatKindRawMat.value?.productVal
							? prodCatKindRawMat.value.productVal
							: prodCatKindRawMat.value?.id
					}
					onChange={(e) => {
						prodCatKindRawMat.onChange(e)
					}}
					style={{ width: '100%', minWidth: '120px' }}
				>
					{prodCat
						.map((p) => {
							p.kindRawMats.forEach((k) => (k.label = p.label))
							return p.kindRawMats
						})
						.flat()
						.filter((el) => !state.some((i) => i.prodCatKindRawMat.id === el.id))
						.sort((a, b) =>
							`${a.label} ${getProdCatKindRawMatLabel(a)}`.localeCompare(
								`${b.label} ${getProdCatKindRawMatLabel(b)}`
							)
						)
						.map((it) => {
							return (
								<Select.Option key={it.id} value={it.id}>
									{`${it.label} ${getProdCatKindRawMatLabel(it)}`}
								</Select.Option>
							)
						}) || []}
				</Select>
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Срок годности"
				getValueProps={(value) => value}
				name="shelfLife"
				onChange={(e) => {
					if (isValidNum(e.target.value)) {
						shelfLife.onChange(e.target.value)
					}
				}}
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Input value={shelfLife.value} />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="ЕИ"
				getValueProps={(value) => value}
				name="vocMeasure"
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Select
					value={vocMeasure.value}
					onChange={vocMeasure.onChange}
					style={{ width: '100%', minWidth: '120px' }}
				>
					{vocMeasures
						.filter((el) => el.id === 7 || el.id === 8)
						.map((it) => {
							return (
								<Select.Option key={it.id} value={it.id}>
									{it.labelShort}
								</Select.Option>
							)
						}) || []}
				</Select>
			</FormAntd.Item>
		</FormAntd>
	)
}

export default Form
