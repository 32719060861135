import React, { useContext } from 'react'
import { ConsolidatedReportItemMainContext } from '../provider/main'
import { getDisplayColumns } from './report-tab'
import { LocalTable, TabItem } from '../../../../components'

const ReportTab = () => {
	const mainCtx = useContext(ConsolidatedReportItemMainContext)
	return (
		<TabItem
			addButton={{ show: true }}
			table={() => {
				return (
					<LocalTable
						size="small"
						rowKey="_uuid_"
						getColumns={getDisplayColumns}
						dataSource={mainCtx.state.reports}
						pagination={false}
						onRow={() => {}}
					/>
				)
			}}
		/>
	)
}

export default ReportTab
