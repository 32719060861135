import { Button, Checkbox, Table, Card, Row, Col, Typography } from 'antd'
import React, { useContext } from 'react'
import { ProductionTaskItemMainContext } from '../provider/main'
import { TabItem, showConfirmModal, SelectModal, TableWithListLoader } from '../../../../components'
import { AuthContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns } from './complectation-tab'
import { getColumns as getRawMatColumns } from '../../../Stock/RawMat/RawMatList/raw-mat-list'
import { getColumns as getSemifColumns } from '../../../Stock/Semif/SemifList/semif-list'
import { getColumns as getReadyProdColumns } from '../../../Stock/ReadyProd/ReadyProdList/ready-prod-list'
import { getColumns as getReadyProdResaleColumns } from '../../../Stock/ReadyProdResale/ReadyProdResaleList/ready-prod-resale-list'
import moment from 'moment'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { capitalizeFirstLetter } from '@berry/common-functions/text-functions'

const ComplectationTab = () => {
	const mainCtx = useContext(ProductionTaskItemMainContext)
	let stateData = mainCtx.state.complectations
	const modCtx = useContext(ModContext)

	const authCtx = useContext(AuthContext)
	const addComplectName = [
		`addComplectStock${capitalizeFirstLetter(mainCtx.addComplect.__name)}Stor`,
	]
	const totalSum = mainCtx.addComplect[addComplectName]?.reduce((sum, cur) => {
		return sum + cur.weight
	}, 0)

	const isDisableAddBtn = () => {
		return (
			modCtx.mod === modes.view ||
			!mainCtx.state.prodCat ||
			!mainCtx.state.date ||
			(!mainCtx.state.prodCatPkg && !mainCtx.state.prodCatKindSemif) ||
			!mainCtx.state.taskKind ||
			mainCtx.isDisabledByChangeStatusBtn
		)
	}

	return (
		<TabItem
			modal={() => {
				return (
					<SelectModal
						visible={mainCtx.addComplect.__isOpen}
						additionalButtons={[
							[
								{ key: 'rawMat', name: 'Сырье' },
								{ key: 'semif', name: 'Полуфабрикаты' },
								{ key: 'readyProd', name: 'Готовая продукция' },
								{ key: 'readyProdResale', name: ' Готовая продукция на ПП' },
							].map((el) => {
								return (
									<Button
										key={el.key}
										type={mainCtx.addComplect.__name === el.key && 'primary'}
										style={{ marginRight: '20px' }}
										onClick={() => {
											mainCtx.modalFunctions.resetAddComplect()
											mainCtx.modalFunctions.openAddComplect()
											mainCtx.modalFunctions.setAddComplect__name(el.key)
										}}
									>
										{el.name}
									</Button>
								)
							}),
						]}
						content={
							<Card>
								<Card>
									<Row justify="end">
										<Col>
											<Typography.Text strong>
												Общая масса, кг: {numToFixed(totalSum, 1)}
											</Typography.Text>
										</Col>
									</Row>
								</Card>
								<TableForSelectComplect
									mainCtx={mainCtx}
									tabName={mainCtx.addComplect.__name}
								></TableForSelectComplect>
							</Card>
						}
						onOk={() => {
							if (!mainCtx.addComplect[addComplectName].length) {
								showConfirmModal({
									title: 'Не выбрана партия',
									showCancel: false,
									okText: 'Ок',
								})
								return
							}
							if (mainCtx.addComplect[addComplectName].length) {
								mainCtx.stateFunctions.complectations.create(
									[...mainCtx.addComplect[addComplectName]],
									`stock${capitalizeFirstLetter(mainCtx.addComplect.__name)}Stor`
								)
								if (mainCtx.state.isSavePartyNum) {
									if (mainCtx.addComplect.__name === 'rawMat')
										mainCtx.stateFunctions.setPartyNum(
											mainCtx.addComplect[addComplectName][0].partyNum
										)
									if (mainCtx.addComplect.__name === 'semif')
										mainCtx.stateFunctions.setPartyNum(
											mainCtx.addComplect[addComplectName][0].partyNum
										)
									if (mainCtx.addComplect.__name === 'readyProd')
										mainCtx.stateFunctions.setPartyNum(
											mainCtx.addComplect[addComplectName][0].partyNum
										)
									if (mainCtx.addComplect.__name === 'readyProdResale')
										mainCtx.stateFunctions.setPartyNum(
											mainCtx.addComplect[addComplectName][0].partyNum
										)
								}
							}
							mainCtx.modalFunctions.resetAddComplect()
						}}
						onClose={() => {
							mainCtx.modalFunctions.resetAddComplect()
						}}
					/>
				)
			}}
			addButton={{
				disabled: isDisableAddBtn(),
				show:
					mainCtx.state.complectations?.length === 1 && mainCtx.state.isSavePartyNum ? false : true,
				onClick: () => {
					mainCtx.modalFunctions.openAddComplect()
					mainCtx.modalFunctions.setAddComplect__name('rawMat')
				},
			}}
			table={() => {
				return (
					<Table
						rowKey="_uuid_"
						size="small"
						loading={mainCtx.tabsLoading.complectations}
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(mainCtx, modCtx, authCtx)}
						dataSource={stateData}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)
			}}
		></TabItem>
	)
}

const TableForSelectComplect = ({ mainCtx, tabName }) => {
	let url = null
	let calcColumns = null
	let filters = {}
	let defaultSorter = null
	let permanentFilters = {}
	const idContrOrder =
		mainCtx.state.complectations[0]?.stockRawMatStor?.idContrOrder ||
		mainCtx.state.complectations[0]?.stockSemifStor?.idContrOrder ||
		mainCtx.state.complectations[0]?.stockReadyProdStor?.idContrOrder
	switch (tabName) {
		case 'rawMat':
			url = '/stock/raw-mats'
			calcColumns = (props) => {
				const columns = getRawMatColumns(props).slice(0, -1)
				columns.forEach((el) => {
					if (el.key === 'idOrder') el.width = '5%'
					if (el.key === 'defrostCount') el.width = '3%'
					if (el.key === 'assignedPartyNum') el.width = '6%'
					if (el.key === 'pkgType') el.width = '4%'
					if (el.key === 'prodCat') el.width = '6%'
					if (el.key === 'sellBy') el.width = '5%'
					if (el.key === 'quality') el.width = '8%'
					if (el.key === 'provider') el.width = '10%'
					if (el.key === 'manuf') el.width = '7%'
					return el
				})
				return [
					getCheckboxColumn({
						mainCtx,
						tabName,
					}),
					...columns,
				]
			}
			filters = {
				defrostCount: [],
				idContrOrder: [],
				assignedPartyNum: [],
				displayCode: [],
				vendorCode: [],
				prodCat: [],
				prodCatKind: [],
				quality: [],
				pkgType: [],
				nettoPkgWeight: [],
				provider: [],
				manuf: [],
				roomNum: [],
				status: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					roomType: ['Внутренний'],
					status: ['Допущено', 'Дефростация'],
					idProduct: [mainCtx.state.prodCat.id],
				},
				exclude: {
					idStorage: [...mainCtx.state.complectations, ...mainCtx.deletedProds]
						.filter((p) => p.idStockRawMatStor || p.stockRawMatStor)
						.flatMap((p) => [
							p.stockRawMatStor?.idStorage || p.idStockRawMatStor,
							p.stockRawMatStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				date: moment(mainCtx.state.date).format('YYYY-MM-DD'),
			}
			if (idContrOrder) {
				permanentFilters.include.idContrOrder = [idContrOrder]
			}
			if (mainCtx.state.complectations.length) {
				permanentFilters.exclude.idStorage = mainCtx.state.complectations
					.flatMap((e) => [e.stockRawMatStor?.idStorage, e.stockRawMatStor?.parent?.id])
					.filter((e) => e)
			}

			break
		case 'semif':
			url = '/stock/semifs'
			calcColumns = (props) => {
				const columns = getSemifColumns(props)
				return [
					getCheckboxColumn({
						mainCtx,
						tabName,
					}),
					...columns,
				]
			}
			filters = {
				idContrOrder: [],
				partyNum: [],
				articul: [],
				articul1C: [],
				prodCat: [],
				prodCatKind: [],
				pkgType: [],
				nettoPkgWeight: [],
				roomNum: [],
				status: [],
				_1c: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					roomType: ['Внутренний'],
					status: ['Допущено', 'Дефростация'],
					idProduct: [mainCtx.state.prodCat.id],
				},
				exclude: {
					idStorage: [...mainCtx.state.complectations, ...mainCtx.deletedProds]
						.filter((p) => p.idStockSemifStor || p.stockSemifStor)
						.flatMap((p) => [
							p.stockSemifStor?.idStorage || p.idStockSemifStor,
							p.stockSemifStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				date: mainCtx.state?.date || null,
			}
			if (idContrOrder) {
				permanentFilters.include.idContrOrder = [idContrOrder]
			}
			if (mainCtx.state.complectations.length) {
				permanentFilters.exclude.idStorage = mainCtx.state.complectations
					.flatMap((e) => [e.stockSemifStor?.idStorage, e.stockSemifStor?.parent?.id])
					.filter((e) => e)
			}

			break
		case 'readyProd':
			url = '/stock/ready-prods'
			calcColumns = (props) => {
				const columns = getReadyProdColumns(props)
				columns.forEach((rp) => {
					if (rp.key === 'idOrder') rp.width = '6.03%'
					if (rp.key === 'fabricatedNumVal') rp.width = '7.61%'
					if (rp.key === 'prodCat') rp.width = '6.32%'
					if (rp.key === 'quality') rp.width = '6.56%'
					if (rp.key === 'pkgType') rp.width = '5.29%'
					if (rp.key === 'status') rp.width = '6.62%'
					if (rp.key === 'prodCatKind') rp.width = '9.33%'
					return rp
				})
				return [
					getCheckboxColumn({
						mainCtx,
						tabName,
					}),
					...columns,
				]
			}
			filters = {
				idContrOrder: [],
				partyNum: [],
				fabricatedNumVal: [],
				articul: [],
				articul1C: [],
				prodCat: [],
				prodCatKind: [],
				roomNum: [],
				customer: [],
				status: [],
				_1c: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					roomType: ['Внутренний'],
					status: ['Допущено', 'Дефростация', 'Удержано', 'Заблокировано'],
					idProduct: [mainCtx.state.prodCat.id],
				},
				exclude: {
					idStorage: [...mainCtx.state.complectations, ...mainCtx.deletedProds]
						.filter((p) => p.idStockReadyProdStor || p.stockReadyProdStor)
						.flatMap((p) => [
							p.stockReadyProdStor?.idStorage || p.idStockReadyProdStor,
							p.stockReadyProdStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				date: mainCtx.state?.date || null,
			}
			if (idContrOrder) {
				permanentFilters.include.idContrOrder = [idContrOrder]
			}
			if (mainCtx.state.complectations.length) {
				permanentFilters.exclude.idStorage = mainCtx.state.complectations
					.flatMap((e) => [e.stockReadyProdStor?.idStorage, e.stockReadyProdStor?.parent?.id])
					.filter((e) => e)
			}
			break
		case 'readyProdResale':
			url = '/stock/ready-prod-resales'
			calcColumns = (props) => {
				const columns = getReadyProdResaleColumns(props).slice(0, -1)
				columns.forEach((el) => {
					if (el.key === 'defrostCount') el.width = '3%'
					if (el.key === 'assignedPartyNum') el.width = '6%'
					if (el.key === 'pkgType') el.width = '4%'
					if (el.key === 'prodCat') el.width = '6%'
					if (el.key === 'sellBy') el.width = '5%'
					if (el.key === 'quality') el.width = '8%'
					if (el.key === 'provider') el.width = '10%'
					if (el.key === 'manuf') el.width = '7%'
					return el
				})
				return [
					getCheckboxColumn({
						mainCtx,
						tabName,
					}),
					...columns,
				]
			}
			filters = {
				defrostCount: [],
				idContrOrder: [],
				assignedPartyNum: [],
				displayCode: [],
				vendorCode: [],
				prodCat: [],
				prodCatKind: [],
				quality: [],
				pkgType: [],
				nettoPkgWeight: [],
				provider: [],
				manuf: [],
				roomNum: [],
				status: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					roomType: ['Внутренний'],
					status: ['Допущено', 'Дефростация', 'Удержано', 'Заблокировано'],
					idProduct: [mainCtx.state.prodCat.id],
				},
				exclude: {
					idStorage: [...mainCtx.state.complectations, ...mainCtx.deletedProds]
						.filter((p) => p.idStockReadyProdResaleStor || p.stockReadyProdResaleStor)
						.flatMap((p) => [
							p.stockReadyProdResaleStor?.idStorage || p.idStockReadyProdResaleStor,
							p.stockReadyProdResaleStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				date: moment(mainCtx.state.date).format('YYYY-MM-DD'),
			}
			if (mainCtx.state.complectations.length) {
				permanentFilters.exclude.idStorage = mainCtx.state.complectations
					.flatMap((e) => [
						e.stockReadyProdResaleStor?.idStockReadyProdResaleStor,
						e.stockReadyProdResaleStor?.parent?.id,
					])
					.filter((e) => e)
			}

			break

		default:
			throw Error('unknown tab opened')
	}

	return (
		<TableWithListLoader
			rowKey="_uuid_"
			url={url}
			calcColumns={calcColumns}
			filters={filters}
			defaultSorter={defaultSorter}
			permanentFilters={permanentFilters}
		></TableWithListLoader>
	)
}

const getCheckboxColumn = (props) => {
	const addComplectName = [`addComplectStock${capitalizeFirstLetter(props.tabName)}Stor`]
	return {
		title: '',
		key: 'checkbox',
		width: '2%',
		render: (_, record) => {
			return (
				<Checkbox
					disabled={props.mainCtx.isModalItemDisabled(record)}
					checked={props.mainCtx.addComplect[addComplectName]?.some(
						(el) => String(el.idStorage) === String(record.idStorage)
					)}
					onChange={(e) => {
						const val = e.target.checked
							? record
							: props.mainCtx.addComplect[addComplectName].find(
									(s) => s.idStorage === record.idStorage
							  )

						if (val._uuid_) {
							props.mainCtx.modalFunctions[addComplectName].delete(val._uuid_)
						}
						if (!val._uuid_) {
							props.mainCtx.modalFunctions[addComplectName].create(val)
						}
					}}
				/>
			)
		},
	}
}
export default ComplectationTab
