import { Table } from 'antd'
import React, { useContext } from 'react'
import { ConsolidatedReportItemMainContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { getDisplayColumns } from './event-history-tab'

const EventHistoryTab = () => {
	const mainCtx = useContext(ConsolidatedReportItemMainContext)
	const columns = getDisplayColumns()
	return (
		<TabItem
			addButton={{ show: false }}
			table={() => (
				<Table
					size="small"
					rowKey="_uuid_"
					columns={columns}
					dataSource={mainCtx.state.eventHistories}
					pagination={false}
					scroll={{ x: 800 }}
				/>
			)}
		/>
	)
}

export default EventHistoryTab
