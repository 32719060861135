import React from 'react'
import { Input, Checkbox } from 'antd'
import { modes } from '../../../utils'
import { ColourPicker, CommonSelect, Status } from '../../../components/'
import { parseHex, parseRGBA } from '../../../utils/helpers/colourConvert'
import { AdditionalAttrTab } from './AdditionalAttrTab/AdditionalAttrTab'

import KindRawMatTab from './KindRawMatTab/KindRawMatTab'
import KindReadyProdTab from './KindReadyProdTab/KindReadyProdTab'
import SpecRawMatTab from './SpecRawMatTab/SpecRawMatTab'
import { CaliberTab } from './CaliberTab/CaliberTab'
import { KindSemifTab } from './KindSemifTab/KindSemifTab'
import { isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'
import { PackageTab } from './PackageTab/PackageTab'
import { testAtLeastSymbolRegExp, testAtLeastLatinRegExp } from '../../../utils/helpers/reg-exps'
import SpecReadyProdTab from './SpecReadyProdTab/SpecReadyProdTab'
import { setNumField } from '@berry/front-common/for-fields'
import { QualityTab } from './QualityTab/QualityTab'

export const getRawFormItems = (mainCtx, modCtx, menuCtx) => {
	const isDisabled = modCtx.mod === modes.view

	const getLayout = (layoutName) => {
		return menuCtx.position === 'left' ? layouts[layoutName] : layouts[`${layoutName}TopMenu`]
	}

	return [
		[
			{
				layout: getLayout('layout'),
				label: 'Наименование',
				name: 'label',
				rules: [{ required: true }],
				required: true,
				field: (
					<Input
						value={mainCtx.state.label}
						disabled={isDisabled || mainCtx.hasDeps}
						onChange={(e) => {
							if (
								e.target.value.length > 43 ||
								testAtLeastSymbolRegExp(e.target.value) ||
								testAtLeastLatinRegExp(e.target.value)
							) {
								mainCtx.stateFunctions.setLabel(mainCtx.state.label)
								return
							}
							mainCtx.stateFunctions.setLabel(e.target.value)
						}}
					/>
				),
			},
			{
				layout: getLayout('layout'),
				label: 'Краткое наименование',
				rules: [{ required: true }],
				name: 'labelShort',
				required: true,
				field: (
					<Input
						value={mainCtx.state.labelShort}
						disabled={isDisabled || mainCtx.hasDeps}
						onChange={(e) => {
							if (
								e.target.value.length > 4 ||
								testAtLeastSymbolRegExp(e.target.value) ||
								testAtLeastLatinRegExp(e.target.value)
							) {
								mainCtx.stateFunctions.setLabelShort(mainCtx.state.labelShort)
								return
							}
							mainCtx.stateFunctions.setLabelShort(e.target.value)
						}}
					/>
				),
			},
			{
				layout: getLayout('layout'),
				label: 'Группа',
				rules: [{ required: true }],
				name: 'vocProdGroup',
				required: true,
				field: (
					<CommonSelect
						value={mainCtx.state.vocProdGroup}
						setValue={mainCtx.stateFunctions.setVocProdGroup}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allVocProdGroups}
					/>
				),
			},
			{
				layout: getLayout('layout'),
				label: 'Артикул',
				field: mainCtx.state.displayCode,
			},
		],
		[
			{
				layout: getLayout('layout2'),
				label: '% выхода',
				name: 'outPercent',
				rules: [{ required: true }],
				required: true,
				field: (
					<Input
						value={mainCtx.state.outPercent}
						disabled={isDisabled}
						onChange={(e) => {
							if (
								!isValidNum(e.target.value) ||
								!isValidNumberDigits(e.target.value, 'float', [4, 2])
							) {
								mainCtx.stateFunctions.setOutPercent(mainCtx.state.outPercent)
								return
							}
							mainCtx.stateFunctions.setOutPercent(e.target.value)
						}}
					/>
				),
			},
			{
				layout: getLayout('layout2'),
				label: 'Цветовое обозначение',
				name: 'color',
				field: (
					<ColourPicker
						style={isDisabled ? { pointerEvents: 'none' } : { zIndex: 999999 }}
						colour={{
							value: parseHex(mainCtx.state.color),
							onChange: (color) => mainCtx.stateFunctions.setColor(parseRGBA(color)),
						}}
					/>
				),
			},
			{
				layout: getLayout('layout2'),
				label: 'Вес 1 паллеты, кг',
				name: 'onePalleta',
				rules: [{ required: true }],
				required: true,
				field: (
					<Input
						value={mainCtx.state.onePalleta}
						disabled={isDisabled}
						onChange={(e) => {
							if (
								!isValidNum(e.target.value) ||
								!isValidNumberDigits(e.target.value, 'int', [10, 0]) ||
								+e.target.value > 2147483647
							) {
								mainCtx.stateFunctions.setOnePalleta(mainCtx.state.onePalleta)
								return
							}
							mainCtx.stateFunctions.setOnePalleta(e.target.value)
						}}
					/>
				),
			},
			{
				layout: getLayout('layout2'),
				label: '% допустимого отклонения',
				name: 'deviation',
				required: true,
				rules: [{ required: true }],
				field: (
					<Input
						value={mainCtx.state.deviation}
						disabled={isDisabled}
						onChange={(e) => {
							setNumField(
								e.target.value,
								mainCtx.state.deviation,
								mainCtx.stateFunctions.setDeviation,
								'float',
								[4, 2]
							)
						}}
					/>
				),
			},
		],
		[
			{
				layout: getLayout('layout3'),
				label: 'Возможна калибровка',
				field: (
					<Checkbox
						disabled={isDisabled || mainCtx.state.calibers?.length}
						checked={mainCtx.state.isCalibration}
						onChange={(e) => mainCtx.stateFunctions.setIsCalibration(e.target.checked)}
					/>
				),
			},
			{
				layout: getLayout('layout3'),
				label: 'Возможен дополнительный признак',
				field: (
					<Checkbox
						disabled={isDisabled || mainCtx.state.additAttrs?.length}
						checked={mainCtx.state.isAdditAttr}
						onChange={(e) => mainCtx.stateFunctions.setIsAdditAttr(e.target.checked)}
					/>
				),
			},
			{
				layout: getLayout('layout3'),
				label: 'Возможна категоризация ПФ по качеству',
				field: (
					<Checkbox
						disabled={isDisabled || mainCtx.state.qualities?.length}
						checked={mainCtx.state.isKindPfByQuality}
						onChange={(e) => mainCtx.stateFunctions.setIsKindPfByQuality(e.target.checked)}
					/>
				),
			},

			{
				layout: getLayout('layout3'),
				label: 'Статус',
				field: <Status status={mainCtx.state.status} />,
			},
		],
	]
}

export const getInfoFormItems = (mainCtx) => {
	return [
		[
			{
				layout: layouts.layoutInfo,
				label: 'Наименование',
				field: mainCtx.state.label,
			},
			{
				layout: layouts.layoutInfo,
				label: 'Краткое наименование',
				field: mainCtx.state.labelShort,
			},
		],
		[
			{
				layout: layouts.layoutInfo2,
				label: 'Группа',
				field: mainCtx.state.vocProdGroup?.displayVal,
			},
			{
				layout: layouts.layoutInfo2,
				label: 'Артикул',
				field: mainCtx.state.displayCode,
			},
		],
		[
			{
				layout: layouts.layoutInfo3,
				label: 'Цветовое обозначение',
				field: (
					<ColourPicker
						style={{ pointerEvents: 'none' }}
						colour={{ value: parseHex(mainCtx.state.color) }}
					/>
				),
			},
			{
				layout: layouts.layoutInfo3,
				label: 'Статус',
				field: <Status status={mainCtx.state.status} />,
			},
		],
	]
}

/**
 * возвращает вкладки которые должны быть отображены в зависимости от выбранной основной вкладки
 * @param {(0|1|2)} mainCtx
 * @param {(0|1|2)} inMainTabKey - массив полей
 */
export const getMinorTabs = (mainCtx, inMainTabKey) => {
	switch (+inMainTabKey) {
		case 0:
			return [
				{ key: 0, title: 'Виды', component: <KindRawMatTab /> },
				{ key: 1, title: 'Спецификации', component: <SpecRawMatTab /> },
			]
		case 1:
			const result = []
			if (mainCtx.state.isKindPfByQuality) {
				result.push({ key: result.length, title: 'Качество', component: <QualityTab /> })
			}
			if (mainCtx.state.isCalibration) {
				result.push({ key: result.length, title: 'Калибр', component: <CaliberTab /> })
			}
			if (mainCtx.state.isAdditAttr) {
				result.push({ key: result.length, title: 'Доп. признак', component: <AdditionalAttrTab /> })
			}
			result.push({ key: result.length, title: 'Виды', component: <KindSemifTab /> })
			return result
		case 2:
			return [
				{ key: 0, title: 'Виды', component: <KindReadyProdTab /> },
				{ key: 1, title: 'Упаковка', component: <PackageTab /> },
				{ key: 2, title: 'Спецификации', component: <SpecReadyProdTab /> },
			]
		default:
			return []
	}
}

const layouts = {
	layout: {
		labelCol: { xs: 24, sm: 8, lg: 12 },
		wrapperCol: { xs: 24, sm: 14, lg: 12 },
	},

	layout2: {
		labelCol: { xs: 24, sm: 8, lg: { span: 12, offset: 2 }, xl: { span: 12, offset: 4 } },
		wrapperCol: { xs: 24, sm: 14, lg: 8 },
	},

	layout3: {
		labelCol: { xs: 24, sm: 8, lg: 12, xl: { span: 12, offset: 4 } },
		wrapperCol: { xs: 24, sm: 14, lg: 8 },
	},
	layoutTopMenu: {
		labelCol: { xs: 24, sm: 5, lg: 7 },
		wrapperCol: { xs: 24, sm: 14, lg: 12 },
	},
	layout2TopMenu: {
		labelCol: { xs: 24, sm: 8, lg: { span: 12, offset: 2 }, xl: { span: 12, offset: 5 } },
		wrapperCol: { xs: 24, sm: 14, lg: 8 },
	},
	layout3TopMenu: {
		labelCol: { xs: 24, sm: 8, lg: 12, xl: { span: 10, offset: 9 } },
		wrapperCol: { xs: 24, sm: 14, lg: 8 },
	},
	layoutInfo: {
		labelCol: { xs: 24, sm: 12, md: 10, xl: 10, xxl: 7 },
		wrapperCol: { xs: 24, sm: 12, md: 14, xl: 14, xxl: 11 },
	},
	layoutInfo2: {
		labelCol: { xs: 24, sm: 12, md: 10, xxl: { span: 5, offset: 6 } },
		wrapperCol: { xs: 24, sm: 12, md: 14, xxl: 16 },
	},
	layoutInfo3: {
		labelCol: { xs: 24, sm: 12, md: 10, xl: 10, xxl: { span: 7, offset: 11 } },
		wrapperCol: { xs: 24, sm: 12, md: 14, xl: 14, xxl: 12 },
	},
}
