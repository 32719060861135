import React from 'react'
import { Card, Typography, Button, Col, Row } from 'antd'
import { useHistory } from 'react-router-dom'

const NotFound = (props) => {
	const history = useHistory()
	return (
		<Card>
			<Row justify="center">
				<Col>
					<Typography.Title level={3}>Страница не найдена</Typography.Title>
				</Col>
			</Row>
			<Row justify="center">
				<Col>
					<Button size="large" type="link" onClick={() => history.push('/')}>
						В начало
					</Button>
				</Col>
			</Row>
		</Card>
	)
}

export default NotFound
