import React, { useContext, useState } from 'react'
import { Form, Input, Button, Row, Col, Layout } from 'antd'
import { Helmet } from 'react-helmet'
import { AuthContext } from '../../contexts/auth/auth'
import { useHistory } from 'react-router-dom'
import { isEmail } from '@berry/common-functions/validators'
import AuthError from '../../components/AuthError/AuthError'
import { isCyrillics } from '../ChangePassword/ChangePassword'
import Logo from './../../components/Logo/Logo'

const { Content } = Layout

const ForgotPassword = (props) => {
	const { forgotPwd } = useContext(AuthContext)

	const history = useHistory()
	const [email, setEmail] = useState('')
	const [error, setError] = useState('')

	const validateEmail = () => {
		if (email === '') {
			setError('Введите email')
			return
		}
		if (!isEmail(email)) {
			setError('Пользователь с данным адресом не найден')
			return
		}
	}
	let inputStyle = { width: '500px', height: '50px', borderRadius: '5px' }
	if (error) {
		inputStyle = { width: '500px', height: '50px', borderRadius: '5px', border: '1px solid red' }
	}
	const btnStyle = {
		fontWeight: 600,
		width: '500px',
		height: '50px',
		alignSelf: 'center',
		borderRadius: '5px',
	}

	return (
		<Layout>
			<Helmet>
				<title>Смена пароля</title>
			</Helmet>
			<Logo />
			<Content
				style={{
					background: 'url(/login.jpg) no-repeat center center fixed',
					backgroundSize: 'cover',
					display: 'flex',
					justifyContent: 'space-around',
					height: '100vh',
				}}
			>
				<Row justify="center" align="middle" style={{ position: 'relative', bottom: '80px' }}>
					<Col style={{ width: '500px' }}>
						<Col>
							<Row
								justify="center"
								style={{
									fontSize: 25,
									fontWeight: '700',
									paddingBottom: '10px',
									color: 'rgb(3 52 97)',
								}}
							>
								ВОССТАНОВЛЕНИЕ ПАРОЛЯ
							</Row>
							<Row
								justify="center"
								style={{
									fontSize: 23,
									fontWeight: 700,
									textAlign: 'center',
									paddingBottom: '30px',
									color: 'rgb(3 52 97)',
									lineHeight: '1',
								}}
							>
								Введите почту указанную при создании учетной записи
							</Row>
						</Col>
						<Form
							colon={false}
							onFinish={async (data) => {
								try {
									validateEmail()
									await forgotPwd(data)
									history.push('/send-mail')
								} catch (err) {
									if (email !== '') setError(err.message)
									console.log(err.message)
								}
							}}
						>
							<Col
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-end',
								}}
							>
								<Form.Item name="email">
									<Input
										style={inputStyle}
										onKeyDown={(e) => {
											if (isCyrillics(e.key)) {
												e.preventDefault()
											}
										}}
										onChange={(e) => setEmail(e.target.value)}
										placeholder="Ваша эл. почта"
									/>
								</Form.Item>
								{error && <AuthError error={error} />}
								<Button
									htmlType="submit"
									type="button"
									style={{
										...btnStyle,
										color: 'white',
										marginBottom: '20px',
										background: 'rgb(3 52 97)',
									}}
								>
									Продолжить
								</Button>
								<Button
									onClick={() => {
										history.push('/login')
									}}
									type="button"
									style={{
										...btnStyle,
										border: '2px solid rgb(3, 52, 97)',
									}}
								>
									Отменить
								</Button>
							</Col>
						</Form>
					</Col>
				</Row>
			</Content>
		</Layout>
	)
}

export default ForgotPassword
