import React from 'react';
import {Main} from "../../layouts";
import {NotFound as NotFoundComponent} from '../../components'

const NotFound = () => {
    return (
        <Main title="Главная" children={<NotFoundComponent />}/>
    );
};

export default NotFound;
