import { AuthContext } from '../../../contexts'
import React, { useState, useContext } from 'react'

import { Card, Table } from 'antd'
import { tableColumns } from './table'
import { UserDataContext } from '../../../contexts'
const { checkRole } = require('@berry/common-functions/role')

const Body = (props) => {
	const {
		columns,
		data,
		handleEditRow,
		handleDeleteRow,
		canUpdate = false,
		canDelete = false,
		rights,
		notEditableFields,
		ignoreDeps,
		memoData,
	} = props

	const [hoverRecord, setHoverRecord] = useState(null)

	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights, isDevAdmin },
	} = useContext(UserDataContext)

	const handleHoverRow = (record) => {
		if (!canUpdate && !canDelete) {
			return
		}

		return {
			onMouseEnter: () => setHoverRecord({ id: record.id, canUpdate, canDelete }),
			onMouseLeave: () => setHoverRecord({}),
		}
	}

	const getEditDeleteColumn = () => {
		if (checkRole(rights, currentRights)) {
			if (!canDelete & !canUpdate) return []
			return tableColumns(
				handleEditRow,
				handleDeleteRow,
				hoverRecord,
				authCtx,
				notEditableFields,
				ignoreDeps,
				isDevAdmin
			)
		}
		return []
	}

	return (
		<Card>
			<Table
				onRow={handleHoverRow}
				dataSource={memoData}
				pagination={{ showSizeChanger: false, pageSize: 16 }}
				rowKey="id"
				scroll={{ x: 800 }}
				size="small"
				columns={[...columns, ...getEditDeleteColumn()]}
			/>
		</Card>
	)
}

export default Body
