import React, { useContext, useEffect, useState } from 'react'

import Wrapper from '../Wrapper/Wrapper'
import { columns } from './columns'
import { getDataForVocs } from '../AdditionalSemiType/AdditionalSemiType'
import { SyncDepsContext } from '../../../contexts'
const vocUrl = '/voc/static/staff-status'

const StaffStatus = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, setState] = useState([])
	useEffect(() => getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl), [])
	return (
		<Wrapper
			rights={{
				Справочники: {
					'Статусы персонала': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Статусы персонала"
			data={state}
			columns={columns}
			vocUrl={vocUrl}
		/>
	)
}

export default StaffStatus
