import { setNumField } from '@berry/front-common/for-fields'
import { CommonSelect, EditDelBtns, InputError } from '../../../../../components'
import { modes, openNewTab } from '../../../../../utils'
/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 *  @param {Object} modCtx
 *  @param {function} editHandler
 */
export const getDisplayColumns = (mainCtx, modCtx, authCtx) => {
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.productions
	return [
		{
			title: '№ партии',
			dataIndex: 'partyNum',
			key: 'assignedPartyNum',
			width: '15.16%',
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			key: 'articul',
			width: '12.16%',
		},
		{
			title: 'Артикул 1С',
			dataIndex: 'articul1C',
			key: 'articul1C',
			width: '13.16%',
		},
		{
			title: 'Продукт',
			key: 'prodCat',
			width: '8%',
			render: (record) => record.product || record.prodCat,
		},
		{
			title: 'Вид',
			key: 'prodCatKind',
			dataIndex: 'prodCatKind',
			width: '18.2%',
		},
		{
			title: '№ помещения',
			dataIndex: ['room', 'displayCode'],
			key: 'displayCode',
			width: '12.48%',
			render: (_, record) => {
				const roomArr = mainCtx.selectors.productions.room.filter(
					(r) => r.outdoorWh.id === mainCtx.state.receiveWh?.id
				)
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`room.${record._uuid_}`]}
						component={
							<CommonSelect
								value={record?.room}
								setValue={(val) => {
									stateFunctions.setRoom(record._uuid_, val)
								}}
								isDisabled={isDisabled}
								dataArr={roomArr}
							/>
						}
					/>
				)
			},
		},
		{
			title: 'Масса, кг',
			dataIndex: '',
			key: 'weight',
			rules: [{ required: true }],
			render: (_, rec) => {
				return (
					<InputError
						showCaption={!!(rec.product || rec.prodCat)}
						hasError={mainCtx.formErrors?.[`weight.${rec._uuid_}`]}
						message={`Доступная масса ${mainCtx.getAvailableWeight(rec)} кг`}
						style={{ width: '100%' }}
						value={rec.weight}
						disabled={isDisabled}
						onChange={(e) => {
							setNumField(e.target.value, rec.weight, stateFunctions.setWeight, 'float', [9, 2], {
								_uuid_: rec._uuid_,
								isOnlyNums: true,
							})
							mainCtx.checkAvailable()
						}}
					/>
				)
			},
			width: '15.16%',
		},

		{
			title: null,
			key: 'action',
			width: '3%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={false}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
							mainCtx.setDeletedProds(record)
						}}
						deps={mainCtx.delEditDeps?.productions?.[record.id]}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					/>
				)
			},
		},
	]
}
