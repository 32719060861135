import { useContext } from 'react'
import { Button, Card, Col, Row, Typography } from 'antd'
import { StockOpsDisposalItemMainContext } from '../provider/main'
import { SelectModal, showConfirmModal } from '../../../../../components'
import { capitalizeFirstLetter } from '@berry/common-functions/text-functions'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import SelectProdModalTable from './SelectProdModalTable'

const SelectProdModal = () => {
	const mainCtx = useContext(StockOpsDisposalItemMainContext)
	const addProdName = [`addProdStock${capitalizeFirstLetter(mainCtx.addProd.__name)}Stor`]
	const totalSum = mainCtx.addProd[addProdName]?.reduce((sum, cur) => sum + +cur.weight, 0)
	return (
		<SelectModal
			visible={mainCtx.addProd.__isOpen}
			onOk={() => {
				if (!mainCtx.addProd[addProdName].length) {
					return showConfirmModal({
						title: 'Не выбрана партия',
						showCancel: false,
						okText: 'Ок',
					})
				}
				mainCtx.stateFunctions.productions.create(
					mainCtx.addProd[addProdName],
					`stock${capitalizeFirstLetter(mainCtx.addProd.__name)}Stor`
				)
				mainCtx.modalFunctions.resetAddProd()
			}}
			onClose={mainCtx.modalFunctions.resetAddProd}
			content={
				<Card>
					<Card>
						<Row justify="end">
							<Col>
								<Typography.Text strong>Общая масса, кг: {numToFixed(totalSum, 1)}</Typography.Text>
							</Col>
						</Row>
					</Card>
					<SelectProdModalTable mainCtx={mainCtx} />
				</Card>
			}
			additionalButtons={[
				[
					{ key: 'waste', name: 'Отходы' },
					{ key: 'sample', name: 'Образцы' },
				].map((el) => (
					<Button
						key={el.key}
						type={mainCtx.addProd.__name === el.key && 'primary'}
						style={{ marginRight: 20 }}
						onClick={() => {
							mainCtx.modalFunctions.resetAddProd()
							mainCtx.modalFunctions.openAddProd()
							mainCtx.modalFunctions.setAddProd__name(el.key)
						}}
					>
						{el.name}
					</Button>
				)),
			]}
		/>
	)
}
export default SelectProdModal
