import React, { useRef } from 'react'

export const reducer = (state) => {
	return {
		...state,
	}
}

const SystemSettingsListMainContext = React.createContext()
SystemSettingsListMainContext.displayName = 'SystemSpecSettingsListMainContext'

const Provider = (props) => {
	const { children } = props
	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	const dataList = [
		{
			link: 'specification-negotiation',
			title: 'Согласование протоколов контроля',
		},
		{
			link: 'supply-agreement',
			title: 'Согласование приемки партии',
		},
		{
			link: 'task-agreement',
			title: 'Согласование отчета, включающего сырье или ГП',
		},
		{
			link: 'task-pf-agreement',
			title: 'Согласование отчета, включающего только ПФ',
		},
		{
			link: 'settings-1c',
			title: 'Настройки 1С',
		},
		{
			link: 'storage-cost',
			title: 'Стоимость хранения',
		},
		{
			link: 'stock-notify',
			title: 'Настройка уведомлений в реестрах запасов',
		},
	]

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}

	const value = {
		state: stateRef.current,
		dataList,
		setSearch,
	}

	return (
		<SystemSettingsListMainContext.Provider value={value}>
			{children}
		</SystemSettingsListMainContext.Provider>
	)
}

export { Provider, SystemSettingsListMainContext }
