import { sendToServer } from './sendToServer'

export const addData = async (url, params) => {
	const { data } = params
	const result = await sendToServer('post', url, data)
	return result
}

export const editData = async (url, params) => {
	const { data } = params
	const result = await sendToServer('put', url, data)
	return result
}

export const deleteData = async (url, params) => {
	const { id } = params
	const result = await sendToServer('delete', url + `/${id}`)
	return result
}
