import React, { useContext, useEffect, useRef } from 'react'
import { ListContext, SyncDepsContext } from '../../../../../contexts'
import { dataUrl } from '../../position-type'
import { canDelete } from '../../../../../utils/helpers/for-deps'
import { deepFind, axios } from '../../../../../utils'
import {
	startUseEffectHandlerForList,
	everyOtherTimeUseEffectHandlerForList,
} from '../../../../../utils'
export const reducer = (state) => {
	return {
		...state,
	}
}

const PosTypeContext = React.createContext()
PosTypeContext.displayName = 'PosTypeContext'

const Provider = (props) => {
	const { children } = props
	const {
		state: { page, pageSize, mainFilter, filters, sorter },
		setTotal,
	} = useContext(ListContext)
	const syncDepsCtx = useContext(SyncDepsContext)

	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: {},
		isInitialized: false,
		isLoading: false,
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	useEffect(() => {
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: { pageLimit: pageSize, sorter, mainFilter, filters },
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [])
	useEffect(() => {
		everyOtherTimeUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				offset: pageSize * page - pageSize,
				limit: pageSize,
				sorter,
				mainFilter,
				filters,
				search: stateRef.current.search,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [
		page,
		sorter,
		filters,
		mainFilter,
		stateRef.current.search,
		syncDepsCtx.state.reloadUuids['office-ms'],
		stateRef.current.search,
	])

	const canDel = async (id) => {
		const dataDeps = await axios('/data-dependency')
		const foundEl = deepFind(['fromServer'], [id], stateRef.current, { key: 'id' })
		if (!foundEl.id) return true
		const result = canDelete(
			{
				voc_position_type: [foundEl.id],
			},
			dataDeps
		)
		return result
	}

	const value = {
		state: stateRef.current,
		allData: stateRef.current.fromServer,
		canDel,
	}

	return <PosTypeContext.Provider value={value}>{children}</PosTypeContext.Provider>
}

export { Provider, PosTypeContext }
