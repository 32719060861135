import { TimePicker } from 'antd'
import { modes } from '../../../../../utils'
import moment from 'moment'
import { EditDelBtns, InputError } from '../../../../../components'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 */
export const getDisplayColumns = (modCtx, stateFunctions, mainCtx) => {
	const isDisabled = modCtx.mod === modes.view

	const items = [
		{
			title: '№ перерыва',
			dataIndex: 'displayCode',
			key: 'displayCode',
			width: '32%',
			render: (_, record) => {
				return record.displayCode
			},
		},
		{
			title: 'Начало перерыва',
			dataIndex: 'startTime',
			key: 'startTime',
			width: '32%',
			render: (_, record) => {
				return (
					<InputError
						hasError={mainCtx.formErrors[`startTime.${record._uuid_}`]}
						component={
							<TimePicker
								inputReadOnly
								defaultValue={record.startTime ? moment(record.startTime) : null}
								placeholder="ХХ:ХХ"
								format="HH:mm"
								onChange={(e) => {
									mainCtx.stateFunctions.breaks.setStartTime(record._uuid_, e)
								}}
								style={{ width: '22%' }}
								disabled={isDisabled}
							/>
						}
					/>
				)
			},
		},
		{
			title: 'Окончание перерыва',
			dataIndex: 'endTime',
			key: 'endTime',
			width: '32%',
			render: (_, record) => {
				return (
					<InputError
						hasError={mainCtx.formErrors[`endTime.${record._uuid_}`]}
						component={
							<TimePicker
								inputReadOnly
								defaultValue={record.endTime ? moment(record.endTime) : null}
								placeholder="ХХ:ХХ"
								format="HH:mm"
								onChange={(e) => {
									mainCtx.stateFunctions.breaks.setEndTime(record._uuid_, e)
								}}
								style={{ width: '22%' }}
								disabled={isDisabled}
							/>
						}
					/>
				)
			},
		},
		{
			title: '',
			key: 'action',
			width: '2%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
					/>
				)
			},
		},
	]
	if (isDisabled) return items.slice(0, items.length - 1)
	return items
}
