import { getColumnSearchProps, sorter } from '../../../../utils'
import { dateFormat } from '../../../../utils/constants/common'
import { colorizeIntegrationListStatus } from '../../../../utils/helpers/for-integration'
import moment from 'moment'
import { sliceStringAndAddThreeDots } from '@berry/front-common'
import { Tooltip } from 'antd'

/**
 * @param {Object} fromServerFilters - массив с записями таблицы
 */
export const getColumns = (props) => {
	const { fromServerFilters } = props

	return [
		{
			title: 'ID перемещения',
			dataIndex: 'id',
			key: 'id',
			width: '3%',
			sorter: sorter('number', 'id'),
			...getColumnSearchProps(fromServerFilters.id),
		},
		{
			title: 'Дата перемещения',
			dataIndex: 'date',
			key: 'date',
			width: '5%',
			render: (date) => (date ? moment(date).format(dateFormat) : null),
			sorter: sorter('date', 'date'),
			...getColumnSearchProps(fromServerFilters.date, undefined, null, true),
		},
		{
			title: 'Партия №',
			dataIndex: 'partyNum',
			key: 'partyNums',
			width: '10%',
			render: (partyNums) => (
				<Tooltip
					overlayInnerStyle={{ fontSize: '12px' }}
					title={
						<span style={{ whiteSpace: 'pre-wrap' }}>
							{partyNums
								.split(', ')
								.map((pn) => pn + '\n')
								.join('')}
						</span>
					}
				>
					{sliceStringAndAddThreeDots(partyNums, 25)}
				</Tooltip>
			),
			...getColumnSearchProps(fromServerFilters.partyNum),
		},
		{
			title: 'Продукт',
			dataIndex: 'allProducts',
			key: 'products',
			width: '30%',
			render: (allProducts) => (
				<Tooltip
					overlayInnerStyle={{ fontSize: '12px' }}
					align={{ align: 'center' }}
					title={
						<span style={{ whiteSpace: 'pre-wrap', wdith: '100%' }}>
							{allProducts
								.split(', ')
								.map((p) => p + '\n')
								.join('')}
						</span>
					}
				>
					{sliceStringAndAddThreeDots(allProducts, 90)}
				</Tooltip>
			),
			...getColumnSearchProps(fromServerFilters.products),
		},
		{
			title: 'Общая масса, кг',
			key: 'totalWeight',
			dataIndex: 'totalWeight',
			width: '7.96%',
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '9.1%',
			render: (_, record) => colorizeIntegrationListStatus(record.status),
			...getColumnSearchProps(fromServerFilters.status),
		},
	]
}
