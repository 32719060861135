import React, { useContext } from 'react'
import { AuthContext, FileProvider, ModProvider, UserDataContext } from '../../../../contexts'
import { Main } from '../../../../layouts'
import { Provider, StockSampleItemMainContext } from './provider/main'
import { Card, Row, Col, Form, Typography } from 'antd'
import {
	ActionButtons,
	CustomForm,
	HeaderMenu,
	showConfirmModal,
	TabList,
} from '../../../../components'
import { actBtnOnClose, actBtnOnDelete, actBtnOnSaveV2, modes, openNewTab } from '../../../../utils'
import { getFormItemsMain } from './sample-item'
import { useHistory } from 'react-router'
import { getBreadcrumbsText } from '../../../../utils/helpers/generators'
import StorageTab from './StorageTab/StorageTab'
import EventHistoryTab from './EventHistoryTab'

const pageUrl = '/stock/samples'

const { Title } = Typography

const StockSampleItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<FileProvider>
				<Provider params={{ id: props.match.params.id }}>
					<Main title="Запасы">
						<InfoComponent />
					</Main>
				</Provider>
			</FileProvider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const mainCtx = useContext(StockSampleItemMainContext)
	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights, isDevAdmin },
	} = useContext(UserDataContext)
	let history = useHistory()

	const [form] = Form.useForm()

	if (mainCtx.state.__notFound) {
		history.replace('/stock/samples')
		return null
	}
	return (
		<>
			<HeaderMenu
				{...getBreadcrumbsText(pageUrl, mainCtx.state, {
					isInitializedMain: mainCtx.isInitializedMain,
				})}
			>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						canDeleteOnly={isDevAdmin}
						canEdit={false}
						rights={{
							'АРМ офиса': {
								'Карточка Запасы': {
									edit: true,
								},
							},
						}}
						formName="stock-sample"
						onClose={() => {
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onDelete={async () => {
							await actBtnOnDelete({
								history,
								pageUrl,
								showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								currentRights,
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
							})
						}}
						onSave={async () => {
							try {
								await actBtnOnSaveV2({
									pageUrl,
									history,
									form,
									showConfirmModal,
									isShowErrorModal: true,
									canSaveWithErrors: true,
									serverEdit: mainCtx.serverEdit,
									validate: mainCtx.validate,
								})
							} catch (err) {
								console.log(err)
							}
						}}
						onCancel={() => {
							form.resetFields()
							mainCtx.reset()
						}}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<Title level={5}>Основные данные</Title>
						<CustomForm form={form} name="main" items={getFormItemsMain(mainCtx)} />
					</Col>
				</Row>
			</Card>
			{
				<TabList
					tabs={[
						{ key: 0, title: 'Хранение', component: <StorageTab /> },
						{ key: 1, title: 'История событий', component: <EventHistoryTab /> },
					]}
				/>
			}
		</>
	)
}
export default StockSampleItem
