import { Table } from 'antd'
import React, { useContext } from 'react'
import { WorkingTimeItemMainContext } from '../provider/main'
import { TabItem } from '../../../../../components'
import { ModContext } from '../../../../../contexts'
import { modes } from '../../../../../utils'
import { getDisplayColumns } from './breaks-tab'
import lodash from 'lodash'

const BreaksTab = () => {
	const mainCtx = useContext(WorkingTimeItemMainContext)
	const stateFunctions = mainCtx.stateFunctions.breaks
	const modCtx = useContext(ModContext)
	let stateData = lodash.sortBy(mainCtx.state?.breaks, ['displayCode'])

	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					stateFunctions.create()
				},
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowKey={(el) => el._uuid_ || el.id}
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(modCtx, stateFunctions, mainCtx)}
						dataSource={stateData}
						pagination={false}
						scroll={{ x: 800 }}
					/>
				)
			}}
		/>
	)
}

export default BreaksTab
