import { Table } from 'antd'
import React, { useContext } from 'react'
import { RequestItemMainContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { ModContext, FileContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns } from './documents-tab'

const DocumentsTab = () => {
	const mainCtx = useContext(RequestItemMainContext)
	const stateFunctions = mainCtx.stateFunctions.documents
	const modCtx = useContext(ModContext)
	let stateData = mainCtx.state.documents
	const { create, addFile, removePhoto } = stateFunctions
	const fileCtx = useContext(FileContext)
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					create()
				},
			}}
			table={() => (
				<Table
					size="small"
					rowClassName={modCtx.mod !== modes.view && 'clickable'}
					columns={getDisplayColumns(
						modCtx,
						stateFunctions,
						removePhoto,
						addFile,
						mainCtx,
						fileCtx
					)}
					dataSource={stateData}
					pagination={false}
					scroll={{ x: 800 }}
					onRow={(record) => ({
						onClick: () => {},
					})}
				/>
			)}
		/>
	)
}

export default DocumentsTab
