import React, { createContext, useEffect, useReducer, useRef } from 'react'
import { useLocation } from 'react-router-dom'
const OpenedItemContext = createContext()

OpenedItemContext.displayName = 'OpenedItemContext'
const reducer = (state) => {
	return {
		...state,
	}
}

const Provider = ({ children }) => {
	const location = useLocation()
	const [state, dispatch] = useReducer(reducer, {
		data: [`/${location.pathname.split('/').slice(1)[0]}`],
		needRerender: true,
	})

	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	const setPage = (page) => {
		executeDispatch({ data: page, needRerender: false })
	}
	useEffect(() => {
		if (stateRef.current.needRerender) {
			executeDispatch({
				data: [`/${location.pathname.split('/').slice(1)[0]}`],
				needRerender: false,
			})
		}
	}, [location.pathname])

	const value = {
		state: stateRef.current.data,
		setPage,
	}
	return <OpenedItemContext.Provider value={value}>{children}</OpenedItemContext.Provider>
}

export { OpenedItemContext, Provider }
