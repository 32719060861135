import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Card, Col, Row, Form } from 'antd'
import {
	ModContext,
	ModProvider,
	FileProvider,
	UserDataContext,
	AuthContext,
} from '../../../../contexts'
import { Provider, RelocDefectItemMainContext } from './provider/main'
import { Main } from '../../../../layouts'
import moment from 'moment'
import {
	ActionButtons,
	CustomForm,
	HeaderMenu,
	LoadingButton,
	showConfirmModal,
	TabList,
} from '../../../../components'
import { getFormItems } from './reloc-defect-item'
import ProductTab from './ProductionTab/ProductionTab'
import EventHistoryTab from './EventHistoryTab'
import { actBtnOnClose, actBtnOnDelete, actBtnOnSaveV2, modes, openNewTab } from '../../../../utils'
import { getBreadcrumbsText } from '../../../../utils/helpers/generators'
import { checkRole } from '@berry/common-functions/role'

const pageUrl = '/stock-operations/reloc-defect'

const rights = {
	'АРМ офиса': {
		'Перемещения в брак': {
			edit: true,
		},
	},
}

const RelocDefectItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<FileProvider>
				<Provider params={{ id: props.match.params.id }}>
					<Main title="Перемещения в брак">
						<InfoComponent />
					</Main>
				</Provider>
			</FileProvider>
		</ModProvider>
	)
}
const InfoComponent = () => {
	const mainCtx = useContext(RelocDefectItemMainContext)
	const modCtx = useContext(ModContext)
	const userDataCtx = useContext(UserDataContext)
	const authCtx = useContext(AuthContext)
	const history = useHistory()
	if (mainCtx.state.__notFound) {
		history.replace(pageUrl)
	}
	const [form] = Form.useForm()
	const breadcrumb = getBreadcrumbsText(pageUrl, mainCtx.state)

	return (
		<>
			<HeaderMenu
				breadcrumbsTitle={breadcrumb.head}
				recordLabel={mainCtx.state.id ? breadcrumb.label : 'Новая'}
			>
				<Row>
					<ActionButtons
						canEdit={['Новое', 'Отклонено 1С', 'Ошибка отправки в 1С'].includes(
							mainCtx.state.status
						)}
						rights={rights}
						formName="reloc-defect"
						canDelete={
							modCtx.mod !== modes.new && ['Новое', 'Отклонено 1С', 'Ошибка отправки в 1С']
						}
						onClose={async () => {
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							await actBtnOnSaveV2({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								pageUrl,
								history,
								isShowErrorModal: true,
								showConfirmModal,
							})
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						onDelete={async () => {
							await actBtnOnDelete({
								history,
								pageUrl,
								showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
								currentRights: userDataCtx.state.rights,
							})
							mainCtx.setDeletedProds()
						}}
						onCancel={() => {
							mainCtx.setDeletedProds()
							const allFields = form.getFieldsError()
							allFields.forEach((f) => {
								f.errors = []
							})
							form.setFields(allFields)
							mainCtx.reset()
						}}
						additionalButtonsPre={
							modCtx.mod === modes.view &&
							['Новое', 'Отклонено 1С', 'Ошибка отправки в 1С'].includes(mainCtx.state.status) &&
							checkRole(rights, userDataCtx.state.rights)
								? [
										<LoadingButton key="send-to-1c" onClick={mainCtx.serverSendTo1c}>
											Отправить в 1С
										</LoadingButton>,
								  ]
								: []
						}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							form={form}
							name="disposal"
							fields={[
								{ name: 'displayCode', value: mainCtx.state.displayCode },
								{
									name: 'date',
									value: mainCtx.state.date ? moment(mainCtx.state.date) : null,
								},
								{ name: 'status', value: mainCtx.state.status },
							]}
							columns={[
								{ size: { xs: 24, md: 12, xl: 9, xxl: 9 }, num: 0 },
								{ size: { xs: 24, md: 12, xl: 9, xxl: 9 }, num: 1 },
								{ size: { xs: 24, md: 12, xl: 6, xxl: 6 }, num: 2 },
							]}
							items={getFormItems(mainCtx, modCtx)}
						/>
					</Col>
				</Row>
			</Card>
			<TabList
				tabs={[
					{ key: 0, title: 'Продукция', component: <ProductTab /> },
					{ key: 1, title: 'История событий', component: <EventHistoryTab /> },
				]}
			/>
		</>
	)
}

export default RelocDefectItem
