import { capitalizeFirstLetter } from '@berry/common-functions/text-functions'
import { Checkbox } from 'antd'
import { getColumns as getRawMatColumns } from '../../../Stock/RawMat/RawMatList/raw-mat-list'
import { getColumns as getSemifColumns } from '../../../Stock/Semif/SemifList/semif-list'
import { getColumns as getReadyProdColumns } from '../../../Stock/ReadyProd/ReadyProdList/ready-prod-list'
import { getColumns as getReadyProdResaleColumns } from '../../../Stock/ReadyProdResale/ReadyProdResaleList/ready-prod-resale-list'
import { getColumns as getWasteColumns } from '../../../Stock/Waste/WasteList/waste-list'
import { TableWithListLoader } from '../../../../components'

const getCheckboxColumn = (mainCtx) => {
	const addProdName = [`addProdStock${capitalizeFirstLetter(mainCtx.addProd.__name)}Stor`]
	return {
		title: null,
		key: 'checkbox',
		width: '2%',
		render: (record) => {
			return (
				<Checkbox
					disabled={
						mainCtx.addProd[addProdName].some(
							(el) =>
								(el.idContrOrder && el.idContrOrder !== record.idContrOrder) ||
								(el.idContrOrder === null && record.idContrOrder)
						) ||
						(mainCtx.state.isFreeSamples &&
							mainCtx.state.productions?.length &&
							((mainCtx.state.productions[0]?.idStockRawMat &&
								mainCtx.addProd.__name !== 'rawMat') ||
								(mainCtx.state.productions[0]?.idStockSemif &&
									mainCtx.addProd.__name !== 'semif') ||
								(mainCtx.state.productions[0]?.idStockWaste &&
									mainCtx.addProd.__name !== 'waste') ||
								((mainCtx.state.productions[0]?.idStockReadyProd ||
									mainCtx.state.productions[0]?.idStockReadyProdResale) &&
									mainCtx.addProd.__name !== 'readyProd' &&
									mainCtx.addProd.__name !== 'readyProdResale'))) ||
						(mainCtx.state.isFreeSamples && record.idContrOrder)
					}
					checked={mainCtx.addProd[addProdName]?.some(
						(el) => String(el.idStorage) === String(record.idStorage)
					)}
					onChange={(e) => {
						const val = e.target.checked
							? record
							: mainCtx.addProd[addProdName].find((s) => s.idStorage === record.idStorage)
						if (val._uuid_) {
							return mainCtx.modalFunctions[addProdName].delete(val._uuid_)
						}
						return mainCtx.modalFunctions[addProdName].create(val)
					}}
				/>
			)
		},
	}
}

const SelectProdModalTable = ({ mainCtx }) => {
	let url = null
	let calcColumns = null
	let filters = {}
	let defaultSorter = null
	let permanentFilters = {}
	switch (mainCtx.addProd.__name) {
		case 'rawMat':
			url = '/stock/raw-mats'
			calcColumns = (props) => [getCheckboxColumn(mainCtx), ...getRawMatColumns(props).slice(0, -1)]
			filters = {
				defrostCount: [],
				idContrOrder: [],
				assignedPartyNum: [],
				displayCode: [],
				vendorCode: [],
				prodCat: [],
				prodCatKind: [],
				quality: [],
				pkgType: [],
				nettoPkgWeight: [],
				provider: [],
				manuf: [],
				roomNum: [],
				status: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					status: mainCtx.state.idContrOrder ? ['К отгрузке'] : ['Допущено'],
					...(mainCtx.state.productions.length && { idContrOrder: [mainCtx.state.idContrOrder] }),
					...(mainCtx.state.outdoorWh?.id && { idVocOutdoorWh: [mainCtx.state.outdoorWh?.id] }),
				},
				exclude: {
					idStorage: [...mainCtx.state.productions, ...mainCtx.deletedProds]
						.filter((p) => p.idStockRawMatStor || p.stockRawMatStor)
						.flatMap((p) => [
							p.stockRawMatStor?.idStorage || p.idStockRawMatStor,
							p.stockRawMatStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				filterByStatus: true,
			}
			break
		case 'readyProd':
			url = '/stock/ready-prods'
			calcColumns = (props) => [getCheckboxColumn(mainCtx), ...getReadyProdColumns(props)]
			const statusFilter = mainCtx.state.productions.length
				? !mainCtx.state.productions[0].idContrOrder
					? ['Допущено']
					: ['К отгрузке']
				: ['Допущено', 'К отгрузке']
			filters = {
				idContrOrder: [],
				partyNum: [],
				articul: [],
				articul1C: [],
				prodCat: [],
				prodCatKind: [],
				pkgType: [],
				nettoPkgWeight: [],
				roomNum: [],
				status: [],
				_1c: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					status: statusFilter,
					_1c: ['Зарегистрирован в 1С'],
					...(mainCtx.state.productions.length && { idContrOrder: [mainCtx.state.idContrOrder] }),
					...(mainCtx.state.outdoorWh?.id && { idVocOutdoorWh: [mainCtx.state.outdoorWh?.id] }),
				},
				exclude: {
					idStorage: [...mainCtx.state.productions, ...mainCtx.deletedProds]
						.filter((p) => p.idStockReadyProdStor || p.stockReadyProdStor)
						.flatMap((p) => [
							p.stockReadyProdStor?.idStorage || p.idStockReadyProdStor,
							p.stockReadyProdStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				filterByStatus: true,
			}
			break
		case 'semif':
			url = '/stock/semifs'
			calcColumns = (props) => [getCheckboxColumn(mainCtx), ...getSemifColumns(props)]
			filters = {
				idContrOrder: [],
				partyNum: [],
				articul: [],
				articul1C: [],
				prodCat: [],
				prodCatKind: [],
				pkgType: [],
				nettoPkgWeight: [],
				roomNum: [],
				status: [],
				_1c: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					status: mainCtx.state.productions.length
						? mainCtx.state.idContrOrder
							? ['К отгрузке']
							: ['Допущено']
						: ['К отгрузке', 'Допущено'],
					_1c: ['Зарегистрирован в 1С'],
					...(mainCtx.state.productions.length && { idContrOrder: [mainCtx.state.idContrOrder] }),
					...(mainCtx.state.outdoorWh?.id && { idVocOutdoorWh: [mainCtx.state.outdoorWh?.id] }),
				},
				exclude: {
					idStorage: [...mainCtx.state.productions, ...mainCtx.deletedProds]
						.filter((p) => p.idStockSemifStor || p.stockSemifStor)
						.flatMap((p) => [
							p.stockSemifStor?.idStorage || p.idStockSemifStor,
							p.stockSemifStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				filterByStatus: true,
			}
			break
		case 'readyProdResale':
			url = '/stock/ready-prod-resales'
			calcColumns = (props) => {
				const columns = getReadyProdResaleColumns(props).slice(0, -1)
				columns.forEach((el) => {
					if (el.key === 'defrostCount') el.width = '3%'
					if (el.key === 'assignedPartyNum') el.width = '6%'
					if (el.key === 'pkgType') el.width = '4%'
					if (el.key === 'prodCat') el.width = '6%'
					if (el.key === 'sellBy') el.width = '5%'
					if (el.key === 'quality') el.width = '8%'
					if (el.key === 'provider') el.width = '10%'
					if (el.key === 'manuf') el.width = '7%'
					return el
				})
				return [getCheckboxColumn(mainCtx), ...columns]
			}
			filters = {
				defrostCount: [],
				idContrOrder: [],
				assignedPartyNum: [],
				displayCode: [],
				vendorCode: [],
				prodCat: [],
				prodCatKind: [],
				quality: [],
				pkgType: [],
				nettoPkgWeight: [],
				provider: [],
				manuf: [],
				roomNum: [],
				status: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					status: ['Допущено'],
					...(mainCtx.state.outdoorWh?.id && { idVocOutdoorWh: [mainCtx.state.outdoorWh?.id] }),
				},
				exclude: {
					idStorage: [...mainCtx.state.productions, ...mainCtx.deletedProds]
						.filter((p) => p.idStockReadyProdResaleStor || p.stockReadyProdResaleStor)
						.flatMap((p) => [
							p.stockReadyProdResaleStor?.idStorage || p.idStockReadyProdResaleStor,
							p.stockReadyProdResaleStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				filterByStatus: true,
			}
			break

		case 'waste':
			url = '/stock/wastes'
			calcColumns = (props) => [getCheckboxColumn(mainCtx), ...getWasteColumns(props).slice(0, -1)]
			filters = {
				defrostCount: [],
				idContrOrder: [],
				assignedPartyNum: [],
				displayCode: [],
				vendorCode: [],
				prodCat: [],
				prodCatKind: [],
				quality: [],
				pkgType: [],
				nettoPkgWeight: [],
				provider: [],
				manuf: [],
				roomNum: [],
				status: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					status: ['К отгрузке'],
					...(mainCtx.state.productions.length && { idContrOrder: [mainCtx.state.idContrOrder] }),
					...(mainCtx.state.outdoorWh?.id && { idVocOutdoorWh: [mainCtx.state.outdoorWh?.id] }),
				},
				exclude: {
					idStorage: [...mainCtx.state.productions, ...mainCtx.deletedProds]
						.filter((p) => p.idStockWasteStor || p.stockWasteStor)
						.flatMap((p) => [
							p.stockWasteStor?.idStorage || p.idStockWasteStor,
							p.stockWasteStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
			}
			break
		default:
			throw Error('unknown tab opened')
	}

	return (
		<TableWithListLoader
			rowKey="idStorage"
			url={url}
			calcColumns={calcColumns}
			filters={filters}
			defaultSorter={defaultSorter}
			permanentFilters={permanentFilters}
		/>
	)
}

export default SelectProdModalTable
