import React, { useState, useEffect, useContext } from 'react'
import { useInput, axios } from '../../../utils'
import { addData, deleteData, editData } from '../../../services/api/requests'
import Wrapper from '../Wrapper/Wrapper'
import { columns } from './columns'
import Form from './Form'
import { getAvailableCode } from '../../../utils/helpers/generators'
import { SyncDepsContext } from '../../../contexts'

const vocUrl = `/voc/editable/additional-semi-type`

export const getDataForVocs = async (setState, setDepsInfo, vocUrl) => {
	const {
		data: { result, syncDeps },
	} = await axios(vocUrl)
	setState(result)
	setDepsInfo({ deps: syncDeps })
}
const AdditionalSemiType = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	useEffect(() => {
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
	}, [syncDepsCtx.state.reloadUuids['office-ms']])

	const [state, setState] = useState([])

	const [recordId, setRecordId] = useState(null)

	const [label, resetLabel] = useInput('')

	const resetFields = () => {
		resetLabel()
	}

	const addItem = async (successFn = (fn) => fn) => {
		await addData(vocUrl, {
			data: {
				label: label.value,
				displayCode: String(getAvailableCode(state.map((e) => +e.displayCode))),
			},
		})
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
		successFn()
	}

	const editItem = async (successFn = (fn) => fn) => {
		await editData(vocUrl, {
			recordId,
			data: {
				id: recordId,
				label: label.value,
			},
		})
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
		successFn()
	}

	const deleteItem = async (id) => {
		await deleteData(vocUrl, {
			id,
		})
		setRecordId(null)
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
	}

	return (
		<Wrapper
			breadcrumbsTitle="Виды дополнительных ПФ"
			model="voc_editable_additional_semi_type"
			rights={{
				Справочники: {
					'Виды дополнительных ПФ': {
						edit: true,
					},
				},
			}}
			canCreate
			canUpdate
			canDelete
			data={state}
			recordId={recordId}
			setRecordId={setRecordId}
			addData={addItem}
			editData={editItem}
			deleteData={deleteItem}
			columns={columns}
			resetFields={resetFields}
			fields={[{ label }]}
			validateFields={[{ label }]}
			uniqueFields={[{ label }]}
			form={<Form label={label} />}
			vocUrl={vocUrl}
		/>
	)
}

export default AdditionalSemiType
