import { CommonSelect } from '../../../../components'
import { modes } from '../../../../utils'

const layout = {
	labelCol: { span: 2 },
	wrapperCol: { span: 9 },
}

export const getFormItems = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				layout: layout,
				label: 'Процесс',
				name: 'vocProcess',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.vocProcess}
						setValue={(value) => mainCtx.stateFunctions.setVocProcess(value)}
						isDisabled={isDisabled}
						dataArr={mainCtx.selectors.vocProcess}
					/>
				),
			},
		],
	]
}
