import { isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'
import { Input, Space, Typography } from 'antd'
import { modes } from '../../../utils'

const { Title } = Typography

export const getFormItems = (mainCtx, modCtx) => [
	[
		{
			key: 'title',
			field: <Title level={5}>Стоимость хранения 1 кг продукции в сутки</Title>,
		},
		{
			label: 'Стоимость хранения 1 кг продукции',
			key: 'storageCost',
			field: (
				<Space>
					<Input
						disabled={modCtx.mod === modes.view}
						value={mainCtx.state.data.storageCost}
						style={{
							width: 100,
						}}
						onChange={(e) => {
							if (!isValidNum(e.target.value)) return
							if (!isValidNumberDigits(e.target.value, 'float', [7, 2])) return
							mainCtx.stateFunctions.fieldSetters.setStorageCost(e.target.value)
						}}
					/>
					<Typography.Text>руб.</Typography.Text>
				</Space>
			),
		},
	],
]
