import { Space } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { sorter } from '../../../utils'

export const getColumns = () => [
	{
		title: 'Наименование',
		dataIndex: 'label',
		key: 'label',
		width: '12%',
		sorter: sorter('string', 'label'),
	},
	{
		title: 'Краткое наименование',
		dataIndex: 'labelShort',
		key: 'labelShort',
		width: '12%',
	},
	{
		title: 'ГП',
		dataIndex: 'isReadyProd',
		key: 'isReadyProd',
		width: '12%',
		render: (isReadyProd) =>
			isReadyProd && (
				<Space size="middle">
					<CheckOutlined />
				</Space>
			),
	},
	{
		title: 'Сырье',
		dataIndex: 'isRawMat',
		key: 'isRawMat',
		width: '12%',
		render: (isRawMat) =>
			isRawMat && (
				<Space size="middle">
					<CheckOutlined />
				</Space>
			),
	},
	{
		title: 'Артикул',
		dataIndex: 'displayCode',
		key: 'displayCode',
		width: '12%',
	},
	{
		title: 'Вес тары, кг',
		dataIndex: 'weight',
		key: 'weight',
		width: '12%',
	},

	{
		title: '1 паллета, шт',
		dataIndex: 'onePalleta',
		key: 'onePalleta',
		width: '12%',
	},
]
