import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { Space, Typography } from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import { ModContext, SyncDepsContext, UserDataContext } from '../../../../contexts'
import {
	getCommonProviderFunctions,
	getCommonProviderModalFunctions,
} from '../../../../utils/helpers/generators'
import {
	prepareObjFromServer,
	modes,
	getNewObj,
	removeByUuid,
	useItemFetchers,
	goToItem,
} from '../../../../utils'
import { basicValidator } from '@berry/common-functions/validators'
import { asyncShowConfirmModal } from '../../../../components'
import { showSaveAsDraft } from '../../../../utils/constants/for-components'
import {
	calcIsProdTaskPf,
	getRole,
	numToFixed,
} from '@berry/common-functions/cross-project-functions'
import { getArticul } from '../ComplectationTab/complectation-tab'
import { axios } from '../../../../utils'
import { useHistory } from 'react-router-dom'
import { v4 } from 'uuid'

const dataUrl = '/production/tasks'

export const reducer = (state) => {
	return {
		...state,
	}
}

const tabs = {
	complectations: 'complectations',
	reports: 'reports',
	'event-histories': 'eventHistories',
	'match-queue': 'matchQueues',
}

const ProductionTaskItemMainContext = React.createContext()
ProductionTaskItemMainContext.displayName = 'ProductionTaskItemMainContext'

const Provider = (props) => {
	const { children, params } = props

	const syncDepsCtx = useContext(SyncDepsContext)
	const modCtx = useContext(ModContext)

	const [state, dispatch] = React.useReducer(reducer, {
		data: { complectations: [], reports: [] },
		oldData: { complectations: [], reports: [] },
		formErrors: {},
		isInitializedMain: false,
		isInitializedAdditional: false,
		additional: {
			allStockReadyProds: [],
			allRooms: [],
			allBlockCauses: [],
			allTasks: [],
			allCauseOfRejReps: [],
			allPosTypes: [],
			allSelectWorkingTimes: [],
			allSelectContTypes: [],
			allSelectCustomers: [],
			allSpecs: [],
			allSelectProdCats: [],
			allProdCatPkg: [],
			allProdCatKindSemifs: [],
		},
		addComplect: {
			__isOpen: false,
			__name: '',
			addComplectStockRawMatStor: [],
			addComplectStockSemifStor: [],
			addComplectStockReadyProdStor: [],
			addComplectStockReadyProdResaleStor: [],
		},
		tabsLoading: {
			complectations: false,
			reports: false,
			eventHistories: false,
			matchQueues: false,
		},
		delEditDeps: {},
		calcDataLoaded: {
			isAllowMatchOrApproveForTask: false,
		},
		deletedProds: [],
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = newState
		dispatch({ ...state })
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))
	useEffect(() => {
		if (params.id !== 'new') {
			getCalcAllowMatchOrApproveForTask()
		}
	}, [syncDepsCtx.state.reloadUuids['office-ms'], params.id])

	const {
		state: { match = {}, isDevAdmin },
	} = useContext(UserDataContext)
	const isProdTaskPf = calcIsProdTaskPf(stateRef.current.oldData)
	const { role, currentApproveLvl, currentMatchLvl } = getRole(
		match[isProdTaskPf ? 'taskPf' : 'task']
	)
	const { status, level } = stateRef.current.data ? stateRef.current.data : stateRef.current
	const canApprove = currentApproveLvl && currentApproveLvl === level
	const canMatch = currentMatchLvl && currentMatchLvl === level

	const getCalcAllowMatchOrApproveForTask = async () => {
		if (params.id === 'new') {
			executeDispatch({
				...stateRef.current,
				isAllowMatchOrApproveForTask: false,
				calcDataLoaded: {
					isAllowMatchOrApproveForTask: true,
				},
			})
			return
		}

		const fromServerResp = await axios(`${dataUrl}/calc-allow-match-or-approve-for-task`, {
			params: { id: params.id },
		})
		executeDispatch({
			...stateRef.current,
			isAllowMatchOrApproveForTask: fromServerResp.data.mainData,
			calcDataLoaded: {
				isAllowMatchOrApproveForTask: true,
			},
		})
	}

	const getRequiredFields = (p) => ({
		complectations: ['weight'],
		reports: [
			'partyNum',
			'type',
			'contType',
			'room',
			'status',
			'weight',
			...(p.type === 'ПФ'
				? ['prodCatKindSemif.prodCatKind', 'prodCatKindSemif.articul', 'prodCatKindSemif.articul1C']
				: [
						'nettoPkgWeight',
						'prodCatPkg.prodCatKind',
						'prodCatPkg.articul',
						'prodCatPkg.articul1C',
				  ]),
			...(['Заблокировано', 'Удержано'].includes(p.status) ? ['blockCauses'] : []),
		],
	})

	const setDeletedProds = (val) => {
		executeDispatch({
			...stateRef.current,
			deletedProds: val ? [...stateRef.current.deletedProds, val] : [],
		})
	}

	const getLatestPartyNum = (year = moment().format('YY'), report = {}) => {
		const initialValue =
			report.type === 'ДГП'
				? stateRef.current.data.reports.reduce(
						(acc, rep) =>
							rep._uuid_ !== report._uuid_ &&
							rep.partyNum?.startsWith(year) &&
							Number(rep.partyNum.slice(-4)) > acc
								? Number(rep.partyNum.slice(-4))
								: acc,
						7999
				  )
				: 7999
		const filteredTasks = stateRef.current.additional.allTasks.filter((task) =>
			task.reports.some(
				(report) =>
					report.partyNum &&
					report.partyNum.startsWith(year) &&
					Number(report.partyNum.slice(-4)) >= initialValue
			)
		)
		const filteredTasksReports = filteredTasks.flatMap((task) => task.reports)
		if (filteredTasksReports.find((r) => r.id === report.id)) {
			return Number(filteredTasksReports.find((r) => r.id === report.id).partyNum.slice(-4))
		}
		const filteredTasksReportsPartyNums = filteredTasksReports
			.filter((report) => report.partyNum && report.partyNum.startsWith(year))
			.map((report) => report.partyNum)
		const max = filteredTasksReportsPartyNums.reduce(
			(max, cur) => (Number(cur.slice(-4)) > max ? Number(cur.slice(-4)) : max),
			initialValue
		)
		if (max) {
			return max + 1
		}
		return initialValue + 1
	}

	const getPartyNum = (report = {}) => {
		if (
			stateRef.current.data.taskKind === 'Производство ГП' &&
			(!report.type || report.type === 'ДГП')
		) {
			const year = moment(
				stateRef.current.data.date ? stateRef.current.data.date : undefined
			).format('YY')
			const oldYear = moment(
				stateRef.current.oldData.date ? stateRef.current.oldData.date : undefined
			).format('YY')
			if (oldYear === year && stateRef.current.oldData.partyNum && !report.type) {
				return stateRef.current.oldData.partyNum
			}
			return `${year}ФР-${getLatestPartyNum(year, report)}`
		}

		if (stateRef.current.data.taskKind === 'Производство ПФ' || report.type === 'ПФ') {
			const complectations = stateRef.current.data.complectations
			const complectation = complectations[0]
			if (!complectation || complectations.every((c) => !c.weight)) {
				return ''
			}
			const _rawMatVal = 3000
			const summedUpByPartyNum = complectations
				.filter((c) => c.weight)
				.reduce((acc, cur) => {
					const samePartyNumComplect = acc.find(
						(c) =>
							(
								c.stockRawMatStor ||
								c.stockSemifStor ||
								c.stockReadyProdStor ||
								c.stockReadyProdResaleStor
							)?.partyNum ===
							(
								cur.stockRawMatStor ||
								cur.stockSemifStor ||
								cur.stockReadyProdStor ||
								cur.stockReadyProdResaleStor
							)?.partyNum
					)
					if (samePartyNumComplect) {
						return acc.map((c) =>
							c._uuid_ === samePartyNumComplect._uuid_
								? { ...c, weight: (+cur.weight || 0) + (+samePartyNumComplect.weight || 0) }
								: c
						)
					}
					return [...acc, cur]
				}, [])
			const maxWeight = summedUpByPartyNum.reduce((max, cur) =>
				+max.weight > +cur.weight ? max : cur
			).weight
			const maxWeightComplectations = summedUpByPartyNum.reduce((acc, cur) => {
				return +cur.weight === +maxWeight ? [...acc, cur] : acc
			}, [])
			const { stockRawMatStor, stockSemifStor, stockReadyProdStor, stockReadyProdResaleStor } =
				maxWeightComplectations.length === 1
					? maxWeightComplectations[0]
					: // выбираем с наименьшим Годен до
					  maxWeightComplectations.reduce((biggest, current, _, self) => {
							const currentStorDate =
								current.stockRawMatStor?.sellBy ||
								current.stockSemifStor?.shelfLifeDate ||
								current.stockReadyProdStor?.shelfLifeDate ||
								current.stockReadyProdResaleStor.sellBy
							const biggestStorDate =
								biggest.stockRawMatStor?.sellBy ||
								biggest.stockSemifStor?.shelfLifeDate ||
								biggest.stockReadyProdStor?.shelfLifeDate ||
								biggest.stockReadyProdResaleStor.sellBy
							if (!biggestStorDate) {
								return current
							}
							const moment1 = moment(biggestStorDate, 'YYYY-MM-DD')
							const moment2 = moment(currentStorDate, 'YYYY-MM-DD')
							if (moment1.isSame(moment2)) {
								const year1 = parseInt(biggest.partyNum)
								const year2 = parseInt(current.partyNum)
								if (year1 !== year2) {
									return year1 > year2 ? biggest : current
								}
								const num1 = Number(biggest.partyNum.slice(-4))
								const num2 = Number(current.partyNum.slice(-4))
								return num1 > num2 ? biggest : current
							}
							return moment1.isBefore(moment2) ? biggest : current
					  })

			if (complectations.length === 1 && complectation.stockRawMatStor) {
				const assignedPartyNum = complectation.stockRawMatStor.partyNum
				const digitPart = Number(assignedPartyNum.slice(-4)) + _rawMatVal
				return assignedPartyNum.slice(0, assignedPartyNum.indexOf('-') + 1) + digitPart
			}
			if (complectations.length === 1 && complectation.stockSemifStor) {
				return complectation.stockSemifStor.partyNum
			}
			if (complectations.length === 1 && complectation.stockReadyProdResaleStor) {
				const assignedPartyNum = complectation.stockReadyProdResaleStor.partyNum
				const digitPart = Number(assignedPartyNum.slice(-4))
				return assignedPartyNum.slice(0, assignedPartyNum.indexOf('-') + 1) + digitPart
			}
			if (complectations.every((el) => el.stockRawMatStor) && complectations.length > 1) {
				const partyNum = stockRawMatStor.partyNum
				const digitPart = Number(partyNum.slice(-4)) + _rawMatVal
				if (complectations.every((el) => el.stockRawMatStor.partyNum === partyNum)) {
					return partyNum.slice(0, partyNum.indexOf('-') + 1) + digitPart
				}
				return partyNum.slice(0, partyNum.indexOf('-')).replace('Х', '') + 'Х-' + digitPart
			}
			if (complectations.every((el) => el.stockSemifStor) && complectations.length > 1) {
				const partyNum = stockSemifStor.partyNum
				const digitPart = Number(partyNum.slice(-4))
				if (complectations.every((el) => el.stockSemifStor.partyNum === partyNum)) {
					return partyNum.slice(0, partyNum.indexOf('-') + 1) + digitPart
				}
				return partyNum.slice(0, partyNum.indexOf('-')).replace('Х', '') + 'Х-' + digitPart
			}
			if (complectations.every((el) => el.stockReadyProdStor) && complectations.length > 1) {
				const partyNum = stockReadyProdStor.partyNum
				if (complectations.every((el) => el.stockReadyProdStor.partyNum === partyNum)) {
					return partyNum
				}
				return (
					partyNum.slice(0, partyNum.indexOf('-')).replace('Х', '') +
					'Х-' +
					partyNum.slice(partyNum.indexOf('-') + 1)
				)
			}
			if (complectations.every((el) => el.stockReadyProdResaleStor) && complectations.length > 1) {
				const partyNum = stockReadyProdResaleStor.partyNum
				if (complectations.every((el) => el.stockReadyProdResaleStor.partyNum === partyNum)) {
					return partyNum
				}
				return (
					partyNum.slice(0, partyNum.indexOf('-')).replace('Х', '') +
					'Х-' +
					partyNum.slice(partyNum.indexOf('-') + 1)
				)
			}
			if (!complectations.every((el) => el.stockRawMatStor) && complectations.length > 1) {
				if (stockRawMatStor) {
					const assignedPartyNum = stockRawMatStor.partyNum
					const digitPart = Number(assignedPartyNum.slice(-4)) + _rawMatVal
					return (
						assignedPartyNum.slice(0, assignedPartyNum.indexOf('-')).replace('Х', '') +
						'Х-' +
						digitPart
					)
				}
				if (stockSemifStor) {
					const partyNum = stockSemifStor.partyNum
					return (
						partyNum.slice(0, partyNum.indexOf('-')).replace('Х', '') + 'Х-' + partyNum.slice(-4)
					)
				}
				if (stockReadyProdStor) {
					const partyNum = stockReadyProdStor.partyNum
					return (
						partyNum.slice(0, partyNum.indexOf('-')).replace('Х', '') + 'Х-' + partyNum.slice(-4)
					)
				}
				if (stockReadyProdResaleStor) {
					const partyNum = stockReadyProdResaleStor.partyNum
					return (
						partyNum.slice(0, partyNum.indexOf('-')).replace('Х', '') + 'Х-' + partyNum.slice(-4)
					)
				}
			}
		}
		return ''
	}

	const calcReportPartyNum = (inUuid) => {
		const foundRep = stateRef.current.data.reports.find((r) => r._uuid_ === inUuid)
		if (['ПФ', 'ДГП'].includes(foundRep.type)) {
			return getPartyNum(foundRep)
		}
		return stateRef.current.data.partyNum || ''
	}

	const getFabricatedNumVal = () => {
		const twoDigitsOfYear = moment().format('YY')
		if (!twoDigitsOfYear) return ''
		const currentYearTasks = stateRef.current.additional?.allTasks.filter((e) =>
			e.partyNum ? e.partyNum.slice(5, 9) >= 8000 && e.partyNum.startsWith(twoDigitsOfYear) : false
		)
		const endNum =
			Math.max(
				...(currentYearTasks.length
					? currentYearTasks.map((e) => +e.partyNum.split('-')[1])
					: [7999])
			) + 1
		return `${twoDigitsOfYear}ФРC-${endNum}`
	}

	const isItemEdited = () => {
		const editedFields = getEditedData()
		return isEdited(editedFields)
	}

	const dropError = (err) => {
		let newErrors = lodash.cloneDeep(stateRef.current.formErrors || {})
		delete newErrors[err]
		executeDispatch({
			...stateRef.current,
			formErrors: newErrors,
		})
	}
	const confirmDraftText = (
		<Space direction="vertical" size="small">
			<Typography.Text>
				Не заполнены обязательные поля или не добавлена ни одна строка в табличной вкладке.
			</Typography.Text>
			<Typography.Text>
				При сохранении карточка будет сохранена со статусом "Черновик".
			</Typography.Text>
			<Typography.Text>При отмене продолжится редактирование карточки.</Typography.Text>
		</Space>
	)
	const confirmDraft = async (title) => {
		const isDraft = await asyncShowConfirmModal({
			...showSaveAsDraft,
			title: title ? (
				<Space direction="vertical" size="small">
					{title.split('.').map((txt) => (
						<Typography.Text>{txt}</Typography.Text>
					))}
				</Space>
			) : (
				confirmDraftText
			),
		})
		if (isDraft) stateFunctions.setStatus('Черновик')
		return isDraft
	}
	const validators = {}

	// const checkReportCopiesTotalWeight = (inUuid) => {
	// let formErrors = {}
	// const currentReport = stateFunctions.reports.get(inUuid)
	// const parent = currentReport.parentUuid
	// 	? stateFunctions.reports.get(currentReport.parentUuid)
	// 	: currentReport
	// const allCopies = stateRef.current.data.reports.filter((rep) =>
	// 	rep.isCopy ? rep.parentUuid === parent._uuid_ : rep._uuid_ === parent._uuid_
	// )
	// if (allCopies.length > 1) {
	// 	const initialReport = stateRef.current.data.reports.find((rep) =>
	// 		allCopies.some((copyRep) => copyRep.id && copyRep.id === rep.id)
	// 	)
	// 	if (initialReport) {
	// 		const totalWeight = allCopies.reduce((sum, cur) => sum + (+cur.weight || 0), 0)
	// 		const initialWeight = Number(
	// 			allCopies.find((c) => c.initialParentWeight).initialParentWeight
	// 		)
	// 		if (totalWeight !== initialWeight) {
	// 			const action = totalWeight > initialWeight ? 'Уменьшить массу' : 'Увеличить массу'
	// 			const weightDiff = Math.abs(initialWeight - totalWeight)
	// 			if (weightDiff) {
	// 				formErrors[`weight.${initialReport._uuid_}`] = `${action} на = ${weightDiff} кг`
	// 				executeDispatch({
	// 					...stateRef.current,
	// 					formErrors: {
	// 						...stateRef.current.formErrors,
	// 						...formErrors,
	// 					},
	// 				})
	// 				return false
	// 			}
	// 		} else {
	// 			dropError(`weight.${initialReport._uuid_}`)
	// 		}
	// 	}
	// }
	// return true
	// }

	const validate = async ({ pre = false } = {}) => {
		let newFormErrors = {}
		const data = stateRef.current.data
		if (!data.complectations.length) {
			newFormErrors = { ...newFormErrors, complectations: true }
		}
		if (!data.reports.length) {
			newFormErrors = { ...newFormErrors, reports: true }
		}
		for (let p of data.complectations) {
			for (let f of getRequiredFields(p).complectations) {
				if (!basicValidator(p[f]) || p[f] === '0') {
					newFormErrors = { ...newFormErrors, [f + '.' + p._uuid_]: true }
				}
			}
		}

		data.reports.forEach((p) => {
			getRequiredFields(p).reports.forEach((f) => {
				const value = lodash.get(p, f)
				const isValidValue = Array.isArray(value)
					? !!value.length
					: basicValidator(value) && +value !== 0
				if (!isValidValue) {
					newFormErrors = { ...newFormErrors, [f + '.' + p._uuid_]: true }
				}
			})
			if (['Заблокировано', 'Удержано'].includes(p.status) && !p.blockCauses?.length) {
				newFormErrors = { ...newFormErrors, ['blockCauses.' + p._uuid_]: true }
			}
		})
		if (pre) {
			if (!checkUniqueArticul()) {
				throw Error(
					'Совпадают артикулы у партии, указанной на вкладке Отчет, и партии, взятой в производство на вкладке Комплектация. Карточка не может быть сохранена'
				)
			}
		}
		if (pre) {
			if (!checkAvailable()) {
				throw Error(
					'В комплектации указана масса, превышающая доступную. Карточка не может быть сохранена'
				)
			}
		}
		if (Object.keys(newFormErrors).length) {
			if (!pre) {
				executeDispatch({ ...stateRef.current, formErrors: newFormErrors })
				throw Error()
			} else {
				executeDispatch({ ...stateRef.current, formErrors: newFormErrors })
				if (data.reports.some((rep) => +rep.weight === 0)) {
					return 'Не заполнены обязательные поля или не добавлена ни одна строка в табличной вкладке.При сохранении карточка будет сохранена со статусом "Черновик".При отмене продолжится редактирование карточки'
				}
				if (['На согласовании', 'На утверждении'].includes(data.status)) {
					return ''
				}
				return 'Не заполнены обязательные поля или не добавлена ни одна строка в табличной вкладке.При сохранении карточка будет сохранена со статусом "Черновик".При отмене продолжится редактирование карточки'
			}
		}
		if (!checkRepSum()) {
			if (['На согласовании', 'На утверждении'].includes(stateRef.current.data.status)) {
				const complectationsSum = numToFixed(
					stateRef.current.data.complectations.reduce((sum, cur) => {
						return sum + (+cur.weight || 0)
					}, 0),
					1
				)
				const reportsSum = numToFixed(
					stateRef.current.data.reports.reduce((sum, cur) => {
						return sum + (+cur.weight || 0)
					}, 0),
					1
				)
				const action = reportsSum > complectationsSum ? 'Уменьшить массу' : 'Увеличить массу'
				throw Error(
					`Сумма произведенной продукции не равна общей массе, взятой в производство. ${action} на = ${numToFixed(
						Math.abs(complectationsSum - reportsSum),
						2
					)} кг`
				)
			}
			if (pre) {
				return 'Сумма произведенной продукции не равна общей массе, взятой в производство.Отчет не может быть отправлен на согласование'
			}
			throw Error()
		}
		if (!isDisabledByChangeStatusBtn) {
			stateFunctions.setStatus('Ожидает отправки')
		}
		return ''
	}

	const isModalItemDisabled = (record) => {
		const { data, addComplect } = stateRef.current
		const { isSavePartyNum, complectations } = data
		const {
			addComplectStockRawMatStor,
			addComplectStockSemifStor,
			addComplectStockReadyProdStor,
			addComplectStockReadyProdResaleStor,
		} = addComplect
		const isAddComplectOrComplectationsLength =
			!!addComplectStockRawMatStor.length ||
			!!addComplectStockSemifStor.length ||
			!!addComplectStockReadyProdStor.length ||
			!!addComplectStockReadyProdResaleStor.length ||
			!!complectations?.length
		if (isSavePartyNum) {
			return isAddComplectOrComplectationsLength
		}
		const idContrOrder =
			addComplectStockRawMatStor[0]?.idContrOrder ||
			addComplectStockSemifStor[0]?.idContrOrder ||
			addComplectStockReadyProdStor[0]?.idContrOrder ||
			complectations[0]?.stockRawMatStor?.idContrOrder ||
			complectations[0]?.stockSemifStor?.idContrOrder ||
			complectations[0]?.stockReadyProdStor?.idContrOrder

		if (idContrOrder) {
			if (idContrOrder) {
				return record.idContrOrder && record.idContrOrder === idContrOrder ? false : true
			}
		}
		if (!idContrOrder && isAddComplectOrComplectationsLength) {
			return record.idContrOrder ? true : false
		}
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		// проверка с id нужна для того чтобы не подумать что мы создаем копию на этой же странице
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			formErrors: {},
			addComplect: {
				__isOpen: false,
				__name: '',
				addComplectStockRawMatStor: [],
				addComplectStockSemifStor: [],
				addComplectStockReadyProdStor: [],
				addComplectStockReadyProdResaleStor: [],
			},
		})
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	/**
	 * подготавливает данные которые нужно выбират
	 */

	useEffect(() => {
		if (params.id === 'new') {
			recalcMainPartyNum()
		}
		_reset()
	}, [_reset, params.id, stateRef.current.oldData])

	const selectors = {
		tasksForComplectations: [],
		allProdCatKindSemifs: stateRef.current.additional.allProdCatKindSemifs.filter(
			(s) => !stateRef.current.data.prodCat || stateRef.current.data.prodCat.id === s.idProdCat
		),
		allProdCatPkg: stateRef.current.additional.allProdCatPkg.filter(
			(s) => !stateRef.current.data.prodCat || stateRef.current.data.prodCat.id === s.idProdCat
		),
		allSpecs: stateRef.current.additional.allSpecs.filter(
			(s) => !stateRef.current.data.prodCat || stateRef.current.data.prodCat.id === s.idProdCat
		),
		allStaffInCharge: stateRef.current.additional.allStaffInCharge,
	}
	const {
		commonFieldUpdate,
		commonDeepFieldUpdate,
		getEditedData,
		isEdited,
		stateFunctions,
		serverEdit,
		serverDelete,
		setError,
		dropErrors,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{
			modCtx,
			dataUrl,
			params,
			requiredFields: getRequiredFields,
			pageUrl: '/production/tasks',
			history,
		},
		selectors,
		{
			date: 'common',
			spec: 'common',
			operation: 'common',
			contType: 'common',
			workingTime: 'common',
			customer: 'common',
			isSamples: 'common',
			isFabricatedParty: 'common',
			fabricatedNumText: 'common',
			fabricatedNumVal: 'common',
			status: 'common',
			partyNum: 'common',
			prodCatKind: 'common',
			articul: 'common',
			articul1C: 'common',
			planWeight: 'common',
			staffInCharge: 'common',
		},
		{
			complectations: {
				weight: 'common',
			},
			reports: {
				prodCatPkg: 'common',
				type: 'common',
				prodCatKindSemif: 'common',
				prodCatKindReadyProd: 'common',
				contType: 'common',
				room: 'common',
				partyNum: 'common',
				status: 'common',
				weight: 'common',
				weightPlan: 'common',
				nettoPkgWeight: 'common',
				blockCause: 'common',
				prodCatKind: 'common',
				articul: 'common',
				articul1C: 'common',
				isControlMd: 'common',
				comment: 'common',
				blockCauses: {
					blockCause: 'common',
				},
			},
		}
	)

	stateFunctions.setPartyNum = (partyNum) => {
		commonFieldUpdate('partyNum', partyNum)
		recalcRepPartyNums(stateRef.current.data.reports)
	}

	const recalcMainPartyNum = () => {
		let partyNum = getPartyNum()
		if (stateRef.current.data.isSavePartyNum) {
			const { stockRawMatStor, stockSemifStor, stockReadyProdStor, stockReadyProdResaleStor } =
				stateRef.current.data.complectations?.[0] || {}
			partyNum =
				stockRawMatStor?.stockRawMat.supplProd.assignedPartyNum ||
				stockSemifStor?.stockSemif.taskRep.partyNum ||
				stockReadyProdStor?.stockReadyProd.taskRep.partyNum ||
				stockReadyProdResaleStor?.stockReadyProdResale.supplProd.assignedPartyNum
		}
		stateFunctions.setPartyNum(partyNum)
	}

	const recalcRepPartyNums = (reports) => {
		for (const rep of reports) {
			commonDeepFieldUpdate(['reports'], [rep._uuid_], 'partyNum', null)
		}
		for (const rep of reports) {
			commonDeepFieldUpdate(['reports'], [rep._uuid_], 'partyNum', calcReportPartyNum(rep._uuid_))
		}
	}

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		selectors,
		[
			{ field: 'stockRawMatStor', updateVal: 'common', modalName: 'addComplect' },
			{ field: 'stockSemifStor', updateVal: 'common', modalName: 'addComplect' },
			{ field: 'stockReadyProdStor', updateVal: 'common', modalName: 'addComplect' },
			{ field: 'stockReadyProdResaleStor', updateVal: 'common', modalName: 'addComplect' },
			{ field: '__name', updateVal: 'common', modalName: 'addComplect' },
		],
		[
			{
				mainField: 'stockRawMatStor',
				field: 'stockRawMatStor',
				updateVal: 'common',
				modalName: 'addComplect',
			},
			{
				mainField: 'stockSemifStor',
				field: 'stockSemifStor',
				updateVal: 'common',
				modalName: 'addComplect',
			},
			{
				mainField: 'stockReadyProdStor',
				field: 'stockReadyProdStor',
				updateVal: 'common',
				modalName: 'addComplect',
			},
			{
				mainField: 'stockReadyProdResaleStor',
				field: 'stockReadyProdResaleStor',
				updateVal: 'common',
				modalName: 'addComplect',
			},
		]
	)

	stateFunctions.reports.setStatus = (inUuid, val) => {
		if (val === 'Допущено') {
			commonDeepFieldUpdate(['reports'], [inUuid], 'blockCauses', [])
		}
		commonDeepFieldUpdate(['reports'], [inUuid], 'status', val, true)
	}

	const serverMatch = async (obj) => {
		try {
			const res = (await axios.put(dataUrl, { ...obj, id: stateRef.current.data.id })).data
			executeDispatch({ ...stateRef.current, reloadUuid: v4() })
			goToItem(history, { url: '/production/tasks', id: stateRef.current.data.id })
			modCtx.set(modes.view)
			getCalcAllowMatchOrApproveForTask()
			return { method: 'put', id: res.result.data.id }
		} catch (err) {
			console.log(err.message)
		}
	}

	stateFunctions.setDate = async (date) => {
		const complectations = stateRef.current.data.complectations.filter((c) => {
			const complDate = moment(
				c.stockRawMatStor?.supplyDate ||
					c.stockReadyProdResaleStor?.supplyDate ||
					(c.stockSemifStor?.taskRep?.task || c.stockReadyProdStor?.taskRep?.task)?.date ||
					c.stockSemifStor?.date ||
					c.stockReadyProdStor.date,
				'YYYY-MM-DD'
			)
			return moment(complDate).isSameOrBefore(date)
		})
		commonFieldUpdate('complectations', complectations)
		commonFieldUpdate('date', date)
		if (!stateRef.current.data.isSavePartyNum) {
			commonFieldUpdate('partyNum', getPartyNum())
		}
		recalcRepPartyNums(stateRef.current.data.reports)
		if (stateRef.current.data.isFabricatedParty) {
			commonFieldUpdate('fabricatedNumVal', getFabricatedNumVal())
		}
	}
	stateFunctions.setIsSavePartyNum = (val) => {
		const reports = stateRef.current.data.reports?.filter((rep) => rep.type !== 'ДГП')
		commonFieldUpdate('reports', reports)
		commonFieldUpdate('isSavePartyNum', val)
		if (!val) {
			recalcMainPartyNum()
		} else {
			commonFieldUpdate('partyNum', null)
			commonFieldUpdate('complectations', [])
		}
	}
	stateFunctions.complectations.setWeight = (uuid, val) => {
		commonDeepFieldUpdate(['complectations'], [uuid], 'weight', val, true)
		if (stateRef.current.data.taskKind === 'Производство ПФ') {
			recalcMainPartyNum()
		}
		recalcRepPartyNums(stateRef.current.data.reports.filter((r) => r.type === 'ПФ'))
	}

	stateFunctions.complectations.create = async (inData, field) => {
		let newArr = [
			...lodash.cloneDeep(stateRef.current.data.complectations),
			...inData.map((e) => {
				let stor = {}
				if (e.idStockRawMat) stor = { idStockRawMatStor: e.idStorage }
				if (e.idStockSemif) stor = { idStockSemifStor: e.idStorage }
				if (e.idStockReadyProd) stor = { idStockReadyProdStor: e.idStorage }
				if (e.idStockReadyProdResale) stor = { idStockReadyProdResaleStor: e.idStorage }
				return getNewObj({ [field]: e, ...stor })
			}),
		]
		commonFieldUpdate('complectations', newArr)
		if (stateRef.current.data.isFabricatedParty) {
			commonFieldUpdate('fabricatedNumVal', getFabricatedNumVal())
		}
		recalcRepPartyNums(stateRef.current.data.reports.filter((r) => r.type === 'ПФ'))
		if (stateRef.current.data.taskKind === 'Производство ПФ') recalcMainPartyNum()
		return newArr.slice(-1)[0]._uuid_
	}
	stateFunctions.complectations.delete = (inUuid) => {
		const newEls = removeByUuid(inUuid, stateRef.current.data.complectations)
		if (stateRef.current.data.isSavePartyNum && !newEls.length) {
			commonFieldUpdate('partyNum', null)
		}
		commonFieldUpdate('complectations', newEls)
		recalcRepPartyNums(stateRef.current.data.reports.filter((r) => r.type === 'ПФ'))
		recalcMainPartyNum()
	}
	stateFunctions.setIsFabricatedParty = (val) => {
		const reports = stateRef.current.data.reports?.filter((rep) => rep.type !== 'ДГП')
		commonFieldUpdate('reports', reports)
		commonFieldUpdate('isFabricatedParty', val)
		if (val) {
			commonFieldUpdate('fabricatedNumText', 'Создать новый')
			commonFieldUpdate('fabricatedNumVal', getFabricatedNumVal())
		} else {
			commonFieldUpdate('fabricatedNumText', null)
			commonFieldUpdate('fabricatedNumVal', null)
		}
	}
	stateFunctions.setFabricatedNumText = (val) => {
		commonFieldUpdate('fabricatedNumText', val)
		if (val === 'Создать новый') {
			commonFieldUpdate('fabricatedNumVal', getFabricatedNumVal())
		} else {
			commonFieldUpdate('fabricatedNumVal', null)
		}
	}

	stateFunctions.setTaskKind = (val) => {
		if (stateRef.current.data.taskKind)
			executeDispatch({
				...stateRef.current,
				data: {
					...stateRef.current.data,
					isSamples: false,
					isFabricatedParty: false,
					isSavePartyNum: false,
					prodCatPkg: null,
					prodCatKindSemif: null,
					prodCatKind: null,
					articul: null,
					articul1C: null,
					level: stateRef.current.data.level,
					version: stateRef.current.data.version,
					status: stateRef.current.data.status,
					reports: [],
					complectations: [],
					eventHistories: [...(stateRef.current.data.eventHistories || [])],
				},
			})
		commonFieldUpdate('taskKind', val)
		stateFunctions.setPartyNum(getPartyNum())
	}

	stateFunctions.setProdCatPkg = (val) => {
		commonFieldUpdate('prodCatPkg', val)
		const spec = stateRef.current.additional.allSpecs.find((e) => {
			if (e.vocProdType?.label !== 'Готовая продукция') return false
			if (
				String(stateRef.current.data.prodCatPkg?.kindReadyProd?.id) !== String(e.kindReadyProd?.id)
			)
				return false
			if (String(stateRef.current.data.prodCatPkg?.vocQuality?.id) !== String(e.vocQuality?.id))
				return false
			if (e.status !== 'Утверждено') return false
			return true
		})
		commonFieldUpdate('spec', spec || null)

		const newRep = getNewObj({
			type: 'ГП',
			isFirst: true,
			prodCatPkg: lodash.cloneDeep(stateRef.current.data.prodCatPkg),
			contType: stateRef.current.data.prodCatPkg.vocContType,
			nettoPkgWeight: stateRef.current.data.prodCatPkg.weight,
			partyNum: stateRef.current.data.partyNum,
			prodCatKind: stateRef.current.data.prodCatPkg.displayVal,
			articul: stateRef.current.data.prodCatPkg.articul,
			articul1C: stateRef.current.data.prodCatPkg.articul1C,
		})

		commonFieldUpdate('reports', [newRep])
		if (!stateRef.current.data.partyNum) {
			commonDeepFieldUpdate(
				['reports'],
				[stateRef.current.data.reports[0]._uuid_],
				'partyNum',
				calcReportPartyNum(stateRef.current.data.reports[0]._uuid_)
			)
		}
	}

	stateFunctions.setProdCatKindSemif = (val) => {
		commonFieldUpdate('prodCatKindSemif', val)
		commonFieldUpdate('spec', null)
		const newRep = getNewObj({
			partyNum: getPartyNum({ type: 'ПФ' }),
			type: 'ПФ',
			isFirst: true,
			prodCatKindSemif: lodash.cloneDeep(stateRef.current.data.prodCatKindSemif),
			prodCatKind: stateRef.current.data.prodCatKindSemif.prodCatKind,
			articul: stateRef.current.data.prodCatKindSemif.articul,
			articul1C: stateRef.current.data.prodCatKindSemif.articul1C,
			...(val.vocProdType?.labelShort === 'НО' && {
				status: 'Брак',
			}),
		})
		commonFieldUpdate('reports', [newRep])
	}
	stateFunctions.setProdCatKindReadyProd = (val) => {
		commonFieldUpdate('spec', null)
		commonFieldUpdate('reports', [
			getNewObj({
				type: 'ГП',
				isFirst: true,
				partyNum: getPartyNum(),
				prodCatKindReadyProd: lodash.cloneDeep(stateRef.current.data.prodCatKindReadyProd),
			}),
		])
	}

	stateFunctions.setProdCat = (val) => {
		commonFieldUpdate('prodCat', val)
		commonFieldUpdate('prodCatPkg', null)
		commonFieldUpdate('prodCatKindSemif', null)
		commonFieldUpdate('spec', null)
		commonFieldUpdate('reports', [])
		commonFieldUpdate('complectations', [])
		commonFieldUpdate('prodCatKind', null)
		commonFieldUpdate('articul', null)
		commonFieldUpdate('articul1C', null)
	}

	stateFunctions.reports.setType = (inUuid, el) => {
		for (const err in stateRef.current.formErrors) {
			if (err.includes(inUuid)) {
				dropError(err)
			}
		}
		if (el === 'ОК' || el === 'ГП') {
			if (stateRef.current.data.prodCatPkg) {
				if (stateRef.current.data?.taskKind !== 'Производство ПФ')
					stateFunctions.reports.setProdCatPkg(inUuid, stateRef.current.data.prodCatPkg)
				if (el === 'ГП') {
					stateFunctions.reports.setContType(inUuid, stateRef.current.data.prodCatPkg.vocContType)
					stateFunctions.reports.setNettoPkgWeight(
						inUuid,
						stateRef.current.data.prodCatPkg.weight,
						false
					)
				} else {
					stateFunctions.reports.setContType(inUuid, null, false)
					stateFunctions.reports.setNettoPkgWeight(inUuid, null, false)
				}
			}
			if (stateRef.current.data.prodCatKindSemif) {
				if (stateRef.current.data?.taskKind !== 'Производство ПФ')
					stateFunctions.reports.setProdCatKindSemif(inUuid, stateRef.current.data.prodCatKindSemif)
			}
		} else {
			stateFunctions.reports.setProdCatKindSemif(inUuid, null, false)
			stateFunctions.reports.setContType(inUuid, null, false)
			stateFunctions.reports.setProdCatPkg(inUuid, null, false)
			stateFunctions.reports.setNettoPkgWeight(inUuid, null, false)
		}
		commonDeepFieldUpdate(['reports'], [inUuid], 'type', el)
	}

	stateFunctions.reports.setProdCatKindSemif = (inUuid, el, required) => {
		commonDeepFieldUpdate(['reports'], [inUuid], 'prodCatKindSemif', el, true)
		if (el) {
			if (el.vocProdType?.labelShort === 'НО') {
				commonDeepFieldUpdate(['reports'], [inUuid], 'status', 'Брак')
			} else {
				commonDeepFieldUpdate(['reports'], [inUuid], 'status', undefined)
			}
			const fieldsArr = ['articul', 'articul1C', 'prodCatKind']
			fieldsArr.forEach((field) => {
				dropError(`prodCatKindSemif.${field}.${inUuid}`)
			})
		}
		commonDeepFieldUpdate(['reports'], [inUuid], 'prodCatPkg', null)
	}
	stateFunctions.reports.setProdCatPkg = (inUuid, el, required) => {
		if (el) {
			const fieldsArr = ['articul', 'articul1C', 'prodCatKind']
			fieldsArr.forEach((field) => {
				dropError(`prodCatPkg.${field}.${inUuid}`)
			})
		}
		commonDeepFieldUpdate(['reports'], [inUuid], 'prodCatPkg', el, required)
		stateFunctions.reports.setContType(inUuid, el?.vocContType || null, required)
		stateFunctions.reports.setNettoPkgWeight(inUuid, el?.weight || null, required)
		commonDeepFieldUpdate(['reports'], [inUuid], 'prodCatKindSemif', null)
	}

	// stateFunctions.reports.getParent = (inUuid) => {
	// 	const rep = stateFunctions.reports.get(inUuid)
	// 	if (rep.isCopy) {
	// 		return stateFunctions.reports.get(rep.parentUuid)
	// 	}
	// 	return null
	// }

	// stateFunctions.reports.delete = (inUuid) => {
	// 	const parent = stateFunctions.reports.getParent(inUuid)
	// 	const newEls = removeByUuid(inUuid, stateRef.current.data.reports)
	// 	commonFieldUpdate('reports', newEls)
	// 	if (parent) {
	// 		dropError(`weight.${parent._uuid_}`)
	// 		checkReportCopiesTotalWeight(parent._uuid_)
	// 	}
	// }

	const serverSendTo1c = async () => {
		let body = { id: stateRef.current.data.id }
		const complectationsArticuls = stateRef.current.data.complectations.map((e) => {
			return {
				id: e.id,
				articul: getArticul(e),
			}
		})
		const reportsArticuls = stateRef.current.data.reports.map((e) => {
			let articul = e.prodCatPkg?.articul || e.prodCatKindSemif?.articul
			return {
				id: e.id,
				articul: articul,
			}
		})
		body.complectationsArticuls = complectationsArticuls
		body.reportsArticuls = reportsArticuls
		body.event = isDevAdmin ? 'Принято в 1С' : 'Отправка в 1С '
		const options = { dataUrl, url: `${dataUrl}/send-to-1c`, method: 'PUT' }
		const id = (await axios.put(`${dataUrl}/send-to-1c`, body)).data

		executeDispatch({ ...stateRef.current, reloadUuid: v4() })
		goToItem(history, { url: '/production/tasks', id: stateRef.current.data.id })
		getCalcAllowMatchOrApproveForTask()

		return { method: options.method, id: id }
	}
	const checkRepSum = () => {
		const reportsSum = numToFixed(
			stateRef.current.data.reports.reduce((sum, cur) => {
				return sum + (+cur.weight || 0)
			}, 0),
			1
		)
		const complectationsSum = numToFixed(
			stateRef.current.data.complectations.reduce((sum, cur) => {
				return sum + (+cur.weight || 0)
			}, 0),
			1
		)
		const result = +reportsSum === +complectationsSum
		if (!result) {
			let errors = {}
			for (const c of stateRef.current.data.complectations) {
				errors = { ...errors, [`weight.${c._uuid_}`]: true }
			}
			executeDispatch({
				...stateRef.current,
				formErrors: {
					...stateRef.current.formErrors,
					...errors,
				},
			})
		}

		return result
	}
	const checkUniqueArticul = () => {
		for (const complect of stateRef.current.data.complectations) {
			const complectArticul = getArticul(complect)
			if (
				stateRef.current.data.reports.some(
					(rep) => (rep.prodCatPkg || rep.prodCatKindSemif)?.articul === complectArticul
				)
			) {
				return false
			}
		}
		return true
	}

	const getAvailableWeight = (complect) => {
		let key = null
		if (complect.stockRawMatStor) {
			key = 'stockRawMatStor'
		} else if (complect.stockSemifStor) {
			key = 'stockSemifStor'
		} else if (complect.stockReadyProdStor) {
			key = 'stockReadyProdStor'
		} else if (complect.stockReadyProdResaleStor) {
			key = 'stockReadyProdResaleStor'
		}

		if (complect[key]?.status === 'Бронь' && complect[key].parent?.status === 'Допущено') {
			return +complect[key].weight + (+complect[key].parent?.weight || 0)
		}
		return +complect[key]?.weight || 0
	}

	const checkAvailable = () => {
		let errors = []
		for (let p of stateRef.current.data.complectations) {
			const availableWeight = getAvailableWeight(p)
			let formErrors = { ...stateRef.current.formErrors }
			if (availableWeight < +p.weight) {
				formErrors[`weight.${p._uuid_}`] = `Доступная масса = ${availableWeight} кг`
				errors.push(p)
			} else if (formErrors[`weight.${p._uuid_}`] === true) {
				formErrors[`weight.${p._uuid_}`] = true
			}
			executeDispatch({
				...stateRef.current,
				formErrors: {
					...stateRef.current.formErrors,
					...formErrors,
				},
			})
		}

		if (errors.length) {
			return false
		}
		return true
	}

	const isDisabledByChangeStatusBtn =
		((role === 'approver' && canApprove && status === 'На утверждении') ||
			(role === 'matcher' && canMatch && status === 'На согласовании')) &&
		(!{
			isLoaded: stateRef.current.calcDataLoaded.isAllowMatchOrApproveForTask,
			isAllowMatchOrApproveForTask: stateRef.current.isAllowMatchOrApproveForTask,
		} ||
			{
				isLoaded: stateRef.current.calcDataLoaded.isAllowMatchOrApproveForTask,
				isAllowMatchOrApproveForTask: stateRef.current.isAllowMatchOrApproveForTask,
			}.isAllowMatchOrApproveForTask)

	const reportCopy = async (rec) => {
		const clonedData = stateFunctions.reports.clone(
			rec._uuid_,
			Object.keys(rec).filter((k) => k !== 'id' && k !== '_uuid_' && k !== 'isFirst')
		)
		clonedData.isCopy = true
		clonedData._uuid_ = v4()
		// clonedData.initialParentWeight = rec.weight
		// clonedData.parentUuid = rec._uuid_
		// await stateFunctions.reports.create(clonedData)
		const foundRepIdx = stateRef.current.data.reports.findIndex((r) => r._uuid_ === rec._uuid_)
		const reports = [
			...stateRef.current.data.reports.slice(0, foundRepIdx + 1),
			clonedData,
			...stateRef.current.data.reports.slice(foundRepIdx + 1, stateRef.current.data.reports.length),
		]
		commonFieldUpdate('reports', reports)

		// checkReportCopiesTotalWeight(rec._uuid_)
	}

	const keysForMatch = {
		isInitiator: isProdTaskPf ? 'isInitiatorProdTaskPf' : 'isInitiatorProdTask',
		lvlMatch: isProdTaskPf ? 'lvlMatchProdTaskPf' : 'lvlMatchProdTask',
		lvlApprove: isProdTaskPf ? 'lvlApproveProdTaskPf' : 'lvlApproveProdTask',
	}
	const keyForMatchType = keysForMatch.isInitiator === 'isInitiatorProdTaskPf' ? 'taskPf' : 'task'
	const value = {
		state: stateRef.current.data,
		isPendingReq: stateRef.current.isPendingReq,
		isInitializedMain: stateRef.current.isInitializedMain,
		isInitializedAdditional: stateRef.current.isInitializedAdditional,
		tabsLoading: stateRef.current.tabsLoading,
		calcDataLoaded: stateRef.current.calcDataLoaded,
		additional: stateRef.current.additional,
		isAllowMatchOrApproveForTask: stateRef.current.isAllowMatchOrApproveForTask,
		keysForMatch,
		keyForMatchType,
		isProdTaskPf,
		stateFunctions,
		serverEdit,
		serverDelete,
		isEdited: isItemEdited,
		validate,
		reset,
		validators: validators,
		formErrors: stateRef.current.formErrors,
		selectors,
		setError,
		modalFunctions,
		addComplect: lodash.cloneDeep(stateRef.current.addComplect),
		deletedProds: lodash.cloneDeep(stateRef.current.deletedProds),
		serverMatch,
		serverSendTo1c,
		getPartyNum,
		checkAvailable,
		// checkReportCopiesTotalWeight,
		getAvailableWeight,
		dropError,
		dropErrors,
		checkRepSum,
		calcReportPartyNum,
		confirmDraft,
		isModalItemDisabled,
		delEditDeps: stateRef.current.delEditDeps,
		isDisabledByChangeStatusBtn,
		reportCopy,
		setDeletedProds,
	}

	return (
		<ProductionTaskItemMainContext.Provider value={value}>
			{children}
		</ProductionTaskItemMainContext.Provider>
	)
}

export { Provider, ProductionTaskItemMainContext }
