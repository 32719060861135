import { useContext, useEffect, useRef } from 'react'
import { SyncDepsContext } from '../../contexts'
import { axios, prepareObjFromServer } from '../helpers'
import lodash from 'lodash'

/**
 * получает данные карточки (Main, Tabs, Additional) и записывает их в stateRef.current, а так же подаисывается на зависимости
 * @param {String} dataUrl - ссылка на реестр
 * @param {String|Number} id - id карточки
 * @param {Object} tabs - пары url:tabName. URL для вкладки: /{dataUrl}/{url}, в params будет лежать id.
 * В stateRef.current.data данные будут лежать по ключу tabName
 * @param {Ref} stateRef - stateRef
 * @param {Function} executeDispatch - executeDispatch  в вызове хука нужно обязательно завернуть в useCallback: useCallback(executeDispatch, [])
 * @param {Object} optional - хранится всякие опционалиные данные
 */

const useItemFetchers = (dataUrl, id, tabs, stateRef, executeDispatch, optional = {}) => {
	const syncDepsCtx = useContext(SyncDepsContext)
	const state = useRef({ isAllLoaded: true })

	const getTabs = (options = {}) => {
		const fetchTab = async (url, tab) => {
			const result = await axios(`${dataUrl}/${url}`, { params: { id } })
			syncDepsCtx.setDepsInfo({ deps: result.data.syncDeps, isMerge: true })
			const tabValue = result.data ? prepareObjFromServer(result.data.mainData) : []
			executeDispatch({
				...stateRef.current,
				tabsLoading: {
					...stateRef.current.tabsLoading,
					[tab]: false,
				},
				data: {
					...stateRef.current.data,
					...stateRef.current.tabs,
					[tab]: tabValue,
				},
				oldData: {
					...stateRef.current.oldData,
					...stateRef.current.tabs,
					[tab]: tabValue,
				},
			})
		}
		const result = []
		Object.entries(tabs).forEach(([url, tabKey]) => {
			if (!options.isWithoutLoading) {
				executeDispatch({
					...stateRef.current,
					tabsLoading: { ...stateRef.current.tabsLoading, [tabKey]: true },
				})
			}
			result.push(fetchTab(url, tabKey))
		})
		return result
	}
	const getAdditional = async () => {
		const res = await axios(`${dataUrl}/additional`, { ...(id && { params: { id } }) })
		executeDispatch({
			...stateRef.current,
			additional: {
				...stateRef.current.additional,
				...prepareObjFromServer(res.data.additional),
			},
			isInitializedAdditional: true,
		})
	}
	const getDeps = async () => {
		const fromServerResp = await axios(`${dataUrl}/del-edit-deps/${id}`)
		executeDispatch({ ...stateRef.current, delEditDeps: fromServerResp.data.mainData })
	}

	const getMain = async () => {
		let data = {}
		let oldData = {}
		const res = await axios(`${dataUrl}/${id}`, {})
		syncDepsCtx.setDepsInfo({ deps: res.data.syncDeps, isMerge: true })
		data = {
			...stateRef.current.data,
			...prepareObjFromServer(lodash.cloneDeep(res.data.mainData)),
		}
		oldData = { ...stateRef.current.oldData, ...res.data.mainData }
		executeDispatch({
			...stateRef.current,
			data,
			oldData,
			isInitializedMain: true,
		})
	}
	const getInitialData = async () => {
		const res = await axios(`${dataUrl}/${optional.initialDataUrl}`, {})
		let data = { ...res.data.mainData }
		let oldData = { ...res.data.mainData }
		executeDispatch({
			...stateRef.current,
			data,
			oldData,
			isInitializedMain: true,
		})
	}
	const setDeps = async () => {
		await syncDepsCtx.setDepsInfo({
			deps: {},
		})
	}
	const refetch = () => {
		getMain()
		getTabs()
	}
	useEffect(() => {
		const promises = []
		promises.push(setDeps())
		if (id !== 'new') {
			promises.push(getMain())
			promises.push(getDeps())
			if (tabs && Object.keys(tabs).length) {
				promises.push(
					...getTabs({
						isWithoutLoading: state.current.isAllLoaded,
					})
				)
			}
		} else if (optional.initialDataUrl) {
			promises.push(getInitialData())
		}
		promises.push(getAdditional())
		Promise.all(promises).then(() => {
			state.current.isAllLoaded = true
		})
	}, [
		executeDispatch,
		dataUrl,
		id,
		stateRef,
		tabs,
		syncDepsCtx.state.reloadUuids['office-ms'],
		stateRef.current.reloadUuid,
	])
	return { refetch }
}

export default useItemFetchers
