import React, { useContext } from 'react'
import { Main } from '../../../../layouts'
import { Provider, PosTypeContext } from './provider/main'
import { HeaderMenu, StatefulTable } from '../../../../components'
import { getColumns } from './pos-type-list'
import { Card } from 'antd'
import { goToItem } from '../../../../utils'
import { useHistory } from 'react-router'
import { pageUrl } from '../position-type'

const PosTypeList = () => {
	return (
		<Provider>
			<Main title="Типы должностей">
				<PosTypeListTable />
			</Main>
		</Provider>
	)
}

const PosTypeListTable = () => {
	const mainCtx = useContext(PosTypeContext)
	const history = useHistory()
	return (
		<>
			<HeaderMenu
				rights={{
					Справочники: {
						'Типы должностей': {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle="Типы должностей"
				canCreate
				search={mainCtx.setSearch}
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					dataSource={mainCtx.allData}
					columns={getColumns}
					rowKey={(rec) => `${rec.id}_${rec.indxMain}`}
					url={pageUrl}
				/>
			</Card>
		</>
	)
}
export default PosTypeList
