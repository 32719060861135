import { Checkbox } from 'antd'
import { frontSorter, getColumnSearchProps } from '../../../utils'

export const getColumns = (data) => [
	{
		title: 'Код 1С',
		dataIndex: 'code',
		key: 'code',
		width: '6%',
	},
	{
		title: 'Наименование',
		dataIndex: 'description',
		key: 'description',
		ellipsis: { showTitle: true },
		width: '25%',
		sorter: frontSorter('string', 'description'),
		...getColumnSearchProps([...new Set(data.map((e) => e.description))], undefined, 'description'),
	},
	{
		title: 'Артикул',
		dataIndex: 'vendorCode',
		key: 'vendorCode',
		width: '5%',
		sorter: frontSorter('number', 'vendorCode'),
		...getColumnSearchProps([...new Set(data.map((e) => e.vendorCode))], undefined, 'vendorCode'),
	},
	{
		title: 'Группа',
		dataIndex: 'group',
		key: 'group',
		width: '10%',
		ellipsis: { showTitle: true },
		...getColumnSearchProps([...new Set(data.map((e) => e.group))], undefined, 'group'),
	},
	{
		title: 'Привязано к Прод. каталогу',
		dataIndex: 'isBinded',
		key: 'isBinded',
		width: '6%',
		sorter: frontSorter('boolean', 'isBinded'),
		render: (value) => <Checkbox disabled checked={value} />,
	},
]
