import React from 'react'

import { Spinner } from '../../components'
import { FullPage } from '../../layouts'

const Loading = () => (
	<FullPage>
		<Spinner size="large" />
	</FullPage>
)

export default Loading
