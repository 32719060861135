import React, { useContext, useEffect } from 'react'
import { Layout, Space } from 'antd'

import { MenuContext, menuPositions } from '../../contexts'
import { Drawer, Menu } from '../../components'
import { Header } from '../index'

const { Content } = Layout

const Main = (props) => {
	const { title, children } = props
	const { collapsed, position } = useContext(MenuContext)
	useEffect(() => {
		const els = document.getElementsByClassName('ant-layout-sider')
		const body = document.body
		const html = document.documentElement
		const height = Math.max(
			// body.scrollHeight,
			body.offsetHeight,
			// html.clientHeight,
			html.scrollHeight /* ,
			html.offsetHeight */
		)
		for (let el of els) {
			el.style.height = `${height}px`
			console.log()
		}
	})
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Drawer />
			{position === menuPositions.left ? (
				<>
					<Menu collapsed={collapsed} />
					<Layout>
						<Header title={title} />
						<Content style={{ padding: '10px 20px', width: '100%' }}>
							<>
								{children}
								<div style={{ height: '150px', width: '100%' }}></div>
							</>
						</Content>
					</Layout>
				</>
			) : (
				<Layout>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Header title={title} />
						<Content style={{ padding: '10px 20px' }}>
							<>
								{children}
								<div style={{ height: '150px', width: '100%' }}></div>
							</>
						</Content>
					</Space>
				</Layout>
			)}
		</Layout>
	)
}

export default Main
