import React, { useContext } from 'react'
import { Main } from '../../../../layouts'
import { Provider, StockWasteListMainContext } from './provider/main'
import { HeaderMenu, StatefulTable } from '../../../../components'
import { getColumns, getToXlsx } from './waste-list'
import { Card, Checkbox, Row, Col, Space, Typography, Modal, Button } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import ModalsForList from './ModalsForList'
import { UserDataContext, ListContext, AuthContext } from '../../../../contexts'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { checkRole } from '@berry/common-functions/role'
import { ExcelIcon } from '../../../../assets'
import saveAsXlsx from '@berry/common-functions/excel'
import LoadingButton from './../../../../components/LoadingButton/LoadingButton'
import { getAllDataForXl } from './../../../../utils/helpers/cross-pages-funcs'
import WeightFilterInputs from '../../../../components/WeightFilter/WeightFilter'

const pageUrl = '/stock/wastes'
const dataUrl = '/stock/wastes'

const StockWasteList = (props) => {
	return (
		<Provider>
			<Main title={'Отходы'}>
				<StockWasteListTable />
			</Main>
		</Provider>
	)
}

const StockWasteListTable = () => {
	const mainCtx = useContext(StockWasteListMainContext)
	const userDataCtx = useContext(UserDataContext)
	const authCtx = useContext(AuthContext)

	const {
		state: { mainFilter, filters, isInStock, sorter, weightFrom, weightTo },
		setIsInStock,
	} = useContext(ListContext)
	const getModalTitle = () => {
		if (mainCtx.utilizationModal.__isOpen) return 'Утилизация'
		if (mainCtx.relocationModal.__isOpen) return 'Перемещение'
	}

	const canEdit = checkRole(
		{
			'АРМ офиса': {
				'Реестр Запасы': {
					edit: true,
				},
			},
		},
		userDataCtx.state.rights
	)
	const canViewRow = checkRole(
		{
			'АРМ офиса': {
				'Карточка Запасы': {
					view: true,
				},
			},
		},
		userDataCtx.state.rights
	)

	return (
		<>
			<HeaderMenu
				breadcrumbsTitle={'Отходы'}
				search={mainCtx.setSearch}
				additionalButtonsPre={[
					<LoadingButton
						type="text"
						title="Сохранить в Excel файл"
						icon={<ExcelIcon />}
						disabled={!mainCtx.toDisplayDataList.length}
						onClick={async () => {
							const { data } = await getAllDataForXl(
								dataUrl,
								filters,
								mainFilter,
								isInStock,
								weightFrom,
								weightTo
							)
							const toXlsx = getToXlsx(
								data,
								getColumns({
									dataSource: data,
									mainCtx,
									canEdit,
									fromServerFilters: mainCtx.state.fromServerFilters,
									sorter,
								}),
								['partyNumForSorter']
							)
							saveAsXlsx(toXlsx)
						}}
					/>,
				]}
			/>
			<Card>
				<Card>
					<Row justify="space-between">
						<Col>
							<Space>
								<Typography.Text strong>В наличии</Typography.Text>
								<Checkbox
									checked={isInStock}
									onChange={(e) => {
										setIsInStock(e.target.checked)
									}}
								/>
								<WeightFilterInputs />
							</Space>
						</Col>
						<Col>
							<Space>
								<Typography.Text strong>
									Общая масса, кг: {numToFixed(mainCtx.fromServerTotalSum, 1)}
								</Typography.Text>
							</Space>
						</Col>
					</Row>
				</Card>
				<StatefulTable
					rowKey={(el) => `${el.id}-${el.idStorage}`}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columnProps={{
						mainCtx,
						canEdit,
						fromServerFilters: mainCtx.state.fromServerFilters,
						sorter,
						authCtx,
						userDataCtx,
					}}
					columns={getColumns}
					dataSource={mainCtx.toDisplayDataList}
					url={pageUrl}
					canViewRow={canViewRow}
					searchFields={mainCtx.searchFields}
					filterStatuses={mainCtx.filterStatuses}
				/>
			</Card>
			<Modal
				onCancel={() => {
					mainCtx.utilizationModal.__isOpen
						? mainCtx.modalFunctions.closeUtilizationModal()
						: mainCtx.modalFunctions.closeRelocationModal()
					mainCtx.resetModals()
				}}
				cancelButtonProps={{ style: { display: 'none' } }}
				title={getModalTitle()}
				showCancel={false}
				width={'25%'}
				centered={true}
				icon={<ExclamationCircleOutlined />}
				visible={mainCtx.utilizationModal.__isOpen || mainCtx.relocationModal.__isOpen}
				destroyOnClose
				maskClosable={false}
				footer={[
					<Button
						key="ok-footer-button"
						type="primary"
						form="sale"
						htmlType="submit"
						autoFocus
						loading={mainCtx.isModalsServerEditSend}
					>
						Ок
					</Button>,
				]}
			>
				<ModalsForList />
			</Modal>
		</>
	)
}
export default StockWasteList
