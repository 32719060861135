import { createContext, useReducer, useRef } from 'react'
import { axios } from '../../utils'

export const AxiosContext = createContext(null)

AxiosContext.displayName = 'AxiosContext'

const reducer = (state) => ({ ...state })

const initialState = {
	data: null,
	error: '',
	loaded: false,
	loading: false,
}

export const AxiosInstanceProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const instanceRef = useRef(axios)

	instanceRef.current.interceptors.request.use(
		(config) => {
			if (!stateRef.current.loading) {
				executeDispatch({
					...state,
					loading: true,
				})
			}
			return config
		},
		(error) => {
			console.log(error)
			executeDispatch({
				...state,
				loading: false,
			})
			throw error
		}
	)
	instanceRef.current.interceptors.response.use(
		(response) => {
			if (stateRef.current.loading) {
				executeDispatch({
					...state,
					loading: false,
				})
			}
			return response
		},
		(error) => {
			console.log(error)
			executeDispatch({
				...state,
				loading: false,
			})
			throw error
		}
	)

	const value = {
		state: stateRef.current,
		axios: instanceRef.current,
	}
	return <AxiosContext.Provider value={value}>{children}</AxiosContext.Provider>
}
