import { Space } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

export const columns = () => [
	{
		title: 'Наименование',
		dataIndex: 'label',
		key: 'label',
		width: '35%',
	},
	{
		title: 'Артикул',
		dataIndex: 'displayCode',
		key: 'displayCode',
		width: '35%',
	},
	{
		title: 'Сырье',
		dataIndex: 'isRawMat',
		key: 'isRawMat',
		width: '12%',
		render: (isRawMat) =>
			isRawMat && (
				<Space size="middle">
					<CheckOutlined />
				</Space>
			),
	},
	{
		title: 'ГП',
		dataIndex: 'isReadyProd',
		key: 'isReadyProd',
		width: '12%',
		render: (isReadyProd) =>
			isReadyProd && (
				<Space size="middle">
					<CheckOutlined />
				</Space>
			),
	},
]
