import moment from 'moment'
import { dateFormat } from '../..//../../utils/constants/common'
import {
	getProdCatPackageLabel,
	getProdNameByProviderQuota,
} from '@berry/common-functions/cross-project-functions'
import { Checkbox } from 'antd'
export const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 },
}

export const getColumns = (mainCtx) => {
	const foundProduct = mainCtx.state.products.find((p) => p._uuid_ === mainCtx.addOrder.uuidProd)
	const modalFunctions = mainCtx.modalFunctions.orderModalFunctions
	let items = [
		{
			key: 'checkbox',
			width: '2%',
			render: (_, record) => (
				<Checkbox
					checked={mainCtx.addOrder.addOrderOrders.find((q) => q.id === record.id)}
					onChange={(e) => {
						if (e.target.checked) {
							modalFunctions.addOrderOrders.create(record)
						} else {
							modalFunctions.addOrderOrders.delete(
								mainCtx.addOrder.addOrderOrders.find((q) => q.id === record.id)._uuid_
							)
						}
					}}
				/>
			),
		},
		{
			title: 'ID ДЗ',
			dataIndex: 'id',
			width: '5%',
		},
		...(foundProduct.contract.frame
			? [
					{
						title: 'ДС',
						dataIndex: 'displayCode',
						width: '5%',
						required: true,
						render: (_, record) => record?.application?.displayCode,
					},
			  ]
			: []),
		{
			title: 'Продукт',
			dataIndex: 'rmProvProd',
			width: '5%',
			required: true,
			render: (_, record) => getProdNameByProviderQuota(record?.rmProvProd),
		},
		{
			title: 'Поставка план, кг',
			dataIndex: 'supplyPlanWeight',
			width: '5%',
		},
		{
			title: 'Поставка факт, кг',
			dataIndex: 'supplyFactWeight',
			width: '5%',
		},
		{
			title: 'Целевой продукт',
			dataIndex: 'prodCatPkg',
			width: '10%',
			required: true,
			render: (_, record) =>
				record?.rmProvProd?.prodCat?.label + getProdCatPackageLabel(record.prodCatPkg),
		},
		{
			title: 'Использование отходов',
			dataIndex: 'wasteManagement',
			width: '5%',
		},
		{
			title: 'Комментарий',
			dataIndex: 'comment',
			width: '5%',
		},
		{
			title: 'Дата отгрузки план',
			dataIndex: 'unloadDatePlan',
			width: '10%',
			render: (_, record) => moment(record.unloadDatePlan).format(dateFormat) || null,
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			width: '5%',
			render: (_, record) => record.status,
		},
	]
	return items
}
