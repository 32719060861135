import { Space } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

export const columns = () => [
	{
		title: 'Наименование',
		dataIndex: 'label',
		key: 'label',
		width: '15%',
	},

	{
		title: 'Поставка сырья',
		dataIndex: 'isSupplyRaw',
		key: 'isSupplyRaw',
		width: '15%',
		render: (isSupplyRaw) =>
			isSupplyRaw && (
				<Space size="middle">
					<CheckOutlined />
				</Space>
			),
	},
	{
		title: 'Обязательное наличие',
		dataIndex: 'isMandatAvail',
		key: 'isMandatAvail',
		width: '15%',
		render: (isMandatAvail) =>
			isMandatAvail && (
				<Space size="middle">
					<CheckOutlined />
				</Space>
			),
	},
	{
		title: 'Тип документа',
		dataIndex: 'type',
		key: 'type',
		width: '15%',
	},
	{
		title: 'Статус документа',
		dataIndex: 'status',
		key: 'status',
		width: '15%',
	},
]
