import React from 'react'
import { Form as FormAntd, Input, Checkbox } from 'antd'

const formItemLayout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

const Form = (props) => {
	const { label, displayCode, form, isRawMat, isReadyProd } = props

	return (
		<FormAntd labelAlign="left" form={form}>
			<FormAntd.Item
				{...formItemLayout}
				label="Наименование"
				getValueProps={(value) => value}
				name="label"
				onChange={label.onChange}
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Input value={label.value} />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Артикул"
				name="displayCode"
				onChange={displayCode.onChange}
				getValueProps={(value) => value}
				rules={[
					{
						required: true,
						message: false,
					},
					{ max: 5, message: 'Введите не более 5 символов' },
				]}
			>
				<Input value={displayCode.value} />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Сырье"
				name="isRawMat"
				valuePropName="isRawMat"
				getValueProps={(value) => value}
			>
				<Checkbox
					checked={isRawMat.value}
					onChange={(e) => {
						if (e.target.checked) {
							isRawMat.onChange(e)
							isReadyProd.onChange({ ...e, target: { ...e.target, checked: !e.target.checked } })
						}
					}}
				/>
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="ГП"
				name="isReadyProd"
				valuePropName="isReadyProd"
				getValueProps={(value) => value}
			>
				<Checkbox
					checked={isReadyProd.value}
					onChange={(e) => {
						if (e.target.checked) {
							isReadyProd.onChange(e)
							isRawMat.onChange({ ...e, target: { ...e.target, checked: !e.target.checked } })
						}
					}}
				/>
			</FormAntd.Item>
		</FormAntd>
	)
}

export default Form
