import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Card, Col, Row, Form } from 'antd'
import { ModContext, ModProvider, UserDataContext, AuthContext } from '../../../contexts'
import { Provider, StaffItemMainContext } from './provider/main'
import { Main } from '../../../layouts'
import {
	ActionButtons,
	asyncShowConfirmModal,
	CustomForm,
	HeaderMenu,
	showConfirmModal,
} from '../../../components'
import { getFormItems } from './staff-item'
import {
	actBtnOnClose,
	modes,
	actBtnOnDelete,
	actBtnOnSaveV2,
	openNewTab,
	axios,
} from '../../../utils'
import { getBreadcrumbsText } from './../../../utils/helpers/generators'
import { showIsBLockedModalForDeps } from '../../../utils/constants/for-components'
export const pageUrl = '/reg/staff'

const StaffItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Персонал">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}
const InfoComponent = () => {
	const mainCtx = useContext(StaffItemMainContext)
	const modCtx = useContext(ModContext)
	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const history = useHistory()
	const [form] = Form.useForm()

	const staffAdminSaveFunc = async (serverOnSave) => {
		if (mainCtx.state.isAdmin) {
			const adminData = mainCtx.additional.adminUsers
			if (adminData.length && adminData.every((p) => p.id !== mainCtx.state.id)) {
				const admin = adminData[0].fullName
				const currentUser = `${mainCtx.state.surname} ${mainCtx.state.name} ${
					mainCtx.state.patronymic || ''
				}`.trim()
				const res = await asyncShowConfirmModal({
					title: (
						<span style={{ whiteSpace: 'pre-line' }}>
							{`В настоящее время администратор системы ${admin}.
							Вместо него будет назначен ${currentUser}.`}
						</span>
					),
					okText: 'Ок',
					onCancel: async () => mainCtx.stateFunctions.setIsAdmin(false),
					isCancelBtnPrimary: true,
				})
				if (!res) {
					return false
				}
			}
		}
		return await serverOnSave()
	}
	const breadCrumb = getBreadcrumbsText(pageUrl, mainCtx.state)
	return (
		<>
			<HeaderMenu breadcrumbsTitle={breadCrumb.head} recordLabel={breadCrumb.label}>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						formName="staff"
						rights={{
							'АРМ офиса': {
								Персонал: {
									edit: true,
								},
							},
						}}
						onClose={() => {
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
								isCancelBtnPrimary: true,
							})
						}}
						onEdit={() => modCtx.set(modes.edit)}
						canDelete={modCtx.mod !== modes.new}
						onDelete={async () => {
							if (await mainCtx.checkIsBlocked()) {
								axios.post(`/logs`, {
									time: new Date().toISOString(),
									action: 'Delete',
									isBlockUnblockLogic: true,
									entity: 'regStaff',
									idEntity: mainCtx.state.id,
								})
								showConfirmModal(showIsBLockedModalForDeps)
								return
							}
							await actBtnOnDelete({
								history,
								pageUrl,
								showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								isCancelBtnPrimary: true,
								getDeps: mainCtx.getDepsOn,
								openNewPage: (url) =>
									openNewTab(url, {
										authCtx: authCtx.state,
									}),
								currentRights,
							})
						}}
						onCancel={() => {
							mainCtx.reset()
							form.resetFields()
						}}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							form={form}
							onFinish={async (values) => {
								if (await mainCtx.checkIsBlocked()) {
									axios.post(`/logs`, {
										time: new Date().toISOString(),
										action: 'Edit',
										isBlockUnblockLogic: true,
										entity: 'regStaff',
										idEntity: mainCtx.state.id,
									})
									showConfirmModal(showIsBLockedModalForDeps)

									return
								}
								const res = await actBtnOnSaveV2({
									serverEdit: mainCtx.serverEdit,
									validate: () => '',
									pageUrl: pageUrl,
									history: history,
									additionalFunc: staffAdminSaveFunc,
								})
								if (res) {
									const allFields = form.getFieldsError()
									allFields.forEach((f) => {
										f.errors = []
									})
									form.setFields(allFields)
								}
							}}
							name="staff"
							fields={[
								{ name: 'photo', value: mainCtx.state.photoPath },
								{ name: 'surname', value: mainCtx.state.surname },
								{ name: 'name', value: mainCtx.state.name },
								{ name: 'patronymic', value: mainCtx.state.patronymic },
								{ name: 'isWithoutPtrnmc', value: mainCtx.state.isWithoutPtrnmc },
								{
									name: 'posType',
									value: mainCtx.state.posType,
								},
								{ name: 'phoneNum', value: mainCtx.state.phoneNum?.replace('+7', '') },
								{ name: 'email', value: mainCtx.state.email },
								{
									name: 'status',
									value: mainCtx.state.status,
								},
								{ name: 'isGph', value: mainCtx.state.isGph },
								{ name: 'isAdmin', value: mainCtx.state.isAdmin },
								{ name: 'login', value: mainCtx.state.login },
							]}
							columns={[
								{ size: { xs: 24, lg: 4, xxl: 4 }, num: 0 },
								{ size: { xs: 24, lg: 6, xxl: 6 }, num: 1 },
								{ size: { xs: 24, lg: 8, xxl: 6 }, num: 2 },
								{ size: { xs: 24, lg: 6, xxl: 6 }, num: 3 },
							]}
							items={getFormItems(mainCtx, modCtx)}
						/>
					</Col>
				</Row>
			</Card>
		</>
	)
}

export default StaffItem
