import { setNumField } from '@berry/front-common/for-fields'
import { DisplayDataFromServer, EditDelBtns, InputError } from '../../../../components'
import { modes, openNewTab } from '../../../../utils'
import { dateFormat } from '../../../../utils/constants/common'
import moment from 'moment'

export const getArticul = (rec) => {
	return (
		rec.stockRawMatStor?.articul ||
		rec.stockSemifStor?.articul ||
		rec.stockReadyProdStor?.articul ||
		rec.stockReadyProdResaleStor?.articul
	)
}
/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 *  @param {Object} modCtx
 *  @param {function} editHandler
 */
export const getDisplayColumns = (mainCtx, modCtx, authCtx) => {
	const isDisabled = modCtx.mod === modes.view || mainCtx.isDisabledByChangeStatusBtn
	const stateFunctions = mainCtx.stateFunctions.complectations

	const getAssignedPartyNum = (_, rec) => {
		return (
			rec.stockRawMatStor ||
			rec.stockSemifStor ||
			rec.stockReadyProdStor ||
			rec.stockReadyProdResaleStor
		)?.partyNum
	}

	const getArticul1c = (rec) => {
		return (
			rec.stockRawMatStor ||
			rec.stockSemifStor ||
			rec.stockReadyProdStor ||
			rec.stockReadyProdResaleStor
		)?.articul1C
	}

	const getProdCatKind = (rec) => {
		return (
			rec.stockRawMatStor ||
			rec.stockSemifStor ||
			rec.stockReadyProdStor ||
			rec.stockReadyProdResaleStor
		)?.prodCatKind
	}

	return [
		{
			title: '№ партии',
			key: 'assignedPartyNum',
			width: '8.5%',
			render: getAssignedPartyNum,
		},
		{
			title: 'Артикул',
			key: 'articul',
			width: '8.5%',
			render: (_, rec) => {
				return getArticul(rec, mainCtx)
			},
		},
		{
			title: 'Артикул 1С',
			key: 'articul1c',
			width: '8.5%',
			render: getArticul1c,
		},
		{
			title: 'Вид',
			key: 'kind',
			render: getProdCatKind,
			width: '17.91%',
		},
		{
			title: 'Поставщик',
			key: 'provider',
			width: '20.91%',
			render: (_, rec) => {
				if (rec.stockReadyProdStor) return ''
				if (rec.stockRawMatStor) {
					return rec.stockRawMatStor.provider
				}
				if (rec.stockReadyProdResaleStor) {
					return rec.stockReadyProdResaleStor.provider
				}
				if (rec.stockSemifStor) {
					return (
						rec.stockSemifStor.provider || (
							<DisplayDataFromServer
								url={'/production/tasks/first-stock-raw-mat-in-task-complectations'}
								params={{ id: rec.stockSemifStor.idProdTask }}
								rerenderId={`stockSemifStor-${rec.stockSemifStor.id}`}
								field={'provider'}
							/>
						)
					)
				}
				return null
			},
		},
		{
			title: 'Производитель',
			key: 'ctrlRawMatManuf',
			width: '17.91%',
			render: (_, rec) => {
				if (rec.stockReadyProdStor) return ''
				if (rec.stockRawMatStor) {
					return rec.stockRawMatStor.ctrlRawMatManuf
				}
				if (rec.stockReadyProdResaleStor) {
					return rec.stockReadyProdResaleStor.ctrlRawMatManuf
				}
				if (rec.stockSemifStor) {
					return (
						rec.stockSemifStor.ctrlRawMatManuf || (
							<DisplayDataFromServer
								url={'/production/tasks/first-stock-raw-mat-in-task-complectations'}
								params={{ id: rec.stockSemifStor.idProdTask }}
								rerenderId={`stockSemifStor-${rec.stockSemifStor.id}`}
								field={'ctrlRawMatManuf'}
							/>
						)
					)
				}
				return null
			},
		},
		{
			title: 'Дата производства',
			key: 'taskDate',
			render: (_, record) =>
				record?.stockSemifStor?.taskDate || record.stockSemifStor?.date
					? moment(record.stockSemifStor?.taskDate || record.stockSemifStor.date).format(dateFormat)
					: null,
			width: '6%',
		},
		{
			title: 'Масса, кг',
			dataIndex: '',
			key: 'weight',
			rules: [{ required: true }],
			render: (_, rec) => {
				return (
					<InputError
						showCaption
						hasError={!!mainCtx.formErrors?.[`weight.${rec._uuid_}`]}
						message={
							mainCtx.formErrors?.[`weight.${rec._uuid_}`] ||
							`Доступная масса ${mainCtx.getAvailableWeight(rec)} кг`
						}
						style={{ width: '100%' }}
						value={rec.weight}
						disabled={isDisabled}
						onChange={(e) => {
							setNumField(e.target.value, rec.weight, stateFunctions.setWeight, 'float', [9, 2], {
								_uuid_: rec._uuid_,
								isOnlyNums: true,
							})
							mainCtx.checkAvailable()
						}}
					/>
				)
			},
			width: '11.77%',
		},
		{
			title: null,
			key: 'action',
			width: '3%',
			render: (text, rec) => {
				return (
					<EditDelBtns
						record={rec}
						canDelete={!isDisabled}
						canUpdate={false}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
							mainCtx.setDeletedProds(record)
							// mainCtx.recalculateReportPartyNums()
						}}
						deps={mainCtx.delEditDeps?.complectations?.[rec.id]}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					></EditDelBtns>
				)
			},
		},
	]
}
