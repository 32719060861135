import localforage from 'localforage'

/**
 * Фильтрует данные на основе поиска и фильров
 * @param {Object} history - экземплар класса с историей
 * @param {{id: string, url: string }} params - параметры для перехода
 */
export const goToItem = (history, params = {}, inData = {}) => {
	if (!params.url) throw Error('Параметры неверны')
	let path = `${params.url}/${params.id || 'new'}`
	history.push(path, { ...inData })
	return
}

/**
 * Сохраняет глобальный стейт и открывает с ним новое окно браузера
 * @param {string} url
 * @param {{dataServerCtx: Object, authCtx: Object }} data - контексты
 */
export const openNewTab = async (url, data = {}) => {
	if (!url || !data.authCtx) throw Error('Параметры неверны')
	await localforage.setItem('__openNewTab', true)
	for (let k in data) {
		localStorage.setItem(k, JSON.stringify(data[k]))
	}
	window.open(url, '_blank')
	return
}
