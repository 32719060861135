import { Table } from 'antd'
import React, { useContext, useState } from 'react'
import { ProductionTaskItemMainContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { AuthContext } from '../../../../contexts'
import { getDisplayColumns } from './match-queue-tab'
import clickableRow from '../../../../utils/helpers/clickableRow'
import rowClassName from '../../../../utils/helpers/rowClassName'

const MatchQueueTab = () => {
	const mainCtx = useContext(ProductionTaskItemMainContext)
	const authCtx = useContext(AuthContext)
	const [rowId, setRowId] = useState('')

	return (
		<TabItem
			addButton={{ show: false }}
			table={() => {
				return (
					<Table
						rowKey="id"
						rowClassName={rowClassName(rowId)}
						size="small"
						columns={getDisplayColumns(mainCtx.state.matchQueues)}
						dataSource={mainCtx.state.matchQueues}
						pagination={false}
						loading={mainCtx.tabsLoading.matchQueues}
						scroll={{ x: 800 }}
						onRow={clickableRow(setRowId, authCtx, '/production/tasks')}
					/>
				)
			}}
		></TabItem>
	)
}

export default MatchQueueTab
