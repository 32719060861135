import { useEffect, useRef } from 'react'
import { modes } from '../constants'

const useInactivityDetector = (
	stateRef,
	dispatch,
	modCtx,
	callback,
	handleInactivityLimit,
	timersOptions = {}
) => {
	const timerRef = useRef(null)
	const insureTimerRef = useRef(null)
	const inactivityTimerRef = useRef(null)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)

		if (modCtx.mod === modes.edit) {
			if (timerRef.current) {
				clearTimer(timerRef)
				if (!insureTimerRef.current) {
					// страховочный таймер - если мы делаем изменения чаще чем раз в 10 секунд
					insureTimerRef.current = setTimeout(() => {
						callback()
					}, (1000 * timersOptions.inactivityLimitSeconds) / 2 - 1)
				}
			}
			timerRef.current = setTimeout(() => {
				// страховочный таймер - если мы делаем изменения чаще чем раз в 10 секунд
				clearTimer(insureTimerRef)
				callback()
				clearTimer(inactivityTimerRef)
				inactivityTimerRef.current = setTimeout(() => {
					clearTimer(timerRef)
					clearTimer(insureTimerRef)
					handleInactivityLimit()
				}, 1000 * timersOptions.inactivityLimitSeconds)
			}, 1000 * timersOptions.activityCallbackIntervalSeconds)
		}
	}

	useEffect(() => {
		if (modCtx.mod === modes.view) {
			clearTimer(timerRef)
			clearTimer(insureTimerRef)
			clearTimer(inactivityTimerRef)
		}
		if (modCtx.mod === modes.edit) {
			if (timerRef.current) {
				clearTimer(timerRef)
				if (!insureTimerRef.current) {
					// страховочный таймер - если мы делаем изменения чаще чем раз в 10 секунд
					insureTimerRef.current = setTimeout(() => {
						callback()
					}, (1000 * timersOptions.inactivityLimitSeconds) / 2 - 1)
				}
			}
			timerRef.current = setTimeout(() => {
				// страховочный таймер - если мы делаем изменения чаще чем раз в 10 секунд
				clearTimer(insureTimerRef)
				callback()
				clearTimer(inactivityTimerRef)
				inactivityTimerRef.current = setTimeout(() => {
					clearTimer(timerRef)
					clearTimer(insureTimerRef)
					handleInactivityLimit()
				}, 1000 * timersOptions.inactivityLimitSeconds)
			}, 1000 * timersOptions.activityCallbackIntervalSeconds)
		}
	}, [modCtx.mod])
	return executeDispatch
}

const clearTimer = (timerRef) => {
	if (timerRef?.current) {
		clearTimeout(timerRef.current)
		timerRef.current = null
	}
}
export default useInactivityDetector
