import { checkIsNot } from '@berry/common-functions/obj-arr'

/**
 * получить общий балл
 * @param {Object} inData - стейт
 * @returns {number}
 */
export const getSumScore = (inData) => {
	if (
		!checkIsNot(
			inData,
			['qualityManagement', 'deliveryTime', 'deliverCondition', 'paymentCondition', 'quality'],
			[undefined, null]
		)
	)
		return ''
	return (
		+inData.qualityManagement +
		+inData.deliveryTime +
		+inData.deliverCondition +
		+inData.paymentCondition +
		+inData.quality +
		5 // ABC-анализ
	)
}

/**
 * получить классификацию
 * @param {Object} inData - стейт
 * @returns {number}
 */
export const getClassification = (inData) => {
	const sumScore = getSumScore(inData)
	if (sumScore === '') return ''
	if (sumScore >= 20 && sumScore <= 30) return 'Высшая'
	if (sumScore >= 15 && sumScore <= 19) return 'Средняя'
	if (sumScore <= 14) return 'Низкая'
	return ''
}
