import { Table } from 'antd'
import React, { useContext, useState } from 'react'
import { TabItem } from '../../../../components'
import { getDisplayColumns, prepareData } from './contract-tab'
import { ProviderItemMainContext } from '../provider/main'
import { AuthContext } from '../../../../contexts'
import rowClassName from '../../../../utils/helpers/rowClassName'
import clickableRow from '../../../../utils/helpers/clickableRow'

const ContractTab = () => {
	const mainCtx = useContext(ProviderItemMainContext)
	const data = prepareData(mainCtx.state.contracts)
	const authCtx = useContext(AuthContext)
	const [rowId, setRowId] = useState('')

	return (
		<TabItem
			addButton={{
				disabled: true,
				show: false,
				onClick: () => {},
			}}
			modal={() => {
				return null
			}}
			table={() => {
				return (
					<Table
						rowClassName={rowClassName(rowId)}
						size="small"
						columns={getDisplayColumns(mainCtx)}
						dataSource={data}
						scroll={{ x: 800 }}
						pagination={false}
						onRow={clickableRow(setRowId, authCtx, '/rm/contracts-providers')}
					/>
				)
			}}
		/>
	)
}

export default ContractTab
