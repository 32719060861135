import { dateFormat } from '../../../../utils/constants/common'
import { getTaskWithStockRawMatCompl } from '../../../../utils/helpers/cross-pages-funcs'
import moment from 'moment'

const layout1Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 11, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 11, offset: 0 },
	},
	wrapperCol: { span: 13 },
}
const layout2Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 5 },
		xl: { span: 11, offset: 1 },
	},
	wrapperCol: { span: 15 },
}
const layout3Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 11, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 11, offset: 0 },
	},
	wrapperCol: { span: 13 },
}

export const getFormItemsMain = (mainCtx) => {
	const weight = mainCtx.state.storages.reduce(
		(sum, cur) => (['Хранение', 'Отклонено 1С'].includes(cur.status) ? sum + +cur.weight : sum),
		0
	)
	return [
		[
			{
				layout: layout1Col,
				label: 'Продукт',
				name: 'prodCat',
				field: mainCtx.state.prodLabel,
			},
			{
				layout: layout1Col,
				label: 'Вид',
				name: 'prodCatKind',
				field: mainCtx.state.prodCatKind,
			},

			{
				layout: layout1Col,
				label: 'Артикул',
				name: 'articul',
				field: mainCtx.state.articul,
			},
			{
				layout: layout1Col,
				label: 'Артикул 1С',
				name: 'articul1C',
				field: mainCtx.state.articul1C,
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Партия №',
				name: 'partyNum',
				field: mainCtx.state.partyNum,
			},
			{
				layout: layout2Col,
				label: 'Дата производства',
				name: 'shelfLifeDate',
				field: mainCtx.state.date,
			},
		],
		[
			{
				layout: layout3Col,
				label: 'Масса, кг',
				name: 'weight',
				field: weight,
			},
			{
				layout: layout3Col,
				label: 'Годен до',
				name: 'shelfLifeDate',
				field: mainCtx.state.shelfLifeDate
					? moment(mainCtx.state.shelfLifeDate).format(dateFormat)
					: null,
			},
			{
				layout: layout3Col,
				label: 'ID ДЗ',
				name: 'idOrder',
				field:
					mainCtx.state.idContrOrder ||
					getTaskWithStockRawMatCompl(
						mainCtx.state.taskRep?.task.complectations?.[0],
						mainCtx,
						mainCtx.additional.allSelectsProdTasks
					)?.stockRawMatStor.stockRawMat.supplProd?.order?.id,
			},
		],
	]
}
