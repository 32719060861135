import React, { useContext } from 'react'
import { Main } from '../../../layouts'
import { Provider, DisplayCodesVocabularyMainContext } from './provider/main'
import { HeaderMenu, LoadingButton, StatefulTable } from '../../../components'
import { getColumns, getToXlsx } from './display-codes-vocabulary-list'
import { Card } from 'antd'
import { ExcelIcon } from '../../../assets'
import saveAsXlsx from '@berry/common-functions/excel'
import { ListProvider, ListContext } from '../../../contexts'

import { getAllDataForXl } from '../../../utils/helpers/cross-pages-funcs'

const dataUrl = '/product-catalogs/display-code-vocabulary'

const DisplayCodesVocabularyList = (props) => {
	return (
		<ListProvider path="/pv/displaycodes-vocabulary">
			<Provider>
				<Main title={'Справочник артикулов'}>
					<DisplayCodesVocabularyListTable />
				</Main>
			</Provider>
		</ListProvider>
	)
}

const DisplayCodesVocabularyListTable = () => {
	const mainCtx = useContext(DisplayCodesVocabularyMainContext)
	const dataSource = mainCtx.toDisplayDataList
	const {
		state: { mainFilter, filters },
	} = useContext(ListContext)
	return (
		<>
			<HeaderMenu
				breadcrumbsTitle={'Справочник артикулов'}
				search={mainCtx.setSearch}
				additionalButtonsPre={[
					<LoadingButton
						type="text"
						title="Сохранить в Excel файл"
						icon={<ExcelIcon />}
						disabled={!mainCtx.toDisplayDataList.length}
						onClick={async () => {
							const { data } = await getAllDataForXl(dataUrl, filters, mainFilter)
							const toXlsx = getToXlsx(
								data,
								getColumns({
									data,
									mainCtx,
									fromServerFilters: mainCtx.state.fromServerFilters,
								})
							)
							saveAsXlsx(toXlsx)
						}}
					/>,
				]}
			/>
			<Card>
				<StatefulTable
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters || {} }}
					dataSource={dataSource}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					bordered
					rowKey="_uuid_"
					columns={getColumns}
				/>
			</Card>
		</>
	)
}
export default DisplayCodesVocabularyList
