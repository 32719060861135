import React, { useContext } from 'react'

import { Main } from '../../../layouts'
import { Provider, ContractListProviderMainContext } from '../provider/list-provider'
import { HeaderMenu, StatefulTable } from '../../../components'
import { getColumns } from './contract-provider-list'
import { Card } from 'antd'
import { goToItem } from '../../../utils'
import { useHistory } from 'react-router-dom'
import { UserDataContext } from '../../../contexts'

export const pageUrl = '/rm/contracts-providers'

const ContractsProvidersList = () => {
	return (
		<Provider>
			<Main title={'Договоры. Поставщики'}>
				<ContractsProvidersTable />
			</Main>
		</Provider>
	)
}

const ContractsProvidersTable = () => {
	const mainCtx = useContext(ContractListProviderMainContext)
	const history = useHistory()
	const {
		state: { isDevAdmin },
	} = useContext(UserDataContext)
	return (
		<>
			<HeaderMenu
				breadcrumbsTitle={'Договоры. Поставщики'}
				search={mainCtx.setSearch}
				rights={{
					'АРМ офиса': {
						'Договоры. Поставщики': {
							edit: true,
						},
					},
				}}
				canCreate={isDevAdmin}
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					dataSource={mainCtx.toDisplayDataList}
					columns={getColumns}
					url={pageUrl}
					// searchFields={mainCtx.searchFields}
				/>
			</Card>
		</>
	)
}
export default ContractsProvidersList
