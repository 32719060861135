import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Card, Layout, Row } from 'antd'
import { MenuContext, menuPositions } from '../../contexts'
import TopMenu from './TopMenu'
import Info from './Info'
import styles from './header.module.scss'
import { withTopMenu, withoutTopMenu, card } from './styles'

const Header = (props) => {
	const { position } = useContext(MenuContext)
	const { title = '' } = props
	const showTopMenu = position === menuPositions.top
	return (
		<Layout.Header className={styles.header}>
			<Helmet title={title} />
			<Card bordered={false} style={card} bodyStyle={showTopMenu ? withTopMenu : withoutTopMenu}>
				<Row align="middle" justify="space-between" wrap={false} style={{ width: '100%' }}>
					<TopMenu show={showTopMenu} />
					<Info />
				</Row>
			</Card>
		</Layout.Header>
	)
}

export default Header
