import { DatePicker, Typography, Select, Col } from 'antd'
import { modes } from '../../../../utils'
import { colorizeIntegrationStatus } from '../../../../utils/helpers/for-integration'
const { Title } = Typography

const layout1 = {
	labelCol: { xs: 24, sm: 8, xl: 8 },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}
const layout2 = {
	labelCol: { xs: 24, sm: 6, xl: 6 },
	wrapperCol: { xs: 24, sm: 14, xl: 10 },
}
const layout3 = {
	labelCol: { xs: 24, sm: 8, xl: 10, xxl: { offset: 2, span: 8 } },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}

export const getFormItems = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				key: 'req-title',
				field: <Title level={5}>Основные данные</Title>,
			},
			...(modCtx.mod !== modes.new
				? [
						{
							layout: layout1,
							label: 'ID перемещения',
							key: 'id',
							name: 'id',
							field: mainCtx.state.id,
						},
				  ]
				: []),
			{
				key: 'date',
				name: 'date',
				layout: layout1,
				label: 'Дата перемещения',
				rules: [{ required: true }],
				field: (
					<DatePicker
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format="DD.MM.YYYY"
						allowClear={false}
						suffixIcon={null}
						onChange={mainCtx.stateFunctions.setDate}
						style={{ width: '100%', maxWidth: '150px' }}
						disabled={isDisabled}
					/>
				),
			},
		],
		[
			{ key: 'mid-column' },
			{
				key: 'shipmentWh',
				name: 'shipmentWh',
				label: 'Склад отгрузки',
				layout: layout2,
				rules: [{ required: true }],
				field: (
					<Select
						disabled={isDisabled}
						value={mainCtx.state.shipmentWh?.label}
						onChange={(el) => {
							mainCtx.stateFunctions.setShipmentWh(el)
						}}
						notFoundContent={<Col>Не найдено</Col>}
					>
						{mainCtx.additional.allOutdoorWhs
							.filter((wh) => wh.id !== mainCtx.state.receiveWh?.id)
							.map((it) => {
								return (
									<Select.Option key={it.id} value={it.id}>
										{it.label}
									</Select.Option>
								)
							}) || []}
					</Select>
				),
			},
			{
				key: 'receiveWh',
				name: 'receiveWh',
				label: 'Склад приемки',
				rules: [{ required: true }],
				layout: layout2,
				field: (
					<Select
						disabled={isDisabled}
						value={mainCtx.state.receiveWh?.label}
						onChange={(el) => {
							mainCtx.stateFunctions.setReceiveWh(el)
						}}
						notFoundContent={<Col>Не найдено</Col>}
					>
						{mainCtx.additional.allOutdoorWhs
							.filter((wh) => wh.id !== mainCtx.state.shipmentWh?.id)
							.map((it) => {
								return (
									<Select.Option key={it.id} value={it.id}>
										{it.label}
									</Select.Option>
								)
							}) || []}
					</Select>
				),
			},
		],
		[
			{ key: 'last-column' },
			{
				key: 'status',
				name: 'status',
				layout: layout3,
				label: 'Статус',
				...colorizeIntegrationStatus(mainCtx.state.status),
			},
			{
				key: 'weight',
				layout: layout3,
				label: 'Общая масса, кг',
				name: 'weight',
				field: mainCtx.state.productions.reduce((sum, cur) => sum + +cur.weight || 0, 0),
			},
		],
	]
}
