import { getColumnSearchProps, frontSorter } from '../../../../utils'
import { dateFormat } from '../../../../utils/constants/common'
import moment from 'moment'
/**
 * вычисляет наименование
 * @param {string} inLabel
 * @param {Object} inRecord
 */

export const getDisplayColumns = (inArr = []) => {
	return [
		{
			title: 'Наименование',
			dataIndex: 'label',
			key: 'label',
			width: '42%',
		},
		{
			title: 'Версия',
			dataIndex: 'version',
			key: 'version',
			width: '12%',
		},

		{
			title: 'Дата утв.',
			dataIndex: 'dateOfApprove',
			key: 'dateOfApprove',
			width: '12%',
			sorter: frontSorter('string', 'dateOfApprove'),
			render: (date) => moment(date, 'YYYY-MM-DD').format(dateFormat),
		},
		{
			title: 'Вид продукта',
			dataIndex: 'quality',
			key: 'quality',
			width: '12%',
			...getColumnSearchProps(
				Array.from(new Set(inArr.map((e) => e?.quality))),
				undefined,
				'quality'
			),
		},
		{
			title: 'Качество',
			dataIndex: ['vocQuality', 'label'],
			key: 'displayCode1c',
			width: '12%',
			...getColumnSearchProps(
				Array.from(new Set(inArr.map((e) => e?.vocQuality.label))),
				undefined,
				'vocQuality.label'
			),
		},
	]
}
