import lodash from 'lodash'
import { getDisplayValue } from '../../../../utils'

export const searchFields = [
	'displayVal',
	'spec.displayVal',
	'vocSpecParamParam.spec.displayVal',
	'vocSpecParamParam.displayVal',
	'vocMeasure.displayVal',
	'vocSpecParamParam.vocMeasure.displayVal',
	'etalonVal1',
	'etalonSymbol',
	'etalonVal2',
	'deviationVal1',
	'deviationSymbol',
	'deviationVal2',
	'etalonText',
	'deviationText',
]

export const getStyle = () => {
	const leftMenu = document.getElementById('leftMenu')
	if (!leftMenu) {
		return {}
	}
	return {
		position: 'absolute',
		left: leftMenu.offsetWidth + 22,
		top: 77,
	}
}

export const getWidth = () => {
	const leftMenu = document.getElementById('leftMenu')
	if (!leftMenu || leftMenu.offsetWidth === 80) {
		return 'calc(94% - 7px)'
	}
	return 'calc(85% - 7px)'
}

/**
 * Фильтрует данные на основе поиска и фильтров
 * @param {Array<Object>} inList - массив полей
 * @param {{search: string,searchFields: Array<String> }} params
 * @returns {Array<Object>}
 */
export const applyFilterForList = (inList, params) => {
	if (![undefined, '', null].includes(params.search)) {
		const copyData = lodash.cloneDeep(inList)
		return copyData.filter((e = {}) =>
			params.searchFields.some((sf) =>
				getDisplayValue(lodash.get(e, sf)).toLowerCase().includes(params.search.toLowerCase())
			)
		)
	}
	return inList
}

/**
 * Рассчитывает rowSpan для записи
 * @param {Array<Object>} inArr - массив строк таблицы
 * @param {String} field (столбец), по которому проводится группировка
 * @param {Object} record объект (строка), для которого производится расчет rowSpan
 */
export const calcSpan = (inArr, field, record) => {
	const span = inArr.filter((el) => lodash.get(el, field) === lodash.get(record, field)).length
	const elIndex = inArr.findIndex((el) =>
		el.id ? el.id === record.id : el.vocSpecParamParam?.id === record.vocSpecParamParam?.id
	)

	const foundIndex = inArr.findIndex((el) => lodash.get(el, field) === lodash.get(record, field))
	if (foundIndex === elIndex) {
		return span
	}
	return 0
}

export const sortWithSpec = (a, b) => {
	const intl = new Intl.Collator('ru')
	const aSpecId = a.vocSpecParamParam?.spec?.id || a.spec?.id
	const bSpecId = b.vocSpecParamParam?.spec?.id || b.spec?.id
	if (aSpecId === bSpecId) {
		return intl.compare(
			a.vocSpecParamParam?.displayVal || a.displayVal,
			b.vocSpecParamParam?.displayVal || b.displayVal
		)
	}
	return 0
}
