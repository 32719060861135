import { Checkbox } from 'antd'
import moment from 'moment'
import { dateFormat } from '../../../../utils/constants/common'
import { numToFixed } from '@berry/common-functions/cross-project-functions'

const layout1Col = {
	labelCol: {
		sm: { span: 12, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 8, offset: 0 },
		xl: { span: 9, offset: 0 },
	},
	wrapperCol: { span: 13 },
}
const layout2Col = {
	labelCol: {
		sm: { span: 12, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 10, offset: 1 },
	},
	wrapperCol: { span: 10 },
}
const layout3Col = {
	labelCol: {
		sm: { span: 12, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 11, offset: 4 },
	},
	wrapperCol: { span: 22 },
}

export const calcRepeatTestReportNum = (date = moment(), allSelectStockRawMats, allSupplProds) => {
	let num
	const formattedDate = moment.isMoment(date)
		? date.format(dateFormat)
		: moment(date).format(dateFormat)
	const reportsForCtrlDate = allSelectStockRawMats.filter(
		(e) => e.supply?.date && e.supply.date === formattedDate
	)
	const repeatTestRepNums = allSupplProds.filter(
		(e) => e?.repeatDate && e.repeatDate === formattedDate
	)
	if (!reportsForCtrlDate.length && !repeatTestRepNums.length) {
		num = 1
	} else {
		num =
			Math.max(
				...reportsForCtrlDate.map((el) => el.ctrlTestReportNum.split('.')[1]),
				...repeatTestRepNums.map((el) => el.repeatTestRepNum.split('.')[1])
			) + 1
	}
	const [day, month, year] = formattedDate.split('.')
	return `${year.slice(-2)}${month}${day}.${num}`
}

export const getSellByDate = (record) => {
	const measure =
		record.supplProd?.ctrlMeasure.labelShort === 'сут'
			? 'days'
			: record.supplProd?.ctrlMeasure.labelShort === 'мес'
			? 'months'
			: null
	return moment(record.supplProd?.ctrlDate)
		.add(record.supplProd?.ctrlShelfLife, measure)
		.format('DD.MM.YYYY')
}

export const getFormItemsMain = (mainCtx, modCtx) => {
	const weightAccepted = numToFixed(
		mainCtx.state.storages?.reduce(
			(sum, cur) => (cur.status === 'Допущено' ? sum + +cur.weight : sum),
			0
		),
		2
	)

	return [
		[
			{
				layout: layout1Col,
				label: 'Продукт',
				name: 'product',
				field: mainCtx.state.prodLabel,
			},
			{
				layout: layout1Col,
				label: 'Вид',
				name: 'kind',
				field: mainCtx.state.prodCatKind,
			},
			{
				layout: layout1Col,
				label: 'Артикул',
				name: 'displayCode',
				field: mainCtx.state.articul,
			},
			{
				layout: layout1Col,
				label: 'Артикул 1С',
				name: 'vocProd1c',
				field: mainCtx.state.articul1C,
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Партия №',
				name: 'assignedPartyNum',
				field: mainCtx.state.partyNum,
			},
			{
				layout: layout2Col,
				label: 'Качество',
				name: 'vocQuality',
				field: mainCtx.state.supplProd?.ctrlSpec?.vocQuality?.label || 'Отсутствует',
			},
			{
				layout: layout2Col,
				label: 'Спецификация',
				name: 'spec',
				field: mainCtx.state.ctrlSpecLabel,
			},
			{
				layout: layout2Col,
				label: 'Кол-во дефростаций',
				name: 'defrostCount',

				field: mainCtx.state.defrostCount,
			},
		],
		[
			{
				layout: layout3Col,
				label: 'Масса, кг допущено',
				name: 'weightAccepted',
				field: weightAccepted,
			},
			{
				layout: layout3Col,
				label: 'Дата производства',
				name: 'prodDate',
				field: mainCtx.state.ctrlDate ? moment(mainCtx.state.ctrlDate).format('DD.MM.YYYY') : null,
			},
			{
				layout: layout3Col,
				label: 'Годен до',
				name: 'ctrlShelfLife',
				field: getSellByDate(mainCtx.state),
			},
			...(mainCtx.state.status === 'Пересорт'
				? [
						{
							layout: layout3Col,
							label: 'Пересорт',
							name: 'peresort',
							field: mainCtx.state.status === 'Пересорт' && <Checkbox checked />,
							value: mainCtx.state.status === 'Пересорт',
						},
				  ]
				: []),
		],
	]
}

export const getFormItemsSupply = (mainCtx, modCtx) => {
	return [
		[
			{
				layout: layout1Col,
				label: '№ поставки',
				name: 'supplyDisplayCode',
				field: mainCtx.state.supplProd?.supply?.id,
			},
			{
				layout: layout1Col,
				label: 'Дата поставки',
				name: 'supplyDate',
				field: mainCtx.state.supplProd?.supply?.date
					? moment(mainCtx.state.supplProd?.supply?.date).format('DD.MM.YYYY')
					: null,
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Поставщик',
				name: 'provider',
				field: mainCtx.state.provider,
				value: mainCtx.state.provider,
			},
			{
				layout: layout2Col,
				label: 'ID ДЗ',
				name: 'orderDisplayCode',
				field: mainCtx.state.supplProd?.order?.id,
			},
		],
		[
			{
				layout: layout3Col,
				label: 'Производитель',
				name: 'ctrlRawMatManuf',
				value: mainCtx.state.supplProd?.ctrlRawMatManuf?.label,
				field: mainCtx.state.supplProd?.ctrlRawMatManuf?.label,
			},
			{
				layout: layout3Col,
				label: 'Страна происхождения',
				name: 'ctrlRawMatCountry',
				value: mainCtx.state.supplProd?.ctrlRawMatCountry?.label,
				field: mainCtx.state.supplProd?.ctrlRawMatCountry?.label,
			},
		],
	]
}

export const getFormItemsControl = (mainCtx) => {
	return [
		[
			{
				layout: layout1Col,
				label: 'Протокол испытаний №',
				name: 'ctrlTestReportNum',
				value: mainCtx.state.supplProd?.ctrlTestReportNum,
				field: mainCtx.state.supplProd?.ctrlTestReportNum,
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Дата повт. контр.',
				name: 'repeatTestRepNum',
				value: mainCtx.state.repeatDate
					? moment(mainCtx.state.repeatDate).format(dateFormat)
					: null,
				field: mainCtx.state.repeatDate
					? moment(mainCtx.state.repeatDate).format(dateFormat)
					: null,
			},
		],
		[
			{
				layout: layout3Col,
				label: 'Протокол испытаний повт. №',
				name: 'repeatTestRepNum',
				value: mainCtx.state.repeatTestRepNum,
				field: mainCtx.state.repeatTestRepNum,
			},
		],
	]
}
