module.exports = {
	allDeny: {},
	viewEditAllow: {
		'АРМ офиса': {
			Заказчики: {
				view: true,
				edit: true,
				match: true,
			},
			'Договоры. Заказчики': {
				view: true,
				edit: true,
				match: true,
			},
			Поставщики: {
				view: true,
				edit: true,
				match: true,
			},
			'Договоры. Поставщики': {
				view: true,
				edit: true,
				match: true,
			},
			'Заявки на поставку сырья': {
				view: true,
				edit: true,
				match: true,
			},
			'Поставки сырья': {
				view: true,
				edit: true,
				match: true,
				'Основные данные': {
					view: true,
					edit: true,
					match: true,
				},
				'Входной контроль': {
					view: true,
					edit: true,
					match: true,
				},
				Разгрузка: {
					view: true,
					edit: true,
					match: true,
				},
			},
			'Продуктовый каталог': {
				view: true,
				edit: true,
				match: true,
			},
			'Справочник артикулов': {
				view: true,
				edit: true,
				match: true,
			},
			'Настройки системы': {
				view: true,
				edit: true,
				match: true,
			},
			'Реестр Запасы': {
				view: true,
				edit: true,
				match: true,
			},
			'Карточка Запасы': {
				view: true,
				edit: true,
				match: true,
				Себестоимость: {
					view: true,
					edit: true,
				},
			},

			'Задачи на производство': {
				view: true,
				edit: true,
				match: true,
				'Изменить статус': {
					view: true,
					edit: true,
				},
			},
			'Заказы давальца': {
				view: true,
				edit: true,
				match: true,
			},
			'Сводные отчеты': {
				view: true,
				edit: true,
				match: true,
			},
			Утилизации: {
				view: true,
				edit: true,
				match: true,
			},
			'Перемещения удал. склад': {
				view: true,
				edit: true,
				match: true,
			},
			'Перемещения в брак': {
				view: true,
				edit: true,
				match: true,
			},
			Персонал: {
				view: true,
				edit: true,
				match: true,
			},
			'Протоколы контроля': {
				view: true,
				edit: true,
				match: true,
			},
			Реализации: {
				view: true,
				edit: true,
				match: true,
			},
		},
		'АРМ цеха': {},
		Справочники: {
			'Причины поломок оборудования': {
				view: true,
				edit: true,
				match: true,
			},
			'Статусы персонала': {
				view: true,
				edit: true,
				match: true,
			},
			'Статусы поставки': {
				view: true,
				edit: true,
				match: true,
			},
			'Состояние машины': {
				view: true,
				edit: true,
				match: true,
			},
			'Статусы согласования': {
				view: true,
				edit: true,
				match: true,
			},
			'Типы объектов производства и вспомогательных служб': {
				view: true,
				edit: true,
				match: true,
			},
			'Типы продукта': {
				view: true,
				edit: true,
				match: true,
			},
			'Виды дополнительных ПФ': {
				view: true,
				edit: true,
				match: true,
			},
			'Виды основных ПФ': {
				view: true,
				edit: true,
				match: true,
			},
			'Внешние склады': {
				view: true,
				edit: true,
				match: true,
			},
			Смены: {
				view: true,
				edit: true,
				match: true,
			},
			'Виды НО': {
				view: true,
				edit: true,
				match: true,
			},
			'Группы продукции': {
				view: true,
				edit: true,
				match: true,
			},
			Качество: {
				view: true,
				edit: true,
				match: true,
			},
			Помещения: {
				view: true,
				edit: true,
				match: true,
			},
			'Назначение цехов': {
				view: true,
				edit: true,
				match: true,
			},
			'Основные операции': {
				view: true,
				edit: true,
				match: true,
			},
			'Производители сырья': {
				view: true,
				edit: true,
				match: true,
			},
			'Производственные участки': {
				view: true,
				edit: true,
				match: true,
			},
			Процессы: {
				view: true,
				edit: true,
				match: true,
			},
			'Продукция из 1С': {
				view: true,
				edit: true,
				match: true,
			},
			'Параметры спецификации': {
				view: true,
				edit: true,
				match: true,
			},
			'Состояния сырья': {
				view: true,
				edit: true,
				match: true,
			},
			'Страны происхождения "Сырья"': {
				view: true,
				edit: true,
				match: true,
			},
			'Срок годности сырья': {
				view: true,
				edit: true,
				match: true,
			},
			'Температурный режим': {
				view: true,
				edit: true,
				match: true,
			},
			'Типы должностей': {
				view: true,
				edit: true,
				match: true,
			},
			'Типы упаковки': {
				view: true,
				edit: true,
				match: true,
			},
			'Типы складов': {
				view: true,
				edit: true,
				match: true,
			},
			'Типы тары': {
				view: true,
				edit: true,
				match: true,
			},
			'Условия доставки': {
				view: true,
				edit: true,
				match: true,
			},
			'Условия оплаты': {
				view: true,
				edit: true,
				match: true,
			},
			'Причины отклонения отчета о производстве при согласовании': {
				view: true,
				edit: true,
				match: true,
			},
			'Причины блокировки партии в отчете о производстве': {
				view: true,
				edit: true,
				match: true,
			},
			'Формы ГП': {
				view: true,
				edit: true,
				match: true,
			},
		},
	},
}
