import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, Divider, Space } from 'antd'
import { SearchOutlined, CloseSquareOutlined } from '@ant-design/icons'
import { useSearchFilter } from '../../utils'
import { FixedSizeList as List } from 'react-window'
import { findDOMNode } from 'react-dom'
import Row from './Row'

const maxDropDownWidth = 400
const minDropDownWidth = 200

const FilterDropDown = ({
	setSelectedKeys,
	selectedKeys,
	confirm,
	clearFilters,
	filters,
	visible,
}) => {
	const { filters: worksFilters, searchValue, onSearch, setSearchValue } = useSearchFilter(filters)
	const listRef = useRef(null)

	const [fontSize, setFontSize] = useState(14)

	useEffect(() => {
		if (listRef.current) {
			const element = findDOMNode(listRef.current)
			const fontSize = parseInt(
				window.getComputedStyle(element, null).getPropertyValue('font-size')
			)
			setFontSize(fontSize)
		}
	}, [])

	const getDropDownWidth = () => {
		if (!worksFilters.length) {
			return minDropDownWidth
		}
		const maxStrLength = worksFilters
			.reduce((max, cur) => (String(cur.text).length > String(max.text).length ? cur : max))
			.text.toString().length
		if (maxStrLength * fontSize > maxDropDownWidth) {
			return maxDropDownWidth
		}
		return Math.max(maxStrLength * fontSize, minDropDownWidth)
	}

	const dropDownWidth = getDropDownWidth()

	return (
		<div style={{ padding: 8, height: 'auto', width: dropDownWidth }}>
			<Input
				onChange={onSearch}
				placeholder="Поиск"
				value={searchValue}
				suffix={
					typeof searchValue === 'string' ? (
						<CloseSquareOutlined
							onClick={() => {
								if (!Array.isArray(selectedKeys)) {
									setSelectedKeys([])
								}
								if (searchValue !== '') {
									setSearchValue('')
								}
							}}
						/>
					) : (
						<SearchOutlined />
					)
				}
			/>
			<List
				ref={listRef}
				height={worksFilters.length > 10 ? 300 : 150}
				itemCount={worksFilters.length}
				itemSize={fontSize + 8}
				width={dropDownWidth - 16}
				itemData={worksFilters}
				itemKey={(index) => worksFilters[index].value}
			>
				{({ index, data, style }) => (
					<div style={{ ...style, top: style.top + 8 }}>
						<Row
							selectedKeys={selectedKeys}
							setSelectedKeys={setSelectedKeys}
							data={data}
							index={index}
						/>
					</div>
				)}
			</List>
			<Divider style={{ margin: '6px 0' }} />
			<Space style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Button
					type="ghost"
					onClick={() => {
						clearFilters()
						setSelectedKeys([])
						confirm()
					}}
					size="small"
				>
					Сбросить
				</Button>
				<Button
					type="primary"
					onClick={() => {
						if (Array.isArray(selectedKeys)) {
							confirm()
						}
					}}
					size="small"
				>
					Ок
				</Button>
			</Space>
		</div>
	)
}

export default FilterDropDown
