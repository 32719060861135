import React, { useContext } from 'react'
import { Card, Row, Col, Button, Input } from 'antd'
import { modes } from '../../utils'
import { ModContext, UserDataContext, ListContext } from '../../contexts'
import { Breadcrumb } from '../index'
import { menuCardStyle, menuBodyStyle, rightCornerComponentsStyle } from './styles'
const { checkRole } = require('@berry/common-functions/role')

/**
 * Хедер экрана с данныцми конкретной записи таблицы
 * @param {Object} props
 * @param {string} props.title - заготовок
 * @param {string} props.breadcrumb - то что идет в крошки
 * @param {string} props.recordLabel - то что в конце крошек при работе с item какого либо list
 * @param {string} props.children - компоненты которые обернуты
 */
const HeaderMenu = (props) => {
	const {
		canCreate = false,
		search = false,
		handleAddOnMenu = (fn) => fn,
		breadcrumbsTitle = '',
		recordLabel = '',
		children,
		headerStyle,
		customBreadcrumbItems,
		rights = {},
		additionalButtonsPre = [],
	} = props

	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)

	const modCtx = useContext(ModContext)
	const listCtx = useContext(ListContext)
	let clickble = true
	if (modCtx?.mod === modes.edit || modCtx?.mod === modes.new) clickble = false

	const rightCornerComponents = []
	if (search)
		rightCornerComponents.push(
			<Col key="search" style={rightCornerComponentsStyle}>
				<Input.Search
					onSearch={typeof search === 'function' ? search : listCtx.setTextFilter}
					defaultValue={listCtx.state.textFilter}
					allowClear
					enterButton
				/>
			</Col>
		)
	if (canCreate && checkRole(rights, currentRights))
		rightCornerComponents.push(
			<Col key="canCreate" style={rightCornerComponentsStyle}>
				<Button type="primary" onClick={handleAddOnMenu}>
					Добавить
				</Button>
			</Col>
		)
	additionalButtonsPre.forEach((btn, idx) => {
		rightCornerComponents.push(
			<Col key={`${additionalButtonsPre}-${idx}`} style={rightCornerComponentsStyle}>
				{additionalButtonsPre[idx]}
			</Col>
		)
	})

	return (
		<Card style={menuCardStyle} bodyStyle={menuBodyStyle}>
			<Row style={headerStyle} align="middle" justify="space-between">
				<Col>
					<Breadcrumb
						isClickble={clickble}
						title={breadcrumbsTitle}
						record={recordLabel}
						customItems={customBreadcrumbItems}
					/>
				</Col>
				{!!rightCornerComponents.length && <Row>{rightCornerComponents}</Row>}
				{children}
			</Row>
		</Card>
	)
}

export default HeaderMenu
