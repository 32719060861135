import { Select } from 'antd'

/**
 * Возвращает массив опций селекта и добавляет к ним пустой селект
 * @param {Array<Object>} inArr - обьект из которго было взято знеаченгие
 * @param {Function | string} valueGetter - функцуия или поле для получения отображаемого значения
 */
export const getNullSelectOpt = (inArr, valueGetter) => {
	return (
		[{ id: null, value: 'Не выбрано' }, ...inArr].map((it) => {
			let value = ''
			if (it.id === null) {
				value = it.value
			} else {
				value = typeof valueGetter === 'function' ? valueGetter(it) : it[valueGetter]
			}
			return (
				<Select.Option key={it.id} value={it.id}>
					{value}
				</Select.Option>
			)
		}) || []
	)
}

/**
 * Возвращает знеачение которое надо отобразить в селекте
 * @param {Object} el - обьект из которго было взято знеаченгие
 * @param {Strting} value - значени отображаемое
 */
export const getNullSeletValue = (el, value) => {
	return el ? value : 'Не выбрано'
}

/**
 * Отменяет нажатие клавиши если она не является составной частью телефонного номера
 * @param {Object} e - event
 */
export const isNum = (e) => {
	if (
		!e.key.match(/[0-9]/) &&
		e.key !== 'Backspace' &&
		e.key !== 'Delete' &&
		e.key !== 'ArrowLeft' &&
		e.key !== 'ArrowRight' &&
		e.key !== 'Tab'
	) {
		e.preventDefault()
	}
}
