import { isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'
import { Input, Select } from 'antd'
import { CommonSelect, EditDelBtns } from '../../../../components'
import { modes, openNewTab } from '../../../../utils'
import { getProdCatPrecalculatedCaliber } from '../../../../utils/helpers/cross-pages-funcs'
import {
	getProdCatKindReadyProdLabel,
	getProdCatKindSemifDisplayVal,
	getProdCatPackageLabel,
} from '@berry/common-functions/cross-project-functions'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} mainCtx - основной контекст
 * @param {Object} modCtx
 * @param {function} editHandler
 */
export const getDisplayColumns = (mainCtx, modCtx, editHandler, authCtx) => {
	const stateFunctions = mainCtx.stateFunctions.calibers
	const isDisabled = modCtx.mod === modes.view
	return [
		{
			title: 'Номер',
			dataIndex: 'displayCode',
			key: 'index',
			width: '25%',
		},
		{
			title: 'Калибр',
			dataIndex: '',
			key: 'label',
			width: '65%',
			render: (record) => getProdCatPrecalculatedCaliber(record),
		},

		{
			title: null,
			key: 'action',
			width: '10%',
			render: (text, record) => {
				const pkgDeps = (mainCtx.state.packages || [])
					.filter((pkg) => pkg.kindReadyProd?.caliber && pkg.kindReadyProd.caliber.id === record.id)
					.map((el) => ({
						link: `/pv/product-catalog/${mainCtx.state.id}`,
						title: `Продуктовый каталог / ${getProdCatPackageLabel(el)}`,
					}))
				const readyProdDeps = (mainCtx.state.kindReadyProds || [])
					.filter((readyProd) => readyProd.caliber && readyProd.caliber.id === record.id)
					.map((el) => ({
						link: `/pv/product-catalog/${mainCtx.state.id}`,
						title: `Продуктовый каталог / ${getProdCatKindReadyProdLabel(el)}`,
					}))
				const semifDeps = (mainCtx.state.kindSemifs || [])
					.filter((semif) => semif.kpCaliber && semif.kpCaliber.id === record.id)
					.map((el) => ({
						link: `/pv/product-catalog/${mainCtx.state.id}`,
						title: `Продуктовый каталог / ${getProdCatKindSemifDisplayVal(el)}`,
					}))
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={!isDisabled}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
						onEdit={editHandler}
						deps={[...pkgDeps, ...readyProdDeps, ...semifDeps]}
						openNewPage={(url) =>
							openNewTab(url, {
								authCtx: authCtx.state,
							})
						}
					/>
				)
			},
		},
	]
}

const layout = {
	labelCol: { span: 13 },
	wrapperCol: { span: 11 },
}

export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []

	const { _uuid_ } = params
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.calibers
	const found = stateFunctions.get(_uuid_)

	return [
		[
			{
				layout: layout,
				label: 'Значение',
				name: 'valueOne',
				validateStatus: mainCtx.formErrors?.['caliber.valueOne'] && 'error',
				help: mainCtx.formErrors?.['caliber.valueOne'] && mainCtx.formErrors['caliber.valueOne'],

				field: (
					<Input
						style={{ width: '100%' }}
						value={found.valueOne}
						disabled={isDisabled || ['<', '>'].includes(found.symbol)}
						onChange={(e) => {
							if (
								!isValidNum(e.target.value) ||
								!isValidNumberDigits(e.target.value, 'int', [10, 0]) ||
								+e.target.value > 2147483647
							) {
								stateFunctions.setValueOne(_uuid_, found.valueOne)
								return
							}

							stateFunctions.setValueOne(_uuid_, e.target.value)
						}}
					/>
				),
				/* rules: [
					{
						validator: async (_, value) => {
							if (found.symbol === '-') {
								if (!isValidNum(value)) {
									throw Error('Поле заполнено неверно')
								}
							}

							return
						},
					},
				], */
			},
		],
		[
			{
				layout: {
					labelCol: { span: 12 },
					wrapperCol: { span: 12, offset: 0 },
				},
				label: 'Знак',
				name: 'symbol',
				rules: [{ required: true }],
				validateStatus: mainCtx.formErrors?.['caliber.symbol'] && 'error',
				help: mainCtx.formErrors?.['caliber.symbol'] && mainCtx.formErrors['caliber.symbol'],

				field: (
					<Select
						style={{ width: '100%' }}
						value={found.symbol}
						disabled={isDisabled}
						onChange={(el) => {
							stateFunctions.setSymbol(_uuid_, el)
						}}
					>
						<Select.Option key={'-'}>-</Select.Option>
						<Select.Option key={'<'}>{'<'}</Select.Option>
						<Select.Option key={'>'}>{'>'}</Select.Option>
					</Select>
				),
			},
		],
		[
			{
				layout: layout,
				label: 'Значение',
				name: 'valueTwo',
				validateStatus: mainCtx.formErrors?.['caliber.valueTwo'] && 'error',
				help: mainCtx.formErrors?.['caliber.valueTwo'] && mainCtx.formErrors['caliber.valueTwo'],

				rules: [{ required: true }],
				field: (
					<Input
						style={{ width: '100%' }}
						value={found.valueTwo}
						disabled={isDisabled}
						onChange={(e) => {
							if (
								!isValidNum(e.target.value) ||
								!isValidNumberDigits(e.target.value, 'int', [10, 0]) ||
								+e.target.value > 2147483647
							) {
								stateFunctions.setValueTwo(_uuid_, found.valueTwo)
								return
							}
							stateFunctions.setValueTwo(_uuid_, e.target.value)
						}}
					/>
				),
			},
		],
		[
			{
				layout: {
					labelCol: { span: 10 },
					wrapperCol: { span: 14, offset: 0 },
				},
				label: 'ЕИ',
				name: 'vocMeasure',
				validateStatus: mainCtx.formErrors?.['caliber.vocMeasure'] && 'error',
				help:
					mainCtx.formErrors?.['caliber.vocMeasure'] && mainCtx.formErrors['caliber.vocMeasure'],

				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={found.vocMeasure}
						setValue={(data) => {
							stateFunctions.setVocMeasure(_uuid_, data)
						}}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allVocMeasures}
					/>
				),
			},
		],
	]
}
