import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import ruRu from 'antd/lib/locale/ru_RU'
import { Alert, ConfigProvider } from 'antd'

import App from './App'
import './index.css'
import { BroadcastProvider } from './contexts'

ReactDOM.render(
	<Router>
		<BroadcastProvider>
			<ConfigProvider locale={ruRu}>
				<Alert.ErrorBoundary>
					<App />
				</Alert.ErrorBoundary>
			</ConfigProvider>
		</BroadcastProvider>
	</Router>,
	document.getElementById('root')
)
