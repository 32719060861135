import React, { useContext, useEffect, useRef, useCallback, useState } from 'react'
import lodash from 'lodash'
import { ModContext, SyncDepsContext } from '../../../../contexts'
import {
	axios,
	getNewObj,
	getObjDiff,
	modes,
	prepareObjFromServer,
	simpleFilterAlreadyExists,
	useItemFetchers,
} from '../../../../utils'
import { getAvailableCode, getCommonProviderFunctions } from '../../../../utils/helpers/generators'
import { basicValidator } from '@berry/common-functions/validators'
import { showSaveAsDraft, showSendNotifyModal } from '../../../../utils/constants/for-components'
import { asyncShowConfirmModal, showConfirmModal } from '../../../../components'
import {
	calcSupplyInpCtrlDiffWeight,
	calcSupplyInpCtrlFactWeight,
} from '@berry/common-functions/cross-project-functions'
import { Modal } from 'antd'
import { useHistory } from 'react-router-dom'
import { commonCheckIsBlocked } from '../../../../utils/helpers/for-block-unblock'
import {
	commonBlockForEditing,
	commonUnblockForEditing,
} from '../../../../utils/helpers/for-block-unblock'
import useInactivityDetector from '../../../../utils/hooks/useInactivityDetector'
import useCloseOrReloadTab from '../../../../utils/hooks/useCloseOrReloadTab'
import { EDIT_SESSION_EXPIRE_IN } from '../../../../utils/constants/plain-text'
import { v4 } from 'uuid'

const dataUrl = '/rm/supply-unloads'
const tabs = { 'unld-storages': 'unldStorages', 'unld-pal-distribs': 'unldPalDistribs' }
export const reducer = (state) => {
	return {
		...state,
	}
}

const SupplyUnloadContext = React.createContext()
SupplyUnloadContext.displayName = 'SupplyUnloadContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const syncDepsCtx = useContext(SyncDepsContext)
	const [preSseUpdateOldData, setPreSseUpdateOldData] = useState({})
	const [state, dispatch] = React.useReducer(reducer, {
		data: {},
		blockUnblock: {
			isBlocked: true,
			depsBlocked: [],
		},
		oldData: {},
		formErrors: {},
		additional: { allRooms: [], unldStorages: [] },
	})
	const stateRef = useRef(state)
	const executeDispatch = useInactivityDetector(
		stateRef,
		dispatch,
		modCtx,
		blockForEditing,
		async () => {
			modCtx.set(modes.view)
			await unblockForEditing()
			await axios.post(`/logs`, {
				time: new Date().toISOString(),
				action: 'User inactivity',
				entity: 'rmSupplProd',
				idEntity: params.id,
			})
			stateRef.current = { ...stateRef.current, reloadUuid: v4() }
			dispatch({ ...stateRef.current })
			showConfirmModal({
				title: EDIT_SESSION_EXPIRE_IN,
				onOk: () => {},
				showCancel: false,
				okText: 'Ок',
			})
		},
		{
			activityCallbackIntervalSeconds: 10,
			inactivityLimitSeconds: 60 * 5,
		}
	)
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))
	const setError = (err) => {
		executeDispatch({
			...stateRef.current,
			formErrors: { ...stateRef.current.formErrors, ...err },
		})
	}

	const unsetError = (err) => {
		const newErrors = { ...stateRef.current.formErrors }
		lodash.unset(newErrors, Object.keys(err))
		executeDispatch({
			...stateRef.current,
			formErrors: newErrors,
		})
	}

	const dropErrors = () => {
		executeDispatch({
			...stateRef.current,
			formErrors: {},
		})
	}

	/**
	 * подготавливает данные которые нужно выбират
	 */
	const selectors = {
		unldPalDistribs: {
			palState: [
				'Удовлетворительно',
				'Повреждена',
				'Загрязнена',
				'Посторонние предметы',
				'Повреждена частично',
			],
			postList: ['Есть', 'Нет', 'Частично'],
			pkgIntegrity: ['Не нарушена', 'Нарушена', 'Нарушена частично'],
			mark: ['Есть', 'Нет', 'Частично'],
			palletization: ['Соответствует ', 'Не соответствует', 'Отсутствует'],
		},
		unldStaff: stateRef.current.additional.allUnldStaff,
		unldStorages: {
			room: simpleFilterAlreadyExists(
				stateRef.current.additional.allRooms,
				stateRef.current.data.unldStorages
			),
		},
	}
	const { getEditedData, isEdited, commonFieldUpdate, stateFunctions, serverEdit, serverDelete } =
		getCommonProviderFunctions(
			stateRef,
			stateRef.current.oldData,
			executeDispatch,
			{ modCtx, dataUrl, params, pageUrl: '/rm/supply-unloads', history },
			selectors,
			{
				unldVNaval: 'common',
				unldNettoPkgWeight: 'common',
				unldStaff: 'obj',
				unldComment: 'common',
				unldStatus: 'common',
				notifyWeightDifference: 'common',
			},
			{
				unldPalDistribs: {
					brutto: 'common',
					palCount: 'common',
					palPkgCount: 'common',
					pkgWeight: 'common',
					palWeight: 'common',
					palState: 'common',
					postList: 'common',
					pkgIntegrity: 'common',
					mark: 'common',
					palletization: 'common',
				},
				unldStorages: {
					weight: 'common',
					room: 'obj',
				},
			}
		)
	stateFunctions.unldPalDistribs.create = () => {
		let newArr = [
			...lodash.cloneDeep(stateRef.current.data.unldPalDistribs),
			getNewObj({
				displayCode: getAvailableCode(
					stateRef.current.data?.unldPalDistribs.map((e) => e.displayCode).filter((e) => e)
				),
				palState: 'Удовлетворительно',
				postList: 'Есть',
				pkgIntegrity: 'Не нарушена',
				mark: 'Есть',
				palletization: 'Соответствует',
			}),
		]
		commonFieldUpdate('unldPalDistribs', newArr)
		return newArr.slice(-1)[0]._uuid_
	}

	stateFunctions.unldStorages.create = () => {
		const rooms = selectors.unldStorages.room.filter((r) => {
			return r.outdoorWh.id === stateRef.current.data.supply.supplyReq.idVocOutdoorWh
		})
		let newArr = [
			...lodash.cloneDeep(stateRef.current.data.unldStorages),
			getNewObj(rooms.length === 1 ? { room: rooms.at(0) } : {}),
		]
		commonFieldUpdate('unldStorages', newArr)
		return newArr.slice(-1)[0]._uuid_
	}

	stateFunctions.setUnldIsStandPkg = (val) => {
		commonFieldUpdate('unldIsStandPkg', val)
		commonFieldUpdate('unldPalDistribs', [])
		commonFieldUpdate('unldStorages', [])
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(
		(onlyStatic) => {
			const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
			prepareObjFromServer(recordFromDataSrvCtx)
			if (onlyStatic) {
				let toUpdate = {}
				for (const key of Object.keys(recordFromDataSrvCtx)) {
					if (!key.startsWith('unld')) {
						toUpdate[key] = recordFromDataSrvCtx[key]
					}
				}
				executeDispatch({
					...stateRef.current,
					data: {
						...stateRef.current.data,
						...lodash.cloneDeep(toUpdate),
					},
					formErrors: {},
				})
			} else {
				executeDispatch({
					...stateRef.current,
					data: {
						...recordFromDataSrvCtx,
					},
					formErrors: {},
				})
			}
			return
		},
		[stateRef.current.oldData]
	)
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
		return
	}

	useEffect(() => {
		if (params.id === 'new') return

		if (stateRef.current.oldData.__notFound) {
			_reset()
			return
		}
		const diff = getObjDiff(
			lodash.cloneDeep(preSseUpdateOldData),
			lodash.cloneDeep(stateRef.current.oldData)
		)
		setPreSseUpdateOldData(lodash.cloneDeep(stateRef.current.oldData))
		const invStatusChanged =
			lodash.get(preSseUpdateOldData, ['invoice', 'status']) &&
			!!lodash.get(diff, ['invoice', 'status'])
		const unldFieldsChanged = Object.keys(diff).some((k) => k.startsWith('unld'))
		const modalObj = {
			...showSendNotifyModal,
			showCancel: false,
			title: (
				<>
					<span>Были внесены изменения в родительской карточке поставки сырья.</span>
					<br></br>
					<span>Система автоматически обновит данные карточки.</span>
				</>
			),
		}
		if (Object.keys(diff).length === 1) return
		if (invStatusChanged) {
			_reset()
			Modal.destroyAll()
			showConfirmModal(modalObj)
			return modCtx.set(modes.view)
		}
		if (!unldFieldsChanged) {
			_reset(true)
			Modal.destroyAll()
			showConfirmModal(modalObj)
		} else {
			_reset()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * Валидация всех полей в соответствии с конфлюенсом
	 * @returns {string} - если вернулась строка и не пустая значит есть ошибка валидации
	 */
	const requiredFields = {
		unldPalDistribs: [
			'brutto',
			'palPkgCount',
			'pkgWeight',
			'palWeight',
			'palState',
			'postList',
			'pkgIntegrity',
			'mark',
			'palletization',
		],
		unldPalDistribsStandPkg: [
			'palCount',
			'palPkgCount',
			'palState',
			'postList',
			'pkgIntegrity',
			'mark',
			'palletization',
		],
		unldStorages: ['room', 'weight'],
	}
	const checkStorageSum = () => {
		const currentSum = stateRef.current.data.unldStorages.reduce(
			(sum, cur) => sum + (+cur.weight || 0),
			0
		)
		const factWeight = calcSupplyInpCtrlFactWeight(stateRef.current.data)
		const result = currentSum === factWeight
		let errors = {}
		for (const storage of stateRef.current.data.unldStorages) {
			errors[`weight.${storage._uuid_}`] = !result
		}
		setError(errors)
		return result
	}
	const validate = async (form) => {
		let newFormErrors = {}
		const data = stateRef.current.data
		const distFields = data.unldIsStandPkg
			? requiredFields.unldPalDistribsStandPkg
			: requiredFields.unldPalDistribs
		if (data.unldPalDistribs.some((dist) => distFields.some((f) => !basicValidator(dist[f])))) {
			data.unldPalDistribs.forEach((d) => {
				distFields.forEach((f) => {
					if (!basicValidator(d[f])) {
						newFormErrors = { ...newFormErrors, [f + '.' + d._uuid_]: true }
					}
				})
			})
		}
		if (
			data.unldStorages.some((s) => requiredFields.unldStorages.some((f) => !basicValidator(s[f])))
		) {
			data.unldStorages.forEach((s) => {
				requiredFields.unldStorages.forEach((f) => {
					if (!basicValidator(s[f])) {
						newFormErrors = { ...newFormErrors, [f + '.' + s._uuid_]: true }
					}
				})
			})
		}
		if (!data.unldPalDistribs?.length) {
			lodash.merge(newFormErrors, { unldPalDistribs: true })
		}
		if (!data.unldStorages?.length) {
			lodash.merge(newFormErrors, { unldStorages: true })
		}
		if (
			data.unldPalDistribs?.length &&
			requiredFields.unldPalDistribs.some((e) => !basicValidator(e))
		) {
			lodash.merge(newFormErrors, { unldPalDistribs: true })
		}
		if (
			data.unldPalDistribs?.length &&
			requiredFields.unldPalDistribs.some((e) => !basicValidator(e))
		) {
			lodash.merge(newFormErrors, { unldPalDistribs: true })
		}
		if (Object.keys(newFormErrors).length || !checkStorageSum()) {
			const saveAsDraft = await confirmDraft()
			if (!saveAsDraft) {
				setError(newFormErrors)
				await form.validateFields()
				throw Error()
			} else {
				stateFunctions.setUnldStatus('Черновик')
				dropErrors()
				form.setFields(
					form.getFieldsError().map((f) => ({
						...f,
						errors: [],
					}))
				)
				return ''
			}
		}
		const diffWeight = calcSupplyInpCtrlDiffWeight(stateRef.current.data)
		if (diffWeight !== 0) {
			const sendDiff = await confirmDiff(diffWeight)
			if (!sendDiff) {
				throw Error()
			}
		}
		stateFunctions.setUnldStatus('Разгрузка завершена')
		return ''
	}

	const confirmDraft = async () => {
		const modalResult = await asyncShowConfirmModal({
			...showSaveAsDraft,
			width: '39.3%',
			title: (
				<>
					<span>Партия не сможет быть принята на склад, не заполнены обязательные поля.</span>
					<br></br>
					<span>Сохранить карточку для последующего редактирования?</span>
				</>
			),
		})
		return modalResult
	}

	const confirmDiff = async (diffWeight) => {
		const modalResult = await asyncShowConfirmModal({
			...showSendNotifyModal,
			title: (
				<>
					<span>
						При сохранении карточки будет направлено уведомление автору Заявки на поставку сырья.
					</span>
					<br></br>
					<span>Расхождение в массе, кг = {diffWeight}</span>
				</>
			),
		})
		if (modalResult) {
			stateFunctions.setNotifyWeightDifference({ diffWeight })
		}
		return modalResult
	}

	const validators = {
		kinds: (inUuid) => {
			const found = stateFunctions.kinds.get(inUuid)
			const whereToFind = stateRef.current.kinds.filter((e) => e._uuid_ !== inUuid)
			if (!basicValidator(found.label)) throw Error()
			if (whereToFind.some((e) => e.label.toLowerCase() === String(found.label).toLowerCase())) {
				throw Error('Вид не уникален')
			}
			return
		},
	}

	const getActualState = () => {
		const obj = lodash.cloneDeep(stateRef.current.data)
		return obj
	}

	const getDepsOn = () => []

	const depsFunctions = {
		unldPalDistribs: (inId) => [],
		unldStorages: (inId) => [],
	}

	const checkIsBlocked = async () => {
		const res = await axios(`${dataUrl}/check-block/${params.id}`)
		if (res.status === 200) {
			return res.data.mainData.isBlocked
		}
		return true
	}

	useCloseOrReloadTab(
		async (event) => {
			if (modCtx.mod === modes.edit) {
				event.preventDefault()
				await unblockForEditing()
			}
		},
		[
			dataUrl,
			params.id,
			modCtx.mod,
			tabs,
			syncDepsCtx.state.reloadUuids['office-ms'],
			stateRef.current.reloadUuid,
		]
	)

	const handleBlockOfChangedSelect = async (entity, val) => {
		const foundIndx = stateRef.current.blockUnblock.depsBlocked.findIndex(
			(d) => d.entity === entity
		)
		let newDepsBlocked = [...stateRef.current.blockUnblock.depsBlocked]
		if (foundIndx !== -1) {
			axios.post(`/unblock-entity`, [{ ...newDepsBlocked[foundIndx] }])
			newDepsBlocked.splice(foundIndx, 1)
		}
		newDepsBlocked.push({ entity, id: val.id, blockedByEntity: 'rmSupplyUnload' })
		executeDispatch({
			...stateRef.current,
			blockUnblock: { ...stateRef.current.blockUnblock, depsBlocked: newDepsBlocked },
		})
		axios.post(`/block-entity`, [{ entity, id: val.id, blockedByEntity: 'rmSupplyUnload' }])
	}

	const entityToSetFuncNameMapForMainCard = {
		regStaff: 'setUnldStaff',
	}
	const entityToSetFuncNameMapForStorages = {
		vocEditableRoom: 'setRoom',
	}
	const handleBlockOfChangedStorages = async (entity, inUuid, val) => {
		const foundIndx = stateRef.current.blockUnblock.depsBlocked.findIndex(
			(d) => d.entity === entity && inUuid === d.uuid
		)
		let newDepsBlocked = [...stateRef.current.blockUnblock.depsBlocked]
		if (foundIndx !== -1) {
			axios.post(`/unblock-entity`, [
				{ entity, id: newDepsBlocked[foundIndx].id, blockedByEntity: 'rmSupplyUnload' },
			])
			newDepsBlocked.splice(foundIndx, 1)
		}
		newDepsBlocked.push({ entity, id: val.id, uuid: inUuid, blockedByEntity: 'rmSupplyUnload' })
		executeDispatch({
			...stateRef.current,
			blockUnblock: { ...stateRef.current.blockUnblock, depsBlocked: newDepsBlocked },
		})
		axios.post(`/block-entity`, [{ entity, id: val.id, blockedByEntity: 'rmSupplyUnload' }])
	}

	Object.entries(entityToSetFuncNameMapForStorages).forEach(([entity, funcName]) => {
		let oldF = stateFunctions.unldStorages[funcName]
		stateFunctions.unldStorages[funcName] = (inUuid, inVal, inRequired) => {
			oldF(inUuid, inVal, inRequired)
			handleBlockOfChangedStorages(entity, inUuid, inVal)
		}
	})

	Object.entries(entityToSetFuncNameMapForMainCard).forEach(([entity, funcName]) => {
		let oldF = stateFunctions[funcName]
		stateFunctions[funcName] = (inVal) => {
			oldF(inVal)
			handleBlockOfChangedSelect(entity, inVal)
		}
	})
	async function blockForEditing() {
		return await commonBlockForEditing(dataUrl, params.id)
	}

	async function unblockForEditing() {
		return await commonUnblockForEditing(
			stateRef.current.blockUnblock.depsBlocked,
			dataUrl,
			params.id
		)
	}
	const rerender = () => {
		executeDispatch(stateRef.current)
	}

	const value = {
		state: getActualState(),
		rerender,
		blockUnblock: stateRef.current.blockUnblock,
		blockForEditing,
		unblockForEditing,
		checkIsBlocked,
		stateFunctions: stateFunctions,
		isPendingReq: stateRef.current.isPendingReq,
		selectors: selectors,
		validators: validators,
		formErrors: stateRef.current.formErrors,
		serverEdit: serverEdit,
		getEditedData: getEditedData,
		isEdited: isEdited,
		validate: validate,
		serverDelete: serverDelete,
		reset: reset,
		getDepsOn: getDepsOn,
		depsFunctions,
		setError,
		unsetError,
		checkStorageSum,
	}

	return <SupplyUnloadContext.Provider value={value}>{children}</SupplyUnloadContext.Provider>
}

export { Provider, SupplyUnloadContext }
