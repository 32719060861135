import { Table } from 'antd'
import React, { useState, useContext } from 'react'
import lodash from 'lodash'
import { CustomerItemMainContext } from '../provider/main'
import { RecordItemModal, TabItem, CustomForm, showConfirmModal } from '../../../../components'
import { ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns, getFormItems } from './contact-tab'
import { prepareForOpenedModal } from '../../../../utils/helpers/for-modal'

const ContactTab = () => {
	const mainCtx = useContext(CustomerItemMainContext)
	const stateFunctions = mainCtx.stateFunctions.contacts
	let stateData = mainCtx.state.contacts
	const modCtx = useContext(ModContext)
	const [isModalOpen, setIsModalOpen] = useState(false)

	/**
	 *  {_uuid_:string,isNew:boolean } itemForModal
	 */
	const [itemForModal, setItemForModal] = useState(null)
	stateData = prepareForOpenedModal(stateData, itemForModal)
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const newUuid = stateFunctions.create()
					setItemForModal({ _uuid_: newUuid, isNew: true })
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						isOpen={isModalOpen}
						formName="contact"
						title="Контакт"
						item={itemForModal}
						deleteFun={stateFunctions.delete}
						setItem={setItemForModal}
						setModalState={setIsModalOpen}
						editFun={stateFunctions.edit}
						validate={() => mainCtx.validators.contacts(itemForModal._uuid_)}
						form={() => {
							if (!itemForModal) return null
							return (
								<CustomForm
									name="contact"
									onFinish={(values) => {
										if (!values.email && !values.phoneNum)
											showConfirmModal({
												title: 'Укажите номер телефона или электронную почту',
												onOk: () => {},
												showCancel: false,
												okText: 'Ок',
											})
									}}
									fields={[
										{ name: 'fullName', value: stateFunctions.get(itemForModal._uuid_).fullName },
										{ name: 'position', value: stateFunctions.get(itemForModal._uuid_).position },
										{ name: 'phoneNum', value: stateFunctions.get(itemForModal._uuid_).phoneNum },
										{ name: 'email', value: stateFunctions.get(itemForModal._uuid_).email },
									]}
									columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
									items={getFormItems(mainCtx, modCtx, itemForModal)}
								/>
							)
						}}
					/>
				)
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(stateFunctions, modCtx, (record) => {
							if (modCtx.mod === modes.view) return
							setItemForModal({ _uuid_: record._uuid_, prevData: lodash.cloneDeep(record) })
							setIsModalOpen(true)
						})}
						loading={mainCtx.tabsLoading.contacts}
						dataSource={stateData}
						pagination={false}
						scroll={{ x: 800 }}
						key={'contactTable'}
					/>
				)
			}}
		></TabItem>
	)
}

export default ContactTab
