import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { services } from '../constants'
import { AuthContext, DataServerContext } from '../../contexts'
import { v4 } from 'uuid'
import { Space, Typography } from 'antd'
import { showConfirmModal } from '../../components'

const useSseSubscribe = () => {
	const authCtx = useContext(AuthContext)
	const dataServerCtx = useContext(DataServerContext)
	const confirmModalRef = useRef(null)
	const authCallbacks = useMemo(
		() => ({
			logout: authCtx.signOut,
		}),
		[authCtx.signOut]
	)
	const officeCallbacks = useMemo(
		() => ({
			update: dataServerCtx.updateSseFn,
			updateVoc: dataServerCtx.updateVocSseFn,
		}),
		[dataServerCtx.updateSseFn, dataServerCtx.updateVocSseFn]
	)
	const [sse, setSse] = useState({})

	const subscribe = useCallback(() => {
		console.log('subscribe try loop')
		const service = 'auth-ms'
		console.log('subscribe inside loop')
		if (!sse[service]) {
			console.log('subscribe inside if')
			let uuidUser
			if (window.name) {
				uuidUser = window.name
			} else {
				uuidUser = v4()
				window.name = uuidUser
			}
			const newSse = new EventSource(
				services[service] +
					`/pub/add-sse-client?uuidUser=${uuidUser}&token=${localStorage.getItem('token')}`
			)
			console.log('subscribe new sse defined')
			newSse.onopen = () => {
				console.log(`subscribe ${service} onopen`)
			}
			newSse.onmessage = async (e) => {
				try {
					const serverMessage = JSON.parse(e.data)
					if (serverMessage.type) {
						console.log(serverMessage.type)
						console.log(`subscribe ${service} got message`)
						console.log(serverMessage)
					}
					if (serverMessage.err) {
					} else {
						if (serverMessage.type === 'close') {
							newSse.close()
						}
						if (authCallbacks[serverMessage.type]) {
							await authCallbacks[serverMessage.type](serverMessage, newSse)
						}
						if (officeCallbacks[serverMessage.type]) {
							await officeCallbacks[serverMessage.type](serverMessage, newSse)
						}
					}
				} catch (err) {
					console.log(`subscribe message error: ${err.message}`)
				}
			}
			newSse.onerror = async (err) => {
				console.log(`subscribe ${service} error: `)
				console.log(err)
				sse[service].close()
				if (process.env.REACT_APP_STAND !== 'develop') {
					if (confirmModalRef.current) {
						confirmModalRef.current.destroy()
					}
					confirmModalRef.current = showConfirmModal({
						title: (
							<Space direction="vertical">
								<Typography.Text>Невозможно подключиться к серверу</Typography.Text>
								<Typography.Text>
									Для продолжения работы необходимо войти в систему заново
								</Typography.Text>
							</Space>
						),
						onOk: () => {
							authCtx.signOut()
							localStorage.setItem('needToUpdate', true)
						},
						okText: 'Ок',
						showCancel: false,
						width: 650,
						closable: false,
					})
				}
			}
			sse[service] = newSse
		}
	}, [authCallbacks, authCtx, officeCallbacks, sse])

	useEffect(() => {
		if (Object.keys(sse).length) {
			if (!authCtx?.state?.isAuthenticated) {
				for (const service of Object.keys(services)) {
					if (sse[service]) {
						sse[service].close()
						delete sse[service]
					}
				}
			}
		} else {
			if (authCtx?.state?.isAuthenticated) {
				subscribe()
			}
		}
	}, [authCtx?.state?.isAuthenticated, sse, subscribe])

	return { sse, setSse, subscribe }
}

export default useSseSubscribe
