import { capitalizeFirstLetter } from '@berry/common-functions/text-functions'
import { Checkbox } from 'antd'
import { getColumns as getWasteColumns } from '../../../../Stock/Waste/WasteList/waste-list'
import { getColumns as getSampleColumns } from '../../../../Stock/Sample/SampleList/sample-list'
import { TableWithListLoader } from '../../../../../components'

const getCheckboxColumn = (mainCtx) => {
	const addProdName = [`addProdStock${capitalizeFirstLetter(mainCtx.addProd.__name)}Stor`]
	return {
		title: null,
		key: 'checkbox',
		width: '2%',
		render: (record) => {
			return (
				<Checkbox
					disabled={mainCtx.isModalItemDisabled(record)}
					checked={mainCtx.addProd[addProdName]?.some(
						(el) => String(el.idStorage) === String(record.idStorage)
					)}
					onChange={(e) => {
						const val = e.target.checked
							? record
							: mainCtx.addProd[addProdName].find((s) => s.idStorage === record.idStorage)
						if (val._uuid_) {
							return mainCtx.modalFunctions[addProdName].delete(val._uuid_)
						}
						return mainCtx.modalFunctions[addProdName].create(val)
					}}
				/>
			)
		},
	}
}

const SelectProdModalTable = ({ mainCtx }) => {
	let url = null
	let calcColumns = null
	let filters = {}
	let defaultSorter = null
	let permanentFilters = {}
	let params = {}
	switch (mainCtx.addProd.__name) {
		case 'waste':
			url = '/stock/wastes'
			params = {
				status: mainCtx.state.idContrOrder
					? ['К отгрузке']
					: mainCtx.state.idContrOrder === null
					? ['Хранение']
					: ['К отгрузке', 'Хранение'],
				...(mainCtx.state.id && {
					idDisposal: mainCtx.state.id,
				}),
			}
			calcColumns = (props) => [getCheckboxColumn(mainCtx), ...getWasteColumns(props).slice(0, -1)]
			filters = {
				idOrder: [],
				partyNum: [],
				articul: [],
				articul1C: [],
				prodCat: [],
				prodCatKind: [],
				pkgType: [],
				contType: [],
				nettoPkgWeight: [],
				roomNum: [],
				status: [],
				product: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					status: mainCtx.state.idContrOrder
						? ['К отгрузке']
						: mainCtx.state.idContrOrder === null
						? ['Хранение']
						: ['К отгрузке', 'Хранение'],
				},
				exclude: {
					idStorage: [...mainCtx.state.productions, ...mainCtx.deletedProds]
						.filter((p) => p.idStockWasteStor || p.stockWasteStor)
						.flatMap((p) => [
							p.idStockWasteStor || p.stockWasteStor.idStorage,
							p.stockWasteStor.parent?.id,
						])
						.filter((id) => !isNaN(id)),
				},
			}
			if (mainCtx.state.idContrOrder) {
				permanentFilters.include.idContrOrder = [mainCtx.state.idContrOrder]
			}
			break
		case 'sample':
			url = '/stock/samples'
			calcColumns = (props) => [getCheckboxColumn(mainCtx), ...getSampleColumns(props)]
			params = mainCtx.state.id ? { status: ['Хранение'], idDisposal: mainCtx.state.id } : {}
			filters = {
				idOrder: [],
				partyNum: [],
				articul: [],
				articul1C: [],
				prodCat: [],
				prodCatKind: [],
				pkgType: [],
				nettoPkgWeight: [],
				roomNum: [],
				status: [],
				_1c: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					status: mainCtx.state.idContrOrder
						? ['К отгрузке']
						: mainCtx.state.idContrOrder === null
						? ['Хранение']
						: ['К отгрузке', 'Хранение'],
				},
				exclude: {
					idStorage: [...mainCtx.state.productions, ...mainCtx.deletedProds]
						.filter((p) => p.idStockSampleStor || p.stockSampleStor)
						.flatMap((p) => [
							p.idStockSampleStor || p.stockSampleStor.idStorage,
							p.stockSampleStor.parent?.id,
						])
						.filter((id) => !isNaN(id)),
				},
			}
			if (mainCtx.state.idContrOrder) {
				permanentFilters.include.idContrOrder = [mainCtx.state.idContrOrder]
			}
			break
		default:
			throw Error('unknown tab opened')
	}

	return (
		<TableWithListLoader
			{...{
				url,
				params,
				calcColumns,
				filters,
				defaultSorter,
				permanentFilters,
			}}
		/>
	)
}

export default SelectProdModalTable
