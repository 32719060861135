import React, { useContext, useState } from 'react'

import { Main } from '../../../layouts'
import { Provider, SystemSettingsListMainContext } from './provider/main'
import { HeaderMenu } from '../../../components'
import { Card, Table } from 'antd'
import { goToItem } from '../../../utils'
import { useHistory } from 'react-router'
import rowClassName from '../../../utils/helpers/rowClassName'

const pageUrl = '/system-settings'

const SystemSettingsList = (props) => {
	return (
		<Provider>
			<Main title={'Настройки системы'}>
				<SystemSettingsListTable />
			</Main>
		</Provider>
	)
}

const SystemSettingsListTable = () => {
	const mainCtx = useContext(SystemSettingsListMainContext)
	const history = useHistory()
	const [rowId, setRowId] = useState('')

	return (
		<>
			<HeaderMenu headerStyle={{ height: 32 }} breadcrumbsTitle="Настройки системы" />
			<Card>
				<Table
					showHeader={false}
					rowKey="link"
					dataSource={mainCtx.dataList}
					columns={[{ dataIndex: 'title', key: 'title', width: '100%' }]}
					pagination={{ showPageSize: false }}
					size="small"
					scroll={{ x: 800 }}
					rowClassName={rowClassName(rowId)}
					onRow={(data) => ({
						onClick: () => goToItem(history, { url: pageUrl, id: data.link }, data),
						onMouseEnter: () => {
							setRowId(data.link)
						},
						onMouseLeave: () => {
							setRowId('')
						},
					})}
				/>
			</Card>
		</>
	)
}
export default SystemSettingsList
