import { message, Upload, Modal, Image, Card } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { axios, modes } from '../../utils'
import { ModContext } from '../../contexts'
import { showConfirmModal } from '../../../src/components'

const getBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
}

const ImageUploader = (props) => {
	const { data, setPhoto: setPhotoValue, isShowConfirmModal } = props
	const modCtx = useContext(ModContext)
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [photo, setPhoto] = useState(null)

	useEffect(() => {
		const getPhoto = async () => {
			try {
				const file = await axios(`/uploads?photo=${data.photoPath}`, {
					headers: { 'Content-Type': 'imageFile.type', accept: 'image/avif,image/webp,*/*' },
					responseType: 'arraybuffer',
				})
				const buffer = Buffer.from(file.data, 'binary').toString('base64')
				if (buffer) {
					setPhoto({
						uid: data.photoPath,
						status: 'done',
						url: `data:image/jpeg;base64, ${buffer}`,
					})
				}
			} catch (err) {
				console.log(err.message)
			}
		}
		if (data.photoPath) {
			getPhoto()
		}
		if ([null, undefined].includes(data.photoPath)) {
			setPhoto(null)
		}
	}, [data.photoPath])

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}></div>
		</div>
	)
	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj)
		}
		setPreviewImage(file.url || file.preview)
		setPreviewVisible(true)
	}
	const handleCancel = () => setPreviewVisible(false)
	const handleChange = (inData) => {
		if (inData.file.status === 'uploading') {
			inData.file.status = 'done'
		}
		if (!inData.fileList.length) {
			setPhotoValue(null)
		} else {
			const msgKey = 'updatable'
			if (!['image/png', 'image/jpg', 'image/jpeg'].includes(inData.fileList[0].type)) {
				if (isShowConfirmModal) {
					showConfirmModal({
						content: 'Можно загружать только изображения',
						okText: 'Ок',
						showCancel: false,
					})
					return
				}
				message.error({
					content: 'Можно загружать только изображения',
					key: msgKey,
					duration: 5,
				})

				return
			}
			setPhotoValue(inData.fileList[0])
			setPhoto(inData.fileList[0])
		}
	}

	return (
		<>
			<Upload
				accept=".png,.jpg,.bmp,.gif,.tif"
				className="register-upload-image"
				disabled={modCtx.mod === modes.view}
				customRequest={({ onSuccess, onError, file }) => {
					onSuccess(null)
				}}
				listType="picture-card"
				fileList={photo ? [photo] : []}
				onPreview={handlePreview}
				onChange={handleChange}
			>
				{photo ? null : uploadButton}
			</Upload>
			<Modal visible={previewVisible} footer={null} onCancel={handleCancel} centered>
				<Card bordered={false} style={{ padding: '30px 0' }}>
					<Image alt="example" style={{ width: '100%' }} src={previewImage} preview={false} />
				</Card>
			</Modal>
		</>
	)
}

export default ImageUploader
