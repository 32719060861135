import { Button, Card, Col, Modal, Row, Table, Typography } from 'antd'
import { useContext, useState } from 'react'
import { filterDisplayData, getQuotasYears, patchYearsProperty, prepareDataV2 } from '../../utils'
import { getDisplayColumnsV2, sortDataBySelected } from './utils'
import { ReactComponent as CloseIcon } from '../../../../assets/svg/close.svg'
import { SupplyReqModalContext } from '../../provider/supply-req'
import { AuthContext } from '../../../../contexts'
import { modes, openNewTab, prepareDataOld } from '../../../../utils'
import { ContractItemMainContext } from '../../provider/item'

const SupplyRequestModal = () => {
	const supplyReqCtx = useContext(SupplyReqModalContext)
	const mainCtx = useContext(ContractItemMainContext)
	const authCtx = useContext(AuthContext)

	const [filters, setFilters] = useState({
		applicationNum: [],
		'rmProvProd.displayVal': null,
		year: [],
		prodCat: [],
	})
	const stateWithFiltredProvQuotas = {
		...mainCtx,
		state: {
			...mainCtx.state,
			providerQuotas: [...mainCtx.state.providerQuotas?.filter((pq) => !pq.rmProvProd?.isArchive)],
		},
	}

	const quotasYears = getQuotasYears(stateWithFiltredProvQuotas, 'providerQuotas')
	const dataWithYearsProp =
		stateWithFiltredProvQuotas.state.providerQuotas?.map((e) => {
			return { ...e, years: quotasYears[e._uuid_] }
		}) || []
	const dataByYears = prepareDataOld(dataWithYearsProp, 'years', 'year')
	const toDisplayData = prepareDataV2(dataByYears)
	const columns = getDisplayColumnsV2(
		stateWithFiltredProvQuotas,
		supplyReqCtx,
		toDisplayData,
		filters
	)

	let filteredToDisplayData = filterDisplayData(toDisplayData, filters)
	patchYearsProperty(filteredToDisplayData, filters.year)
	filteredToDisplayData = sortDataBySelected(filteredToDisplayData, supplyReqCtx.state.selected)

	return (
		<Modal
			visible={supplyReqCtx.state.isModalOpen}
			destroyOnClose
			centered
			bodyStyle={{ paddingTop: '10px' }}
			footer={null}
			closable={false}
			width="90%"
		>
			<Card
				bodyStyle={{
					margin: 0,
					padding: 0,
				}}
				bordered={false}
			>
				<Row gutter={[16, 16]} style={{ padding: '0 0 10px 0' }}>
					<Col flex={1}>
						<Typography.Title level={4} style={{ margin: 0 }}>
							Заявка
						</Typography.Title>
					</Col>
					<Col>
						<Button
							type="primary"
							// size="large"
							onClick={async () => {
								try {
									supplyReqCtx.validate()
									openNewTab(`/rm/supply-requests/new`, {
										authCtx: authCtx.state,
										modCtx: {
											mod: modes.new,
										},
										supplyReqCopy: supplyReqCtx.getBody(),
									})
									supplyReqCtx.reset()
									supplyReqCtx.stateFunctions.fieldSetters.setIsModalOpen(false)
								} catch (err) {
									if (err.message) {
										Modal.warning({
											centered: true,
											closable: false,
											closeIcon: null,
											content: err.message,
											keyboard: true,
										})
									}
								}
							}}
						>
							Сохранить
						</Button>
					</Col>
					<Col>
						<Button
							type="text"
							// size="large"
							style={{ lineHeight: 0.8 }}
							icon={<CloseIcon />}
							onClick={() => {
								supplyReqCtx.reset()
								supplyReqCtx.stateFunctions.fieldSetters.setIsModalOpen(false)
							}}
						/>
					</Col>
				</Row>
			</Card>
			<Table
				rowKey={(rec) => {
					return `${rec.id || rec._uuid_}_${rec.indxMain}_${
						rec.isDiplayedRowBySpan ? 'displ' : 'not_displ'
					}`
				}}
				dataSource={filteredToDisplayData}
				columns={columns}
				pagination={false}
				scroll={{ x: 800 }}
				size="small"
				onChange={(_, filters, __) => {
					setFilters(filters)
				}}
			/>
		</Modal>
	)
}

export default SupplyRequestModal
