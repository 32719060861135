import React, { useState, useEffect, useContext } from 'react'

import Wrapper from '../Wrapper/Wrapper'
import { axios, useInput } from '../../../utils'
import { getDataForVocs } from '../AdditionalSemiType/AdditionalSemiType'

import Form from './Form'
import { columns } from './columns'
import { addData, deleteData, editData } from '../../../services/api/requests'
import { SyncDepsContext } from '../../../contexts'
import { commonCheckIsBlocked } from '../../../utils/helpers/for-block-unblock'
import showConfirmModal from '../../ConfirmModal/ConfirmModal'
import { showIsBLockedModalForDeps } from '../../../utils/constants/for-components'

const vocUrl = '/voc/editable/raw-material-country'

const RawManufacturer = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, setState] = useState([])
	useEffect(async () => {
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
	}, [syncDepsCtx.state.reloadUuids['office-ms']])
	const [recordId, setRecordId] = useState(null)

	const [label, resetLabel] = useInput('')

	const resetFields = () => {
		resetLabel()
	}

	const addItem = async (successFn = (fn) => fn) => {
		const response =
			(await addData(vocUrl, {
				data: {
					label: label.value,
				},
			})) || {}
		if (response.data.result.data) {
			setState((prev) => [...prev, response.data.result.data])
			successFn()
		}
	}

	const editItem = async (successFn = (fn) => fn) => {
		if (await checkIsBlocked(recordId)) {
			axios.post(`/logs`, {
				time: new Date().toISOString(),
				action: 'Edit',
				isBlockUnblockLogic: true,
				entity: 'vocEditableRawMatCountry',
				idEntity: recordId,
			})
			showConfirmModal(showIsBLockedModalForDeps)
			return
		}

		const response = await editData(vocUrl, {
			recordId,
			data: {
				id: recordId,
				label: label.value,
			},
		})
		if (response.data.result.data) {
			setState((prev) => {
				const foundIndex = prev.findIndex((el) => el.id === response.data.result.data.id)
				return [
					...prev.slice(0, foundIndex),
					response.data.result.data,
					...prev.slice(foundIndex + 1),
				]
			})
			successFn()
		}
	}

	const deleteItem = async (id) => {
		axios.post(`/logs`, {
			time: new Date().toISOString(),
			action: 'Delete',
			isBlockUnblockLogic: true,
			entity: 'vocEditableRawMatCountry',
			idEntity: recordId,
		})
		const response = await deleteData(vocUrl, {
			id,
		})
		setState((prev) => {
			const foundIndex = prev.findIndex((el) => el.id === +response.data.result.id)
			return [...prev.slice(0, foundIndex), ...prev.slice(foundIndex + 1)]
		})
		setRecordId(null)
	}
	const checkIsBlocked = async (id) => {
		const result = await commonCheckIsBlocked([{ entity: 'vocEditableRawMatCountry', id }])
		return result[0]?.isBlocked
	}

	return (
		<Wrapper
			rights={{
				Справочники: {
					'Страны происхождения "Сырья"': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle='Страны происхождения "Сырья"'
			model="voc_editable_raw_mat_country"
			canCreate
			canUpdate
			canDelete
			data={state}
			recordId={recordId}
			setRecordId={setRecordId}
			addData={addItem}
			editData={editItem}
			deleteData={deleteItem}
			columns={columns}
			resetFields={resetFields}
			fields={[{ label }]}
			validateFields={[{ label }]}
			uniqueFields={[{ label }]}
			form={<Form label={label} />}
			vocUrl={vocUrl}
		/>
	)
}

export default RawManufacturer
