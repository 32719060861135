import { Input } from 'antd'
import { modes } from '../../../utils'
import CommonSelect from './../../../components/CommonSelect/CommonSelect'

const layout = {
	labelCol: { xs: 24, sm: 10, md: 12, lg: 12, xl: 10 },
	wrapperCol: { xs: 24, sm: 14, md: 10, lg: 12 },
}

const layoutSmall = {
	labelCol: { sm: 10, md: 12, lg: 10, xl: 9, xxl: 5 },
	wrapperCol: { sm: 14, md: 10, lg: 14, xl: 14 },
}

const layoutMid = {
	labelCol: { sm: 10, md: 4, lg: 8, xl: { span: 5, offset: 3 }, xxl: { span: 3, offset: 3 } },
	wrapperCol: { sm: 14, md: 10, lg: 14, xl: 12 },
}

export const getFormItems = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view

	return [
		[
			{
				layout: layoutSmall,
				label: 'Заказчик',
				field: mainCtx.state.label,
			},
			{
				layout: layoutSmall,
				label: 'Код 1С',
				field: mainCtx.state.code,
			},
			{
				layout: layoutSmall,
				label: 'Адрес',
				field: mainCtx.state.address,
			},
		],
		[
			{
				layout: layoutMid,
				label: 'ИНН',
				field: mainCtx.state.inn,
			},
			{
				layout: layoutMid,
				label: 'КПП',
				field: mainCtx.state.kpp,
			},
		],
		[
			{
				layout: layout,
				label: 'Условия оплаты',
				name: 'vocPayCondit',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.vocPayCondit}
						setValue={mainCtx.stateFunctions.setVocPayCondit}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allSelectPayCondits}
					/>
				),
			},
			{
				layout: layout,
				label: 'Своевременность вывоза ГП',
				name: 'timelyExport',
				rules: [{ required: true }],
				field: (
					<Input
						style={{ width: '100%' }}
						value={mainCtx.state.timelyExport}
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setTimelyExport(e.target.value)
						}}
					/>
				),
			},
		],
	]
}
