import { Button, message, Space, Typography } from 'antd'
import { goToItem } from './for-router'
import { checkRole } from '@berry/common-functions/role'
import { rights } from '../urls/rights'
/**
 * делает базовые вещи при нажатии на кнопку сохранить
 * @param {object} params - параметры для перехода
 * @param {function} params.validate - валидатор
 * @param {function} params.serverEdit - функция изменения данных на сервере
 * @param {object} params.history - обьект истории
 * @param {string} params.pageUrl - урл основной на которолм рисуется кнопка
 */
export const actBtnOnSave = async (params) => {
	if (!params.validate || !params.serverEdit) throw Error('параметры передавны неверно')
	const { validate, serverEdit, draft = false } = params
	try {
		const validateResult = await validate()
		if (validateResult.length) {
			message.error(validateResult, 5)
			return
		}
		const res = await serverEdit()
		if (res === null)
			throw Error('Были внесены изменения другим пользователем, введенные данные не сохранены')
		if (res?.method === 'POST' && res.id) {
			if (params.history && params.pageUrl) {
				goToItem(params.history, { url: params.pageUrl, id: res.id })
			}
		}
	} catch (err) {
		const errMessage = draft ? err : 'Ошибка во время сохранения'
		if (errMessage?.length) {
			message.error(errMessage, 5)
		}
	}
	return true
}
/**
 * делает базовые вещи при нажатии на кнопку сохранить
 * @param {object} params - параметры для перехода
 * @param {function} params.validate - валидатор
 * @param {function} params.serverEdit - функция изменения данных на сервере
 * @param {object} params.history - обьект истории
 * @param {string} params.pageUrl - урл основной на которолм рисуется кнопка
 */
export const actBtnOnSaveV2 = async (params) => {
	if (!params.validate || !params.serverEdit) throw Error('параметры передавны неверно')
	let id = null
	try {
		const {
			validate,
			serverEdit,
			isShowErrorModal,
			showConfirmModal,
			confirmModalWidth,
			additionalFunc,
			form,
			canSaveWithErrors = false,
		} = params
		let validateResult = ''
		try {
			if (form && !canSaveWithErrors) {
				await form.validateFields()
			}
			validateResult = await validate()
		} catch (err) {
			console.log(err.message)
			if (!canSaveWithErrors) return
		}
		if (validateResult?.length) {
			if (isShowErrorModal) {
				showConfirmModal({
					title: validateResult,
					onOk: () => {},
					showCancel: false,
					okText: 'Ок',
					width: confirmModalWidth,
				})
			}
		}
		if (Object.values(validateResult).some((item) => item !== undefined)) {
			console.log('has error fields')
			return
		}
		const serverEditFunc = async () => {
			const res = await serverEdit()
			if (res === null) throw Error('Ошибка во время сохранения')
			if (form)
				form.setFields(
					form.getFieldsError().map((f) => ({
						...f,
						errors: [],
					}))
				)
			if (res?.id) {
				if (params.history && params.pageUrl) {
					goToItem(params.history, { url: params.pageUrl, id: res.id })
				}
			}
		}
		if (additionalFunc) {
			id = await additionalFunc(serverEditFunc)
		} else {
			id = await serverEditFunc()
		}
		return id
	} catch (err) {
		console.log(err.message)
		message.error('Были внесены изменения другим пользователем, введенные данные не сохранены', 5)
	}
}

export const actBtnOnSaveDraft = async (params) => {
	if (!params.validate || !params.serverEdit) throw Error('параметры переданы неверно')
	const {
		validate,
		serverEdit,
		isShowErrorModal,
		showConfirmModal,
		confirmModalWidth,
		additionalFunc,
		form,
		draft = false,
		confirmDraft,
	} = params

	const serverEditFunc = async () => {
		const res = await serverEdit()
		if (form)
			form.setFields(
				form.getFieldsError().map((f) => ({
					...f,
					errors: [],
				}))
			)
		if (res?.method === 'POST' && res.id) {
			if (params.history && params.pageUrl) {
				goToItem(params.history, { url: params.pageUrl, id: res.id })
			}
		}
	}

	try {
		if (draft) {
			const validateResult = await validate({ pre: true })
			const hasFormErrors = form.getFieldsError().some((field) => field.errors.length)
			if (validateResult.length || hasFormErrors) {
				const isDraft = await confirmDraft(validateResult)
				if (isDraft) {
					await serverEditFunc()
					return true
				}
			}
		}
		await form.validateFields()
		await validate({ pre: false })
		if (additionalFunc) {
			additionalFunc(serverEditFunc)
		} else {
			await serverEditFunc()
		}
		return true
	} catch (err) {
		if (isShowErrorModal && err.message) {
			showConfirmModal({
				title: err.message,
				onOk: () => {},
				showCancel: false,
				okText: 'Ок',
				width: confirmModalWidth,
			})
		}
	}
}

/**
 * делает базовые вещи при нажатии на кнопку закрыть
 * @param {Object} params
 * @param {Function} params.isEdited - проверка на наличие изменений в записи
 * @param {Function} params.showConfirmModal - функция показа модального окна
 * @param {Object} params.history - история
 * @param {string} params.pageUrl - урт на который будем возвращаться в случае успеха
 *  @param {string} params.modalTitle - заголовок модального окна с подтверждением
 */

export const actBtnOnClose = async (params) => {
	if (!params.isEdited || !params.showConfirmModal || !params.history || !params.pageUrl)
		throw Error('параметры передавны неверно')

	const {
		isEdited,
		showConfirmModal,
		history,
		pageUrl,
		modalTitle = 'Есть несохранённые изменения. Всё равно выйти?',
		isCancelBtnPrimary = false,
	} = params

	if (isEdited()) {
		showConfirmModal({
			title: modalTitle,
			onOk: params.onClose ? params.onClose : () => history.replace(pageUrl),
			isCancelBtnPrimary,
		})
	} else {
		history.replace(pageUrl)
	}
}

/**
 * делает базовые вещи при нажатии на кнопку удалить
 * @param {Object} params
 * @param {Function} params.deleteFun - функцуия удаления
 * @param {Function} params.showConfirmModal - функция показа модального окна
 * @param {Object} params.history - история
 * @param {string} params.pageUrl - урт на который будем возвращаться в случае успеха
 *  @param {string} params.modalTitle - заголовок модального окна с подтверждением
 */

export const actBtnOnDelete = async (params) => {
	if (!params.deleteFun || !params.showConfirmModal || !params.pageUrl)
		throw Error('параметры передавны неверно')

	const {
		deleteFun,
		showConfirmModal,
		history = null,
		pageUrl,
		deps,
		isCancelBtnPrimary = false,
		openNewPage = () => {},
		currentRights = {},
	} = params

	const isHasDeps = () => {
		const clickHandler = (e) => {
			if (!checkRole(rights[e.link.substring(0, e.link.lastIndexOf('/'))], currentRights)) {
				return showConfirmModal({
					title: 'Ограничены права доступа к данному объекту. Обратитесь к администратору системы',
					okText: 'Ок',
					width: 450,
					showCancel: false,
				})
			}
			openNewPage(e.link)
		}
		if (deps?.length) {
			showConfirmModal({
				title: 'Для того, чтобы удалить данный элемент необходимо удалить все связи',
				onOk: () => {},
				width: '40%',
				okText: '',
				content: (
					<Space direction="vertical">
						{deps.slice(0, 10).map((dep) => {
							let title = dep.title
							if (dep.link.includes('/vocabularies')) {
								title = `Справочники/${dep.title}`
							}
							return (
								<Button key={title} type="link" onClick={() => clickHandler(dep)}>
									{title}
								</Button>
							)
						})}
						{deps.length > 10 && (
							<Typography.Text>...связей еще {deps.length - 10} шт</Typography.Text>
						)}
					</Space>
				),
				showCancel: false,
			})
			return true
		}
		return false
	}

	if (isHasDeps()) return
	showConfirmModal({
		title:
			params.title ||
			'При удалении этот элемент удалится навсегда. Вы действительно хотите удалить этот элемент?',
		width: params.width || '33%',
		okText: params.okText || 'Ок',
		cancelText: params.cancelText || 'Отмена',

		onOk: async () => {
			if (deps?.length) {
				showConfirmModal({
					title: 'Удалить нельзя, имеются зависимости',
					onOk: () => {},
					okText: '',
					showCancel: false,
				})
			} else {
				await deleteFun(params.delRecord)
				if (history) history.replace(pageUrl)
			}
			return
		},
		isCancelBtnPrimary,
	})
}

/**
 * делает базовые вещи при нажатии на кнопку редактировать
 * @param {Object} params
 * @param {Function} params.showConfirmModal - функция показа модального окна
 * @param {Object} params.history - история
 * @param {string} params.pageUrl - урт на который будем возвращаться в случае успеха
 *  @param {string} params.modalTitle - заголовок модального окна с подтверждением
 */

export const actBtnOnEdit = async (params) => {
	if (!params.showConfirmModal || !params.pageUrl) throw Error('параметры передавны неверно')

	const { showConfirmModal, deps, openNewPage = () => {}, currentRights = {} } = params

	const isHasDeps = () => {
		const clickHandler = (e) => {
			if (!checkRole(rights[e.link.substring(0, e.link.lastIndexOf('/'))], currentRights)) {
				return showConfirmModal({
					title: 'Ограничены права доступа к данному объекту. Обратитесь к администратору системы',
					okText: 'Ок',
					width: 450,
					showCancel: false,
				})
			}
			openNewPage(e.link)
		}
		if (deps?.length) {
			showConfirmModal({
				title: 'Для того, чтобы редактировать данный элемент необходимо удалить все связи',
				onOk: () => {},
				width: '40%',
				okText: '',
				content: (
					<Space direction="vertical">
						{deps.slice(0, 10).map((dep) => {
							let title = dep.title
							if (dep.link.includes('/vocabularies')) {
								title = `Справочники/${dep.title}`
							}
							return (
								<Button key={title} type="link" onClick={() => clickHandler(dep)}>
									{title}
								</Button>
							)
						})}
						{deps.length > 10 && (
							<Typography.Text>...связей еще {deps.length - 10} шт</Typography.Text>
						)}
					</Space>
				),
				showCancel: false,
			})
			return true
		}
		return false
	}

	if (isHasDeps()) return true
}
