module.exports = {
	voc_editable_container_type: { urls: ['/vocabularies/container-type'] },
	voc_editable_delivery_condition: { urls: ['/vocabularies/delivery-condition'] },
	voc_editable_outdoor_warehouse: { urls: ['/vocabularies/outdoor-warehouse'] },
	voc_editable_equipment: { urls: ['/vocabularies/equipment'] },
	voc_editable_manufactory_purpose: { urls: ['/vocabularies/manufactory-purpose'] },
	voc_editable_operation: { urls: ['/vocabularies/operation'] },
	voc_editable_pkg_type: { urls: ['/vocabularies/package-type'] },
	voc_editable_payment_condition: { urls: ['/vocabularies/payment-condition'] },
	voc_editable_cause_of_reject_rep: { urls: ['/vocabularies/cause-of-reject-rep'] },
	voc_editable_block_cause: { urls: ['/vocabularies/block-cause'] },
	voc_editable_position_type: { urls: ['/vocabularies/position-type'] },
	voc_editable_product: { urls: ['/vocabularies/product'] },
	voc_editable_production_area: { urls: ['/vocabularies/production-area'] },
	voc_editable_quality: { urls: ['/vocabularies/quality'] },
	voc_editable_raw_mat_manuf: { urls: ['/vocabularies/raw-material-manufacturer'] },
	voc_editable_raw_mat_country: { urls: ['/vocabularies/raw-material-country'] },
	voc_editable_warehouse_type: { urls: ['/vocabularies/warehouse-type'] },
	voc_editable_timely_export: { urls: ['/vocabularies/timely-export'] },
	voc_editable_additional_semi_type: { urls: ['/vocabularies/additional-semi-type'] },
	voc_editable_main_semi_type: { urls: ['/vocabularies/main-semi-type'] },
	voc_editable_raw_mat_state: { urls: ['/vocabularies/raw-mat-state'] },
	voc_editable_ready_product_form: { urls: ['/vocabularies/ready-product-form'] },
	voc_editable_temperature_regime: { urls: ['/vocabularies/temperature-regime'] },
	voc_editable_waste_type: { urls: ['/vocabularies/waste-type'] },
	voc_editable_product_1c: { urls: ['/vocabularies/product-1c'] },
	voc_static_facility_type: { urls: ['/vocabularies/facility-type'] },
	voc_static_matching_status: { urls: ['/vocabularies/matching-status'] },
	voc_static_product_type: { urls: ['/vocabularies/product-type'] },
	voc_static_staff_status: { urls: ['/vocabularies/staff-status'] },
	voc_static_supply_status: { urls: ['/vocabularies/supply-status'] },
	voc_static_type_reas_brk: { urls: ['/vocabularies/type-reason-break'] },
	voc_common_measure: { urls: ['/vocabularies/common/measure'] },

	rp_customer: { urls: ['/rp/customers'] },

	rm_provider: { urls: ['/rm/providers'] },
	rm_provider_prod: { urls: ['/rm/providers'] },

	rm_rp_contact: { urls: ['/rp/customers', '/rm/providers'] },
	rp_rm_platform: { urls: ['/rp/customers', '/rm/providers'] },

	reg_specification: { urls: ['/reg/specifications'] },
	param_photo: { urls: ['/reg/specifications'] },
	spec_param: { urls: ['/reg/specifications'] },
	spec_comment: { urls: ['/reg/specifications'] },

	rp_rw_contract: { urls: ['/rp/contracts-customers', '/rp/contracts-providers'] },
	contr_customer_quota: { urls: ['/rp/contracts-customers'] },
	contr_provider_quota: { urls: ['/rm/contracts-providers'] },
	cstmr_quota_mnth_lstng: { urls: ['/rp/contracts-customers'] },
	prov_quota_mnth_lstng: { urls: ['/rp/contracts-providers'] },

	rp_order: { urls: ['/rp/orders'] },
	rp_order_product: { urls: ['/rp/orders'] },
}
