import React, { useContext } from 'react'

import { Main } from '../../../../layouts'
import { Provider, MainSemiKindListContext } from './provider/main'
import { HeaderMenu, StatefulTable } from '../../../../components'
import { getColumns } from './main-semi-kind-list'
import { Card } from 'antd'
import { getDataByPage, getPageLength, goToItem, prepareData } from '../../../../utils'
import { useHistory } from 'react-router'
import { ListContext } from '../../../../contexts'

const pageUrl = '/vocabularies/main-semifinished-kind'

const MainSemiKindList = () => {
	return (
		<Provider>
			<Main title={'Виды основных ПФ'}>
				<MainSemiKindListTable />
			</Main>
		</Provider>
	)
}

const MainSemiKindListTable = () => {
	const mainCtx = useContext(MainSemiKindListContext)
	const {
		state: { page: pageNum },
	} = useContext(ListContext)
	const history = useHistory()

	const pageData = getDataByPage(mainCtx.toDisplayDataList(), pageNum)
	const preparedDataForPage = prepareData(pageData, 'kinds', 'kind')

	const realTotalPages = getPageLength(mainCtx.toDisplayDataList())
	let total = preparedDataForPage.length * realTotalPages
	return (
		<>
			<HeaderMenu
				rights={{
					Справочники: {
						'Виды основных ПФ': {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle={'Виды основных ПФ'}
				canCreate={true}
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					rowKey={(rec) => `${rec.id}_${rec.indxMain}`}
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					dataSource={preparedDataForPage}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					url={pageUrl}
					pagination={{
						total: total,
						pageSize: preparedDataForPage.length,
						current: pageNum,
						showSizeChanger: false,
					}}
				/>
			</Card>
		</>
	)
}
export default MainSemiKindList
