import React, { useEffect, useState } from 'react'
import { Form as FormAntd, Input, Checkbox } from 'antd'

const formItemLayout = {
	labelCol: { span: 15 },
	wrapperCol: { span: 9 },
}

const Form = (props) => {
	const { data, recordId, form, action } = props
	const [inputDisabled, setInputDisabled] = useState(!form.getFieldValue('isReadyProd'))

	useEffect(() => {
		form.resetFields()
	}, [form])

	return (
		<FormAntd
			form={form}
			labelAlign="left"
			validateMessages={{ required: 'Поле обязательно для заполнения!' }}
			initialValues={data.find((el) => String(el.id) === String(recordId)) || {}}
			onFinish={action}
			onFieldsChange={() => setInputDisabled(!form.getFieldValue('isReadyProd'))}
		>
			<FormAntd.Item
				{...formItemLayout}
				label="Наименование"
				name="label"
				rules={[
					{
						required: true,
					},
					{
						validator: async (_, value) => {
							if (value) {
								const hasDuplicate = data.find(
									(voc) => voc.label?.toLowerCase() === value.toLowerCase() && voc.id !== recordId
								)
								if (hasDuplicate) {
									throw Error('Тара с таким наименованием уже создана')
								}
							}
						},
					},
				]}
			>
				<Input />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Краткое наименование"
				name="labelShort"
				rules={[
					{
						required: true,
					},
					{ max: 12, message: 'Введите не более 12 символов' },
					{
						validator: async (_, value) => {
							if (value) {
								const hasDuplicate = data.find(
									(voc) =>
										voc.labelShort?.toLowerCase() === value.toLowerCase() && voc.id !== recordId
								)
								if (hasDuplicate) {
									throw Error('Тара с таким кратким наименованием уже создана')
								}
							}
						},
					},
				]}
			>
				<Input />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="ГП"
				name="isReadyProd"
				valuePropName="checked"
				onChange={(e) => {
					if (!e.target.checked) {
						form.setFieldsValue({
							displayCode: null,
						})
					}
				}}
			>
				<Checkbox />
			</FormAntd.Item>
			<FormAntd.Item {...formItemLayout} label="Сырье" name="isRawMat" valuePropName="checked">
				<Checkbox />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Артикул"
				name="displayCode"
				dependencies={['isReadyProd']}
				rules={[
					({ getFieldValue }) => ({
						required: getFieldValue('isReadyProd'),
					}),
					{ max: 5, message: 'Введите не более 5 символов' },
					({ getFieldValue, getFieldsValue }) => ({
						validator: async (_, value) => {
							if (value) {
								const regExp = new RegExp('^[a-zA-Z]+$')
								if (!regExp.test(value)) throw Error('Только латинские буквы')
								const hasDuplicate = data.find(
									(voc) =>
										voc.displayCode?.toLowerCase() === value.toLowerCase() && voc.id !== recordId
								)
								if (hasDuplicate) throw Error('Тара с таким артикулом уже создана')
								return Promise.resolve()
							}
						},
					}),
				]}
			>
				<Input disabled={inputDisabled} />
			</FormAntd.Item>
			<FormAntd.Item {...formItemLayout} label="Вес тары, кг" name="weight">
				<Input />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="1 паллета, шт"
				name="onePalleta"
				rules={[
					{
						validator: async (_, value) => {
							if (value) {
								if (!/^-?\d+$/.test(value)) {
									throw Error('Введите целое число')
								}
								if (+value <= 0) {
									throw Error('Количество должно быть больше 0')
								}
							}
						},
					},
				]}
			>
				<Input />
			</FormAntd.Item>
		</FormAntd>
	)
}

export default Form
