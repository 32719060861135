import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import moment from 'moment'
import { AxiosContext, ModContext } from '../../../../../contexts'
import {
	getCommonProviderFunctions,
	getCommonProviderModalFunctions,
} from '../../../../../utils/helpers/generators'
import {
	prepareObjFromServer,
	modes,
	getNewObj,
	useItemFetchers,
	goToItem,
	removeByUuid,
} from '../../../../../utils'
import { basicValidator } from '@berry/common-functions/validators'
import { useHistory } from 'react-router-dom'
export const dataUrl = '/stock/operations/reloc-remote-warehouses'
const frontUrl = '/stock-operations/reloc-remote-warehouse'
const tabs = {
	productions: 'productions',
	'event-histories': 'eventHistories',
}

export const reducer = (state) => {
	return {
		...state,
	}
}

const initData = {
	date: moment(),
	status: 'Новое',
	productions: [],
	eventHistories: [],
}

const initialState = {
	data: initData,
	oldData: initData,
	formErrors: [],
	additional: {
		allVocRooms: [],
		allOutdoorWhs: [],
	},
	addProd: {
		__isOpen: false,
		__name: null,
		addProdStockRawMatStor: [],
		addProdStockReadyProdStor: [],
		addProdStockReadyProdResaleStor: [],
	},
	deletedProds: [],
}

const RelocRemoteWhItemMainContext = React.createContext()
RelocRemoteWhItemMainContext.displayName = 'RelocRemoteWhItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const { axios } = useContext(AxiosContext)
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))

	const requiredFields = {
		productions: ['room', 'weight'],
	}

	const isItemEdited = () => {
		const editedFields = getEditedData()
		return isEdited(editedFields)
	}

	const dropError = (err) => {
		let newErrors = lodash.cloneDeep(stateRef.current.formErrors || {})
		delete newErrors[err]
		executeDispatch({
			...stateRef.current,
			formErrors: newErrors,
		})
	}

	const setDeletedProds = (val) => {
		executeDispatch({
			...stateRef.current,
			deletedProds: val ? [...stateRef.current.deletedProds, val] : [],
		})
	}

	const validators = {}
	const getAvailableWeight = (product) => {
		if (!isNaN(product.availableWeight)) {
			return product.availableWeight || 0
		}
		let key = null
		if (product.stockRawMatStor) {
			key = 'stockRawMatStor'
		} else if (product.stockReadyProdStor) {
			key = 'stockReadyProdStor'
		} else if (product.stockReadyProdResaleStor) {
			key = 'stockReadyProdResaleStor'
		}
		const storage = product.storages.find((e) => product[key].idStorage === e.id)
		if (!storage) return
		return storage.status === 'Бронь' && storage.parent?.status === 'Допущено'
			? +storage.weight + (+storage.parent?.weight || 0)
			: +storage.weight
	}
	const checkAvailable = () => {
		let errors = []
		for (let p of stateRef.current.data.productions) {
			const availableWeight = getAvailableWeight(p)
			let formErrors = { ...stateRef.current.formErrors }
			if (basicValidator(p.weight) && availableWeight < +p.weight) {
				formErrors[`weight.${p._uuid_}`] = `Доступная масса = ${availableWeight} кг`
				errors.push(p)
			} else {
				formErrors[`weight.${p._uuid_}`] = false
			}
			executeDispatch({
				...stateRef.current,
				formErrors: {
					...stateRef.current.formErrors,
					...formErrors,
				},
			})
		}
		if (errors.length) {
			return false
		}
		return true
	}
	const validate = async ({ pre = false } = {}) => {
		let newFormErrors = {}
		const data = stateRef.current.data
		if (!data.productions.length) {
			return 'Не добавлена ни одна строка в табличную вкладку.'
		}
		if (!checkAvailable()) {
			return 'В продукции указана масса, превышающая доступную. Карточка не может быть сохранена'
		}
		data.productions.forEach((p) => {
			requiredFields.productions.forEach((f) => {
				if (!basicValidator(p[f]) || +p[f] === 0) {
					newFormErrors = { ...newFormErrors, [f + '.' + p._uuid_]: true }
				}
			})
		})
		if (Object.keys(newFormErrors).length) {
			if (!pre) {
				executeDispatch({ ...stateRef.current, formErrors: newFormErrors })
				throw Error()
			} else {
				return 'Не заполнены обязательные поля или не добавлена ни одна строка в табличной вкладке.При сохранении карточка будет сохранена со статусом "Черновик".При отмене продолжится редактирование карточки'
			}
		}
		return ''
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({ ...stateRef.current, data: recordFromDataSrvCtx })
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	/**
	 * подготавливает данные которые нужно выбират
	 */

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	const selectors = {
		productions: {
			room: stateRef.current.additional.allVocRooms,
		},
		shipmentWh: stateRef.current.additional.allOutdoorWhs,
		receiveWh: stateRef.current.additional.allOutdoorWhs,
	}

	const {
		commonFieldUpdate,
		getEditedData,
		isEdited,
		stateFunctions,
		serverDelete,
		serverEdit,
		setError,
		commonObjUpdate,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{
			modCtx,
			dataUrl,
			params,
			requiredFields,
			pageUrl: frontUrl,
			history,
		},
		selectors,
		{
			date: 'common',
			status: 'common',
			receiveWh: 'obj',
		},
		{
			productions: {
				weight: 'common',
				room: 'obj',
			},
		}
	)

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		selectors,
		[
			{ field: 'stockRawMatStor', updateVal: 'common', modalName: 'addProd' },
			{ field: 'stockReadyProdStor', updateVal: 'common', modalName: 'addProd' },
			{ field: 'stockReadyProdResaleStor', updateVal: 'common', modalName: 'addProd' },
			{ field: '__name', updateVal: 'common', modalName: 'addProd' },
		],
		[
			{
				mainField: 'stockRawMatStor',
				field: 'stockRawMatStor',
				updateVal: 'common',
				modalName: 'addProd',
			},

			{
				mainField: 'stockReadyProdStor',
				field: 'stockReadyProdStor',
				updateVal: 'common',
				modalName: 'addProd',
			},
			{
				mainField: 'stockReadyProdResaleStor',
				field: 'stockReadyProdResaleStor',
				updateVal: 'common',
				modalName: 'addProd',
			},
		]
	)
	stateFunctions.setShipmentWh = (val) => {
		commonObjUpdate('shipmentWh', val)
		commonFieldUpdate('productions', [])
	}
	stateFunctions.setDate = (m) => {
		commonFieldUpdate('date', moment(m).format('YYYY-MM-DD'))
	}
	stateFunctions.productions.create = (inData, field) => {
		if (stateRef.current.data.toOrFromOutdoorWarehouse === 'На внешний склад') {
			const outdoorRooms = selectors.productions.room.filter((r) => r.roomType === 'Внешний')
			if (outdoorRooms.length === 1) {
				inData = inData.map((data) => {
					return { ...data, room: outdoorRooms[0] }
				})
			}
		} else {
			const internalRooms = selectors.productions.room.filter((r) => r.roomType === 'Внутренний')
			if (internalRooms.length === 1) {
				inData = inData.map((data) => {
					return { ...data, room: internalRooms[0] }
				})
			}
		}
		let newArr = [
			...lodash.cloneDeep(stateRef.current.data.productions),
			...inData.map((el) => getNewObj({ ...el, [field]: el, weight: null, id: null })),
		]
		commonFieldUpdate('productions', newArr)
	}
	stateFunctions.productions.delete = (inUuid) => {
		const newProducts = removeByUuid(inUuid, stateRef.current.data.productions)
		commonFieldUpdate('productions', newProducts)
	}
	const getDepsOn = () => []

	const depsFunctions = {
		productions: (inId) => [],
	}
	const serverSendTo1c = async () => {
		let body = { id: stateRef.current.data.id }
		const productionsArticuls = stateRef.current.data.productions.map((e) => {
			return {
				id: e.id,
				articul: e.articul,
			}
		})
		body.productionsArticuls = productionsArticuls
		const { data } = (await axios.put(`${dataUrl}/send-to-1c`, body)) || {}
		if (data?.data?.id) {
			goToItem(history, { id: data.data.id, url: frontUrl }, { refresh: true })
		}
	}

	const value = {
		commonFieldUpdate,
		state: lodash.cloneDeep(stateRef.current.data),
		additional: stateRef.current.additional,
		stateFunctions,
		serverEdit,
		serverDelete,
		isEdited: isItemEdited,
		validate,
		reset,
		validators: validators,
		formErrors: stateRef.current.formErrors,
		selectors,
		getDepsOn,
		depsFunctions,
		setError,
		modalFunctions,
		addProd: lodash.cloneDeep(stateRef.current.addProd),
		deletedProds: lodash.cloneDeep(stateRef.current.deletedProds),
		serverSendTo1c,
		dropError,
		checkAvailable,
		getAvailableWeight,
		setDeletedProds,
	}

	return (
		<RelocRemoteWhItemMainContext.Provider value={value}>
			{children}
		</RelocRemoteWhItemMainContext.Provider>
	)
}

export { Provider, RelocRemoteWhItemMainContext }
