import { Checkbox } from 'antd'
import moment from 'moment'
import { dateFormat } from '../../../../utils/constants/common'
import { numToFixed } from '@berry/common-functions/cross-project-functions'

const layout1Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 11, offset: 0 },
	},
	wrapperCol: { span: 13 },
}
const layout2Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 10, offset: 0 },
	},
	wrapperCol: { span: 15 },
}
const layout3Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 11, offset: 0 },
	},
	wrapperCol: { span: 13 },
}

export const getFormItemsMain = (mainCtx) => {
	const weightAccepted = numToFixed(
		mainCtx.state.storages?.reduce(
			(sum, cur) => (cur.status === 'Допущено' ? sum + +cur.weight : sum),
			0
		),
		2
	)
	return [
		[
			{
				layout: layout1Col,
				label: 'Продукт',
				name: 'prodCat',
				field: mainCtx.state.taskRep?.task?.prodCat?.label,
			},
			{
				layout: layout1Col,
				label: 'Вид',
				name: 'prodCatKind',
				field: mainCtx.state.prodCatKind,
			},

			{
				layout: layout1Col,
				label: 'Артикул',
				name: 'articul',
				field: mainCtx.state.articul,
			},
			{
				layout: layout1Col,
				label: 'Артикул 1С',
				name: 'articul1C',
				field: mainCtx.state.articul1C,
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Партия №',
				name: 'partyNum',
				field: mainCtx.state.taskRep?.partyNum,
			},

			{
				layout: layout2Col,
				label: 'Спецификация',
				name: 'spec',
				field: mainCtx.state.taskRep?.task?.spec?.label,
			},
			{
				layout: layout2Col,
				label: 'Поставщик сырья',
				name: 'provider',
				field: mainCtx.state.provider,
			},
			{
				layout: layout2Col,
				label: 'Производитель сырья',
				name: 'manufacturer',
				field: mainCtx.state.ctrlRawMatManuf,
			},
			...(mainCtx.state.isCreatedFromBal
				? [
						{
							layout: layout2Col,
							label: 'Договор',
							name: 'contractNum',
							field: mainCtx.state.taskRep?.task?.contrOrder?.contract?.num,
						},
				  ]
				: []),
		],
		[
			{
				layout: layout3Col,
				label: 'Масса, кг допущено',
				name: 'weightAccepted',

				field: weightAccepted,
			},
			{
				layout: layout3Col,
				label: 'Дата производства',
				name: 'prodDate',
				field: mainCtx.state.taskRep?.task?.date
					? moment(mainCtx.state.taskRep?.task?.date).format(dateFormat)
					: null,
			},
			{
				layout: layout3Col,
				label: 'Годен до',
				name: 'shelfLifeDate',
				field: mainCtx.state.shelfLifeDate
					? moment(mainCtx.state.shelfLifeDate).format(dateFormat)
					: null,
			},
			...(mainCtx.state.isCreatedFromBal
				? [
						{
							layout: layout3Col,
							label: 'Поставщик',
							name: 'provider',
							field: mainCtx.state.taskRep?.task?.contrOrder?.contract?.provider?.label,
						},
				  ]
				: [
						{
							layout: layout3Col,
							label: 'ID ДЗ',
							name: 'idOrder',
							field: mainCtx.additional.firstStockRawMatComplectData?.idContrOrder,
						},
				  ]),

			...(!['Утверждена', 'Принято в 1С'].includes(mainCtx.state.taskRep?.task.status)
				? [
						{
							layout: layout3Col,
							label: 'Не зарегистрирован в 1С',
							name: 'isNotRegIn1C',
							field: <Checkbox disabled checked />,
						},
				  ]
				: []),
		],
	]
}
