import React from 'react'
import { Redirect } from 'react-router-dom'
import { AuthContext } from '../../../contexts/auth/auth'
import { LoadingPage } from '../../../pages'

const IsLoggedIn = ({ children, ...props }) => {
	const {
		state: { isAuthenticated, isLoaded, isLoading },
	} = React.useContext(AuthContext)

	if (!isLoaded || isLoading) return <LoadingPage />
	if (!isAuthenticated) {
		return <Redirect push to="/login" />
	}

	return React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, { ...props })
		}
		return child
	})
}

export default IsLoggedIn
