import { Table } from 'antd'
import React, { useContext, useState } from 'react'
import { RecordItemModal, TabItem, CustomForm } from '../../../../components'
import { AuthContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns, getFormItems } from './quality-tab'
import { ProductCatalogItemMainContext } from '../provider/main'
import lodash from 'lodash'
import { prepareForOpenedModal } from '../../../../utils/helpers/for-modal'

export const QualityTab = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(ProductCatalogItemMainContext)
	const authCtx = useContext(AuthContext)
	const stateFunctions = mainCtx.stateFunctions.qualities
	let stateData = mainCtx.state.qualities || []

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [itemForModal, setItemForModal] = useState(null)
	stateData = prepareForOpenedModal(stateData, itemForModal)
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const newUuid = stateFunctions.create()
					setItemForModal({ _uuid_: newUuid, isNew: true })
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						isOpen={isModalOpen}
						title="Качество"
						item={itemForModal}
						deleteFun={stateFunctions.delete}
						setItem={setItemForModal}
						setModalState={setIsModalOpen}
						editFun={stateFunctions.edit}
						validate={() => mainCtx.validators.quality(itemForModal._uuid_)}
						formName="quality"
						form={() =>
							!itemForModal ? null : (
								<CustomForm
									name="quality"
									fields={[{ name: 'label', value: stateFunctions.get(itemForModal._uuid_).label }]}
									columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
									items={getFormItems(mainCtx, modCtx, itemForModal)}
								/>
							)
						}
					/>
				)
			}}
			table={() => (
				<Table
					size="small"
					rowClassName={modCtx.mod !== modes.view && 'clickable'}
					columns={getDisplayColumns(
						mainCtx,
						modCtx,
						(record) => {
							if (modCtx.mod === modes.view) return
							setItemForModal({ _uuid_: record._uuid_, prevData: lodash.cloneDeep(record) })
							setIsModalOpen(true)
						},
						authCtx
					)}
					dataSource={stateData
						.slice()
						.sort((a, b) =>
							+a.displayCode > +b.displayCode ? 1 : +a.displayCode < +b.displayCode ? -1 : 0
						)}
					scroll={{ x: 800 }}
					pagination={false}
				/>
			)}
		/>
	)
}
