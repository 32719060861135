import { Modal, Row, Card, Button } from 'antd'
import { showSelectModalForList } from '../../utils/constants/for-components'
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg'

const SelectModal = (props) => {
	const { content, visible, onOk, onClose, title, additionalButtons = [] } = props
	return (
		<Modal
			bodyStyle={{ backgroundColor: '#f0f2f5' }}
			closable={false}
			visible={visible}
			{...showSelectModalForList}
		>
			<Card style={{ marginBottom: 10 }} bodyStyle={{ padding: '5px 20px' }}>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{!!additionalButtons.length && <Row style={{ fontWeight: 500 }}>{additionalButtons}</Row>}
					{!!title && <Row style={{ fontWeight: 500 }}>{title}</Row>}
					<Row>
						<Button type="primary" style={{ marginRight: 20 }} onClick={onOk}>
							Сохранить
						</Button>
						<Button
							type="text"
							style={{ lineHeight: 0.8 }}
							icon={<CloseIcon />}
							onClick={onClose}
						/>
					</Row>
				</Row>
			</Card>

			{content}
		</Modal>
	)
}

export default SelectModal
