module.exports = [
	{
		'Номер партии': '22ФР-8125',
		'Арт.1С': '3630',
		Арт: '3.00-Ru2-BB400',
		Наименование: 'Брусника быстрозамороженная - БигБег Россия "2 сорт" (3.630)',
		'кол-во': 7902,
		'№ пом.': null,
		Column7: ' Г/П',
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8240',
		'Арт.1С': '3630',
		Арт: '3.00-Ru2-BB400',
		Наименование: 'Брусника быстрозамороженная - БигБег Россия "2 сорт" (3.630)',
		'кол-во': 2941,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8090',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 750,
		'№ пом.': null,
		Column7: 1,
		Column8: 'Брусника',
	},
	{
		'Номер партии': '22ФР-8098',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 460,
		'№ пом.': null,
		Column7: 2,
		Column8: 'Клубника',
	},
	{
		'Номер партии': '22ФР-8192',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 7890,
		'№ пом.': null,
		Column7: 3,
		Column8: 'Малина',
	},
	{
		'Номер партии': '22ФР-8198',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 5420,
		'№ пом.': null,
		Column7: 4,
		Column8: 'Черника',
	},
	{
		'Номер партии': '22ФР-8227',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 8610,
		'№ пом.': null,
		Column7: 5,
		Column8: 'Баклажан',
	},
	{
		'Номер партии': '22ФР-8229',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 8180,
		'№ пом.': null,
		Column7: 6,
		Column8: 'Клюква',
	},
	{
		'Номер партии': '22ФР-8236',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 8520,
		'№ пом.': null,
		Column7: 7,
		Column8: 'Черная смородина',
	},
	{
		'Номер партии': '22ФР-8249',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 2860,
		'№ пом.': null,
		Column7: 8,
		Column8: 'Томат',
	},
	{
		'Номер партии': '22ФР-8250',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 3050,
		'№ пом.': null,
		Column7: 9,
		Column8: 'Яблоко ',
	},
	{
		'Номер партии': '22ФР-8251',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 700,
		'№ пом.': null,
		Column7: 10,
		Column8: 'Спаржа',
	},
	{
		'Номер партии': '22ФР-8252',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 7400,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8274',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 3380,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8276',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 100,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8285',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 2160,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8286',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 2480,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8288',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 510,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8289',
		'Арт.1С': '3611',
		Арт: '3.00-Ru-K10',
		Наименование: 'Брусника быстрозамороженная -короб 10кг Россия (3.611)',
		'кол-во': 1280,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8146',
		'Арт.1С': '3621',
		Арт: '3.00-Ru-M25',
		Наименование: 'Брусника быстрозамороженная -мешок 25кг Россия (3.621)',
		'кол-во': 1600,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8005',
		'Арт.1С': '3623',
		Арт: '3.00-Exp-M25',
		Наименование: 'Брусника быстрозамороженная -мешок 25кг Экспорт (3.623)',
		'кол-во': 7015,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-8298',
		'Арт.1С': '11649',
		Арт: '11.00.5-VBD-K10',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм короб 10кг ВБД (11.649)',
		'кол-во': 20,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8278',
		'Арт.1С': '11649',
		Арт: '11.00.5-VBD-K10',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм короб 10кг ВБД (11.649)',
		'кол-во': 4120,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8178',
		'Арт.1С': '11628',
		Арт: '11.00.5-VBD-M25',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм мешок 25кг ВБД (11.628)',
		'кол-во': 5,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8277',
		'Арт.1С': '11628',
		Арт: '11.00.5-VBD-M25',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм мешок 25кг ВБД (11.628)',
		'кол-во': 25,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8325',
		'Арт.1С': '11628',
		Арт: '11.00.5-VBD-M25',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм мешок 25кг ВБД (11.628)',
		'кол-во': 12700,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8334',
		'Арт.1С': '11628',
		Арт: '11.00.5-VBD-M25',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм мешок 25кг ВБД (11.628)',
		'кол-во': 23625,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8337',
		'Арт.1С': '11628',
		Арт: '11.00.5-VBD-M25',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм мешок 25кг ВБД (11.628)',
		'кол-во': 23650,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8323',
		'Арт.1С': '11640',
		Арт: '11.00-Ru-K10',
		Наименование: 'Клубника быстрозамороженная короб 10кг Россия (11.640)',
		'кол-во': 23740,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8330',
		'Арт.1С': '11620',
		Арт: '11.03-Ru-M25',
		Наименование: 'Клубника быстрозамороженная крошка мешок 25 кг Россия (11.620)',
		'кол-во': 1350,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8336',
		'Арт.1С': '11620',
		Арт: '11.03-Ru-M25',
		Наименование: 'Клубника быстрозамороженная крошка мешок 25 кг Россия (11.620)',
		'кол-во': 2275,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8339',
		'Арт.1С': '11620',
		Арт: '11.03-Ru-M25',
		Наименование: 'Клубника быстрозамороженная крошка мешок 25 кг Россия (11.620)',
		'кол-во': 1500,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8343',
		'Арт.1С': '11620',
		Арт: '11.03-Ru-M25',
		Наименование: 'Клубника быстрозамороженная крошка мешок 25 кг Россия (11.620)',
		'кол-во': 1900,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8345',
		'Арт.1С': '11620',
		Арт: '11.03-Ru-M25',
		Наименование: 'Клубника быстрозамороженная крошка мешок 25 кг Россия (11.620)',
		'кол-во': 1700,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8297',
		'Арт.1С': '11611',
		Арт: '11.01-D-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Данон (11.611)',
		'кол-во': 1410,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8315',
		'Арт.1С': '11611',
		Арт: '11.01-D-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Данон (11.611)',
		'кол-во': 1680,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8317',
		'Арт.1С': '11611',
		Арт: '11.01-D-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Данон (11.611)',
		'кол-во': 760,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8319',
		'Арт.1С': '11611',
		Арт: '11.01-D-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Данон (11.611)',
		'кол-во': 26100,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8320',
		'Арт.1С': '11611',
		Арт: '11.01-D-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Данон (11.611)',
		'кол-во': 26450,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8324',
		'Арт.1С': '11611',
		Арт: '11.01-D-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Данон (11.611)',
		'кол-во': 24730,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8326',
		'Арт.1С': '11611',
		Арт: '11.01-D-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Данон (11.611)',
		'кол-во': 24200,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8329',
		'Арт.1С': '11611',
		Арт: '11.01-D-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Данон (11.611)',
		'кол-во': 26080,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8342',
		'Арт.1С': '11611',
		Арт: '11.01-D-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Данон (11.611)',
		'кол-во': 25740,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8344',
		'Арт.1С': '11611',
		Арт: '11.01-D-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Данон (11.611)',
		'кол-во': 26510,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-8198',
		'Арт.1С': '11612',
		Арт: '11.01-Ru-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Россия (11.612)',
		'кол-во': 30,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8340',
		'Арт.1С': '2620',
		Арт: '2.00-Ru-M25',
		Наименование: 'Малина быстрозамороженная - мешок 25кг Россия (2.620)',
		'кол-во': 100,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8347',
		'Арт.1С': '2620',
		Арт: '2.00-Ru-M25',
		Наименование: 'Малина быстрозамороженная - мешок 25кг Россия (2.620)',
		'кол-во': 4650,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8348',
		'Арт.1С': '2620',
		Арт: '2.00-Ru-M25',
		Наименование: 'Малина быстрозамороженная - мешок 25кг Россия (2.620)',
		'кол-во': 2675,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8257',
		'Арт.1С': '2610',
		Арт: '2.03-D-K10',
		Наименование: 'Малина быстрозамороженная крошка - короб 10кг Данон (2.610)',
		'кол-во': 620,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8350',
		'Арт.1С': '2613',
		Арт: '2.00-Ru2-K8',
		Наименование: 'Малина быстрозамороженная целая - короб 8кг (2.613)',
		'кол-во': 304,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-8251',
		'Арт.1С': '1630',
		Арт: '1.00-Ru-BB400',
		Наименование: 'Черника быстрозамороженная БигБэг (1.630)',
		'кол-во': 464,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8064',
		'Арт.1С': '1630',
		Арт: '1.00-Ru-BB400',
		Наименование: 'Черника быстрозамороженная БигБэг (1.630)',
		'кол-во': 5571,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8135',
		'Арт.1С': '1630',
		Арт: '1.00-Ru-BB400',
		Наименование: 'Черника быстрозамороженная БигБэг (1.630)',
		'кол-во': 5830,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8179',
		'Арт.1С': '1630',
		Арт: '1.00-Ru-BB400',
		Наименование: 'Черника быстрозамороженная БигБэг (1.630)',
		'кол-во': 2260,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8181',
		'Арт.1С': '1630',
		Арт: '1.00-Ru-BB400',
		Наименование: 'Черника быстрозамороженная БигБэг (1.630)',
		'кол-во': 4485,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8099',
		'Арт.1С': '1613',
		Арт: '1.00-Ru2-K10',
		Наименование: 'Черника быстрозамороженная короб 10кг Россиия "2 сорт" (1.613)',
		'кол-во': 1420,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8118',
		'Арт.1С': '1611',
		Арт: '1.00-Ru-K10',
		Наименование: 'Черника быстрозамороженная короб 10кг Россия (1.611)',
		'кол-во': 1340,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8202',
		'Арт.1С': '1611',
		Арт: '1.00-Ru-K10',
		Наименование: 'Черника быстрозамороженная короб 10кг Россия (1.611)',
		'кол-во': 1060,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8213',
		'Арт.1С': '1611',
		Арт: '1.00-Ru-K10',
		Наименование: 'Черника быстрозамороженная короб 10кг Россия (1.611)',
		'кол-во': 1530,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8209',
		'Арт.1С': '1625',
		Арт: '1.00-VBD-M25',
		Наименование: 'Черника быстрозамороженная мешок 25кг ВБД (1.625)',
		'кол-во': 475,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8346',
		'Арт.1С': '1620',
		Арт: '1.00-D-M25',
		Наименование: 'Черника быстрозамороженная мешок 25кг Данон (1.620)',
		'кол-во': 5100,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8165',
		'Арт.1С': '1624',
		Арт: '1.00-Ru2-M25',
		Наименование: 'Черника быстрозамороженная мешок 25кг Россия "2 сорт" (1.624)',
		'кол-во': 5550,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8217',
		'Арт.1С': '1621',
		Арт: '1.00-Ru-M25',
		Наименование: 'Черника быстрозамороженная мешок 25кг Россия (1.621)',
		'кол-во': 6025,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8218',
		'Арт.1С': '1621',
		Арт: '1.00-Ru-M25',
		Наименование: 'Черника быстрозамороженная мешок 25кг Россия (1.621)',
		'кол-во': 3550,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8235',
		'Арт.1С': '1621',
		Арт: '1.00-Ru-M25',
		Наименование: 'Черника быстрозамороженная мешок 25кг Россия (1.621)',
		'кол-во': 650,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8238',
		'Арт.1С': '1621',
		Арт: '1.00-Ru-M25',
		Наименование: 'Черника быстрозамороженная мешок 25кг Россия (1.621)',
		'кол-во': 4440,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8243',
		'Арт.1С': '1621',
		Арт: '1.00-Ru-M25',
		Наименование: 'Черника быстрозамороженная мешок 25кг Россия (1.621)',
		'кол-во': 1325,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8244',
		'Арт.1С': '1621',
		Арт: '1.00-Ru-M25',
		Наименование: 'Черника быстрозамороженная мешок 25кг Россия (1.621)',
		'кол-во': 15,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8272',
		'Арт.1С': '1621',
		Арт: '1.00-Ru-M25',
		Наименование: 'Черника быстрозамороженная мешок 25кг Россия (1.621)',
		'кол-во': 3375,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8314',
		'Арт.1С': '1621',
		Арт: '1.00-Ru-M25',
		Наименование: 'Черника быстрозамороженная мешок 25кг Россия (1.621)',
		'кол-во': 525,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8294',
		'Арт.1С': '11620',
		Арт: '11.03-Ru-M25',
		Наименование: 'Клубника быстрозамороженная крошка мешок 25 кг Россия (11.620)',
		'кол-во': 200,
		'№ пом.': 'С этой строки ЦЕХ Г/П ',
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8293',
		'Арт.1С': '11611',
		Арт: '11.01-D-K10',
		Наименование: 'Клубника быстрозамороженная кубик 10х10 короб 10кг Данон (11.611)',
		'кол-во': 3200,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3095',
		'Арт.1С': '51.41',
		Арт: '25.210',
		Наименование: 'Баклажан быстрозамороженный кубик 10х10 (51.41)',
		'кол-во': 518,
		'№ пом.': 'С этой строки ЦЕХ (полуфабрикаты)',
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3165',
		'Арт.1С': '391',
		Арт: '3.В51',
		Наименование: 'Брусника - отход возвратный от 1й оптической сортировки (с 01.05.19)',
		'кол-во': 123,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3226',
		'Арт.1С': '391',
		Арт: '3.В51',
		Наименование: 'Брусника - отход возвратный от 1й оптической сортировки (с 01.05.19)',
		'кол-во': 548,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3251',
		'Арт.1С': '392',
		Арт: '3.В52',
		Наименование: 'Брусника - отход возвратный от 2й и более оптической сортировки (с 01.05.19)',
		'кол-во': 337,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3165',
		'Арт.1С': '393',
		Арт: '3.В41',
		Наименование:
			'Брусника - отход возвратный после калибровки на стадии механической очистки (3.93)',
		'кол-во': 60,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3192',
		'Арт.1С': '393',
		Арт: '3.В41',
		Наименование:
			'Брусника - отход возвратный после калибровки на стадии механической очистки (3.93)',
		'кол-во': 54,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3201',
		'Арт.1С': '393',
		Арт: '3.В41',
		Наименование:
			'Брусника - отход возвратный после калибровки на стадии механической очистки (3.93)',
		'кол-во': 189,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3213',
		'Арт.1С': '393',
		Арт: '3.В41',
		Наименование:
			'Брусника - отход возвратный после калибровки на стадии механической очистки (3.93)',
		'кол-во': 98,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3172',
		'Арт.1С': '394',
		Арт: '3.417',
		Наименование:
			'Брусника - отход не возвратный после сепаратора плодоножки и полировки на стадии мех очистки (3.94)',
		'кол-во': 5227,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3177',
		'Арт.1С': '394',
		Арт: '3.417',
		Наименование:
			'Брусника - отход не возвратный после сепаратора плодоножки и полировки на стадии мех очистки (3.94)',
		'кол-во': 542,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3215',
		'Арт.1С': '394',
		Арт: '3.417',
		Наименование:
			'Брусника - отход не возвратный после сепаратора плодоножки и полировки на стадии мех очистки (3.94)',
		'кол-во': 198,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3226',
		'Арт.1С': '394',
		Арт: '3.417',
		Наименование:
			'Брусника - отход не возвратный после сепаратора плодоножки и полировки на стадии мех очистки (3.94)',
		'кол-во': 178,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3251',
		'Арт.1С': '394',
		Арт: '3.417',
		Наименование:
			'Брусника - отход не возвратный после сепаратора плодоножки и полировки на стадии мех очистки (3.94)',
		'кол-во': 127,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3165',
		'Арт.1С': '39251',
		Арт: '3.В52.51',
		Наименование: 'Брусника - Сортекс 1 отходов Сортекс - 1 раз (3.9251)',
		'кол-во': 4581,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3192',
		'Арт.1С': '39151',
		Арт: '3.В51.51',
		Наименование: 'Брусника - Сортекс отходов Сортекс - 1 раз (3.9151)',
		'кол-во': 680,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3165',
		'Арт.1С': '341',
		Арт: '3.410',
		Наименование: 'Брусника быстрозаморожденная мехочистка 1 раз (3.41)',
		'кол-во': 1153,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3204',
		'Арт.1С': '352',
		Арт: '3.520',
		Наименование: 'Брусника Сортекс (после мехочистки) 2 раза (3.52)',
		'кол-во': 3104,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3165',
		'Арт.1С': '352',
		Арт: '3.520',
		Наименование: 'Брусника Сортекс (после мехочистки) 2 раза (3.52)',
		'кол-во': 688,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3165',
		'Арт.1С': '39191',
		Арт: '3.518',
		Наименование: 'Брусника-Отходы от отходов сортекс (3.9191)',
		'кол-во': 1329,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3021',
		'Арт.1С': '1194',
		Арт: '11.В72',
		Наименование: 'Клубника б/зам - отход не возвратный - от визуальной инспекции (11.94)',
		'кол-во': 737,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3030',
		'Арт.1С': '1194',
		Арт: '11.В72',
		Наименование: 'Клубника б/зам - отход не возвратный - от визуальной инспекции (11.94)',
		'кол-во': 262,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3034',
		'Арт.1С': '1194',
		Арт: '11.В72',
		Наименование: 'Клубника б/зам - отход не возвратный - от визуальной инспекции (11.94)',
		'кол-во': 535,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3039',
		'Арт.1С': '1194',
		Арт: '11.В72',
		Наименование: 'Клубника б/зам - отход не возвратный - от визуальной инспекции (11.94)',
		'кол-во': 364,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3074',
		'Арт.1С': '1194',
		Арт: '11.В72',
		Наименование: 'Клубника б/зам - отход не возвратный - от визуальной инспекции (11.94)',
		'кол-во': 250,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3072',
		'Арт.1С': '1198',
		Арт: '11.В61',
		Наименование:
			'Клубника б/зам - отход не возвратный - от визуальной инспекции при упаковке (11.98)',
		'кол-во': 151,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3074',
		'Арт.1С': '1198',
		Арт: '11.В61',
		Наименование:
			'Клубника б/зам - отход не возвратный - от визуальной инспекции при упаковке (11.98)',
		'кол-во': 95,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3122',
		'Арт.1С': '1141',
		Арт: '11.210',
		Наименование:
			'Клубника быстрозамороженная 10х10 п/ф -мойка, визуал инспекция, шоковая доморозка (11.41)',
		'кол-во': 1918,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3131',
		'Арт.1С': '1141',
		Арт: '11.210',
		Наименование:
			'Клубника быстрозамороженная 10х10 п/ф -мойка, визуал инспекция, шоковая доморозка (11.41)',
		'кол-во': 1474,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3015',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 330,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3016',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 312,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3017',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 344,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3024',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 18211,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3028',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 221,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3038',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 18295,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3045',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 17941,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3046',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 13513,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3047',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 19080,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3051',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 20676,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3058',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 22083,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3059',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 8299,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3060',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 16132,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3061',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 13698,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3062',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 2400,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3063',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 683,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3064',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 2294,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3067',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 735,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3069',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 17094,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3070',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 4589,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3071',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 17766,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3072',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 13286,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3074',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 10898,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3075',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 21477,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3076',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 17814,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3077',
		'Арт.1С': '1146',
		Арт: '11.112',
		Наименование: 'Клубника быстрозамороженная калиброванная > 25 мм (11.46)',
		'кол-во': 21676,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3006',
		'Арт.1С': '1148',
		Арт: '11.114',
		Наименование: 'Клубника быстрозамороженная калиброванная > 35 мм (11.48)',
		'кол-во': 320,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3023',
		'Арт.1С': '1148',
		Арт: '11.114',
		Наименование: 'Клубника быстрозамороженная калиброванная > 35 мм (11.48)',
		'кол-во': 110,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3030',
		'Арт.1С': '1148',
		Арт: '11.114',
		Наименование: 'Клубника быстрозамороженная калиброванная > 35 мм (11.48)',
		'кол-во': 190,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3036',
		'Арт.1С': '1148',
		Арт: '11.114',
		Наименование: 'Клубника быстрозамороженная калиброванная > 35 мм (11.48)',
		'кол-во': 7201,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3037',
		'Арт.1С': '1148',
		Арт: '11.114',
		Наименование: 'Клубника быстрозамороженная калиброванная > 35 мм (11.48)',
		'кол-во': 7424,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3043',
		'Арт.1С': '1148',
		Арт: '11.114',
		Наименование: 'Клубника быстрозамороженная калиброванная > 35 мм (11.48)',
		'кол-во': 4170,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3044',
		'Арт.1С': '1148',
		Арт: '11.114',
		Наименование: 'Клубника быстрозамороженная калиброванная > 35 мм (11.48)',
		'кол-во': 6338,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3015',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 940,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3017',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 434,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3018',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 1251,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3020',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 271,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3024',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 923,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3028',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 1226,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3029',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 97,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3038',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 1439,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3045',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 3364,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3046',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 2537,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3047',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 3071,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3051',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 3683,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3058',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 4361,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3059',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 2373,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3060',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 3732,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3061',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 4014,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3062',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 2973,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3063',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 4135,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3064',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 2849,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3065',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 3570,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3066',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 2889,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3067',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 2772,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3069',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 2328,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3070',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 2502,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3071',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 2071,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3072',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 3021,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3073',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 2211,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3074',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 3629,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3076',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 3044,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3077',
		'Арт.1С': '1145',
		Арт: '11.111',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-25 мм (11.45)',
		'кол-во': 3069,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3005',
		'Арт.1С': '1149',
		Арт: '11.115',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм (11.49)',
		'кол-во': 801,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3026',
		'Арт.1С': '1149',
		Арт: '11.115',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм (11.49)',
		'кол-во': 10226,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3027',
		'Арт.1С': '1149',
		Арт: '11.115',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм (11.49)',
		'кол-во': 10669,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3029',
		'Арт.1С': '1149',
		Арт: '11.115',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм (11.49)',
		'кол-во': 1237,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3030',
		'Арт.1С': '1149',
		Арт: '11.115',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм (11.49)',
		'кол-во': 4063,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3036',
		'Арт.1С': '1149',
		Арт: '11.115',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм (11.49)',
		'кол-во': 17673,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3037',
		'Арт.1С': '1149',
		Арт: '11.115',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм (11.49)',
		'кол-во': 17481,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3043',
		'Арт.1С': '1149',
		Арт: '11.115',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм (11.49)',
		'кол-во': 20566,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3044',
		'Арт.1С': '1149',
		Арт: '11.115',
		Наименование: 'Клубника быстрозамороженная калиброванная 15-35 мм (11.49)',
		'кол-во': 18585,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3015',
		'Арт.1С': '1142',
		Арт: '11.211',
		Наименование: 'Клубника быстрозамороженная крошка п/ф (11.42)',
		'кол-во': 70,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3019',
		'Арт.1С': '1142',
		Арт: '11.211',
		Наименование: 'Клубника быстрозамороженная крошка п/ф (11.42)',
		'кол-во': 95,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3023',
		'Арт.1С': '1142',
		Арт: '11.211',
		Наименование: 'Клубника быстрозамороженная крошка п/ф (11.42)',
		'кол-во': 67,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3028',
		'Арт.1С': '1142',
		Арт: '11.211',
		Наименование: 'Клубника быстрозамороженная крошка п/ф (11.42)',
		'кол-во': 93,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3046',
		'Арт.1С': '1142',
		Арт: '11.211',
		Наименование: 'Клубника быстрозамороженная крошка п/ф (11.42)',
		'кол-во': 944,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3059',
		'Арт.1С': '1142',
		Арт: '11.211',
		Наименование: 'Клубника быстрозамороженная крошка п/ф (11.42)',
		'кол-во': 526,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3064',
		'Арт.1С': '1142',
		Арт: '11.211',
		Наименование: 'Клубника быстрозамороженная крошка п/ф (11.42)',
		'кол-во': 978,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3067',
		'Арт.1С': '1142',
		Арт: '11.211',
		Наименование: 'Клубника быстрозамороженная крошка п/ф (11.42)',
		'кол-во': 257,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3070',
		'Арт.1С': '1142',
		Арт: '11.211',
		Наименование: 'Клубника быстрозамороженная крошка п/ф (11.42)',
		'кол-во': 592,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3074',
		'Арт.1С': '1142',
		Арт: '11.211',
		Наименование: 'Клубника быстрозамороженная крошка п/ф (11.42)',
		'кол-во': 131,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3015',
		'Арт.1С': '1143',
		Арт: '11.212',
		Наименование: 'Клубника быстрозамороженная резаная п/ф (11.43)',
		'кол-во': 49,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3016',
		'Арт.1С': '1143',
		Арт: '11.212',
		Наименование: 'Клубника быстрозамороженная резаная п/ф (11.43)',
		'кол-во': 313,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3146',
		'Арт.1С': '591',
		Арт: '5.В51',
		Наименование: 'Клюква - отход возвратный от 1й оптической сортировки (с 01.05.19)',
		'кол-во': 560,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3210',
		'Арт.1С': '594',
		Арт: '5.417',
		Наименование:
			'Клюква - отход не возвратный после сепаратора плодоножки и полировки на стадии мехочистки (5.94)',
		'кол-во': 220,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3146',
		'Арт.1С': '552',
		Арт: '5.520',
		Наименование: 'Клюква Сортекс (после мехочистки) 2 раза (5.52)',
		'кол-во': 1189,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3078',
		'Арт.1С': '2931',
		Арт: '2.416',
		Наименование: 'Малина - Отход возвратный после воздушного сепаратора (мелкая фракция)',
		'кол-во': 139,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3079',
		'Арт.1С': '2931',
		Арт: '2.416',
		Наименование: 'Малина - Отход возвратный после воздушного сепаратора (мелкая фракция)',
		'кол-во': 166,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3199',
		'Арт.1С': '25341',
		Арт: '2.470',
		Наименование: 'Малина б/зам Доп мехочистка после 3-х Сортекс - 1раз (2.5341)',
		'кол-во': 536,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3188',
		'Арт.1С': '241',
		Арт: '2.410',
		Наименование: 'Малина быстрозамороженная мехочистка 1 раз (2.41)',
		'кол-во': 132,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3078',
		'Арт.1С': '241',
		Арт: '2.410',
		Наименование: 'Малина быстрозамороженная мехочистка 1 раз (2.41)',
		'кол-во': 4758,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3079',
		'Арт.1С': '241',
		Арт: '2.410',
		Наименование: 'Малина быстрозамороженная мехочистка 1 раз (2.41)',
		'кол-во': 5675,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3147',
		'Арт.1С': '252',
		Арт: '2.520',
		Наименование: 'Малина быстрозамороженная Сортекс (после мехочистки) 2 раза (2.52)',
		'кол-во': 1049,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3175',
		'Арт.1С': '252',
		Арт: '2.520',
		Наименование: 'Малина быстрозамороженная Сортекс (после мехочистки) 2 раза (2.52)',
		'кол-во': 1945,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3078',
		'Арт.1С': '2932',
		Арт: '2.В41',
		Наименование: 'Малина-отход возвратный после сепаратора плодоножки (ломаная - дробь, картечь)',
		'кол-во': 120,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3079',
		'Арт.1С': '2932',
		Арт: '2.В41',
		Наименование: 'Малина-отход возвратный после сепаратора плодоножки (ломаная - дробь, картечь)',
		'кол-во': 162,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3088',
		'Арт.1С': '79151',
		Арт: '7.В51.51',
		Наименование: 'Черная смородина - Сортекс отходов Сортекс - 1 раз (7.9151)',
		'кол-во': 259,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3078',
		'Арт.1С': '15241',
		Арт: '1.460',
		Наименование: 'Черника - доп мехочистка после 2-х Сортекс- 1 раз (1.5241)',
		'кол-во': 256,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3122',
		'Арт.1С': '15241',
		Арт: '1.460',
		Наименование: 'Черника - доп мехочистка после 2-х Сортекс- 1 раз (1.5241)',
		'кол-во': 180,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3277',
		'Арт.1С': '191',
		Арт: '1.В51',
		Наименование: 'Черника - отход возвратный от 1-ой оптической сортировки (1.91)',
		'кол-во': 536,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3067',
		'Арт.1С': '191',
		Арт: '1.В51',
		Наименование: 'Черника - отход возвратный от 1-ой оптической сортировки (1.91)',
		'кол-во': 734,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3130',
		'Арт.1С': '191',
		Арт: '1.В51',
		Наименование: 'Черника - отход возвратный от 1-ой оптической сортировки (1.91)',
		'кол-во': 523,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3252',
		'Арт.1С': '191',
		Арт: '1.В51',
		Наименование: 'Черника - отход возвратный от 1-ой оптической сортировки (1.91)',
		'кол-во': 186,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8131',
		'Арт.1С': '191',
		Арт: '1.В51',
		Наименование: 'Черника - отход возвратный от 1-ой оптической сортировки (1.91)',
		'кол-во': 508,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3122',
		'Арт.1С': '192',
		Арт: '1.В52',
		Наименование: 'Черника - отход возвратный от 2-й и более оптической сортировки (1.92)',
		'кол-во': 526,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3137',
		'Арт.1С': '192',
		Арт: '1.В52',
		Наименование: 'Черника - отход возвратный от 2-й и более оптической сортировки (1.92)',
		'кол-во': 332,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3138',
		'Арт.1С': '192',
		Арт: '1.В52',
		Наименование: 'Черника - отход возвратный от 2-й и более оптической сортировки (1.92)',
		'кол-во': 247,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3174',
		'Арт.1С': '192',
		Арт: '1.В52',
		Наименование: 'Черника - отход возвратный от 2-й и более оптической сортировки (1.92)',
		'кол-во': 734,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3205',
		'Арт.1С': '192',
		Арт: '1.В52',
		Наименование: 'Черника - отход возвратный от 2-й и более оптической сортировки (1.92)',
		'кол-во': 186,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3219',
		'Арт.1С': '192',
		Арт: '1.В52',
		Наименование: 'Черника - отход возвратный от 2-й и более оптической сортировки (1.92)',
		'кол-во': 388,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3081',
		'Арт.1С': '192',
		Арт: '1.В52',
		Наименование: 'Черника - отход возвратный от 2-й и более оптической сортировки (1.92)',
		'кол-во': 178,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3087',
		'Арт.1С': '192',
		Арт: '1.В52',
		Наименование: 'Черника - отход возвратный от 2-й и более оптической сортировки (1.92)',
		'кол-во': 280,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3252',
		'Арт.1С': '192',
		Арт: '1.В52',
		Наименование: 'Черника - отход возвратный от 2-й и более оптической сортировки (1.92)',
		'кол-во': 263,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8131',
		'Арт.1С': '192',
		Арт: '1.В52',
		Наименование: 'Черника - отход возвратный от 2-й и более оптической сортировки (1.92)',
		'кол-во': 695,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3061',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 179,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3068',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 748,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3071',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 157,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3092',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 149,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3096',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 99979,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3116',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 629,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3277',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 174,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3056',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 344,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3061',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 383,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3069',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 24,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3072',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 32,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3073',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 110,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3081',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 45,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3089',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 103,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3092',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 75,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3097',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 8,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3100',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 131,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3105',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 171,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3157',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 166,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3205',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 200,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3222',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 65,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3252',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 79,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3515',
		'Арт.1С': '193',
		Арт: '1.В41',
		Наименование: 'Черника - отход возвратный после калибровки на стадии мехочистки (1.93)',
		'кол-во': 92,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8131',
		'Арт.1С': '198',
		Арт: '1.В61',
		Наименование: 'Черника - отход невозвратный - от визуальной инспекции при упаковке (1.98)',
		'кол-во': 21,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3085',
		'Арт.1С': '194',
		Арт: '1.417',
		Наименование:
			'Черника - отход невозвратный после сепаратора плодоножки и полировки на стадии мехочистки (1.94)',
		'кол-во': 4060,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3095',
		'Арт.1С': '194',
		Арт: '1.417',
		Наименование:
			'Черника - отход невозвратный после сепаратора плодоножки и полировки на стадии мехочистки (1.94)',
		'кол-во': 1293,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3097',
		'Арт.1С': '194',
		Арт: '1.417',
		Наименование:
			'Черника - отход невозвратный после сепаратора плодоножки и полировки на стадии мехочистки (1.94)',
		'кол-во': 2684,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3105',
		'Арт.1С': '194',
		Арт: '1.417',
		Наименование:
			'Черника - отход невозвратный после сепаратора плодоножки и полировки на стадии мехочистки (1.94)',
		'кол-во': 1949,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8131',
		'Арт.1С': '194',
		Арт: '1.417',
		Наименование:
			'Черника - отход невозвратный после сепаратора плодоножки и полировки на стадии мехочистки (1.94)',
		'кол-во': 480,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8284',
		'Арт.1С': '194',
		Арт: '1.417',
		Наименование:
			'Черника - отход невозвратный после сепаратора плодоножки и полировки на стадии мехочистки (1.94)',
		'кол-во': 36,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3092',
		'Арт.1С': '19191',
		Арт: '1.518',
		Наименование: 'Черника - отходот отходов Сортекс (1.9191)',
		'кол-во': 264,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3097',
		'Арт.1С': '19191',
		Арт: '1.518',
		Наименование: 'Черника - отходот отходов Сортекс (1.9191)',
		'кол-во': 101,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3515',
		'Арт.1С': '19191',
		Арт: '1.518',
		Наименование: 'Черника - отходот отходов Сортекс (1.9191)',
		'кол-во': 169,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3081',
		'Арт.1С': '152',
		Арт: '1.520',
		Наименование: 'Черника - Сортекс (после мехочистки) 2 раза (1.52)',
		'кол-во': 92,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3092',
		'Арт.1С': '152',
		Арт: '1.520',
		Наименование: 'Черника - Сортекс (после мехочистки) 2 раза (1.52)',
		'кол-во': 881,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3094',
		'Арт.1С': '152',
		Арт: '1.520',
		Наименование: 'Черника - Сортекс (после мехочистки) 2 раза (1.52)',
		'кол-во': 298,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3110',
		'Арт.1С': '152',
		Арт: '1.520',
		Наименование: 'Черника - Сортекс (после мехочистки) 2 раза (1.52)',
		'кол-во': 392,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3060',
		'Арт.1С': '153',
		Арт: '1.530',
		Наименование: 'Черника - Сортекс (после мехочистки) 3 раза (1.53)',
		'кол-во': 513,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3160',
		'Арт.1С': '153',
		Арт: '1.530',
		Наименование: 'Черника - Сортекс (после мехочистки) 3 раза (1.53)',
		'кол-во': 1373,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3252',
		'Арт.1С': '153',
		Арт: '1.530',
		Наименование: 'Черника - Сортекс (после мехочистки) 3 раза (1.53)',
		'кол-во': 7028,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8131',
		'Арт.1С': '153',
		Арт: '1.530',
		Наименование: 'Черника - Сортекс (после мехочистки) 3 раза (1.53)',
		'кол-во': 13623,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3060',
		'Арт.1С': '19151',
		Арт: '1.В51.51',
		Наименование: 'Черника - Сортекс отходов Сортекс - 1 раз (1.9151)',
		'кол-во': 1934,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '21ФР-3069',
		'Арт.1С': '142',
		Арт: '1.420',
		Наименование: 'Черника мехочистка 2 раза (1.42)',
		'кол-во': 457,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8295',
		'Арт.1С': '53.623',
		Арт: '27.03-Ru-M25',
		Наименование: 'Томаты б/з крошка Мешок 25кг Россия (53.623)',
		'кол-во': 525,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8298',
		'Арт.1С': '53.623',
		Арт: '27.03-Ru-M25',
		Наименование: 'Томаты б/з крошка Мешок 25кг Россия (53.623)',
		'кол-во': 1550,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8301',
		'Арт.1С': '53.623',
		Арт: '27.03-Ru-M25',
		Наименование: 'Томаты б/з крошка Мешок 25кг Россия (53.623)',
		'кол-во': 2850,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8305',
		'Арт.1С': '53.623',
		Арт: '27.03-Ru-M25',
		Наименование: 'Томаты б/з крошка Мешок 25кг Россия (53.623)',
		'кол-во': 2025,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8308',
		'Арт.1С': '53.623',
		Арт: '27.03-Ru-M25',
		Наименование: 'Томаты б/з крошка Мешок 25кг Россия (53.623)',
		'кол-во': 1425,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8281',
		'Арт.1С': '53.622',
		Арт: '27.07-Ru-M25',
		Наименование: 'Томаты б/з кусочки Мешок 25кг Россия (53.622)',
		'кол-во': 3050,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8287',
		'Арт.1С': '53.622',
		Арт: '27.07-Ru-M25',
		Наименование: 'Томаты б/з кусочки Мешок 25кг Россия (53.622)',
		'кол-во': 2025,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8292',
		'Арт.1С': '53.622',
		Арт: '27.07-Ru-M25',
		Наименование: 'Томаты б/з кусочки Мешок 25кг Россия (53.622)',
		'кол-во': 1275,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8313',
		'Арт.1С': '53.622',
		Арт: '27.07-Ru-M25',
		Наименование: 'Томаты б/з кусочки Мешок 25кг Россия (53.622)',
		'кол-во': 525,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8048',
		'Арт.1С': '4610',
		Арт: '4.01-D-K9',
		Наименование: 'Яблоко быстрозамороженное -короб 9кг Данон (4610) ',
		'кол-во': 36,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8212',
		'Арт.1С': '4622',
		Арт: '4.01-Ru1-M25',
		Наименование: 'Яблоко быстрозамороженное -мешок 25кг Россия "1 сорт" (4622) ',
		'кол-во': 3375,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8215',
		'Арт.1С': '4622',
		Арт: '4.01-Ru1-M25',
		Наименование: 'Яблоко быстрозамороженное -мешок 25кг Россия "1 сорт" (4622) ',
		'кол-во': 3575,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8247',
		'Арт.1С': '4622',
		Арт: '4.01-Ru1-M25',
		Наименование: 'Яблоко быстрозамороженное -мешок 25кг Россия "1 сорт" (4622) ',
		'кол-во': 50,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8164',
		'Арт.1С': '4614',
		Арт: '4.11-Ru-K9',
		Наименование: 'Яблоко быстрозамороженное неочищенное кубик 10х10 - короб 9кг Россия (4.614)',
		'кол-во': 450,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8173',
		'Арт.1С': '4614',
		Арт: '4.11-Ru-K9',
		Наименование: 'Яблоко быстрозамороженное неочищенное кубик 10х10 - короб 9кг Россия (4.614)',
		'кол-во': 1908,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8174',
		'Арт.1С': '4614',
		Арт: '4.11-Ru-K9',
		Наименование: 'Яблоко быстрозамороженное неочищенное кубик 10х10 - короб 9кг Россия (4.614)',
		'кол-во': 2898,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-8246',
		'Арт.1С': '4623',
		Арт: '4.04-Ru-M25',
		Наименование: 'Яблоко быстрозамороженное с кожицей резанное - мешок 25кг Россия (4623) ',
		'кол-во': 1000,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3054',
		'Арт.1С': '57.21',
		Арт: '30/1.240',
		Наименование:
			'Спаржа б/зам зеленая 120-170мм, обрезка, визуальная инспекция, мойка, обдув, заморозка (57.21)',
		'кол-во': 2169,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3055',
		'Арт.1С': '57.21',
		Арт: '30/1.241',
		Наименование:
			'Спаржа б/зам зеленая 120-170мм, обрезка, визуальная инспекция, мойка, обдув, заморозка (57.21)',
		'кол-во': 1079,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3056',
		'Арт.1С': '57.21',
		Арт: '30/1.242',
		Наименование:
			'Спаржа б/зам зеленая 120-170мм, обрезка, визуальная инспекция, мойка, обдув, заморозка (57.21)',
		'кол-во': 1978,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3040',
		'Арт.1С': '57.20',
		Арт: '30/1.710',
		Наименование:
			'Спаржа б/зам зеленая короткая 120-170мм, визуальная инспекция, мойка, обдув, заморозка (57.20)',
		'кол-во': 561,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3041',
		'Арт.1С': '57.20',
		Арт: '30/1.710',
		Наименование:
			'Спаржа б/зам зеленая короткая 120-170мм, визуальная инспекция, мойка, обдув, заморозка (57.20)',
		'кол-во': 138,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3056',
		'Арт.1С': '57.20',
		Арт: '30/1.710',
		Наименование:
			'Спаржа б/зам зеленая короткая 120-170мм, визуальная инспекция, мойка, обдув, заморозка (57.20)',
		'кол-во': 2607,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3054',
		'Арт.1С': '57.98',
		Арт: '30.Н61.3',
		Наименование: 'Спаржа отход невозвратный от визуальной инспекции (57.98)',
		'кол-во': 214,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3055',
		'Арт.1С': '57.98',
		Арт: '30.Н61.4',
		Наименование: 'Спаржа отход невозвратный от визуальной инспекции (57.98)',
		'кол-во': 170,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3056',
		'Арт.1С': '57.98',
		Арт: '30.Н61.5',
		Наименование: 'Спаржа отход невозвратный от визуальной инспекции (57.98)',
		'кол-во': 95,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3054',
		'Арт.1С': '57.97',
		Арт: '30.Н24.13',
		Наименование: 'Спаржа отход невозвратный, обрезки (57.97)',
		'кол-во': 1069,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3055',
		'Арт.1С': '57.97',
		Арт: '30.Н24.13',
		Наименование: 'Спаржа отход невозвратный, обрезки (57.97)',
		'кол-во': 620,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '22ФР-3056',
		'Арт.1С': '57.97',
		Арт: '30.Н24.13',
		Наименование: 'Спаржа отход невозвратный, обрезки (57.97)',
		'кол-во': 37,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3170',
		'Арт.1С': '53.41',
		Арт: '27.210',
		Наименование: 'Томат быстрозамороженный-кубик 10х10мм (53.41)',
		'кол-во': 850,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3168',
		'Арт.1С': '53.43',
		Арт: '27.215',
		Наименование: 'Томат быстрозамороженный-скопления от калибратора (53.43)',
		'кол-во': 775,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3144',
		'Арт.1С': '53.42',
		Арт: '27.211',
		Наименование: 'Томат свежий-крошка (53.42)',
		'кол-во': 403,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3168',
		'Арт.1С': '53.42',
		Арт: '27.211',
		Наименование: 'Томат свежий-крошка (53.42)',
		'кол-во': 474,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3170',
		'Арт.1С': '53.42',
		Арт: '27.211',
		Наименование: 'Томат свежий-крошка (53.42)',
		'кол-во': 1488,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'Номер партии': '20ФР-3168',
		'Арт.1С': '53.94',
		Арт: '27.В72',
		Наименование: 'Томат свежий-отход от визуальной инспекции (53.94)',
		'кол-во': 1118,
		'№ пом.': null,
		Column7: null,
		Column8: null,
	},
	{
		'№ партии': '10ФР-0007',
		Наименование: 'Брусника б/з',
		'Масса, кг': 102424,
	},
	{
		'№ партии': '10ФР-0004',
		Наименование: 'Клубника б/з',
		'Масса, кг': 795281,
	},
	{
		'№ партии': '10ФР-0008',
		Наименование: 'Малина б/з',
		'Масса, кг': 23031,
	},
	{
		'№ партии': '10ФР-0006',
		Наименование: 'Черника б/з',
		'Масса, кг': 103533,
	},
	{
		'№ партии': '10ФР-0003',
		Наименование: 'Баклажан б/з или свеж?',
		'Масса, кг': 518,
	},
	{
		'№ партии': '10ФР-0010',
		Наименование: 'Клюква б/з',
		'Масса, кг': 1969,
	},
	{
		'№ партии': '10ФР-0005',
		Наименование: 'Черная смородина б/з',
		'Масса, кг': 259,
	},
	{
		'№ партии': '10ФР-0002',
		Наименование: 'Томат свеж.',
		'Масса, кг': 20358,
	},
	{
		'№ партии': '10ФР-0009',
		Наименование: 'Яблоко свеж',
		'Масса, кг': 13292,
	},
	{
		'№ партии': '10ФР-0001',
		Наименование: 'Спаржа свеж',
		'Масса, кг': 10737,
	},
]
