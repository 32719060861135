import { Table } from 'antd'
import React, { useContext } from 'react'
import { RawMaterialSupplyItemMainContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { ModContext, UserDataContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns } from './invoices-tab'
import { prepareData } from './../../../../utils/helpers/for-table'

const InvoicesTab = () => {
	const mainCtx = useContext(RawMaterialSupplyItemMainContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const stateFunctions = mainCtx.stateFunctions.invoices
	const modCtx = useContext(ModContext)
	const preparedData = prepareData(
		mainCtx.state.invoices.flatMap((inv) =>
			inv.products.length ? inv.products.map((p) => ({ ...p, ...inv })) : inv
		),
		'products',
		'prod'
	).filter(
		(el, index, self) =>
			self.findIndex((s) =>
				el.prod ? s.prod?._uuid_ === el.prod._uuid_ : s._uuid_ === el._uuid_
			) === index
	)
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					stateFunctions.create()
				},
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowKey={(rec) => rec._uuid_ + rec.prod?._uuid_ || ''}
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(modCtx, stateFunctions, mainCtx, currentRights)}
						dataSource={preparedData}
						scroll={{ x: 800 }}
						pagination={false}
						onRow={(record) => ({
							onClick: () => {},
						})}
					/>
				)
			}}
		/>
	)
}

export default InvoicesTab
