import { getRowSpan } from '../../../../utils/helpers/for-table'
import moment from 'moment'
import { dateFormat } from '../../../../utils/constants/common'
import { colorizeIntegrationListStatus } from '../../../../utils/helpers/for-integration'
/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (mainCtx) => {
	return [
		{
			title: 'ID запроса',
			dataIndex: 'id',
			key: 'id',
			width: '8.11%',
			render: (_, record) => record.id,
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.prods?.length),
			}),
		},
		{
			title: '№ партии',
			dataIndex: 'assignedPartyNum',
			key: 'assignedPartyNum',
			width: '10.77%',
			render: (_, record) => record.prod.partyNum,
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.prods?.length),
			}),
		},
		{
			title: 'Артикул',
			dataIndex: 'displayCode',
			key: 'displayCode',
			width: '11.91%',
			render: (_, record) => record.prod.articul,
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.prods?.length),
			}),
		},
		{
			title: 'Артикул 1С',
			dataIndex: 'vendorCode',
			key: 'vendorCode',
			width: '11.91%',
			render: (_, record) => record.prod.articul1C,
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.prods?.length),
			}),
		},
		{
			title: 'Продукт',
			dataIndex: 'product',
			key: 'product',
			width: '24.86%',
			render: (_, record) => record.prod.product,
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.prods?.length),
			}),
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '8.5%',
			render: (_, record) => record.prod.weight,
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.prods?.length),
			}),
		},
		{
			title: 'Статус',
			dataIndex: ['prod', 'status'],
			key: 'status',
			width: '11.5%',
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.prods?.length),
			}),
			render: colorizeIntegrationListStatus,
		},
		{
			title: 'Дата',
			dataIndex: 'date',
			key: 'date',
			width: '12.44%',
			render: (_, record) => moment(record.prod.date).format(dateFormat),
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.prods?.length),
			}),
		},
	]
}
