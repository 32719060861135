const dataSource = {
	Карточки: [
		{
			label: 'АРМ офиса',
			children: [
				{ label: 'Задачи на производство', children: [{ label: 'Изменить статус' }] },
				{ label: 'Поставщики' },
				{ label: 'Договоры. Поставщики' },
				{ label: 'Заявки на поставку сырья' },
				{
					label: 'Поставки сырья',
					children: [
						{ label: 'Основные данные' },
						{ label: 'Входной контроль' },
						{ label: 'Разгрузка' },
					],
				},
				{ label: 'Заказчики' },
				{ label: 'Договоры. Заказчики' },
				{ label: 'Сводные отчеты' },
				{ label: 'Продуктовый каталог' },
				{ label: 'Справочник артикулов' },
				{ label: 'Реестр Запасы' },
				{ label: 'Карточка Запасы', children: [{ label: 'Себестоимость' }] },
				{ label: 'Реализации' },
				{ label: 'Утилизации' },
				{ label: 'Перемещения удал. склад' },
				{ label: 'Перемещения в брак' },
				{ label: 'Протоколы контроля' },
				{ label: 'Персонал' },
			],
		},
		{
			label: 'АРМ цеха',
			children: [],
		},
	],
	Справочники: [
		'Продукция из 1С',
		'Статусы персонала',
		'Типы продукта',
		'Виды дополнительных ПФ',
		'Виды основных ПФ',
		'Виды НО',
		'Внешние склады',
		'Группы продукции',
		'Качество',
		'Помещения',
		'Производители сырья',
		'Процессы',
		'Параметры спецификации',
		'Причины отклонения отчета о производстве при согласовании',
		'Причины блокировки партии в отчете о производстве',
		'Смены',
		'Состояния сырья',
		'Страны происхождения "Сырья"',
		'Состояние машины',
		'Срок годности сырья',
		'Температурный режим',
		'Типы должностей',
		'Типы тары',
		'Условия доставки',
		'Условия оплаты',
		'Формы ГП',
	],
}

const structure = {
	Карточки: {
		'АРМ офиса': [
			'Поставщики',
			'Договоры. Поставщики',
			'Заявки на поставку сырья',
			'Заказчики',
			'Договоры. Заказчики',
			'Сводные отчеты',
			{
				'Поставки сырья': ['Основные данные', 'Входной контроль', 'Разгрузка'],
			},
			'Продуктовый каталог',
			'Справочник артикулов',
			'Реестр Запасы',
			{ 'Карточка Запасы': ['Себестоимость'] },
			{ 'Задачи на производство': ['Изменить статус'] },
			'Утилизации',
			'Персонал',
			'Реализации',
			'Перемещения удал. склад',
			'Перемещения в брак',
			'Протоколы контроля',
		],
		'АРМ цеха': [],
	},
	Справочники: {
		'Продукция из 1С': [],
		'Статусы персонала': [],
		'Типы продукта': [],
		'Виды дополнительных ПФ': [],
		'Виды основных ПФ': [],
		'Виды НО': [],
		'Внешние склады': [],
		'Группы продукции': [],
		Качество: [],
		Помещения: [],
		'Производители сырья': [],
		Процессы: [],
		'Параметры спецификации': [],
		Смены: [],
		'Состояния сырья': [],
		'Страны происхождения "Сырья"': [],
		'Состояние машины': [],
		'Срок годности сырья': [],
		'Температурный режим': [],
		'Типы должностей': [],
		'Типы тары': [],
		'Условия доставки': [],
		'Условия оплаты': [],
		'Причины отклонения отчета о производстве при согласовании': [],
		'Причины блокировки партии в отчете о производстве': [],
		'Формы ГП': [],
	},
}

module.exports = {
	dataSource,
	structure,
}
