import React from 'react'
import { Tabs } from 'antd'
import './tabs.scss'

const { TabPane } = Tabs

const TabList = (props) => {
	const { tabs, onTabClick = () => {}, style } = props

	const additionalProp = {}
	if (![null, undefined].includes(props.activeKey)) {
		additionalProp.activeKey = props.activeKey
	}
	return (
		<div className="tabsWrapper">
			<Tabs type="card" onTabClick={onTabClick} style={{ ...style }} {...additionalProp}>
				{tabs.map((tab) => {
					return (
						<TabPane tab={tab.title} key={tab.key}>
							{tab.component}
						</TabPane>
					)
				})}
			</Tabs>
		</div>
	)
}

export default TabList
