import React, { useContext, useState } from 'react'
import { Main } from '../../../../layouts'
import { Provider, StockRawMatListMainContext } from './provider/main'
import { HeaderMenu, StatefulTable, LoadingButton } from '../../../../components'
import { getColumns, getModalTitle, getToXlsx } from './raw-mat-list'
import { Button, Card, Checkbox, Row, Col, Space, Typography, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import ModalsForList from './ModalsForList'
import { AuthContext, ListContext, UserDataContext } from '../../../../contexts'
import { checkRole } from '@berry/common-functions/role'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { ExcelIcon } from '../../../../assets'
import saveAsXlsx from '@berry/common-functions/excel'
import { getAllDataForXl } from './../../../../utils/helpers/cross-pages-funcs'
import WeightFilterInputs from '../../../../components/WeightFilter/WeightFilter'

const pageUrl = '/stock/raw-mats'
const dataUrl = '/stock/raw-mats'

const StockRawMatList = (props) => {
	return (
		<Provider>
			<Main title={'Сырье'}>
				<StockRawMatListTable />
			</Main>
		</Provider>
	)
}

const StockRawMatListTable = () => {
	const mainCtx = useContext(StockRawMatListMainContext)
	const authCtx = useContext(AuthContext)

	const userDataCtx = useContext(UserDataContext)
	const {
		state: { mainFilter, filters, isInStock, sorter, weightFrom, weightTo },

		setIsInStock,
	} = useContext(ListContext)
	const [className, setClassName] = useState('')

	const canEdit = checkRole(
		{
			'АРМ офиса': {
				'Реестр Запасы': {
					edit: true,
				},
			},
		},
		userDataCtx.state.rights
	)
	const canViewRow = checkRole(
		{
			'АРМ офиса': {
				'Карточка Запасы': {
					view: true,
				},
			},
		},
		userDataCtx.state.rights
	)
	const dataSource = mainCtx.toDisplayDataList
	return (
		<>
			<HeaderMenu
				breadcrumbsTitle={'Сырье'}
				search={mainCtx.setSearch}
				additionalButtonsPre={[
					<LoadingButton
						type="text"
						title="Сохранить в Excel файл"
						icon={<ExcelIcon />}
						disabled={!mainCtx.toDisplayDataList.length}
						onClick={async () => {
							const { data } = await getAllDataForXl(
								dataUrl,
								filters,
								mainFilter,
								isInStock,
								weightFrom,
								weightTo
							)
							const toXlsx = getToXlsx(
								data,
								getColumns({
									dataSource,
									mainCtx,
									canEdit,
									fromServerFilters: mainCtx.state.fromServerFilters,
									sorter,
								}),
								['assignedPartyNumForSorter']
							)
							saveAsXlsx(toXlsx)
						}}
					/>,
				]}
			/>
			<Card>
				<Card>
					<Row justify="space-between">
						<Col>
							<Space>
								<Typography.Text strong>В наличии</Typography.Text>
								<Checkbox
									checked={isInStock}
									onChange={(e) => {
										setIsInStock(e.target.checked)
									}}
								/>
								<WeightFilterInputs />
							</Space>
						</Col>

						<Col>
							<Space>
								<Typography.Text strong>
									Общая масса, кг: {numToFixed(mainCtx.fromServerTotalSum, 1)}
								</Typography.Text>
							</Space>
						</Col>
					</Row>
				</Card>
				<StatefulTable
					rowKey={(el) => `${el.id}-${el.idStorage}`}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					dataSource={dataSource}
					columns={getColumns}
					size="small"
					url={pageUrl}
					canViewRow={canViewRow}
					searchFields={mainCtx.searchFields}
					columnProps={{
						mainCtx,
						canEdit,
						fromServerFilters: mainCtx.state.fromServerFilters,
						stockNotify: mainCtx.state.stockNotify,
						sorter,
						className,
						setClassName,
						authCtx,
						userDataCtx,
					}}
				/>
			</Card>
			<Modal
				onCancel={() => {
					if (mainCtx.removeRegisterModal.__isOpen) {
						mainCtx.modalFunctions.closeRemoveRegisterModal()
					}
					if (mainCtx.relocationModal.__isOpen) {
						mainCtx.modalFunctions.closeRelocationModal()
					}
					if (mainCtx.repackModal.__isOpen) {
						mainCtx.modalFunctions.closeRepackModal()
					}
					if (mainCtx.changeStatusModal.__isOpen) {
						mainCtx.modalFunctions.closeChangeStatusModal()
					}

					mainCtx.resetModals()
				}}
				cancelButtonProps={{ style: { display: 'none' } }}
				title={mainCtx.removeRegisterModal.__isOpen ? 'Возврат' : getModalTitle(mainCtx)}
				showCancel={false}
				width={'30%'}
				centered={true}
				icon={<ExclamationCircleOutlined />}
				visible={
					mainCtx.removeRegisterModal.__isOpen ||
					mainCtx.relocationModal.__isOpen ||
					mainCtx.repackModal.__isOpen ||
					mainCtx.changeStatusModal.__isOpen
				}
				maskClosable={false}
				destroyOnClose
				footer={[
					<Button
						key="ok-footer-button"
						type="primary"
						form="removeRegister"
						htmlType="submit"
						autoFocus
						loading={mainCtx.isModalsServerEditSend}
					>
						Ок
					</Button>,
				]}
			>
				<ModalsForList />
			</Modal>
		</>
	)
}
export default StockRawMatList
