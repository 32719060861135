import React, { useContext } from 'react'

import Wrapper from '../Wrapper/Wrapper'
import { DataServerContext } from '../../../contexts'
import { columns } from './columns'

const vocUrl = '/voc/static/facility-type'

const RawManufacturer = () => {
	const { state } = useContext(DataServerContext)

	return (
		<Wrapper
			rights={{
				Справочники: {
					'Типы объектов производства и вспомогательных служб': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Типы объектов производства и вспомогательных служб"
			data={state[vocUrl].data}
			columns={columns}
			vocUrl={vocUrl}
		/>
	)
}

export default RawManufacturer
