import { Table } from 'antd'
import React, { useContext } from 'react'
import { PosTypeContext } from '../provider/main'
import { TabItem } from '../../../../../components'
import { ModContext } from '../../../../../contexts'
import { modes } from '../../../../../utils'
import { getDisplayColumns } from './voc-tab'
import { posTypeData } from '@berry/static-data'

const VocTab = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(PosTypeContext)
	return (
		<TabItem
			addButton={{
				show: false,
			}}
			table={() => (
				<Table
					size="small"
					rowClassName={modCtx.mod !== modes.view && 'clickable'}
					columns={getDisplayColumns(mainCtx, modCtx)}
					dataSource={posTypeData.dataSource.Справочники || []}
					pagination={false}
					scroll={{ x: 800 }}
				/>
			)}
		/>
	)
}

export default VocTab
