import { Table } from 'antd'
import React, { useContext, useState } from 'react'
import { RecordItemModal, TabItem } from '../../../../components'
import { AuthContext, DataServerContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns, getDisplayModalColumns, prepareData } from './kind-raw-mat-tab'
import { ProductCatalogItemMainContext } from '../provider/main'
import lodash from 'lodash'

const KindRawMatTab = () => {
	const modCtx = useContext(ModContext)
	const dataFromServerCtx = useContext(DataServerContext)
	const mainCtx = useContext(ProductCatalogItemMainContext)

	const authCtx = useContext(AuthContext)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [itemForModal, setItemForModal] = useState(lodash.cloneDeep(mainCtx.state.kindRawMats))
	let toDisplayData = mainCtx.state.kindRawMats
	if (itemForModal?.prevData) toDisplayData = itemForModal.prevData
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					setItemForModal({ prevData: mainCtx.state.kindRawMats })
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						showErrorModal={true}
						isOpen={isModalOpen}
						title="Вид сырья"
						item={itemForModal}
						width={'50%'}
						deleteFun={() => {}}
						setItem={setItemForModal}
						setModalState={setIsModalOpen}
						editFun={(_, newData) => mainCtx.stateFunctions.kindRawMats.editAll(newData)}
						validate={() => {
							return mainCtx.validators.kindRawMats()
						}}
						form={() => {
							if (!itemForModal) return null
							const data = prepareData(mainCtx)
							return (
								<Table
									size="small"
									rowClassName={modCtx.mod !== modes.view && 'clickable'}
									columns={getDisplayModalColumns(mainCtx, modCtx)}
									dataSource={data}
									pagination={false}
									scroll={{ x: 800 }}
									onRow={(record) => ({
										onDoubleClick: () => {},
									})}
								/>
							)
						}}
					/>
				)
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowKey="id"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(mainCtx, modCtx, dataFromServerCtx, authCtx)}
						dataSource={toDisplayData}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)
			}}
		/>
	)
}

export default KindRawMatTab
