import { Modal, Table } from 'antd'
import React, { useContext } from 'react'
import { StockSemifItemMainContext } from '../provider/main'
import { TabItem } from '../../../../../components'
import { AuthContext, ModContext, UserDataContext } from '../../../../../contexts'
import { getDisplayColumns } from './storage-tab'
import PeresortModal from './PeresortModal'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { numToFixed } from '@berry/common-functions/cross-project-functions'

const StorageTab = () => {
	const mainCtx = useContext(StockSemifItemMainContext)
	const modCtx = useContext(ModContext)
	const storageSumWeight = mainCtx.state.storages.reduce((acc, cur) => {
		return acc + cur.weight
	}, 0)
	const authCtx = useContext(AuthContext)
	const userDataCtx = useContext(UserDataContext)

	return (
		<TabItem
			addButton={{ show: false }}
			modal={() => {
				return (
					<Modal
						onOk={() => {}}
						onCancel={() => {
							mainCtx.modalFunctions.closePeresort()
						}}
						cancelButtonProps={{ style: { display: 'none' } }}
						title={'Пересорт'}
						showCancel={false}
						width={'45%'}
						centered={true}
						icon={<ExclamationCircleOutlined />}
						okText={'Ok'}
						visible={mainCtx.peresort.__isOpen}
						destroyOnClose
					>
						<PeresortModal></PeresortModal>
					</Modal>
				)
			}}
			table={() => (
				<>
					<Table
						size="small"
						rowKey="_uuid_"
						rowClassName="clickable"
						columns={getDisplayColumns(mainCtx, modCtx, authCtx, userDataCtx)}
						dataSource={mainCtx.state.storages.filter((s) => s.weight)}
						loading={mainCtx.tabsLoading.storages}
						pagination={false}
						scroll={{ x: 800 }}
						summary={() => {
							return (
								<Table.Summary.Row
									style={{
										fontWeight: 700,
									}}
								>
									<Table.Summary.Cell>Итого</Table.Summary.Cell>
									<Table.Summary.Cell>{numToFixed(storageSumWeight, 2)}</Table.Summary.Cell>
								</Table.Summary.Row>
							)
						}}
					/>
				</>
			)}
		></TabItem>
	)
}

export default StorageTab
