import { Table } from 'antd'
import React, { useContext } from 'react'
import { TabItem } from '../../../../../components'
import { StockRawMatItemMainContext } from '../provider/main'

const CostTab = () => {
	const mainCtx = useContext(StockRawMatItemMainContext)
	const dataSource = mainCtx.state.costs
	return (
		<TabItem
			addButton={{ show: false }}
			table={() => (
				<Table
					loading={mainCtx.tabsLoading.costs}
					size="small"
					dataSource={dataSource}
					pagination={false}
					scroll={{ x: 800 }}
					columns={[
						{
							title: 'Тип затрат',
							dataIndex: 'type',
							key: 'type',
							width: '15%',
							render: (val, _, index) =>
								index !== dataSource.length - 1 ? val : <strong>{val}</strong>,
						},
						{
							title: 'Стоимость на 1 кг, руб',
							dataIndex: 'cost',
							key: 'cost',
							render: (val, _, index) =>
								index !== dataSource.length - 1 ? val : <strong>{val}</strong>,
						},
					]}
				/>
			)}
		/>
	)
}

export default CostTab
