import { basicValidator } from '@berry/common-functions/validators'
import React, { useContext, useEffect, useRef } from 'react'
import { ListContext, SyncDepsContext } from '../../../../../contexts'
import {
	everyOtherTimeUseEffectHandlerForList,
	startUseEffectHandlerForList,
} from '../../../../../utils'
import { getEtalon } from '../../spec-param'
import lodash from 'lodash'
const dataUrl = '/voc/editable/specification-parameter'

export const reducer = (state) => {
	return {
		...state,
	}
}

const SpecParamContext = React.createContext()
SpecParamContext.displayName = 'SpecParamContext'

const Provider = (props) => {
	const { children } = props
	const {
		state: { page, pageSize, mainFilter, filters, sorter },
		setTotal,
	} = useContext(ListContext)
	const syncDepsCtx = useContext(SyncDepsContext)

	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: { label: [], paramLabel: [] },
		isInitialized: false,
		isLoading: false,
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	useEffect(() => {
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: { pageLimit: pageSize, sorter, mainFilter, filters },
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [])
	useEffect(() => {
		everyOtherTimeUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				offset: pageSize * page - pageSize,
				limit: pageSize,
				sorter,
				mainFilter,
				filters,
				search: stateRef.current.search,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [
		page,
		sorter,
		mainFilter,
		filters,
		stateRef.current.search,
		syncDepsCtx.state.reloadUuids['office-ms'],
		stateRef.current.search,
	])

	const toDisplayDataList = () => {
		let result = stateRef.current.fromServer
		if (basicValidator(stateRef.current.search)) {
			result.forEach((e) => {
				if (String(e.label).toLowerCase().includes(stateRef.current.search.toLowerCase())) {
					e.__returnAll = true
				}
			})
			result.forEach((e) => {
				if (e.__returnAll) return
				e.params = e.params.filter((p) => {
					if (getEtalon(p).toLowerCase().includes(stateRef.current.search.toLowerCase()))
						return true
					return ['label', 'vocMeasure.labelShort'].some((sf) =>
						String(lodash.get(p, sf)).toLowerCase().includes(stateRef.current.search.toLowerCase())
					)
				})
			})

			result.forEach((e) => {
				delete e.__returnAll
			})
		}

		return result
	}

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}

	const value = {
		state: stateRef.current,
		toDisplayDataList: toDisplayDataList,
		setSearch: setSearch,
		allData: stateRef.current.fromServer,
	}

	return <SpecParamContext.Provider value={value}>{children}</SpecParamContext.Provider>
}

export { Provider, SpecParamContext }
