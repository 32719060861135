import React, { useContext } from 'react'

import { Main } from '../../../layouts'
import { Provider, ProviderListMainContext } from './provider/main'
import { HeaderMenu, StatefulTable } from '../../../components'
import { getColumns } from './provider-list'
import { Card } from 'antd'
import { UserDataContext } from '../../../contexts'
import { useHistory } from 'react-router-dom'
import { goToItem } from '../../../utils'

const pageUrl = '/rm/providers'

const ProviderList = () => {
	return (
		<Provider>
			<Main title={'Поставщики'}>
				<ProviderListTable />
			</Main>
		</Provider>
	)
}

const ProviderListTable = () => {
	const mainCtx = useContext(ProviderListMainContext)
	const history = useHistory()
	const {
		state: { isDevAdmin },
	} = useContext(UserDataContext)
	return (
		<>
			<HeaderMenu
				breadcrumbsTitle={'Поставщики'}
				search={mainCtx.setSearch}
				rights={{
					'АРМ офиса': {
						Поставщики: {
							edit: true,
						},
					},
				}}
				canCreate={isDevAdmin}
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					url={pageUrl}
					// searchFields={mainCtx.searchFields}
				/>
			</Card>
		</>
	)
}
export default ProviderList
