import React, { useRef, useReducer } from 'react'

export const dispatcher = (state) => {
	return { ...state }
}

const FromChildToParentContext = React.createContext()
FromChildToParentContext.displayName = 'FromChildToParent'

const Provider = (props) => {
	const { children } = props
	const [state, dispatch] = useReducer(dispatcher, {})

	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	const set = (fun, name) => {
		executeDispatch({ [name]: fun })
	}

	const value = {
		state: stateRef.current,
		set: set,
	}

	return (
		<FromChildToParentContext.Provider value={value}>{children}</FromChildToParentContext.Provider>
	)
}

export { Provider, FromChildToParentContext }
