import { Checkbox } from 'antd'
import { getProdNameByCustomerQuota } from '../../../common-functions'
import { changeDateOutput } from '../../../Contract/utils'

/**
 * определяет сколько строк обьединять
 * @param {Object} inRecord
 * @returns {number}
 */
const getRowSpan = (inRecord) => {
	if (inRecord.indxQuota === 0) {
		return inRecord.customerQuotas.length || 1
	} else {
		return 0
	}
}

/**
 * поля таблицы
 */
export const getDisplayColumns = (mainCtx) => {
	const isFrame = mainCtx.state.contracts.some((c) => c.frame)
	let items = [
		{
			title: 'Р',
			key: 'frame',
			render: (text, record) => <Checkbox disabled checked={record.frame}></Checkbox>,
			onCell: (record) => ({
				rowSpan: getRowSpan(record),
			}),
		},

		{
			title: '№ договора',
			dataIndex: 'num',
			key: 'num',
			onCell: (record) => ({
				rowSpan: getRowSpan(record),
			}),
		},
		{
			title: 'Дата заключения',
			dataIndex: 'dateStart',
			key: 'dateStart',
			onCell: (record) => ({ rowSpan: getRowSpan(record) }),
		},
		{
			title: 'Срок договора ',
			key: 'dateEnd',
			render: (text, record) => changeDateOutput(record),
			onCell: (record) => ({
				rowSpan: getRowSpan(record),
			}),
		},
		...(isFrame
			? [
					{
						title: '№ ДС',
						render: (_, record, index) => {
							return record.customerQuotas[index]?.application?.displayCode
						},
					},
					{
						title: 'Дата заключения ДС',
						render: (_, record, index) => {
							return record.customerQuotas[index]?.application?.dateStart
						},
					},
			  ]
			: []),
		{
			title: 'Продукт',
			key: 'prodCatPkg',
			render: (_, record, index) =>
				record.customerQuotas[index]
					? getProdNameByCustomerQuota(record.customerQuotas[index])
					: '',
		},
		{
			title: 'Квота, кг',
			dataIndex: 'quota',
			render: (inData, record, index) => {
				return record.customerQuotas[record.indxQuota]?.quota
			},
		},
		{
			title: 'Условия оплаты',
			key: 'vocPayCondit',
			render: (text, record, index) => {
				return record.customerQuotas[index]?.application
					? record.customerQuotas[index].application.paymentCondition.label
					: record.vocPayCondit?.label
			},
		},
		{
			title: 'Условия доставки',
			key: 'vocDeliverCondit',
			render: (text, record, index) => {
				return record.customerQuotas[index]?.application
					? record.customerQuotas[index].application.deliveryCondition.label
					: record.vocDeliverCondit?.label
			},
		},
	]
	return items
}

/**
 * подготавливает данные для отрисовки таблицы
 * @param {Array<Object>} inContracts - массив контрактов
 *
 */

export const prepareData = (inContracts) => {
	const result = inContracts.reduce((acc, curContr) => {
		const rows = curContr.customerQuotas.map((quota, indx) => {
			return { ...curContr, indxQuota: indx }
		})
		if (!rows.length) return [...acc, { ...curContr, indxQuota: 0 }]
		return [...acc, ...rows]
	}, [])
	return result
}
