import React from 'react'
import { Checkbox, Form as FormAntd, Input } from 'antd'

const formItemLayout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

const Form = (props) => {
	const { label, labelShort, isBindCaliber } = props

	return (
		<FormAntd labelAlign="left">
			<FormAntd.Item
				{...formItemLayout}
				label="Форма"
				getValueProps={(value) => value}
				name="label"
				onChange={label.onChange}
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Input value={label.value} />
			</FormAntd.Item>

			<FormAntd.Item
				{...formItemLayout}
				label="Краткое наименование"
				getValueProps={(value) => value}
				name="labelShort"
				onChange={labelShort.onChange}
				rules={[
					{
						required: false,
						message: false,
					},
					{ max: 15, message: 'Введите не более 11 символов' },
				]}
			>
				<Input value={labelShort.value} maxLength={15} />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Привязать калибр"
				name="isBindCaliber"
				valuePropName="isBindCaliber"
				getValueProps={(value) => value}
				onChange={isBindCaliber.onChange}
			>
				<Checkbox checked={isBindCaliber.value} />
			</FormAntd.Item>
		</FormAntd>
	)
}

export default Form
