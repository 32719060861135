import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Row, Table } from 'antd'
import { ModContext, ModProvider } from '../../../contexts'
import { Provider, MatchTaskPfSettingsItemMainContext } from './provider/main'
import { Main } from '../../../layouts'
import { ActionButtons, HeaderMenu, showConfirmModal } from '../../../components'
import { getDisplayColumns } from './match-task-pf-settings-item'
import { actBtnOnClose, modes } from '../../../utils'
import TabItem from '../../../components/TabItem/TabItem'

const pageUrl = '/system-settings'

const MatchTaskPfSettingsItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Настройки системы">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const mainCtx = useContext(MatchTaskPfSettingsItemMainContext)
	const modCtx = useContext(ModContext)
	const history = useHistory()
	return (
		<>
			<HeaderMenu
				breadcrumbsTitle="Настройки системы"
				recordLabel="Согласование отчета, включающего только ПФ"
			>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						rights={{
							'АРМ офиса': {
								'Настройки системы': {
									edit: true,
								},
							},
						}}
						addBtn={() => {}}
						onClose={() =>
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
								isCancelBtnPrimary: true,
							})
						}
						onSave={async () => await mainCtx.serverEdit()}
						onEdit={() => modCtx.set(modes.edit)}
						onCancel={() => mainCtx.reset()}
					/>
				</Row>
			</HeaderMenu>
			<TabItem
				addButton={{ show: false }}
				table={() => {
					return (
						<Table
							size="small"
							rowClassName={modCtx.mod !== modes.view && 'clickable'}
							columns={getDisplayColumns(mainCtx, modCtx)}
							dataSource={mainCtx.state}
							pagination={false}
							scroll={{ x: 800 }}
							key={'contactTable'}
						/>
					)
				}}
			/>
		</>
	)
}

export default MatchTaskPfSettingsItem
