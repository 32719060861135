import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import moment from 'moment'
import { v4 } from 'uuid'
import { ModContext, SyncDepsContext } from '../../../../contexts'
import {
	getCommonProviderFunctions,
	getCommonProviderModalFunctions,
	getNextCode,
} from '../../../../utils/helpers/generators'
import { calcTestReportNum } from '../../../../utils/helpers/cross-pages-funcs'
import {
	prepareObjFromServer,
	modes,
	getNewObj,
	isAvailableToAddToArr,
	removeByUuid,
	useItemFetchers,
	axios,
	goToItem as goToItemCommon,
} from '../../../../utils'
import { basicValidator } from '@berry/common-functions/validators'
import { useHistory } from 'react-router-dom'
import useCloseOrReloadTab from '../../../../utils/hooks/useCloseOrReloadTab'
import useInactivityDetector from '../../../../utils/hooks/useInactivityDetector'
import {
	commonBlockForEditing,
	commonUnblockForEditing,
} from '../../../../utils/helpers/for-block-unblock'
import { showConfirmModal } from '../../../../components'
export const dataUrl = '/rm/supplies'
const tabs = { invoices: 'invoices', 'event-histories': 'eventHistories', products: 'products' }
export const reducer = (state) => {
	return {
		...state,
	}
}

const RawMaterialSupplyItemMainContext = React.createContext()
RawMaterialSupplyItemMainContext.displayName = 'RawMaterialSupplyItemMainContext'

const initialData = {
	date: moment(),
	status: 'Новая',
	isSeal: true,
	isHasReq: true,
	invoices: [],
	products: [],
}

const initialState = {
	data: initialData,
	oldData: initialData,
	formErrors: {},
	additional: {
		allSelectCarStates: [],
		staff: [],
		allSelectContTypes: [],
		supplyProds: [],
		maxInvoiceId: null,
	},
	blockUnblock: {
		isBlocked: true,
		depsBlocked: [],
	},
	isInitializedMain: false,
	isInitializedAdditional: false,
	tabsLoading: {
		eventHistories: false,
		invoices: false,
		products: false,
	},
	addContrQuota: {
		__isOpen: false,
		addContrQuotaContrQuota: [],
	},
}

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const stateRef = useRef(state)
	const executeDispatch = useInactivityDetector(
		stateRef,
		dispatch,
		modCtx,
		blockForEditing,
		async () => {
			modCtx.set(modes.view)
			await unblockForEditing()
			await axios.post(`/logs`, {
				time: new Date().toISOString(),
				action: 'User inactivity',
				entity: 'rmSupply',
				idEntity: params.id,
			})
			stateRef.current = { ...stateRef.current, reloadUuid: v4() }
			dispatch({ ...stateRef.current })
			showConfirmModal({
				title:
					'Ваша сессия истекла из-за неактивности в течение определенного времени, введенные данные не сохранены',
				onOk: () => {},
				showCancel: false,
				okText: 'Ок',
			})
		},
		{
			activityCallbackIntervalSeconds: 10,
			inactivityLimitSeconds: 60 * 5,
		}
	)

	const history = useHistory()
	const requiredFields = {
		products: ['weightInv', 'vocContTypeFact', 'assignedPartyNum', 'invoice'],
		main: ['supplyReq', 'staff'],
		document: ['documents.label'],
		invoices: ['displayCode', 'date'],
	}

	const getActualState = () => {
		const result = lodash.cloneDeep(stateRef.current.data)
		result.products.sort((a, b) => {
			const __idA = a.contrQuota ? a.contrQuota.contract.id : a.order.contract.id
			const __idB = b.contrQuota ? b.contrQuota.contract.id : b.order.contract.id
			return __idA - __idB
		})
		result.invoices.sort((a, b) => a.id - b.id)
		return result
	}

	const getDepsOn = () => []

	const validators = {}

	const validate = async () => {
		let newFormErrors = {}
		const data = stateRef.current.data
		if (!data.supplyReq?.id) {
			return 'Не выбрана заявка'
		}
		const isUniqByReq = (
			await axios(`${dataUrl}/check-is-unique-by-req`, {
				params: {
					id: data.id,
					reqId: data.supplyReq.id,
				},
			})
		)?.data?.result
		if (!isUniqByReq) {
			return 'Невозможно сохранить карточку. Поставка по указанной заявке уже существует в системе.'
		}
		if (!data.products.length || !data.invoices.length) {
			return 'Не добавлена ни одна строка в табличной вкладке'
		}
		if (data.isSeal && !basicValidator(data.sealNum)) {
			return 'Не заполнены обязательные поля'
		}
		if (requiredFields.main.some((f) => !basicValidator(data[f]))) {
			return 'Не заполнены обязательные поля'
		}
		if (
			data.invoices.some((invoice) =>
				requiredFields.invoices.some((f) => !basicValidator(invoice[f]))
			)
		) {
			data.invoices.forEach((p) => {
				requiredFields.invoices.forEach((f) => {
					if (!basicValidator(p[f])) {
						newFormErrors = { ...newFormErrors, [f + '.' + p._uuid_]: true }
					}
				})
			})
			executeDispatch({ ...stateRef.current, formErrors: newFormErrors })
			return 'Не заполнены обязательные поля'
		}
		if (
			data.products.some((product) =>
				requiredFields.products.some((f) => !basicValidator(product[f]))
			)
		) {
			data.products.forEach((p) => {
				requiredFields.products.forEach((f) => {
					if (!basicValidator(p[f])) {
						newFormErrors = { ...newFormErrors, [f + '.' + p._uuid_]: true }
					}
				})
			})
			executeDispatch({ ...stateRef.current, formErrors: newFormErrors })
			return 'Не заполнены обязательные поля'
		}
		return ''
	}
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			oldData: recordFromDataSrvCtx,
			formErrors: {},
			addContrQuota: {
				__isOpen: false,
				addContrQuotaContrQuota: [],
			},
		})
	}, [])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}
	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, params.id])

	const getAssignedPartyNum = (prod, index, isReadyProdResale = false) => {
		const __defaultValueForIsAcceptFromZagotovka = 1000
		const __defaultValIsReadyProdResale = 2000
		const __defaultPref = 'ФР'
		const lastTwoNumsOfHarvestYear = moment(prod.reqProd?.harvestYear || prod.harvestYear).format(
			'YY'
		)
		const options = {}
		const { isAcceptFromZagotovka } = stateRef.current.data
		isReadyProdResale =
			typeof isReadyProdResale === 'boolean' ? isReadyProdResale : prod.isReadyProdResale
		const getMaxAssignedPartyNum = () => {
			if (isReadyProdResale) {
				return stateRef.current.additional.assignedPartyNum.isReadyProdResale[
					lastTwoNumsOfHarvestYear
				]
			}
			if (isAcceptFromZagotovka) {
				return stateRef.current.additional.assignedPartyNum.isAcceptFromZagotovka[
					lastTwoNumsOfHarvestYear
				]
			}
			return stateRef.current.additional.assignedPartyNum.regular[lastTwoNumsOfHarvestYear]
		}
		const maxAssignedPartyNum = getMaxAssignedPartyNum()

		if (+lastTwoNumsOfHarvestYear === 22) {
			options.startWith = 90
		}
		let nextPartyNum = getNextCode(
			[
				+maxAssignedPartyNum?.assignedPartyNum?.slice(5, 9) ||
					(isReadyProdResale
						? __defaultValIsReadyProdResale
						: isAcceptFromZagotovka
						? __defaultValueForIsAcceptFromZagotovka
						: 0),
				...stateRef.current.data.products
					.filter((e) => {
						if (isReadyProdResale)
							return e.assignedPartyNum && e.assignedPartyNum.startsWith(lastTwoNumsOfHarvestYear)
						if (stateRef.current.data.isAcceptFromZagotovka) {
							return (
								e.assignedPartyNum &&
								e.assignedPartyNum.startsWith(lastTwoNumsOfHarvestYear) &&
								!e.isReadyProdResale
							)
						}
						return (
							e.assignedPartyNum &&
							e.assignedPartyNum.startsWith(lastTwoNumsOfHarvestYear) &&
							!e.isReadyProdResale
						)
					})
					.map((e) => +e.assignedPartyNum?.slice(5, 9)),
			],
			options
		)
		return `${lastTwoNumsOfHarvestYear}${__defaultPref}-${String(nextPartyNum + index).padStart(
			4,
			'0'
		)}`
	}

	const getIndexByHarvestYear = (data, inArr) => {
		const harvestYear = moment(data.reqProd?.harvestYear).format('YYYY')
		let foundIndex = 0
		if (data.isReadyProdResale) {
			const readyProdResaleArr = inArr.filter(
				(e) => e.isReadyProdResale && e.reqProd.harvestYear.startsWith(harvestYear)
			)
			foundIndex = readyProdResaleArr.findIndex((e) => e.id === data.id)
		}
		if (!data.isReadyProdResale) {
			const notReadyProdResaleArr = inArr.filter(
				(e) => !e.isReadyProdResale && e.reqProd.harvestYear.startsWith(harvestYear)
			)
			foundIndex = notReadyProdResaleArr.findIndex((e) => e.id === data.id)
		}
		if (!data.isReadyProdResale && data.isAcceptFromZagotovka) {
			const isAcceptFromZagatovkaArr = inArr.filter(
				(e) => !e.isAcceptFromZagotovka && e.reqProd.harvestYear.startsWith(harvestYear)
			)
			foundIndex = isAcceptFromZagatovkaArr.findIndex((e) => e.id === data.id)
		}
		return foundIndex
	}

	const productCopy = async (rec) => {
		const clonedData = stateFunctions.products.clone(rec._uuid_, [
			'displayVal',
			rec.order ? 'order' : 'contrQuota',
			...(rec.invoice ? ['invoice'] : []),
		])
		const foundProd = stateRef.current.data.supplyReq.products.find((p) =>
			rec.contrQuota ? p.contrQuota?.id === rec.contrQuota.id : p.order?.id === rec.order.id
		)
		const newProd = {
			contract: foundProd.contract,
			vocContType: foundProd.vocContType,
			containerWeight: foundProd.containerWeight,
			harvestYear: foundProd.harvestYear,
			reqProd: { ...foundProd, isReadyProdResale: rec.isReadyProdResale },
			isReadyProdResale: rec.isReadyProdResale,
			...clonedData,
		}
		const newProdUuid = await stateFunctions.products.create([newProd])
		if (rec.invoice?.id) {
			processInvoices(stateFunctions.products.get(newProdUuid), rec.invoice.id)
		}
	}

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		{},
		[
			{
				field: 'contrQuota',
				updateVal: 'common',
				modalName: 'addContrQuota',
			},
		],
		[
			{
				mainField: 'contrQuota',
				field: 'contrQuota',
				updateVal: 'common',
				modalName: 'addContrQuota',
			},
		]
	)
	const selectors = {
		products: {
			invoice: stateRef.current.data.invoices,
			vocContTypeFact: stateRef.current.additional.allSelectContTypes,
		},
	}
	const {
		commonFieldUpdate,
		commonDeepObjUpdate,
		commonDeepFieldUpdate,
		isEdited,
		stateFunctions,
		serverEdit,
		serverDelete,
		setError,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{ modCtx, dataUrl, params, requiredFields, pageUrl: '/rm/supplies', history },
		selectors,
		{
			vehicleNum: 'common',
			trailerNum: 'common',
			arriveTime: 'common',
			supplyDatePlan: 'common',
			isHasReq: 'common',
			sealNum: 'common',
			temperSymb: 'common',
			temperVal: 'common',
			carState: 'common',
			staff: 'common',
			date: 'common',
			noCooling: 'common',
			defrost: 'common',
			unldTimeStart: 'common',
			unldTimeStop: 'common',
		},
		{
			products: {
				contrQuota: 'obj',
				weightInv: 'common',
				packageCountInv: 'common',
				vocContTypeFact: 'obj',
				partyNumManuf: 'common',
				assignedPartyNum: 'common',
				isReadyProd: 'common',
				ctrlControlDate: 'common',
				ctrlStatus: 'common',
			},
			invoices: {
				date: 'common',
				isReadyProdResale: 'common',
			},
		}
	)
	const { refetch } = useItemFetchers(
		dataUrl,
		params.id,
		tabs,
		stateRef,
		useCallback(executeDispatch, [])
	)

	// useCheckBlock(`${dataUrl}/check-block/${params.id}`, executeDispatch, stateRef, [
	// 	dataUrl,
	// 	params.id,
	// 	tabs,
	// 	syncDepsCtx.state.reloadUuids['office-ms'],
	// 	stateRef.current.reloadUuid,
	// ])

	const checkIsBlocked = async () => {
		const res = await axios(`${dataUrl}/check-block/${params.id}`)
		if (res.status === 200) {
			return res.data.mainData.isBlocked
		}
		return true
	}

	useCloseOrReloadTab(
		async (event) => {
			if (modCtx.mod === modes.edit) {
				event.preventDefault()
				await unblockForEditing()
			}
		},
		[
			dataUrl,
			params.id,
			modCtx.mod,
			tabs,
			syncDepsCtx.state.reloadUuids['office-ms'],
			stateRef.current.reloadUuid,
		]
	)

	const serverSendTo1c = async (idInvoice) => {
		const body = {
			ids: [idInvoice],
			id: stateRef.current.data.id,
			idSupply: stateRef.current.data.id,
			articuls: stateRef.current.data.products.map((e) => ({
				id: e.id,
				articul: e.articul,
			})),
		}
		await axios.put(`${dataUrl}/send-to-1c`, { ...body })
		refetch()
	}
	const processInvoices = (inProd, invId) => {
		const foundInvoiceSelected = stateRef.current.data.invoices.find((e) => +e.id === +invId)
		const foundInvoiceDroppedIndx = stateRef.current.data.invoices.findIndex(
			(e) => e._uuid_ === inProd.invoice?._uuid_ || +e.id === +inProd.invoice?.id
		)
		if (foundInvoiceDroppedIndx !== -1) {
			const invoice = stateRef.current.data.invoices[foundInvoiceDroppedIndx]
			const prodIndx = invoice.products.findIndex(
				(e) => +e.id === +inProd.id || inProd._uuid_ === e._uuid_
			)
			let newProducts = [
				...invoice.products.slice(0, prodIndx),
				...invoice.products.slice(prodIndx + 1, invoice.products.length),
			]
			commonDeepFieldUpdate(['invoices'], [invoice._uuid_], 'products', newProducts)
		}
		commonDeepFieldUpdate(['invoices'], [foundInvoiceSelected._uuid_], 'products', [
			...(foundInvoiceSelected.products || []),
			inProd,
		])
	}

	const handleBlockOfChangedSelect = async (entity, val) => {
		const foundIndx = stateRef.current.blockUnblock.depsBlocked.findIndex(
			(d) => d.entity === entity
		)
		let newDepsBlocked = [...stateRef.current.blockUnblock.depsBlocked]
		if (foundIndx !== -1) {
			axios.post(`/unblock-entity`, [{ ...newDepsBlocked[foundIndx] }])
			newDepsBlocked.splice(foundIndx, 1)
		}
		newDepsBlocked.push({ entity, id: val.id })
		executeDispatch({
			...stateRef.current,
			blockUnblock: { ...stateRef.current.blockUnblock, depsBlocked: newDepsBlocked },
		})
		axios.post(`/block-entity`, [{ entity, id: val.id }])
	}

	const handleBlockOfChangedProducts = async (entity, inUuid, val) => {
		const foundIndx = stateRef.current.blockUnblock.depsBlocked.findIndex(
			(d) => d.entity === entity && inUuid === d.uuid
		)
		let newDepsBlocked = [...stateRef.current.blockUnblock.depsBlocked]
		if (foundIndx !== -1) {
			axios.post(`/unblock-entity`, [{ entity, id: newDepsBlocked[foundIndx].id }])
			newDepsBlocked.splice(foundIndx, 1)
		}
		newDepsBlocked.push({ entity, id: val.id, uuid: inUuid })
		executeDispatch({
			...stateRef.current,
			blockUnblock: { ...stateRef.current.blockUnblock, depsBlocked: newDepsBlocked },
		})
		axios.post(`/block-entity`, [{ entity, id: val.id }])
	}

	const entityToSetFuncNameMapForMainCard = {
		regStaff: 'setStaff',
		vocCarState: 'setCarState',
	}

	const entityToSetFuncNameMapForProducts = {
		contrProviderQuota: 'setContrQuota',
		vocEditableContainerType: 'setVocContTypeFact',
	}

	Object.entries(entityToSetFuncNameMapForMainCard).forEach(([entity, funcName]) => {
		let oldF = stateFunctions[funcName]
		stateFunctions[funcName] = (inVal) => {
			oldF(inVal)
			handleBlockOfChangedSelect(entity, inVal)
		}
	})

	Object.entries(entityToSetFuncNameMapForProducts).forEach(([entity, funcName]) => {
		let oldF = stateFunctions.products[funcName]
		stateFunctions.products[funcName] = (inUuid, inVal, inRequired) => {
			oldF(inUuid, inVal, inRequired)
			handleBlockOfChangedProducts(entity, inUuid, inVal)
		}
	})
	stateFunctions.products.setAssignedPartyNum = (inUuid, val, inProd = {}) => {
		commonDeepFieldUpdate(['products'], [inUuid], 'assignedPartyNum', val, true)
		const foundProds = stateRef.current.data.products.filter(
			(p) => p.invoice.id === inProd.invoice.id
		)
		const foundInvoiceSelected = stateRef.current.data.invoices.find(
			(e) => +e.id === +inProd.invoice.id
		)
		commonDeepFieldUpdate(['invoices'], [foundInvoiceSelected._uuid_], 'products', [
			...(foundProds || []),
		])
	}
	stateFunctions.products.setInvoice = (inUuid, invId, inData = {}) => {
		commonDeepObjUpdate(['products'], [inUuid], 'invoice', invId, true)
		processInvoices(inData, invId)
	}
	stateFunctions.invoices.setDisplayCode = (inUuid, val) => {
		commonDeepFieldUpdate(['invoices'], [inUuid], 'displayCode', val, true)
		const foundInvoice = stateRef.current.data.invoices?.find((inv) => inv._uuid_ === inUuid)
		const productsWithSameInvoice = stateRef.current.data?.products?.filter(
			(prod) => prod?.invoice?.id === foundInvoice?.id
		)
		productsWithSameInvoice?.forEach((prod) => {
			commonDeepFieldUpdate(['products'], [prod._uuid_], 'invoice', {
				...(foundInvoice || []),
			})
		})
	}

	stateFunctions.products.create = async (inArr = []) => {
		const newProducts = []
		for await (const [index, inData] of inArr.entries()) {
			const toCreate = {
				contract: inData.contract,
				contrQuota: inData.contrQuota,
				order: inData.order,
				unldVNaval: !!inData.unldVNaval,
				ctrlDate: inData.date || stateRef.current.data?.date || moment(),
				ctrlControlDate: inData.reqProd.isReadyProdResale ? null : stateRef.current.data?.date,
				reqProd: inData.reqProd,
				displayVal: inData.displayVal,
				invoice: inData.invoice,
				isReadyProdResale: inData.reqProd.isReadyProdResale,
			}
			const indexByHarvestYear = getIndexByHarvestYear(inData, inArr)
			toCreate.ctrlTestReportNum = await calcTestReportNum(stateRef.current.data.products, {
				index,
			})
			toCreate.assignedPartyNum =
				inData.contrQuota || inData.order
					? getAssignedPartyNum(inData, indexByHarvestYear, inData.isReadyProdResale)
					: null
			toCreate.unldStatus = 'Новая'
			toCreate.ctrlStatus = 'Новая'
			const foundProdShelfLife = stateRef.current.additional.rawMatShelfLifes.find((el) => {
				return inData.contrQuota
					? inData.contrQuota.rmProvProd.prodCatKindRawMat?.id === el.prodCatKindRawMat.id
					: inData.order?.rmProvProd?.prodCatKindRawMat?.id === el.prodCatKindRawMat.id
			})
			if (foundProdShelfLife) {
				toCreate.ctrlShelfLife = foundProdShelfLife.shelfLife
				toCreate.ctrlMeasure = foundProdShelfLife.vocMeasure
			}
			if (isAvailableToAddToArr(toCreate)) {
				newProducts.push(...stateRef.current.data.products, getNewObj(toCreate))
			} else {
				newProducts.push(...stateRef.current.data.products, getNewObj())
			}
		}
		commonFieldUpdate('products', newProducts)
		return newProducts.slice(-1)[0]._uuid_
	}
	stateFunctions.products.delete = (inUuid, id) => {
		const foundInvoiceWithDroppedProdIndx = stateRef.current.data.invoices.findIndex((e) =>
			e.products?.some((p) => p._uuid_ === inUuid || +p.id === +id)
		)
		if (foundInvoiceWithDroppedProdIndx !== -1) {
			const invoice = stateRef.current.data.invoices[foundInvoiceWithDroppedProdIndx]
			const prodIndx = invoice.products.findIndex((e) => +e.id === +id || inUuid === e._uuid_)
			let newProducts = [
				...invoice.products.slice(0, prodIndx),
				...invoice.products.slice(prodIndx + 1, invoice.products.length),
			]
			commonDeepFieldUpdate(['invoices'], [invoice._uuid_], 'products', newProducts)
		}
		const newProducts = removeByUuid(inUuid, stateRef.current.data.products)
		commonFieldUpdate('products', newProducts)
	}
	stateFunctions.setIsAcceptFromZagotovka = (val) => {
		commonFieldUpdate('isAcceptFromZagotovka', val)
		stateRef.current.data.products.forEach((p) =>
			commonDeepFieldUpdate(['products'], [p._uuid_], 'assignedPartyNum', null)
		)
		stateRef.current.data.products.forEach((p, index) => {
			commonDeepFieldUpdate(
				['products'],
				[p._uuid_],
				'assignedPartyNum',
				getAssignedPartyNum(
					p,
					getIndexByHarvestYear(p, stateRef.current.data.products),
					p.isReadyProdResale
				)
			)
		})
	}
	stateFunctions.invoices.create = (inData) => {
		const toCreate = { status: 'Новая', uuid: v4(), isReadyProdResale: false, products: [] }
		let newValues
		let id =
			Math.max(
				...stateRef.current.data.invoices.map((i) => i.id),
				stateRef.current.additional.maxInvoiceId
			) + 1
		if (+id < 200) id = 200
		if (isAvailableToAddToArr(inData)) {
			newValues = [
				...(stateRef.current.data.invoices || []),
				{
					...getNewObj({ ...inData, ...toCreate }),
					id,
				},
			]
		} else {
			newValues = [
				...(stateRef.current.data.invoices || []),
				{
					...getNewObj(),
					id,
				},
			]
		}
		commonFieldUpdate('invoices', newValues)
		return newValues.slice(-1)[0]._uuid_
	}
	stateFunctions.invoices.delete = (inUuid, id) => {
		const foundProducts = stateRef.current.data.products.filter(
			(e) => e.invoice?._uuid_ === inUuid || +e.invoice?.id === +id
		)
		foundProducts.forEach((p) => commonDeepFieldUpdate(['products'], [p._uuid_], 'invoice', null))
		const newInvoices = removeByUuid(inUuid, stateRef.current.data.invoices)
		commonFieldUpdate('invoices', newInvoices)
	}
	stateFunctions.products.getProductByContrQuota = (contrQuota) => {
		return stateRef.current.data.supplyReq.products.find((product) => {
			return product.contrQuota?.id === contrQuota?.id
		})
	}

	stateFunctions.setSupplyReq = (val) => {
		commonFieldUpdate('supplyReq', val)
		handleBlockOfChangedSelect('rmSupplyRequest', val)
		commonFieldUpdate('products', [])
		commonFieldUpdate('invoices', [])
		if (val.products.length) {
			stateFunctions.products.create(
				val.products.map((prod) => ({
					...prod,
					reqProd: prod,
					unldVNaval: !!prod.vNaval,
				}))
			)
		}
	}

	stateFunctions.setIsSeal = (val) => {
		commonFieldUpdate('isSeal', val)
		commonFieldUpdate('sealNum', null)
	}
	stateFunctions.products.setIsReadyProdResale = (val, inUuid, prod) => {
		commonDeepFieldUpdate(['products'], [inUuid], 'assignedPartyNum', null)
		commonDeepFieldUpdate(['products'], [inUuid], 'invoice', null)
		if (prod.invoice?.id) {
			const foundInv = stateRef.current.data.invoices?.find((inv) => inv.id === prod.invoice.id)
			commonDeepFieldUpdate(
				['invoices'],
				[foundInv._uuid_],
				'products',
				foundInv.products.filter((p) => p.id !== prod.id)
			)
		}
		if (val) {
			commonDeepFieldUpdate(['products'], [inUuid], 'ctrlControlDate', null)
		} else {
			commonDeepFieldUpdate(['products'], [inUuid], 'ctrlControlDate', stateRef.current.data?.date)
		}

		const partyNum = getAssignedPartyNum(prod, 0, val)
		commonDeepFieldUpdate(['products'], [inUuid], 'isReadyProdResale', val)
		commonDeepFieldUpdate(['products'], [inUuid], 'assignedPartyNum', partyNum)
	}
	stateFunctions.invoices.setIsReadyProdResale = (inUuid, val, inv) => {
		commonDeepFieldUpdate(['invoices'], [inUuid], 'isReadyProdResale', val)
		commonDeepFieldUpdate(['invoices'], [inUuid], 'products', [])
		stateRef.current.data.products.forEach((p) => {
			if (p.invoice?.id === inv.id) {
				commonDeepFieldUpdate(['products'], [p._uuid_], 'invoice', null)
			}
		})
	}

	stateFunctions.setNoCooling = (val) => {
		commonFieldUpdate('defrost', false)
		commonFieldUpdate('temperVal', null)
		commonFieldUpdate('temperSymb', null)
		commonFieldUpdate('noCooling', val)
	}

	stateFunctions.setDefrost = (val) => {
		commonFieldUpdate('noCooling', false)
		commonFieldUpdate('temperVal', null)
		commonFieldUpdate('temperSymb', null)
		commonFieldUpdate('defrost', val)
	}

	stateFunctions.setDate = async (date) => {
		commonFieldUpdate('date', date)
		for (const p of stateRef.current.data.products) {
			commonDeepFieldUpdate(
				['products'],
				[p._uuid_],
				'ctrlTestReportNum',
				await calcTestReportNum(stateRef.current.data.products)
			)
		}
	}

	const validateContrQuota = () => {
		if (!stateRef.current.addContrQuota.addContrQuotaContrQuota?.length) {
			throw Error('Не выбрана строка')
		}
	}

	async function blockForEditing() {
		return await commonBlockForEditing(dataUrl, params.id)
	}

	async function unblockForEditing() {
		return await commonUnblockForEditing(
			stateRef.current.blockUnblock.depsBlocked,
			dataUrl,
			params.id
		)
	}

	const goToItem = (url, id) => {
		goToItemCommon(history, { url, id })
	}

	const rerender = () => {
		executeDispatch(stateRef.current)
	}

	const value = {
		state: getActualState(),
		blockUnblock: stateRef.current.blockUnblock,
		rerender,
		isPendingReq: stateRef.current.isPendingReq,
		additional: stateRef.current.additional,
		addContrQuota: lodash.cloneDeep(stateRef.current.addContrQuota),
		blockForEditing,
		unblockForEditing,
		checkIsBlocked,
		stateFunctions,
		modalFunctions,
		serverEdit,
		serverDelete,
		isEdited,
		validate,
		reset,
		validators: validators,
		formErrors: stateRef.current.formErrors,
		getDepsOn,
		requiredFields,
		setError,
		serverSendTo1c,
		productCopy,
		validateContrQuota,
		goToItem,
		tabsLoading: stateRef.current.tabsLoading,
	}

	return (
		<RawMaterialSupplyItemMainContext.Provider value={value}>
			{children}
		</RawMaterialSupplyItemMainContext.Provider>
	)
}

export { Provider, RawMaterialSupplyItemMainContext }
