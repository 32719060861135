import { dateFormat } from '../../../../utils/constants/common'
import moment from 'moment'
import { getSellByDate } from '../../RawMat/RawMatItem/raw-mat-item'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { Col, Select } from 'antd'
import { modes } from '../../../../utils'
const layout1Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 11, offset: 0 },
	},
	wrapperCol: { span: 13 },
}
const layout2Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 10, offset: 0 },
	},
	wrapperCol: { span: 15 },
}
const layout3Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 11, offset: 0 },
	},
	wrapperCol: { span: 13 },
}

export const getFormItemsMain = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view

	const weightAccepted = numToFixed(
		mainCtx.state.storages.reduce(
			(sum, cur) => (cur.status === 'Допущено' ? sum + +cur.weight : sum),
			0
		),
		2
	)
	return [
		[
			{
				layout: layout1Col,
				label: 'Продукт',
				name: 'product',
				field: mainCtx.state.prodLabel,
			},
			{
				layout: layout1Col,
				label: 'Вид',
				name: 'kind',
				field: mainCtx.state.prodCatKind,
			},
			{
				layout: layout1Col,
				label: 'Артикул',
				name: 'displayCode',
				field: mainCtx.state.articul,
			},
			{
				layout: layout1Col,
				label: 'Артикул 1С',
				name: 'vocProd1c',
				field: mainCtx.state.articul1C,
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Партия №',
				name: 'assignedPartyNum',
				field: mainCtx.state.partyNum,
			},
			{
				layout: layout2Col,
				label: 'Качество',
				name: 'vocQuality',
				field: (
					<Select
						value={
							(mainCtx.state.ctrlSpec && mainCtx.state.ctrlSpec?.vocQuality?.label) ||
							mainCtx.state.supplProd?.ctrlSpec?.vocQuality?.label ||
							(mainCtx.state.supplProd?.ctrlSpec && 'Отсутствует')
						}
						style={{ width: '100%', minWidth: '120px', maxWidth: '250px' }}
						onChange={(el) => {
							mainCtx.stateFunctions.setCtrlSpec(el)
						}}
						disabled={isDisabled}
						notFoundContent={<Col>Не найдено</Col>}
					>
						{mainCtx.selectors.ctrlSpec?.map((it) => {
							return (
								<Select.Option key={it.id} value={it.id}>
									{it.vocQuality?.displayVal || 'Отсутствует'}
								</Select.Option>
							)
						})}
					</Select>
				),
			},
			{
				layout: layout2Col,
				label: 'Спецификация',
				name: 'spec',
				field: mainCtx.state.ctrlSpec?.label,
			},
			{
				layout: layout2Col,
				label: 'Кол-во дефростаций',
				name: 'defrostCount',
				field: mainCtx.state.defrostCount,
			},
		],
		[
			{
				layout: layout3Col,
				label: 'Масса, кг допущено',
				name: 'weightAccepted',
				field: weightAccepted,
			},
			{
				layout: layout3Col,
				label: 'Дата производства',
				name: 'prodDate',
				field: mainCtx.state.ctrlDate ? moment(mainCtx.state.ctrlDate).format('DD.MM.YYYY') : null,
			},
			{
				layout: layout3Col,
				label: 'Годен до',
				name: 'ctrlShelfLife',
				field: getSellByDate(mainCtx.state),
			},
		],
	]
}

export const getFormItemsSupply = (mainCtx, modCtx) => {
	return [
		[
			{
				layout: layout1Col,
				label: '№ поставки',
				name: 'supplyDisplayCode',
				field: mainCtx.state.supplProd?.supply?.id,
			},
			{
				layout: layout1Col,
				label: 'Дата поставки',
				name: 'supplyDate',
				field: mainCtx.state.supplProd?.supply?.date
					? moment(mainCtx.state.supplProd?.supply?.date).format('DD.MM.YYYY')
					: null,
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Поставщик',
				name: 'provider',
				field: mainCtx.state.provider,
				value: mainCtx.state.provider,
			},
		],
		[
			{
				layout: layout3Col,
				label: 'Производитель',
				name: 'ctrlRawMatManuf',
				value: mainCtx.state.supplProd?.ctrlRawMatManuf?.label,
				field: mainCtx.state.supplProd?.ctrlRawMatManuf?.label,
			},
			{
				layout: layout3Col,
				label: 'Страна происхождения',
				name: 'ctrlRawMatCountry',
				value: mainCtx.state.supplProd?.ctrlRawMatCountry?.label,
				field: mainCtx.state.supplProd?.ctrlRawMatCountry?.label,
			},
		],
	]
}

export const getFormItemsControl = (mainCtx) => {
	return [
		[
			{
				layout: layout1Col,
				label: 'Протокол испытаний №',
				name: 'ctrlTestReportNum',
				value: mainCtx.state?.ctrlTestReportNum,
				field: mainCtx.state?.ctrlTestReportNum,
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Дата повт. контр.',
				name: 'repeatTestRepNum',
				value: mainCtx.state.repeatDate
					? moment(mainCtx.state.repeatDate).format(dateFormat)
					: null,
				field: mainCtx.state.repeatDate
					? moment(mainCtx.state.repeatDate).format(dateFormat)
					: null,
			},
		],
		[
			{
				layout: layout3Col,
				label: 'Протокол испытаний повт. №',
				name: 'repeatTestRepNum',
				value: mainCtx.state.repeatTestRepNum,
				field: mainCtx.state.repeatTestRepNum,
			},
		],
	]
}
