import { Button } from 'antd'
import { showConfirmModal } from '../../../../../components'
import { openNewTab } from '../../../../../utils'
import { dateFormat } from '../../../../../utils/constants/common'
import moment from 'moment'
/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (authCtx, { canViewImpl, canViewDisposal }) => {
	return [
		{
			title: '№ партии',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '15%',
		},
		{
			title: 'Артикул 1С',
			dataIndex: ['vocProd1c', 'vendorCode'],
			key: 'date',
			width: '10%',
		},
		{
			title: 'Наименование 1С',
			dataIndex: ['vocProd1c', 'description'],
			key: 'description',
			width: '20%',
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '10%',
		},
		{
			title: 'Дата',
			dataIndex: 'date',
			key: 'date',
			width: '10%',
			render: (date) => (date ? moment(date).format(dateFormat) : null),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '10%',
			render: (status, record) =>
				record.implId || record.disposalId ? (
					<Button
						type="text"
						onClick={() => {
							if ((record.implId && !canViewImpl) || (record.disposalId && !canViewDisposal)) {
								return showConfirmModal({
									title:
										'Ограничены права доступа к данному объекту. Обратитесь к администратору системы',
									okText: 'Ок',
									width: 450,
									showCancel: false,
								})
							}
							const pageUrl = record.implId
								? '/stock-operations/implementations'
								: record.disposalId
								? '/stock-operations/disposals'
								: ''
							return openNewTab(pageUrl + '/' + record.id, {
								authCtx: authCtx.state,
							})
						}}
					>
						{status}
					</Button>
				) : (
					status
				),
		},
	]
}
