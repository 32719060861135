import { getProdCatKindRawMatLabel } from '@berry/common-functions/cross-project-functions'
import { setNumField } from '@berry/front-common/for-fields'
import { Col, Select } from 'antd'
import { InputError } from '../../../../../components'

const layout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

export const getFormItems = (mainCtx) => {
	let qualityVal = null
	if (mainCtx.selectors.peresort.spec.length) {
		qualityVal = mainCtx.peresort.spec?.vocQuality?.label
	} else qualityVal = 'Отсутствует'
	return [
		[
			{
				layout: layout,
				label: 'Продукт',
				name: 'prodCat',
				rules: [{ required: true }],
				field: (
					<Select
						value={mainCtx.peresort?.prodCat?.label}
						style={{ width: '100%', minWidth: '120px' }}
						onChange={(el) => {
							mainCtx.modalFunctions.setPeresortProdCat(el)
						}}
						notFoundContent={<Col>Не найдено</Col>}
					>
						{mainCtx.selectors.peresort.prodCat.map((it) => {
							return (
								<Select.Option key={it.id} value={it.id}>
									{it.label}
								</Select.Option>
							)
						}) || []}
					</Select>
				),
			},
			{
				layout: layout,
				label: 'Вид',
				name: 'prodCatKindRawMat',
				rules: [{ required: true }],
				field: (
					<Select
						value={getProdCatKindRawMatLabel(mainCtx.peresort?.prodCatKindRawMat)}
						style={{ width: '100%', minWidth: '120px' }}
						onChange={(el) => {
							mainCtx.modalFunctions.setPeresortProdCatKindRawMat(el)
						}}
						notFoundContent={<Col>Не найдено</Col>}
					>
						{mainCtx.selectors.peresort.prodCatKindRawMat.map((it) => {
							return (
								<Select.Option key={it.id} value={it.id}>
									{getProdCatKindRawMatLabel(it)}
								</Select.Option>
							)
						}) || []}
					</Select>
				),
			},
			{
				layout: layout,
				label: 'Качество',
				name: 'spec',
				rules: [{ required: true }],
				field: (
					<Select
						value={qualityVal}
						style={{ width: '100%', minWidth: '120px' }}
						onChange={(el) => {
							mainCtx.modalFunctions.setPeresortSpec(el)
						}}
						notFoundContent={<Col>Не найдено</Col>}
					>
						{mainCtx.selectors.peresort.spec.map((it) => {
							return (
								<Select.Option key={it.id} value={it.id}>
									{it.label}
								</Select.Option>
							)
						}) || []}
					</Select>
				),
			},
			{
				layout: layout,
				label: 'Масса, кг',
				name: 'weight',
				rules: [{ required: true }],
				field: (
					<InputError
						hasError={mainCtx.formErrors?.[`peresort.weight`]}
						maxLength={8}
						value={mainCtx.peresort?.weight}
						onChange={(e) => {
							setNumField(
								e.target.value,
								mainCtx.peresort?.weight,
								mainCtx.modalFunctions.setPeresortWeight,
								'float',
								[14, 5]
							)
						}}
						style={{ width: '100%' }}
					/>
				),
			},
		],
	]
}
