import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'

import { AuthContext } from '../contexts'

import {
	AccessDeniedPage,
	LoadingPage,
	LoginPage,
	HomePage,
	VocabularyPage,
	NotFoundPage,
	CustomerList,
	CustomerItem,
	VocabularyPageRouter,
	ContractsCustomersList,
	ContractCustomerItem,
	ContractProviderItem,
	ContractsProvidersList,
	ProviderItem,
	ProviderList,
	ProductCatalogList,
	ProductCatalogItem,
	SpecificationItem,
	SpecificationList,
	SupplyRequestItem,
	SupplyRequestList,
	StaffList,
	StaffItem,
	DisplayCodesVocabularyList,
	SystemSettingsList,
	MatchSpecSettingsItem,
	MatchSupplySettingsItem,
	MatchTaskSettingsItem,
	MatchTaskPfSettingsItem,
	ChangePassword,
	ForgotPassword,
	ResetPassword,
	SendMail,
	RawMaterialSupplyList,
	RawMaterialSupplyItem,
	InputControlItem,
	SupplyUnloadItem,
	StockRawMatList,
	StockRawMatItem,
	StockSemifItem,
	StockSampleItem,
	StockSemifList,
	StockSampleList,
	StockReadyProdList,
	StockReadyProdItem,
	StockReadyProdResaleList,
	StockReadyProdResaleItem,
	StockWasteList,
	StockWasteItem,
	ProductionTaskItem,
	ProductionTaskList,
	ImplementationItem,
	ImplementationList,
	ConsolidatedReportItem,
	ConsolidatedReportList,
	Settings1c,
	StockOpsDisposalItem,
	StockOpsDisposalList,
	RelocRemoteWarehouseList,
	RelocRemoteWarehouseItem,
	RelocDefectItem,
	RelocDefectList,
	StorageCost,
	StockNotify,
} from './'
import { RouteComponentWrapper, ListPageWrapper } from '../components'
import specialVocs from './Vocabulary/router.js'
import { useAppUpdate, useSseSubscribe } from '../utils'

const ContentRouter = () => {
	const {
		state: { isLoading: authLoading },
	} = useContext(AuthContext)

	useSseSubscribe()
	useAppUpdate()

	if (authLoading) return <LoadingPage />

	return (
		<Switch>
			<Route key="/login" exact path="/login" component={LoginPage} />
			<Route exact key="/access-denied" path="/access-denied" component={AccessDeniedPage} />
			<Route exact key="/change-password" path="/change-password" component={ChangePassword} />
			<Route exact key="/forgot-password" path="/forgot-password" component={ForgotPassword} />
			<Route
				exact
				key="/reset-password/:token/:login"
				path="/reset-password/:token/:login"
				component={ResetPassword}
			/>
			<Route exact key="/send-mail/" path="/send-mail/" component={SendMail} />
			<Route
				exact
				key="/homepage"
				path="/"
				render={(props) => (
					<RouteComponentWrapper
						key="/homepageRouteComponentWrapper"
						{...props}
						child={(props) => <HomePage {...props} />}
					/>
				)}
			/>
			{specialVocs}
			<Route
				path="/vocabularies/:url"
				key="/vocabularies/:url"
				render={(props) => (
					<RouteComponentWrapper
						key="/vocabularies/:urlRouteComponentWrapper"
						{...props}
						child={(props) => <VocabularyPageRouter {...props} />}
					/>
				)}
			/>
			<Route
				exact
				key="/vocabularies"
				path="/vocabularies"
				render={(props) => (
					<RouteComponentWrapper
						key="/vocabulariesRouteComponentWrapper"
						{...props}
						child={(props) => <VocabularyPage {...props} />}
					/>
				)}
			/>
			<Route
				path="/rp/customers"
				key="/rp/customers"
				render={(props) => (
					<ListPageWrapper
						key="/rp/customersListPageWrapper"
						{...props}
						item={<CustomerItem {...props} />}
						list={<CustomerList {...props} />}
						path="/rp/customers"
					/>
				)}
			/>
			<Route
				path="/rm/providers"
				key="/rm/providers"
				render={(props) => (
					<ListPageWrapper
						key="/rm/providersListPageWrapper"
						{...props}
						item={<ProviderItem {...props} />}
						list={<ProviderList {...props} />}
						path="/rm/providers"
					/>
				)}
			/>
			<Route
				path="/rm/supply-requests"
				key="/rm/supply-requests"
				render={(props) => (
					<ListPageWrapper
						key="/rm/supply-requestsListPageWrapper"
						{...props}
						item={<SupplyRequestItem {...props} />}
						list={<SupplyRequestList {...props} />}
						path="/rm/supply-requests"
					/>
				)}
			/>
			<Route
				path="/rm/supplies"
				key="/rm/supplies"
				render={(props) => (
					<ListPageWrapper
						key="/rm/supplyListPageWrapper"
						{...props}
						item={<RawMaterialSupplyItem {...props} />}
						list={<RawMaterialSupplyList {...props} />}
						path="/rm/supplies"
					/>
				)}
			/>
			<Route
				path="/reports/consolidated-reports"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<ConsolidatedReportItem {...props} />}
						list={<ConsolidatedReportList {...props} />}
						path="/reports/consolidated-reports"
					/>
				)}
			/>
			<Route
				path="/rp/contracts-customers"
				key="/rp/contracts-customers"
				render={(props) => (
					<ListPageWrapper
						key="/rp/contracts-customersListPageWrapper"
						{...props}
						item={<ContractCustomerItem {...props} />}
						list={<ContractsCustomersList {...props} />}
						path="/rp/contracts-customers"
					/>
				)}
			/>
			<Route
				path="/rm/contracts-providers"
				key="/rm/contracts-providers"
				render={(props) => (
					<ListPageWrapper
						key="/rm/contracts-providersListPageWrapper"
						{...props}
						item={<ContractProviderItem {...props} />}
						list={<ContractsProvidersList {...props} />}
						path="/rm/contracts-providers"
					/>
				)}
			/>
			<Route
				path="/pv/product-catalog"
				key="/pv/product-catalog"
				render={(props) => (
					<ListPageWrapper
						key="/pv/product-catalogListPageWrapper"
						{...props}
						item={<ProductCatalogItem {...props} />}
						list={<ProductCatalogList {...props} />}
						path="/pv/product-catalog"
					/>
				)}
			/>
			<Route
				path="/reg/staff"
				key="/reg/staff"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<StaffItem {...props} />}
						list={<StaffList {...props} />}
						path="/reg/staff"
					/>
				)}
			/>
			<Route
				path="/stock/raw-mats"
				key="/stock/raw-mats"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<StockRawMatItem {...props} />}
						list={<StockRawMatList {...props} />}
						path="/stock/raw-mats"
					/>
				)}
			/>
			<Route
				path="/stock/samples"
				key="/stock/samples"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<StockSampleItem {...props} />}
						list={<StockSampleList {...props} />}
						path="/stock/samples"
					/>
				)}
			/>
			<Route
				path="/stock/semifs"
				key="/stock/semifs"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<StockSemifItem {...props} />}
						list={<StockSemifList {...props} />}
						path="/stock/semifs"
					/>
				)}
			/>
			<Route
				path="/stock/ready-prods"
				key="/stock/ready-prods"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<StockReadyProdItem {...props} />}
						list={<StockReadyProdList {...props} />}
						path="/stock/ready-prods"
					/>
				)}
			/>
			<Route
				path="/stock/ready-prod-resales"
				key="/stock/ready-prod-resales"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<StockReadyProdResaleItem {...props} />}
						list={<StockReadyProdResaleList {...props} />}
						path="/stock/ready-prod-resales"
					/>
				)}
			/>
			<Route
				path="/stock/wastes"
				key="/stock/wastes"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<StockWasteItem {...props} />}
						list={<StockWasteList {...props} />}
						path="/stock/wastes"
					/>
				)}
			/>
			<Route
				path="/stock-operations/disposals"
				key="/stock-operations/disposals"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<StockOpsDisposalItem {...props} />}
						list={<StockOpsDisposalList {...props} />}
						path="/stock-operations/disposals"
					/>
				)}
			/>
			<Route
				path="/stock-operations/reloc-remote-warehouse"
				key="/stock-operations/reloc-remote-warehouse"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<RelocRemoteWarehouseItem {...props} />}
						list={<RelocRemoteWarehouseList {...props} />}
						path="/stock-operations/reloc-remote-warehouse"
					/>
				)}
			/>
			<Route
				path="/stock-operations/reloc-defect"
				key="/stock-operations/reloc-defect"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<RelocDefectItem {...props} />}
						list={<RelocDefectList {...props} />}
						path="/stock-operations/reloc-defect"
					/>
				)}
			/>
			<Route
				path="/production/tasks"
				key="/production/tasks"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<ProductionTaskItem {...props} />}
						list={<ProductionTaskList {...props} />}
						path="/production/tasks"
					/>
				)}
			/>
			<Route
				path="/stock-operations/implementations"
				key="/stock-operations/implementations"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<ImplementationItem {...props} />}
						list={<ImplementationList {...props} />}
						path="/stock-operations/implementations"
					/>
				)}
			/>

			<Route
				path="/reg/specifications"
				key="/reg/specifications"
				render={(props) => (
					<ListPageWrapper
						{...props}
						item={<SpecificationItem {...props} />}
						list={<SpecificationList {...props} />}
						path="/reg/specifications"
					/>
				)}
			/>
			<Route
				exact
				path="/system-settings"
				key="/system-settings"
				render={(props) => (
					<RouteComponentWrapper {...props} child={(props) => <SystemSettingsList {...props} />} />
				)}
			/>
			<Route
				exact
				path="/system-settings/:id"
				key="/system-settings/:id"
				render={(props) => (
					<RouteComponentWrapper
						{...props}
						child={(props) => {
							if (props.match.params.id === 'specification-negotiation')
								return <MatchSpecSettingsItem {...props} />
							if (props.match.params.id === 'supply-agreement')
								return <MatchSupplySettingsItem {...props} />
							if (props.match.params.id === 'task-agreement')
								return <MatchTaskSettingsItem {...props} />
							if (props.match.params.id === 'task-pf-agreement')
								return <MatchTaskPfSettingsItem {...props} />
							if (props.match.params.id === 'settings-1c') return <Settings1c {...props} />
							if (props.match.params.id === 'storage-cost') return <StorageCost {...props} />
							if (props.match.params.id === 'stock-notify') return <StockNotify {...props} />
						}}
					/>
				)}
			/>
			<Route
				exact
				path="/pv/displaycodes-vocabulary"
				key="/pv/displaycodes-vocabulary"
				render={(props) => (
					<RouteComponentWrapper
						{...props}
						child={(props) => <DisplayCodesVocabularyList {...props} />}
					/>
				)}
			/>
			<Route
				exact
				path="/rm/supply-unloads/:id"
				key="/rm/supply-unloads/:id"
				render={(props) => (
					<RouteComponentWrapper {...props} child={(props) => <SupplyUnloadItem {...props} />} />
				)}
			/>
			<Route
				exact
				key="/rm/supply-input-controls/:id"
				path="/rm/supply-input-controls/:id"
				render={(props) => (
					<RouteComponentWrapper {...props} child={(props) => <InputControlItem {...props} />} />
				)}
			/>
			<Route exact key="/*" path="*" component={NotFoundPage} />
		</Switch>
	)
}

export default ContentRouter
