import React, { useContext, useState, useEffect } from 'react'
import { Form, Input, Button, Row, Col, Layout } from 'antd'
import { Helmet } from 'react-helmet'
import { AuthContext } from '../../contexts/auth/auth'
import { useHistory } from 'react-router-dom'
import AuthError from '../../components/AuthError/AuthError'
import Logo from '../../components/Logo/Logo'
const { validatePasswordStepByStep } = require('@berry/common-functions/validators')
const { Content } = Layout

const ChangePassword = (props) => {
	const { changePwd } = useContext(AuthContext)

	const [newPwd1, setNewPwd1] = useState('')
	const [newPwd2, setNewPwd2] = useState('')
	const [login, setLogin] = useState('')
	const [loginError, setLoginError] = useState('')
	const [oldPwd, setOldPwd] = useState('')
	const [oldPwdError, setOldPwdError] = useState('')
	const [errorPwd1, setErrorPwd1] = useState('')
	const [errorPwd2, setErrorPwd2] = useState('')
	const [errorServer, setErrorServer] = useState('')
	const [generalError, setGeneralError] = useState('')

	let inputStyle = { width: '500px', height: '50px', borderRadius: '5px' }
	const inputStyleError = {
		width: '500px',
		height: '50px',
		borderRadius: '5px',
		border: '1px solid red',
	}
	const styleBtn = {
		fontWeight: 600,
		width: '500px',
		height: '50px',
		alignSelf: 'center',
		borderRadius: '5px',
	}

	useEffect(() => {
		validatePwds(
			newPwd1,
			newPwd2,
			setErrorPwd1,
			setErrorPwd2,
			setErrorServer,
			setGeneralError,
			setLoginError,
			setOldPwdError
		)
	}, [newPwd1, newPwd2, login, oldPwd])
	const history = useHistory()

	return (
		<Layout>
			<Helmet>
				<title>Смена пароля</title>
			</Helmet>
			<Logo />
			<Content
				style={{
					background: 'url(/login.jpg) no-repeat center center fixed',
					backgroundSize: 'cover',
					display: 'flex',
					justifyContent: 'space-around',
					height: '100vh',
				}}
			>
				<Row justify="center" align="middle" style={{ position: 'relative', bottom: '80px' }}>
					<Col style={{ width: '500px' }}>
						<Col>
							<Row
								justify="center"
								style={{
									fontSize: 25,
									fontWeight: '700',
									paddingBottom: '10px',
									color: 'rgb(3 52 97)',
								}}
							>
								ИЗМЕНЕНИЕ ПАРОЛЯ
							</Row>
							<Row
								justify="center"
								style={{
									fontSize: 23,
									fontWeight: 700,
									textAlign: 'center',
									paddingBottom: '30px',
									color: 'rgb(3 52 97)',
									lineHeight: '1',
								}}
							>
								После изменения пароля произойдет выход из аккаунта на всех устройствах, где вошли с
								текущим паролем
							</Row>
						</Col>
						<Form
							colon={false}
							onFinish={async (data) => {
								try {
									if (newPwd1 === '' && newPwd2 === '' && oldPwd === '' && login === '') {
										setGeneralError('Введите данные')
									} else if (oldPwd === '') {
										if (newPwd1 === '') {
											setErrorPwd1('Введите пароль')
										}
										if (newPwd2 === '') {
											setErrorPwd2('Введите пароль')
										}
										setOldPwdError('Введите данные')
									} else if (login === '') {
										if (newPwd1 === '') {
											setErrorPwd1('Введите пароль')
										}
										if (newPwd2 === '') {
											setErrorPwd2('Введите пароль')
										}

										setLoginError('Введите данные')
									} else if (newPwd1 === '' && newPwd2 === '') {
										setErrorPwd1('Введите данные')
										setErrorPwd2('Введите данные')
									} else if (newPwd1 === '') {
										setErrorPwd1('Введите данные')
									} else if (newPwd2 === '' && errorPwd1 === '') {
										setErrorPwd2('Введите данные')
									} else {
										validatePwds(newPwd1, newPwd2, setErrorPwd1, setErrorPwd2)
									}

									await changePwd(data)
								} catch (err) {
									if (errorPwd1 === '' && errorPwd2 === '' && newPwd1 !== '' && newPwd2 !== '') {
										setErrorServer(err.message)
									}
									console.log(err.message, 5)
								}
							}}
						>
							<Col
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-end',
								}}
							>
								<Form.Item name="login">
									<Input
										type="text"
										onKeyDown={(e) => {
											if (isCyrillics(e.key)) {
												e.preventDefault()
											}
										}}
										style={generalError || loginError ? inputStyleError : inputStyle}
										placeholder="Логин"
										onChange={(e) => setLogin(e.target.value)}
									/>
								</Form.Item>
								<Form.Item name="oldPwd">
									<Input
										type="password"
										onKeyDown={(e) => {
											if (isCyrillics(e.key)) {
												e.preventDefault()
											}
										}}
										style={generalError || oldPwdError ? inputStyleError : inputStyle}
										placeholder="Текущий пароль"
										onChange={(e) => setOldPwd(e.target.value)}
									/>
								</Form.Item>
								<Form.Item name="newPwd1">
									<Input
										type="password"
										onKeyDown={(e) => {
											if (isCyrillics(e.key)) {
												e.preventDefault()
											}
										}}
										onChange={(e) => setNewPwd1(e.target.value)}
										style={errorPwd1 || generalError ? inputStyleError : inputStyle}
										placeholder="Новый пароль"
									/>
								</Form.Item>
								<Form.Item name="newPwd2">
									<Input
										type="password"
										onKeyDown={(e) => {
											if (isCyrillics(e.key)) {
												e.preventDefault()
											}
										}}
										onChange={(e) => setNewPwd2(e.target.value)}
										style={errorPwd2 || generalError ? inputStyleError : inputStyle}
										placeholder="Новый пароль еще раз"
									/>
								</Form.Item>
								{(loginError ||
									oldPwdError ||
									errorPwd2 ||
									errorPwd1 ||
									errorServer ||
									generalError) && (
									<AuthError
										error={
											loginError ||
											oldPwdError ||
											errorPwd2 ||
											errorPwd1 ||
											errorServer ||
											generalError ||
											loginError
										}
									/>
								)}
								<Button
									htmlType="submit"
									type="btn"
									style={{
										...styleBtn,
										background: 'rgb(3 52 97)',
										color: 'white',
										marginBottom: '20px',
									}}
								>
									СОХРАНИТЬ
								</Button>
								<Button
									onClick={() => {
										history.push('/login')
									}}
									type="button"
									style={{
										...styleBtn,
										border: '2px solid rgb(3, 52, 97)',
									}}
								>
									ОТМЕНИТЬ
								</Button>
							</Col>
						</Form>
					</Col>
				</Row>
			</Content>
		</Layout>
	)
}

export default ChangePassword

export const validatePwds = (
	newPwd1,
	newPwd2,
	setErrorPwd1,
	setErrorPwd2,
	setErrorCurPwd = () => {},
	setGeneralError = () => {},
	setLoginError = () => {},
	setOldPwdError = () => {}
) => {
	setGeneralError('')
	setErrorCurPwd('')
	setLoginError('')
	setOldPwdError('')
	if (newPwd1 !== '') {
		if (validatePasswordStepByStep(newPwd1) !== undefined) {
			setErrorPwd1(validatePasswordStepByStep(newPwd1))
		}
	} else {
		setErrorPwd1('')
	}

	if (newPwd2 !== '' && newPwd1 !== '') {
		if (newPwd1 !== newPwd2) {
			setErrorPwd2('Пароли не воспадают')
		} else {
			setErrorPwd2('')
		}
	} else {
		setErrorPwd2('')
	}
}

export const isCyrillics = (val) => {
	return /[А-Яа-я]/.test(val)
}
