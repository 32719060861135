import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { getColumnSearchProps, sorter } from '../../../utils'
import { dateFormat } from '../../../utils/constants/common'
import { colorizeIntegrationListStatus } from '../../../utils/helpers/for-integration'
import moment from 'moment'

export const getColumns = (props) => {
	const { fromServerFilters } = props

	return [
		{
			title: 'Смена',
			dataIndex: 'workingTime',
			key: 'workingTime',
			width: '5.04%',
			render: (text) => <span style={{ wordBreak: 'break-all' }}>{text}</span>,
			...getColumnSearchProps(fromServerFilters.workingTime),
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			width: '3.88%',
			sorter: sorter('number', 'id'),
			...getColumnSearchProps(fromServerFilters.id),
		},
		{
			title: 'Дата производства',
			dataIndex: 'date',
			key: 'date',
			width: '9.4%',
			render: (date) => (date ? moment(date).format(dateFormat) : null),
			sorter: sorter('date', 'date'),
			...getColumnSearchProps(fromServerFilters.date, undefined, null, true),
		},
		{
			title: 'ID ДЗ',
			dataIndex: 'idContrOrder',
			key: 'idContrOrder',
			width: '4.6%',
			sorter: sorter('number', 'idContrOrder'),
			...getColumnSearchProps(fromServerFilters.idContrOrder),
		},
		{
			title: 'Операция',
			dataIndex: 'operation',
			key: 'operation',
			width: '7%',
			...getColumnSearchProps(fromServerFilters.operation),
		},
		{
			title: 'Партия №',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '7.83%',
			sorter: sorter('string', 'partyNum'),
			...getColumnSearchProps(fromServerFilters.partyNum),
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			key: 'articul',
			width: '7.31%',
			...getColumnSearchProps(fromServerFilters.articul),
		},
		{
			title: 'Артикул 1С',
			dataIndex: 'articul1C',
			key: 'articul1C',
			width: '7.32%',
			...getColumnSearchProps(fromServerFilters.articul1C),
		},
		{
			title: 'Продукт',
			dataIndex: 'productLabel',
			key: 'productLabel',
			width: '8.15%',
			sorter: sorter('string', 'productLabel'),
			...getColumnSearchProps(fromServerFilters.productLabel),
		},
		{
			title: 'Вид',
			dataIndex: 'prodCatKind',
			key: 'prodCatKind',
			width: '19.8%',
			sorter: sorter('string', 'prodCatKind'),
			...getColumnSearchProps(fromServerFilters.prodCatKind),
		},
		{
			title: 'Масса, кг',
			key: 'weight',
			dataIndex: 'weight',
			children: [
				{
					title: 'Общая',
					key: 'weight',
					dataIndex: 'weight',
					width: '4.02%',
				},
				{
					title: 'Выпущ.',
					dataIndex: 'weightOut',
					key: 'weightOut',
					width: '4.02%',
				},
				{},
			],
		},
		{
			title: '% вых.',
			key: 'percentOut',
			width: '4.02%',
			render: (record) =>
				record.weight ? numToFixed((record.weightOut / record.weight) * 100, 2) : 0,
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '7.61%',
			render: (_, record) => colorizeIntegrationListStatus(record.status),
			...getColumnSearchProps(fromServerFilters.status),
		},
	]
}
