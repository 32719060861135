import { getColumnSearchProps } from '../../../utils'
export const getColumns = (data, isDevAdmin) => [
	{
		title: 'Номер',
		dataIndex: 'displayCode',
		key: 'displayCode',
		width: '10%',
		...getColumnSearchProps([...new Set(data.map((e) => e.displayCode))], undefined, 'displayCode'),
	},
	{
		title: 'Объект',
		dataIndex: 'outdoorWh',
		key: 'outdoorWh',
		width: '20%',
		...getColumnSearchProps([...new Set(data.map((e) => e.outdoorWh))], undefined, 'outdoorWh'),
	},
	{
		title: 'Тип помещения',
		dataIndex: 'type',
		key: 'type',
		width: '25%',
		...getColumnSearchProps([...new Set(data.map((e) => e.type))], undefined, 'type'),
	},
	{
		title: 'Хранение',
		dataIndex: 'storage',
		key: 'storage',
		width: '25%',
		render: (_, record) => {
			return record.storage.split(',').join(', ')
		},
		...getColumnSearchProps(
			[...new Set(...data.map((e) => e.storage.split(',')))],
			undefined,
			'storagesForFilter'
		),
	},
	...(isDevAdmin
		? [
				{
					title: 'Идентификатор 1С',
					dataIndex: 'uuid1C',
					key: 'uuid1C',
					width: '15%',
				},
		  ]
		: []),
]
