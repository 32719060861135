const host =
	window?._env_?.PROTOCOL === 'HTTPS'
		? 'https://'
		: 'http://' + window?._env_?.REACT_APP_HOST + ':' + window?._env_?.REACT_APP_API_PORT
const services = {
	'office-ms':
		window?._env_?.REACT_APP_STAND === 'develop' || process?.env?.REACT_APP_STAND === 'develop'
			? `http://localhost:1294`
			: `${window?._env_?.PROTOCOL === 'HTTPS' ? 'https://' : 'http://'}office-ms.${
					window?._env_?.REACT_APP_STAND
			  }.${window?._env_?.REACT_APP_HOST}.nip.io`,
	'auth-ms':
		window?._env_?.REACT_APP_STAND === 'develop' || process?.env?.REACT_APP_STAND === 'develop'
			? `http://localhost:1297`
			: `${window?._env_?.PROTOCOL === 'HTTPS' ? 'https://' : 'http://'}auth-ms.${
					window?._env_?.REACT_APP_STAND
			  }.${window?._env_?.REACT_APP_HOST}.nip.io`,
}
// const host = 'http://localhost:1294'
export { host, services }
