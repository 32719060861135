import { paintTextByStatus } from '../../../RawMat/RawMatItem/StorageTab/storage-tab'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { dateFormat } from '../../../../../utils/constants/common'
import moment from 'moment'
import { Status } from '../../../../../components'
import clickableStatus from '../../../../../utils/helpers/clickableStatus'

export const getDisplayColumns = (mainCtx, modCtx, authCtx, userDataCtx) => {
	return [
		{
			title: '№ помещения',
			dataIndex: ['room', 'displayCode'],
			key: 'displayCode',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '12.48%',
			render: (_, record) => paintTextByStatus(numToFixed(record.weight, 2), record),
		},
		{
			title: 'Тип упаковки',
			dataIndex: ['vocContType', 'labelShort'],
			key: 'vocContType',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Дата',
			dataIndex: 'date',
			key: 'date',
			width: '7.41%',
			render: (date, record) =>
				paintTextByStatus(date ? moment(date).format(dateFormat) : null, record),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '12.48%',
			render: (status) => <Status status={status} />,
			onCell: clickableStatus(authCtx, {}, userDataCtx),
		},
	]
}
