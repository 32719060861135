import { getColumnSearchProps, sorter } from '../../../../utils'
import { getProdNameByProviderQuota } from '@berry/common-functions/cross-project-functions'
import { getProviders } from '../../../SupplyRequest/SupplyRequestList/supply-request-list'
import { dateFormat } from '../../../../utils/constants/common'
import moment from 'moment'
import { Status } from '../../../../components'

/**
 * получить продукты заявки
 * @param {Object} products
 * @returns {String}
 */
export const getProducts = (inState) => {
	return inState.products
		?.map((e) => {
			return getProdNameByProviderQuota(e.contrQuota?.rmProvProd || e?.order?.rmProvProd)
		})
		.filter((e) => e)
		.join(', ')
}

/**
 * @param {Object} inArr - массив с записями таблицы
 */
export const getColumns = (props) => {
	const { fromServerFilters } = props
	return [
		{
			title: 'Номер заявки',
			dataIndex: 'displayCode',
			key: 'displayCode',
			width: '10%',
			sorter: sorter('number', 'displayCode'),
		},
		{
			title: 'Дата заявки',
			dataIndex: 'date',
			key: 'date',
			width: '10%',
			...getColumnSearchProps(fromServerFilters.date, undefined, null, true),
			sorter: sorter('date', 'date', 'YYYY-MM-DD'),
		},
		{
			title: 'Номер договора',
			key: 'contractNums',
			width: '10%',
			render: (record) => [...new Set(record.products.map((p) => p.contract.num))].join(', '),
			...getColumnSearchProps(fromServerFilters.contractNums || []),
		},
		{
			title: 'Дата поставки план.',
			dataIndex: 'supplyDatePlan',
			key: 'supplyDatePlan',
			width: '10%',
			render: (supplyDatePlan) =>
				supplyDatePlan ? moment(supplyDatePlan).format(dateFormat) : null,
			...getColumnSearchProps(fromServerFilters.supplyDatePlan, undefined, null, true),
			sorter: sorter('date', 'supplyDatePlan', 'YYYY-MM-DD'),
		},
		{
			title: '№ машины/прицепа',
			dataIndex: 'vehicleNum',
			key: 'vehicleNum',
			width: '12.17%',
			...getColumnSearchProps(fromServerFilters.vehicleNum),
		},
		{
			title: 'Продукт',
			dataIndex: 'preparedProducts',
			key: 'forFilterProducts',
			width: '20%',
			render: (_, record) => getProducts(record),
			...getColumnSearchProps(fromServerFilters.forFilterProducts || []),
			sorter: sorter('string', 'preparedProducts'),
		},
		{
			title: 'Поставщик',
			dataIndex: 'preparedProviders',
			key: 'contractProviders',
			width: '15%',
			...getColumnSearchProps(fromServerFilters.contractProviders || []),

			render: (_, record) => getProviders(record),
			sorter: sorter('string', 'preparedProviders'),
		},
		{
			title: 'Склад приемки',
			dataIndex: 'outdoorWhLabel',
			key: 'outdoorWhLabel',
			width: '15%',
			...getColumnSearchProps(fromServerFilters.outdoorWhLabel),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '10%',
			render: (status) => <Status status={status} />,
			...getColumnSearchProps(fromServerFilters.status),
		},
	]
}
