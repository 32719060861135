import React, { useContext } from 'react'
import { AuthContext, ModContext, ModProvider, UserDataContext } from '../../../../contexts'
import { Main } from '../../../../layouts'
import { MainSemiKindItemContext, Provider } from './provider/main'
import { Card, Row, Col, Form } from 'antd'
import { ActionButtons, CustomForm, HeaderMenu, TabList } from '../../../../components'
import {
	actBtnOnClose,
	actBtnOnDelete,
	modes,
	openNewTab,
	goToItem,
	actBtnOnEdit,
} from '../../../../utils'
import { showConfirmModal } from '../../../../components'
import { getFormItems } from './main-semi-kind-item'
import { useHistory } from 'react-router'
import KindTab from './KindTab/KindTab'

const pageUrl = '/vocabularies/main-semifinished-kind'

const MainSemiKindItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Виды основных ПФ">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(MainSemiKindItemContext)
	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	let history = useHistory()
	if (mainCtx.state.__notFound) {
		history.replace(pageUrl)
	}
	const [form] = Form.useForm()
	return (
		<>
			<HeaderMenu
				breadcrumbsTitle="Виды основных ПФ"
				recordLabel={mainCtx.state.vocProcess?.displayVal || 'Новый процесс'}
			>
				<Row>
					<ActionButtons
						rights={{
							Справочники: {
								'Виды основных ПФ': {
									edit: true,
								},
							},
						}}
						formName={'mainSemiKind'}
						onClose={() => {
							actBtnOnClose({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onEdit={async () => {
							return (
								(await actBtnOnEdit({
									history,
									pageUrl: history.location.pathname,
									showConfirmModal,
									currentRights: currentRights,
									openNewPage: (url) =>
										openNewTab(url, {
											authCtx: authCtx.state,
										}),
								})) || modCtx.set(modes.edit)
							)
						}}
						canDelete={modCtx.mod !== modes.new}
						onDelete={async () => {
							await actBtnOnDelete({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								deps: mainCtx.delEditDeps?.['voc_editable_main_semi_kind'][mainCtx.state.id],
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
								currentRights,
							})
						}}
						onCancel={mainCtx.reset}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							form={form}
							onFinish={async () => {
								const res = await mainCtx.serverEdit()
								if (res.method === 'POST' && res.id) {
									if (history && pageUrl) {
										goToItem(history, { url: pageUrl, id: res.id }, { refresh: true })
									}
								}
							}}
							name="mainSemiKind"
							fields={[{ name: 'vocProcess', value: mainCtx.state.vocProcess }]}
							columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
							items={getFormItems(mainCtx, modCtx)}
						/>
					</Col>
				</Row>
			</Card>

			<TabList tabs={[{ key: 0, title: 'Вид основного ПФ', component: <KindTab /> }]} />
		</>
	)
}
export default MainSemiKindItem
