import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Card } from 'antd'
import { Provider, RelocRemoteWarehouseListMainContext } from './provider/main'
import { Main } from '../../../../layouts'
import { goToItem } from '../../../../utils'
import { HeaderMenu, StatefulTable } from '../../../../components'
import { getColumns } from './reloc-remote-wh'

const pageUrl = '/stock-operations/reloc-remote-warehouse'

const RelocRemoteWarehouseList = () => (
	<Provider>
		<Main title="Перемещения удал. склад">
			<RelocRemoteWarehouseListTable />
		</Main>
	</Provider>
)

const RelocRemoteWarehouseListTable = () => {
	const mainCtx = useContext(RelocRemoteWarehouseListMainContext)
	const history = useHistory()
	return (
		<>
			<HeaderMenu
				rights={{
					'АРМ офиса': {
						'Перемещения удал. склад': {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle="Перемещения удал. склад"
				search={mainCtx.setSearch}
				canCreate
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					searchFields={mainCtx.searchFields}
					columnProps={{ mainCtx, fromServerFilters: mainCtx.state.fromServerFilters }}
					url={pageUrl}
				/>
			</Card>
		</>
	)
}

export default RelocRemoteWarehouseList
