import {
	getDataFromSupplyProdRmProvProd,
	numToFixed,
} from '@berry/common-functions/cross-project-functions'
import React, { useContext, useEffect, useRef } from 'react'
import { ListContext, SyncDepsContext } from '../../../../../contexts'
import {
	startUseEffectHandlerForList,
	everyOtherTimeUseEffectHandlerForList,
	axios,
} from '../../../../../utils'
import moment from 'moment'
import lodash from 'lodash'
import { getCommonProviderModalFunctions } from '../../../../../utils/helpers/generators'
import { prepareParamsForStockRelocationModal } from '../../../../../utils/helpers/cross-pages-funcs'

const dataUrl = '/stock/raw-mats'

export const reducer = (state) => {
	return {
		...state,
	}
}

export const getStatus = (relocationModal, item) => {
	if (item.status === 'Дефростация' && !relocationModal.isDefrost && !relocationModal.isWriteOff) {
		return 'Допущено'
	}
	if (relocationModal?.isDefrost) return 'Дефростация'
	if (relocationModal?.isWriteOff) return 'Списано'
	return item.status
}
const StockRawMatListMainContext = React.createContext()
StockRawMatListMainContext.displayName = 'StockRawMatListMainContext'

const Provider = (props) => {
	const {
		state: { page, pageSize, mainFilter, filters, sorter, isInStock, weightFrom, weightTo },
		setTotal,
	} = useContext(ListContext)
	const syncDepsCtx = useContext(SyncDepsContext)
	const { children } = props
	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: {
			defrostCount: [],
			idContrOrder: [],
			partyNum: [],
			displayCode: [],
			vendorCode: [],
			prodCat: [],
			prodCatKind: [],
			quality: [],
			pkgType: [],
			nettoPkgWeight: [],
			provider: [],
			manuf: [],
			roomNum: [],
			status: [],
		},
		isInitialized: false,
		isLoading: false,
		removeRegisterModal: { __isOpen: false },
		relocationModal: { __isOpen: false },
		changeStatusModal: { __isOpen: false },
		repackModal: { __isOpen: false },
		fromServerSelectors: {},
		isModalsServerEditSend: false,
		stockNotify: {},
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = newState
		dispatch({ ...state })
	}

	const resetModals = () => {
		executeDispatch({
			...stateRef.current,
			removeRegisterModal: { __isOpen: false },
			relocationModal: { __isOpen: false },
			changeStatusModal: { __isOpen: false },
			repackModal: { __isOpen: false },
		})
	}

	const modalSetOpen = (val, modalName) => {
		executeDispatch({ ...stateRef.current, [modalName]: { isOpen: val } })
	}
	const selectors = {
		removeRegisterModal: { customer: stateRef.current.fromServerSelectors.customers },
		repackModal: {
			vocContType: stateRef.current.repackModal.storage
				? stateRef.current.fromServerSelectors.vocContType.filter(
						(cont) => cont.labelShort !== stateRef.current.repackModal.storage.pkgType
				  )
				: stateRef.current.fromServerSelectors.vocContType,
		},
		relocationModal: {
			roomNum: stateRef.current.fromServerSelectors.room,
		},
		changeStatusModal: {
			blockCauses: stateRef.current.fromServerSelectors.blockCause,
		},
	}

	useEffect(() => {
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				isInStock,
				sorter,
				mainFilter,
				filters,
				offset: pageSize * page - pageSize,
				limit: pageSize,
				weightFrom,
				weightTo,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [])

	const everyOtherTimeUseEffectHandlerForListFunc = () =>
		everyOtherTimeUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				offset: pageSize * page - pageSize,
				limit: pageSize,
				sorter,
				mainFilter,
				filters,
				search: stateRef.current.search,
				isInStock,
				isSelectorsLoaded: true,
				weightFrom,
				weightTo,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	useEffect(() => {
		everyOtherTimeUseEffectHandlerForListFunc()
	}, [
		page,
		sorter,
		mainFilter,
		filters,
		stateRef.current.search,
		syncDepsCtx.state.reloadUuids['office-ms'],
		isInStock,
		stateRef.current.search,
		weightFrom,
		weightTo,
	])

	const modalsServerEdit = async () => {
		let options = { url: dataUrl, method: 'PUT' }
		const { removeRegisterModal, relocationModal, repackModal, changeStatusModal } =
			stateRef.current
		let currentModal = null
		if (removeRegisterModal.storage) currentModal = removeRegisterModal
		if (relocationModal.storage) currentModal = relocationModal
		if (repackModal.storage) currentModal = repackModal
		if (changeStatusModal.storage) currentModal = changeStatusModal

		const item = currentModal.storage.storages.find(
			(el) => el.id === currentModal.storage.idStorage
		)
		let body = {}
		if (removeRegisterModal.storage) {
			if (removeRegisterModal.weight === removeRegisterModal.storage.weight.toString()) {
				body = {
					id: removeRegisterModal.storage.id,
					storages: [
						{
							id: removeRegisterModal.storage.idStorage,
							weight: removeRegisterModal.weight,
							status: 'Отправлено в 1С',
							oldStatus: item.status,
							newStatus: removeRegisterModal.sale ? 'Продажа' : 'Возврат',
							date: moment(),
						},
					],
					to1cData: {
						counteragent: removeRegisterModal.sale
							? removeRegisterModal.customer
							: removeRegisterModal.storage._fullProvider,
						isSale: removeRegisterModal.sale,
					},
				}
			}
			if (+removeRegisterModal.weight < +removeRegisterModal.storage.weight) {
				body = {
					id: removeRegisterModal.storage.id,
					storages: [
						{
							id: removeRegisterModal.storage.idStorage,
							weight: removeRegisterModal.storage.weight - removeRegisterModal.weight,
						},
						{
							...item,
							id: null,
							idParent: removeRegisterModal.storage.idStorage,
							weight: removeRegisterModal.weight,
							date: moment(),
							newStatus: removeRegisterModal.sale ? 'Продажа' : 'Возврат',
							isPermanent: false,
						},
					],
					to1cData: {
						counteragent: removeRegisterModal.sale
							? removeRegisterModal.customer
							: removeRegisterModal.storage._fullProvider,
						isSale: removeRegisterModal.sale,
					},
				}
				delete body.storages[1].id
			}
			body.articul = getDataFromSupplyProdRmProvProd(removeRegisterModal.storage.supplProd)?.articul
			options = { dataUrl, url: '/stock/raw-mats/remove-register', method: 'PUT' }
		}

		if (relocationModal.storage) {
			const preparedParams = prepareParamsForStockRelocationModal(
				relocationModal,
				{
					dataUrl,
					wasteKey: 'idStockRawMat',
					status: getStatus(relocationModal, item),
				},
				stateRef.current.fromServerSelectors.vocProduct1C
			)
			options = preparedParams.options
			body = preparedParams.body
			body.articul = getDataFromSupplyProdRmProvProd(relocationModal.storage.supplProd)?.articul
			body.isNeedCreateIntegrHistRec =
				item.room.roomType === 'Внутренний' && relocationModal.roomNum.roomType === 'Внешний'
					? false
					: true
		}

		if (repackModal.storage) {
			if (Number(repackModal.weight) === Number(repackModal.storage.weight)) {
				body = {
					id: repackModal.storage.id,
					storages: [
						{
							id: repackModal.storage.idStorage,
							vocContType: repackModal.vocContType,
							nettoPkgWeight: repackModal.nettoPkgWeight,
							date: moment(),
						},
					],
				}
			}
			if (Number(repackModal.weight) < Number(repackModal.storage.weight)) {
				body = {
					id: repackModal.storage.id,
					storages: [
						{
							id: repackModal.storage.idStorage,
							weight: repackModal.storage.weight - repackModal.weight,
						},
						{
							...item,
							id: null,
							weight: repackModal.weight,
							vocContType: repackModal.vocContType,
							isStandPkg: repackModal.isStandPkg ? false : true,
							nettoPkgWeight: repackModal.nettoPkgWeight,
							date: moment(),
						},
					],
				}
			}
		}

		if (changeStatusModal.storage) {
			if (Number(changeStatusModal.weight) === Number(changeStatusModal.storage.weight)) {
				body = {
					id: changeStatusModal.storage.id,
					storages: [
						{
							id: changeStatusModal.storage.idStorage,
							status: changeStatusModal.status,
							blockCauses: changeStatusModal.blockCauses?.map((bk) => {
								return {
									blockCause: { ...bk },
								}
							}),
							date: moment(),
						},
					],
					isChangeStatus: true,
				}
			}
			if (Number(changeStatusModal.weight) < Number(changeStatusModal.storage.weight)) {
				body = {
					id: changeStatusModal.storage.id,
					storages: [
						{
							id: changeStatusModal.storage.idStorage,
							weight: changeStatusModal.storage.weight - changeStatusModal.weight,
						},
						{
							...item,
							id: null,
							weight: changeStatusModal.weight,
							status: changeStatusModal.status,
							blockCauses: changeStatusModal.blockCauses?.map((bk) => {
								return {
									blockCause: { ...bk },
								}
							}),
							date: moment(),
						},
					],
					isChangeStatus: true,
				}
			}
		}
		executeDispatch({ ...stateRef.current, isModalsServerEditSend: true })
		const resp = await axios.put(options.url, body)
		executeDispatch({ ...stateRef.current, isModalsServerEditSend: false })
		everyOtherTimeUseEffectHandlerForListFunc()
		resetModals()
		return { method: options.method, id: resp }
	}

	const dropStatus = async (el) => {
		let storages = []
		if (el.parent) {
			storages = [
				{
					id: el.idStorage,
					__toDelete: true,
				},
				{
					id: el.parent.id,
					weight: +el.weight + +el.parent.weight,
				},
			]
		} else {
			storages = [
				{
					id: el.idStorage,
					status: el.oldStatus,
					oldStatus: null,
				},
			]
		}
		const resp = await axios.put(dataUrl, {
			id: el.id,
			storages,
		})
		if (resp.data.data.id) everyOtherTimeUseEffectHandlerForListFunc()
		return { method: 'PUT', id: resp.data.data.id }
	}

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}
	const filterStatuses = [
		'Допущено',
		'Ответ. хранение',
		'Дефростация',
		'В производстве',
		'Удержано',
		'Заблокировано',
		'Неполный комплект документов',
		'На оформлении в 1С',
		'Отправляется в 1С',
		'Бронь',
		'Отклонено 1С',
		'Ошибка отправки в 1С',
		'К отгрузке',
		'Отправлено в 1С',
	]

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		selectors,
		[
			{ field: 'weight', updateVal: 'common', modalName: 'removeRegisterModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'removeRegisterModal' },
			{ field: 'sale', updateVal: 'common', modalName: 'removeRegisterModal' },
			{ field: 'refund', updateVal: 'common', modalName: 'removeRegisterModal' },
			{ field: 'customer', updateVal: 'obj', modalName: 'removeRegisterModal' },

			{ field: 'weight', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'isDefrost', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'isWriteOff', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'roomNum', updateVal: 'obj', modalName: 'relocationModal' },

			{ field: 'status', updateVal: 'common', modalName: 'changeStatusModal' },
			{ field: 'weight', updateVal: 'common', modalName: 'changeStatusModal' },
			{ field: 'blockCauses', updateVal: 'common', modalName: 'changeStatusModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'changeStatusModal' },

			{ field: 'weight', updateVal: 'common', modalName: 'repackModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'repackModal' },
			{ field: 'nettoPkgWeight', updateVal: 'common', modalName: 'repackModal' },
			{ field: 'isStandPkg', updateVal: 'common', modalName: 'repackModal' },
			{ field: 'vocContType', updateVal: 'obj', modalName: 'repackModal' },
		]
	)

	const value = {
		state: stateRef.current,
		toDisplayDataList: stateRef.current.fromServer,
		fromServerTotalSum: stateRef.current.fromServerTotalSum,
		selectors,
		isModalsServerEditSend: stateRef.current.isModalsServerEditSend,
		removeRegisterModal: lodash.cloneDeep(stateRef.current.removeRegisterModal),
		relocationModal: lodash.cloneDeep(stateRef.current.relocationModal),
		changeStatusModal: lodash.cloneDeep(stateRef.current.changeStatusModal),
		repackModal: lodash.cloneDeep(stateRef.current.repackModal),
		modalsServerEdit,
		resetModals,
		modalSetOpen,
		modalFunctions,
		dropStatus,
		// searchFields,
		filterStatuses,
		setSearch,
	}

	return (
		<StockRawMatListMainContext.Provider value={value}>
			{children}
		</StockRawMatListMainContext.Provider>
	)
}

export const prepareList = (data) => {
	let preparedData = []
	data.forEach((it) => {
		it.storages.forEach((el) => {
			if (+el.weight === 0) return
			const { prodCatLabel, prodCatKindLabel, articul, articul1C } =
				getDataFromSupplyProdRmProvProd(it.supplProd)
			it = {
				...it,
				vocContType: el.vocContType,
				nettoPkgWeight: numToFixed(el.nettoPkgWeight, 2),
				weight: numToFixed(Number(el.weight), 2),
				assignedPartyNum: it.supplProd.assignedPartyNum,
				assignedPartyNumForSorter: it.supplProd.assignedPartyNum.slice(5, 9),
				displayCode: articul,
				vendorCode: articul1C,
				product: prodCatLabel,
				_idProduct:
					it.supplProd.contrQuota?.rmProvProd.prodCat.id ||
					it.supplProd.order.rmProvProd.prodCat.id,
				prodCatKind: prodCatKindLabel,
				quality: it.supplProd.ctrlSpec?.vocQuality?.label || null,
				pkgType: el.vocContType.labelShort,
				provider:
					it.supplProd.contrQuota?.contract.provider?.label ||
					it.supplProd.order.contract.provider?.label,
				_fullProvider:
					it.supplProd.contrQuota?.contract.provider || it.supplProd.order.contract.provider,
				manuf: it.supplProd.ctrlRawMatManuf?.label,
				shelfLife: `${it.supplProd.ctrlShelfLife} ${it.supplProd.ctrlMeasure.labelShort}`,
				ctrlShelfLife: it.supplProd.ctrlShelfLife,
				roomNum: el.room?.displayCode,
				roomType: el.room?.roomType,
				status: el.status,
				idStorage: el.id,
				isPermanent: el.isPermanent,
				idOrder: it.supplProd?.order?.id || null,
				sellBy:
					it.supplProd.ctrlMeasure.labelShort === 'сут'
						? moment(it.supplProd.ctrlDate).add(it.supplProd.ctrlShelfLife, 'days')
						: moment(it.supplProd.ctrlDate).add(it.supplProd.ctrlShelfLife, 'months'),
			}
			preparedData = [...preparedData, it]
		})
	})
	return preparedData
}
export { Provider, StockRawMatListMainContext }
