import { getColumnSearchProps, sorter, renderPossiblyEmptyString } from '../../../utils'

/**
 * @param {Object} inArr - массив с записями таблицы
 */
export const getColumns = (props) => {
	const { fromServerFilters } = props
	return [
		{
			title: 'Поставщик',
			dataIndex: 'label',
			key: 'label',
			...getColumnSearchProps(fromServerFilters.label),
			sorter: sorter('string', 'label'),
			width: '40%',
			render: renderPossiblyEmptyString,
		},
		{
			title: 'ИНН',
			dataIndex: 'inn',
			key: 'inn',
			width: '20%',
			render: renderPossiblyEmptyString,
		},
		{
			title: 'КПП',
			dataIndex: 'kpp',
			key: 'kpp',
			width: '20%',
			render: renderPossiblyEmptyString,
		},
		{
			title: 'Общий балл',
			dataIndex: 'sumScore',
			key: 'sumScore',
			width: '10%',
			...getColumnSearchProps(fromServerFilters.sumScore),
		},
		{
			title: 'Классификация',
			dataIndex: 'classification',
			key: 'classification',
			width: '10%',
			...getColumnSearchProps(fromServerFilters.classification),
		},
	]
}
