import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'
import { Button, Typography, Upload } from 'antd'
import { ReactComponent as AddPhotoIcon } from '../../../../assets/svg/addPhoto.svg'
import { getEtalon } from '../specification-item'
import { calcSpan } from '../modals/utils'
import { Carousel, EditDelBtns, InputError } from '../../../../components'
import { getSpecParamDeviation } from '@berry/common-functions/cross-project-functions'
import '../../../../index.css'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (
	addPhoto,
	removePhoto,
	setEditModalItem,
	setIsModalOpen,
	isFullScreen,
	setIsFullScreen,
	disabled,
	currentDataSource = [],
	formErrors
) => {
	return [
		{
			title: 'Раздел',
			dataIndex: ['vocSpecParamParam', 'spec', 'displayVal'],
			key: 'section',
			width: '18%',
			defaultSordOrder: 'ascend',
			className: 'elemAlignTop',
			onCell: (record) => ({
				rowSpan: calcSpan(currentDataSource, 'vocSpecParamParam.spec.displayVal', record),
			}),
		},
		{
			title: 'Параметр',
			dataIndex: ['vocSpecParamParam', 'displayVal'],
			key: 'parameter',
			width: '25%',
			render: (parameter) => <span style={{ wordBreak: 'break-all' }}>{parameter}</span>,
		},
		{
			title: 'ЕИ',
			dataIndex: ['vocSpecParamParam', 'vocMeasure', 'displayVal'],
			key: 'measure',
			width: '5%',
			render: (text) => <span style={{ wordBreak: 'break-all' }}>{text}</span>,
		},
		{
			title: 'Цель',
			key: 'etalon',
			width: '15%',
			render: (_, record) => <span style={{ wordBreak: 'break-all' }}>{getEtalon(record)}</span>,
		},
		{
			title: 'Допустимое отклонение',
			key: 'deviation',
			width: '25%',
			render: (record) => (
				<InputError
					hasError={formErrors[record._uuid_]}
					component={<Typography.Text>{getSpecParamDeviation(record)}</Typography.Text>}
				/>
			),
		},
		{
			title: 'Фото',
			dataIndex: 'photos',
			key: 'photoPath',
			width: '4%',
			render: (photos, record, index) =>
				photos?.length ? (
					<Carousel
						files={photos}
						delFn={(photo) => removePhoto(record._uuid_, photo)}
						title={record.vocSpecParamParam?.label || record.label}
						disabled={disabled}
						path={'photoPath'}
						previewStyle={{
							display: 'flex',
							maxHeight: '3vh',
							height: '3vh',
						}}
					/>
				) : (
					''
				),
			onCell: (record, index) => ({
				style: {
					padding: 4,
					overflow: 'hidden',
				},
			}),
		},
		{
			title: null,
			key: 'photoAction',
			width: '4%',
			render: (record) => {
				if (disabled) {
					return null
				}
				const isDisabled = disabled || record.photos?.length >= 10
				return (
					<Upload
						multiple
						accept=".png,.jpg,.bmp,.gif,.tif"
						onChange={({ file, fileList }) => {
							if (fileList?.length > 10) return
							if (!file?.type?.includes('image')) {
								return
							}
							if (file.status === 'uploading') addPhoto(record._uuid_, fileList)
						}}
						customRequest={({ onSuccess, onError, file }) => onSuccess(null)}
						fileList={record.photos || []}
						showUploadList={false}
						disabled={isDisabled}
					>
						<AddPhotoIcon
							style={{
								fontSize: 20,
								opacity: isDisabled ? 0.3 : 1,
								cursor: isDisabled ? 'not-allowed' : 'pointer',
							}}
							className="ant-btn ant-btn-text ant-btn-icon-only"
						/>
					</Upload>
				)
			},
		},
		{
			title: () =>
				isFullScreen ? (
					<Button
						type="text"
						icon={<FullscreenExitOutlined className={'pictogram'} style={{ fontSize: 32 }} />}
						onClick={() => {
							setIsFullScreen(false)
						}}
					/>
				) : (
					<Button
						type="text"
						icon={<FullscreenOutlined className={'pictogram'} style={{ fontSize: 32 }} />}
						onClick={() => {
							setIsFullScreen(true)
						}}
					/>
				),
			key: 'action',
			width: '4%',
			render: (record) => (
				<EditDelBtns
					canUpdate={!disabled}
					onEdit={() => {
						setIsModalOpen(true)
						setEditModalItem(record)
					}}
				/>
			),
		},
	]
}
