import React, { useContext } from 'react'
import { StockReadyProdItemMainContext } from '../provider/main'
import { TabItem, TableWithListLoader, showConfirmModal } from '../../../../../components'
import { AuthContext, UserDataContext } from '../../../../../contexts'
import { getDisplayColumns } from './parent-tab'
import { checkRole } from '@berry/common-functions/role'
import { openNewTab } from '../../../../../utils'

const ParentTab = ({ modalItem, setModalItem, isModalOpen, setIsModalOpen }) => {
	const mainCtx = useContext(StockReadyProdItemMainContext)
	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const canViewStocks = checkRole(
		{
			'АРМ офиса': {
				'Реестр Запасы': {
					view: true,
				},
			},
		},
		currentRights
	)
	return (
		<TabItem
			addButton={{ show: false }}
			table={() => (
				<TableWithListLoader
					url={`/stock/ready-prods/parents`}
					calcColumns={getDisplayColumns}
					filters={{}}
					defaultSorter={{}}
					permanentFilters={{}}
					params={{ id: mainCtx.state.id }}
					rowKey="_uuid_"
					highlight
					authCtx={authCtx}
					canViewStocks={canViewStocks}
					pagination={false}
					onRow={(record) => ({
						onClick: () => {
							const id = record.idStockRawMat || record.idStockReadyProdResale
							const url = record.idStockRawMat
								? `/stock/raw-mats/${id}`
								: `/stock/ready-prod-resales/${id}`
							if (!canViewStocks) {
								return showConfirmModal({
									title:
										'Ограничены права доступа к данному объекту. Обратитесь к администратору системы',
									okText: 'Ок',
									width: 450,
									showCancel: false,
								})
							}
							openNewTab(url, { authCtx })
						},
					})}
				/>
			)}
		/>
	)
}

export default ParentTab
