import { DatePicker, Typography } from 'antd'
import { modes } from '../../../../utils'
import { colorizeIntegrationStatus } from '../../../../utils/helpers/for-integration'
const { Title } = Typography

const layout1 = {
	labelCol: { xs: 24, sm: 8, xl: 8 },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}
const layout2 = {
	labelCol: { xs: 24, sm: 8, xl: 8 },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}
const layout3 = {
	labelCol: { xs: 24, sm: 8, xl: 10, xxl: { offset: 2, span: 8 } },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}

export const getFormItems = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				key: 'req-title',
				field: <Title level={5}>Основные данные</Title>,
			},

			{
				layout: layout1,
				label: 'ID перемещения',
				key: 'id',
				name: 'id',
				field: mainCtx.state.id,
			},
		],
		[
			{ key: 'mid-column' },
			{
				key: 'date',
				name: 'date',
				layout: layout2,
				label: 'Дата перемещения',
				rules: [{ required: true }],
				field: (
					<DatePicker
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format="DD.MM.YYYY"
						allowClear={false}
						suffixIcon={null}
						onChange={mainCtx.stateFunctions.setDate}
						style={{ width: '100%', maxWidth: '150px' }}
						disabled={isDisabled}
					/>
				),
			},
		],
		[
			{ key: 'last-column' },
			{
				key: 'status',
				name: 'status',
				layout: layout3,
				label: 'Статус',
				...colorizeIntegrationStatus(mainCtx.state.status),
			},
		],
	]
}
