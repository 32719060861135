import { Upload } from 'antd'
import { Carousel, EditDelBtns, InputError } from '../../../../components'
import { modes } from '../../../../utils'
import moment from 'moment'
import { PaperClipOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { dateFormat } from '../../../../utils/constants/common'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 */
export const getDisplayColumns = (
	modCtx,
	stateFunctions,
	removePhoto,
	addFile,
	mainCtx,
	fileCtx
) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		{
			title: 'Наименование',
			dataIndex: 'id',
			key: 'id',
			width: '68%',
			render: (_, record) => (
				<InputError
					hasError={!!mainCtx.formErrors?.[`label.${record._uuid_}`]}
					maxLength={130}
					placeholder="Наименование..."
					value={stateFunctions.get(record._uuid_)?.label}
					disabled={isDisabled}
					onChange={(e) => {
						stateFunctions.setLabel(record._uuid_, e.target.value)
						stateFunctions.setDate(record._uuid_, moment())
					}}
				/>
			),
		},
		{
			title: 'Дата загрузки',
			dataIndex: 'date',
			key: 'date',
			width: '10%',
			render: (_, record) => {
				return moment(record?.date).format(dateFormat)
			},
		},
		{
			title: 'Документ',
			key: 'filePath',
			width: '10%',
			render: (_, record, index) =>
				record.files?.length ? (
					<Carousel
						files={record.files}
						delFn={(document) => {
							removePhoto(record._uuid_, document)
						}}
						title={record.label}
						disabled={isDisabled}
						path={'filePath'}
						previewStyle={{
							display: 'flex',
							maxHeight: '3vh',
							height: '3vh',
						}}
					/>
				) : (
					''
				),
			onCell: (record) => ({
				style: {
					padding: 4,
					overflow: 'hidden',
				},
			}),
		},
		{
			title: null,
			key: 'photoAction',
			width: '4%',
			render: (record) => {
				const disable = isDisabled || record.files?.length >= 10 || mainCtx.state.status !== 'Новая'
				return (
					<Upload
						multiple
						accept=".png,.jpg,.bmp,.gif,.tif,.pdf,.doc,.txt,.rtf"
						onChange={({ file, fileList, event }) => {
							if (fileList?.length > 10) return
							if (
								![
									'image/jpeg',
									'image/png',
									'image/bmp',
									'image/gif',
									'image/tiff',
									'text/plain',
									'application/pdf',
									'application/msword',
									'text/csv',
									'application/rtf',
									'application/vnd.ms-word.template.macroEnabled.12',
									'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
									'application/vnd.ms-word.document.macroEnabled.12',
									'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
								].includes(file?.type)
							) {
								return
							}
							if (file.status === 'uploading') addFile(record._uuid_, fileList)
						}}
						customRequest={({ onSuccess, onError, file }) => {
							onSuccess(null)
						}}
						fileList={record.files || []}
						showUploadList={false}
						disabled={disable}
					>
						<PaperClipOutlined
							disabled={disable}
							style={{
								fontSize: 22,
								cursor: disable ? 'not-allowed' : 'pointer',
							}}
							className={`ant-btn ant-btn-text ${disable ? '' : 'pictogram'}`}
						/>
					</Upload>
				)
			},
		},
		{
			title: '',
			dataIndex: '',
			key: 'download',
			width: '4%',
			render: (_, record) => {
				const disable = modCtx.mod === modes.edit || !record.files?.length
				return (
					<VerticalAlignBottomOutlined
						disabled={disable}
						style={{
							fontSize: 22,
						}}
						onClick={() => (disable ? null : fileCtx.downloadFiles(record.files, 'filePath'))}
						className={`ant-btn ant-btn-text ${disable ? '' : 'pictogram'}`}
					/>
				)
			},
		},
		{
			title: '',
			key: 'action',
			width: '6%',
			render: (record) => (
				<EditDelBtns
					record={record}
					canDelete={!isDisabled}
					onDelete={(record) => stateFunctions.delete(record._uuid_)}
				/>
			),
		},
	]
}
