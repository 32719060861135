import React, { useEffect, useState, useContext } from 'react'
import { Menu } from 'antd'
import {
	ShareAltOutlined,
	GiftOutlined,
	KeyOutlined,
	TableOutlined,
	ReadOutlined,
	ToolOutlined,
	ApartmentOutlined,
	EllipsisOutlined,
	SettingOutlined,
	BuildOutlined,
	ClusterOutlined,
} from '@ant-design/icons'
import { Link, useLocation, useParams } from 'react-router-dom'
import { modes } from '../../utils'
import { ModContext, UserDataContext, MenuContext, menuPositions } from '../../contexts'
import { vocabularies } from '../Vocabulary/vocabularies'
import { checkRole } from '@berry/common-functions/role'
import { rights } from '../../utils/urls/rights'

const items = [
	{
		label: 'Производство',
		icon: <SettingOutlined />,
		path: '/production',
		key: '/production',
		children: [
			{
				label: 'Задачи на производство',
				path: '/production/tasks',
				key: '/production/tasks',
				right: {
					'АРМ офиса': {
						'Задачи на производство': {
							view: true,
						},
					},
				},
			},
			// {
			// 	label: 'Заказы давальца',
			// 	path: '/production/giver-orders',
			// 	key: '/production/giver-orders',
			// 	right: {
			// 		'АРМ офиса': {
			// 			'Заказы давальца': {
			// 				view: true,
			// 			},
			// 		},
			// 	},
			// },
		],
	},
	{
		label: 'Сырье',
		icon: <ShareAltOutlined />,
		path: '/rm',
		key: '/rm',
		children: [
			{
				label: 'Поставщики',
				path: '/rm/providers',
				key: '/rm/providers',
				right: {
					'АРМ офиса': {
						Поставщики: {
							view: true,
						},
					},
				},
			},
			{
				label: 'Договоры. Поставщики',
				path: '/rm/contracts-providers',
				key: '/rm/contracts-providers',
				right: {
					'АРМ офиса': {
						'Договоры. Поставщики': {
							view: true,
						},
					},
				},
			},
			{
				label: 'Заявки на поставку сырья',
				path: '/rm/supply-requests',
				key: '/rm/supply-requests',
				right: {
					'АРМ офиса': {
						'Заявки на поставку сырья': {
							view: true,
						},
					},
				},
			},
			{
				label: 'Поставки сырья',
				path: '/rm/supplies',
				key: '/rm/supplies',
				right: {
					'АРМ офиса': {
						'Поставки сырья': {
							'Основные данные': { view: true },
						},
					},
				},
			},
		],
	},
	{
		label: 'Готовая продукция',
		icon: <GiftOutlined />,
		path: '/rp',
		key: '/rp',
		children: [
			{
				label: 'Заказчики',
				path: '/rp/customers',
				key: '/rp/customers',
				right: {
					'АРМ офиса': {
						Заказчики: {
							view: true,
						},
					},
				},
			},
			{
				label: 'Договоры. Заказчики',
				path: '/rp/contracts-customers',
				key: '/rp/contracts-customers',
				right: {
					'АРМ офиса': {
						'Договоры. Заказчики': {
							view: true,
						},
					},
				},
			},
		],
	},
	{
		label: 'Давальческая продукция',
		icon: <BuildOutlined />,
		path: '/reports',
		key: '/reports',
		children: [
			{
				label: 'Сводные отчеты по ДЗ',
				path: '/reports/consolidated-reports',
				key: '/reports/consolidated-reports',
				right: {
					'АРМ офиса': {
						'Сводные отчеты': {
							view: true,
						},
					},
				},
			},
		],
	},
	{
		label: 'Справочник продукции',
		icon: <KeyOutlined />,
		path: '/pv',
		key: '/pv',
		children: [
			{
				label: 'Продуктовый каталог',
				path: '/pv/product-catalog',
				key: '/pv/product-catalog',
				right: {
					'АРМ офиса': {
						'Продуктовый каталог': {
							view: true,
						},
					},
				},
			},
			{
				label: 'Справочник артикулов',
				path: '/pv/displaycodes-vocabulary',
				key: '/pv/displaycodes-vocabulary',
				right: {
					'АРМ офиса': {
						'Справочник артикулов': {
							view: true,
						},
					},
				},
			},
		],
	},
	{
		label: 'Запасы',
		icon: <ApartmentOutlined />,
		path: '/stock',
		key: '/stock',
		children: [
			{
				label: 'Сырье',
				path: '/stock/raw-mats',
				key: '/stock/raw-mats',
				right: {
					'АРМ офиса': {
						'Реестр Запасы': {
							view: true,
						},
					},
				},
			},
			{
				label: 'Полуфабрикаты',
				path: '/stock/semifs',
				key: '/stock/semifs',
				right: {
					'АРМ офиса': {
						'Реестр Запасы': {
							view: true,
						},
					},
				},
			},
			{
				label: 'Готовая продукция',
				path: '/stock/ready-prods',
				key: '/stock/ready-prods',
				right: {
					'АРМ офиса': {
						'Реестр Запасы': {
							view: true,
						},
					},
				},
			},
			{
				label: 'ГП на перепродажу',
				path: '/stock/ready-prod-resales',
				key: '/stock/ready-prod-resales',
				right: {
					'АРМ офиса': {
						'Реестр Запасы': {
							view: true,
						},
					},
				},
			},
			{
				label: 'Образцы',
				path: '/stock/samples',
				key: '/stock/samples',
				right: {
					'АРМ офиса': {
						'Реестр Запасы': {
							view: true,
						},
					},
				},
			},
			{
				label: 'Отходы',
				path: '/stock/wastes',
				key: '/stock/wastes',
				right: {
					'АРМ офиса': {
						'Реестр Запасы': {
							view: true,
						},
					},
				},
			},
		],
	},
	{
		label: 'Операции с запасами',
		icon: <ClusterOutlined />,
		path: '/stock-operations',
		key: '/stock-operations',
		children: [
			{
				label: 'Реализации',
				path: '/stock-operations/implementations',
				key: '/stock-operations/implementations',
				right: {
					'АРМ офиса': {
						Реализации: {
							view: true,
						},
					},
				},
			},
			{
				label: 'Утилизации',
				path: '/stock-operations/disposals',
				key: '/stock-operations/disposals',
				right: {
					'АРМ офиса': {
						Утилизации: {
							view: true,
						},
					},
				},
			},
			{
				label: 'Перемещения удал. склад',
				path: '/stock-operations/reloc-remote-warehouse',
				key: '/stock-operations/reloc-remote-warehouse',
				right: {
					'АРМ офиса': {
						'Перемещения удал. склад': {
							view: true,
						},
					},
				},
			},
			{
				label: 'Перемещения в брак',
				path: '/stock-operations/reloc-defect',
				key: '/stock-operations/reloc-defect',
				right: {
					'АРМ офиса': {
						'Перемещения в брак': {
							view: true,
						},
					},
				},
			},
		],
	},
	{
		label: 'Объекты учета',
		icon: <TableOutlined />,
		path: '/reg',
		key: '/reg',
		children: [
			{
				label: 'Протоколы контроля',
				path: '/reg/specifications',
				key: '/reg/specifications',
				right: {
					'АРМ офиса': {
						'Протоколы контроля': {
							view: true,
						},
					},
				},
			},
			{
				label: 'Персонал',
				path: '/reg/staff',
				key: '/reg/staff',
				right: {
					'АРМ офиса': {
						Персонал: {
							view: true,
						},
					},
				},
			},
		],
	},
	{
		label: 'Справочники',
		icon: <ReadOutlined />,
		path: '/vocabularies',
		key: '/vocabularies',
	},
	{
		label: 'Настройки системы',
		icon: <ToolOutlined />,
		path: '/system-settings',
		key: '/system-settings',
	},
]

const style = {
	display: 'flex',
	alignItems: 'center',
}

const Items = () => {
	const params = useParams()
	const location = useLocation()
	const modCtx = useContext(ModContext)
	const { position, collapsed } = useContext(MenuContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const [selected, setSelected] = useState([])
	const [opened, setOpened] = useState([`/${location.pathname.split('/').slice(1)[0]}`])

	const clickable = !modCtx || modCtx.mod === modes.view
	const leftMenu = position === menuPositions.left

	useEffect(() => {
		if (collapsed || !leftMenu) {
			setOpened([])
		} else {
			setOpened([`/${location.pathname.split('/').slice(1)[0]}`])
		}
	}, [collapsed, leftMenu, location.pathname])

	useEffect(() => {
		if (Object.keys(params).length) {
			setSelected([
				location.pathname
					.split('/')
					.slice(0, location.pathname.split('/').length - 1)
					.join('/'),
			])
		} else {
			setSelected([location.pathname])
		}
	}, [location.pathname, params])

	const filterItemsByRights = () => {
		if (typeof currentRights === 'object' && !Object.keys(currentRights).length) {
			return items
		}
		return items.reduce((acc, item) => {
			if (item.path === '/vocabularies') {
				return vocabularies.some(
					(voc) =>
						currentRights.Справочники && Object.keys(currentRights.Справочники).includes(voc.title)
				)
					? [...acc, item]
					: acc
			}
			if (item.children?.length) {
				const filteredData = item.children.filter((d) => checkRole(d.right, currentRights))
				if (filteredData.length) {
					return [...acc, { ...item, data: filteredData, children: filteredData }]
				}
				return acc
			}
			return checkRole(rights[item.path], currentRights) ? [...acc, item] : acc
		}, [])
	}

	const preparedItems = filterItemsByRights().map((menuItem) => {
		if (menuItem.children && menuItem.children.length) {
			return {
				...menuItem,
				...(!leftMenu && {
					style,
				}),
				children: menuItem.children.map((child) => ({
					...child,
					style,
					label: (
						<Link to={child.path} href={child.path}>
							{child.label}
						</Link>
					),
				})),
			}
		}
		return {
			...menuItem,
			style,
			label: (
				<Link to={menuItem.path} href={menuItem.path}>
					{menuItem.label}
				</Link>
			),
		}
	})

	return (
		<Menu
			mode={leftMenu ? 'inline' : 'horizontal'}
			items={preparedItems}
			triggerSubMenuAction={collapsed || !leftMenu ? 'hover' : 'click'}
			style={{
				width: '100%',
				height: leftMenu ? 'auto' : '100%',
			}}
			theme="dark"
			openKeys={opened}
			selectedKeys={selected}
			disabled={!clickable}
			onOpenChange={(e) => setOpened(e)}
			overflowedIndicator={
				<EllipsisOutlined
					style={{
						display: 'flex',
						alignItems: 'center',
						height: 64,
					}}
				/>
			}
		/>
	)
}

export default Items
