import React from 'react'
import { Form as FormAntd, Input } from 'antd'

const formItemLayout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

const Form = (props) => {
	const { label, labelShort, form } = props

	return (
		<FormAntd labelAlign="left" form={form}>
			<FormAntd.Item
				{...formItemLayout}
				label="Наименование"
				getValueProps={(value) => value}
				name="label"
				onChange={label.onChange}
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Input value={label.value} />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Краткое наименование"
				name="labelShort"
				onChange={labelShort.onChange}
				getValueProps={(value) => value}
				rules={[
					{
						required: true,
						message: false,
					},
					{ max: 10, message: 'Введите не более 10 знаков' },
				]}
			>
				<Input value={labelShort.value} />
			</FormAntd.Item>
		</FormAntd>
	)
}

export default Form
