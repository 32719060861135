import { showConfirmModal } from '../../components'
import { openNewTab } from './for-router'
import { axios } from '../../utils'

const clickableRow = (setRowId, authCtx, url, canView = true) => {
	return (record) => ({
		onClick: async () => {
			if (!canView) {
				return showConfirmModal({
					title: 'Ограничены права доступа к данному объекту. Обратитесь к администратору системы',
					okText: 'Ок',
					width: 450,
					showCancel: false,
				})
			}
			const { id, status } = record
			let frontUrl = url ? `${url}/${id}` : ''
			const params = { id, status }
			if (!url && ['Бронь', 'Продажа', 'Сработано', 'Утилизировано'].includes(status)) {
				params.data = record
				const { data } = await axios(`/stock/get-url-for-storage`, { params: params })
				frontUrl = data
			}
			if (!frontUrl) return
			openNewTab(frontUrl, {
				authCtx: authCtx.state,
			})
		},
		onMouseEnter: () => {
			setRowId(record.id)
		},
		onMouseLeave: () => {
			setRowId('')
		},
	})
}

export default clickableRow
