import { capitalizeFirstLetter } from '@berry/common-functions/text-functions'
import { Checkbox } from 'antd'
import { getColumns as getRawMatColumns } from '../../../../Stock/RawMat/RawMatList/raw-mat-list'
import { getColumns as getReadyProdColumns } from '../../../../Stock/ReadyProd/ReadyProdList/ready-prod-list'
import { getColumns as getReadyProdResaleColumns } from '../../../../Stock/ReadyProdResale/ReadyProdResaleList/ready-prod-resale-list'
import { TableWithListLoader } from '../../../../../components'

const getCheckboxColumn = (mainCtx) => {
	const addProdName = [`addProdStock${capitalizeFirstLetter(mainCtx.addProd.__name)}Stor`]
	return {
		title: null,
		key: 'checkbox',
		width: '2%',
		render: (record) => {
			return (
				<Checkbox
					disabled={
						mainCtx.addProd[addProdName].some(
							(el) =>
								(el.idContrOrder && el.idContrOrder !== record.idContrOrder) ||
								(el.idContrOrder === null && record.idContrOrder)
						) ||
						(mainCtx.state.productions?.length &&
							mainCtx.state.productions[0]?.idStockRawMat &&
							mainCtx.addProd.__name !== 'rawMat') ||
						(mainCtx.state.productions?.length &&
							mainCtx.state.productions[0]?.idStockSemif &&
							mainCtx.addProd.__name !== 'semif') ||
						(mainCtx.state.productions?.length &&
							(mainCtx.state.productions[0]?.idStockReadyProd ||
								mainCtx.state.productions[0]?.idStockReadyProdResale) &&
							!['readyProd', 'readyProdResale'].includes(mainCtx.addProd.__name))
					}
					checked={mainCtx.addProd[addProdName]?.some(
						(el) => String(el.idStorage) === String(record.idStorage)
					)}
					onChange={(e) => {
						const val = e.target.checked
							? record
							: mainCtx.addProd[addProdName].find((s) => s.idStorage === record.idStorage)
						if (val._uuid_) {
							return mainCtx.modalFunctions[addProdName].delete(val._uuid_)
						}
						return mainCtx.modalFunctions[addProdName].create(val)
					}}
				/>
			)
		},
	}
}

const SelectProdModalTable = ({ mainCtx }) => {
	let url = null
	let calcColumns = null
	let filters = {}
	let defaultSorter = null
	let permanentFilters = {}
	switch (mainCtx.addProd.__name) {
		case 'rawMat':
			url = '/stock/raw-mats'
			calcColumns = (props) => [getCheckboxColumn(mainCtx), ...getRawMatColumns(props).slice(0, -1)]
			filters = {
				defrostCount: [],
				idContrOrder: [],
				assignedPartyNum: [],
				displayCode: [],
				vendorCode: [],
				prodCat: [],
				prodCatKind: [],
				quality: [],
				pkgType: [],
				nettoPkgWeight: [],
				provider: [],
				manuf: [],
				roomNum: [],
				status: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					status: !mainCtx.state.productions[0]?.idContrOrder
						? ['Допущено', 'Удержано', 'Заблокировано', 'Дефростация', 'Ответ. хранение']
						: ['Допущено', 'Удержано', 'Заблокировано', 'Дефростация'],
					roomType: ['Внутренний'],
					roomOutdoorWhLabel: ['Внутренний'],
				},
				exclude: {
					idStorage: [...mainCtx.state.productions, ...mainCtx.deletedProds]
						.filter((p) => p.idStockRawMatStor || p.stockRawMatStor)
						.flatMap((p) => [
							p.stockRawMatStor?.idStorage || p.idStockRawMatStor,
							p.stockRawMatStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				filterByStatus: true,
			}
			break

		case 'readyProd':
			url = '/stock/ready-prods'
			calcColumns = (props) => [getCheckboxColumn(mainCtx), ...getReadyProdColumns(props)]
			filters = {
				idContrOrder: [],
				partyNum: [],
				articul: [],
				articul1C: [],
				prodCat: [],
				prodCatKind: [],
				pkgType: [],
				nettoPkgWeight: [],
				roomNum: [],
				status: [],
				_1c: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					status: ['Допущено', ' Удержано', 'Заблокировано'],
					_1c: ['Зарегистрирован в 1С'],
					idContrOrder: [null],
					roomType: ['Внутренний'],
					roomOutdoorWhLabel: ['Внутренний'],
				},
				exclude: {
					idStorage: [...mainCtx.state.productions, ...mainCtx.deletedProds]
						.filter((p) => p.idStockReadyProdStor || p.stockReadyProdStor)
						.flatMap((p) => [
							p.stockReadyProdStor?.idStorage || p.idStockReadyProdStor,
							p.stockReadyProdStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				filterByStatus: true,
			}
			break
		case 'semif':
			url = '/stock/semifs'
			calcColumns = (props) => [getCheckboxColumn(mainCtx), ...getReadyProdColumns(props)]
			filters = {
				idContrOrder: [],
				partyNum: [],
				articul: [],
				articul1C: [],
				prodCat: [],
				prodCatKind: [],
				pkgType: [],
				nettoPkgWeight: [],
				roomNum: [],
				status: [],
				_1c: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					status: !mainCtx.state.productions[0]?.idContrOrder
						? ['Допущено', 'Удержано', 'Заблокировано', 'Дефростация']
						: [],
					roomType: ['Внутренний'],
					_1c: ['Зарегистрирован в 1С'],
					idContrOrder: [null],
					roomOutdoorWhLabel: ['Внутренний'],
				},
				exclude: {
					idStorage: [...mainCtx.state.productions, ...mainCtx.deletedProds]
						.filter((p) => p.idStockSemifStor || p.stockSemifStor)
						.flatMap((p) => [
							p.stockSemifStor?.idStorage || p.idStockSemifStor,
							p.stockSemifStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				filterByStatus: true,
			}
			break
		case 'readyProdResale':
			url = '/stock/ready-prod-resales'
			calcColumns = (props) => [
				getCheckboxColumn(mainCtx),
				...getReadyProdResaleColumns(props).slice(0, -1),
			]
			filters = {
				defrostCount: [],
				idContrOrder: [],
				assignedPartyNum: [],
				displayCode: [],
				vendorCode: [],
				prodCat: [],
				prodCatKind: [],
				quality: [],
				pkgType: [],
				nettoPkgWeight: [],
				provider: [],
				manuf: [],
				roomNum: [],
				status: [],
			}
			defaultSorter = {
				field: 'prodCat',
				order: 'ascend',
			}
			permanentFilters = {
				include: {
					status: ['Допущено', 'Удержано', 'Заблокировано', 'Дефростация', 'Ответ. хранение'],
					roomType: ['Внутренний'],
					roomOutdoorWhLabel: ['Внутренний'],
				},
				exclude: {
					idStorage: [...mainCtx.state.productions, ...mainCtx.deletedProds]
						.filter((p) => p.idStockReadyProdResaleStor || p.stockReadyProdResaleStor)
						.flatMap((p) => [
							p.stockReadyProdResaleStor?.idStorage || p.idStockReadyProdResaleStor,
							p.stockReadyProdResaleStor?.parent?.id || p.idParent,
						])
						.filter((id) => !isNaN(id)),
				},
				filterByStatus: true,
			}
			break
		default:
			throw Error('unknown tab opened')
	}

	return (
		<TableWithListLoader
			rowKey="idStorage"
			url={url}
			calcColumns={calcColumns}
			filters={filters}
			defaultSorter={defaultSorter}
			permanentFilters={permanentFilters}
		/>
	)
}

export default SelectProdModalTable
