import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import { ModContext } from '../../../../contexts'
import { prepareObjFromServer, modes, getObjDiff, isEdited, axios } from '../../../../utils'
import { showConfirmModal } from '../../../../components'

const dataUrl = '/other-data'
export const reducer = (state) => {
	return {
		...state,
	}
}

const MatchSupplySettingsItemMainContext = React.createContext()
MatchSupplySettingsItemMainContext.displayName = 'MatchSupplySettingsItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)

	useEffect(() => {
		const getData = async () => {
			const {
				data: { result = [] },
			} = await axios(dataUrl)
			const rec = result.find((el) => el.type === '1c-setting')
			executeDispatch({
				...stateRef.current,
				data: { id: rec.id, ...rec.data },
				oldData: { id: rec.id, ...rec.data },
			})
		}
		getData()
	}, [])

	const [state, dispatch] = React.useReducer(reducer, {
		data: {
			canEdit: false,
		},
		oldData: {
			canEdit: false,
		},
		formErrors: [],
	})
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	const commonFieldUpdate = (inField, value) => {
		let newState = lodash.cloneDeep(stateRef.current.data)
		newState[inField] = value
		executeDispatch({
			...stateRef.current,
			data: {
				...newState,
			},
		})
	}
	const stateFunctions = {
		fieldSetters: {
			setIsAllowEdit: (val) => {
				commonFieldUpdate('isAllowEdit', val)
			},
		},
	}
	const validate = () => {
		return ''
	}
	const serverEdit = async () => {
		const err = await validate()
		if (err) {
			return showConfirmModal({
				title: err,
				showCancel: false,
			})
		}
		let body = {}
		let options = { url: `${dataUrl}/settings-1c` }
		if (params.id === 'new') {
			body = stateRef.current.data
			options.method = 'POST'
		} else {
			options.method = 'PUT'
			body = getEditedData()
		}
		if (!isEdited(body)) {
			return modCtx.set(modes.view)
		}
		executeDispatch({ ...stateRef.current, isPendingReq: true })
		await axios[options.method.toLowerCase()](options.url, body)
		executeDispatch({ ...stateRef.current, isPendingReq: false })
		modCtx.set(modes.view)
	}

	const getEditedData = () => {
		return getObjDiff(stateRef.current.oldData, stateRef.current.data)
	}
	const isItemEdited = () => {
		if (modCtx.mod === modes.view) {
			return false
		}
		if (modCtx.mod === modes.new && Object.keys(stateRef.current.data).length) {
			return true
		}
		const editedFields = getEditedData()
		return isEdited(editedFields)
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */

	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
		})
	}, [stateRef.current.oldData])

	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * подготавливает данные которые нужно выбират
	 */
	const selectors = {}

	const value = {
		state: stateRef.current,
		isPendingReq: stateRef.current.isPendingReq,
		selectors,
		stateFunctions,
		serverEdit,
		isEdited: isItemEdited,
		reset,
	}

	return (
		<MatchSupplySettingsItemMainContext.Provider value={value}>
			{children}
		</MatchSupplySettingsItemMainContext.Provider>
	)
}

export { Provider, MatchSupplySettingsItemMainContext }
