import React, { useContext, useEffect, useRef } from 'react'
import { ListContext, SyncDepsContext } from '../../../../contexts'
import { startUseEffectHandlerForList } from '../../../../utils'

const dataUrl = '/rm/supplies'

export const reducer = (state) => {
	return {
		...state,
	}
}

const RawMaterialSupplyListMainContext = React.createContext()
RawMaterialSupplyListMainContext.displayName = 'RawMaterialSupplyListMainContext'

const Provider = (props) => {
	const { children } = props
	const {
		state: { page, pageSize, mainFilter, filters, sorter, textFilter },
		setTotal,
	} = useContext(ListContext)
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: {
			supplyId: [],
			supplyReqDisplayCode: [],
			supplyReqVehicleNum: [],
			productProvider: [],
			formatedUnloadDatePlan: [],
			productInvoiceDisplayCode: [],
			partyNum: [],
			prodLabel: [],
		},
		isInitialized: false,
		isLoading: false,
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	useEffect(() => {
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				sorter,
				mainFilter,
				filters,
				offset: pageSize * page - pageSize,
				limit: pageSize,
				search: textFilter,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [
		mainFilter,
		filters,
		textFilter,
		page,
		pageSize,
		setTotal,
		sorter,
		syncDepsCtx.state.reloadUuids['office-ms'],
	])

	const value = {
		state: stateRef.current,
		toDisplayDataList: stateRef.current.fromServer,
	}

	return (
		<RawMaterialSupplyListMainContext.Provider value={value}>
			{children}
		</RawMaterialSupplyListMainContext.Provider>
	)
}

export { Provider, RawMaterialSupplyListMainContext }
