import { Modal, Table } from 'antd'
import React, { useState, useContext } from 'react'
import { SpecificationItemContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns } from './utils'
import { ParameterAddModal, ParameterDelModal, ParameterEditModal } from '../modals'

const ParameterTab = () => {
	const mainCtx = useContext(SpecificationItemContext)
	const modCtx = useContext(ModContext)
	const [isAddModalOpen, setIsAddModalOpen] = useState(false)
	const [isDelModalOpen, setIsDelModalOpen] = useState(false)
	const [isEditModalOpen, setIsEditModalOpen] = useState(false)
	const [editModalItem, setEditModalItem] = useState(null)
	const [isFullScreen, setIsFullScreen] = useState(false)

	const initialDataSource = mainCtx.state.data.parameters
	const { addPhoto, removePhoto } = mainCtx.stateFunctions.parameters

	const columns = getDisplayColumns(
		addPhoto,
		removePhoto,
		setEditModalItem,
		setIsEditModalOpen,
		isFullScreen,
		setIsFullScreen,
		modCtx.mod === modes.view,
		initialDataSource,
		mainCtx.formErrors
	)

	const tabItem = (
		<>
			<TabItem
				addButton={{
					disabled: false,
					show: true,
					onClick: () => {
						setIsAddModalOpen(true)
					},
				}}
				delButton={{
					disabled:
						!['Не утверждено', 'Черновик'].includes(mainCtx.state.data.status) ||
						!mainCtx.state.data.parameters?.length,
					show: true,
					onClick: () => {
						setIsDelModalOpen(true)
					},
				}}
				modal={() =>
					isAddModalOpen ? (
						<ParameterAddModal
							isOpen={isAddModalOpen}
							setIsOpen={setIsAddModalOpen}
							mainCtx={mainCtx}
						/>
					) : isDelModalOpen ? (
						<ParameterDelModal
							isOpen={isDelModalOpen}
							setIsOpen={setIsDelModalOpen}
							mainCtx={mainCtx}
						/>
					) : null
				}
				table={() => (
					<Table
						size="small"
						rowKey="_uuid_"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={columns}
						dataSource={initialDataSource}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)}
			/>
			<ParameterEditModal
				isVisible={isEditModalOpen}
				setIsVisible={(isVisible) => {
					if (!isVisible) {
						mainCtx.setErrors([])
					}
					setIsEditModalOpen(isVisible)
				}}
				modalItem={editModalItem}
				setModalItem={setEditModalItem}
			/>
		</>
	)
	return isFullScreen ? (
		<Modal
			visible={isFullScreen}
			closable={false}
			onCancel={() => {
				setIsFullScreen(false)
			}}
			footer={false}
			className="full-screen"
			style={{
				position: 'absolute',
				top: 0,
				bottom: 0,
			}}
		>
			{tabItem}
		</Modal>
	) : (
		tabItem
	)
}

export default ParameterTab
