import { Input } from 'antd'
import { EditDelBtns } from '../../../../components'
import { formValidateUniq, modes, openNewTab } from '../../../../utils'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (mainCtx, modCtx, authCtx, editHandler) => {
	const stateFunctions = mainCtx.stateFunctions.platforms
	let isDisabled = false
	if (modCtx.mod === modes.view) isDisabled = true

	return [
		{
			title: 'Название',
			dataIndex: 'label',
			key: 'label',
			width: '47.5%',
		},
		{
			title: 'Фактический адрес',
			dataIndex: 'address',
			key: 'address',
			width: '47.5%',
		},
		{
			title: null,
			key: 'action',
			width: '5%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={!isDisabled}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
						onEdit={editHandler}
						deps={{}}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					/>
				)
			},
		},
	]
}

const layout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 18 },
}

/**
 * поля формы в модальном окне
 * @param {Object} mainCtx - главный контекст
 * @param {Object} modCtx - контекст мода
 * @param {{_uuid_: string}} params - отбьект с функциями изменения состояния вкладки
 */
export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []
	const { _uuid_ } = params
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.platforms
	return [
		[
			{
				layout: layout,
				label: 'Название',
				name: 'label',
				rules: [
					{ required: true },
					{
						validator: async (_, value) => {
							if (!formValidateUniq(mainCtx.state.platforms, stateFunctions.get(_uuid_), 'label')) {
								throw Error('Площадка с таким названием уже создана')
							}
						},
					},
				],
				field: (
					<Input
						style={{ width: '100%' }}
						value={stateFunctions.get(_uuid_).label}
						disabled={isDisabled}
						maxLength={75}
						onChange={(e) => {
							stateFunctions.setLabel(_uuid_, e.target.value)
						}}
					/>
				),
			},
			{
				layout: layout,
				label: 'Фактический адрес',
				name: 'address',
				rules: [{ required: true }],
				field: (
					<Input
						style={{ width: '100%' }}
						value={stateFunctions.get(_uuid_).address}
						disabled={isDisabled}
						maxLength={75}
						onChange={(e) => {
							stateFunctions.setAddress(_uuid_, e.target.value)
						}}
					/>
				),
			},
		],
	]
}
