import React, { useContext } from 'react'
import { ModContext, ModProvider, UserDataContext } from '../../../contexts'
import { Main } from '../../../layouts'
import { ProviderItemMainContext, Provider } from './provider/main'
import { Card, Row, Col, Form } from 'antd'
import { ActionButtons, CustomForm, HeaderMenu, TabList } from '../../../components'
import { actBtnOnClose, actBtnOnSaveV2, modes } from '../../../utils'
import { showConfirmModal } from '../../../components'
import { getFormItems, getMinorTabs } from './provider-item'
import { useHistory } from 'react-router'
import { getClassification, getSumScore } from '../utils'

const pageUrl = '/rm/providers'
const rights = {
	'АРМ офиса': {
		Поставщики: {
			edit: true,
		},
	},
}

const ProviderItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Поставщик">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(ProviderItemMainContext)
	const userDataCtx = useContext(UserDataContext)
	const history = useHistory()
	const [form] = Form.useForm()
	return (
		<>
			<HeaderMenu breadcrumbsTitle="Поставщики" recordLabel={mainCtx.state?.label || ''}>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						rights={rights}
						formName="provider"
						onClose={() => {
							actBtnOnClose({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							await actBtnOnSaveV2({
								form,
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								isShowErrorModal: true,
								pageUrl: pageUrl,
								showConfirmModal,
								history: history,
							})
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						onCancel={() => {
							const allFields = form.getFieldsError()
							allFields.forEach((f) => {
								f.errors = []
							})
							form.setFields(allFields)
							mainCtx.reset()
						}}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							validateMessages={{
								required: 'Обязательное поле!',
							}}
							form={form}
							name="provider"
							fields={[
								{ name: 'label', value: mainCtx.state.label },
								{ name: 'inn', value: mainCtx.state.inn },
								{ name: 'kpp', value: mainCtx.state.kpp },
								{ name: 'address', value: mainCtx.state.address },
								{ name: 'sumScore', value: getSumScore(mainCtx.state) },
								{ name: 'classification', value: getClassification(mainCtx.state) },
								{ name: 'abcAnalyze', value: 'A' },
								{ name: 'qualityManagement', value: mainCtx.state.qualityManagement },
								{ name: 'deliveryTime', value: mainCtx.state.deliveryTime },
								{ name: 'deliverCondition', value: mainCtx.state.deliverCondition },
								{ name: 'paymentCondition', value: mainCtx.state.paymentCondition },
								{ name: 'quality', value: mainCtx.state.quality },
							]}
							columns={[
								{ size: { xs: 24, lg: 12, xxl: 5 }, num: 0 },
								{ size: { xs: 24, lg: 12, xxl: 11 }, num: 1 },
								{ size: { xs: 24, lg: 12, xxl: 4 }, num: 2 },
								{ size: { xs: 24, lg: 12, xxl: 4 }, num: 3 },
							]}
							items={getFormItems({ mainCtx, modCtx, userDataCtx })}
						/>
					</Col>
				</Row>
			</Card>
			<TabList tabs={getMinorTabs(mainCtx, userDataCtx)} />
		</>
	)
}
export default ProviderItem
