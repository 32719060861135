import React, { useContext, useEffect, useRef, useCallback } from 'react'
import { DataServerContext, ModContext } from '../../../../contexts'
import { getCommonProviderFunctions } from '../../../../utils/helpers/generators'
import lodash from 'lodash'
import { prepareObjFromServer, axios, modes, getObjDiff, useItemFetchers } from '../../../../utils'
import { basicValidator, isEmail } from '@berry/common-functions/validators'
import { formValidateUniq } from '../../../../utils'
import { getDepsForTab } from '../../../../utils/helpers/for-deps'
import { useHistory } from 'react-router-dom'
const dataUrl = '/rp/customers'
const tabs = { contacts: 'contacts', contracts: 'contracts', platforms: 'platforms' }

export const reducer = (state) => {
	return {
		...state,
	}
}
const initialData = {
	contacts: [],
	contracts: [],
	platforms: [],
}

const CustomerItemMainContext = React.createContext()
CustomerItemMainContext.displayName = 'CustomerItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const [state, dispatch] = React.useReducer(reducer, {
		data: initialData,
		oldData: initialData,
		additional: {
			allSelectPayCondits: [],
		},
		formErrors: [],
		isInitializedMain: false,
		isInitializedAdditional: false,
		tabsLoading: {
			contacts: false,
			contracts: false,
			platforms: false,
		},
	})
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))

	const serverRemove = async () => {
		if (!stateRef.current?.id) {
			throw Error('Объект не найден')
		}
		await axios.delete(`${dataUrl}/${stateRef.current.id}`)
		return
	}

	const getEditedData = () => {
		return getObjDiff(stateRef.current.oldData, stateRef.current.data)
	}
	const isItemEdited = () => {
		if (modCtx.mod === modes.new && Object.keys(stateRef.current.data).length) {
			return true
		}
		const editedFields = getEditedData()
		return isEdited(editedFields)
	}

	/**
	 * Валидация всех полей в соответствии с конфлюенсом
	 * @returns {string} - если вернулась строка и не пустая значит есть ошибка валидации
	 */
	const validate = () => {
		const state = stateRef.current.data

		if (state.contacts.some((contact) => !contact.email && !contact.phoneNum)) {
			return 'Укажите номер телефона или электронную почту для всех контактов'
		}
		if (
			state.contacts.reduce((acc, c) => {
				if (c.email && !isEmail(c.email)) {
					return [...acc, c]
				}
				return acc
			}, []).length
		) {
			return 'Неверно указан адрес электронной почты'
		}
		return ''
	}

	const validators = {
		contacts: (inUuid) => {
			const found = stateFunctions.contacts.get(inUuid)
			if (!basicValidator(found.fullName)) throw Error()
			if (!basicValidator(found.position)) throw Error()
			if (found.email && !isEmail(found.email)) throw Error()
			if (found.phoneNum && found.phoneNum.replace(/\D/g, '').length !== 10) throw Error()
			if (!found.email && !found.phoneNum) {
				throw Error()
			}
		},
		platforms: (inUuid) => {
			const found = stateFunctions.platforms.get(inUuid)
			if (!basicValidator(found.label) & !basicValidator(found.address)) {
				throw Error()
			}
			if (!basicValidator(found.label)) throw Error()
			if (!basicValidator(found.address)) throw Error()
			if (!formValidateUniq(stateRef.current.data.platforms, found, 'label')) throw Error()
		},
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			formErrors: {},
		})
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * подготавливает данные которые нужно выбират
	 */
	const selectors = {
		vocPayCondit: stateRef.current.additional.allSelectPayCondits,
	}

	const getActualState = () => {
		const obj = lodash.cloneDeep(stateRef.current.data)
		// actualizeData(
		// 	dataServerCtx,
		// 	obj,
		// 	['contracts', 'customerQuotas'],
		// 	'prodCatPkg',
		// 	'/product-catalog',
		// 	{
		// 		dataField: 'packages',
		// 	}
		// )

		return obj
	}

	const { stateFunctions, serverEdit, isEdited } = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{ modCtx, dataUrl, params, pageUrl: dataUrl, history },
		selectors,
		{
			vocPayCondit: 'obj',
			timelyExport: 'common',
		},
		{
			contacts: {
				fullName: 'common',
				position: 'common',
				phoneNum: 'common',
				email: 'common',
			},
			platforms: {
				label: 'common',
				address: 'common',
			},
		}
	)

	const value = {
		getActualState,
		state: getActualState() /* stateRef.current */,
		isPendingReq: stateRef.current.isPendingReq,
		tabsLoading: stateRef.current.tabsLoading,
		additional: stateRef.current.additional,
		stateFunctions: stateFunctions,
		selectors: selectors,
		validators: validators,
		serverEdit: serverEdit,
		serverRemove: serverRemove,
		getEditedData: getEditedData,
		isEdited: isItemEdited,
		validate: validate,
		reset: reset,
	}

	return (
		<CustomerItemMainContext.Provider value={value}>{children}</CustomerItemMainContext.Provider>
	)
}

export { Provider, CustomerItemMainContext }
