import React, { useContext } from 'react'
import { Main } from '../../../layouts'
import { Provider, ConsolidatedReportListMainContext } from './provider/main'
import { HeaderMenu, StatefulTable } from '../../../components'
import { getColumns } from './consolidated-report-list'
import { Card } from 'antd'
import { UserDataContext } from '../../../contexts'
import { checkRole } from '@berry/common-functions/role'

const pageUrl = '/reports/consolidated-reports'
const rights = {
	'АРМ офиса': {
		'Сводные отчеты': {
			view: true,
		},
	},
}

const ConsolidatedReportList = (props) => {
	return (
		<Provider>
			<Main title="Сводные отчеты по ДЗ">
				<GiverReportsListTable />
			</Main>
		</Provider>
	)
}

const GiverReportsListTable = () => {
	const mainCtx = useContext(ConsolidatedReportListMainContext)
	const userDataCtx = useContext(UserDataContext)
	const canViewRow = checkRole(rights, userDataCtx.state.rights)
	return (
		<>
			<HeaderMenu breadcrumbsTitle="Сводные отчеты по ДЗ" search={mainCtx.setSearch} />
			<Card>
				<StatefulTable
					rowKey="id"
					columns={getColumns}
					url={pageUrl}
					canViewRow={canViewRow}
					searchFields={mainCtx.searchFields}
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columnProps={{ mainCtx, fromServerFilters: mainCtx.state.fromServerFilters }}
				/>
			</Card>
		</>
	)
}
export default ConsolidatedReportList
