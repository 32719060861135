import React, { useContext, useEffect, useRef, useCallback } from 'react'
import lodash from 'lodash'
import { ModContext } from '../../../../../contexts'
import staticData from '@berry/static-data'
import {
	getNewObj,
	isAvailableToAddToArr,
	modes,
	prepareObjFromServer,
	deepFind,
	editByUuid,
	validateRequired,
	useItemFetchers,
} from '../../../../../utils'
import { basicValidator } from '@berry/common-functions/validators'
import { getCommonProviderFunctions } from '../../../../../utils/helpers/generators'
import { useHistory } from 'react-router-dom'

const dataUrl = '/voc/editable/specification-parameter'
const tabs = { params: 'params' }
export const symbolSelectValues = [
	{
		value: null,
		text: 'Ничего не выбрано',
	},
	{
		value: '>',
		text: 'Больше чем',
	},
	{
		value: '<',
		text: 'Меньше чем',
	},
	{
		value: '=',
		text: 'Конкретное значение',
	},
	{
		value: '-',
		text: 'Диапазон',
	},
	{
		value: 'не доп.',
		text: 'Не допускается',
	},
]

export const reducer = (state) => {
	return {
		...state,
	}
}

const SpecParamContext = React.createContext()
SpecParamContext.displayName = 'SpecParamContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const history = useHistory()
	const [state, dispatch] = React.useReducer(reducer, {
		formErrors: {},
		data: {},
		oldData: {},
		additional: {
			allSpecParamNames: [],
		},
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
		})
		return
	}, [stateRef.current.oldData])

	const reset = () => {
		modCtx.set(modes.view)
		_reset()
		return
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * Валидация всех полей в соответствии с конфлюенсом
	 * @returns {string} - если вернулась строка и не пустая значит есть ошибка валидации
	 */
	const validate = () => {
		if (!stateRef.current.data.label) {
			throw Error('Проверьте правильность заполнения данных')
		}
		if (
			stateRef.current.additional.allSpecParamNames.some(
				(e) => e === String(stateRef.current.data.displayVal).toLowerCase()
			)
		) {
			throw Error()
		}
		return ''
	}

	const errorFields = {
		param: [
			'param.label',
			'param.vocMeasure',
			'param.etalonSymbol',
			'param.etalonVal1',
			'param.etalonVal2',
			'param.deviationSymbol',
			'param.deviationVal1',
			'param.deviationVal2',
			'param.etalonText',
		],
	}

	const validators = {
		params: (inUuid) => {
			const found = stateFunctions.params.get(inUuid)
			validateRequired(setError, 'param.label', '', found.label)
			if (found.isNumber) {
				validateRequired(setError, 'param.vocMeasure', '', found.vocMeasure)
				if (found.etalonSymbol === '-') {
					if (basicValidator(found.etalonVal1) && basicValidator(found.etalonVal2)) {
						if (+found.etalonVal1 >= +found.etalonVal2) {
							throw Error("Эталонное значение 'от' должно быть меньше эталонного значения 'до'")
						}
					}
				}
				if (found.deviationSymbol === '-') {
					if (basicValidator(found.deviationVal1) && basicValidator(found.deviationVal2)) {
						if (+found.deviationVal1 >= +found.deviationVal2) {
							throw Error("Значение отклонения 'от' должно быть меньше значения отклонения 'до'")
						}
					}
				}
			}
			if (errorFields.param.some((e) => stateRef.current.formErrors[e])) throw Error()
			const whereToFind = stateRef.current.data.params.filter((e) => e._uuid_ !== inUuid)
			if (
				whereToFind.some(
					(e) =>
						e.label.toLowerCase() === String(found.label).toLowerCase() &&
						((!e.vocMeasure && !found.vocMeasure) || +e.vocMeasure?.id === +found.vocMeasure?.id)
				)
			) {
				throw Error()
			}
		},
	}

	/**
	 * подготавливает данные которые нужно выбират
	 */
	const selectors = {
		params: {
			vocMeasure: staticData.measure.map((m) => ({
				...m,
				displayVal: m.label,
			})),
			etalonSymbol: (inUuid) => symbolSelectValues,
			deviationSymbol: (inUuid) => symbolSelectValues,
		},
	}

	const {
		getEditedData,
		isEdited,
		stateFunctions,
		serverDelete,
		serverEdit,
		commonFieldUpdate,
		setError,
		commonDeepFieldUpdate,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{ modCtx, dataUrl, params, pageUrl: '/vocabularies/specification-parameter', history },
		selectors,
		{
			label: 'common',
		},
		{
			params: {
				vocMeasure: 'common',
				label: 'common',
				etalonText: 'common',
				deviationText: 'common',
				isText: 'common',
				isNumber: 'common',
				etalonVal1: 'common',
				etalonVal2: 'common',
				etalonSymbol: 'common',
				deviationVal1: 'common',
				deviationVal2: 'common',
				deviationSymbol: 'common',
			},
		}
	)

	stateFunctions.params.create = (inData) => {
		let newArr
		if (isAvailableToAddToArr(inData)) {
			newArr = [
				...(stateRef.current.data.params || []),
				getNewObj({ ...inData, isNumber: true, etalonSymbol: null, deviationSymbol: null }),
			]
		} else {
			newArr = [
				...(stateRef.current.data.params || []),
				getNewObj({ isNumber: true, etalonSymbol: null, deviationSymbol: null }),
			]
		}
		commonFieldUpdate('params', newArr)
		return newArr.slice(-1)[0]._uuid_
	}

	stateFunctions.params.setIsText = (inUuid, val) => {
		if (deepFind(['params'], [inUuid], stateRef.current.data).isText) {
			return
		}
		commonDeepFieldUpdate(['params'], [inUuid], 'isText', val)
		commonDeepFieldUpdate(['params'], [inUuid], 'isNumber', !val)
		const newParam = lodash.cloneDeep(deepFind(['params'], [inUuid], stateRef.current.data))
		newParam.vocMeasure = null
		newParam.etalonVal1 = null
		newParam.etalonSymbol = null
		newParam.etalonVal2 = null
		newParam.deviationVal1 = null
		newParam.deviationSymbol = null
		newParam.deviationVal2 = null
		const newArr = editByUuid(inUuid, stateRef.current.data.params, newParam)
		commonFieldUpdate('params', newArr)
		const fields = [
			'param.etalonVal1',
			'param.etalonSymbol',
			'param.etalonVal2',
			'param.deviationVal1',
			'param.deviationSymbol',
			'param.deviationVal2',
			'param.vocMeasure',
		]
		fields.forEach((e) => {
			setError([], [], e, undefined)
		})
	}
	stateFunctions.params.setIsNumber = (inUuid, val) => {
		if (deepFind(['params'], [inUuid], stateRef.current.data).isNumber) {
			return
		}
		commonDeepFieldUpdate(['params'], [inUuid], 'isNumber', val)
		commonDeepFieldUpdate(['params'], [inUuid], 'isText', !val)
		commonDeepFieldUpdate(['params'], [inUuid], 'deviationText', null)
		commonDeepFieldUpdate(['params'], [inUuid], 'etalonText', null)

		setError([], [], 'param.etalonText', undefined)
	}
	stateFunctions.params.setEtalonVal1 = (inUuid, val) => {
		if (val === '') val = null
		commonDeepFieldUpdate(['params'], [inUuid], 'etalonVal1', val)
		if (val !== null) {
			commonDeepFieldUpdate(['params'], [inUuid], 'etalonSymbol', '-')
		}
		validateRequired(setError, 'param.etalonVal1', '', val)
	}
	stateFunctions.params.setEtalonSymbol = (inUuid, val) => {
		commonDeepFieldUpdate(['params'], [inUuid], 'etalonSymbol', val)
		commonDeepFieldUpdate(['params'], [inUuid], 'etalonVal1', null)
		commonDeepFieldUpdate(['params'], [inUuid], 'etalonVal2', null)
		if (['не доп.', null].includes(val)) {
			setError([], [], 'param.etalonVal1', undefined)
			setError([], [], 'param.etalonVal2', undefined)
		}
		if (['<', '>'].includes(val)) {
			setError([], [], 'param.etalonVal1', undefined)
		}
	}
	stateFunctions.params.setEtalonVal2 = (inUuid, val) => {
		if (val === '') val = null
		commonDeepFieldUpdate(['params'], [inUuid], 'etalonVal2', val)
		validateRequired(setError, 'param.etalonVal2', '', val)
	}
	stateFunctions.params.setDeviationVal1 = (inUuid, val) => {
		if (val === '') val = null
		commonDeepFieldUpdate(['params'], [inUuid], 'deviationVal1', val)
		if (val !== null) {
			commonDeepFieldUpdate(['params'], [inUuid], 'deviationSymbol', '-')
		}
		validateRequired(setError, 'param.deviationVal1', '', val)
	}
	stateFunctions.params.setDeviationSymbol = (inUuid, val) => {
		commonDeepFieldUpdate(['params'], [inUuid], 'deviationSymbol', val)
		commonDeepFieldUpdate(['params'], [inUuid], 'deviationVal1', null)
		commonDeepFieldUpdate(['params'], [inUuid], 'deviationVal2', null)
		if (['не доп.', null].includes(val)) {
			setError([], [], 'param.deviationVal1', undefined)
			setError([], [], 'param.deviationVal2', undefined)
		}
		if (['<', '>'].includes(val)) {
			setError([], [], 'param.deviationVal1', undefined)
		}
	}
	stateFunctions.params.setDeviationVal2 = (inUuid, val) => {
		if (val === '') val = null
		commonDeepFieldUpdate(['params'], [inUuid], 'deviationVal2', val)
		validateRequired(setError, 'param.deviationVal2', '', val)
	}

	const value = {
		state: stateRef.current.data,
		additional: stateRef.current.additional,
		stateFunctions,
		validators,
		selectors,
		serverEdit,
		serverDelete,
		getEditedData,
		isEdited,
		validate,
		reset,
		setError,
		errorFields,
		formErrors: stateRef.current.formErrors,
		delEditDeps: stateRef.current.delEditDeps,
	}

	return <SpecParamContext.Provider value={value}>{children}</SpecParamContext.Provider>
}

export { Provider, SpecParamContext }
