import { DatePicker, Typography } from 'antd'
import { modes } from '../../../../utils'
import { colorizeIntegrationStatus } from '../../../../utils/helpers/for-integration'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
const { Title } = Typography

const layout1 = {
	labelCol: { xs: 24, sm: 8, xl: 10, xxl: 8 },
	wrapperCol: { xs: 24, sm: 14, xl: 10 },
}

const layout2 = {
	labelCol: { xs: 24, sm: 8, xl: 8 },
	wrapperCol: { xs: 24, sm: 16, xl: 16 },
}

const layout3 = {
	labelCol: { xs: 24, sm: 8, xl: 7 },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}

export const getFormItems = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				key: 'req-title',
				field: <Title level={5}>Основные данные</Title>,
			},
			...(modCtx.mod !== modes.new
				? [
						{
							layout: layout1,
							label: 'ID утилизации',
							key: 'id',
							name: 'id',
							field: mainCtx.state.id,
						},
				  ]
				: []),
			{
				key: 'date',
				name: 'date',
				layout: layout1,
				label: 'Дата утилизации',
				rules: [{ required: true }],
				field: (
					<DatePicker
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format="DD.MM.YYYY"
						allowClear={false}
						suffixIcon={null}
						onChange={mainCtx.stateFunctions.setDate}
						style={{ width: '100%' }}
						disabled={isDisabled}
					/>
				),
			},
		],
		[
			{ key: 'mid-column' },
			{
				key: 'weight',
				layout: layout2,
				label: 'Общая масса, кг',
				name: 'weight',
				field: numToFixed(
					mainCtx.state.productions.reduce((sum, cur) => sum + +cur.weight || 0, 0),
					2
				),
			},
		],
		[
			{ key: 'last-column' },
			{
				key: 'status',
				name: 'status',
				layout: layout3,
				label: 'Статус',
				...colorizeIntegrationStatus(mainCtx.state.status),
			},

			{
				key: 'idOrder',
				layout: layout3,
				label: 'ID ДЗ',
				field:
					mainCtx.state.idContrOrder ||
					mainCtx.state.productions[0]?.idContrtOrder ||
					mainCtx.state.productions[0]?.stockWasteStor?.idContrOrder ||
					mainCtx.state.productions[0]?.stockSampleStor?.idContrOrder,
			},
		],
	]
}
