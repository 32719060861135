import { getRowSpan, sorter, getColumnSearchProps } from '../../../../utils'
import { getEtalon, getDeviation } from '../spec-param'
import '../../../../index.css'

export const getColumns = ({ fromServerFilters }) => {
	return [
		{
			title: 'Раздел',
			key: 'label',
			dataIndex: 'label',
			className: 'elemAlignTop',
			...getColumnSearchProps(fromServerFilters.label),
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.params.length),
			}),
			width: '20%',
		},
		{
			title: 'Параметр',
			dataIndex: 'preparedParams',
			render: (_, record) => record.param?.label,
			...getColumnSearchProps(fromServerFilters.paramLabel.flat()),
			sorter: sorter('string', 'preparedParams'),
			width: '20%',
		},
		{
			title: 'ЕИ',
			dataIndex: 'param.vocMeasure',
			render: (_, record) => {
				return record.param?.vocMeasure?.labelShort
			},
			width: '5%',
		},
		{
			title: 'Цель',
			dataIndex: 'param.etalon',
			width: '15%',
			render: (text, record) => (
				<div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
					{getEtalon(record.param)}
				</div>
			),
		},
		{
			title: 'Допустимое отклонение',
			dataIndex: 'param.deviation',
			width: '30%',
			render: (text, record) => (
				<div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
					{getDeviation(record.param)}
				</div>
			),
		},
	]
}
