import { Checkbox, Input } from 'antd'
import { modes } from '../../../../utils'

export const getFormItems = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				label: 'Должность',
				name: 'label',
				wrapperCol: { span: 10 },
				rules: [
					{ required: true },
					{
						validator: async (_, value) => {
							if (
								mainCtx.additional.allPosTypeLabels.some(
									(label) => label === String(value).toLowerCase()
								)
							)
								throw Error('Такая должность уже существует')
						},
					},
				],
				field: (
					<Input
						style={{ width: '100%' }}
						value={mainCtx.state.label}
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setLabel(e.target.value)
						}}
					/>
				),
			},
		],
		[
			{
				labelCol: { offset: 6 },
				label: 'Пользователь',
				key: 'isUser',
				field: (
					<Checkbox
						disabled={isDisabled}
						checked={mainCtx.state.isUser}
						onChange={(e) => mainCtx.stateFunctions.setIsUser(e.target.checked)}
					/>
				),
			},
		],
		[
			{
				labelCol: { offset: 6 },
				label: 'Ответственный за производственную задачу',
				key: 'inChargeOfTask',
				field: (
					<Checkbox
						disabled={isDisabled}
						checked={mainCtx.state.inChargeOfTask}
						onChange={(e) => mainCtx.stateFunctions.setInChargeOfTask(e.target.checked)}
					/>
				),
			},
		],
	]
}

export const getPath = (inData, f, path = []) => {
	if (Array.isArray(inData)) {
		if (inData.includes(f)) {
			return [...path, f]
		}
		const objects = inData.filter((el) => typeof el === 'object')
		for (const obj of objects) {
			if (getPath(obj, f, path)) {
				return getPath(obj, f, path)
			}
		}
	} else {
		for (const key in inData) {
			if (key === f) {
				return [...path, key]
			}
			const newPath = getPath(inData[key], f, [...path, key])
			if (newPath) {
				return newPath
			}
		}
	}
	return false
}
