import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import lodash from 'lodash'
import { axios } from '../../utils'
import { AuthContext, UserDataContext } from '../index'
import { checkIsNot } from '@berry/common-functions/obj-arr'
import { useLocation } from 'react-router-dom'
import { showConfirmModal } from '../../components'
import { showUpdateModal } from '../../utils/constants/for-components'
import { Modal } from 'antd'
import localforage from 'localforage'
import { isValidNum } from '@berry/common-functions/validators'

export const reducer = (state) => {
	return {
		...state,
	}
}
const differBackFrontUrls = {
	'/rp/customers': ['/rp/customers'],
	'/rm/providers': ['/rm/providers'],
	'/reg/specifications': ['/reg/specifications'],
	'/contracts': ['/rm/contracts-providers', '/rp/contracts-customers'],
	'/product-catalogs': ['/pv/product-catalog'],
	'/rm/requests': ['/rm/supply-requests'],
	'/rm/supplies': ['/rm/supplies'],
	'/reg/staff': ['/reg/staff'],
	'/rm/supply-input-controls': ['/rm/supply-input-controls'],
	'/rm/supply-unloads': ['/rm/supply-unloads'],
	'/giver-prod/consolidated-reports': ['/reports/consolidated-reports'],
}

const dataUrls = [
	'/voc/editable/container-type',
	// '/voc/editable/delivery-condition',
	// '/voc/editable/equipment',
	// '/voc/editable/manufactory-purpose',
	// '/voc/editable/operation',
	// '/voc/editable/package-type',
	// '/voc/editable/payment-condition',
	// '/voc/editable/position-type',
	// '/voc/editable/production-area',
	// '/voc/editable/quality',
	// '/voc/editable/raw-material-manufacturer',
	// '/voc/editable/raw-material-country',
	// '/voc/editable/raw-mat-shelf-life',
	// '/voc/editable/car-state',
	// '/voc/editable/documentation',
	// '/voc/editable/warehouse-type',
	// '/voc/editable/timely-export',
	// '/voc/static/facility-type',
	// '/voc/static/matching-status',
	// '/voc/static/product-type',
	// '/voc/static/staff-status',
	// '/voc/static/supply-status',
	// '/voc/editable/product-1c',
	// '/voc/editable/process',
	// '/voc/static/type-reason-break',
	// '/voc/editable/additional-semi-type',
	// '/voc/editable/main-semifinished-kind',
	// '/voc/editable/working-time',
	// '/voc/editable/raw-mat-state',
	// '/voc/editable/ready-product-form',
	// '/voc/editable/temperature-regime',
	// '/voc/editable/waste-type',
	// '/voc/common/measure',
	// '/rp/customer',
	// '/rm/provider',
	// '/reg/specification',
	// '/contract',
	// '/voc/editable/product-group',
	// '/voc/editable/specification-parameter',
	// '/product-catalog',
	// '/data-dependency',
	// '/rm/request',
	// '/rm/supply',
	// '/reg/staff',
	// '/rm/supply-input-control',
	// '/rm/supply-unload',
	// '/voc/editable/room',
	// '/stock/raw-mat',
	// '/stock/semif',
	// '/stock/ready-prod',
	// '/stock/waste',
	// '/stock/sample',
	// '/stock/operations/disposal',
	// '/production/task',
	// '/production/task/task-num',
	// '/rp/implementation',
	// '/giver-prod/consolidated-report',
	// '/other-data',
]

const initialState = dataUrls.reduce((acc, cur) => {
	return {
		...acc,
		[cur]: {
			isInitialized: false,
			isLoading: false,
			data: [],
		},
	}
}, {})

const DataServerContext = React.createContext()
DataServerContext.displayName = 'DataServerContext'
const splittedLocation = window.location.pathname.split('/')
if (splittedLocation.length > 1) {
	if (isValidNum(splittedLocation[splittedLocation.length - 1])) {
		splittedLocation.pop()
	}
}

const Provider = (props) => {
	const { children } = props
	const location = useLocation()
	const [needToFetch, setNeedToFetch] = useState(false)
	const locationPathRef = useRef(location.pathname)
	const locationStateRef = useRef(location.state)
	const confirmModalRef = useRef(null)
	const clonedState = useRef({ isLoading: false, data: null })

	useEffect(() => {
		locationStateRef.current = location.state
	}, [location.state])
	const updateSseFn = async (response) => {
		const { data = {}, params } = response.sseResponse || {}
		const splittedUrl = locationPathRef.current.split('/')
		const frontUrls = differBackFrontUrls[params.url] || [getUrl(params.url)] || [params.url]
		const isSpecSettingsPage =
			locationPathRef.current.includes('system-settings') &&
			splittedUrl[splittedUrl.length - 1].includes('spec')
		const isSupplySettingsPage =
			locationPathRef.current.includes('system-settings') &&
			splittedUrl[splittedUrl.length - 1].includes('supply')
		const isSame1cEntity = Object.keys(data.result.other || {}).some((key) => {
			return data.result.other[key].some((el) => {
				return (
					el.data.uuid1C &&
					data.result.data.uuid1C &&
					el.data.uuid1C === data.result.data.uuid1C &&
					differBackFrontUrls[key].includes(splittedUrl.slice(0, -1).join('/'))
				)
			})
		})
		if (
			(isSpecSettingsPage && params.isSpecSettings) ||
			(isSupplySettingsPage && params.isSupplySettings) ||
			isSame1cEntity
		) {
			if (confirmModalRef.current) {
				confirmModalRef.current.destroy()
			}
			const modal = Modal.confirm({
				...showUpdateModal,
				cancelButtonProps: { style: { display: 'none' } },
			})
			confirmModalRef.current = modal
		}
		if (isValidNum(+splittedUrl[splittedUrl.length - 1])) {
			const foundFrontUrlStartsWith = frontUrls.find((url) =>
				locationPathRef.current.startsWith(url)
			)
			if (
				foundFrontUrlStartsWith ||
				`${params.url.replace('/voc/editable', '/vocabularies')}/${data.result?.data?.id}` ===
					locationPathRef.current
			) {
				if (String(data?.result?.data?.id) === String(splittedUrl[splittedUrl.length - 1])) {
					Modal.destroyAll()
					showConfirmModal({
						...showUpdateModal,
					})
				}
			}
		}
		if (!stateRef.current[params.url]) {
			await sendRequestToServer(data.result?.data, params, response.sseResponse)
		} else {
			await update(data.result?.data, params, response.sseResponse)
		}
	}

	const updateVocSseFn = async (response) => {
		const { sseResponse: { data = {}, params = {} } = {} } = response || {}
		if (params.sse) {
			if (params.url.replace('/voc/editable', '/vocabularies') === locationPathRef.current) {
				Modal.destroyAll()
				showConfirmModal({
					...showUpdateModal,
				})
			}
		}
		await updateData(data, params)
	}
	const {
		state: { isAuthenticated },
	} = useContext(AuthContext)
	const {
		state: { isInitialized: isUserDataInitialized },
	} = useContext(UserDataContext)
	const isInitialMount = useRef(true)
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const stateRef = useRef(state)
	const executeDispatch = (newState, options) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	const getAll = useCallback(() => {
		const loadData = async () => {
			try {
				if (!isAuthenticated) return
				const newState = { ...stateRef.current }
				for (let url in newState) {
					newState[url].isLoading = false
					newState[url].isInitialized = true
				}
				executeDispatch(newState)
			} catch (err) {
				if (isAuthenticated) {
					const newState = { ...stateRef.current }
					for (let url in newState) {
						newState[url].isLoading = false
						newState[url].isInitialized = true
						newState[url].data = []
					}
					clonedState.current = {
						isLoading: false,
						data: lodash.cloneDeep(newState),
					}

					executeDispatch(newState)
				}
			}
		}
		const urls = dataUrls.filter((url) => !stateRef.current[url].isInitialized)
		try {
			if (urls.some((url) => !stateRef.current[url] || !stateRef.current[url].isLoading)) {
				loadData()
			}
		} catch (err) {
			console.log(err)
		}
	}, [isAuthenticated])

	useEffect(() => {
		const getLocalData = async () => {
			let localData = null
			const openNewTabData = await localforage.getItem('__openNewTab')
			if (openNewTabData) {
				await localforage.removeItem('__openNewTab')
				for (let i = 0; i < 10; i++) {
					await new Promise((resolve) => {
						setTimeout(() => {
							resolve()
						}, 500)
					})
					localData = await localforage.getItem('dataServerCtx')
					if (localData) break
				}
			}
			if (localData) {
				const prevState = JSON.parse(localData)
				const clonedPrevState = JSON.parse(localData)
				if (checkIsNot(prevState, dataUrls, [undefined])) {
					await localforage.removeItem('dataServerCtx')
					setNeedToFetch(false)
					clonedState.current = {
						isLoading: false,
						data: clonedPrevState,
					}
					return executeDispatch(prevState)
				}
			} else {
				setNeedToFetch(true)
			}
		}
		getLocalData()
	}, [])

	// каждый раз когда происходит обновление зависимостей ,
	// исключая инициализационный маунт
	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false
		} else {
			const dropAll = async () => {
				if (!isAuthenticated) {
					executeDispatch(
						dataUrls.reduce((acc, cur) => {
							return {
								...acc,
								[cur]: {
									isInitialized: false,
									isLoading: false,
									data: [],
								},
							}
						}, {})
					)
					await localforage.removeItem('dataServerCtx')
					setNeedToFetch(true)
				}
			}
			dropAll()
		}
	}, [isAuthenticated, isUserDataInitialized])

	// Каждый раз когда происходит обновление зависимостей
	// вместе с инициализационным маунтом
	useEffect(() => {
		const autoGetAll = () => {
			if (isAuthenticated && isUserDataInitialized) {
				if (Object.values(stateRef.current).some((u) => !u.isInitialized) && needToFetch) {
					getAll()
				}
			}
		}
		autoGetAll()
	}, [getAll, isAuthenticated, isUserDataInitialized, needToFetch])

	const isAllLoaded = () => {
		return Object.values(stateRef.current).every((url) => url.isInitialized)
	}

	/**
	 * Ищет в стейте данные (нужно для ссылок типа /stock/raw-mat/utilization)
	 * @param {Object} inData - Изменяемый обьект
	 * @param {{url:string, method:string}} params - параметры
	 */
	const getUrl = useCallback((url) => {
		if (!url) return ''
		if (stateRef.current[url]?.data) {
			return url
		}
		return getUrl(url.split('/').slice(0, -1).join('/'))
	}, [])

	/**
	 * Добавляет элемент на сервер и обновляет контекст
	 * @param {(Object|Object[])} inData - Добавляемый обьект или масив
	 * @param {{url:string}} params - параметры
	 */
	const add = async (inData, params) => {
		if (!params.url) throw Error('params.url is required')
		const res = await axios.post(params.url, inData)
		const urlData = [...stateRef.current[params.url].data]
		let newData = [...lodash.cloneDeep(urlData), res.data.result]
		executeDispatch({
			...stateRef.current,
			[params.url]: {
				...stateRef.current[params.url],
				data: newData,
			},
		})
		return
	}

	const _sendRequest = async (inData, params) => {
		switch (params.method) {
			case 'POST':
				return await axios.post(params.url, inData)
			case 'PUT':
				return await axios.put(params.url, inData)
			case 'DELETE':
				return await axios.delete(`${params.url}/${inData.id}`)
			default:
				throw Error('неправильный метод')
		}
	}
	/**
	 * Обновляет элемент на сервер и обновляет контекст
	 * @param {Object} inData - Изменяемый обьект
	 * @param {{url:string, method:string}} params - параметры
	 */
	const sendRequestToServer = useCallback(async (inData, params, sseResponse = {}) => {
		try {
			let res
			let reqData = {}
			if (params.sse) {
				res = sseResponse
			} else if (params.dataUrl) {
				const urlData = [...stateRef.current[params.dataUrl].data]
				const foundIndx = stateRef.current[params.dataUrl].data.findIndex(
					(e) => String(e.id) === String(inData.id)
				)
				if (foundIndx === -1) {
					throw Error(`Элемент ${params.dataUrl}:${inData.id} не найден`)
				}
				let newData = urlData
				let data = []
				switch (params.method) {
					case 'POST':
						res = await axios.post(params.url, inData)
						data = res.data.result.data
						if (!data) break
						newData = [...urlData, ...(Array.isArray(data) ? data : [data])]
						break
					case 'PUT':
						res = await axios.put(params.url, inData)
						if (res.data.err) {
							throw Error(res.data.err)
						}
						data = res.data.result.data
						if (!data) break
						newData = Array.isArray(data)
							? urlData.map((el) => {
									const newEl = data.find((newEl) => newEl.id === el.id)
									if (newEl) {
										return newEl
									}
									return el
							  })
							: [
									...urlData.slice(0, foundIndx),
									data,
									...urlData.slice(foundIndx + 1, urlData.length),
							  ]
						break
					case 'DELETE':
						res = await axios.delete(`${params.url}/${inData.id}`)
						newData = [
							...urlData.slice(0, foundIndx),
							...urlData.slice(foundIndx + 1, urlData.length),
						]
						break
					default:
						throw Error('неправильный метод')
				}
				reqData = {
					[params.dataUrl]: {
						...stateRef.current[params.dataUrl],
						data: newData,
					},
				}
			} else {
				res = await _sendRequest(inData, params)
			}
			const newDeps = lodash.cloneDeep(stateRef.current['/data-dependency'].data)
			Object.entries(res.data.result.deps || {}).forEach(([k, v]) => {
				Object.entries(v).forEach(([id, deps]) => {
					if (!newDeps[k]) newDeps[k] = {}
					newDeps[k][id] = deps
				})
			})

			let actualState = {}

			const others = Object.entries(res?.data?.result?.other || {})
			const deleted = Object.entries(res?.data?.result?.deleted || {})
			if (others.length === 0) {
				actualState = {}
			}
			_handleOthers(others, actualState, newDeps, {
				...stateRef.current,
			})
			_handleDeleted(deleted, actualState, {
				...stateRef.current,
			})
			const toDispatch = {
				...stateRef.current,
				...actualState,
				'/data-dependency': {
					...stateRef.current['/data-dependency'],
					data: newDeps,
				},
			}
			if (reqData && params.dataUrl && reqData[params.dataUrl] && !actualState[params.dataUrl]) {
				toDispatch[params.dataUrl] = {
					...reqData[params.dataUrl],
				}
			}
			executeDispatch(toDispatch)
			const result = res.data.result.data
			return params.isReturnAllBody ? result : result?.[0]?.id
		} catch (err) {
			console.log(err)
		}
	}, [])

	const _handleOthers = (inOthers, actualState, newDeps, curData) => {
		inOthers.forEach(([key, value]) => {
			let curState = curData[key].data
			value.forEach((el) => {
				let findIndex = curState.findIndex((e) => String(e.id) === String(el?.data?.id))
				if (findIndex !== -1) {
					curState = [
						...curState.slice(0, findIndex),
						el.data,
						...curState.slice(findIndex + 1, curState.length),
					]
				} else {
					curState = [...curState, el.data]
				}
				Object.entries(el.deps).forEach(([k, v]) => {
					Object.entries(v).forEach(([id, deps]) => {
						if (!newDeps[k]) newDeps[k] = {}
						newDeps[k][id] = deps
					})
				})
			})
			actualState[key] = { ...stateRef.current[key], data: curState }
		})
	}

	const _handleDeleted = (inDeleted, actualState, curData) => {
		inDeleted.forEach(([key, value]) => {
			let itemData = actualState?.[key]?.data || curData[key].data
			value.forEach((el) => {
				let findIndex = itemData.findIndex((e) => String(e.id) === String(el))
				if (findIndex !== -1) {
					itemData = [
						...itemData.slice(0, findIndex),
						...itemData.slice(findIndex + 1, itemData.length),
					]
				}
			})
			actualState[key] = { ...stateRef.current[key], data: itemData }
		})
		return
	}
	/**
	 * Обновляет элемент на сервер и обновляет контекст
	 * @param {Object} inData - Изменяемый обьект
	 * @param {{url:string, method:string}} params - параметры
	 */
	const update = useCallback(
		async (inData, params, sseResponse = {}) => {
			if (!params.dataUrl) {
				params.dataUrl = getUrl(params.url)
			}
			let foundIndx
			if (!Array.isArray(inData) && !(inData instanceof FormData)) {
				if ((!inData?.id && params.method !== 'POST') || !params.url)
					throw Error('Параметры переданы неверно')
				foundIndx = stateRef.current[params.dataUrl].data.findIndex(
					(e) => String(e.id) === String(inData.id)
				)

				if (foundIndx === -1 && params.method !== 'POST')
					throw Error('Обновляемый элемент не найден')
			} else {
				for (const value of inData.values()) {
					try {
						if (JSON.parse(value)) {
							const data = JSON.parse(value)
							foundIndx = stateRef.current[params.dataUrl].data.findIndex(
								(e) => String(e.id) === String(data.id)
							)
						}
					} catch (err) {
						console.log(err.message)
					}
				}
			}

			let res
			if (params.sse) {
				res = sseResponse
			}
			const urlData = [...stateRef.current[params.dataUrl].data]
			let newData = urlData
			try {
				switch (params.method) {
					case 'POST':
						if (!params.sse) res = await axios.post(params.url, inData)

						if (res) {
							newData = [...urlData, res?.data?.result?.data]
						}
						break
					case 'PUT':
						if (!params.sse) res = await axios.put(params.url, inData)

						const data = res.data.result.data
						if (Array.isArray(data)) {
							newData = urlData
							data.forEach((item) => {
								const dataElIndex = stateRef.current[params.dataUrl].data.findIndex(
									(e) => String(e.id) === String(item.id)
								)
								if (dataElIndex === -1) throw Error('индекс не найден')
								newData = [
									...newData.slice(0, dataElIndex),
									item,
									...newData.slice(dataElIndex + 1, newData.length),
								]
							})
						} else {
							newData = [
								...urlData.slice(0, foundIndx),
								data,
								...urlData.slice(foundIndx + 1, urlData.length),
							]
						}
						break
					case 'DELETE':
						if (!params.sse) res = await axios.delete(`${params.url}/${inData.id}`)

						newData = [
							...urlData.slice(0, foundIndx),
							...urlData.slice(foundIndx + 1, urlData.length),
						]
						break
					default:
						throw Error('неправильный метод')
				}
			} catch (err) {
				console.log(err)
				throw err
			}

			const newDeps = lodash.cloneDeep(stateRef.current['/data-dependency'].data)
			Object.entries(res.data.result.deps).forEach(([k, v]) => {
				Object.entries(v).forEach(([id, deps]) => {
					if (!newDeps[k]) newDeps[k] = {}
					newDeps[k][id] = deps
				})
			})

			let actualState = {}
			const others = Object.entries(res?.data?.result?.other || {})
			const deleted = Object.entries(res?.data?.result?.deleted || {})
			if (others.length === 0) {
				actualState = {
					[params.url]: {
						...stateRef.current[params.dataUrl],
						data: newData,
					},
				}
			}
			_handleOthers(others, actualState, newDeps, {
				...stateRef.current,
				[params.dataUrl]: {
					...stateRef.current[params.dataUrl],
					data: newData,
				},
			})
			_handleDeleted(deleted, actualState, {
				...stateRef.current,
				[params.dataUrl]: {
					...stateRef.current[params.dataUrl],
					data: newData,
				},
			})
			executeDispatch({
				...stateRef.current,
				[params.url]: {
					...stateRef.current[params.dataUrl],
					data: newData,
				},
				...actualState,
				'/data-dependency': {
					...stateRef.current['/data-dependency'],
					data: newDeps,
				},
			})
			const result = params.sse ? inData : res.data.result.data
			return params.isReturnAllBody ? result : result.id
		},
		[getUrl]
	)

	const updateV2 = async (inData, params) => {
		let res

		try {
			switch (params.method) {
				case 'POST':
					res = await axios.post(params.url, inData)

					break
				case 'PUT':
					res = await axios.put(params.url, inData)

					break
				case 'DELETE':
					res = await axios.delete(`${params.url}/${inData.id}`)

					break
				default:
					throw Error('неправильный метод')
			}
			return res.data
		} catch (err) {
			console.log(err)
			throw err
		}
	}

	/**
	 * Удаляет элемент на сервере и внутри контекста
	 * @param {string|number} inId - параметры
	 * @param {{url:string,id:number}} params - параметры
	 */
	const remove = async (inId, params) => {
		if (!params.url || !params.id) throw Error('Параметры переданы неверно')
		const foundIndx = stateRef.current[params.url].data.findIndex(
			(e) => String(e.id) === String(inId)
		)
		if (foundIndx === -1) throw Error('Удаляемый элемент не найден')
		await axios.delete(`${params.url}/${inId}`)
		const urlData = [...stateRef.current[params.url].data]
		let newData = [...urlData.slice(0, foundIndx), ...urlData.slice(foundIndx + 1, urlData.length)]
		executeDispatch({
			...stateRef.current,
			[params.url]: {
				...stateRef.current[params.url],
				data: newData,
			},
		})
		return
	}

	/**
	 * Ищет элемент внутри контекста
	 * @param {{url:string,id:number}} params - параметры
	 * @param {{withError:boolean}} options
	 */
	const findRecord = (params, options = { withError: true, initialState: {} }) => {
		const errOrEmpty = (text) => {
			if (options.withError) throw Error(text)
			return {
				...options.initialState,
				__notFound: true,
			}
		}
		if (!params.url || !params.id) return errOrEmpty('Параамтеры неверны')
		if (!stateRef.current[params.url]) return errOrEmpty('Url wrong')
		const foundIndx = stateRef.current[params.url].data.findIndex(
			(e) => String(e.id) === String(params.id)
		)
		if (foundIndx === -1) return errOrEmpty('Запись не найдена')
		return stateRef.current[params.url].data[foundIndx]
	}

	/**
	 * Обновляет полностью объект
	 * @param {Object} inData - обновленный объект
	 * @param {{url:string}} params - параметры
	 */
	const updateData = (inData, params) => {
		const { url, method } = params
		const { data = [] } = stateRef.current[url] || {}
		let newData = []
		switch (method) {
			case 'POST':
				newData = [...data, inData?.result?.data]
				break
			case 'PUT':
				newData = data.map((el) =>
					el.id === inData?.result?.data?.id ? { ...el, ...inData?.result?.data } : el
				)
				break
			case 'DELETE':
				newData = data.filter((el) => String(el.id) !== String(inData?.result?.id))
				break
			default:
				break
		}
		executeDispatch({
			...stateRef.current,
			[url]: {
				...stateRef.current[url],
				data: newData,
			},
		})
	}
	const value = {
		state: stateRef.current,
		clonedState: clonedState.current.data,
		add: add,
		updateV2,
		update: update,
		remove: remove,
		isAllLoaded: isAllLoaded,
		findRecord: findRecord,
		updateData,
		updateVocSseFn,
		updateSseFn,
		sendRequestToServer,
	}

	return <DataServerContext.Provider value={value}>{children}</DataServerContext.Provider>
}
export { Provider, DataServerContext }
