import React, { useContext } from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import { ModContext } from '../../contexts'

import { modes } from '../../utils'

/**
 * рисует элемент хлебных кролшек
 * @param {Object} props
 * @param {String} props.link - ссылка
 * @param {String} props.title - как называется
 */
const CustomBreadcrumbItem = (props) => {
	const modCtx = useContext(ModContext)
	if (props.link && modCtx?.mod !== modes.edit && modCtx?.mod !== modes.new)
		return (
			<Breadcrumb.Item key={props.title} className="ant-breadcrumb ant-breadcrumb-link">
				<Link to={props.link}>{props.title}</Link>
			</Breadcrumb.Item>
		)
	return <Breadcrumb.Item key={props.title}>{props.title}</Breadcrumb.Item>
}

export default CustomBreadcrumbItem
