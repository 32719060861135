import React, { useEffect } from 'react'
import { Form as FormAntd, Input } from 'antd'

const formItemLayout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

const Form = ({ data, recordId, form, action, options }) => {
	useEffect(() => {
		form.resetFields()
	}, [form])

	return (
		<FormAntd
			form={form}
			validateMessages={{ required: 'Поле обязательно для заполнения!' }}
			initialValues={data.find((el) => String(el.id) === String(recordId)) || {}}
			onFinish={action}
			labelAlign="left"
		>
			<FormAntd.Item
				{...formItemLayout}
				label="Наименование"
				name="label"
				rules={[
					{
						required: true,
					},
				]}
			>
				<Input />
			</FormAntd.Item>
		</FormAntd>
	)
}

export default Form
