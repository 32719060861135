import React, { useContext, useEffect, useRef, useCallback } from 'react'
import lodash from 'lodash'
import { ModContext } from '../../../../../contexts'
import {
	modes,
	getObjDiff,
	isEdited as isDataEdited,
	useItemFetchers,
	axios,
} from '../../../../../utils'

import { dataUrl } from '../../position-type'
import { getPath } from '../pos-type-item'

import { structure } from '@berry/static-data/position-type-data'

export const reducer = (state) => {
	return {
		...state,
	}
}

const PosTypeContext = React.createContext()
PosTypeContext.displayName = 'PosTypeContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const [state, dispatch] = React.useReducer(reducer, { data: { params: {} }, oldData: {} })
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = {
			...newState,
		}
		dispatch(newState)
	}

	useItemFetchers(dataUrl, params.id, undefined, stateRef, useCallback(executeDispatch, []))

	const commonFieldUpdate = (field, value) => {
		if (modCtx.mod === modes.view) return
		executeDispatch({
			...stateRef.current,
			data: {
				...stateRef.current.data,
				[field]: typeof value === 'object' ? lodash.cloneDeep(value) : value,
			},
		})
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
		})
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	const getEditedData = () => {
		if (modCtx.mod === modes.new) {
			return lodash.cloneDeep(stateRef.current.data)
		}

		return getObjDiff(stateRef.current.oldData, stateRef.current.data)
	}

	const isEdited = () => {
		if (modCtx.mod === modes.view) return false

		const editedFields = getEditedData()
		return isDataEdited(editedFields)
	}

	const validate = () => {
		return ''
	}

	const getDepsOn = () => []

	const serverEdit = async () => {
		if (!isEdited()) {
			return modCtx.set(modes.view)
		}
		let body = {}
		let options = { url: dataUrl }
		if (params.id === 'new') {
			options.method = 'POST'
		} else {
			options.method = 'PUT'
		}
		body = { ...stateRef.current.data, params: JSON.stringify(stateRef.current.data.params) }
		const res = await axios[options.method.toLowerCase()](options.url, body)
		modCtx.set(modes.view)
		executeDispatch({
			...stateRef.current,
			oldData: stateRef.current.data,
		})
		return { method: options.method, id: res.data.data.id }
	}

	const serverDelete = async () => {
		await axios.delete(`${dataUrl}/${+stateRef.current.data.id}`)
		return true
	}

	const stateFunctions = {
		setLabel: (val) => {
			commonFieldUpdate('label', val)
		},
		setIsUser: (bool) => {
			commonFieldUpdate('isUser', bool)
			commonFieldUpdate('params', {})
		},
		setInChargeOfTask: (bool) => {
			commonFieldUpdate('inChargeOfTask', bool)
		},
		setParams: (inData, action, value, tab = 'Карточки') => {
			const path =
				tab === 'Карточки' ? getPath(structure[tab], inData.label, []) : ['Справочники', inData]
			if (value) {
				const valueToSet = ['edit', 'match'].includes(action)
					? {
							view: value,
							[action]: value,
					  }
					: { [action]: value }
				const newProp = lodash.set({}, path, valueToSet)
				if (['Входной контроль', 'Разгрузка'].includes(inData.label)) {
					const additional = lodash.set({}, path.slice(0, path.length - 1), {
						'Основные данные': {
							view: true,
						},
					})
					lodash.merge(newProp, additional)
				}
				if (inData.label === 'Карточка Запасы') {
					const additional = lodash.set({}, path.slice(0, path.length - 1), {
						'Реестр Запасы': {
							view: true,
						},
					})
					lodash.merge(newProp, additional)
				}
				if (inData.label === 'Себестоимость') {
					const additional = lodash.set({}, path.slice(0, path.length - 2), {
						'Карточка Запасы': {
							view: true,
						},
						'Реестр Запасы': {
							view: true,
						},
					})
					lodash.merge(newProp, additional)
				}

				if (inData.label === 'Изменить статус') {
					const additional = lodash.set({}, path.slice(0, path.length - 2), {
						'Задачи на производство': {
							view: true,
							match: true,
						},
					})
					lodash.merge(newProp, additional)
				}
				commonFieldUpdate('params', lodash.merge(stateRef.current.data.params, newProp))
			} else {
				const valueToUnset =
					action === 'view'
						? {
								[action]: value,
								edit: value,
								match: value,
						  }
						: {
								[action]: value,
						  }
				const newProp = lodash.set({}, path, valueToUnset)
				if (inData.label === 'Основные данные') {
					const additional = lodash.set({}, path.slice(0, path.length - 1), {
						'Входной контроль': {
							[action]: value,
							edit: value,
							match: value,
						},
						Разгрузка: {
							[action]: value,
							edit: value,
							match: value,
						},
					})
					lodash.merge(newProp, additional)
				}
				if (inData.label === 'Спецификации') {
					stateRef.current.isInitiatorSpec = false
					stateRef.current.lvlMatchSpec = '-'
					stateRef.current.lvlApproveSpec = '-'
				}
				if (inData.label === 'Реестр Запасы') {
					const additional = lodash.set({}, path.slice(0, path.length - 1), {
						'Карточка Запасы': {
							view: false,
							Себестоимость: {
								view: false,
							},
						},
					})
					lodash.merge(newProp, additional)
				}
				if (inData.label === 'Задачи на производство') {
					const additional = lodash.set({}, path.slice(0, path.length - 1), {
						'Задачи на производство': {
							'Изменить статус': {
								edit: false,
							},
						},
					})
					lodash.merge(newProp, additional)
				}
				if (inData.label === 'Карточка Запасы') {
					const additional = lodash.set({}, path.slice(0, path.length - 1), {
						Себестоимость: {
							view: false,
						},
					})
					lodash.merge(newProp, additional)
				}
				commonFieldUpdate('params', lodash.merge(stateRef.current.data.params, newProp))
				if (
					['view', 'edit', 'match'].every(
						(act) => lodash.get(stateRef.current.data.params, [...path, act]) === false
					)
				) {
					lodash.unset(stateRef.current.data.params, path)
					if (!Object.keys(lodash.get(stateRef.current.data.params, path.slice(0, -1))).length) {
						lodash.unset(stateRef.current.data.params, path.slice(0, -1))
					}
				}
			}
		},
	}

	const value = {
		state: stateRef.current.data,
		additional: stateRef.current.additional,
		delEditDeps: stateRef.current.delEditDeps,
		stateFunctions,
		serverEdit,
		serverDelete,
		getEditedData,
		isEdited,
		validate,
		reset,
		getDepsOn,
	}

	return <PosTypeContext.Provider value={value}>{children}</PosTypeContext.Provider>
}

export { Provider, PosTypeContext }
