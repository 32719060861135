import { Input, Space, Typography } from 'antd'
import { modes } from '../../../utils'
import { WarningRed, WarningYellow } from '../../../assets'
import { setNumField } from '@berry/front-common/for-fields'

const { Title } = Typography

export const getFormItems = (mainCtx, modCtx, form) => [
	[
		{
			key: 'title',
			field: <Title level={5}>Уведомления об истекающем сроке годности</Title>,
		},
		{
			label: 'Отображать при остаточном сроке годности менее',
			key: 'stockNotifyYellow',
			name: 'stockNotifyYellow',
			required: true,
			rules: [{ required: true }],
			field: (
				<Space>
					<WarningYellow />
					<Input
						disabled={modCtx.mod === modes.view}
						value={mainCtx.state.data.stockNotifyYellow}
						name="stockNotifyYellow"
						style={{ width: 100 }}
						pattern="\d*"
						onChange={(e) =>
							setNumField(
								e.target.value,
								mainCtx.state.data.stockNotifyYellow,
								mainCtx.stateFunctions.fieldSetters.setStockNotifyYellow,
								'float',
								[5, 2],
								{ isOnlyNums: true }
							)
						}
					/>
					<Typography.Text>%</Typography.Text>
				</Space>
			),
		},
		{
			label: 'Отображать при остаточном сроке годности менее',
			key: 'stockNotifyRed',
			name: 'stockNotifyRed',
			required: true,
			rules: [{ required: true }],
			field: (
				<Space>
					<WarningRed />
					<Input
						disabled={modCtx.mod === modes.view}
						value={mainCtx.state.data.stockNotifyRed}
						style={{ width: 100 }}
						pattern="\d*"
						name="stockNotifyRed"
						onChange={(e) =>
							setNumField(
								e.target.value,
								mainCtx.state.data.stockNotifyRed,
								mainCtx.stateFunctions.fieldSetters.setStockNotifyRed,
								'float',
								[5, 2],
								{ isOnlyNums: true }
							)
						}
					/>
					<Typography.Text>%</Typography.Text>
				</Space>
			),
		},
	],
]
