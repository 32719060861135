import { isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'
import { Checkbox } from 'antd'
import { InputError } from '../../../../components'
import { getColumnSearchProps, getRowSpan } from '../../../../utils'
import { getRemainder, renderPlanFactCellV2 } from '../../utils'
import { numToFixed } from '@berry/common-functions/cross-project-functions'

export const sortDataBySelected = (inArr, inSelected) => {
	const onlyUuids = inSelected.map((e) => e._uuid_)
	return inArr.sort((a, b) => {
		if (onlyUuids.includes(a._uuid_) && onlyUuids.includes(b._uuid_)) return 0
		if (!onlyUuids.includes(a._uuid_) && onlyUuids.includes(b._uuid_)) return 1
		if (onlyUuids.includes(a._uuid_) && !onlyUuids.includes(b._uuid_)) return -1
		if (!onlyUuids.includes(a._uuid_) && !onlyUuids.includes(b._uuid_)) return 0
		return 0
	})
}

const getJoinedColRawSpan = (index, isSomeSelected, supplyReqCtx, preparedData) => {
	let rowSpan = 0
	if (index === 0 && isSomeSelected) {
		rowSpan = supplyReqCtx.state.selected.reduce((acc, cur) => {
			const filtered = preparedData.filter((e) => e._uuid_ === cur._uuid_)
			return acc + filtered.length
		}, 0)
	}
	return rowSpan
}

/**
 * Столбцы для таблицы Распределение квоты пм Сырье/Договоры.Поставщики
 * @param {Object} mainCtx - главный контекст
 * @param {Object} modCtx - контекст мода
 * @param {Array<Object>} preparedData - данные которые идут на вход таблице
 */
export const getDisplayColumnsV2 = (mainCtx, supplyReqCtx, preparedData, filters) => {
	const isSomeSelected = supplyReqCtx.state.selected.some((e) =>
		preparedData.find((d) => d._uuid_ === e._uuid_)
	)

	const isDisabledByPlatform = (record) => {
		if (record.application) {
			const quota = mainCtx.state.providerQuotas?.find(
				(element) =>
					element?._uuid_ ===
					supplyReqCtx.state.selected?.find((el) => {
						return el._uuid_
					})?._uuid_
			)

			if (
				(String(quota?.application?.platform?.id) !== String(record.application?.platform?.id) ||
					String(quota?.application?.deliveryCondition?.id) !==
						String(record.application?.deliveryCondition?.id)) &&
				supplyReqCtx.state.selected.length
			) {
				return true
			}
		}
	}
	const result = [
		{
			key: 'checkbox',
			width: '2%',
			onCell: (record) => ({
				rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
			}),
			render: (_, record) => (
				<Checkbox
					disabled={isDisabledByPlatform(record)}
					checked={supplyReqCtx.stateFunctions.selected.get(record._uuid_)}
					onChange={(e) => {
						supplyReqCtx.stateFunctions.selected.setIsSelected(record._uuid_, e.target.checked)
					}}
				/>
			),
		},
	]
	if (mainCtx.state.frame) {
		result.push({
			title: '№ ДС',
			dataIndex: ['application', 'displayCode'],
			key: 'applicationNum',
			width: '5%',
			onCell: (record, index) => ({
				rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
			}),
			...getColumnSearchProps(
				Array.from(
					new Set(preparedData.filter((p) => p.application).map((p) => p.application.displayCode))
				)
			),
			onFilter: (val, record) => val === record?.application.displayCode,
			filteredValue: filters['applicationNum'],
		})
	}
	result.push(
		...[
			{
				title: 'Продукт',
				dataIndex: ['rmProvProd', 'displayVal'],
				width: '13%',
				ellipsis: true,
				onCell: (record, index) => ({
					rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
				}),
				...getColumnSearchProps(
					Array.from(
						new Set(
							preparedData
								.filter((p) => {
									return p.rmProvProd
								})
								.map((p) => {
									return p.rmProvProd.displayVal
								})
						)
					)
				),
				onFilter: (val, record) => val === record.rmProvProd.displayVal,
				filteredValue: filters['rmProvProd.displayVal'],
			},
			{
				title: 'Масса, кг',
				key: 'weight',
				width: '5%',
				style: { width: '100%', border: `1px solid #e9e7e7` },
				render: (text, record, index) =>
					supplyReqCtx.stateFunctions.selected.get(record._uuid_) ? (
						<InputError
							disabled={supplyReqCtx.stateFunctions.selected.get(record._uuid_) ? false : true}
							style={{ width: '100%' }}
							value={supplyReqCtx.stateFunctions.selected.get(record._uuid_)?.weight || ''}
							onChange={(e) => {
								if (!isValidNum(e.target.value)) return
								if (!isValidNumberDigits(e.target.value, 'float', [9, 2])) return
								supplyReqCtx.stateFunctions.selected.setWeight(record._uuid_, e.target.value)
							}}
							hasError={!!supplyReqCtx.formErrors?.[record._uuid_]}
							message={supplyReqCtx.formErrors?.[record._uuid_]}
						/>
					) : null,
				onCell: (record, index) => ({
					rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
				}),
			},
			{
				title: 'Остаток, кг',
				key: 'remainder',
				width: '6%',
				render: (_, record) => numToFixed(getRemainder(record), 2),
				onCell: (record) => ({
					rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
				}),
			},
		]
	)

	result.push(
		...[
			{
				title: 'Год',
				dataIndex: 'year',
				width: '4%',
				...getColumnSearchProps(
					Array.from(
						new Set(
							preparedData
								.filter((p) => p.monthlyListings)
								.flatMap((p) => p.monthlyListings.map((l) => l.year))
						)
					)
				),
				onFilter: (val, record) => +val === record.year,
				filteredValue: filters['year'],
				onCell: (record) => ({
					rowSpan: record.isDiplayedRowBySpan ? 2 : 0,
				}),
			},
			{
				title: null,
				key: 'monthlyStart',
				width: '5%',
				render: (_, record) => {
					if (record.isPlan) return 'П'
					if (record.isFact) return 'Ф'
				},
			},

			{
				title: 'Янв',
				width: '5%',
				dataIndex: ['monthlyListing', 'month0'],
				key: 'monthlyListingMonth0',
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '0', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Фев',
				width: '5%',
				dataIndex: ['monthlyListing', 'month1'],
				key: 'monthlyListingMonth1',
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '1', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Мар',
				width: '5%',
				key: 'monthlyListingMonth2',
				dataIndex: ['monthlyListing', 'month2'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '2', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Апр',
				width: '5%',
				key: 'monthlyListingMonth3',
				dataIndex: ['monthlyListing', 'month3'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '3', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Май',
				width: '5%',
				key: 'monthlyListingMonth4',
				dataIndex: ['monthlyListing', 'month4'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '4', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Июн',
				width: '5%',
				key: 'monthlyListingMonth5',
				dataIndex: ['monthlyListing', 'month5'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '5', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Июл',
				width: '5%',
				key: 'monthlyListingMonth6',
				dataIndex: ['monthlyListing', 'month6'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '6', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Авг',
				width: '5%',
				key: 'monthlyListingMonth7',
				dataIndex: ['monthlyListing', 'month7'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '7', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Сен',
				width: '5%',
				key: 'monthlyListingMonth8',
				dataIndex: ['monthlyListing', 'month8'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '8', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Окт',
				width: '5%',
				key: 'monthlyListingMonth9',
				dataIndex: ['monthlyListing', 'month9'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '9', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Ноя',
				width: '5%',
				key: 'monthlyListingMonth10',
				dataIndex: ['monthlyListing', 'month10'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '10', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Дек',
				width: '5%',
				key: 'monthlyListingMonth11',
				dataIndex: ['monthlyListing', 'month11'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '11', {
						onlyView: true,
					})
				},
			},
		]
	)

	result.push(
		...[
			{
				title: 'Общая масса, кг',
				key: 'totalWeight',
				width: '8%',
				align: 'center',
				render: (_, record) => {
					let totalWeight = 0
					supplyReqCtx.state.selected.forEach((e) => {
						totalWeight = totalWeight + parseFloat(e.weight || 0)
					})
					totalWeight = Math.round(totalWeight * 100000) / 100000
					return isSomeSelected ? numToFixed(totalWeight, 2) : null
				},
				onCell: (_, index) => ({
					rowSpan: getJoinedColRawSpan(index, isSomeSelected, supplyReqCtx, preparedData),
				}),
			},
		]
	)

	return result
}
