import { Checkbox } from 'antd'
import { getRowSpan } from '../../../../utils'

export const getColumns = () => {
	return [
		{
			title: 'Процесс',
			key: 'vocProcess',
			dataIndex: ['vocProcess', 'label'],
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.kinds.length),
			}),
		},

		{
			title: 'Вид основных ПФ',
			dataIndex: 'kind',
			render: (_, record) => {
				return record.kind?.label
			},
		},
		{
			title: 'Артикул',
			dataIndex: 'id',
			render: (_, record) => {
				return record.kind?.displayCode
			},
		},

		{
			title: 'Привязать калибр',
			dataIndex: 'isCaliber',
			render: (_, record) => {
				return <Checkbox disabled={true} checked={record.kind?.isCaliber}></Checkbox>
			},
		},
		{
			title: 'Привязать доп. признак',
			dataIndex: 'isAdditAttr',
			render: (_, record) => {
				return <Checkbox disabled={true} checked={record.kind?.isAdditAttr}></Checkbox>
			},
		},
	]
}
