/**
 * получить обьединенное значение эталона
 * @param {Object} inData -  обьект с данными
 */
export const getEtalon = (inData = {}) => {
	if (inData.isText) return inData.etalonText || ''
	return `${[null, undefined].includes(inData.etalonVal1) ? '' : inData.etalonVal1}${
		(inData.etalonSymbol === 'не доп.'
			? 'Не допускается'
			: inData.etalonSymbol !== '='
			? inData.etalonSymbol
			: '') || ''
	}${[null, undefined].includes(inData.etalonVal2) ? '' : inData.etalonVal2}`
}

/**
 * Получить обьединенное значение допустимого отклонения
 * @param {Object} inData -  обьект с данными
 */
export const getDeviation = (inData = {}) => {
	if (inData.isText) return inData.deviationText || ''
	return `${[null, undefined].includes(inData.deviationVal1) ? '' : inData.deviationVal1}${
		(inData.deviationSymbol === 'не доп.'
			? 'Не допускается'
			: inData.deviationSymbol !== '='
			? inData.deviationSymbol
			: '') || ''
	}${[null, undefined].includes(inData.deviationVal2) ? '' : inData.deviationVal2}`
}
