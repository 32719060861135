import React, { useState } from 'react'
import { SketchPicker } from 'react-color'

const ColourPicker = (props) => {
	const { colour, style } = props

	const [displayColorPicker, setDisplayColorPicker] = useState(false)

	const styles = {
		color: {
			width: '2em',
			height: '1em',
			borderRadius: 4,
			background: colour.value
				? `rgba(${colour.value.r}, ${colour.value.g}, ${colour.value.b}, ${colour.value.a})`
				: 'inherited',
		},
		popover: {
			position: 'absolute',
			zIndex: '2',
		},
		cover: {
			position: 'fixed',
			top: '0px',
			right: '0px',
			bottom: '0px',
			left: '0px',
		},
	}

	const handleClick = () => {
		setDisplayColorPicker(!displayColorPicker)
	}

	const handleClose = () => {
		setDisplayColorPicker(false)
	}

	const handleChange = (color) => {
		colour.onChange(color.rgb)
	}

	return (
		<div>
			<div style={style} onClick={handleClick}>
				<div style={{ border: '1px solid #d9d9d9', ...styles.color }} />
			</div>
			{displayColorPicker ? (
				<div style={{ ...styles.popover, position: 'fixed' }}>
					<div style={styles.cover} onClick={handleClose} />
					<SketchPicker
						disableAlpha={true}
						color={colour.value || undefined}
						onChange={handleChange}
					/>
				</div>
			) : null}
		</div>
	)
}

export default ColourPicker
