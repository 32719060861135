import { Col, Row } from 'antd'
import React, { useContext } from 'react'
import { StockSampleListMainContext } from './provider/main'
import { CustomForm } from '../../../../components'
import { getFormItems } from './modals-for-list'
const ModalsForList = () => {
	const mainCtx = useContext(StockSampleListMainContext)
	let key = null
	if (mainCtx.saleModal.__isOpen) {
		key = 'saleModal'
	}
	if (mainCtx.relocationModal.__isOpen) {
		key = 'relocationModal'
	}
	if (!key) return null
	/* if (mainCtx.utilizationModal.__isOpen) {
		key = 'utilizationModal'
	} */
	return (
		<Row>
			<Col span="24">
				<CustomForm
					onFinish={mainCtx.modalsServerEdit}
					name="sale"
					fields={[
						{ name: 'prodCat', value: mainCtx[key]?.storage.prodCat },
						{ name: 'partyNum', value: mainCtx[key]?.storage.partyNum },
						{ name: 'articul', value: mainCtx[key]?.storage.articul },

						{
							name: 'weight',
							value:
								mainCtx.saleModal?.weight ||
								mainCtx.relocationModal?.weight ||
								mainCtx.utilizationModal?.weight,
						},
						{
							name: 'room',
							value:
								mainCtx.saleModal?.room?.displayCode || mainCtx.relocationModal?.room?.displayCode,
						},
					]}
					columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
					items={getFormItems(mainCtx)}
				/>
			</Col>
		</Row>
	)
}

export default ModalsForList
