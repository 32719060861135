const lodash = require('lodash')
const passwordValidator = require('password-validator')
const pwdSchema = new passwordValidator()
const pwdSchemaIsMin = new passwordValidator()
const pwdSchemaHasUppercase = new passwordValidator()
const pwdSchemaHasLowercase = new passwordValidator()
const pwdSchemaHasDigits = new passwordValidator()
const pwdSchemaHasSymbols = new passwordValidator()
const pwdSchemaHasNotSpaces = new passwordValidator()

pwdSchemaIsMin.is().min(8)
pwdSchemaHasUppercase.has().uppercase()
pwdSchemaHasLowercase.has().lowercase()
pwdSchemaHasDigits.has().digits()
pwdSchemaHasSymbols.has().symbols()
pwdSchemaHasNotSpaces.has().not().spaces()

pwdSchema
	.is()
	.min(8)
	.is()
	.max(100)
	.has()
	.uppercase()
	.has()
	.lowercase()
	.has()
	.digits()
	.has()
	.symbols()
	.has()
	.not()
	.spaces()

const validatePasswordStepByStep = (inPwd) => {
	if (!pwdSchemaIsMin.validate(inPwd)) return 'Введите не менее 8 символов'
	if (!pwdSchemaHasUppercase.validate(inPwd))
		return 'Пароль должен содержать хотя бы одну заглавную букву'
	if (!pwdSchemaHasLowercase.validate(inPwd))
		return 'Пароль должен содержать хотя бы одну строчную букву'
	if (!pwdSchemaHasDigits.validate(inPwd)) return 'Пароль должен содержать хотя бы одну цифру'
	if (!pwdSchemaHasSymbols.validate(inPwd))
		return 'Пароль должен содержать хотя бы один спец. символ'
	if (!pwdSchemaHasNotSpaces.validate(inPwd)) return 'Пароль не должен содержать пробелы'
	return ''
}

const isValidNum = (val) => {
	if ([NaN, null, undefined].includes(val) || isNaN(val)) return false
	if (Infinity === +val) return false
	return true
}

/**
 * проверяет значени на уникальность
 * @param {Object} inArr - массив элементов
 * @param {string} field - поле через дот-нотацию
 * @param {string} [filename=id] - значение
 */
const isUniqueValidator = (inArr, field, val) => {
	const allValues = inArr.map((el) => String(lodash.get(el, field)).toLowerCase())
	if (allValues.includes(val.toLowerCase())) {
		return false
	}
	return true
}

/**
 * проверяет значени на уникальность
 * @param {Object} inArr - массив элементов
 * @param {string} field - поле через дот-нотацию
 * @param {string} [filename=id] - значение
 */
const isUniqueByMultipleFields = (inArr, inFields, inObj) => {
	if (
		inArr.some((e) => {
			return inFields.every((f) => {
				return String(lodash.get(e, f)) === String(lodash.get(inObj, f))
			})
		})
	)
		return false
	return true
}

const basicValidator = (val) => {
	if ([undefined, null, ''].includes(val)) return false
	return true
}

/**
 * Сравниваем 2 числа с указанной точностью
 * @param {number} inNum - основное число
 * @param {('int'|'float')} type - неточное число
 * @param {[number,number]} digits - [сколько всего знаков , сколько после запятой] как в sql
 * @returns {boolean}
 */
const isValidNumberDigits = (inNum, type, digits) => {
	if (!isValidNum(inNum)) throw Error('параметры неверные')
	if (digits.length !== 2) throw Error('параметры неверные')
	const splittedNumStr = String(inNum).split('.')
	const fullNumStr = splittedNumStr.join('')
	if (fullNumStr.length > digits[0]) return false
	switch (type) {
		case 'int':
			if (splittedNumStr.length === 2) return false
			break
		case 'float':
			if (splittedNumStr.length === 2) {
				if (splittedNumStr[1].length > digits[1]) return false
			} else {
				if (splittedNumStr[0].length > digits[0] - digits[1]) return false
			}
			break

		default:
			throw Error('параметры неверные')
	}
	return true
}

const isValidPwd = (inPwd) => {
	return pwdSchema.validate(inPwd)
}

const isEmail = (val) => {
	return /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(val)
}

module.exports.isValidNum = isValidNum
module.exports.isUniqueValidator = isUniqueValidator
module.exports.basicValidator = basicValidator
module.exports.isValidNumberDigits = isValidNumberDigits
module.exports.isUniqueByMultipleFields = isUniqueByMultipleFields
module.exports.isValidPwd = isValidPwd
module.exports.validatePasswordStepByStep = validatePasswordStepByStep
module.exports.isEmail = isEmail
