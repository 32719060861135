import { Typography, Space } from 'antd'
import { showConfirmModal } from '../../../../components'
import { getOrderStatus } from './../../../../utils/helpers/cross-pages-funcs'

const produceSendTo1cModal = (mainCtx, finish = false) => {
	const orderStatus = getOrderStatus(mainCtx.state, mainCtx.additional.allRequests)

	const onOkText = (finish) => {
		if (finish) return 'Завершить заказ'
		return 'Промежуточный отчет'
	}

	const readyProdToSendIn1c = mainCtx.state.produceds?.filter((p) =>
		finish
			? ['ГП', 'ДГП'].includes(p.type) &&
			  (p.status === 'Допущено' ||
					p.reps.find((rep) => rep.idProd === p.id)?.rep.status === 'Ошибка отправки в 1С')
			: mainCtx.addTo1cProduced.addTo1cProducedReadyProds.some((e) => +e.id === +p.id)
	)

	const wasteToSendIn1c = mainCtx.state.produceds?.filter((p) =>
		finish
			? p.type === 'ПФ' &&
			  // условие 1 из кф
			  ((['Допущено', 'Хранение'].includes(p.status) && orderStatus === 'В работе') ||
					// условие 2 из кф
					(orderStatus === 'Выполнен' &&
						mainCtx.state.wasteManagement === 'Утилизация за счет производства' &&
						'К отгрузке' === p.status))
			: mainCtx.addTo1cProduced.addTo1cProducedWastes.some((e) => +e.id === +p.id)
	)

	return showConfirmModal({
		title: 'В 1С будут отправлены данные',
		width: '40%',
		isCancelBtnPrimary: true,
		content: (
			<Space direction="vertical">
				{readyProdToSendIn1c.length ? (
					<>
						<Typography.Title level={5} style={{ margin: '20px 0 20px 0' }}>
							Готовая продукция:
						</Typography.Title>
						{readyProdToSendIn1c.map((el, idx) => (
							<Typography.Text key={el.id}>{`${idx + 1}. ${el.partyNum} - ${el.product} = ${
								el.weight
							} кг`}</Typography.Text>
						))}
					</>
				) : null}
				{wasteToSendIn1c.length ? (
					<>
						<Typography.Title level={5} style={{ margin: '20px 0 10px 0' }}>
							Отходы:
						</Typography.Title>
						{wasteToSendIn1c.map((el, idx) => (
							<Typography.Text key={el.id}>{`${idx + 1}. ${el.partyNum} - ${el.product} = ${
								el.weight
							} кг`}</Typography.Text>
						))}
					</>
				) : null}
			</Space>
		),
		okText: onOkText(finish),
		cancelText: 'Отмена',
		centered: true,
		async onOk() {
			try {
				if (finish) {
					await mainCtx.endOrderTo1c()
				} else {
					await mainCtx.sendTo1c()
				}
			} catch (err) {
				console.log(err)
			} finally {
				mainCtx.modalFunctions.resetAddTo1cProduced()
			}
		},
	})
}

const produceBalanceSendTo1cModal = (mainCtx) => {
	const wasteToSendIn1c = mainCtx.state.produceds?.filter((p) =>
		mainCtx.addTo1cProduced.addTo1cProducedWastes.some((e) => +e.id === +p.id)
	)
	return showConfirmModal({
		title: 'В 1С будет отправлен запрос на перевод на баланс следующих партий:',
		width: '40%',
		isCancelBtnPrimary: true,
		content: (
			<Space direction="vertical">
				{wasteToSendIn1c.length ? (
					<>
						{wasteToSendIn1c.map((el, idx) => (
							<Typography.Text key={el.id}>{`${idx + 1}. ${el.partyNum} - ${el.product} = ${
								el.weight
							} кг`}</Typography.Text>
						))}
					</>
				) : null}
			</Space>
		),
		okText: 'Перевести на баланс',
		cancelText: 'Отмена',
		centered: true,
		async onOk() {
			try {
				await mainCtx.balSendTo1c()
			} catch (err) {
				console.log(err)
			} finally {
				mainCtx.modalFunctions.resetAddTo1cProduced()
			}
		},
	})
}

export { produceSendTo1cModal, produceBalanceSendTo1cModal }
