const getBase64 = async (file, filePath) => {
	return await new Promise((resolve, reject) => {
		const newFile = new File(file, filePath)
		let reader = new FileReader()
		reader.readAsDataURL(newFile)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (err) => reject(err)
	})
}

export default getBase64
