import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Typography } from 'antd'
import ReactDOM from 'react-dom'
import {
	DeleteOutlined,
	RotateRightOutlined,
	ZoomInOutlined,
	ZoomOutOutlined,
	VerticalAlignBottomOutlined,
} from '@ant-design/icons'
import { ArrowLeft, ArrowRight } from '../../assets'
import { ReactComponent as CloseIcon } from '../../assets/svg/closeWhite.svg'
import { getTargetNode } from './utils'

export const ButtonsContent = ({
	handleRotate,
	handleZoomIn,
	handleZoomOut,
	handleClose,
	deleteFn,
	setIsVisible,
	isVisible,
	isZoomInDisabled,
	isZoomOutDisabled,
	isDelDisabled,
	title,
	clickLeft,
	clickRight,
	isLeftArrowDisabled,
	isRightArrowDisabled,
	downloadFn,
}) => (
	<div>
		<Row className="custom-ant-image-preview-operations-top">
			<Col span={22} offset={1}>
				<Typography.Text>
					<span
						style={{
							color: '#FFF',
							fontSize: '0.8vw',
							textOverflow: 'ellipsis',
							maxWidth: '100%',
							maxHeight: '100px',
							display: '-webkit-box',
							WebkitLineClamp: 2,
							overflow: 'hidden',
							WebkitBoxOrient: 'vertical',
						}}
					>
						{title}
					</span>
				</Typography.Text>
			</Col>
			<Col
				style={{
					lineHeight: 1.2,
					transform: 'scale(0.8)',
				}}
				span={1}
			>
				<Button
					type="text"
					onClick={() => {
						handleClose()
					}}
					icon={<CloseIcon />}
				/>
			</Col>
		</Row>
		<Row className="custom-ant-image-preview-operations-middle">
			<Button
				type="text"
				shape="circle"
				onClick={(e) => {
					e.stopPropagation()
					clickLeft()
				}}
				style={{
					position: 'absolute',
					left: '1%',
				}}
				disabled={isLeftArrowDisabled}
			>
				<ArrowLeft
					style={{
						color: `rgba(0, 0, 0, ${isLeftArrowDisabled ? '.25' : '.85'})`,
						transform: 'scale(0.8)',
					}}
				/>
			</Button>
			<Button
				type="text"
				shape="circle"
				onClick={(e) => {
					e.stopPropagation()
					clickRight()
				}}
				style={{
					position: 'absolute',
					right: '1%',
				}}
				disabled={isRightArrowDisabled}
			>
				<ArrowRight
					style={{
						color: `rgba(255, 255, 255, ${isRightArrowDisabled ? '.25' : '.85'})`,
						transform: 'scale(0.8)',
					}}
				/>
			</Button>
		</Row>
		<Row size="large" className="custom-ant-image-preview-operations-bottom">
			<Col span={1}>
				<Button
					type="text"
					onClick={(e) => {
						e.stopPropagation()
						handleZoomIn()
					}}
					disabled={isZoomInDisabled}
				>
					<ZoomInOutlined
						style={{
							color: `rgba(255, 255, 255, ${isZoomInDisabled ? '.25' : '.85'})`,
							transform: 'scale(1.5)',
						}}
					/>
				</Button>
			</Col>
			<Col span={1}>
				<Button
					type="text"
					onClick={(e) => {
						e.stopPropagation()
						handleZoomOut()
					}}
					disabled={isZoomOutDisabled}
				>
					<ZoomOutOutlined
						style={{
							color: `rgba(255, 255, 255,${isZoomOutDisabled ? '.25' : '.85'})`,
							transform: 'scale(1.5)',
						}}
					/>
				</Button>
			</Col>
			<Col span={1}>
				<Button
					type="text"
					onClick={(e) => {
						e.stopPropagation()
						handleRotate()
					}}
				>
					<RotateRightOutlined
						style={{
							color: `rgba(255, 255, 255, 0.85)`,
							transform: 'scale(1.5)',
						}}
					/>
				</Button>
			</Col>
			<Col span={1}>
				<Button type="text" onClick={downloadFn}>
					<VerticalAlignBottomOutlined
						style={{
							color: `rgba(255, 255, 255, 0.85)`,
							transform: 'scale(1.5)',
						}}
					/>
				</Button>
			</Col>
			{!isDelDisabled && (
				<Col span={1}>
					<Button type="text" onClick={deleteFn}>
						<DeleteOutlined
							style={{ color: `rgba(255, 255, 255, 0.85)`, transform: 'scale(1.5)' }}
						/>
					</Button>
				</Col>
			)}
		</Row>
	</div>
)

const Buttons = ({ isVisible, setIsVisible, ...rest }) => {
	const [targetNode, setTargetNode] = useState(null)
	useEffect(() => {
		const node = getTargetNode()
		if (node) {
			setTargetNode(node)
		}
	}, [isVisible])
	if (!targetNode) {
		return null
	}
	return ReactDOM.createPortal(<ButtonsContent {...rest} />, targetNode)
}
export default Buttons
