import React, { useContext } from 'react'
import { MenuContext, ModContext } from '../../../contexts'
import { ProductCatalogItemMainContext } from './provider/main'
import { Card, Col, Row } from 'antd'
import { CustomForm } from '../../../components'

import { getRawFormItems, getInfoFormItems } from './product-catalog-item'

const TabTemplate = (props) => {
	return (
		<Card>
			<Row gutter={[16, 16]} className="center-label-field-vertical">
				<Col flex={1}>
					<CustomForm {...props} />
				</Col>
			</Row>
		</Card>
	)
}

export const RawTab = (props) => {
	const mainCtx = useContext(ProductCatalogItemMainContext)
	const modCtx = useContext(ModContext)
	const menuCtx = useContext(MenuContext)

	return (
		<TabTemplate
			name="rawTab"
			fields={[
				{ name: 'label', value: mainCtx.state.label },
				{ name: 'labelShort', value: mainCtx.state.labelShort },
				{ name: 'vocProdGroup', value: mainCtx.state.vocProdGroup },
				{ name: 'vocMeasure', value: mainCtx.state.vocMeasure?.labelShort },
				{ name: 'displayCode', value: mainCtx.state.displayCode },
				{ name: 'outPercent', value: mainCtx.state.outPercent },
				{ name: 'color', value: mainCtx.state.color },
				{ name: 'deviation', value: mainCtx.state.deviation },
				{ name: 'onePalleta', value: mainCtx.state.onePalleta },
				{ name: 'isCalibration', value: mainCtx.state.isCalibration },
				{ name: 'isAdditAttr', value: mainCtx.state.isAdditAttr },
				{ name: 'status', value: mainCtx.state.status },
			]}
			columns={[
				{ size: { xs: 24, lg: 12, xl: 8, xxl: 9 }, num: 0 },
				{ size: { xs: 24, lg: 12, xl: 8, xxl: 6 }, num: 1 },
				{ size: { xs: 24, lg: 12, xl: 8, xxl: 9 }, num: 2 },
			]}
			form={props.form}
			items={getRawFormItems(mainCtx, modCtx, menuCtx)}
		/>
	)
}

export const SemifinishedTab = (props) => {
	const mainCtx = useContext(ProductCatalogItemMainContext)

	return (
		<TabTemplate
			name="SemifinishedTab"
			fields={[
				{ name: 'label', value: mainCtx.state.label },
				{ name: 'labelShort', value: mainCtx.state.labelShort },
				{ name: 'vocProdGroup', value: mainCtx.state.vocProdGroup?.label },
				{ name: 'vocMeasure', value: mainCtx.state.vocMeasure?.labelShort },
				{ name: 'displayCode', value: mainCtx.state.displayCode },
				{ name: 'color', value: mainCtx.state.color },
				{ name: 'status', value: mainCtx.state.status },
			]}
			columns={[
				{ size: { xs: 24, sm: 12, xl: 8, xxl: 9 }, num: 0 },
				{ size: { xs: 24, sm: 12, xl: 8, xxl: 6 }, num: 1 },
				{ size: { xs: 24, sm: 12, xl: 8, xxl: 9 }, num: 2 },
			]}
			items={getInfoFormItems(mainCtx)}
		/>
	)
}

export const ReadyProductTab = (props) => {
	const mainCtx = useContext(ProductCatalogItemMainContext)

	return (
		<TabTemplate
			name="ReadyProductTab"
			fields={[
				{ name: 'label', value: mainCtx.state.label },
				{ name: 'labelShort', value: mainCtx.state.labelShort },
				{ name: 'vocProdGroup', value: mainCtx.state.vocProdGroup?.label },
				{ name: 'vocMeasure', value: mainCtx.state.vocMeasure?.labelShort },
				{ name: 'displayCode', value: mainCtx.state.displayCode },
				{ name: 'color', value: mainCtx.state.color },
				{ name: 'status', value: mainCtx.state.status },
			]}
			columns={[
				{ size: { xs: 24, sm: 12, xl: 9 }, num: 0 },
				{ size: { xs: 24, sm: 12, xl: 6 }, num: 1 },
				{ size: { xs: 24, sm: 12, xl: 9 }, num: 2 },
			]}
			items={getInfoFormItems(mainCtx)}
		/>
	)
}
