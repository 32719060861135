import {
	checkInputCtrlLabIndicatorColor,
	getSpecParamDeviation,
} from '@berry/common-functions/cross-project-functions'
import { setNumField } from '@berry/front-common/for-fields'
import { Col, Select, Upload, Input } from 'antd'
import { getRowSpan, modes } from '../../../../utils'
import { getEtalon } from '../../../Specification/SpecificationItem/specification-item'
import { Carousel, InputError } from '../../../../components'
import { ReactComponent as AddPhotoIcon } from '../../../../assets/svg/addPhoto.svg'
import { basicValidator } from '@berry/common-functions/validators'
import { isNum } from './../../../../utils/helpers/for-form'
import '../../../../index.css'
/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 * @param {Object} modCtx
 * @param {function} editHandler - обработка нажатия на карандаш
 */

/**
 * группирует данные по разделу
 * @param {Object} inArr - данные
 */
export const groupData = (inArr) => {
	return inArr.reduce((acc, cur) => {
		const foundIndx = acc.findIndex(
			(e) => String(e.id) === String(cur.specParam.vocSpecParamParam.spec.id)
		)
		if (foundIndx !== -1) {
			acc[foundIndx].ctrlLabIndicators.push(cur)
			return acc
		} else {
			return [
				...acc,
				{
					id: cur.specParam.vocSpecParamParam.spec.id,
					ctrlLabIndicators: [cur],
				},
			]
		}
	}, [])
}

export const getDisplayColumns = (mainCtx, modCtx, authCtx, addPhoto, removePhoto) => {
	const stateFunctions = mainCtx.stateFunctions.ctrlLabIndicators
	let isDisabled = false
	if (modCtx.mod === modes.view) isDisabled = true

	return [
		{
			title: 'Раздел',
			key: 'param',
			width: '13.01%',
			className: 'elemAlignTop',
			render: (_, rec) => rec.ctrlLabIndic.specParam.vocSpecParamParam.spec.label,
			onCell: (rec) => ({ rowSpan: getRowSpan(rec, rec.ctrlLabIndicators.length) }),
		},
		{
			title: 'Параметр',
			key: 'param',
			width: '13.01%',

			render: (_, rec) => {
				return rec.ctrlLabIndic.specParam.vocSpecParamParam.label
			},
		},
		{
			title: 'ЕИ',
			dataIndex: ['ctrlLabIndic', 'specParam', 'vocSpecParamParam', 'vocMeasure', 'labelShort'],
			key: 'vocMeasure',
			width: '3.92%',
		},
		{
			title: 'Факт. значение',
			key: 'fact',
			width: '10.14%',
			rules: [{ required: true }],
			render: (_, rec) => {
				if (!rec.ctrlLabIndic.specParam) return null
				if (rec.ctrlLabIndic.specParam.etalonText || rec.ctrlLabIndic.specParam.deviationText) {
					let className = 'white-field-selector'
					if (rec.ctrlLabIndic.factText === 'NC') className = 'red-field-selector'
					if (rec.ctrlLabIndic.factText === 'C - NC') className = 'yellow-field-selector'
					return (
						<InputError
							hasError={mainCtx.formErrors?.[`factText.${rec.ctrlLabIndic._uuid_}`]}
							component={
								<Select
									value={rec.ctrlLabIndic.factText}
									className={className}
									style={{ width: '100%', minWidth: '120px' }}
									onChange={(el) => {
										stateFunctions.setFactText(rec.ctrlLabIndic._uuid_, el)
									}}
									disabled={isDisabled}
									notFoundContent={<Col>Не найдено</Col>}
								>
									{mainCtx.selectors.ctrlLabIndicators.factText.map((it) => {
										return (
											<Select.Option key={it} value={it}>
												{it}
											</Select.Option>
										)
									}) || []}
								</Select>
							}
						/>
					)
				}

				let backgroundColor = 'inherit'
				if (
					checkInputCtrlLabIndicatorColor(rec.ctrlLabIndic, 'etalon') ||
					!basicValidator(rec.ctrlLabIndic.factVal) ||
					(rec.ctrlLabIndic.specParam.vocSpecParamParam.vocMeasure?.labelShort !== '°C' &&
						isNaN(rec.ctrlLabIndic.factVal))
				) {
					backgroundColor = 'inherit'
				} else if (checkInputCtrlLabIndicatorColor(rec.ctrlLabIndic, 'deviation')) {
					backgroundColor = 'rgba(255, 250, 204, 1)'
				} else {
					backgroundColor = 'rgba(255, 168, 168, 1)'
				}
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`factVal.${rec.ctrlLabIndic._uuid_}`]}
						style={{ width: '100%', backgroundColor }}
						value={rec.ctrlLabIndic.factVal}
						disabled={isDisabled}
						onKeyDown={(e) => {
							if (
								rec.ctrlLabIndic.specParam.vocSpecParamParam.vocMeasure?.labelShort === '°C' &&
								rec.ctrlLabIndic?.factVal?.length
							) {
								if (
									Object.values(rec.ctrlLabIndic.factVal).filter((el) => el.includes(' '))
										?.length >= 2 &&
									e.key === ' ' &&
									e.key !== 'Backspace'
								)
									e.preventDefault()
								if (
									Object.values(rec.ctrlLabIndic.factVal).filter((el) => el.includes('-'))
										?.length >= 3 &&
									e.key === '-' &&
									e.key !== 'Backspace'
								)
									e.preventDefault()
							}
							if (rec.ctrlLabIndic.specParam.vocSpecParamParam.vocMeasure?.labelShort === '°C') {
								if (e.key === '-' || e.key === ' ' || e.key === '.') return
								return isNum(e)
							}
						}}
						onChange={(e) => {
							if (
								rec.ctrlLabIndic.specParam.vocSpecParamParam.vocMeasure?.labelShort === '°C' &&
								rec.ctrlLabIndic.factVal?.length
							) {
								let val = e.target.value
								if (Object.values(rec.ctrlLabIndic.factVal).length < Object.values(val).length) {
									const checkRepeatDashInRow = (v) => {
										return /(-)\1/.test(v) > false
									}
									if (checkRepeatDashInRow(val)) return
									if (
										Object.values(val).some((el, idx) => {
											if (idx > 0) {
												return (
													(el === '-' &&
														!isNaN(parseFloat(Object.values(val[idx - 1]))) &&
														isFinite(Object.values(val[idx - 1]))) ||
													(el === '.' &&
														isNaN(parseFloat(Object.values(val[idx - 1]))) &&
														isFinite(Object.values(val[idx - 1]))) ||
													val[0] === '.'
												)
											}
											return undefined
										})
									)
										return

									let splitedVal = val.split(' - ')
									if (
										Object.values(splitedVal[0]).filter((el) => el.includes('.')).length > 1 ||
										(splitedVal.length === 2 &&
											Object.values(splitedVal[1]).filter((el) => el.includes('.')).length > 1)
									)
										return
									if (Object.values(val).filter((el) => el.includes(' ')).length === 1) {
										if (val.includes(' - ')) {
										}
										val = val.split(' ')
										val = val.join(' - ')
									}
									if (!val.includes(' - ') && (val.includes(' -') || val.includes('- '))) return
								} else {
									if (!val.includes(' - ')) {
										if (val.includes('  -')) {
											val = val.split('  -')
											val = val[0]
										} else if (val.includes(' -')) {
											if (val.includes('- -')) {
												val = val.split('- -')
												val = ''
											} else {
												val = val.split(' -')
												val = val[0]
											}
										} else if (val.includes('  -')) {
											val = val.split('  -')
											val = val[0]
										} else if (val.includes('- ')) {
											val = val.split('- ')
											val = val[0]
										} else if (val.includes('  ')) {
											val = val.split('  ')
											val = val[0]
										}
									}
								}
								stateFunctions.setFactVal(rec.ctrlLabIndic._uuid_, val)
								if (
									rec.ctrlLabIndic.specParam.vocSpecParamParam.vocMeasure?.labelShort === '°C' &&
									val.includes(' - ')
								)
									mainCtx.validateTemp(rec, e.target.value)
								return
							}
							setNumField(
								e.target.value,
								rec.ctrlLabIndic.factVal,
								stateFunctions.setFactVal,
								'float',
								[14, 5],
								{
									_uuid_: rec.ctrlLabIndic._uuid_,
								}
							)
						}}
					/>
				)
			},
		},
		{
			title: 'Комментарий',
			key: 'comment',
			width: '20.99%',
			render: (_, rec) => (
				<Input
					style={{ width: '100%' }}
					maxLength={500}
					value={rec.ctrlLabIndic?.comment}
					disabled={isDisabled}
					onChange={(e) => {
						stateFunctions.setComment(rec.ctrlLabIndic._uuid_, e.target.value)
					}}
				/>
			),
		},
		{
			title: 'Цель',
			key: 'etalon',
			width: '10.93%',
			render: (_, rec) => {
				if (rec.ctrlLabIndic?.specParam)
					return (
						<span style={{ wordBreak: 'break-all' }}>{getEtalon(rec.ctrlLabIndic?.specParam)}</span>
					)
				return ''
			},
		},
		{
			title: 'Допустимое отклонение',
			key: 'deviation',
			width: '10.6%',
			render: (_, rec) => {
				if (rec.ctrlLabIndic?.specParam)
					return (
						<span style={{ wordBreak: 'break-all' }}>
							{getSpecParamDeviation(rec.ctrlLabIndic?.specParam)}
						</span>
					)
				return ''
			},
		},
		{
			title: 'Фото эталон',
			dataIndex: 'etalonPhotos',
			key: 'etalonPhotos',
			width: '9.62%',
			render: (_, record) =>
				record.ctrlLabIndic.specParam?.photos?.length ? (
					<Carousel
						files={record.ctrlLabIndic.specParam.photos}
						title={record.ctrlLabIndic.specParam.vocSpecParamParam.label}
						disabled={true}
						path={'photoPath'}
						previewStyle={{
							display: 'flex',
							maxHeight: '3vh',
							height: '3vh',
						}}
					/>
				) : (
					''
				),
			onCell: () => ({
				style: {
					padding: 4,
					overflow: 'hidden',
				},
			}),
		},
		{
			title: 'Фото',
			dataIndex: 'photos',
			key: 'photos',
			width: '9.62%',
			render: (_, record) =>
				record.ctrlLabIndic.photos?.length ? (
					<Carousel
						files={record.ctrlLabIndic.photos}
						delFn={(photo) => removePhoto(record.ctrlLabIndic._uuid_, photo)}
						title={record.ctrlLabIndic.specParam.vocSpecParamParam.label}
						disabled={isDisabled}
						path={'photoPath'}
						previewStyle={{
							display: 'flex',
							maxHeight: '3vh',
							height: '3vh',
						}}
					/>
				) : (
					''
				),
			onCell: (record) => ({
				style: {
					padding: 4,
					overflow: 'hidden',
				},
			}),
		},
		{
			title: null,
			key: 'photoAction',
			width: '4.61%',
			render: (record) => {
				if (isDisabled || !record.ctrlLabIndic.specParam) return null
				const disable = isDisabled || record.ctrlLabIndic.photos?.length >= 10
				return (
					<Upload
						multiple
						accept=".png,.jpg,.bmp,.tif"
						onChange={({ file, fileList }) => {
							if (fileList?.length > 10) return
							if (!file?.type?.includes('image') || file.type === 'image/gif') {
								return
							}
							if (file.status === 'uploading') addPhoto(record.ctrlLabIndic._uuid_, fileList)

							addPhoto(record.ctrlLabIndic._uuid_, fileList)
						}}
						customRequest={({ onSuccess, onError, file }) => onSuccess(null)}
						fileList={record.ctrlLabIndic.photos || []}
						showUploadList={false}
						disabled={disable}
					>
						<AddPhotoIcon
							style={{
								fontSize: 20,
								opacity: disable ? 0.3 : 1,
								cursor: disable ? 'not-allowed' : 'pointer',
							}}
							className="ant-btn ant-btn-text ant-btn-icon-only"
						/>
					</Upload>
				)
			},
		},
	]
}
