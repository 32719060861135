import { Table } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'

const LocalTable = ({
	dataSource,
	searchFields,
	filterStatuses,
	getColumns,
	prepareDataFns,
	...props
}) => {
	const initialDataSource = useMemo(() => dataSource, [dataSource])
	const [cds, setCds] = useState(initialDataSource)
	const columnsMemo = getColumns(cds)

	const getCurDs = useCallback(
		(d) => {
			let data = d
			if (prepareDataFns?.length) {
				for (const fn of prepareDataFns) {
					data = fn(data)
				}
			}
			return data
		},
		[prepareDataFns]
	)

	useEffect(() => {
		let data = dataSource
		if (prepareDataFns?.length) {
			for (const fn of prepareDataFns) {
				data = fn(data)
			}
		}
		setCds(data)
	}, [dataSource, filterStatuses, prepareDataFns, searchFields])

	return (
		<Table
			rowKey="id"
			size="small"
			rowClassName="clickable"
			dataSource={getCurDs(initialDataSource)}
			columns={columnsMemo}
			onChange={(pagination, filters, sorter, { currentDataSource }) => {
				setCds(getCurDs(currentDataSource))
			}}
			{...props}
		/>
	)
}

export default LocalTable
