const { basicValidator } = require('./validators')

/**
 * Сравниваем 2 числа с указанной точностью
 * @param {number} general - основное число
 * @param {number} minor - неточное число
 * @param {number} accuracy - точность
 * @returns {boolean} - модеи
 */
const roundCompare = (general, minor, accuracy) => {
	if (
		[undefined, null].includes(general) ||
		[undefined, null].includes(minor) ||
		[undefined, null].includes(accuracy)
	)
		throw 'Parameters are requided'

	// подбираем корректирующий коэффициент для нужного разряда после запятой
	let corrective = 9
	for (let i = 0; i < accuracy; i++) {
		corrective = corrective / 10
	}
	let roundCoeff = '1'
	for (let i = 0; i < accuracy; i++) {
		roundCoeff = `${roundCoeff}0`
	}
	if (accuracy === 0) return general === minor
	if (general > minor) {
		// пытаемся прибавить число 9 в том разряде после запятой, который указан в параметре точность
		if (minor + corrective >= general) return true
		return false
	}
	if (general === minor) return true
	if (minor > general) {
		if (Math.round(minor * Number(roundCoeff)) / Number(roundCoeff) - corrective <= general)
			return true
		return false
	}
	return false
}

const getAccuracy = (inData) => {
	if ([undefined, null].includes(inData)) throw Error('параметры неверные')
	const strNum = inData.toString().split('.')
	if (strNum.length === 1) return 1
	return strNum[1].length
}

/**
 * Преобразовывает величины
 * @param {string} inVal - основное число
 * @param {number} coeff - коэфициент преобразования
 * @returns {string}
 */
const transformMeasure = (inVal, coeff, symbol) => {
	if (!['/', '*'].includes(symbol)) throw Error('непрвавильно переданы параметры')
	let result = inVal
	if (basicValidator(inVal)) {
		const splitted = `${inVal}`.split('.')
		if (splitted.length > 1 && splitted[1].length === 0) {
			switch (symbol) {
				case '*':
					splitted[0] = +splitted[0] * coeff
					break

				case '/':
					splitted[0] = +splitted[0] / coeff
					break
			}

			result = splitted[0]
		} else {
			switch (symbol) {
				case '*':
					result = +inVal * coeff
					break
				case '/':
					result = +inVal / coeff
					break
			}
		}
	}
	return result
}
module.exports.roundCompare = roundCompare
module.exports.getAccuracy = getAccuracy
module.exports.transformMeasure = transformMeasure
