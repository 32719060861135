import React, { useContext } from 'react'

import { Main } from '../../../../layouts'
import { Provider, SpecParamContext } from './provider/main'
import { HeaderMenu, StatefulTable } from '../../../../components'
import { getColumns } from './spec-param-list'
import { Card } from 'antd'
import {
	getDataByPage,
	getPageLength,
	goToItem,
	prepareData,
	sortWithRowSpan,
} from '../../../../utils'
import { useHistory } from 'react-router'
import { ListContext } from '../../../../contexts'

const pageUrl = '/vocabularies/specification-parameter'

const SpecParamList = (props) => {
	return (
		<Provider>
			<Main title={'Параметры спецификации'}>
				<SpecParamListTable />
			</Main>
		</Provider>
	)
}

const SpecParamListTable = () => {
	const mainCtx = useContext(SpecParamContext)
	const {
		state: { page: pageNum, sorter },
	} = useContext(ListContext)

	const history = useHistory()

	const pageData = mainCtx.state.search?.length
		? mainCtx.toDisplayDataList()
		: getDataByPage(mainCtx.toDisplayDataList(), pageNum)
	const preparedDataForPage = prepareData(
		sortWithRowSpan(pageData, sorter, 'params', 'label'),
		'params',
		'param'
	)
	const realTotalPages = getPageLength(mainCtx.toDisplayDataList())
	let total = preparedDataForPage.length * realTotalPages
	return (
		<>
			<HeaderMenu
				rights={{
					Справочники: {
						'Параметры спецификации': {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle={'Параметры спецификации'}
				canCreate={true}
				search={mainCtx.setSearch}
				handleAddOnMenu={() => goToItem(history, { url: pageUrl, id: 'new' })}
			/>
			<Card>
				<StatefulTable
					rowKey={(rec) => `${rec.id}_${rec.indxMain}`}
					dataSource={preparedDataForPage}
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					url={pageUrl}
					pagination={{
						total: total,
						pageSize: preparedDataForPage.length,
						current: pageNum,
						showSizeChanger: false,
					}}
				/>
			</Card>
		</>
	)
}
export default SpecParamList
