import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import { deepUpdateField } from '../../../utils'
import { ContractItemMainContext } from './item'
import { isValidNum, basicValidator } from '@berry/common-functions/validators'
import { getRemainder } from '../utils'
import moment from 'moment'

export const reducer = (state) => {
	return {
		...state,
	}
}

const initialState = {
	isModalOpen: false,
	formErrors: {},
	data: {
		selected: [],
	},
}

const SupplyReqModalContext = React.createContext()
SupplyReqModalContext.displayName = 'SupplyReqModalContext'

/**
 * @param {{children: React.Component, params: {idContract:string} }} props
 */
const Provider = (props) => {
	const { children, params } = props
	const contractCtx = useContext(ContractItemMainContext)
	const providerData = contractCtx.state
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const reset = useCallback(() => {
		if (params.idContract === 'new') return
		executeDispatch(initialState)
	}, [params.idContract])
	useEffect(() => {
		reset()
	}, [providerData, reset])

	const commonFieldUpdate = (field, value) => {
		executeDispatch({
			...stateRef.current,
			data: {
				...stateRef.current.data,
				[field]: typeof value === 'object' ? lodash.cloneDeep(value) : value,
			},
		})
	}

	const commonDeepFieldUpdate = (inFields, inUuids, field, value) => {
		const newState = deepUpdateField(inFields, inUuids, stateRef.current.data, field, value)
		executeDispatch({ ...stateRef.current, data: newState })
	}

	const setErrors = (err) => {
		executeDispatch({
			...stateRef.current,
			formErrors: { ...stateRef.current.formErrors, ...err },
		})
	}

	const resetErrors = () => {
		executeDispatch({ ...stateRef.current, formErrors: {} })
	}

	const stateFunctions = {
		selected: {
			get: (inUuid) => {
				return stateRef.current.data.selected.find((e) => e._uuid_ === inUuid)
			},
			setIsSelectedAll: (val) => {
				stateRef.current.formErrors = []
				commonFieldUpdate('selected', val)
			},
			setIsSelected: (inUuid, val) => {
				let newArr = lodash.cloneDeep(stateRef.current.data.selected) || []
				if (val === false) {
					const foundIndx = newArr.findIndex((e) => e._uuid_ === inUuid)
					if (foundIndx === -1) throw Error('Удаляемый элемент не найден')
					newArr = [...newArr.slice(0, foundIndx), ...newArr.slice(foundIndx + 1, newArr.length)]
					stateRef.current.formErrors = Object.keys(stateRef.current.formErrors).filter(
						(key) => key !== inUuid
					)
				} else {
					newArr.push({ _uuid_: inUuid, weight: 0 })
				}
				commonFieldUpdate('selected', newArr)
			},
			setWeight: (inUuid, val) => {
				const found = contractCtx.state.providerQuotas.find((q) => q._uuid_ === inUuid)
				delete stateRef.current.formErrors[inUuid]
				if (+val > getRemainder(found)) {
					setErrors({ [inUuid]: 'Масса не должна превышать остаток' })
				}
				if (!val || +val === 0) {
					setErrors({ [inUuid]: 'Укажите массу' })
				}
				commonDeepFieldUpdate(['selected'], [inUuid], 'weight', val)
			},
		},
		fieldSetters: {
			setSupplyDatePlan: (val) => {
				commonFieldUpdate('supplyDatePlan', val)
			},
			setIsModalOpen: (val) => {
				commonFieldUpdate('isModalOpen', val)
			},
		},
	}

	const validate = () => {
		if (!stateRef.current.data.selected?.length) {
			throw Error('Не выбран ни один продукт')
		}
		const errWeightProducts = stateRef.current.data.selected.filter(
			(e) => !basicValidator(e.weight) || !isValidNum(e.weight) || String(e.weight) === '0'
		)
		if (errWeightProducts.length) {
			for (const prod of errWeightProducts) {
				setErrors({ [prod._uuid_]: 'Укажите массу' })
			}
			throw Error()
		}
		if (
			stateRef.current.data.selected.some((e) => {
				const found = contractCtx.state.providerQuotas.find((q) => q._uuid_ === e._uuid_)
				return +e.weight > getRemainder(found)
			})
		) {
			for (const prod of stateRef.current.data.selected) {
				const remainder = getRemainder(
					contractCtx.state.providerQuotas.find((q) => q._uuid_ === prod._uuid_)
				)
				if (prod.weight > remainder)
					setErrors({ [prod['_uuid_']]: 'Масса не должна превышать остаток' })
			}
			throw Error('Масса не должна превышать остаток')
		}

		// if (!stateRef.current.data.supplyDatePlan) {
		// 	throw Error('Выберите планируемую дату поставки')
		// }

		if (
			[
				...new Set(
					stateRef.current.data.selected.map((e) => {
						return contractCtx.state.providerQuotas.find((q) => q._uuid_ === e._uuid_)?.application
							?.platform?.id
					})
				),
			].length > 1
		)
			throw Error('Разные площадки')
		resetErrors()
	}
	const getBody = () => {
		const body = {
			date: moment().format('YYYY-MM-DD'),
			status: 'Новая',
			products: stateRef.current.data.selected.map((e) => {
				const found = contractCtx.state.providerQuotas.find((q) => q._uuid_ === e._uuid_)
				contractCtx.state.displayVal = contractCtx.state.num
				return {
					contract: contractCtx.state,
					contrQuota: found,
					weight: e.weight,
				}
			}),
			supplies: [],
		}
		return body
	}

	const value = {
		state: lodash.cloneDeep(stateRef.current.data),
		stateFunctions,
		reset,
		validate,
		getBody,
		formErrors: lodash.cloneDeep(stateRef.current.formErrors),
		setErrors,
	}

	return <SupplyReqModalContext.Provider value={value}>{children}</SupplyReqModalContext.Provider>
}

export { Provider, SupplyReqModalContext }
