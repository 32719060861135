import React, { useContext } from 'react'
import { PosTypeContext } from '../provider/main'
import { AuthContext, ModContext } from '../../../../../contexts'
import { getDisplayColumns } from './item-tab'
import { ExpandableTable, TabItem } from '../../../../../components'
import { posTypeData } from '@berry/static-data'

const ItemTab = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(PosTypeContext)
	const authCtx = useContext(AuthContext)
	const columns = getDisplayColumns(mainCtx, modCtx, authCtx)
	return (
		<TabItem
			addButton={{
				show: false,
			}}
			table={() => (
				<ExpandableTable dataSource={posTypeData.dataSource.Карточки} columns={columns} />
			)}
		/>
	)
}

export default ItemTab
