import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Main } from '../../../../layouts'
import { Provider, WorkingTimeListMainContext } from './provider/main'
import { HeaderMenu, StatefulTable } from '../../../../components'
import { getColumns } from './working-time-list'
import { Card } from 'antd'
import { goToItem } from '../../../../utils'

const pageUrl = '/vocabularies/working-time'

const WorkingTimeList = () => {
	return (
		<Provider>
			<Main title={'Смены'}>
				<WorkingTimeListTable />
			</Main>
		</Provider>
	)
}

const WorkingTimeListTable = () => {
	const mainCtx = useContext(WorkingTimeListMainContext)
	const history = useHistory()

	return (
		<>
			<HeaderMenu
				rights={{
					Справочники: {
						Смены: {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle={'Смены'}
				search={mainCtx.setSearch}
				canCreate
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					dataSource={mainCtx.toDisplayDataList()}
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					url={pageUrl}
				/>
			</Card>
		</>
	)
}
export default WorkingTimeList
