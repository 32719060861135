import moment from 'moment'
import { capitalizeFirstLetter } from '@berry/common-functions/text-functions'
import { axios, goToItem } from './'
import { modes } from '../constants'
import {
	deepFind,
	deepUpdateField,
	editByUuid,
	getObjDiff,
	removeByUuid,
	isEdited as isDataEdited,
	getNewObj,
} from './for-provider'
import lodash from 'lodash'
import { dateFormat } from '../constants/common'
import { basicValidator } from '@berry/common-functions/validators'
import { getProduct } from '../../components/Vocabulary/RawMatShelfLife/columns'
import { v4 } from 'uuid'
import { getDataFromSupplyProdRmProvProd } from '@berry/common-functions/cross-project-functions'

/**
 * изет первый свободный айдишник
 * @param {Array<number>} inArr
 * @param {Object} options
 * @returns {number}
 */
export const getAvailableCode = (inArr, options = { fromZero: false, withLength: false }) => {
	if (!inArr) throw Error('пустой параметр')
	if (inArr.length === 0) {
		if (options.startWith) return options.startWith
		return options.fromZero ? 0 : 1
	}
	if (inArr.some((e) => [undefined, null].includes(e))) throw Error('передан пустой айдишник')
	if (inArr.some((e) => typeof e === 'string'))
		try {
			inArr = inArr.map((el) => +el)
		} catch (err) {
			console.log(err.message)
			throw Error('Невозможно преобразовать код в число')
		}
	const max = Math.max(...inArr)
	let newCode = max + 1
	let startWith = 1
	if (options.fromZero) {
		startWith = 0
	}
	if (options.startWith) {
		startWith = options.startWith
		if (max < startWith) newCode = startWith
	}
	for (let i = startWith; i <= max + 1; i++) {
		if (inArr.some((e) => e === i)) {
			continue
		}
		newCode = i
		break
	}
	if (options.withLength) {
		newCode = String(newCode)
		newCode = newCode.padStart(6, '0')
	}
	return newCode
}

/**
 * изет слудуцющий айдишник
 * @param {Array<Object>} inArr
 * @returns {number}
 */
export const getNextCode = (inArr, options = { fromZero: false }) => {
	if (!inArr) throw Error('пустой параметр')
	if (inArr.length === 0) return options.fromZero ? 0 : 1
	if (inArr.some((e) => [undefined, null].includes(e))) throw Error('передан пустой айдишник')
	let max = null
	if (options.startWith) {
		max = Math.max(...inArr, options.startWith)
	} else {
		max = Math.max(...inArr)
	}
	return max + 1
}

/**
 * Получить  название пункта менб и наименование элемента этого пункита
 * @param {string } inUrl
 * @param {string } inObj - обьект с данными конкретного элемента пункта менбю
 */
export const getBreadcrumbsText = (inUrl, inObj, options = {}) => {
	switch (inUrl) {
		case '/product-catalog':
			return {
				head: 'Продуктовый каталог',
				label: inObj.label,
			}

		case '/voc/editable/main-semifinished-kind':
			return {
				head: 'Виды основных ПФ',
				label: inObj?.vocProcess?.label,
			}

		case '/voc/editable/specification-parameter':
			return {
				head: 'Параметры спецификации',
				label: inObj.label,
			}
		case '/voc/editable/raw-mat-shelf-life':
			return {
				head: 'Срок годности сырья',
				label: getProduct(inObj),
			}
		case '/contracts': {
			if (options.provider) {
				const label = inObj.num
					? `${inObj.num} - ${inObj.provider?.displayVal || ''}`
					: inObj.provider?.displayVal || 'Новый'
				return {
					head: 'Договоры. Поставщики',
					label,
				}
			}
			const label = inObj.num
				? `${inObj.num} - ${inObj.customer?.displayVal || ''}`
				: inObj.customer?.displayVal
			return {
				head: 'Договоры. Заказчики',
				label,
			}
		}

		case '/rm/providers':
			return {
				head: 'Поставщики',
				label: inObj.label,
			}

		case '/rp/customers':
			return {
				head: 'Заказчики',
				label: inObj.label,
			}

		case '/rm/supply-requests': {
			const label = `${inObj?.displayCode || 'Новая заявка'} от ${moment(inObj.date).format(
				dateFormat
			)}`
			return {
				head: 'Заявки на поставку сырья',
				label: label,
			}
		}

		case '/reg/specifications': {
			const label = `${inObj?.vocProdType?.label || ''} - ${inObj?.label || ''}`
			return {
				head: 'Протоколы контроля',
				label: label,
			}
		}
		case '/reg/staff': {
			let label = `${inObj?.surname || ''} ${inObj?.name || ''}`
			if (inObj?.patronymic) {
				label = `${label} ${inObj?.patronymic}`
			}
			return {
				head: 'Персонал',
				label: label,
			}
		}
		case '/rm/supplies': {
			const date = moment.isMoment(inObj.date)
				? moment(inObj.date).format(dateFormat)
				: moment(inObj.date, 'YYYY-MM-DD').format(dateFormat)
			const label = `${inObj?.id || 'Новая поставка'} от ${date}`
			return {
				head: 'Поставки сырья',
				label: label,
			}
		}
		case '/rm/supply-unloads': {
			let label = `Разгрузка - партия ${inObj?.assignedPartyNum || ''}`
			return {
				head: getBreadcrumbsText('/rm/supplies', inObj.supply || {}).label,
				label: label,
			}
		}
		case '/rm/supply-input-controls': {
			let label = `Входной контроль - партия ${inObj?.assignedPartyNum || ''}`
			return {
				head: getBreadcrumbsText('/rm/supplies', inObj.supply || {}).label,
				label: label,
			}
		}
		case '/stock/raw-mats': {
			const { prodCatLabel, prodCatKindLabel } = getDataFromSupplyProdRmProvProd(inObj.supplProd)
			const assignedPartyNum = inObj.supplProd?.assignedPartyNum

			const recordLabel = `${assignedPartyNum} - ${prodCatLabel} ${prodCatKindLabel}`
			return {
				breadcrumbsTitle: 'Сырье',
				recordLabel,
			}
		}
		case '/production/tasks': {
			let date
			if (inObj.date) {
				date = moment.isMoment(inObj.date)
					? moment(inObj.date).format(dateFormat)
					: moment(inObj.date, 'YYYY-MM-DD').format(dateFormat)
			}
			let label = `${inObj?.id || ''} `
			if (date) {
				label += ` от ${date}`
			}
			return {
				head: 'Задачи на производство',
				label: label,
			}
		}
		case '/stock-operations/implementations': {
			const date = moment.isMoment(inObj.date)
				? moment(inObj.date).format(dateFormat)
				: moment(inObj.date, 'YYYY-MM-DD').format(dateFormat)
			const label = `${inObj?.id || ''} от ${date || ''}`
			return {
				head: 'Реализации',
				label: label,
			}
		}
		case '/stock-operations/reloc-remote-warehouse': {
			const date = inObj.date ? moment(inObj.date).format(dateFormat) : ''
			const label = `${inObj.id || ''} от ${date}`
			return {
				head: 'Перемещения удал. склад',
				label: label,
			}
		}
		case '/stock-operations/reloc-defect': {
			const date = inObj.date ? moment(inObj.date).format(dateFormat) : ''
			const label = `${inObj.id || ''} от ${date}`
			return {
				head: 'Перемещения в брак',
				label: label,
			}
		}
		case '/stock/semifs': {
			const partyNum = inObj.partyNum
			const prodLabel = inObj.taskRep?.task?.prodCat?.label
			const prodCatKindSemif = inObj.prodCatKind

			const recordLabel = `${partyNum} - ${prodLabel} ${prodCatKindSemif}`
			return {
				breadcrumbsTitle: 'Полуфабрикат',
				recordLabel,
			}
		}

		case '/stock/ready-prods': {
			const partyNum = inObj.taskRep?.partyNum
			const prodLabel = inObj.taskRep?.task?.prodCat?.label
			const prodCatKindSemif = inObj.prodCatKind
			const recordLabel = `${partyNum} - ${prodLabel} ${prodCatKindSemif}`
			return {
				breadcrumbsTitle: 'Готовая продукция',
				recordLabel,
			}
		}
		case '/stock/ready-prod-resales': {
			const { prodCatLabel, prodCatKindLabel } = getDataFromSupplyProdRmProvProd(inObj.supplProd)
			const assignedPartyNum = inObj.supplProd?.assignedPartyNum
			const recordLabel = `${assignedPartyNum} - ${prodCatLabel} ${prodCatKindLabel}`
			return {
				breadcrumbsTitle: 'ГП на перепродажу',
				recordLabel,
			}
		}
		case '/stock/wastes': {
			const partyNum = inObj.partyNum
			const prodLabel = inObj.prodCat
			if (inObj.stockRawMat || inObj.stockReadyProdResale) {
				const rawMatStateLabelShort =
					inObj.vocRawMatStateLabelShortt || inObj.vocTempRegimeLabelShort
				const recordLabel = `${partyNum} - ${prodLabel} ${rawMatStateLabelShort}`
				return {
					breadcrumbsTitle: 'Отходы',
					recordLabel,
				}
			}

			const recordLabel = `${partyNum} - ${prodLabel} ${inObj.prodCatKind}`
			return {
				breadcrumbsTitle: 'Отходы',
				recordLabel,
			}
		}
		case '/stock/samples': {
			const partyNum = inObj.partyNum
			const prodCat = inObj.prodLabel
			const prodCatKind = inObj.prodCatKind
			const recordLabel = `${partyNum} - ${prodCat} ${prodCatKind}`
			return {
				breadcrumbsTitle: 'Образцы',
				recordLabel,
			}
		}
		case '/reports/consolidated-reports':
			return {
				breadcrumbsTitle: 'Сводные отчеты по ДЗ',
				recordLabel: inObj.id,
			}
		case '/stock-operations/disposals':
			const date = moment.isMoment(inObj.date)
				? moment(inObj.date).format(dateFormat)
				: moment(inObj.date, 'YYYY-MM-DD').format(dateFormat)
			const label = `${inObj.id || ''} от ${date || ''}`
			return {
				head: 'Утилизации',
				label,
			}
		default:
			return '-'
	}
}
/**
 * возвращает базовый функционал для мэин провайдера
 * @param {Object} inStateRef - стейтреф
 * @param {Object} oldData - оло дата
 * @param {Object} executeDispatch - диспатчер
 * @param {{modCtx: Object, dataServerCtx: Object, dataUrl: string}} additional - ополнитекльные нужные обьекты и функции
 * @param {Object} selectors - селекторы из провайдера
 * @param {Object} inSetFields - ключ это поле значение это одно из common или obj
 * @param {Object} inDeepStateFunctionsFields - структура с ключами (пример ниже)
 * @returns {object}
 */
export const getCommonProviderFunctions = (
	inStateRef,
	oldData,
	executeDispatch,
	additional = {},
	selectors,
	inSetFields = {},
	inDeepStateFunctionsFields = {}
) => {
	const { modCtx, dataUrl, params, requiredFields = [], pageUrl, history } = additional
	const dropErrors = () => {
		executeDispatch({
			...inStateRef.current,
			formErrors: {},
		})
	}
	const commonFieldUpdate = (field, value, required, isUpdateFieldInViewMod = false) => {
		if (modCtx.mod === modes.view && !isUpdateFieldInViewMod) return
		let formErrors = inStateRef.current.formErrors || {}
		if (required) {
			if (!basicValidator(value)) {
				formErrors = { ...formErrors, [field]: true }
			} else {
				delete formErrors[field]
			}
		}
		executeDispatch({
			...inStateRef.current,
			data: {
				...inStateRef.current.data,
				[field]: typeof value === 'object' ? lodash.cloneDeep(value) : value,
			},
			formErrors,
		})
	}
	const commonObjUpdate = (field, value) => {
		const val = selectors[field]?.find((e) => {
			return String(e.id) === String(value?.id || value)
		})
		if (!val) throw Error('выбираемый сыелекс не найден ')
		commonFieldUpdate(field, val)
	}
	const commonDeepFieldUpdate = (inFields, inUuids, field, value, required) => {
		let newFormErrors = inStateRef.current.formErrors || {}
		if (required) {
			const errField = [field, ...inUuids].join('.')
			const isValidValue = Array.isArray(value) ? !!value.length : basicValidator(value)
			if (!isValidValue) {
				newFormErrors = { ...inStateRef.current.formErrors, [errField]: true }
			} else {
				delete newFormErrors[errField]
			}
		}
		const newState = deepUpdateField(inFields, inUuids, inStateRef.current.data, field, value)
		executeDispatch({ ...inStateRef.current, data: newState, formErrors: newFormErrors })
	}
	const commonDeepObjUpdate = (inFields, inUuids, field, value, required) => {
		if (value === null) {
			return commonDeepFieldUpdate(inFields, inUuids, field, value)
		}
		const val = lodash.get(selectors, [...inFields, field].join('.'))?.find((e) => {
			return String(e.id) === String(value?.id || value)
		})
		if (!val) throw Error('выбираемый сыелекс не найден ')
		commonDeepFieldUpdate(inFields, inUuids, field, val, required)
	}
	const getEditedData = () => {
		if (modCtx.mod === modes.new) {
			return lodash.cloneDeep(inStateRef.current.data)
		}
		return getObjDiff(oldData, inStateRef.current.data)
	}

	const isEdited = () => {
		if (modCtx.mod === modes.view) {
			return false
		}
		const editedFields = getEditedData()
		return isDataEdited(editedFields)
	}

	const setError = (inFields, inUuids, field, text) => {
		if (!inFields.length && !inUuids.length) {
			executeDispatch({
				...inStateRef.current,
				formErrors: { ...inStateRef.current.formErrors, [field]: text },
			})
			return
		}
	}

	const stateFunctions = {}
	Object.entries(inSetFields).forEach(([k, v]) => {
		switch (v) {
			case 'common':
				stateFunctions[`set${capitalizeFirstLetter(k)}`] = (val) => {
					commonFieldUpdate(k, val)
				}
				break
			case 'obj':
				stateFunctions[`set${capitalizeFirstLetter(k)}`] = (val) => {
					commonObjUpdate(k, val)
				}
				break
			default:
				break
		}
	})
	/**
 * пример структуры inDeepStateFunctionsFields
 *  {
		products: [
			{
				contrQuota: 'obj'
			}
		]
	}
 */
	Object.entries(inDeepStateFunctionsFields).forEach(([mainK, mainV]) => {
		stateFunctions[mainK] = {
			get: (inUuid) => {
				return lodash.cloneDeep(deepFind([mainK], [inUuid], inStateRef.current.data))
			},
			edit: (inUuid, value) => {
				const newElement = editByUuid(inUuid, inStateRef.current.data[mainK], value)
				commonFieldUpdate(mainK, newElement)
			},
			delete: (inUuid) => {
				const newProducts = removeByUuid(inUuid, inStateRef.current.data[mainK])
				commonFieldUpdate(mainK, newProducts)
				for (const errKey of Object.keys(inStateRef.current.formErrors)) {
					if (errKey.includes(inUuid)) {
						const errors = inStateRef.current.formErrors
						delete errors[errKey]
						executeDispatch({ ...inStateRef.current, formErrors: errors })
					}
				}
			},
			create: (inData) => {
				let newArr = [
					...lodash.cloneDeep(inStateRef.current.data[mainK] || []),
					getNewObj({ ...(inData || {}) }),
				]
				commonFieldUpdate(mainK, newArr)
				return newArr.slice(-1)[0]._uuid_
			},
			clone: (inUuid, include = [], exclude = []) => {
				const foundField = deepFind([mainK], [inUuid], inStateRef.current.data)
				if (!include.length && !exclude.length) throw Error('include and exclude are empty')
				let clonedData = {}
				if (include.length) {
					include.forEach((k) => {
						if (!Object.keys(foundField).includes(k)) throw Error(`fieid: ${k} does not exist`)
						clonedData = { ...clonedData, [k]: foundField[k] }
					})
				} else {
					exclude = [...exclude, 'id', '_uuid_']
					exclude.forEach((k) => {
						if (!Object.keys(foundField).includes(k)) throw Error(`fieid: ${k} does not exist`)
						Object.keys(foundField).forEach((k) => {
							if (exclude.includes(k)) return
							clonedData = { ...clonedData, [k]: foundField[k] }
						})
					})
				}
				return clonedData
			},
		}
		Object.entries(mainV).forEach(([k, v]) => {
			if (typeof mainV[k] === 'object') {
				switch (mainV[k][k.slice(0, -1)]) {
					case 'common':
						stateFunctions[mainK][k] = {}
						stateFunctions[mainK][k][`set${capitalizeFirstLetter(k.slice(0, -1))}`] = (
							inUuid,
							val,
							inData,
							inRequired
						) => {
							const objField = k.slice(0, -1)
							const oldElement = stateFunctions[mainK].get(inUuid)
							let required = false
							const reqFields =
								typeof requiredFields === 'function' ? requiredFields(oldElement) : requiredFields
							if (reqFields[mainK]?.includes(k)) {
								required = inRequired ?? true
							}
							if (inData.length > (val[k]?.length || 0)) {
								inData = inData[inData.length - 1]
								let vals = val?.[k] || []
								let newArr = [...vals, getNewObj({ [objField]: { id: inData } })]
								commonDeepFieldUpdate([mainK], [inUuid], k, newArr, required)
								return newArr.slice(-1)[0]._uuid_
							}
							const foundIndex = inStateRef.current.data[mainK]?.findIndex(
								(el) => el._uuid_ === val._uuid_
							)
							if (foundIndex === -1) throw Error('выбираемый сыелекс не найден ')
							let newEls = [
								...inStateRef.current.data[mainK][foundIndex][k].filter((el) =>
									inData.some((id) => id === el[objField].id)
								),
							]
							commonDeepFieldUpdate([mainK], [inUuid], k, newEls, required)
						}

						break
					// case 'obj':
					// 	break
					default:
						break
				}
			}
			switch (v) {
				case 'common':
					stateFunctions[mainK][`set${capitalizeFirstLetter(k)}`] = (inUuid, val, inRequired) => {
						const oldElement = stateFunctions[mainK].get(inUuid)
						let required = false
						const reqFields =
							typeof requiredFields === 'function' ? requiredFields(oldElement) : requiredFields
						if (reqFields[mainK]?.includes(k)) {
							required = inRequired ?? true
						}
						commonDeepFieldUpdate([mainK], [inUuid], k, val, required)
					}
					break
				case 'obj':
					stateFunctions[mainK][`set${capitalizeFirstLetter(k)}`] = (inUuid, val, inRequired) => {
						const oldElement = stateFunctions[mainK].get(inUuid)
						let required = false
						const reqFields =
							typeof requiredFields === 'function' ? requiredFields(oldElement) : requiredFields
						if (reqFields[mainK]?.includes(k)) {
							required = inRequired ?? true
						}
						commonDeepObjUpdate([mainK], [inUuid], k, val, required)
					}
					break
				default:
					break
			}
		})
	})
	const serverDelete = async () => {
		await axios.delete(`${dataUrl}/${+inStateRef.current.data.id}`)
		return true
	}

	const serverEdit = async () => {
		if (!isEdited()) return modCtx.set(modes.view)
		let body = {}
		let method = ''
		let axiosMethod = ''
		if (params.id === 'new') {
			body = inStateRef.current.data
			method = 'POST'
			axiosMethod = 'post'
		} else {
			method = 'PUT'
			axiosMethod = 'put'
			body = getEditedData()
		}
		let res = null
		executeDispatch({ ...inStateRef.current, isPendingReq: true })
		try {
			res = await axios[axiosMethod](dataUrl, body)
			executeDispatch({ ...inStateRef.current, isPendingReq: false })
			if (res.data?.data?.id) {
				if (method === 'POST') {
					goToItem(history, { url: pageUrl, id: res.data.data.id })
				}
				executeDispatch({ ...inStateRef.current, reloadUuid: v4() })
				modCtx.set(modes.view)
				return { method: method, id: res.data.data.id }
			}
		} catch (err) {
			executeDispatch({ ...inStateRef.current, isPendingReq: false })
		}

		return res
	}
	return {
		commonFieldUpdate,
		commonObjUpdate,
		commonDeepFieldUpdate,
		commonDeepObjUpdate,
		getEditedData,
		isEdited,
		stateFunctions,
		serverDelete,
		serverEdit,
		setError,
		deepFind,
		editByUuid,
		removeByUuid,
		getNewObj,
		dropErrors,
	}
}

/**
 * возвращает базовый функционал для листа мэин провайдера
 * @param {Object} inStateRef - стейтреф
 * @param {Object} executeDispatch - диспатчер
 * @param {{modCtx: Object, dataServerCtx: Object, dataUrl: string}} additional - дополнительные нужные обьекты и функции
 * @param {Object} selectors - селекторы из провайдера
 * @param {Object} inSetFields - ключ это поле значение это одно из common или obj
 */

export const getCommonProviderModalFunctions = (
	inStateRef,
	executeDispatch,
	additional = {},
	selectors,
	inSetFields = {},
	inDeepStateFunctionsFields = []
) => {
	const commonModalFieldUpdate = (field, value, modalName) => {
		executeDispatch({
			...inStateRef.current,
			[modalName]: {
				...inStateRef.current[modalName],
				[field]: typeof value === 'object' ? lodash.cloneDeep(value) : value,
			},
		})
	}

	const commonModalObjUpdate = (field, value, modalName) => {
		if (value === null) {
			commonModalFieldUpdate(field, null, modalName)
			return
		}
		const val = selectors[modalName][field]?.find((e) => {
			return String(e.id) === String(value.id || value)
		})
		if (!val) throw Error('выбираемый сыелекс не найден ')
		commonModalFieldUpdate(field, val, modalName)
	}

	const commonModalDeepFieldUpdate = (inArrField, inModalName, field, inUuids, value) => {
		let newFormErrors = inStateRef.current.formErrors || {}
		const errField = [field, ...inUuids].join('.')
		if (!basicValidator(value)) {
			newFormErrors = { ...inStateRef.current.formErrors, [errField]: true }
		} else {
			delete newFormErrors[errField]
		}
		const newState = deepUpdateField(
			[inArrField],
			inUuids,
			inStateRef.current[inModalName],
			field,
			value
		)
		executeDispatch({
			...inStateRef.current,
			[inModalName]: {
				...inStateRef.current[inModalName],
				...newState,
			},
		})
	}

	const stateFunctions = {}

	inSetFields.forEach((props) => {
		switch (props.updateVal) {
			case 'common':
				stateFunctions[
					`set${capitalizeFirstLetter(props.modalName)}${capitalizeFirstLetter(props.field)}`
				] = (val) => {
					commonModalFieldUpdate(props.field, val, props.modalName)
				}
				break
			case 'obj':
				stateFunctions[
					`set${capitalizeFirstLetter(props.modalName)}${capitalizeFirstLetter(props.field)}`
				] = (val) => {
					commonModalObjUpdate(props.field, val, props.modalName)
				}
				break
			default:
				break
		}
		stateFunctions[`open${capitalizeFirstLetter(props.modalName)}`] = () => {
			executeDispatch({
				...inStateRef.current,
				[props.modalName]: {
					...inStateRef.current[props.modalName],
					__isOpen: true,
				},
			})
		}
		stateFunctions[`close${capitalizeFirstLetter(props.modalName)}`] = () => {
			executeDispatch({
				...inStateRef.current,
				[props.modalName]: {
					// ...inStateRef.current[props.modalName],
					__isOpen: false,
				},
			})
		}
		stateFunctions[`reset${capitalizeFirstLetter(props.modalName)}`] = () => {
			let resetItems = {}
			inSetFields.forEach((el) => {
				resetItems = { ...resetItems, [`${el.modalName}${capitalizeFirstLetter(el.field)}`]: [] }
			})
			inDeepStateFunctionsFields.forEach((el) => {
				resetItems = { ...resetItems, [el.field]: [] }
			})
			let newFormErrors = {}
			for (const errKey of Object.keys(inStateRef.current.formErrors || {})) {
				if (errKey.includes(props.modalName)) {
					continue
				} else {
					newFormErrors = { ...newFormErrors, [errKey]: inStateRef.current.formErrors[errKey] }
				}
			}
			executeDispatch({
				...inStateRef.current,
				formErrors: newFormErrors,
				[props.modalName]: {
					...resetItems,
					__isOpen: false,
				},
			})
		}
	})
	inDeepStateFunctionsFields.forEach((props) => {
		if (stateFunctions[`${props.modalName}${capitalizeFirstLetter(props.mainField)}`]) return
		stateFunctions[`${props.modalName}${capitalizeFirstLetter(props.mainField)}`] = {
			delete: (inUuid) => {
				const items = removeByUuid(
					inUuid,
					inStateRef.current[props.modalName][
						`${props.modalName}${capitalizeFirstLetter(props.field)}`
					]
				)
				commonModalFieldUpdate(
					`${props.modalName}${capitalizeFirstLetter(props.field)}`,
					items,
					props.modalName
				)
			},
			create: (inData) => {
				let newArr = [
					...lodash.cloneDeep(
						inStateRef.current[props.modalName][
							`${props.modalName}${capitalizeFirstLetter(props.field)}`
						]
					),
					getNewObj({ ...(inData || {}) }),
				]
				commonModalFieldUpdate(
					`${props.modalName}${capitalizeFirstLetter(props.field)}`,
					newArr,
					props.modalName
				)
				return newArr.slice(-1)[0]._uuid_
			},
		}
	})
	inDeepStateFunctionsFields.forEach((props) => {
		switch (props.updateVal) {
			case 'common':
				stateFunctions[`${props.modalName}${capitalizeFirstLetter(props.mainField)}`][
					`set${capitalizeFirstLetter(props.field)}`
				] = (inUuid, val) => {
					commonModalDeepFieldUpdate(
						`${props.modalName}${capitalizeFirstLetter(props.mainField)}`,
						props.modalName,
						props.field,
						[inUuid],
						val
					)
				}
				break
			default:
				break
		}
	})
	return {
		commonModalFieldUpdate,
		commonModalObjUpdate,
		stateFunctions,
		modalFunctions: stateFunctions,
	}
}
