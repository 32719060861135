import { getColumnSearchProps } from '../../../utils'

export const columns = (data) => [
	{
		title: 'Наименование',
		dataIndex: 'label',
		key: 'label',
		width: '90%',
		...getColumnSearchProps([...new Set(data.map((e) => e.label))], undefined, 'label'),
	},
]
