import React from 'react'
import { Modal as ModalAntd, Button } from 'antd'
import { modes } from '../../../utils'
import { asyncShowConfirmModal } from '../..'

const Modal = (props) => {
	const {
		visible,
		closeModal,
		getForm,
		addItem,
		editItem,
		deleteItem,
		record,
		setRecord,
		form,
		maskClosable = false,
		data,
		mod,
		vocUrl,
		setState,
		getDataForVocs,
	} = props
	const title =
		mod === modes.new
			? 'Создание нового элемента'
			: mod === modes.edit
			? 'Редактирование элемента'
			: null
	const action = mod === modes.new ? addItem : mod === modes.edit ? editItem : deleteItem

	return (
		<ModalAntd
			maskClosable={maskClosable}
			destroyOnClose
			title={title}
			visible={visible}
			onCancel={async () => {
				if (form.isFieldsTouched()) {
					if (
						!(await asyncShowConfirmModal({
							title: 'Выйти без сохранения изменений?',
						}))
					)
						return
				}
				setRecord(null)
				closeModal()
			}}
			centered
			width="40%"
			footer={
				<Button
					type="primary"
					htmlType="submit"
					onClick={async () => {
						form.submit()
						// await getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
					}}
				>
					OK
				</Button>
			}
		>
			{getForm(data, record?.id, form, action, { setState, vocUrl, getDataForVocs })}
		</ModalAntd>
	)
}

export default Modal
