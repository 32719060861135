/**
 * Когда открылось модальное окно для создания/релдактирования элемента вкладки
 * этот элемент нужно исключить из отображения/заменить на предыдущий, в таблице под модальным окном
 * @param {Array<Object>} inArr - массив
 * @param {Object} item - элемент на вход модального окна
 */
export const prepareForOpenedModal = (inArr, item) => {
	if (!item) return inArr
	if (!inArr) return []
	let result = inArr.filter((e) => {
		if (item.isNew) {
			if (item._uuid_ === e._uuid_) return false
		}
		return true
	})

	if (item?.isNew) return result
	const foundIndx = inArr.findIndex((e) => e._uuid_ === item._uuid_)
	if (foundIndx === -1) return result
	result = [
		...result.slice(0, foundIndx),
		item.prevData,
		...result.slice(foundIndx + 1, result.length),
	]
	return result
}
