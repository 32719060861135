module.exports = [
	{
		assignedPartyNum: '22ФР-0258',
		costPrice: 2932476,
		idSupply: 1,
		Column7: 'Приобретение товаров и услуг Ф000-001769 от 05.10.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0259',
		costPrice: 187000,
		idSupply: 2,
		Column7: 'Приобретение товаров и услуг Ф000-001768 от 05.10.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0260',
		costPrice: 1512000,
		idSupply: 3,
		Column7: 'Приобретение товаров и услуг Ф000-001771 от 06.10.2022 8:50:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0261',
		costPrice: 685800,
		idSupply: 3,
		Column7: 'Приобретение товаров и услуг Ф000-001771 от 06.10.2022 8:50:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0262',
		costPrice: 592200,
		idSupply: 3,
		Column7: 'Приобретение товаров и услуг Ф000-001771 от 06.10.2022 8:50:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0263',
		costPrice: 3549000,
		idSupply: 4,
		Column7: 'Приобретение товаров и услуг Ф000-001772 от 07.10.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0265',
		costPrice: 2088388.8,
		idSupply: 6,
		Column7: 'Приобретение товаров и услуг Ф000-001784 от 09.10.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0266',
		costPrice: 964206.61,
		idSupply: 6,
		Column7: 'Приобретение товаров и услуг Ф000-001784 от 09.10.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0268',
		costPrice: 862820,
		idSupply: 8,
		Column7: 'Приобретение товаров и услуг Ф000-000933 от 10.10.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0269',
		costPrice: 105600,
		idSupply: 10,
		Column7: 'Приобретение товаров и услуг Ф000-001786 от 11.10.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0190',
		costPrice: 2345070.78,
		idSupply: 124,
		Column7: 'Приобретение товаров и услуг Ф000-001278 от 01.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0191',
		costPrice: 201612.42,
		idSupply: 124,
		Column7: 'Приобретение товаров и услуг Ф000-001278 от 01.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0191',
		costPrice: 1695454.34,
		idSupply: 127,
		Column7: 'Приобретение товаров и услуг Ф000-001279 от 01.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0192',
		costPrice: 2900000,
		idSupply: 130,
		Column7: 'Приобретение товаров и услуг Ф000-001280 от 01.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0195',
		costPrice: 3464.5,
		idSupply: 133,
		Column7: 'Приобретение товаров и услуг Ф000-001321 от 02.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0197',
		costPrice: 145781,
		idSupply: 134,
		Column7: 'Приобретение товаров и услуг Ф000-001319 от 03.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0198',
		costPrice: 102,
		idSupply: 135,
		Column7: 'Приобретение товаров и услуг Ф000-001320 от 03.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0199',
		costPrice: 4507787.38,
		idSupply: 136,
		Column7: 'Приобретение товаров и услуг Ф000-001337 от 03.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0200',
		costPrice: 1797705,
		idSupply: 137,
		Column7: 'Приобретение товаров и услуг Ф000-001318 от 04.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0201',
		costPrice: 594093,
		idSupply: 138,
		Column7: 'Приобретение товаров и услуг Ф000-001317 от 05.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0203',
		costPrice: 68629,
		idSupply: 142,
		Column7: 'Приобретение товаров и услуг Ф000-001283 от 02.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0193',
		costPrice: 20927,
		idSupply: 143,
		Column7: 'Приобретение товаров и услуг Ф000-001286 от 02.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0202',
		costPrice: 1121285,
		idSupply: 144,
		Column7: 'Приобретение товаров и услуг Ф000-001470 от 05.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0204',
		costPrice: 52226,
		idSupply: 145,
		Column7: 'Приобретение товаров и услуг Ф000-001344 от 07.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0205',
		costPrice: 312960,
		idSupply: 146,
		Column7: 'Приобретение товаров и услуг Ф000-001345 от 08.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0207',
		costPrice: 4198356.41,
		idSupply: 148,
		Column7: 'Приобретение товаров и услуг Ф000-001408 от 08.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0209',
		costPrice: 2145000,
		idSupply: 149,
		Column7: 'Приобретение товаров и услуг Ф000-001395 от 09.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0210',
		costPrice: 803000,
		idSupply: 149,
		Column7: 'Приобретение товаров и услуг Ф000-001395 от 09.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0210',
		costPrice: 1081360.98,
		idSupply: 150,
		Column7: 'Приобретение товаров и услуг Ф000-001396 от 09.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0211',
		costPrice: 19571.5,
		idSupply: 151,
		Column7: 'Приобретение товаров и услуг Ф000-001397 от 10.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0213',
		costPrice: 1445600,
		idSupply: 152,
		Column7: 'Приобретение товаров и услуг Ф000-001399 от 12.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0214',
		costPrice: 883722.09,
		idSupply: 153,
		Column7: 'Приобретение товаров и услуг Ф000-001410 от 13.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0215',
		costPrice: 16705.5,
		idSupply: 154,
		Column7: 'Приобретение товаров и услуг Ф000-001431 от 12.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0217',
		costPrice: 144123.5,
		idSupply: 155,
		Column7: 'Приобретение товаров и услуг Ф000-001442 от 13.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0221',
		costPrice: 2924378.8,
		idSupply: 156,
		Column7: 'Приобретение товаров и услуг Ф000-001436 от 14.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0222',
		costPrice: 3474610,
		idSupply: 157,
		Column7: 'Приобретение товаров и услуг Ф000-001439 от 14.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0222',
		costPrice: 464445.6,
		idSupply: 158,
		Column7: 'Приобретение товаров и услуг Ф000-001441 от 14.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0223',
		costPrice: 509478.67,
		idSupply: 158,
		Column7: 'Приобретение товаров и услуг Ф000-001441 от 14.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0218',
		costPrice: 326040,
		idSupply: 159,
		Column7: 'Приобретение товаров и услуг Ф000-001462 от 13.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0219',
		costPrice: 190190,
		idSupply: 159,
		Column7: 'Приобретение товаров и услуг Ф000-001462 от 13.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0220',
		costPrice: 216964,
		idSupply: 159,
		Column7: 'Приобретение товаров и услуг Ф000-001462 от 13.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0224',
		costPrice: 1127.5,
		idSupply: 161,
		Column7: 'Приобретение товаров и услуг Ф000-001460 от 15.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0225',
		costPrice: 1743561.6,
		idSupply: 162,
		Column7: 'Приобретение товаров и услуг Ф000-001602 от 14.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0226',
		costPrice: 68656.5,
		idSupply: 163,
		Column7: 'Приобретение товаров и услуг Ф000-001461 от 15.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0227',
		costPrice: 475006,
		idSupply: 164,
		Column7: 'Приобретение товаров и услуг Ф000-001469 от 15.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0228',
		costPrice: 89292,
		idSupply: 165,
		Column7: 'Приобретение товаров и услуг Ф000-001528 от 17.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0231',
		costPrice: 30794,
		idSupply: 166,
		Column7: 'Приобретение товаров и услуг Ф000-001531 от 19.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0232',
		costPrice: 2257020.64,
		idSupply: 167,
		Column7: 'Приобретение товаров и услуг Ф000-001532 от 19.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0233',
		costPrice: 1601858.76,
		idSupply: 167,
		Column7: 'Приобретение товаров и услуг Ф000-001532 от 19.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0229',
		costPrice: 1080000,
		idSupply: 168,
		Column7: 'Приобретение товаров и услуг Ф000-001530 от 18.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0230',
		costPrice: 799200,
		idSupply: 168,
		Column7: 'Приобретение товаров и услуг Ф000-001530 от 18.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0206',
		costPrice: 403942,
		idSupply: 169,
		Column7: 'Приобретение товаров и услуг Ф000-001535 от 08.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0235',
		costPrice: 269496,
		idSupply: 170,
		Column7: 'Приобретение товаров и услуг Ф000-001533 от 20.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0236',
		costPrice: 30080,
		idSupply: 170,
		Column7: 'Приобретение товаров и услуг Ф000-001533 от 20.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0234',
		costPrice: 937207,
		idSupply: 171,
		Column7: 'Приобретение товаров и услуг Ф000-001534 от 21.09.2022 11:07:23',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0238',
		costPrice: 2960000,
		idSupply: 172,
		Column7: 'Приобретение товаров и услуг Ф000-001595 от 21.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0237',
		costPrice: 86009.5,
		idSupply: 173,
		Column7: 'Приобретение товаров и услуг Ф000-001596 от 21.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0240',
		costPrice: 142200,
		idSupply: 174,
		Column7: 'Приобретение товаров и услуг Ф000-001597 от 21.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0239',
		costPrice: 2107430.6,
		idSupply: 175,
		Column7: 'Приобретение товаров и услуг Ф000-001761 от 21.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0241',
		costPrice: 104143.5,
		idSupply: 176,
		Column7: 'Приобретение товаров и услуг Ф000-001645 от 23.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0244',
		costPrice: 11250,
		idSupply: 176,
		Column7: 'Приобретение товаров и услуг Ф000-001645 от 23.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0245',
		costPrice: 784604,
		idSupply: 177,
		Column7: 'Приобретение товаров и услуг Ф000-001644 от 24.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0245',
		costPrice: 1624000,
		idSupply: 178,
		Column7: 'Приобретение товаров и услуг Ф000-001643 от 24.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0246',
		costPrice: 293300,
		idSupply: 179,
		Column7: 'Приобретение товаров и услуг Ф000-001683 от 25.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0247',
		costPrice: 471300,
		idSupply: 180,
		Column7: 'Приобретение товаров и услуг Ф000-001684 от 25.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0248',
		costPrice: 307598,
		idSupply: 181,
		Column7: 'Приобретение товаров и услуг Ф000-001682 от 25.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0251',
		costPrice: 2506873.5,
		idSupply: 182,
		Column7: 'Приобретение товаров и услуг Ф000-001677 от 25.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0250',
		costPrice: 331527,
		idSupply: 183,
		Column7: 'Приобретение товаров и услуг Ф000-001736 от 28.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0252',
		costPrice: 2182224.45,
		idSupply: 184,
		Column7: 'Приобретение товаров и услуг Ф000-001734 от 29.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0253',
		costPrice: 817120.97,
		idSupply: 184,
		Column7: 'Приобретение товаров и услуг Ф000-001734 от 29.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0254',
		costPrice: 3081540,
		idSupply: 185,
		Column7: 'Приобретение товаров и услуг Ф000-001735 от 29.09.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0255',
		costPrice: 4356262.58,
		idSupply: 186,
		Column7: 'Приобретение товаров и услуг Ф000-001737 от 30.09.2022 20:56:22',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0256',
		costPrice: 2915512.5,
		idSupply: 187,
		Column7: 'Приобретение товаров и услуг Ф000-001751 от 02.10.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0268',
		costPrice: 3381748.81,
		idSupply: 200,
		Column7: 'Приобретение товаров и услуг Ф000-001791 от 10.10.2022 11:10:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0267',
		costPrice: 293143.2,
		idSupply: 201,
		Column7: 'Приобретение товаров и услуг Ф000-001795 от 09.10.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0264',
		costPrice: 2960000,
		idSupply: 202,
		Column7: 'Приобретение товаров и услуг Ф000-001790 от 07.10.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0270',
		costPrice: 450500,
		idSupply: 203,
		Column7: 'Приобретение товаров и услуг Ф000-001794 от 12.10.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0271',
		costPrice: 3505500,
		idSupply: 204,
		Column7: 'Приобретение товаров и услуг Ф000-001801 от 13.10.2022 12:01:22',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0272',
		costPrice: 546912,
		idSupply: 205,
		Column7: 'Приобретение товаров и услуг Ф000-001815 от 14.10.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0257',
		costPrice: 2090501.6,
		idSupply: 206,
		Column7: 'Приобретение товаров и услуг Ф000-001820 от 04.10.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0273',
		costPrice: 216524,
		idSupply: 207,
		Column7: 'Приобретение товаров и услуг Ф000-002156 от 17.10.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0274',
		costPrice: 513032,
		idSupply: 208,
		Column7: 'Приобретение товаров и услуг Ф000-001833 от 19.10.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0275',
		costPrice: 514432,
		idSupply: 209,
		Column7: 'Приобретение товаров и услуг Ф000-001839 от 22.10.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0276',
		costPrice: 2184710.4,
		idSupply: 210,
		Column7: 'Приобретение товаров и услуг Ф000-001840 от 23.10.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0277',
		costPrice: 505989,
		idSupply: 210,
		Column7: 'Приобретение товаров и услуг Ф000-001840 от 23.10.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0278',
		costPrice: 513592,
		idSupply: 211,
		Column7: 'Приобретение товаров и услуг Ф000-001867 от 26.10.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0279',
		costPrice: 920855.93,
		idSupply: 212,
		Column7: 'Приобретение товаров и услуг Ф000-001868 от 27.10.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0280',
		costPrice: 2356907.49,
		idSupply: 212,
		Column7: 'Приобретение товаров и услуг Ф000-001868 от 27.10.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0281',
		costPrice: 54400,
		idSupply: 213,
		Column7: 'Приобретение товаров и услуг Ф000-001869 от 27.10.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0282',
		costPrice: 511072,
		idSupply: 214,
		Column7: 'Приобретение товаров и услуг Ф000-001885 от 29.10.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0283',
		costPrice: 515832,
		idSupply: 215,
		Column7: 'Приобретение товаров и услуг Ф000-001893 от 02.11.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0284',
		costPrice: 507432,
		idSupply: 216,
		Column7: 'Приобретение товаров и услуг Ф000-001926 от 04.11.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0285',
		costPrice: 502504,
		idSupply: 217,
		Column7: 'Приобретение товаров и услуг Ф000-001937 от 09.11.2022 15:55:04',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0286',
		costPrice: 3193728,
		idSupply: 218,
		Column7: 'Приобретение товаров и услуг Ф000-001969 от 16.11.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0287',
		costPrice: 510085,
		idSupply: 219,
		Column7: 'Приобретение товаров и услуг Ф000-001968 от 16.11.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0288',
		costPrice: 546793,
		idSupply: 220,
		Column7: 'Приобретение товаров и услуг Ф000-001971 от 18.11.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0289',
		costPrice: 535173,
		idSupply: 221,
		Column7: 'Приобретение товаров и услуг Ф000-001975 от 19.11.2022 11:20:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0290',
		costPrice: 538416,
		idSupply: 222,
		Column7: 'Приобретение товаров и услуг Ф000-001976 от 23.11.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0294',
		costPrice: 540396,
		idSupply: 223,
		Column7: 'Приобретение товаров и услуг Ф000-001977 от 24.11.2022 11:30:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0291',
		costPrice: 1574736.59,
		idSupply: 224,
		Column7: 'Приобретение товаров и услуг Ф000-001979 от 24.11.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0292',
		costPrice: 993852.25,
		idSupply: 225,
		Column7: 'Приобретение товаров и услуг Ф000-001980 от 24.11.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0293',
		costPrice: 215364.5,
		idSupply: 225,
		Column7: 'Приобретение товаров и услуг Ф000-001980 от 24.11.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0295',
		costPrice: 2795000,
		idSupply: 226,
		Column7: 'Приобретение товаров и услуг Ф000-001978 от 25.11.2022 14:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0296',
		costPrice: 533396,
		idSupply: 227,
		Column7: 'Приобретение товаров и услуг Ф000-001983 от 26.11.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0297',
		costPrice: 2795000,
		idSupply: 228,
		Column7: 'Приобретение товаров и услуг Ф000-001982 от 27.11.2022 14:10:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0298',
		costPrice: 2795000,
		idSupply: 229,
		Column7: 'Приобретение товаров и услуг Ф000-001981 от 28.11.2022 14:10:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0299',
		costPrice: 535916,
		idSupply: 230,
		Column7: 'Приобретение товаров и услуг Ф000-001998 от 30.11.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0300',
		costPrice: 2808000,
		idSupply: 231,
		Column7: 'Приобретение товаров и услуг Ф000-002059 от 30.11.2022 7:05:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0303',
		costPrice: 186960,
		idSupply: 231,
		Column7: 'Приобретение товаров и услуг Ф000-002059 от 30.11.2022 7:05:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0301',
		costPrice: 528076,
		idSupply: 232,
		Column7: 'Приобретение товаров и услуг Ф000-002078 от 03.12.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0302',
		costPrice: 135300,
		idSupply: 234,
		Column7: 'Приобретение товаров и услуг Ф000-002092 от 05.12.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0304',
		costPrice: 519984,
		idSupply: 235,
		Column7: 'Приобретение товаров и услуг Ф000-002089 от 07.12.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0305',
		costPrice: 543476,
		idSupply: 236,
		Column7: 'Приобретение товаров и услуг Ф000-002101 от 09.12.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0306',
		costPrice: 742170,
		idSupply: 237,
		Column7: 'Приобретение товаров и услуг Ф000-002094 от 09.12.2022 14:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0307',
		costPrice: 191520,
		idSupply: 237,
		Column7: 'Приобретение товаров и услуг Ф000-002094 от 09.12.2022 14:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0308',
		costPrice: 537596,
		idSupply: 239,
		Column7: 'Приобретение товаров и услуг Ф000-002102 от 13.12.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0309',
		costPrice: 527796,
		idSupply: 240,
		Column7: 'Приобретение товаров и услуг Ф000-002112 от 15.12.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0310',
		costPrice: 2795000,
		idSupply: 241,
		Column7: 'Приобретение товаров и услуг Ф000-002119 от 16.12.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0311',
		costPrice: 522756,
		idSupply: 242,
		Column7: 'Приобретение товаров и услуг Ф000-002121 от 17.12.2022 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0312',
		costPrice: 2795000,
		idSupply: 243,
		Column7: 'Приобретение товаров и услуг Ф000-002120 от 17.12.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0313',
		costPrice: 2795000,
		idSupply: 244,
		Column7: 'Приобретение товаров и услуг Ф000-002122 от 18.12.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0314',
		costPrice: 432120,
		idSupply: 245,
		Column7: 'Приобретение товаров и услуг Ф000-002173 от 23.12.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0315',
		costPrice: 2600000,
		idSupply: 246,
		Column7: 'Приобретение товаров и услуг Ф000-002164 от 25.12.2022 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0316',
		costPrice: 471640,
		idSupply: 247,
		Column7: 'Приобретение товаров и услуг Ф000-002193 от 27.12.2022 10:10:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0318',
		costPrice: 315725.52,
		idSupply: 248,
		Column7: 'Приобретение товаров и услуг Ф000-002194 от 29.12.2022 12:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0319',
		costPrice: 211062.48,
		idSupply: 248,
		Column7: 'Приобретение товаров и услуг Ф000-002194 от 29.12.2022 12:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0317',
		costPrice: 537036,
		idSupply: 249,
		Column7: 'Приобретение товаров и услуг Ф000-002172 от 27.12.2022 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0320',
		costPrice: 3138752.1,
		idSupply: 250,
		Column7: 'Приобретение товаров и услуг Ф000-000005 от 10.01.2023 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0321',
		costPrice: 3164106.6,
		idSupply: 254,
		Column7: 'Приобретение товаров и услуг Ф000-000020 от 14.01.2023 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0322',
		costPrice: 446420,
		idSupply: 255,
		Column7: 'Приобретение товаров и услуг Ф000-000039 от 17.01.2023 10:10:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0323',
		costPrice: 448240,
		idSupply: 256,
		Column7: 'Приобретение товаров и услуг Ф000-000047 от 19.01.2023 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0324',
		costPrice: 2795000,
		idSupply: 257,
		Column7: 'Приобретение товаров и услуг Ф000-000050 от 19.01.2023 14:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0325',
		costPrice: 2795000,
		idSupply: 257,
		Column7: 'Приобретение товаров и услуг Ф000-000050 от 19.01.2023 14:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0328',
		costPrice: 2795000,
		idSupply: 257,
		Column7: 'Приобретение товаров и услуг Ф000-000050 от 19.01.2023 14:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0326',
		costPrice: 3129276,
		idSupply: 259,
		Column7: 'Приобретение товаров и услуг Ф000-000053 от 20.01.2023 16:06:15',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0327',
		costPrice: 2127907.68,
		idSupply: 260,
		Column7: 'Приобретение товаров и услуг Ф000-000057 от 20.01.2023 12:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0329',
		costPrice: 5134187.04,
		idSupply: 262,
		Column7: 'Приобретение товаров и услуг Ф000-000081 от 26.01.2023 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0330',
		costPrice: 2093012.72,
		idSupply: 267,
		Column7: 'Приобретение товаров и услуг Ф000-000106 от 29.01.2023 12:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
	{
		assignedPartyNum: '22ФР-0331',
		costPrice: 2093012.72,
		idSupply: 268,
		Column7: 'Приобретение товаров и услуг Ф000-000104 от 29.01.2023 11:20:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
	{
		assignedPartyNum: '22ФР-0332',
		costPrice: 2093012.72,
		idSupply: 269,
		Column7: 'Приобретение товаров и услуг Ф000-000107 от 29.01.2023 12:25:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
	{
		assignedPartyNum: '22ФР-0333',
		costPrice: 2093012.72,
		idSupply: 270,
		Column7: 'Приобретение товаров и услуг Ф000-000105 от 29.01.2023 11:30:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
	{
		assignedPartyNum: '22ФР-0334',
		costPrice: 462800,
		idSupply: 271,
		Column7: 'Приобретение товаров и услуг Ф000-000115 от 01.02.2023 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0335',
		costPrice: 1278900,
		idSupply: 272,
		Column7: 'Приобретение товаров и услуг Ф000-000121 от 02.02.2023 13:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0336',
		costPrice: 3000000,
		idSupply: 273,
		Column7: 'Приобретение товаров и услуг Ф000-000122 от 02.02.2023 12:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0337',
		costPrice: 1332909.03,
		idSupply: 274,
		Column7: 'Приобретение товаров и услуг Ф000-000124 от 02.02.2023 12:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'BYN',
	},
	{
		assignedPartyNum: '22ФР-0338',
		costPrice: 1514500,
		idSupply: 275,
		Column7: 'Приобретение товаров и услуг Ф000-000123 от 02.02.2023 13:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0339',
		costPrice: 437580,
		idSupply: 276,
		Column7: 'Приобретение товаров и услуг Ф000-000137 от 03.02.2023 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0341',
		costPrice: 3393000,
		idSupply: 278,
		Column7: 'Приобретение товаров и услуг Ф000-000157 от 07.02.2023 12:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0342',
		costPrice: 2017775.7,
		idSupply: 279,
		Column7: 'Приобретение товаров и услуг Ф000-000169 от 14.02.2023 7:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
	{
		assignedPartyNum: '22ФР-0343',
		costPrice: 2017775.7,
		idSupply: 280,
		Column7: 'Приобретение товаров и услуг Ф000-000170 от 14.02.2023 7:20:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
	{
		assignedPartyNum: '22ФР-0344',
		costPrice: 2024182.76,
		idSupply: 281,
		Column7: 'Приобретение товаров и услуг Ф000-000171 от 15.02.2023 12:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
	{
		assignedPartyNum: '22ФР-0345',
		costPrice: 427180,
		idSupply: 282,
		Column7: 'Приобретение товаров и услуг Ф000-000181 от 16.02.2023 13:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0346',
		costPrice: 2048827.18,
		idSupply: 283,
		Column7: 'Приобретение товаров и услуг Ф000-000187 от 17.02.2023 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
	{
		assignedPartyNum: '22ФР-0347',
		costPrice: 478560,
		idSupply: 284,
		Column7: 'Приобретение товаров и услуг Ф000-000188 от 18.02.2023 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0348',
		costPrice: 471520,
		idSupply: 285,
		Column7: 'Приобретение товаров и услуг Ф000-000200 от 20.02.2023 12:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0349',
		costPrice: 543776,
		idSupply: 286,
		Column7: 'Приобретение товаров и услуг Ф000-000285 от 12.03.2023 13:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '23ФР-0001',
		costPrice: 1195028.64,
		idSupply: 287,
		Column7: 'Приобретение товаров и услуг Ф000-000305 от 19.03.2023 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
	{
		assignedPartyNum: '23ФР-0002',
		costPrice: 1195028.64,
		idSupply: 288,
		Column7: 'Приобретение товаров и услуг Ф000-000306 от 19.03.2023 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
	{
		assignedPartyNum: '22ФР-0350',
		costPrice: 2068240,
		idSupply: 289,
		Column7: 'Приобретение товаров и услуг Ф000-000309 от 18.03.2023 0:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '22ФР-0351',
		costPrice: 1132200,
		idSupply: 290,
		Column7: 'Приобретение товаров и услуг Ф000-000310 от 18.03.2023 11:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'руб.',
	},
	{
		assignedPartyNum: '23ФР-0003',
		costPrice: 1195028.64,
		idSupply: 291,
		Column7: 'Приобретение товаров и услуг Ф000-000313 от 19.03.2023 11:20:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
	{
		assignedPartyNum: '23ФР-0004',
		costPrice: 1195028.64,
		idSupply: 292,
		Column7: 'Приобретение товаров и услуг Ф000-000314 от 20.03.2023 10:00:00',
		Column9: 'Приобретение товаров и услуг',
		Column10: 'USD',
	},
]
