import React, { useContext, useEffect, useRef, useCallback } from 'react'
import { ModContext } from '../../../../../contexts'
import lodash from 'lodash'
import {
	prepareObjFromServer,
	getNewObj,
	modes,
	useItemFetchers,
	axios,
} from '../../../../../utils'
import { getCommonProviderFunctions } from '../../../../../utils/helpers/generators'
import showConfirmModal from './../../../../../components/ConfirmModal/ConfirmModal'
import { basicValidator } from '@berry/common-functions/validators'
import { useHistory } from 'react-router-dom'

export const dataUrl = '/voc/editable/working-time'
const tabs = { breaks: 'breaks' }
export const reducer = (state) => {
	return {
		...state,
	}
}

const WorkingTimeItemMainContext = React.createContext()
WorkingTimeItemMainContext.displayName = 'WorkingTimeItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)

	const [state, dispatch] = React.useReducer(reducer, {
		data: { breaks: [] },
		oldData: {},
		additional: {},
		formErrors: [],
	})
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))

	const isItemEdited = () => {
		if (modCtx.mod === modes.new && Object.keys(stateRef.current.data).length) {
			return true
		}
		const editedFields = getEditedData()
		return isEdited(editedFields)
	}

	const requiredFields = {
		breaks: ['startTime', 'endTime'],
	}

	/**
	 * Валидация всех полей в соответствии с конфлюенсом
	 * @returns {string} - если вернулась строка и не пустая значит есть ошибка валидации
	 */
	const validate = () => {
		let newFormErrors = {}
		if (
			stateRef.current.data.breaks.some((b) =>
				requiredFields.breaks.some((f) => !basicValidator(b[f]))
			)
		) {
			stateRef.current.data.breaks.forEach((p) => {
				requiredFields.breaks.forEach((f) => {
					if (!basicValidator(p[f])) {
						newFormErrors = { ...newFormErrors, [f + '.' + p._uuid_]: true }
					}
				})
			})
		}
		executeDispatch({ ...stateRef.current, formErrors: newFormErrors })
		return ''
	}

	const validators = {}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({ ...stateRef.current, data: recordFromDataSrvCtx })
	}, [])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * подготавливает данные которые нужно выбират
	 */
	const selectors = {
		vocPayCondit: stateRef.current.additional.allPaymentConditions,
	}

	const getActualState = () => {
		const obj = lodash.cloneDeep(stateRef.current.data)
		return obj
	}

	const depsFunctions = {
		breaks: (inId) => [],
	}

	const {
		commonFieldUpdate,
		serverDelete,
		getEditedData,
		isEdited,
		stateFunctions,
		commonDeepFieldUpdate,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{ modCtx, dataUrl, params, pageUrl: '/vocabularies/working-time', history },
		selectors,
		{
			label: 'common',
			startTime: 'common',
			endTime: 'common',
		},
		{
			breaks: {
				displayCode: 'common',
			},
		}
	)

	stateFunctions.breaks.setEndTime = (inUuid, val) => {
		commonDeepFieldUpdate(['breaks'], [inUuid], 'endTime', val)
		validate()
	}
	stateFunctions.breaks.setStartTime = (inUuid, val) => {
		commonDeepFieldUpdate(['breaks'], [inUuid], 'startTime', val)
		validate()
	}

	stateFunctions.breaks.create = () => {
		const newBreaks = [...(stateRef.current.data?.breaks || []), getNewObj()]
		commonFieldUpdate('breaks', newBreaks)
		lodash.sortBy(stateRef.current.data.breaks || [], ['displayCode']).forEach((el, index) => {
			stateFunctions.breaks.setDisplayCode(el._uuid_, index + 1)
		})
		return newBreaks.slice(-1)[0]._uuid_
	}

	stateFunctions.breaks.delete = (inUuid) => {
		const newBreaks = (stateRef.current.data.breaks || [])
			.filter((b) => b._uuid_ !== inUuid)
			.sort((a, b) => a.displayCode.toString().localeCompare(b.displayCode.toString()))
			.map((el, index) => {
				return {
					...el,
					displayCode: index + 1,
				}
			})
		commonFieldUpdate('breaks', newBreaks)
	}

	const serverEdit = async () => {
		if (!stateRef.current.data.breaks?.length) {
			showConfirmModal({
				title: 'Не добавлена ни одна строка в табличной вкладке Перерывы',
				width: '33%',
				showCancel: false,
				okText: '',
			})
			return
		}
		if (Object.values(stateRef.current.formErrors).length) return
		if (!isEdited()) return modCtx.set(modes.view)
		let body = {}
		let options = { url: dataUrl }
		if (params.id === 'new') {
			body = stateRef.current.data
			options.method = 'POST'
		} else {
			options.method = 'PUT'
			body = getEditedData()
		}
		const res = await axios[options.method.toLowerCase()](options.url, body) // или добавить
		modCtx.set(modes.view)
		return { method: options.method, id: res.data?.data?.id }
	}

	const value = {
		state: getActualState(),
		additional: stateRef.current.additional,
		delEditDeps: stateRef.current.delEditDeps,
		depsFunctions,
		stateFunctions,
		selectors,
		validators,
		serverEdit,
		getEditedData,
		isEdited: isItemEdited,
		validate,
		reset,
		serverDelete,
		formErrors: stateRef.current.formErrors,
	}

	return (
		<WorkingTimeItemMainContext.Provider value={value}>
			{children}
		</WorkingTimeItemMainContext.Provider>
	)
}

export { Provider, WorkingTimeItemMainContext }
