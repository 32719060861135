import React, { useEffect, useRef } from 'react'
import { axios } from '../../utils'

export const reducer = (state) => {
	return {
		...state,
	}
}

const ListLoaderContext = React.createContext()
ListLoaderContext.displayName = 'ListLoaderContext'

const getInitialState = (fromServerFilters) => {
	return {
		page: 1,
		pageSize: 16,
		total: 0,
		mainFilter: {},
		filters: {},
		sorter: {},
		fromServer: [],
		fromServerFilters: fromServerFilters,
		stockNotify: {},
		isInitialized: false,
		isLoading: false,
	}
}
const Provider = (props) => {
	const {
		children,
		filters: fromServerFilters,
		defaultSorter,
		permanentFilters,
		url,
		params,
	} = props
	const [state, dispatch] = React.useReducer(reducer, getInitialState(fromServerFilters))

	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = newState
		dispatch({ ...state })
	}

	useEffect(() => {
		const reload = async () => {
			executeDispatch({ ...getInitialState(fromServerFilters), isLoading: true })
			const fromServerResp = await axios(url, {
				params: {
					...params,
					pageLimit: stateRef.current.pageSize,
					defaultSorter,
					permanentFilters,
					sorter: stateRef.current.sorter,
					mainFilter: stateRef.current.mainFilter,
					filters: stateRef.current.filters,
				},
			})
			executeDispatch({
				...stateRef.current,
				fromServer: fromServerResp.data.data,
				fromServerFilters: fromServerResp.data.filters,
				stockNotify: fromServerResp.data.stockNotify,
				isInitialized: true,
				isLoading: false,
				total: fromServerResp.data.count,
			})
		}
		reload()
	}, [url])

	useEffect(() => {
		if (!stateRef.current.isInitialized) return
		executeDispatch({
			...stateRef.current,
			isLoading: true,
		})
		axios(url, {
			params: {
				...params,
				offset: stateRef.current.pageSize * stateRef.current.page - stateRef.current.pageSize,
				limit: stateRef.current.pageSize,
				sorter: stateRef.current.sorter,
				mainFilter: stateRef.current.mainFilter,
				filters: stateRef.current.filters,
				permanentFilters,
				defaultSorter,
			},
		})
			.then((response) =>
				executeDispatch({
					...stateRef.current,
					fromServer: response.data.data,
					fromServerFilters: response.data.filters,
					stockNotify: response.data.stockNotify,
					isLoading: false,
					total: response.data.count,
				})
			)
			.catch((err) =>
				executeDispatch({
					...stateRef.current,
					fromServerFilters: [],
					fromServer: [],
					isLoading: false,
					total: 0,
				})
			)
	}, [
		stateRef.current.page,
		stateRef.current.sorter,
		stateRef.current.filters,
		stateRef.current.mainFilter,
	])

	const setPage = (page) => {
		executeDispatch({ ...stateRef.current, page })
	}
	const setTotal = (total) => {
		executeDispatch({ ...stateRef.current, total })
	}
	const setMainFilter = (filter) => executeDispatch({ ...stateRef.current, mainFilter: filter })
	const setFilters = (filters) => {
		const newFilters = Object.keys(filters).reduce((all, cur) => {
			if (!Object.keys(stateRef.current.mainFilter).length) {
				if (filters[cur]?.length) {
					setMainFilter({ [cur]: filters[cur] })
					return all
				}
			} else {
				if (stateRef.current.mainFilter[cur]) {
					if (filters[cur]?.length) {
						setMainFilter({ [cur]: filters[cur] })
						return all
					}
					setMainFilter({})
					return all
				}
			}
			if (
				Object.keys(stateRef.current.mainFilter).length &&
				Object.keys(stateRef.current.mainFilter).some((key) => key === cur)
			) {
				if (filters[cur] === null) {
					setMainFilter({})
				}
				setMainFilter({ [cur]: filters[cur] })
				return all
			}
			if (filters[cur]?.length) {
				return { ...all, [cur]: filters[cur] }
			}
			return all
		}, {})
		executeDispatch({
			...stateRef.current,
			filters: newFilters,
		})
	}
	const setSorter = (sorter) => {
		executeDispatch({ ...stateRef.current, sorter })
	}

	const value = {
		state: stateRef.current,
		setPage,
		setTotal,
		setFilters,
		setSorter,
	}

	return <ListLoaderContext.Provider value={value}>{children}</ListLoaderContext.Provider>
}

export { Provider, ListLoaderContext }
