import { Table } from 'antd'
import React, { useContext, useState } from 'react'
import { TabItem } from '../../../../components'
import { ModContext } from '../../../../contexts'
import { openNewTab } from '../../../../utils'
import { getDisplayColumns, prepareData } from './contract-tab'
import { CustomerItemMainContext } from '../provider/main'
import { AuthContext } from '../../../../contexts'
import rowClassName from '../../../../utils/helpers/rowClassName'
import clickableRow from '../../../../utils/helpers/clickableRow'

const ContractTab = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(CustomerItemMainContext)
	const data = prepareData(mainCtx.state.contracts)
	const authCtx = useContext(AuthContext)
	const [rowId, setRowId] = useState('')

	return (
		<TabItem
			addButton={{
				disabled: true,
				show: false,
				onClick: () => {},
			}}
			modal={() => {
				return null
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={rowClassName(rowId)}
						columns={getDisplayColumns(mainCtx, modCtx)}
						dataSource={data}
						scroll={{ x: 800 }}
						pagination={false}
						loading={mainCtx.tabsLoading.contracts}
						onRow={clickableRow(setRowId, authCtx, '/rp/contracts-customers')}
					/>
				)
			}}
		/>
	)
}

export default ContractTab
