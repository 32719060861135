import React, { useContext } from 'react'
import {
	AuthContext,
	ListProvider,
	ModContext,
	ModProvider,
	UserDataContext,
} from '../../../../contexts'
import { Main } from '../../../../layouts'
import { Provider, StockSemifItemMainContext } from './provider/main'
import { Card, Row, Col, Form, Typography } from 'antd'
import {
	ActionButtons,
	CustomForm,
	HeaderMenu,
	showConfirmModal,
	TabList,
} from '../../../../components'
import { actBtnOnClose, actBtnOnDelete, actBtnOnSaveV2, modes, openNewTab } from '../../../../utils'
import { getFormItemsMain } from './semif-item'
import { useHistory } from 'react-router'
import { getBreadcrumbsText } from '../../../../utils/helpers/generators'
import StorageTab from './StorageTab/StorageTab'
import EventHistoryTab from './EventHistoryTab'
import CostTab from './CostTab/CostTab'
import ParentTab from './ParentTab/ParentTab'
import { checkRole } from '@berry/common-functions/role'

const pageUrl = '/stock/semifs'

const { Title } = Typography

const costRights = {
	'АРМ офиса': {
		'Карточка Запасы': {
			Себестоимость: {
				view: true,
			},
		},
	},
}

const StockSemifItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<ListProvider path={'/stock/semifs/parents'}>
				<Provider params={{ id: props.match.params.id }}>
					<Main title="Запасы">
						<InfoComponent />
					</Main>
				</Provider>
			</ListProvider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(StockSemifItemMainContext)
	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights, isDevAdmin },
	} = useContext(UserDataContext)
	let history = useHistory()

	const [form] = Form.useForm()

	if (mainCtx.state.__notFound) {
		history.replace('/stock/semif')
		return null
	}
	return (
		<>
			<HeaderMenu {...getBreadcrumbsText(pageUrl, mainCtx.state)}>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						rights={{
							'АРМ офиса': {
								'Карточка Запасы': {
									edit: true,
								},
							},
						}}
						formName="stock-semif"
						onClose={() => {
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						canEdit={false}
						canDelete={isDevAdmin}
						onDelete={async () => {
							await actBtnOnDelete({
								history,
								pageUrl,
								showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								currentRights,
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
							})
						}}
						onSave={async () => {
							try {
								await actBtnOnSaveV2({
									pageUrl,
									history,
									form,
									showConfirmModal,
									isShowErrorModal: true,
									canSaveWithErrors: true,
									serverEdit: mainCtx.serverEdit,
									validate: mainCtx.validate,
								})
							} catch (err) {
								console.log(err)
							}
						}}
						onCancel={() => {
							form.resetFields()
							mainCtx.reset()
						}}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<Title level={5}>Основные данные</Title>
						<CustomForm form={form} name="main" items={getFormItemsMain(mainCtx)} />
					</Col>
				</Row>
			</Card>
			{
				<TabList
					tabs={[
						{ key: 0, title: 'Хранение', component: <StorageTab /> },
						{ key: 1, title: 'Исходные партии', component: <ParentTab /> },
						...(checkRole(costRights, currentRights)
							? [{ key: 2, title: 'Себестоимость', component: <CostTab /> }]
							: []),
						{ key: 3, title: 'История событий', component: <EventHistoryTab /> },
					]}
				/>
			}
		</>
	)
}
export default StockSemifItem
