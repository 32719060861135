import { Table } from 'antd'
import React, { useContext } from 'react'
import { RawMaterialSupplyItemMainContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { AuthContext, ModContext, UserDataContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns } from './product-tab'
import SelectSupplyReqProdsModal from './SelectSupplyReqProdsModal'

const sortProducts = (a, b) => {
	if ((a.contrQuota || a.order).contract.id !== (b.contrQuota || b.order).contract.id) {
		return 0
	}
	if ((a.contrQuota || a.order).rmProvProd.id !== (b.contrQuota || b.order).rmProvProd.id) {
		return (a.contrQuota || a.order).rmProvProd.id - (b.contrQuota || b.order).rmProvProd.id
	}
	if (!a.invoice && !b.invoice) {
		return 0
	}
	if (!a.invoice) {
		return 1
	}
	if (!b.invoice) {
		return -1
	}
	return a.invoice.id - b.invoice.id
}

const ProductTab = () => {
	const mainCtx = useContext(RawMaterialSupplyItemMainContext)
	const modCtx = useContext(ModContext)
	const authCtx = useContext(AuthContext)
	const userDataCtx = useContext(UserDataContext)
	const modalFunctions = mainCtx.modalFunctions
	const preparedData = mainCtx.state.products.sort(sortProducts).map((el, index) => ({
		...el,
		contract: (el.contrQuota || el.order).contract,
		idRmProvProd: (el.contrQuota || el.order).rmProvProd?.id,
	}))

	const dataSource = preparedData
		.map((el) => ({
			...el,
			products: mainCtx.state.products
				.filter((item) =>
					item.contrQuota
						? el.contrQuota?.contract.id === item.contrQuota.contract?.id
						: el.order?.contract.id === item.order.contract?.id
				)
				.map((prod) => ({
					...prod,
					contract: (prod.contrQuota || prod.order).contract,
					idRmProvProd: (prod.contrQuota || prod.order).rmProvProd?.id,
				}))
				.sort(sortProducts),
		}))
		.map((el, index, self) => {
			const invProds = el.products.filter(
				(prod) =>
					el.invoice && prod.idRmProvProd === el.idRmProvProd && prod.invoice?.id === el.invoice.id
			)
			const prods = el.products.filter(
				(prod) => !prod.invoice && prod.idRmProvProd === el.idRmProvProd
			)
			return {
				...el,
				key: el.id || el._uuid_,
				indxMain: self.findIndex((s, i) => s.idRmProvProd === el.idRmProvProd) - index,
				indxProd: prods.find((p) => p._uuid_ === el._uuid_)
					? prods.findIndex((p) => p._uuid_ === el._uuid_)
					: invProds.findIndex((p) => p._uuid_ === el._uuid_),
				totalProductsLength: el.products.filter((p) => p.idRmProvProd === el.idRmProvProd).length,
				invoicesLength: invProds.length,
				productsLength: prods.length,
			}
		})
	return (
		<TabItem
			addButton={{
				show: true,
				disabled: !mainCtx.state.supplyReq,
				onClick: () => modalFunctions.openAddContrQuota(),
			}}
			modal={() => {
				if (mainCtx.addContrQuota.__isOpen) return <SelectSupplyReqProdsModal />
			}}
			table={() => {
				return (
					<Table
						bordered
						size="small"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(mainCtx, modCtx, authCtx, userDataCtx)}
						dataSource={dataSource}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)
			}}
		/>
	)
}

export default ProductTab
