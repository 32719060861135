import React, { useContext, useEffect, useState } from 'react'
import { Form as FormAntd, Input, Select } from 'antd'
import { ModContext } from '../../../contexts'
import { modes } from '../../../utils'
import { addData, editData } from '../../../services/api/requests'
import { vocUrl } from './Room'

const formItemLayout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

const Form = (props) => {
	const [object, setObject] = useState({})

	const {
		data,
		recordId,
		form,
		options: { setState },
		outdoorWhVoc,
		isDevAdmin,
	} = props
	const modCtx = useContext(ModContext)
	useEffect(() => {
		form.resetFields()
	}, [form])
	const initialValues = data.find((el) => String(el.id) === String(recordId))

	const getAction = () => {
		if (modCtx.mod === modes.new) {
			return async (values) => {
				try {
					const response = await addData(vocUrl, {
						data: {
							...values,
							type: values.type.join(','),
							storage: values.storage.join(','),
							roomType: values.roomType,
							uuid1C: values.uuid1C,
							idVocOutdoorWh: values.outdoorWh,
						},
					})
					if (response?.data) {
						response.data.result.data.outdoorWh = outdoorWhVoc.find(
							(wh) => wh.id === response.data.result.data.idVocOutdoorWh
						)?.label
						setState((prev) => [...prev, response.data.result.data])
					}
				} catch (err) {
					console.log(err.message)
				}
			}
		}
		if (modCtx.mod === modes.edit) {
			return async (values) => {
				try {
					const response = await editData(vocUrl, {
						data: {
							...values,
							type: values.type.join(','),
							storage: values.storage.join(','),
							roomType: values.roomType,
							uuid1C: values.uuid1C,
							idVocOutdoorWh: values.outdoorWh.id ? values.outdoorWh.id : values.outdoorWh,
							id: recordId,
						},
					})
					if (response?.data) {
						response.data.result.data.outdoorWh = outdoorWhVoc.find(
							(wh) => wh.id === response.data.result.data.idVocOutdoorWh
						)?.label
						setState((prev) => {
							const foundIndex = prev.findIndex((el) => el.id === response.data.result.data.id)
							return [
								...prev.slice(0, foundIndex),
								response.data.result.data,
								...prev.slice(foundIndex + 1),
							]
						})
					}
				} catch (err) {
					console.log(err.message)
				}
			}
		}
		return () => {}
	}

	return (
		<FormAntd
			form={form}
			labelAlign="left"
			initialValues={
				initialValues
					? {
							...initialValues,
							type: initialValues.type.split(','),
							storage: initialValues.storage.split(','),
							roomType: initialValues.roomType,
							uuid1C: initialValues.uuid1C,
							outdoorWh: outdoorWhVoc.find((wh) => wh.id === initialValues.idVocOutdoorWh),
					  }
					: {}
			}
			onFinish={getAction()}
		>
			<FormAntd.Item
				{...formItemLayout}
				label="Номер"
				name="displayCode"
				rules={[
					{
						required: true,
						message: false,
					},
					{
						validator: (_, value) => {
							if (value) {
								const hasDuplicate = !!data.find(
									(el) => String(el.displayCode) === String(value) && el.id !== recordId
								)
								if (hasDuplicate) {
									return Promise.reject('Помещение с данным номером уже создано')
								}
							}
							return Promise.resolve()
						},
					},
				]}
			>
				<Input style={{ width: '100%', minWidth: '120px' }} maxLength={8} />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Объект"
				name="outdoorWh"
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Select
					style={{ width: '100%', minWidth: '120px' }}
					onChange={(e) => {
						const outdoorWhLabel = outdoorWhVoc.find((wh) => wh.id === e).label
						setObject(outdoorWhLabel)
						if (outdoorWhLabel !== 'Внутренний') {
							form.setFieldsValue({
								type: ['Складское'],
							})
						} else {
							form.setFieldsValue({
								type: [],
							})
						}
					}}
				>
					{outdoorWhVoc.map((it) => {
						return (
							<Select.Option key={it.id} value={it.id}>
								{it.label}
							</Select.Option>
						)
					}) || []}
				</Select>
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Тип помещения"
				name="type"
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Select
					mode="multiple"
					style={{ width: '100%', minWidth: '120px' }}
					disabled={
						(object?.length || initialValues?.outdoorWh?.length) && object?.length
							? object !== 'Внутренний'
							: initialValues?.outdoorWh !== 'Внутренний' &&
							  (object?.length || initialValues?.outdoorWh?.length)
					}
				>
					{[
						{ label: 'Складское', id: 1 },
						{ label: 'Производственное', id: 2 },
					].map((it) => {
						return (
							<Select.Option key={it.id} value={it.label}>
								{it.label}
							</Select.Option>
						)
					}) || []}
				</Select>
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Хранение"
				name="storage"
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Select mode="multiple" style={{ width: '100%', minWidth: '120px' }}>
					{[
						{ label: 'Сырье', id: 1 },
						{ label: 'Полуфабрикат', id: 2 },
						{ label: 'Готовая продукция', id: 3 },
					].map((it) => {
						return (
							<Select.Option key={it.id} value={it.label}>
								{it.label}
							</Select.Option>
						)
					}) || []}
				</Select>
			</FormAntd.Item>
			{isDevAdmin ? (
				<FormAntd.Item
					{...formItemLayout}
					label="Идентификатор 1С"
					name="uuid1C"
					rules={[
						{
							required:
								(object?.length || initialValues?.outdoorWh?.length) && object?.length
									? object !== 'Внутренний'
									: initialValues?.outdoorWh !== 'Внутренний' &&
									  (object?.length || initialValues?.outdoorWh?.length),
							message: false,
						},
					]}
				>
					<Input style={{ width: '100%', minWidth: '120px' }} maxLength={50} />
				</FormAntd.Item>
			) : null}
		</FormAntd>
	)
}

export default Form
