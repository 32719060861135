import { Table } from 'antd'
import React, { useContext } from 'react'
import { ConsolidatedReportItemMainContext } from '../provider/main'
import { getDisplayColumns } from './produced-tab'
import { TabItem } from '../../../../components'
import { ModContext } from '../../../../contexts'

const ProducedTab = () => {
	const mainCtx = useContext(ConsolidatedReportItemMainContext)
	const modCtx = useContext(ModContext)
	return (
		<TabItem
			addButton={{ show: false }}
			table={() => {
				return (
					<Table
						rowKey="id"
						size="small"
						columns={getDisplayColumns(mainCtx, modCtx)}
						dataSource={mainCtx.state.produceds?.filter(
							(p) => !['Сработано', 'Списано'].includes(p.status)
						)}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)
			}}
		/>
	)
}

export default ProducedTab
