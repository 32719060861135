import React, { useContext, useState, useEffect } from 'react'
import { Form, Input, Button, Row, Col, Layout } from 'antd'
import { Helmet } from 'react-helmet'
import { AuthContext } from '../../contexts/auth/auth'
import { useHistory } from 'react-router-dom'
import AuthError from '../../components/AuthError/AuthError'
import { validatePwds } from './../ChangePassword/ChangePassword'
import { basicValidator } from '@berry/common-functions/validators'
import Logo from './../../components/Logo/Logo'

const { Content } = Layout

const btnStyle = {
	fontWeight: 600,
	width: '500px',
	height: '50px',
	alignSelf: 'center',
	borderRadius: '5px',
}

const inputStyle = { width: '500px', height: '50px', borderRadius: '5px' }
const inputStyleError = {
	width: '500px',
	height: '50px',
	borderRadius: '5px',
	border: '1px solid red',
}

const ResetPassword = (props) => {
	const { resetPwd } = useContext(AuthContext)
	const token = props.match.params?.token
	const login = props.match.params?.login
	const history = useHistory()

	const [newPwd1, setNewPwd1] = useState('')
	const [newPwd2, setNewPwd2] = useState('')
	const [errorPwd1, setErrorPwd1] = useState('')
	const [errorPwd2, setErrorPwd2] = useState('')
	const [generalError, setGeneralError] = useState('')
	const [, setLoginError] = useState('')
	const [, setOldPwdError] = useState('')

	useEffect(() => {
		validatePwds(
			newPwd1,
			newPwd2,
			setErrorPwd1,
			setErrorPwd2,
			undefined,
			setGeneralError,
			setLoginError,
			setOldPwdError
		)
	}, [newPwd1, newPwd2])

	return (
		<Layout>
			<Helmet>
				<title>Изменение пароля</title>
			</Helmet>
			<Logo />
			<Content
				style={{
					background: 'url(/login.jpg) no-repeat center center fixed',
					backgroundSize: 'cover',
					display: 'flex',
					justifyContent: 'space-around',
					height: '100vh',
				}}
			>
				<Row justify="center" align="middle" style={{ position: 'relative', bottom: '80px' }}>
					<Col style={{ width: '500px' }}>
						<Col>
							<Row
								justify="center"
								style={{
									fontSize: 25,
									fontWeight: '700',
									paddingBottom: '10px',
									color: 'rgb(3 52 97)',
								}}
							>
								ИЗМЕНЕНИЕ ПАРОЛЯ
							</Row>
							<Row
								justify="center"
								style={{
									fontSize: 23,
									fontWeight: 700,
									textAlign: 'center',
									paddingBottom: '30px',
									color: 'rgb(3 52 97)',
									lineHeight: '1',
								}}
							>
								{`Логин который используется для входа ${login}`}
							</Row>
						</Col>
						<Form
							colon={false}
							onFinish={async (data) => {
								try {
									if (newPwd1 === '' && newPwd2 === '') {
										setErrorPwd1('Введите пароль')
										setErrorPwd2('Введите пароль')
									} else if (newPwd1 === '') {
										setErrorPwd1('Введите новый пароль ')
									} else if (newPwd2 === '' && errorPwd1 === '') {
										setErrorPwd2('Введите новый пароль еще раз')
									} else {
										validatePwds(
											newPwd1,
											newPwd2,
											setErrorPwd1,
											setErrorPwd2,
											undefined,
											setGeneralError,
											setLoginError,
											setOldPwdError
										)
									}
									data.token = token
									data.login = login
									await resetPwd(data)
								} catch (err) {
									setGeneralError('Выполните сброс пароля заново')
									console.log(err.message, 5)
								}
							}}
						>
							<Col
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-end',
								}}
							>
								<Form.Item name="newPwd1">
									<Input
										placeholder="Новый пароль"
										onChange={(e) => setNewPwd1(e.target.value)}
										type="password"
										style={errorPwd1 ? inputStyleError : inputStyle}
									/>
								</Form.Item>
								<Form.Item name="newPwd2">
									<Input
										placeholder="Новый пароль еще раз"
										onChange={(e) => setNewPwd2(e.target.value)}
										type="password"
										style={errorPwd2 ? inputStyleError : inputStyle}
									/>
								</Form.Item>
								{(errorPwd2 || errorPwd1) && <AuthError error={errorPwd2 || errorPwd1} />}
								{basicValidator(generalError) && <AuthError error={generalError} />}
								<Button
									htmlType="submit"
									type="btn"
									style={{
										...btnStyle,
										borderRadius: '5px',
										background: 'rgb(3 52 97)',
										color: 'white',
										marginBottom: '20px',
									}}
								>
									Сохранить
								</Button>
								<Button
									onClick={() => {
										history.push('/login')
									}}
									type="btn"
									style={{
										...btnStyle,
										border: '2px solid rgb(3, 52, 97)',
									}}
								>
									Отменить
								</Button>
							</Col>
						</Form>
					</Col>
				</Row>
			</Content>
		</Layout>
	)
}

export default ResetPassword
