import { Typography } from 'antd'

export const prepareOptions = (inArr) => {
	if (!inArr) return []
	if (inArr.every((e) => typeof e === 'string'))
		return inArr.map((e, i) => ({
			id: i,
			value: e,
			data: e,
		}))
	return inArr.map((e) => {
		if ((!e.id || e.displayVal === undefined) && !e.isEmpty) {
			throw Error('wrong parameters of function')
		}
		return {
			label: (
				<Typography.Text type={e.id ? '' : 'secondary'} italic={!e.id}>
					{e.displayVal}
				</Typography.Text>
			),
			strLabel: e.displayVal,
			value: e.id,
			data: e,
		}
	})
}
