import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Card } from 'antd'
import { Provider, ImplementationListMainContext } from './provider/main'
import { Main } from '../../../layouts'
import { goToItem } from '../../../utils'
import { HeaderMenu, StatefulTable } from '../../../components'
import { getColumns } from './implementation-list'

const pageUrl = '/stock-operations/implementations'

const ImplementationList = () => (
	<Provider>
		<Main title="Реализации">
			<ImplementationTable />
		</Main>
	</Provider>
)

const ImplementationTable = () => {
	const mainCtx = useContext(ImplementationListMainContext)
	const history = useHistory()
	return (
		<>
			<HeaderMenu
				rights={{
					'АРМ офиса': {
						Реализации: {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle="Реализации"
				search={mainCtx.setSearch}
				canCreate
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					searchFields={mainCtx.searchFields}
					columnProps={{ mainCtx, fromServerFilters: mainCtx.state.fromServerFilters }}
					url={pageUrl}
				/>
			</Card>
		</>
	)
}

export default ImplementationList
