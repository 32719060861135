import { getProdNameByProviderQuota } from '@berry/common-functions/cross-project-functions'
import { getColumnSearchProps, sorter } from '../../../utils'
import { dateFormat } from '../../../utils/constants/common'
import moment from 'moment'
import { colorizeIntegrationListStatus } from '../../../utils/helpers/for-integration'

/**
 * получить продукты заявки
 * @param {Object} products
 * @returns {String}
 */
export const getProducts = (inState) => {
	return inState.products
		?.map((e) => {
			if (e?.order) {
				return getProdNameByProviderQuota(e.order?.rmProvProd)
			}
			return getProdNameByProviderQuota(e.contrQuota?.rmProvProd)
		})
		.filter((e) => e)
		.join(', ')
}

export const getProviders = (inState) => {
	return [...new Set(inState.products?.map((p) => p.contract.provider.label))].join(', ')
}

/**
 * @param {Object} fromServerFilters - массив с записями таблицы
 */
export const getColumns = (props) => {
	const { fromServerFilters } = props
	return [
		{
			title: 'Номер заявки',
			dataIndex: 'displayCode',
			key: 'displayCode',
			width: '8.28%',
			sorter: sorter('string', 'displayCode'),
		},
		{
			title: 'Дата заявки',
			dataIndex: 'date',
			key: 'date',
			width: '9.3%',
			render: (date) => (date ? moment(date).format(dateFormat) : null),
			...getColumnSearchProps(fromServerFilters.date, undefined, null, true),
			sorter: sorter('date', 'date', 'YYYY-MM-DD'),
		},
		{
			title: 'Номер договора',
			key: 'contractNums',
			width: '9.3%',
			render: (record) => [...new Set(record.products.map((p) => p.contract.num))].join(', '),
			...getColumnSearchProps(fromServerFilters.contractNums || []),
		},
		{
			title: 'Дата поставки план.',
			dataIndex: 'supplyDatePlan',
			key: 'supplyDatePlan',
			width: '12.49%',
			render: (supplyDatePlan) =>
				supplyDatePlan ? moment(supplyDatePlan).format(dateFormat) : null,
			...getColumnSearchProps(fromServerFilters.supplyDatePlan, undefined, null, true),
			sorter: sorter('date', 'supplyDatePlan', 'YYYY-MM-DD'),
		},
		{
			title: '№ машины/прицепа',
			dataIndex: 'vehicleNum',
			key: 'vehicleNum',
			width: '12.17%',
			...getColumnSearchProps(fromServerFilters.vehicleNum),
		},
		{
			title: 'Продукт',
			dataIndex: 'preparedProducts',
			key: 'forFilterProducts',
			width: '20.07%',
			render: (_, record) => getProducts(record),
			...getColumnSearchProps(fromServerFilters.forFilterProducts || []),
			sorter: sorter('string', 'preparedProducts'),
		},
		{
			title: 'Поставщик',
			dataIndex: 'preparedProviders',
			key: 'contractProviders',
			width: '18.86%',
			...getColumnSearchProps(fromServerFilters.contractProviders || []),
			render: (_, record) => getProviders(record),
			sorter: sorter('string', 'preparedProviders'),
		},
		{
			title: 'Склад приемки',
			dataIndex: 'outdoorWhLabel',
			key: 'outdoorWhLabel',
			width: '9.53%',
			...getColumnSearchProps(fromServerFilters.outdoorWhLabel),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '9.53%',
			...getColumnSearchProps(fromServerFilters.status),
			render: (status) => colorizeIntegrationListStatus(status, true),
		},
	]
}
