import moment from 'moment'
import { dateFormat } from '../../../utils/constants/common'

const layout1Col = {
	labelCol: {
		sm: { span: 12, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 8, offset: 0 },
		xl: { span: 12, offset: 0 },
	},
	wrapperCol: { span: 12 },
}
const layout2Col = {
	labelCol: {
		sm: { span: 12, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 8, offset: 0 },
	},
	wrapperCol: { span: 16 },
}
const layout3Col = {
	labelCol: {
		sm: { span: 12, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 11, offset: 4 },
	},
	wrapperCol: { span: 22 },
}

export const getFormItems = (mainCtx) => {
	return [
		[
			{
				layout: layout1Col,
				label: 'Статус',
				name: 'status',
				field: mainCtx.state.status,
			},
			{
				layout: layout1Col,
				label: 'ID ДЗ',
				name: 'id',
				field: mainCtx.state.id,
			},
			{
				layout: layout1Col,
				label: 'Дата отгрузки план.',
				name: 'displayCode',
				field: mainCtx.state.unloadDatePlan
					? moment(mainCtx.state.unloadDatePlan).format(dateFormat)
					: null,
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Целевой продукт',
				name: 'targetProduct',
				field: mainCtx.state.targetProduct,
			},
			{
				layout: layout2Col,
				label: 'Артикул',
				name: 'articul',
				field: mainCtx.state.articul,
			},
			{
				layout: layout2Col,
				label: 'Артикул 1С',
				name: 'vendorCode',
				field: mainCtx.state.articul1C,
			},
		],
		[
			{
				layout: layout3Col,
				label: 'Поставка сырья план, кг',
				name: 'supplyPlanWeight',
				field: mainCtx.state.supplyPlanWeight,
			},
			{
				layout: layout3Col,
				label: 'Поставка сырья факт, кг',
				name: 'weightFact',
				field: mainCtx.state.weightFact,
			},
			{
				layout: layout3Col,
				label: 'Отгрузка ГП факт, кг',
				name: 'unldWeightFact',
				field: mainCtx.state.unldWeightFact,
			},
		],
	]
}
