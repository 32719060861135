import { setNumField } from '@berry/front-common/for-fields'
import { Col, Select, Checkbox, Input } from 'antd'
import { InputError } from '../../../../components'
import { forSearchInFieldProps } from '../../../../utils'
import { getRoomNumsArray } from '../../RawMat/RawMatList/modals-for-list'

const layout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

export const getFormItems = (mainCtx) => {
	let key = null
	const result = [[]]
	if (mainCtx.saleModal.__isOpen) {
		key = 'saleModal'
		result[0].push(
			...[
				{
					layout: layout,
					label: 'Заказчик',
					name: 'customer',
					rules: [{ required: !mainCtx.saleModal.isForSaleDepartment }],
					field: (
						<Select
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								mainCtx.modalFunctions.setSaleModalCustomer(el)
							}}
							notFoundContent={<Col>Не найдено</Col>}
							disabled={mainCtx.saleModal.isForSaleDepartment}
							{...forSearchInFieldProps}
						>
							{mainCtx.selectors.saleModal.customer.map((it) => {
								return (
									<Select.Option key={it.id} value={it.id}>
										{it.label}
									</Select.Option>
								)
							}) || []}
						</Select>
					),
				},
				{
					layout: layout,
					label: `Списание на отдел продаж`,
					name: 'isForSaleDepartment',
					field: (
						<Checkbox
							checked={mainCtx.saleModal.isForSaleDepartment}
							onChange={(e) => {
								mainCtx.modalFunctions.setSaleModalIsForSaleDepartment(e.target.checked)
							}}
						/>
					),
				},

				{
					layout: layout,
					label: 'Масса, кг',
					name: 'weight',
					rules: [
						{ required: true },
						{
							validator: (_, value) => {
								if (Number(value) > mainCtx.saleModal.storage.weight) {
									return Promise.reject('Масса не должна превышать допущенную')
								}
								return Promise.resolve()
							},
						},
					],
					field: (
						<InputError
							hasError={mainCtx.formErrors?.[`saleModal.weight`]}
							value={mainCtx.saleModal?.weight}
							onChange={(e) => {
								setNumField(
									e.target.value,
									mainCtx.saleModal?.weight,
									mainCtx.modalFunctions.setSaleModalWeight,
									'float',
									[9, 2]
								)
							}}
							style={{ width: '100%' }}
						/>
					),
				},
			]
		)
	}
	if (mainCtx.relocationModal.__isOpen) {
		key = 'relocationModal'
		result[0].push(
			...[
				{
					layout: layout,
					label: 'Тек. масса, кг',
					name: 'storWeight',
					field: mainCtx.relocationModal.storage?.weight,
				},
				{
					layout: layout,
					label: 'Тек. помещение №',
					name: 'roomNumber',
					field: mainCtx.relocationModal.storage?.roomNum,
				},

				// {
				// 	layout: layout,
				// 	label: `Перемещение в брак`,
				// 	name: 'isWriteOff',
				// 	field: (
				// 		<Checkbox
				// 			checked={mainCtx.relocationModal.isWriteOff}
				// 			onChange={(e) => {
				// 				mainCtx.modalFunctions.setRelocationModalIsWriteOff(e.target.checked)
				// 				mainCtx.modalFunctions.setRelocationModalRoomNum(null)
				// 			}}
				// 		/>
				// 	),
				// },
				{
					layout: layout,
					label: 'Масса, кг',
					name: 'weight',
					rules: [
						{ required: true },
						{
							validator: (_, value) => {
								if (Number(value) > mainCtx.relocationModal.storage.weight) {
									return Promise.reject('Масса не должна превышать допущенную')
								}
								return Promise.resolve()
							},
						},
					],
					field: (
						<InputError
							hasError={mainCtx.formErrors?.[`relocationModal.weight`]}
							value={mainCtx.relocationModal?.weight}
							onChange={(e) => {
								setNumField(
									e.target.value,
									mainCtx.relocationModal?.weight,
									mainCtx.modalFunctions.setRelocationModalWeight,
									'float',
									[9, 2]
								)
							}}
							style={{ width: '100%' }}
						/>
					),
				},
				{
					layout: layout,
					label: 'Помещение №',
					name: 'roomNums',
					rules: [{ required: true }],
					field: (
						<Select
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								mainCtx.modalFunctions.setRelocationModalRoomNum(el)
							}}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{getRoomNumsArray(mainCtx, 'ГП')?.map((it) => {
								return (
									<Select.Option key={it.id} value={it.id}>
										{it.displayCode}
									</Select.Option>
								)
							}) || []}
						</Select>
					),
				},
			]
		)
	}
	if (mainCtx.repackingModal.__isOpen) {
		key = 'repackingModal'
		result[0].push(
			...[
				{
					layout: layout,
					label: 'Тек. тип упаковки',
					name: 'roomNumber',
					field: mainCtx.repackingModal.storage?.prodCatKind,
				},
				{
					layout: layout,
					label: 'Масса, кг',
					name: 'weight',
					rules: [
						{ required: true },
						{
							validator: (_, value) => {
								if (Number(value) > mainCtx.repackingModal.storage.weight) {
									return Promise.reject('Масса не должна превышать допущенную')
								}
								return Promise.resolve()
							},
						},
					],
					field: (
						<InputError
							hasError={mainCtx.formErrors?.[`repackingModal.weight`]}
							value={mainCtx.repackingModal?.weight}
							onChange={(e) => {
								setNumField(
									e.target.value,
									mainCtx.repackingModal?.weight,
									mainCtx.modalFunctions.setRepackingModalWeight,
									'float',
									[9, 2]
								)
							}}
							style={{ width: '100%' }}
						/>
					),
				},
				{
					layout: layout,
					label: 'Целевая упаковка',
					name: 'containerType',
					rules: [{ required: true }],
					field: (
						<Select
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								mainCtx.modalFunctions.setRepackingModalContainerType(el)
							}}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors.repackingModal.containerType?.map((it) => {
								return (
									<Select.Option key={it.id} value={it.id}>
										{it.labelShort}
									</Select.Option>
								)
							}) || []}
						</Select>
					),
				},

				{
					layout: layout,
					label: 'Масса нетто ед. упак., кг',
					name: 'nettoPkgWeight',
					rules: [{ required: true }],
					field: (
						<InputError
							hasError={mainCtx.formErrors?.[`repackingModal.nettoPkgWeight`]}
							value={mainCtx.repackingModal?.nettoPkgWeight}
							onChange={(e) => {
								setNumField(
									e.target.value,
									mainCtx.repackingModal?.nettoPkgWeight,
									mainCtx.modalFunctions.setRepackingModalNettoPkgWeight,
									'float',
									[9, 2]
								)
							}}
							style={{ width: '100%' }}
						/>
					),
				},
			]
		)
	}
	if (mainCtx.changeStatusModal.__isOpen) {
		key = 'changeStatusModal'
		result[0].push(
			...[
				{
					layout: layout,
					label: 'Тек. масса, кг',
					name: 'storWeight',
					field: mainCtx.changeStatusModal.storage?.weight,
				},
				{
					layout: layout,
					label: 'Тек. статус',
					name: 'roomNumber',
					field: mainCtx.changeStatusModal.storage?.status,
				},
				{
					layout: layout,
					label: 'Масса, кг',
					name: 'weight',
					rules: [
						{ required: true },
						{
							validator: (_, value) => {
								if (Number(value) > mainCtx.changeStatusModal.storage.weight) {
									return Promise.reject('Масса не должна превышать допущенную')
								}
								return Promise.resolve()
							},
						},
					],
					field: (
						<InputError
							hasError={mainCtx.formErrors?.[`changeStatusModal.weight`]}
							value={mainCtx.changeStatusModal?.weight}
							onChange={(e) => {
								setNumField(
									e.target.value,
									mainCtx.changeStatusModal?.weight,
									mainCtx.modalFunctions.setChangeStatusModalWeight,
									'float',
									[9, 2],
									{ isOnlyNums: true }
								)
							}}
							style={{ width: '100%' }}
						/>
					),
				},
				{
					layout: layout,
					label: `Статус`,
					name: 'status',
					rules: [{ required: true }],
					field: (
						<Select
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								mainCtx.modalFunctions.setChangeStatusModalStatus(el)
							}}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{['Допущено', 'Заблокировано', 'Удержано']
								.filter((s) => s !== mainCtx.changeStatusModal.storage?.status)
								.map((it) => {
									return (
										<Select.Option key={it} value={it}>
											{it}
										</Select.Option>
									)
								}) || []}
						</Select>
					),
				},
				...(['Заблокировано', 'Удержано'].includes(mainCtx.changeStatusModal.status)
					? [
							{
								layout: layout,
								label: 'Причина блокировки',
								name: 'blockCauses',
								rules: [
									{
										required: ['Заблокировано', 'Удержано'].includes(
											mainCtx.changeStatusModal.status
										),
									},
								],
								field: (
									<Select
										mode="multiple"
										placeholder="Выберите причины"
										style={{ width: '100%', minWidth: '120px' }}
										value={mainCtx.changeStatusModal?.blockCauses?.map((bk) => bk?.id)}
										onChange={(e) => {
											mainCtx.modalFunctions.setChangeStatusModalBlockCauses([
												...e.map((id) => {
													const bk = mainCtx.selectors.changeStatusModal.blockCauses.find(
														(bk) => bk.id === id
													)
													return bk
												}),
											])
										}}
									>
										{mainCtx.selectors.changeStatusModal.blockCauses?.map((it) => {
											return (
												<Select.Option key={it.id} value={it.id}>
													{it.label}
												</Select.Option>
											)
										}) || []}
									</Select>
								),
							},
					  ]
					: []),
				{
					layout: layout,
					label: 'Комментарий',
					name: 'comment',
					field: (
						<Input
							value={mainCtx.changeStatusModal?.comment}
							onChange={(e) => {
								mainCtx.modalFunctions.setChangeStatusModalComment(e.target.value)
							}}
							style={{ width: '100%' }}
						/>
					),
				},
			]
		)
	}

	result[0].unshift(
		...[
			{
				layout: layout,
				label: 'Продукт',
				name: 'prodCat',
				field:
					(mainCtx[key]?.storage &&
						`${mainCtx[key]?.storage?.prodCat} ${mainCtx[key]?.storage?.prodCatKind}`) ||
					'',
			},
			{
				layout: layout,
				label: 'Партия №',
				name: 'partyNum',
				field: mainCtx[key]?.storage?.partyNum,
			},
			{
				layout: layout,
				label: 'Артикул',
				name: 'articul',
				field: mainCtx[key]?.storage && mainCtx[key].storage?.articul,
			},
		]
	)
	return result
}
