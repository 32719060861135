import { AuthContext } from '../../../contexts/index.js'
import React, { useState, useContext } from 'react'
import { Card, Table } from 'antd'
import { tableColumns } from './table'
import { UserDataContext } from '../../../contexts'
const { checkRole } = require('@berry/common-functions/role')

const Body = (props) => {
	const {
		columns,
		data,
		handleEditRow,
		handleDeleteRow,
		canUpdate = false,
		canDelete = false,
		model = '',
		rights,
		vocUrl,
	} = props

	const [hoverRecord, setHoverRecord] = useState(null)

	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights, isDevAdmin },
	} = useContext(UserDataContext)

	const handleHoverRow = (record) => {
		if (canUpdate && canDelete) {
			return {
				onMouseEnter: () => setHoverRecord({ id: record.id, canUpdate, canDelete }),
				onMouseLeave: () => setHoverRecord({}),
			}
		}
	}

	const hasRights = checkRole(rights, currentRights)

	const getEditDeleteColumn = () => {
		if (hasRights && canUpdate && canDelete) {
			return tableColumns(
				handleEditRow,
				handleDeleteRow,
				hoverRecord,
				authCtx,
				vocUrl,
				model,
				isDevAdmin
			)
		}
		return []
	}

	return (
		<Card>
			<Table
				onRow={handleHoverRow}
				dataSource={data}
				pagination={{ showSizeChanger: false, pageSize: 16 }}
				rowKey="id"
				scroll={{ x: 800 }}
				size="small"
				columns={[...columns, ...getEditDeleteColumn()]}
			/>
		</Card>
	)
}

export default Body
