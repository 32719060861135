import { Checkbox } from 'antd'
import { changeDateOutput } from '../utils'
import { getColumnSearchProps, sorter } from '../../../utils'
import moment from 'moment'
import { dateFormat } from '../../../utils/constants/common'
import { Status } from '../../../components'

export const getColumns = (props) => {
	const { fromServerFilters } = props

	return [
		{
			title: 'Д',
			dataIndex: 'tolling',
			key: 'tolling',
			render: (value) => {
				return <Checkbox disabled checked={value}></Checkbox>
			},
			width: '2.5%',
			sorter: sorter('boolean', 'tolling'),
		},
		{
			title: 'Р',
			dataIndex: 'frame',
			key: 'frame',
			render: (value) => {
				return <Checkbox disabled checked={value}></Checkbox>
			},
			width: '2.5%',
			sorter: sorter('boolean', 'frame'),
		},
		{
			title: 'Номер договора',
			dataIndex: 'num',
			key: 'num',
			width: '15%',
			sorter: sorter('string', 'num'),
		},
		{
			title: 'Дата заключения',
			dataIndex: 'dateStart',
			key: 'dateStart',
			render: (dateStart) => (dateStart ? moment(dateStart).format(dateFormat) : null),
			...getColumnSearchProps(fromServerFilters.dateStart, undefined, null, true),
			width: '15%',
			sorter: sorter('date', 'dateStart', 'YYYY-MM-DD'),
		},

		{
			title: 'Поставщик',
			dataIndex: 'providerLabel',
			key: 'providerLabel',
			...getColumnSearchProps(fromServerFilters.providerLabel),
			width: '20%',
		},
		{
			title: 'Продукт',
			dataIndex: 'product',
			key: 'product',
			...getColumnSearchProps(fromServerFilters.product),
			width: '25%',
		},
		{
			title: 'Срок договора',
			dataIndex: 'dateEnd',
			key: 'dateEnd',
			render: (_, record) =>
				changeDateOutput(record)
					? moment(changeDateOutput(record)).format(dateFormat)
					: changeDateOutput(record),
			...getColumnSearchProps(fromServerFilters.dateEnd, undefined, null, true),
			width: '10%',
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			...getColumnSearchProps(fromServerFilters.status),
			render: (status) => <Status status={status} />,
			width: '10%',
		},
	]
}
