import { Button, Checkbox, Space, Typography } from 'antd'
import lodash from 'lodash'
import { modes, openNewTab } from '../../../../../utils'
import { posTypeData } from '@berry/static-data'
import { getPath } from '../pos-type-item'
import { showConfirmModal } from '../../../../../components'

const isChecked = (mainCtx, record, action) =>
	lodash.get(mainCtx.state.params, getPath(posTypeData.structure.Карточки, record.label))?.[action]

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 *  @param {Object} modCtx
 */
export const getDisplayColumns = (mainCtx, modCtx, authCtx) => {
	const isDisabled = modCtx.mod === modes.view
	const canUncheckSpecMatch =
		(!mainCtx.state.isInitiatorSpec &&
			mainCtx.state.lvlApproveSpec === '-' &&
			mainCtx.state.lvlMatchSpec === '-') ||
		modCtx.mod === modes.new
	return [
		{
			title: 'Типы карточек',
			dataIndex: 'label',
			key: 'label',
			width: '30%',
		},
		{
			title: 'Просмотр',
			dataIndex: 'view',
			key: 'view',
			width: '20%',
			render: (text, record) =>
				((!['АРМ цеха', 'АРМ офиса', 'Изменить статус'].includes(record.label) &&
					!record.children?.length) ||
					record.label === 'Карточка Запасы' ||
					record.label === 'Задачи на производство') && (
					<Checkbox
						disabled={isDisabled}
						checked={isChecked(mainCtx, record, 'view')}
						onChange={(e) => mainCtx.stateFunctions.setParams(record, 'view', e.target.checked)}
					/>
				),
		},
		{
			dataIndex: 'edit',
			key: 'edit',
			title: 'Редактирование',
			width: '20%',
			render: (text, record) =>
				!['АРМ цеха', 'АРМ офиса', 'Себестоимость'].includes(record.label) &&
				(!record.children?.length ||
					record.label === 'Карточка Запасы' ||
					record.label === 'Задачи на производство') && (
					<Checkbox
						disabled={isDisabled}
						checked={isChecked(mainCtx, record, 'edit')}
						onChange={(e) => mainCtx.stateFunctions.setParams(record, 'edit', e.target.checked)}
					/>
				),
		},
		{
			dataIndex: 'match',
			key: 'match',
			title: 'Согласование',
			render: (text, record) =>
				['Входной контроль', 'Протоколы контроля', 'Задачи на производство'].includes(
					record.label
				) && (
					<Checkbox
						disabled={isDisabled}
						checked={isChecked(mainCtx, record, 'match')}
						onChange={(e) => {
							if (e.target.checked) {
								mainCtx.stateFunctions.setParams(record, 'match', e.target.checked)
							} else if (canUncheckSpecMatch) {
								mainCtx.stateFunctions.setParams(record, 'match', e.target.checked)
							} else {
								return showConfirmModal({
									title: (
										<Space direction="vertical">
											<Typography.Text>
												Невозможно снять флаг, пока для данной должности установлено значение в
												алгоритме согласования
											</Typography.Text>
											<Button
												type="link"
												onClick={() =>
													openNewTab('/system-settings/specification-negotiation', {
														authCtx: authCtx.state,
													})
												}
											>
												Настройки системы/Согласование спецификации
											</Button>
										</Space>
									),
									showCancel: false,
									width: '30%',
									okText: 'Ок',
								})
							}
						}}
					/>
				),
		},
	]
}
