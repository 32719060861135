import { Checkbox, DatePicker, Input } from 'antd'
import { CommonSelect, Status } from '../../../components'
import { modes } from '../../../utils'
import ApplicationTab from '../ApplicationTab'
import QuotaTab from '../QuotaTab/QuotaTab'
import { changeDateOutput, isContractActive } from '../utils'
import OrderTab from './../OrderTab/index'
import moment from 'moment'
import MainCardClickableAttr from '../../../components/MainCardClickableAttr/MainCardClickableAttr'

const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 12 },
}

const layoutRight = {
	labelCol: {
		sm: 8,
		lg: 8,
		xl: { span: 9, offset: 10 },
	},
	wrapperCol: { sm: 6, lg: 14, xxl: 12 },
}

const layoutMid = {
	labelCol: { sm: 4, lg: 8, xl: { span: 10, offset: 2 } },
	wrapperCol: { sm: 4, lg: 12, xl: 8 },
}

const pageRights = {
	'АРМ офиса': {
		Поставщики: {
			view: true,
		},
	},
}

export const getFormItems = (props) => {
	const {
		mainCtx,
		modCtx,
		userDataCtx: {
			state: { isDevAdmin },
		},
	} = props
	const isDisabled = modCtx.mod === modes.view

	const items = [
		[
			{
				key: 'num',
				layout: layout,
				label: 'Номер договора',
				field: isDevAdmin ? (
					<Input
						style={{ minWidth: '85px' }}
						value={mainCtx.state.num}
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setNum(e.target.value)
						}}
					/>
				) : (
					mainCtx.state.num
				),
			},
			{
				key: 'dateStart',
				layout: layout,
				label: 'Дата заключения',
				field: isDevAdmin ? (
					<DatePicker
						value={mainCtx.state.dateStart ? moment(mainCtx.state.dateStart, 'YYYY-MM-DD') : null}
						style={{ width: '100%' }}
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format="DD.MM.YYYY"
						allowClear={false}
						suffixIcon={null}
						onChange={(e) => mainCtx.stateFunctions.setDateStart(e)}
						disabled={isDisabled}
					/>
				) : (
					mainCtx.state.dateStart
				),
			},
			{
				key: 'dateEnd',
				layout: layout,
				label: 'Срок договора',
				field: isDevAdmin ? (
					<DatePicker
						value={mainCtx.state.dateEnd ? moment(mainCtx.state.dateEnd, 'YYYY-MM-DD') : null}
						style={{ width: '100%' }}
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format="DD.MM.YYYY"
						allowClear={false}
						suffixIcon={null}
						onChange={(e) => {
							mainCtx.stateFunctions.setDateEnd(e)
						}}
						disabled={isDisabled}
					/>
				) : (
					changeDateOutput(mainCtx.state)
				),
			},
		],
		[
			{
				layout: mainCtx.state.frame ? layoutMid : layout,
				key: 'tolling',
				label: 'Давальческий',
				field: (
					<Checkbox
						disabled={isDisabled || !isDevAdmin}
						checked={mainCtx.state.tolling}
						onChange={(e) => mainCtx.stateFunctions.setTolling(e.target.checked)}
					/>
				),
			},
			{
				layout: mainCtx.state.frame ? layoutMid : layout,
				key: 'frame',
				label: 'Рамочный',
				field: (
					<Checkbox
						disabled={isDisabled || !isDevAdmin}
						checked={mainCtx.state.frame}
						onChange={(e) => mainCtx.stateFunctions.setFrame(e.target.checked)}
					/>
				),
			},
		],
		[],
	]
	if (!mainCtx.state.frame) {
		items[0].push({
			key: 'provider',
			layout: layout,
			label: 'Поставщик',
			field:
				modCtx.mod === modes.view || !isDevAdmin ? (
					<MainCardClickableAttr
						text={mainCtx.state.provider?.displayVal}
						pageRights={pageRights}
						url={`/rm/providers/${mainCtx.state.provider?.id}`}
						formProps={props}
					/>
				) : (
					<CommonSelect
						value={mainCtx.state.provider}
						setValue={mainCtx.stateFunctions.setProvider}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allProviders}
					/>
				),
		})
		items[1].push(
			{
				layout: mainCtx.state.frame ? layoutMid : layout,
				key: 'autoProlongation',
				label: 'Автопродление договора',
				field: (
					<Checkbox
						disabled={isDisabled || !isDevAdmin}
						checked={mainCtx.state.autoProlongation}
						onChange={(e) => {
							mainCtx.stateFunctions.setAutoProlongation(e.target.checked)
						}}
					/>
				),
			},
			{
				layout: layout,
				key: 'isProtDisagr',
				label: 'Протокол разногласий',
				name: 'isProdDisagr',
				field: (
					<Checkbox
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setIsProtDisagr(e.target.checked)
						}}
						checked={mainCtx.state.isProtDisagr}
					/>
				),
			}
		)
		items[2].push(
			{
				layout: layout,
				key: 'isContractActive',
				label: 'Статус',
				field: <Status status={isContractActive(mainCtx.state) ? 'Действующий' : 'Закрытый'} />,
			},
			{
				layout: layout,
				key: 'platform',
				label: 'Площадка',
				required: true,
				name: 'platform',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.platform}
						setValue={mainCtx.stateFunctions.setPlatform}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allPlatforms.filter(
							(p) => mainCtx.state.provider && mainCtx.state.provider.id === p.provider?.id
						)}
					/>
				),
			},
			{
				layout: layout,
				name: 'vocDeliverCondit',
				key: 'vocDeliverCondit',
				label: 'Условия доставки',
				required: true,
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.vocDeliverCondit}
						setValue={mainCtx.stateFunctions.setVocDeliverCondit}
						isDisabled={isDisabled}
						dataArr={mainCtx.additional.allSelectDeliveryConditions}
					/>
				),
			},
			{
				layout: layout,
				name: 'vocPayCondit',
				key: 'vocPayCondit',
				label: 'Условия оплаты',
				required: true,
				rules: [{ required: true }],
				onChange: mainCtx.stateFunctions.setVocPayCondit,
				field: (
					<CommonSelect
						value={mainCtx.state.setVocPayCondit}
						setValue={mainCtx.stateFunctions.setVocPayCondit}
						isDisabled={isDisabled}
						dataArr={mainCtx.additional.allSelectPayConditions}
					/>
				),
			}
		)
	} else {
		items[1].unshift({
			layout: layoutMid,
			label: 'Поставщик',
			key: 'provider',
			field:
				modCtx.mod === modes.view || !isDevAdmin ? (
					<MainCardClickableAttr
						text={mainCtx.state.provider?.displayVal}
						pageRights={pageRights}
						url={`/rm/providers/${mainCtx.state.provider?.id}`}
						formProps={props}
					/>
				) : (
					<CommonSelect
						value={mainCtx.state.provider}
						setValue={mainCtx.stateFunctions.setProvider}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional?.allProviders || []}
					/>
				),
		})

		items[2].push(
			{
				layout: layoutRight,
				key: 'autoProlongation',
				label: 'Автопродление договора',
				field: (
					<Checkbox
						disabled={isDisabled || !isDevAdmin}
						checked={mainCtx.state.autoProlongation}
						onChange={(e) => {
							mainCtx.stateFunctions.setAutoProlongation(e.target.checked)
						}}
					/>
				),
			},
			{
				layout: layoutRight,
				key: 'isProtDisagr',
				label: 'Протокол разногласий',
				field: (
					<Checkbox
						disabled={isDisabled}
						onChange={(e) => mainCtx.stateFunctions.setIsProtDisagr(e.target.checked)}
						checked={mainCtx.state.isProtDisagr}
					/>
				),
			},
			{
				layout: layoutRight,
				key: 'isContractActive',
				label: 'Статус',
				field: <Status status={isContractActive(mainCtx.state) ? 'Действующий' : 'Закрытый'} />,
			}
		)
	}
	return items
}

/**
 * возвращает вкладки которые должны быть отображены
 * @param {Object} mainCtx
 */
export const getMinorTabs = (mainCtx) => {
	const result = []
	if (mainCtx.state.frame) {
		result.push({ key: result.length, title: 'Приложения', component: <ApplicationTab /> })
	}
	result.push({
		key: result.length,
		title: 'Распределение квоты',
		component: <QuotaTab provOrCustKey="providerQuotas" />,
	})
	if (mainCtx.state.tolling) {
		result.push({ key: result.length, title: 'Заказы', component: <OrderTab /> })
	}
	return result
}
