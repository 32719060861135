import { Modal, Table } from 'antd'
import React, { useState, useContext } from 'react'
import lodash from 'lodash'
import { SpecParamContext } from '../provider/main'
import { RecordItemModal, TabItem, CustomForm } from '../../../../../components'
import { AuthContext, ModContext } from '../../../../../contexts'
import { modes } from '../../../../../utils'
import { getDisplayColumns, getFormItems } from './param-tab'
import { prepareForOpenedModal } from '../../../../../utils/helpers/for-modal'

const ParamTab = () => {
	const mainCtx = useContext(SpecParamContext)
	const stateFunctions = mainCtx.stateFunctions.params
	let stateData = mainCtx.state.params
	const modCtx = useContext(ModContext)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const authCtx = useContext(AuthContext)
	/**
	 *  {_uuid_:string,isNew:boolean } itemForModal
	 */
	const [itemForModal, setItemForModal] = useState(null)

	stateData = prepareForOpenedModal(stateData, itemForModal)
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const newUuid = stateFunctions.create()
					setItemForModal({ _uuid_: newUuid, isNew: true })
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						isOpen={isModalOpen}
						formName="param"
						width={'60%'}
						title="Параметр"
						item={itemForModal}
						deleteFun={stateFunctions.delete}
						setItem={setItemForModal}
						setModalState={setIsModalOpen}
						editFun={stateFunctions.edit}
						beforeClose={() => {
							mainCtx.errorFields.param.forEach((e) => {
								mainCtx.setError([], [], e, undefined)
							})
						}}
						validate={() => {
							const whereToFind = mainCtx.state.params.filter(
								(e) => e._uuid_ !== itemForModal._uuid_
							)
							const currentItem = mainCtx.state.params.find((e) => e._uuid_ === itemForModal._uuid_)
							if (
								currentItem.isNumber &&
								(whereToFind.some(
									(param) =>
										param.label.toLowerCase() === String(currentItem.label).toLowerCase() &&
										currentItem.vocMeasure &&
										+param.vocMeasure?.id === +currentItem.vocMeasure?.id
								) ||
									mainCtx.additional.allSpecParams.some((spec) =>
										spec.params.some(
											(param) =>
												param.isNumber &&
												currentItem._uuid_ !== param._uuid_ &&
												param.label.toLowerCase() === String(currentItem.label).toLowerCase() &&
												param.vocMeasure?.id === currentItem.vocMeasure?.id
										)
									))
							) {
								Modal.warning({
									centered: true,
									content: 'Параметр не уникален',
									keyboard: true,
								})
								throw Error()
							}
							return mainCtx.validators.params(itemForModal._uuid_)
						}}
						form={() => {
							if (!itemForModal) return null
							return (
								<CustomForm
									name="param"
									fields={[
										{ name: 'label', value: stateFunctions.get(itemForModal._uuid_).label },
										{ name: 'isText', value: stateFunctions.get(itemForModal._uuid_).isText },
										{ name: 'isNumber', value: stateFunctions.get(itemForModal._uuid_).isNumber },
										{
											name: 'vocMeasure',
											value: stateFunctions.get(itemForModal._uuid_).vocMeasure,
										},
										{
											name: 'deviationSymbol',
											value: stateFunctions.get(itemForModal._uuid_).deviationSymbol,
										},
										{
											name: 'deviationVal1',
											value: stateFunctions.get(itemForModal._uuid_).deviationVal1,
										},
										{
											name: 'deviationVal2',
											value: stateFunctions.get(itemForModal._uuid_).deviationVal2,
										},
										{
											name: 'etalonSymbol',
											value: stateFunctions.get(itemForModal._uuid_).etalonSymbol,
										},
										{
											name: 'etalonVal1',
											value: stateFunctions.get(itemForModal._uuid_).etalonVal1,
										},
										{
											name: 'etalonVal2',
											value: stateFunctions.get(itemForModal._uuid_).etalonVal2,
										},
										{
											name: 'deviationText',
											value: stateFunctions.get(itemForModal._uuid_).deviationText,
										},
										{
											name: 'etalonText',
											value: stateFunctions.get(itemForModal._uuid_).etalonText,
										},
									]}
									columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
									items={getFormItems(mainCtx, modCtx, itemForModal)}
								/>
							)
						}}
					/>
				)
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(mainCtx, modCtx, authCtx, (record) => {
							if (modCtx.mod === modes.view) return
							setItemForModal({ _uuid_: record._uuid_, prevData: lodash.cloneDeep(record) })
							setIsModalOpen(true)
						})}
						dataSource={stateData}
						pagination={false}
						scroll={{ x: 800 }}
					/>
				)
			}}
		></TabItem>
	)
}

export default ParamTab
