import React, { useCallback, useContext, useRef } from 'react'
import lodash from 'lodash'
import { ModContext } from '../../../../contexts'
import { v4 } from 'uuid'
import {
	prepareObjFromServer,
	modes,
	useItemFetchers,
	axios,
	appendFiles,
	goToItem,
} from '../../../../utils'
import { getCommonProviderFunctions } from '../../../../utils/helpers/generators'
import { useHistory } from 'react-router-dom'
import { entityToModelMap } from '@berry/static-data/all-models'
import { commonCheckIsBlocked } from '../../../../utils/helpers/for-block-unblock'
import { message } from 'antd'

const dataUrl = '/reg/staff'

export const reducer = (state) => {
	return {
		...state,
	}
}

const StaffItemMainContext = React.createContext()
StaffItemMainContext.displayName = 'StaffItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)

	const [state, dispatch] = React.useReducer(reducer, {
		data: {},
		oldData: {},
		additional: {},
		formErrors: [],
		isInitializedMain: false,
		isInitializedAdditional: false,
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, undefined, stateRef, useCallback(executeDispatch, []))

	const { commonFieldUpdate, stateFunctions, serverDelete, isEdited, getEditedData } =
		getCommonProviderFunctions(
			stateRef,
			stateRef.current.oldData,
			executeDispatch,
			{ modCtx, dataUrl, params, pageUrl: dataUrl, history },
			{},
			{
				name: 'common',
				surname: 'common',
				patronymic: 'common',
				posType: 'common',
				phoneNum: 'common',
				email: 'common',
				status: 'common',
				login: 'common',
				isGph: 'common',
				isWithoutPtrnmc: 'common',
				isAdmin: 'common',
			}
		)

	stateFunctions.setPhoto = (val) => {
		if (val === null) {
			commonFieldUpdate('photoPath', val)
		} else {
			if (val.originFileObj && !val.originFileObj.photoPath) {
				val.originFileObj.photoPath = `staff_${v4()}.${val.originFileObj.name?.split('.').pop()}`
				commonFieldUpdate('photoPath', val.originFileObj.photoPath)
			}
		}
		commonFieldUpdate('photo', val)
	}

	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
		})
	}, [])

	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	const getActualState = () => {
		const obj = lodash.cloneDeep(stateRef.current.data)
		if (!obj.phoneNum) obj.phoneNum = '+7'
		return obj
	}

	const checkIsBlocked = async () => {
		const result = await commonCheckIsBlocked([{ entity: 'regStaff', id: params.id }])
		return result[0]?.isBlocked
	}

	const serverEdit = async () => {
		try {
			const formData = appendFiles([stateRef.current.data], 'photo', '_uuid_', 'staff', {
				key: 'photoPath',
			})
			let body
			let options = { url: dataUrl }
			if (params.id === 'new') {
				body = lodash.cloneDeep(stateRef.current.data)
				options.method = 'POST'
			} else {
				body = getEditedData()
				options.method = 'PUT'
			}
			formData.append('data', JSON.stringify(body))
			executeDispatch({ ...stateRef.current, isPendingReq: true })
			const res = await axios[options.method.toLowerCase()](options.url, formData)
			executeDispatch({ ...stateRef.current, isPendingReq: false })
			modCtx.set(modes.view)
			if (res.data?.data?.id) {
				if (options.method === 'POST') {
					goToItem(history, { url: dataUrl, id: res.data.data.id })
				}
				executeDispatch({ ...stateRef.current, reloadUuid: v4() })
				modCtx.set(modes.view)
				return { method: options.method, id: res.data.data.id }
			}
			return res
		} catch (err) {
			executeDispatch({ ...stateRef.current, isPendingReq: false })
			message.error('Ошибка при сохранении')
			console.log(err.message)
		}
	}

	const value = {
		state: getActualState(),
		isPendingReq: stateRef.current.isPendingReq,
		checkIsBlocked,
		additional: stateRef.current.additional,
		stateFunctions,
		serverDelete,
		serverEdit,
		isEdited,
		reset,
	}

	return <StaffItemMainContext.Provider value={value}>{children}</StaffItemMainContext.Provider>
}

export { Provider, StaffItemMainContext }
