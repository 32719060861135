import { showConfirmModal } from '../../components'
import { openNewTab } from './for-router'
import { axios } from '..'
import { checkRole } from '@berry/common-functions/role'

const clickableStatus = (authCtx, state, userDataCtx) => {
	return (record) => ({
		className: ['Бронь', 'Продажа', 'Сработано', 'Утилизировано'].includes(record.status)
			? `tab-link clickable`
			: '',
		onClick: async (e) => {
			const { id, idStorage, status } = record
			if (['Бронь', 'Продажа', 'Сработано', 'Утилизировано'].includes(status)) {
				e.stopPropagation()
			}
			let url = ''
			let right = ''
			const params = { id: idStorage || id, status }
			if (['Бронь', 'Продажа', 'Сработано', 'Утилизировано'].includes(status)) {
				const { storages, ...els } = record
				params.data = els
				if (record.idStockRawMat) {
					els.assignedPartyNum = state.partyNum
				}
				const { data } = await axios(`/stock/get-url-for-storage`, {
					params: params,
				})
				url = data.pageUrl
				right = data.pageRight
			}
			const {
				state: { rights },
			} = userDataCtx
			if (!url) return
			if (!checkRole(right, rights)) {
				return showConfirmModal({
					title: 'Ограничены права доступа к данному объекту. Обратитесь к администратору системы',
					okText: 'Ок',
					width: 450,
					showCancel: false,
				})
			}
			openNewTab(url, {
				authCtx: authCtx.state,
			})
		},
	})
}

export default clickableStatus
