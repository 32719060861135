import React, { useContext } from 'react'
import { Card } from 'antd'
import { Provider, RequestListMainContext } from './provider/main'
import { Main } from '../../../layouts'

import { HeaderMenu, StatefulTable } from '../../../components'
import { getColumns } from './supply-request-list'
import { goToItem } from '../../../utils'
import { useHistory } from 'react-router-dom'

const pageUrl = '/rm/supply-requests'

const SupplyRequestList = () => (
	<Provider>
		<Main title="Заявки на поставку сырья">
			<SupReqListTable />
		</Main>
	</Provider>
)

const SupReqListTable = () => {
	const mainCtx = useContext(RequestListMainContext)
	const history = useHistory()
	return (
		<>
			<HeaderMenu
				rights={{
					'АРМ офиса': {
						'Заявки на поставку сырья': {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle="Заявки на поставку сырья"
				search={mainCtx.setSearch}
				canCreate={true}
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					fromServerFilters={mainCtx.state.fromServerFilters}
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					url={pageUrl}
					columnProps={{ mainCtx, fromServerFilters: mainCtx.state.fromServerFilters }}
					searchFields={mainCtx.searchFields}
				/>
			</Card>
		</>
	)
}

export default SupplyRequestList
