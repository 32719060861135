import IsLoggedIn from '../RouteWrappers/IsLoggedIn/IsLoggedIn'
import IsUserDataLoaded from '../RouteWrappers/IsUserDataLoaded/IsUserDataLoaded'

const RouteComponentWrapper = ({ child, ...props }) => {
	return (
		<IsLoggedIn>
			<IsUserDataLoaded>{child(props)}</IsUserDataLoaded>
		</IsLoggedIn>
	)
}

export default RouteComponentWrapper
