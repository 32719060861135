import { useState } from 'react'
import { modes } from '../constants'

const useVocModalTitle = () => {
	const [title, setTitle] = useState('Создание нового элемента')

	const changeTitle = (currentMode) => {
		if (currentMode === modes?.new) {
			setTitle('Создание нового элемента')
		}

		if (currentMode === modes?.edit) {
			setTitle('Редактирование элемента')
		}
	}

	return [title, changeTitle]
}

export default useVocModalTitle
