import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import { ModContext, UserDataContext } from '../../../../../contexts'
import { getCommonProviderFunctions } from '../../../../../utils/helpers/generators'
import { prepareObjFromServer, modes, useItemFetchers, axios } from '../../../../../utils'
import { basicValidator } from '@berry/common-functions/validators'
import { message } from 'antd'
import { getStockCalculatedParams } from '@berry/common-functions/cross-project-functions'
import { useHistory } from 'react-router-dom'

const dataUrl = '/stock/ready-prods'

export const reducer = (state) => {
	return {
		...state,
	}
}

const initialData = {
	storages: [],
	eventHistories: [],
	costs: [],
	__notFound: false,
}
const tabs = {
	'event-histories': 'eventHistories',
	storages: 'storages',
	costs: 'costs',
}

const StockReadyProdItemMainContext = React.createContext()
StockReadyProdItemMainContext.displayName = 'StockReadyProdItemMainContext'

const Provider = (props) => {
	const { children, params } = props

	const {
		state: { isDevAdmin },
	} = useContext(UserDataContext)

	const modCtx = useContext(ModContext)
	const [state, dispatch] = React.useReducer(reducer, {
		data: initialData,
		oldData: initialData,
		additional: {
			allSelectBlockCauses: [],
			allSelectsProdTasks: [],
		},
		formErrors: [],
		isInitializedMain: false,
		isInitializedAdditional: false,
		tabsLoading: {
			eventHistories: false,
			storages: false,
			parents: false,
			relatedParties: false,
		},
	})
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	const { refetch } = useItemFetchers(
		dataUrl,
		params.id,
		tabs,
		stateRef,
		useCallback(executeDispatch, [])
	)

	const requiredFields = {
		storages: ['status', 'blockCauses'],
	}

	const validate = () => {
		for (const obj in requiredFields) {
			for (const el of stateRef.current.data[obj]) {
				if (requiredFields[obj]?.some((f) => !basicValidator(el[f]))) return 'error'
			}
		}
		const blockCauseHasError = (storage) =>
			['Удержано', 'Заблокировано'].includes(storage.status) && !storage.blockCauses.length

		if (stateRef.current.data.storages.some(blockCauseHasError)) {
			for (const storage of stateRef.current.data.storages) {
				if (blockCauseHasError(storage)) setError([], [], `blockCauses.${storage._uuid_}`, true)
			}
			return 'Не заполнены обязательные поля'
		}
		return ''
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			formErrors: {},
		})
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * подготавливает данные которые нужно выбират
	 */

	const selectors = {
		tasksForComplectations: lodash.cloneDeep(stateRef.current.additional.allSelectsProdTasks),
		storages: {
			blockCause: stateRef.current.additional.allSelectBlockCauses.filter(
				(bc) => bc.status === 'Действующая'
			),
			status: [
				{ label: 'Допущено' },
				{ label: 'Удержано' },
				{ label: 'Заблокировано' },
				...(isDevAdmin ? [{ label: 'Сработано' }] : []),
			],
		},
	}

	const { getEditedData, commonDeepFieldUpdate, isEdited, stateFunctions, serverDelete, setError } =
		getCommonProviderFunctions(
			stateRef,
			stateRef.current.oldData,
			executeDispatch,
			{ modCtx, dataUrl, params, pageUrl: '/stock/ready-prods', history, requiredFields },
			selectors,
			{},
			{
				storages: {
					blockCauses: {
						blockCause: 'common',
					},
				},
			}
		)

	stateFunctions.storages.setStatus = (inUuid, val) => {
		if (val === 'Допущено') {
			commonDeepFieldUpdate(['storages'], [inUuid], 'blockCauses', [])
		}
		commonDeepFieldUpdate(['storages'], [inUuid], 'status', val)
	}

	const serverEdit = async () => {
		try {
			let body
			let options = { url: dataUrl }
			if (params.id === 'new') {
				body = lodash.cloneDeep(stateRef.current.data)
				options.method = 'POST'
			} else {
				body = getEditedData()
				options.method = 'PUT'
			}
			body?.storages?.forEach((s) => {
				if (s.status) {
					const oldFound = stateRef.current.oldData.storages.find((st) => +st.id === +s.id)
					s.oldStatus = oldFound.status
					s.newStatus = s.status
					if (!stateRef.current.data.idContrOrder) {
						s.status = 'Отправлено в 1С'
					}
				}
			})
			const { articul } = getStockCalculatedParams(stateRef.current.data)
			body.__articul = articul
			body.__isContrOrderId = !!stateRef.current.data.idContrOrder
			executeDispatch({ ...stateRef.current, isPendingReq: true })
			const res = (await axios[options.method.toLowerCase()](dataUrl, body))?.data || {}
			executeDispatch({ ...stateRef.current, isPendingReq: false })
			if (res?.data?.id) {
				if (options.method === 'POST') {
					executeDispatch({
						...stateRef.current,
						data: {
							...stateRef.current.data,
							id: res.data.id,
						},
						oldData: lodash.cloneDeep({
							...stateRef.current.data,
							id: res.data.id,
						}),
					})
				}
				modCtx.set(modes.view)
			}
			refetch()
			return { method: options.method, id: res.data.id }
		} catch (err) {
			message.error('Ошибка при сохранении')
			console.log(err.message)
		}
	}

	const value = {
		state: lodash.cloneDeep(stateRef.current.data),
		additional: stateRef.current.additional,
		tabsLoading: stateRef.current.tabsLoading,
		stateFunctions,
		isPendingReq: stateRef.current.isPendingReq,
		serverEdit,
		serverDelete,
		isEdited,
		validate,
		reset,
		formErrors: stateRef.current.formErrors,
		selectors,
		setError,
	}

	return (
		<StockReadyProdItemMainContext.Provider value={value}>
			{children}
		</StockReadyProdItemMainContext.Provider>
	)
}

export { Provider, StockReadyProdItemMainContext }
