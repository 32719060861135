import { Col, Row } from 'antd'
import React, { useContext } from 'react'
import { StockReadyProdResaleListMainContext } from './provider/main'
import { CustomForm } from '../../../../components'
import { getFormItems } from './modals-for-list'
import { getProdCatKindRawMatLabel } from '@berry/common-functions/cross-project-functions'
const ModalsForList = () => {
	const mainCtx = useContext(StockReadyProdResaleListMainContext)
	return (
		<Row>
			<Col span="24">
				<CustomForm
					onFinish={mainCtx.modalsServerEdit}
					name="removeRegister"
					fields={[
						{ name: 'refund', value: mainCtx.removeRegisterModal?.refund },
						{ name: 'sale', value: mainCtx.removeRegisterModal?.sale },
						{ name: 'prodCat', value: mainCtx.removeRegisterModal?.prodCat?.label },
						{
							name: 'prodCatKindRawMat',
							value: getProdCatKindRawMatLabel(mainCtx.removeRegisterModal?.prodCatKindRawMat),
						},
						{
							name: 'weight',
							value:
								mainCtx.removeRegisterModal?.weight ||
								mainCtx.relocationModal?.weight ||
								mainCtx.repackModal?.weight ||
								mainCtx.changeStatusModal?.weight,
						},
						{
							name: 'nettoPkgWeight',
							value: mainCtx.repackModal?.nettoPkgWeight,
						},
						{
							name: 'vocContType',
							value: mainCtx.repackModal?.vocContType?.labelShort || null,
						},
						{ name: 'roomNums', value: mainCtx.relocationModal?.roomNum?.displayCode },
						{
							name: 'comment',
							value: mainCtx.changeStatusModal?.comment,
						},
					]}
					columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
					items={getFormItems(mainCtx)}
				/>
			</Col>
		</Row>
	)
}

export default ModalsForList
