const dotObj = require('dot-object')
/**
 * Проверяет наличие требуемых прав
 * @param {Object} required - обьект с требуемыми правами в дот нотации
 * @param {Object} current - обьет с текущими правами в дот нотации
 * @returns {boolean}
 */
const checkRole = (required = {}, current = {}) => {
	const dotNotationRequired = dotObj.dot(required)
	const dotNotationUserRights = dotObj.dot(current)
	return Object.keys(dotNotationUserRights).some(
		(userRight) =>
			dotNotationRequired[userRight] !== undefined &&
			dotNotationRequired[userRight] === dotNotationUserRights[userRight]
	)
}

module.exports.checkRole = checkRole
