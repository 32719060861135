import { getColumnSearchProps } from '../../../../utils'
import {
	RightSquareOutlined,
	BlockOutlined,
	UndoOutlined,
	StopOutlined,
	RotateRightOutlined,
} from '@ant-design/icons'
import { Row } from 'antd'
import { colorizeIntegrationListStatus } from '../../../../utils/helpers/for-integration'
import { LeftTooltip, ShelfLifeDateCell, Status } from '../../../../components'
import clickableStatus from '../../../../utils/helpers/clickableStatus'
/**
 * @param {Object} inArr - массив с записями таблицы
 */

export const getColumns = (props) => {
	const {
		fromServerFilters,
		mainCtx,
		canEdit,
		frontFilters,
		sorter,
		authCtx,
		userDataCtx,
		stockNotify,
	} = props
	let items = [
		{
			title: 'ID ДЗ',
			dataIndex: 'idContrOrder',
			sorter: true,
			sortOrder: sorter.columnKey === 'idContrOrder' && sorter.order,
			key: 'idContrOrder',
			width: '2.81%',
			...getColumnSearchProps(fromServerFilters.idContrOrder, frontFilters, 'idContrOrder'),
		},
		{
			title: 'Партия №',
			dataIndex: 'partyNumForSorter',
			key: 'partyNum',
			width: '7.02%',
			sorter: true,
			sortOrder: sorter.columnKey === 'partyNum' && sorter.order,
			render: (_, rec) => rec.partyNum,
			...getColumnSearchProps(fromServerFilters.partyNum, frontFilters, 'partyNum'),
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			...getColumnSearchProps(fromServerFilters.articul, frontFilters, 'articul'),
			key: 'articul',
			width: '12.89%',
		},
		{
			title: 'Артикул 1C',
			dataIndex: 'articul1C',
			...getColumnSearchProps(fromServerFilters.articul1C, frontFilters, 'articul1C'),
			key: 'articul1C',
			width: '5.87%',
		},
		{
			title: 'Продукт',
			dataIndex: 'prodCat',
			key: 'prodCat',
			...getColumnSearchProps(fromServerFilters.prodCat, frontFilters, 'prodCat'),
			sorter: true,
			sortOrder: sorter.columnKey === 'prodCat' && sorter.order,
			width: '7.02%',
		},
		{
			title: 'Вид',
			dataIndex: 'prodCatKind',
			key: 'prodCatKind',
			width: '27.34%',
			sorter: true,
			sortOrder: sorter.columnKey === 'prodCatKind' && sorter.order,
			...getColumnSearchProps(fromServerFilters.prodCatKind, frontFilters, 'prodCatKind'),
		},
		{
			title: 'Годен до',
			dataIndex: 'shelfLifeDate',
			key: 'shelfLifeDate',
			width: '5.87%',
			render: (date) => <ShelfLifeDateCell date={date} stockNotify={stockNotify} />,
			sorter: true,
			sortOrder: sorter.columnKey === 'shelfLifeDate' && sorter.order,
		},

		{
			title: '№ пом',
			dataIndex: 'roomNum',
			key: 'roomNum',
			width: '5.11%',
			...getColumnSearchProps(fromServerFilters.roomNum, frontFilters, 'roomNum'),
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '6.06%',
			sorter: true,
			sortOrder: sorter.columnKey === 'weight' && sorter.order,
		},
		// {
		// 	title: 'Заказчик',
		// 	dataIndex: 'customer',
		// 	key: 'customer',
		// 	width: '8.28%',
		// 	sorter: true,
		// 	sortOrder: sorter.columnKey === 'customer' && sorter.order,
		// 	...getColumnSearchProps(fromServerFilters.customer, frontFilters, 'customer'),
		// },
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '10.72%',
			render: (status, record) => {
				if (['Удержано', 'Заблокировано'].includes(status)) {
					return (
						<div style={{ display: 'flex' }}>
							<LeftTooltip
								label={''}
								tooltip={
									<span style={{ whiteSpace: 'pre-wrap', wdith: '100%' }}>
										{record.blockCauses.map((p) => p.blockCause.label + '\n').join('')}
									</span>
								}
							/>
							<Status style={{ marginLeft: '3px' }} status={status} />
						</div>
					)
				}
				return colorizeIntegrationListStatus(status, false, {
					marginLeft: '17px',
				})
			},
			onCell: clickableStatus(authCtx, {}, userDataCtx),

			...getColumnSearchProps(fromServerFilters.status, frontFilters, 'status'),
		},
		{
			title: '1С',
			dataIndex: '_1c',
			key: '_1c',
			width: '4%',
			render: (__1C) => {
				if (__1C === 'Не зарегистрирован в 1С') {
					return (
						<StopOutlined
							style={{ fontSize: 19, color: 'rgb(207,9,9)' }}
							title="Не зарегистрирован в 1С"
							onClick={(e) => {}}
						/>
					)
				}
			},
			...getColumnSearchProps(fromServerFilters._1c, frontFilters, '_1c'),
		},
		...(canEdit
			? [
					{
						title: '',
						dataIndex: 'sale',
						key: 'sale',
						width: '8.3%',
						render: (_, record) => {
							const componentsToReturn = []
							if (
								['Допущено', 'Удержано', 'Заблокировано'].includes(record.status) &&
								record._1c !== 'Не зарегистрирован в 1С'
							)
								componentsToReturn.push(
									<RotateRightOutlined
										title="Смена статуса партии"
										className={'pictogram'}
										onClick={(e) => {
											e.stopPropagation()
											mainCtx.modalFunctions.openChangeStatusModal()
											mainCtx.modalFunctions.setChangeStatusModalStorage(record)
										}}
									/>
								)
							if (
								[
									'Допущено',
									'Удержано',
									'Заблокировано',
									'Отклонено 1С',
									'Ошибка отправки в 1С',
								].includes(record.status)
							) {
								if (['Отклонено 1С', 'Ошибка отправки в 1С'].includes(record.status)) {
									componentsToReturn.push(
										<UndoOutlined
											title="Вернуть статус"
											className={'pictogram'}
											onClick={(e) => {
												e.stopPropagation()
												mainCtx.dropStatus(record)
											}}
										/>
									)
								} else if (['Утверждена', 'Принято в 1С'].includes(record.taskRep.task.status)) {
									componentsToReturn.push(
										<>
											<RightSquareOutlined
												title="Перемещение"
												className={'pictogram'}
												onClick={(e) => {
													e.stopPropagation()
													mainCtx.modalFunctions.openRelocationModal()
													mainCtx.modalFunctions.setRelocationModalStorage(record)
												}}
											/>
											<BlockOutlined
												title="Перетарка"
												className={'pictogram'}
												onClick={(e) => {
													e.stopPropagation()
													mainCtx.modalFunctions.openRepackingModal()
													mainCtx.modalFunctions.setRepackingModalStorage(record)
												}}
											/>
										</>
									)
								}
								return (
									<Row style={{ justifyContent: 'space-around', fontSize: 21, flexWrap: 'nowrap' }}>
										{componentsToReturn}
									</Row>
								)
							}
							return null
						},
					},
			  ]
			: []),
	]
	return items
}

const xlsxColumnTitles = ['Партия №', 'Артикул', 'Артикул 1C', 'Продукт', 'Вид', 'Масса, кг']

export const getToXlsx = (dataSource, columns, renameColumnDataIndexToKey = []) => {
	if (renameColumnDataIndexToKey.length)
		columns.forEach((c) => {
			if (renameColumnDataIndexToKey.includes(c.dataIndex)) c.dataIndex = c.key
		})
	return {
		dataSource,
		columns: columns.filter((c) => xlsxColumnTitles.includes(c.title)),
		tableName: 'Реестр запасов ГП',
		fileName: 'Реестр_запасов_ГП.xlsx',
	}
}
