import React from 'react'

import { Main } from '../../layouts'
import { VocabulariesList } from '../../components'

const Vocabulary = () => {
	return <Main title="Справочники" children={<VocabulariesList />} />
}

export default Vocabulary
