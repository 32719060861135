import { isEmail } from '@berry/common-functions/validators'
import { Input } from 'antd'
import { EditDelBtns } from '../../../../components'
import { isNum, modes } from '../../../../utils'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (stateFunctions, modCtx, editHandler) => {
	let isDisabled = false
	if (modCtx.mod === modes.view) isDisabled = true
	return [
		{
			title: 'Ф.И.О',
			dataIndex: 'fullName',
			key: 'fullName',
			width: '23.75%',
		},
		{
			title: 'Должность',
			dataIndex: 'position',
			key: 'position',
			width: '23.75%',
		},
		{
			title: 'Телефон',
			dataIndex: 'phoneNum',
			key: 'phoneNum',
			width: '23.75%',
			render: (phoneNum) => phoneNum && '+7' + phoneNum,
		},
		{
			title: 'E-mail',
			dataIndex: 'email',
			key: 'email',
			width: '23.75%',
		},
		{
			title: null,
			key: 'action',
			width: '5%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={!isDisabled}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
						onEdit={editHandler}
					/>
				)
			},
		},
	]
}

const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 },
}

/**
 * поля формы в модальном окне
 * @param {Object} mainCtx - главный контекст
 * @param {Object} modCtx - контекст мода
 * @param {{_uuid_: string}} params - отбьект с функциями изменения состояния вкладки
 */
export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []
	const { _uuid_ } = params
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.contacts
	return [
		[
			{
				layout: layout,
				label: 'Ф.И.О',
				name: 'fullName',
				rules: [{ required: true }],
				field: (
					<Input
						style={{ width: '100%' }}
						value={stateFunctions.get(_uuid_).fullName}
						disabled={isDisabled}
						maxLength={55}
						onChange={(e) => {
							stateFunctions.setFullName(_uuid_, e.target.value)
						}}
					/>
				),
			},
			{
				layout: layout,
				label: 'Должность',
				colon: false,
				name: 'position',
				rules: [{ required: true }],
				field: (
					<Input
						style={{ width: '100%' }}
						value={stateFunctions.get(_uuid_).position}
						disabled={isDisabled}
						maxLength={55}
						onChange={(e) => {
							stateFunctions.setPosition(_uuid_, e.target.value)
						}}
					/>
				),
			},
			{
				layout: layout,
				label: 'Телефон',
				name: 'phoneNum',
				rules: [
					{ min: 10, message: 'Не менее 10 символов' },
					{ max: 10, message: 'Не более 10 символов' },
				],
				field: (
					<Input
						style={{ width: '100%' }}
						value={stateFunctions.get(_uuid_).phoneNum}
						disabled={isDisabled}
						onKeyDown={isNum}
						onPaste={(e) => e.preventDefault()}
						placeholder="XXX XXX XX XX"
						addonBefore="+7"
						onChange={(e) => {
							stateFunctions.setPhoneNum(_uuid_, e.target.value)
						}}
					/>
				),
			},
			{
				layout: layout,
				label: 'E-mail',
				name: 'email',
				rules: [
					{
						validator: async (_, value) => {
							if (value) {
								if (!isEmail(value)) {
									throw Error('Неверно указан адрес электронной почты')
								}
							}
						},
					},
				],
				field: (
					<Input
						style={{ width: '100%' }}
						value={stateFunctions.get(_uuid_).email}
						disabled={isDisabled}
						onChange={(e) => {
							stateFunctions.setEmail(_uuid_, e.target.value)
						}}
					/>
				),
			},
		],
	]
}
