import { sliceStringAndAddThreeDots } from '@berry/front-common'
import moment from 'moment'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */

export const getDisplayColumns = (dataSource) => {
	const renderWithRowSpan = (record, index) => {
		const foundIndex = dataSource.findIndex((p) => p.contract?.id === record.contract?.id)
		const length = dataSource.filter((p) => p.contract?.id === record.contract?.id).length
		return {
			rowSpan: index === foundIndex ? length || 1 : 0,
		}
	}
	return [
		{
			title: 'Поставщик',
			dataIndex: ['contract', 'provider', 'label'],
			key: 'provider',
			width: '13.53%',
			onCell: renderWithRowSpan,
		},
		{
			title: '№ договора',
			dataIndex: ['contract', 'num'],
			key: 'num',
			width: '7.6%',
			onCell: renderWithRowSpan,
		},
		{
			title: '№ ДС',
			dataIndex: ['contrQuota', 'application', 'displayCode'],
			key: 'displayCode',
			width: '5.16%',
			onCell: renderWithRowSpan,
			render: (text) => sliceStringAndAddThreeDots(text, 10),
		},
		{
			title: 'Продукт',
			key: 'product',
			width: '13.61%',
			render: (record) => record.displayVal,
		},
		{
			title: 'Год урожая',
			dataIndex: 'harvestYear',
			key: 'harvestYear',
			width: '8.9%',
			render: (val) => moment(val).format('YYYY'),
		},
		{
			title: 'Дата изготовления',
			dataIndex: 'date',
			key: 'date',
			render: (val) => moment(val).format('DD.MM.YYYY'),
			width: '11.44%',
		},
		{
			title: 'Тип упаковки',
			dataIndex: ['vocContType', 'labelShort'],
			key: 'vocContType',
			width: '8.24%',
		},
		{
			title: 'Масса нетто ед. упак., кг',
			dataIndex: 'containerWeight',
			key: 'containerWeight',
			width: '6.16%',
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '8.2%',
		},
	]
}
