import { Row } from 'antd'
const Logo = () => {
	return (
		<Row
			style={{
				left: '23%',
				top: '5%',
				width: 160,
				height: 160,
				position: 'absolute',
				background: 'url(/logo.png) no-repeat',
				backgroundSize: 'auto',
			}}
		></Row>
	)
}

export default Logo
