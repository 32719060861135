import { Button, Col, Row, Select, Table } from 'antd'
import React, { useContext, useMemo, useState } from 'react'
import { TabItem } from '../../../components'
import { AuthContext, ModContext } from '../../../contexts'
import { modes, prepareData } from '../../../utils'
import { getDisplayColumnsV2 } from './quota-tab'
import { ContractItemMainContext } from '../provider/item'
import { getQuotasYears, prepareDataV2 } from '../utils'
import { ResetFilter } from '../../../assets'
import Icon from '@ant-design/icons'
import { filterDisplayData, patchYearsProperty } from '../utils'

const QuotaTab = (props) => {
	const { provOrCustKey } = props
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(ContractItemMainContext)
	const authCtx = useContext(AuthContext)
	const [filters, setFilters] = useState({
		applicationNum: null,
		'rmProvProd.displayVal': null,
		year: null,
		prodCat: null,
		prodCatKindRawMat: null,
		prodCatPkg: null,
	})
	const stateFunctions = mainCtx.stateFunctions[provOrCustKey]
	const quotasYears = getQuotasYears(mainCtx, provOrCustKey)

	const dataWithYearsProp =
		mainCtx.state[provOrCustKey]?.map((e) => {
			return { ...e, years: quotasYears[e._uuid_] }
		}) || []
	const dataByYears = prepareData(dataWithYearsProp, 'years', 'year', { withoutUuidCheck: true })
	const toDisplayData = prepareDataV2(dataByYears)
	prepareData(dataWithYearsProp, 'years', 'year', { withoutUuidCheck: true })

	const clearFilters = () => {
		setFilters({
			applicationNum: null,
			'rmProvProd.displayVal': null,
			year: null,
			prodCat: null,
			prodCatKindRawMat: null,
			prodCatPkg: null,
		})
		setCurrentDataSource(toDisplayData)
	}
	const filteredToDisplayData = useMemo(
		() => filterDisplayData(toDisplayData, filters),
		[filters, toDisplayData]
	)

	const [currentDataSource, setCurrentDataSource] = useState(toDisplayData)

	patchYearsProperty(filteredToDisplayData, filters.year)
	const columns = getDisplayColumnsV2(
		mainCtx,
		modCtx,
		authCtx,
		provOrCustKey,
		currentDataSource.length ? currentDataSource : filteredToDisplayData,
		filters
	)
	return (
		<TabItem
			addButton={{
				disabled: modCtx.mod === modes.view,
				show: true,
				showOnView: true,
				onClick: () => {
					stateFunctions.create()
				},
			}}
			afterButtonComponent={
				<Row align="middle" style={{ width: '100%' }} justify="end">
					<Col style={{ fontWeight: 500, paddingRight: 10 }}>ЕИ</Col>
					<Select
						value="кг"
						style={{ width: 55, marginRight: 10 }}
						options={[{ value: 'кг', label: 'кг' }]}
					/>
					<Button
						type="link"
						icon={<Icon style={{ fontSize: '24px' }} component={ResetFilter} />}
						onClick={clearFilters}
					/>
				</Row>
			}
			table={() => (
				<Table
					size="small"
					rowKey={(rec) =>
						`${rec.id || rec._uuid_}_${rec.indxMain}_${
							rec.isDiplayedRowBySpan ? 'displ' : 'not_displ'
						}`
					}
					rowClassName={modCtx.mod !== modes.view && 'clickable'}
					columns={columns}
					dataSource={filteredToDisplayData}
					pagination={false}
					onChange={(_, filters, __, { currentDataSource }) => {
						setFilters(filters)
						setCurrentDataSource(currentDataSource)
					}}
					scroll={{ x: 800 }}
				/>
			)}
		/>
	)
}

export default QuotaTab
