import React, { useContext } from 'react'
import { Main } from '../../../layouts'
import { Provider, SpecificationListContext } from './provider/main'
import { HeaderMenu, StatefulTable } from '../../../components'
import { getColumns } from './specification-list'
import { Card } from 'antd'
import { goToItem } from '../../../utils'
import { useHistory } from 'react-router'

const pageUrl = '/reg/specifications'

const SpecificationList = () => {
	return (
		<Provider>
			<Main title="Протоколы контроля">
				<InfoComponent />
			</Main>
		</Provider>
	)
}

const InfoComponent = () => {
	const mainCtx = useContext(SpecificationListContext)
	const history = useHistory()
	return (
		<>
			<HeaderMenu
				rights={{
					'АРМ офиса': {
						'Протоколы контроля': {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle="Протоколы контроля"
				search={mainCtx.setSearch}
				canCreate
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					url={pageUrl}
					// searchFields={mainCtx.searchFields}
				/>
			</Card>
		</>
	)
}
export default SpecificationList
