import { Button, Col, Input, Space, Typography } from 'antd'
import { ListContext } from '../../contexts'
import { useContext } from 'react'
import { ResetFilter } from '../../assets'
import { setNumField } from '@berry/front-common/for-fields'

const WeightFilterInputs = () => {
	const {
		state: { weightFrom, weightTo },
		setWeightFrom,
		setWeightTo,
		setFilters,
		setSorter,
		setTextFilter,
		setMainFilter,
	} = useContext(ListContext)
	return (
		<Col style={{ paddingLeft: '60px' }}>
			<Space>
				<Typography.Text strong>Масса от</Typography.Text>
				<Input
					style={{ width: '120px' }}
					placeholder="кг"
					value={weightFrom}
					onChange={(e) => {
						return setNumField(e.target.value, weightFrom, setWeightFrom, 'float', [9, 2])
					}}
				/>
			</Space>
			<Space>
				<Typography.Text strong style={{ paddingLeft: '8px' }}>
					до
				</Typography.Text>
				<Input
					style={{ width: '120px' }}
					placeholder="кг"
					value={weightTo}
					onChange={(e) => {
						return setNumField(e.target.value, weightTo, setWeightTo, 'float', [9, 2])
					}}
				/>
				<Typography.Text strong style={{ paddingLeft: '8px' }}>
					кг
				</Typography.Text>
				<Button
					title="Очистить период, все фильтры и сортировки"
					type="link"
					icon={<ResetFilter className={'pictogram'} style={{ fontSize: 24 }} />}
					onClick={() => {
						setWeightFrom(null)
						setWeightTo(null)
						setTextFilter(null)
						setFilters({})
						setSorter({})
						setMainFilter({})
					}}
				/>
			</Space>
		</Col>
	)
}
export default WeightFilterInputs
