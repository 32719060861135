export const columns = [
	{
		title: 'Наименование',
		dataIndex: 'label',
		key: 'label',
		width: '30%',
	},
	{
		title: 'Адрес',
		dataIndex: 'address',
		key: 'address',
		width: '65%',
	},
]
