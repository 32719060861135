import { useState } from 'react'

const useCheckbox = (initialValue) => {
	const [value, setValue] = useState(initialValue)

	const onChange = (e) => {
		setValue(e.target?.checked || false)
	}

	return [{ value, onChange }, () => setValue(initialValue)]
}

export default useCheckbox
