import { Table } from 'antd'
import React, { useState, useContext } from 'react'
import lodash from 'lodash'
import { ProviderItemMainContext } from '../provider/main'
import { RecordItemModal, TabItem, CustomForm } from '../../../../components'
import { AuthContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns, getFormItems } from './platform-tab'
import { prepareForOpenedModal } from '../../../../utils/helpers/for-modal'

const PlatformTab = () => {
	const mainCtx = useContext(ProviderItemMainContext)
	const authCtx = useContext(AuthContext)
	const stateFunctions = mainCtx.stateFunctions.platforms
	const modCtx = useContext(ModContext)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [itemForModal, setItemForModal] = useState(null)
	const stateData = prepareForOpenedModal(mainCtx.state.platforms, itemForModal)
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const newUuid = stateFunctions.create()
					setItemForModal({ _uuid_: newUuid, isNew: true })
					setIsModalOpen(true)
				},
			}}
			modal={() => (
				<RecordItemModal
					isOpen={isModalOpen}
					formName="platform"
					width={'50%'}
					title="Площадки"
					item={itemForModal}
					deleteFun={stateFunctions.delete}
					setItem={setItemForModal}
					setModalState={setIsModalOpen}
					editFun={stateFunctions.edit}
					validate={() => mainCtx.validators.platforms(itemForModal._uuid_)}
					form={() => {
						if (!itemForModal) return null
						return (
							<CustomForm
								name="platform"
								fields={[
									{ name: 'label', value: stateFunctions.get(itemForModal._uuid_).label },
									{ name: 'address', value: stateFunctions.get(itemForModal._uuid_).address },
								]}
								columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
								items={getFormItems(mainCtx, modCtx, itemForModal)}
							/>
						)
					}}
				/>
			)}
			table={() => (
				<Table
					size="small"
					rowClassName={modCtx.mod !== modes.view && 'clickable'}
					columns={getDisplayColumns(mainCtx, modCtx, authCtx, (record) => {
						if (modCtx.mod === modes.view) return
						setItemForModal({ _uuid_: record._uuid_, prevData: lodash.cloneDeep(record) })
						setIsModalOpen(true)
					})}
					dataSource={stateData}
					scroll={{ x: 800 }}
					pagination={false}
				/>
			)}
		/>
	)
}

export default PlatformTab
