import React, { useMemo } from 'react'
import { Form, Row, Col } from 'antd'

const defaultColumns = [
	{ size: { xs: 24, md: 12, xl: 8 }, num: 0 },
	{ size: { xs: 24, md: 12, xl: 8 }, num: 1 },
	{ size: { xs: 24, md: 12, xl: 8 }, num: 2 },
	{ size: { xs: 24, md: 12, xl: 8 }, num: 3 },
]

// const defaultLayout = {
// 	0: {
// 		labelCol: { xs: 24, sm: 8, xl: 8 },
// 		wrapperCol: { xs: 24, sm: 14, xl: 14 },
// 	},
// 	1: {
// 		labelCol: { xs: 24, sm: 8, xl: 8 },
// 		wrapperCol: { xs: 24, sm: 14, xl: 14 },
// 	},
// 	2: {
// 		labelCol: { xs: 24, sm: 8, xl: 8 },
// 		wrapperCol: { xs: 24, sm: 14, xl: 14 },
// 	},
// 	half: {
// 		labelCol: { xs: 24, sm: 8, lg: 8, xl: 8 },
// 		wrapperCol: { xs: 24, sm: 14, lg: 16, xl: 14 },
// 	},
// }

const CustomForm = (props) => {
	const {
		columns = defaultColumns,
		name,
		validateMessages = {
			required: 'Поле обязательно для заполнения!',
		},
		fields = [],
		items,
		colon = true,
		form,
		children = null,
		...formProps
	} = props

	const memoFields = useMemo(
		() =>
			fields?.length
				? fields
				: items.map((item) => ({
						name: item.name,
						value: item.value,
				  })),
		[fields, items]
	)

	const generatedColumn = () => {
		if (!columns) return
		const result = []
		for (const column of columns) {
			if (!column) continue
			result.push(
				<Col key={column.num} {...column.size}>
					{generatedFormItem(column.num)}
				</Col>
			)
		}
		return result
	}

	const getTemplateFormItem = (
		{ field, layout, label, name, getValueProps, rules, style, tooltip, key, ...itemProps },
		index
	) => {
		if (rules) {
			itemProps = { ...itemProps, name }
		}
		return (
			<Form.Item
				{...itemProps}
				{...layout}
				label={label}
				getValueProps={getValueProps}
				rules={rules}
				style={style}
				tooltip={tooltip}
				key={name || key}
			>
				{field}
			</Form.Item>
		)
	}

	const getTwoColumn = (item) => (
		<Row key={`double_col_${item.map((item) => item.name).join('_')}`}>
			{item.map((e, index) => {
				return (
					<Col key={`${item.name}_${index}`} span={e?.colSpan || 12}>
						{getTemplateFormItem(e)}
					</Col>
				)
			})}
		</Row>
	)

	const generatedFormItem = (colNum) => {
		if (!items || !items[colNum]) return
		const result = []
		for (const item of items[colNum]) {
			if (!item) continue
			Array.isArray(item) ? result.push(getTwoColumn(item)) : result.push(getTemplateFormItem(item))
		}
		return result
	}

	return (
		<Form
			labelAlign="left"
			labelWrap
			name={name}
			validateMessages={validateMessages}
			fields={memoFields}
			colon={colon}
			form={form}
			{...formProps}
		>
			<Row gutter={16}>{generatedColumn()}</Row>
			{children}
		</Form>
	)
}

export default CustomForm
