import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Card, Col, Row, Form } from 'antd'
import { checkRole } from '@berry/common-functions/role'
import {
	ModContext,
	ModProvider,
	FileProvider,
	UserDataContext,
	AuthContext,
} from '../../../contexts'
import { Provider, RequestItemMainContext } from './provider/main'
import { Main } from '../../../layouts'
import moment from 'moment'
import {
	ActionButtons,
	CustomForm,
	HeaderMenu,
	showConfirmModal,
	TabList,
} from '../../../components'
import { getFormItems, getPlatform } from './supply-request-item'
import SupplyTab from './SupplyTab'
import ProductTab from './ProductTab'
import DocumentsTab from './DocumentsTab/index'

import {
	actBtnOnClose,
	actBtnOnDelete,
	actBtnOnSaveV2,
	axios,
	modes,
	openNewTab,
} from '../../../utils'
import { getBreadcrumbsText } from '../../../utils/helpers/generators'
import { dateFormat } from '../../../utils/constants/common'
import { showIsBLockedModalForDeps } from '../../../utils/constants/for-components'

const pageUrl = '/rm/supply-requests'

const SupplyRequestItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<FileProvider>
				<Provider params={{ id: props.match.params.id }}>
					<Main title="Заявка на поставку сырья">
						<InfoComponent />
					</Main>
				</Provider>
			</FileProvider>
		</ModProvider>
	)
}
const InfoComponent = () => {
	const mainCtx = useContext(RequestItemMainContext)
	const modCtx = useContext(ModContext)
	const userDataCtx = useContext(UserDataContext)
	const authCtx = useContext(AuthContext)
	const history = useHistory()
	if (mainCtx.state.__notFound) {
		history.replace(pageUrl)
	}
	const [form] = Form.useForm()

	const showSupplyTab = checkRole(
		{
			'АРМ офиса': {
				'Поставки сырья': {
					'Основные данные': {
						view: true,
					},
				},
			},
		},
		userDataCtx.state.rights
	)
	const breadCrumb = getBreadcrumbsText(pageUrl, mainCtx.state)

	return (
		<>
			<HeaderMenu breadcrumbsTitle={breadCrumb.head} recordLabel={breadCrumb.label}>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						canEdit={mainCtx.state.status === 'Новая'}
						rights={{
							'АРМ офиса': {
								'Заявки на поставку сырья': {
									edit: true,
								},
							},
						}}
						formName="supplyRequest"
						canDelete={modCtx.mod !== modes.new}
						onCopy={mainCtx.copyRequest}
						onClose={async () => {
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							if (await mainCtx.checkIsBlocked()) {
								axios.post(`/logs`, {
									time: new Date().toISOString(),
									action: 'Edit',
									isBlockUnblockLogic: true,
									entity: 'rmSupplyRequest',
									idEntity: mainCtx.state.id,
								})
								showConfirmModal(showIsBLockedModalForDeps)

								return
							}
							await actBtnOnSaveV2({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								pageUrl: pageUrl,
								history: history,
								isShowErrorModal: true,
								form,
								showConfirmModal,
							})
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						onDelete={async () => {
							if (await mainCtx.checkIsBlocked()) {
								axios.post(`/logs`, {
									time: new Date().toISOString(),
									action: 'Delete',
									isBlockUnblockLogic: true,
									entity: 'rmSupplyRequest',
									idEntity: mainCtx.state.id,
								})
								showConfirmModal(showIsBLockedModalForDeps)

								return
							}
							await actBtnOnDelete({
								history,
								pageUrl,
								showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								getDeps: mainCtx.getDepsOn,
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
								currentRights: userDataCtx.state.rights,
							})
						}}
						onCancel={() => {
							const allFields = form.getFieldsError()
							allFields.forEach((f) => {
								f.errors = []
							})
							form.setFields(allFields)
							mainCtx.reset()
						}}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							form={form}
							name="supplyRequest"
							fields={[
								{ name: 'status', value: mainCtx.state.status },
								{ name: 'id', value: mainCtx.state.id },
								{ name: 'date', value: moment(mainCtx.state.date).format(dateFormat) },
								{
									name: 'supplyDatePlan',
									value: mainCtx.state.supplyDatePlan ? moment(mainCtx.state.supplyDatePlan) : null,
								},
								{ name: 'vehicleNum', value: mainCtx.state.vehicleNum },
								{ name: 'trailerNum', value: mainCtx.state.trailerNum },
								{ name: 'driverPhone', value: mainCtx.state.driverPhone },
								{ name: 'comment', value: mainCtx.state.comment },
								{ name: 'outdoorWh', value: mainCtx.state.outdoorWh?.label },
								{
									name: 'platform',
									value: mainCtx.state.contract ? getPlatform(mainCtx.state.contract) : '',
								},
								{ name: 'dateStart', value: mainCtx.state.dateStart },
								{ name: 'tolling', value: mainCtx.state.tolling },
							]}
							columns={[
								{ size: { xs: 24, md: 12, xl: 6, xxl: 8 }, num: 0 },
								{ size: { xs: 24, md: 12, xl: 10, xxl: 9 }, num: 1 },
								{ size: { xs: 24, md: 12, xl: 8, xxl: 7 }, num: 2 },
							]}
							items={getFormItems(mainCtx, modCtx)}
						/>
					</Col>
				</Row>
			</Card>
			<TabList
				tabs={[
					{ key: 0, title: 'Продукты', component: <ProductTab /> },
					{ key: 1, title: 'Документы', component: <DocumentsTab /> },
					...(showSupplyTab ? [{ key: 2, title: 'Поставки', component: <SupplyTab /> }] : []),
				]}
			/>
		</>
	)
}

export default SupplyRequestItem
