import { dateFormat } from '../../../../utils/constants/common'
import { colorizeIntegrationListStatus } from '../../../../utils/helpers/for-integration'
import { getRowSpan } from '../../../../utils/helpers/for-table'
import { getColumnSearchProps } from './../../../../utils/helpers/for-filter-sorter'
import moment from 'moment'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (inArr) => {
	return [
		{
			title: 'ID отчета',
			dataIndex: 'id',
			key: 'id',
			width: '6.21%',
			...getColumnSearchProps(
				inArr
					.map((el) => el.id)
					.filter((id, index, self) => self.findIndex((s) => s === id) === index),
				undefined,
				'id'
			),
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.prods?.length),
			}),
		},
		{
			title: '№ партии',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '12.54%',
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			key: 'articul',
			width: '12%',
		},
		{
			title: 'Артикул 1С',
			dataIndex: 'articul1C',
			key: 'articul1C',
			width: '12%',
		},
		{
			title: 'Продукт',
			dataIndex: 'product',
			key: 'product',
			width: '30%',
			render: (_, record) => `${record.prodCat} ${record.prodCatKind}`,
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '9%',
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '11.51%',
			...getColumnSearchProps(
				inArr
					.map((el) => el.status)
					.filter((status, index, self) => self.findIndex((s) => s === status) === index),
				undefined,
				'status'
			),
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.prods?.length),
			}),
			render: (status, record) => colorizeIntegrationListStatus(status, true),
		},
		{
			title: 'Дата',
			dataIndex: 'date',
			key: 'date',
			width: '6.74%',
			render: (date) => (date ? moment(date).format(dateFormat) : null),
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.prods?.length),
			}),
		},
	]
}
