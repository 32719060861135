import moment from 'moment'
import { CommonSelect, EditDelBtns } from '../../../components'
import { modes, openNewTab } from '../../../utils'
import { DatePicker, Input } from 'antd'
import { sliceStringAndAddThreeDots } from '@berry/front-common'

export const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 },
}

export const getColumns = (mainCtx, modCtx, authCtx, editHandler) => {
	const stateFunctions = mainCtx.stateFunctions.applications
	let isDisabled = false
	if (modCtx.mod === modes.view) isDisabled = true
	return [
		{
			title: '№ ДС',
			dataIndex: 'displayCode',
			width: '15%',
		},
		{
			title: 'Дата заключения',
			dataIndex: 'dateStart',
			width: '15%',
			render: (text, record) => (text ? moment(text).format('YYYY-MM-DD') : ''),
		},
		{
			title: 'Срок действия',
			dataIndex: 'dateEnd',
			width: '15%',
			render: (text, record) => (text ? moment(text).format('YYYY-MM-DD') : ''),
		},
		{
			title: 'Площадка',
			dataIndex: ['platform', 'displayVal'],
			width: '15%',
			render: (_, record) => {
				return sliceStringAndAddThreeDots(record.platform?.displayVal, 24)
			},
		},
		{
			title: 'Условия оплаты',
			dataIndex: ['paymentCondition', 'displayVal'],
			width: '15%',
		},
		{
			title: 'Условия доставки',
			dataIndex: ['deliveryCondition', 'displayVal'],
			width: '15%',
		},
		{
			title: null,
			key: 'action',
			width: '6%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={!isDisabled}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
						onEdit={editHandler}
						deps={mainCtx.delEditDeps?.applications?.[record.id]}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					></EditDelBtns>
				)
			},
		},
	]
}

/**
 * поля формы в модальном окне
 * @param {Object} mainCtx - главный контекст
 * @param {Object} modCtx - контекст мода
 * @param {{_uuid_: string}} params - отбьект с функциями изменения состояния вкладки
 */
export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []
	const { _uuid_ } = params
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.applications
	function disabledDate(current) {
		return current < moment(stateFunctions.get(_uuid_).dateStart)?.add(1, 'days')
	}

	const found = stateFunctions.get(_uuid_)
	return [
		[
			{
				layout: layout,
				label: '№ ДС',
				name: 'displayCode',
				required: true,
				validateStatus: mainCtx.formErrors['application.displayCode'] && 'error',
				help:
					mainCtx.formErrors['application.displayCode'] &&
					mainCtx.formErrors['application.displayCode'],

				field: (
					<Input
						maxLength={60}
						style={{ width: '100%' }}
						disabled={isDisabled}
						value={found.displayCode}
						onChange={(e) => stateFunctions.setDisplayCode(_uuid_, e.target.value)}
					/>
				),
			},
			{
				layout: layout,
				label: 'Дата заключения',
				name: 'dateStart',
				required: true,
				validateStatus: mainCtx.formErrors['application.dateStart'] && 'error',
				help:
					mainCtx.formErrors['application.dateStart'] &&
					mainCtx.formErrors['application.dateStart'],

				field: (
					<DatePicker
						style={{ width: '100%' }}
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format="DD.MM.YYYY"
						allowClear={false}
						suffixIcon={null}
						value={found.dateStart ? moment(found.dateStart) : null}
						onChange={(e) => {
							stateFunctions.setDateStart(_uuid_, e)
							if (
								stateFunctions.get(_uuid_).dateStart.add(1, 'days') >=
								stateFunctions.get(_uuid_)?.dateEnd
							) {
								stateFunctions.setDateEnd(_uuid_, '')
							}
						}}
						disabled={isDisabled}
					/>
				),
			},
			{
				layout: layout,
				label: 'Срок действия',
				name: 'dateEnd',
				required: true,
				validateStatus: mainCtx.formErrors['application.dateEnd'] && 'error',
				help:
					mainCtx.formErrors['application.dateEnd'] && mainCtx.formErrors['application.dateEnd'],
				field: (
					<DatePicker
						style={{ width: '100%' }}
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format="DD.MM.YYYY"
						allowClear={false}
						suffixIcon={null}
						value={found.dateEnd ? moment(found.dateEnd) : null}
						onChange={(e) => stateFunctions.setDateEnd(_uuid_, e)}
						disabled={isDisabled}
						disabledDate={(current) => disabledDate(current)}
					/>
				),
			},
		],
		[
			{
				layout: layout,
				label: 'Площадка',
				name: 'platform',
				required: true,
				validateStatus: mainCtx.formErrors['application.platform'] && 'error',
				help:
					mainCtx.formErrors['application.platform'] && mainCtx.formErrors['application.platform'],
				field: (
					<CommonSelect
						value={mainCtx.state.platform}
						setValue={(e) => stateFunctions.setPlatform(_uuid_, e)}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allPlatforms.filter(
							(p) =>
								(mainCtx.state.provider && mainCtx.state.provider.id === p.provider?.id) ||
								(mainCtx.state.customer && mainCtx.state.customer.id === p.customer?.id)
						)}
					/>
				),
			},
			{
				layout: layout,
				label: 'Условия оплаты',
				name: 'paymentCondition',
				required: true,
				validateStatus: mainCtx.formErrors['application.paymentCondition'] && 'error',
				help:
					mainCtx.formErrors['application.paymentCondition'] &&
					mainCtx.formErrors['application.paymentCondition'],
				field: (
					<CommonSelect
						value={mainCtx.state.paymentCondition}
						setValue={(e) => stateFunctions.setPaymentCondition(_uuid_, e)}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allSelectPayConditions}
					/>
				),
			},

			{
				layout: layout,
				label: 'Условия доставки',
				name: 'deliveryCondition',
				required: true,
				validateStatus: mainCtx.formErrors['application.deliveryCondition'] && 'error',
				help:
					mainCtx.formErrors['application.deliveryCondition'] &&
					mainCtx.formErrors['application.deliveryCondition'],
				field: (
					<CommonSelect
						value={mainCtx.state.deliveryCondition}
						setValue={(e) => stateFunctions.setDeliveryCondition(_uuid_, e)}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allSelectDeliveryConditions}
					/>
				),
			},
		],
	]
}
