import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import { ModContext, UserDataContext } from '../../../../../contexts'
import { getCommonProviderFunctions } from '../../../../../utils/helpers/generators'
import { prepareObjFromServer, modes, useItemFetchers } from '../../../../../utils'
import { basicValidator } from '@berry/common-functions/validators'
import { useHistory } from 'react-router-dom'

const dataUrl = '/stock/wastes'

export const reducer = (state) => {
	return {
		...state,
	}
}

const initialData = {
	storages: [],
	eventHistories: [],
}

const tabs = {
	'event-histories': 'eventHistories',
	storages: 'storages',
	'parties-1c': 'parties1Cs',
}

const StockWasteItemMainContext = React.createContext()
StockWasteItemMainContext.displayName = 'StockWasteItemMainContext'

// export const prepareWaste = (findParams, dataServerCtx) => {
// 	const found = dataServerCtx.findRecord(findParams, { withError: false })
// 	return initializeWaste(found, dataServerCtx)
// }
const Provider = (props) => {
	const { children, params } = props

	const {
		state: { isDevAdmin },
	} = useContext(UserDataContext)

	const modCtx = useContext(ModContext)
	const [state, dispatch] = React.useReducer(reducer, {
		data: initialData,
		oldData: initialData,
		additional: {
			allSelectsProdTasks: [],
		},
		formErrors: [],
		isInitializedMain: false,
		isInitializedAdditional: false,
		tabsLoading: {
			eventHistories: false,
			storages: false,
			parties1Cs: false,
		},
	})
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))
	const requiredFields = {
		storages: ['status'],
	}

	const validate = () => {
		for (const obj in requiredFields) {
			for (const el of stateRef.current.data[obj]) {
				if (requiredFields[obj]?.some((f) => !basicValidator(el[f]))) return 'error'
			}
		}
		return ''
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			formErrors: {},
		})
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * подготавливает данные которые нужно выбират
	 */

	const selectors = {
		storages: {
			status: ['Хранение', 'Утилизировано', ...(isDevAdmin ? ['Сработано'] : [])],
		},
	}

	const { isEdited, stateFunctions, serverEdit, serverDelete, setError } =
		getCommonProviderFunctions(
			stateRef,
			stateRef.current.oldData,
			executeDispatch,
			{ modCtx, dataUrl, params, pageUrl: '/stock/wastes', history },
			selectors,
			{},
			{
				storages: {
					status: 'common',
				},
			}
		)

	const value = {
		state: lodash.cloneDeep(stateRef.current.data),
		isPendingReq: stateRef.current.isPendingReq,
		tabsLoading: stateRef.current.tabsLoading,
		additional: stateRef.current.additional,
		stateFunctions,
		serverEdit,
		serverDelete,
		isEdited,
		validate,
		reset,
		formErrors: stateRef.current.formErrors,
		selectors,
		setError,
	}

	return (
		<StockWasteItemMainContext.Provider value={value}>
			{children}
		</StockWasteItemMainContext.Provider>
	)
}

export { Provider, StockWasteItemMainContext }
