import { LeftTooltip, Status } from '../../components'

export const colorizeIntegrationStatus = (status) => {
	return {
		label:
			status === 'Ошибка отправки в 1С' ? (
				<LeftTooltip
					label="Статус"
					tooltip="Обратитесь в службу поддержки по адресу support_fragarea@it-intevo.ru"
				/>
			) : (
				'Статус'
			),
		field: <Status status={status} />,
	}
}

export const colorizeIntegrationListStatus = (status, tooltip = false, style = {}) => {
	if (status === 'Ошибка отправки в 1С')
		return (
			<>
				{tooltip ? (
					<LeftTooltip
						label={''}
						tooltip="Обратитесь в службу поддержки по адресу support_fragarea@it-intevo.ru"
					/>
				) : null}
				<Status style={style} status={status} />
			</>
		)
	return <Status status={status} style={style} />
}
