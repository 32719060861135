import React from 'react'
import { Spin } from 'antd'

const Spinner = (props) => {
	const { size = 'middle', center = false } = props
	return (
		<Spin
			size={size}
			style={
				center
					? {
							margin: '0 auto',
					  }
					: null
			}
		/>
	)
}

export default Spinner
