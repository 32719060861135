import { Space } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

export const getColumns = (ctx) => [
	{
		title: 'Должность',
		dataIndex: 'label',
		key: 'label',
		width: '65%',
	},
	{
		title: 'Пользователь',
		dataIndex: 'isUser',
		key: 'isUser',
		width: '35%',
		render: (isUser) =>
			isUser && (
				<Space size="middle">
					<CheckOutlined />
				</Space>
			),
	},
]
