import { CheckOutlined } from '@ant-design/icons'
import { Space } from 'antd'

export const columns = [
	{
		title: 'Артикул',
		key: 'displayCode',
		width: '20%',
		dataIndex: 'displayCode',
	},
	{
		title: 'Форма',
		dataIndex: 'label',
		key: 'label',
		width: '30%',
	},
	{
		title: 'Краткое наименование',
		dataIndex: 'labelShort',
		key: 'labelShort',
		width: '30%',
	},
	{
		title: 'Привязать калибр',
		dataIndex: 'isBindCaliber',
		key: 'isBindCaliber',
		width: '10%',
		render: (isBindCaliber) =>
			isBindCaliber && (
				<Space size="middle">
					<CheckOutlined />
				</Space>
			),
	},
]
