import { Button, Card, Col, Input, Modal, Row, Table, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { getDisplayColumns } from './utils'
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/close.svg'
import { applyFilterForList, getWidth, searchFields } from '../utils'

const ParameterAddModal = ({ isOpen, setIsOpen, mainCtx }) => {
	const { selectors } = mainCtx
	const [search, setSearch] = useState('')
	const initialDataSource = selectors.parameters.vocSpecParamParam
	const [dataSource, setDataSource] = useState([])
	const [params, setParams] = useState([])
	const [columns, setColumns] = useState([])
	useEffect(() => {
		if (search) {
			setDataSource(applyFilterForList(initialDataSource, { search, searchFields }))
		} else {
			setDataSource(initialDataSource)
		}
	}, [initialDataSource, search, selectors])
	useEffect(() => {
		setColumns(getDisplayColumns(mainCtx, dataSource, params, setParams))
	}, [dataSource, mainCtx, params])
	return (
		<Modal
			visible={isOpen}
			centered
			destroyOnClose
			footer={null}
			closable={false}
			width={getWidth()}
			title={
				<Card
					bodyStyle={{
						margin: 0,
						padding: 0,
					}}
					bordered={false}
				>
					<Row align="middle" gutter={[16, 16]}>
						<Col flex={1}>
							<Typography.Title level={4}>Параметры спецификации</Typography.Title>
						</Col>
						<Col>
							<Input.Search
								onSearch={(val) => {
									setSearch(val)
								}}
								size="large"
								allowClear
								enterButton
							/>
						</Col>
						<Col>
							<Button
								type="primary"
								size="large"
								onClick={() => {
									if (params.length) {
										mainCtx.stateFunctions.parameters.create(params)
										setIsOpen(false)
									} else {
										Modal.warning({
											centered: true,
											content: 'Не выбран ни один параметр',
											keyboard: true,
										})
									}
								}}
							>
								Добавить в спецификацию
							</Button>
						</Col>
						<Col>
							<Button
								type="text"
								size="large"
								style={{ lineHeight: 0.8 }}
								icon={<CloseIcon />}
								onClick={() => {
									setIsOpen(false)
								}}
							/>
						</Col>
					</Row>
				</Card>
			}
		>
			<Table
				rowKey="id"
				dataSource={
					search
						? applyFilterForList(selectors.parameters.vocSpecParamParam, { search, searchFields })
						: initialDataSource
				}
				scroll={{ x: 800 }}
				columns={columns}
				size="small"
				pagination={false}
				onChange={(_, filter, orderParams, action) => {
					setColumns(getDisplayColumns(mainCtx, action.currentDataSource, params, setParams))
				}}
			/>
		</Modal>
	)
}

export default ParameterAddModal
