import {
	Checkbox,
	TimePicker,
	Input,
	Typography,
	Select,
	Col,
	Radio,
	Row,
	Card,
	DatePicker,
} from 'antd'
import lodash from 'lodash'
import { modes } from '../../../utils'
import moment from 'moment'
import { getColumns } from './SupplyRequestModalList/supply-request-list'
import { CommonSelect, showConfirmModal, TableWithListLoader } from '../../../components'
import { dateFormat } from '../../../utils/constants/common'
import SelectModal from '../../../components/SelectModal/SelectModal'
import { isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'

const { Title } = Typography

const layoutMid = {
	labelCol: { xs: 24, sm: 8, xl: 10, xxl: 10 },
	wrapperCol: { xs: 24, sm: 14, xl: 9, xxl: 6 },
}

const layoutLeft = {
	labelCol: { xs: 24, sm: 8, xl: 9 },
	wrapperCol: { xs: 24, sm: 14, xl: 15 },
}

const layoutRight = {
	labelCol: { xs: 24, sm: 8, xl: 12 },
	wrapperCol: { xs: 24, sm: 14, xl: 12 },
}
/**
 * Получить значение поля площадка
 * @param {Object} inContract
 */

const getPlatforms = (inState) => {
	if (!inState?.products.length) {
		return []
	}
	return [
		...new Set(
			inState?.products
				.map((prod) => {
					return (
						(prod.contrQuota || prod.order || prod)?.contract?.platform?.label ||
						(prod.contrQuota || prod.order)?.application?.platform?.label
					)
				})
				.filter((el) => el)
		),
	].join(', ')
}

const concatenateDateTime = (mainCtx, time) => {
	if (time === null) {
		return null
	}
	const t = moment(time).format('HH:mm:ss')
	const d = moment(mainCtx.state.date).format('YYYY-MM-DD')
	let isoTimestamp = `${d}T${t}`
	const unldTime = moment(isoTimestamp).format()
	return unldTime
}

export const getFormItems = (mainCtx, modCtx, form, supReqItem, setSupReqItem) => {
	const isDisabled =
		modCtx.mod === modes.view ||
		mainCtx.state.invoices.some((inv) => ['Отправлено в 1С', 'Принято в 1С'].includes(inv.status))

	const TableForSelectComplect = (props) => {
		const url = '/rm/requests'
		const calcColumns = (props) => {
			const columns = getColumns(props)
			return [
				{
					title: '',
					key: 'supReqItem',
					width: '3%',
					render: (_, record) => {
						return (
							<Checkbox
								checked={record.id === supReqItem.data?.id && supReqItem.data?.checked}
								onChange={(e) => {
									const checkedItem = lodash.cloneDeep(record)
									checkedItem.checked = e.target.checked
									checkedItem.checked
										? setSupReqItem({ ...supReqItem, data: { ...checkedItem } })
										: setSupReqItem({ ...supReqItem, data: {} })
									form.setFieldsValue({
										supplyReq: e.target.checked ? checkedItem.id : null,
										vehicleNum: e.target.checked ? checkedItem.vehicleNum : null,
									})
								}}
							/>
						)
					},
				},
				...columns,
			]
		}
		const filters = {}
		const defaultSorter = {}
		const permanentFilters = { include: { status: ['Новая'] }, exclude: {} }

		return (
			<TableWithListLoader
				url={url}
				calcColumns={calcColumns}
				filters={filters}
				defaultSorter={defaultSorter}
				permanentFilters={permanentFilters}
				rowKey="id"
			/>
		)
	}
	return [
		[
			{
				field: <Title level={5}>Основные данные</Title>,
				key: 'main',
			},
			{ layout: layoutLeft, label: 'ID', field: mainCtx.state?.id, key: 'id' },
			{
				key: 'date',
				layout: layoutLeft,
				label: 'Дата поставки',
				name: 'date',
				field: (
					<DatePicker
						disabled={isDisabled}
						name="date"
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format={dateFormat}
						allowClear={false}
						suffixIcon={null}
						value={
							!mainCtx.state.date
								? null
								: moment.isMoment(mainCtx.state.date)
								? mainCtx.state.date
								: moment(mainCtx.state.date, 'YYYY-MM-DD')
						}
						onChange={(date) => {
							date = moment(date).format('YYYY-MM-DD')
							mainCtx.stateFunctions.setDate(date)
						}}
					/>
				),
			},
			{
				key: 'isHasReq',
				layout: layoutLeft,
				label: 'Наличие заявки',
				field: (
					<Radio.Group
						disabled={isDisabled}
						defaultValue={mainCtx.state.isHasReq}
						onChange={(e) => {
							mainCtx.stateFunctions.setIsHasReq(e.target.value)
						}}
					>
						<Radio value={true}>Да</Radio>
						<Radio value={false}>Нет</Radio>
					</Radio.Group>
				),
			},
			{
				field: <Title level={5}>Транспортное средство</Title>,
				key: 'transport',
			},
			{
				key: 'vehicleNum',
				layout: layoutLeft,
				label: '№ машины/прицепа',
				field:
					mainCtx.state?.supplyReq?.vehicleNum && mainCtx.state?.supplyReq?.trailerNum
						? `${mainCtx.state?.supplyReq.vehicleNum} / ${mainCtx.state?.supplyReq.trailerNum}`
						: '',
			},
			{
				key: 'driverPhone',
				layout: layoutLeft,
				label: 'Телефон водителя',
				field: mainCtx.state?.supplyReq?.driverPhone
					? '+' + mainCtx.state?.supplyReq?.driverPhone
					: '',
			},
			{
				layout: {
					labelCol: { xs: 24, sm: 8, xl: 9 },
					wrapperCol: { xs: 24, sm: 14, xl: 7, xxl: 5 },
				},
				key: 'arriveTime',
				label: 'Время приезда машины',
				name: 'arriveTime',
				field: (
					<TimePicker
						popupClassName="time-picker-popup-height"
						inputReadOnly
						value={mainCtx.state.arriveTime ? moment(mainCtx.state.arriveTime) : null}
						placeholder="ХХ:ХХ"
						format="HH:mm"
						onChange={(e) => {
							mainCtx.stateFunctions.setArriveTime(e)
						}}
						style={{ width: '100%' }}
						disabled={isDisabled}
					/>
				),
			},
			{
				key: 'isAcceptFromZagotovka',
				layout: layoutLeft,
				label: 'Приемка с пункта заготовки',
				field: (
					<Checkbox
						disabled={isDisabled}
						checked={mainCtx.state?.isAcceptFromZagotovka}
						onChange={(e) => {
							mainCtx.stateFunctions.setIsAcceptFromZagotovka(e.target.checked)
						}}
					/>
				),
			},
		],
		[
			{ key: 'second-col' },
			{
				key: 'supplyReq',
				layout: layoutMid,
				label: 'Номер заявки',
				name: 'supplyReq',
				required: true,
				rules: [{ required: true }],
				field: (
					<Select
						dropdownStyle={{ display: 'none' }}
						onClick={async () => {
							if (
								isDisabled ||
								mainCtx.state?.invoices?.some((inv) => inv.status === 'Отправлено в 1С')
							)
								return
							if (!supReqItem.visible) {
								if (mainCtx.state.products.length) {
									return showConfirmModal({
										title:
											'При смене заявки все данные карточек Поставки сырья/Входной контроль/Разгрузка будут удалены',
										onOk: () => {
											setSupReqItem({
												...supReqItem,
												data: { ...mainCtx.state.supplyReq, checked: true },
												visible: true,
											})
										},
										okText: 'Ок',
										width: '300',
										cancelText: 'Отмена',
									})
								} else {
									setSupReqItem({
										...supReqItem,
										data: { ...mainCtx.state.supplyReq, checked: true },
										visible: true,
									})
								}
							}
						}}
						dropdownRender={() => {
							if (mainCtx.state.invoices?.every((inv) => inv.status !== 'Отправлено в 1С')) {
								return (
									<SelectModal
										visible={supReqItem.visible}
										title="Заявка"
										content={
											<Card style={{ marginBottom: 10 }} bodyStyle={{ padding: 0 }}>
												<Row>
													<Col span="24">
														<TableForSelectComplect
															mainCtx={mainCtx}
															size="small"
															rowClassName="clickable"
															scroll={{ y: 700 }}
															pagination={false}
														></TableForSelectComplect>
													</Col>
												</Row>
											</Card>
										}
										onOk={() => {
											if (!supReqItem.data.checked) {
												return showConfirmModal({
													title: 'Не выбрана заявка',
													showCancel: false,
													okText: 'Ок',
												})
											}
											mainCtx.stateFunctions.setSupplyReq(supReqItem.data)
											setSupReqItem({ ...supReqItem, visible: false })
										}}
										onClose={() => {
											if (mainCtx.state.supplyReq) {
												setSupReqItem({
													data: { ...mainCtx.state.supplyReq, checked: true },
												})
											} else setSupReqItem({ data: {} })
										}}
									/>
								)
							}
						}}
						disabled={isDisabled}
						notFoundContent={<Col>Не найдено</Col>}
					/>
				),
			},
			{
				key: 'supplyReqDate',
				layout: layoutMid,
				label: 'Дата заявки',
				field: mainCtx.state.supplyReq?.date
					? moment(mainCtx.state.supplyReq?.date).format(dateFormat)
					: null,
			},
			{ key: 'third-col' },
			{ key: 'col-two-gap-one' },
			{
				key: 'isSeal',
				layout: layoutMid,
				label: 'Наличие пломбы',
				field: (
					<Radio.Group
						disabled={isDisabled}
						defaultValue={mainCtx.state.isSeal}
						onChange={(e) => {
							mainCtx.stateFunctions.setIsSeal(e.target.value)
						}}
					>
						<Radio value={true}>Да</Radio>
						<Radio value={false}>Нет</Radio>
					</Radio.Group>
				),
			},
			...(mainCtx.state.isSeal
				? [
						{
							key: 'isSeal',
							layout: layoutMid,
							label: '№ пломбы на контейнере',
							name: 'sealNum',
							required: mainCtx.state.isSeal,
							rules: [{ required: mainCtx.state.isSeal }],
							field: (
								<Input
									disabled={isDisabled || !mainCtx.state.isSeal}
									onChange={(e) => {
										mainCtx.stateFunctions.setSealNum(e.target.value)
									}}
								/>
							),
						},
				  ]
				: []),
			[
				{
					key: 'temperSymb',
					layout: {
						labelCol: { xs: 24, sm: 16, xl: 19 },
						wrapperCol: { xs: 24, sm: 4, xl: { offset: 1, span: 1 } },
					},
					label: '℃ в машине перед разгрузкой',
					rules: [{ required: !mainCtx.state.noCooling && !mainCtx.state.defrost }],
					name: 'temperSymb',
					dependencies: ['noCooling', 'defrost'],
					field: (
						<Select
							style={{ minWidth: 65 }}
							value={mainCtx.state.temperSymb}
							disabled={isDisabled}
							notFoundContent={<Col>Не найдено</Col>}
							onChange={(e) => {
								mainCtx.stateFunctions.setTemperSymb(e)
							}}
						>
							{['+', '-'].map((it) => (
								<Select.Option key={it} value={it}>
									{it}
								</Select.Option>
							)) || []}
						</Select>
					),
				},
				{
					name: 'temperVal',
					layout: {
						wrapperCol: {
							xs: 24,
							sm: { pull: 3, span: 4 },
							xl: { span: 6, offset: 8, pull: 0 },
							xxl: { span: 5, offset: 3, pull: 0 },
						},
					},
					dependencies: ['temperSymb'],
					rules: [{ required: !!mainCtx.state.temperSymb }],
					field: (
						<Input
							value={mainCtx.state.temperVal}
							disabled={isDisabled}
							onChange={(e) => {
								if (
									!isValidNum(e.target.value) ||
									!isValidNumberDigits(e.target.value, 'float', [3, 1]) ||
									e.target.value?.includes('-')
								) {
									mainCtx.stateFunctions.setTemperVal(mainCtx.state.temperVal || null)
								} else {
									mainCtx.stateFunctions.setTemperVal(e.target.value)
								}
							}}
						/>
					),
				},
			],
			{
				key: 'noCooling',
				layout: layoutMid,
				label: 'Без охлаждения',
				name: 'noCooling',
				rules: [],
				field: (
					<Checkbox
						disabled={isDisabled}
						checked={mainCtx.state?.noCooling}
						onChange={(e) => {
							mainCtx.stateFunctions.setNoCooling(e.target.checked)
						}}
					/>
				),
			},
			{
				key: 'defrost',
				layout: layoutMid,
				label: 'Оттайка',
				name: 'defrost',
				rules: [],
				field: (
					<Checkbox
						disabled={isDisabled}
						checked={mainCtx.state?.defrost}
						onChange={(e) => {
							mainCtx.stateFunctions.setDefrost(e.target.checked)
						}}
					/>
				),
			},
		],
		[
			{ key: 'col-three' },
			{
				key: 'staff',
				layout: layoutRight,
				label: 'ФИО проверяющего',
				name: 'staff',
				required: true,
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.staff}
						setValue={mainCtx.stateFunctions.setStaff}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.staff}
					/>
				),
			},
			{
				key: 'contractProviderLabel',
				layout: layoutRight,
				label: 'Поставщик',
				field: [
					...new Set(
						mainCtx.state.products.map(
							(p) => p.contrQuota?.contract?.provider?.label || p.order.contract.provider.label
						)
					),
				].join(', '),
			},
			{
				key: 'contractPlatformLabel',
				layout: layoutRight,
				label: 'Площадка',
				field: getPlatforms(mainCtx.state),
			},
			{ key: 'col-three-gap-one' },
			{
				key: 'carState',
				layout: layoutRight,
				label: 'Санитарное состояние ТС',
				name: 'carState',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.carState}
						setValue={mainCtx.stateFunctions.setCarState}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allSelectCarStates}
					/>
				),
			},
			{
				layout: layoutRight,
				label: 'Время начала разгрузки',
				name: 'unldTimeStart',
				rules: [{ required: true }],
				field: (
					<TimePicker
						popupClassName="time-picker-popup-height"
						style={{ width: '100%' }}
						value={mainCtx.state.unldTimeStart ? moment(mainCtx.state.unldTimeStart) : null}
						format={'HH:mm'}
						disabled={isDisabled}
						onChange={(e) =>
							mainCtx.stateFunctions.setUnldTimeStart(concatenateDateTime(mainCtx, e))
						}
					/>
				),
			},
			{
				layout: layoutRight,
				label: 'Время окончания разгрузки',
				name: 'unldTimeStop',
				rules: [{ required: true }],
				field: (
					<TimePicker
						popupClassName="time-picker-popup-height"
						style={{ width: '100%' }}
						format={'HH:mm'}
						value={mainCtx.state.unldTimeStop ? moment(mainCtx.state.unldTimeStop) : null}
						disabled={isDisabled}
						onChange={(e) =>
							mainCtx.stateFunctions.setUnldTimeStop(concatenateDateTime(mainCtx, e))
						}
					/>
				),
			},
		],
	]
}
