const atLeastSymbolRegExp =
	'[\\!"\\#\\$\\%\\&\\\'\\(\\)\\*\\+\\,\\-\\.\\/\\:\\;\\<\\>\\=\\?\\@\\[\\]\\{\\}\\\\^\\_\\`\\~]'
const atLeastLatinRegExp = '[a-zA-Z]'

export const testAtLeastSymbolRegExp = (inString) => {
	const regExp = new RegExp(atLeastSymbolRegExp)
	return regExp.test(inString)
}

export const testAtLeastLatinRegExp = (inString) => {
	const regExp = new RegExp(atLeastLatinRegExp)
	return regExp.test(inString)
}
