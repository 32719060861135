import { Checkbox, Input } from 'antd'
import { EditDelBtns } from '../../../../../components'
import { modes, openNewTab } from '../../../../../utils'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 * @param {Object} modCtx
 * @param {function} editHandler - обработка нажатия на карандаш
 */
export const getDisplayColumns = (mainCtx, modCtx, authCtx, editHandler) => {
	const stateFunctions = mainCtx.stateFunctions.kinds
	let isDisabled = false
	if (modCtx.mod === modes.view) isDisabled = true
	return [
		{
			title: 'Вид основного ПФ',
			dataIndex: 'label',
			key: 'label',
			width: '30%',
		},
		{
			title: 'Артикул',
			dataIndex: 'displayCode',
			key: 'displayCode',
			width: '16%',
		},
		{
			title: 'Привязать калибр',
			dataIndex: 'isCaliber',
			key: 'isCaliber',
			render: (value) => {
				return <Checkbox disabled checked={value}></Checkbox>
			},
			width: '22%',
		},
		{
			title: 'Привязать доп. признак',
			dataIndex: 'isAdditAttr',
			key: 'isAdditAttr',
			render: (value) => {
				return <Checkbox disabled checked={value}></Checkbox>
			},
			width: '22%',
		},
		{
			title: null,
			key: 'action',
			width: '4.5%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={!isDisabled}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
						onEdit={editHandler}
						deps={mainCtx.delEditDeps?.kinds?.[record.id]}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					></EditDelBtns>
				)
			},
		},
	]
}

const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 },
}

/**
 * поля формы в модальном окне
 * @param {Object} mainCtx - главный контекст
 * @param {Object} modCtx - контекст мода
 * @param {{_uuid_: string}} params - отбьект с функциями изменения состояния вкладки
 */
export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []
	const { _uuid_ } = params
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.kinds
	const currentItem = stateFunctions.get(_uuid_)
	return [
		[
			{
				layout: layout,
				label: 'Вид основного ПФ',
				name: 'label',
				rules: [
					{ required: true },
					{
						validator: (_, value) => {
							if (value) {
								const hasDuplicate = mainCtx.state.kinds.some(
									(k) => k.label.toLowerCase() === value.toLowerCase() && k.id !== currentItem.id
								)
								if (hasDuplicate) {
									return Promise.reject('Данный вид уже добавлен')
								}
							}
							return Promise.resolve()
						},
					},
				],
				field: (
					<Input
						style={{ width: '100%' }}
						value={currentItem.label}
						disabled={isDisabled}
						onChange={(e) => {
							stateFunctions.setLabel(_uuid_, e.target.value)
						}}
					/>
				),
			},
			{
				layout: layout,
				label: 'Привязать калибр',
				field: (
					<Checkbox
						disabled={isDisabled}
						checked={currentItem.isCaliber}
						onChange={(e) => stateFunctions.setIsCaliber(_uuid_, e.target.checked)}
					/>
				),
				name: 'isCaliber',
			},
			{
				layout: layout,
				label: 'Привязать доп. признак',
				field: (
					<Checkbox
						disabled={isDisabled}
						checked={stateFunctions.get(_uuid_).isAdditAttr}
						onChange={(e) => stateFunctions.setIsAdditAttr(_uuid_, e.target.checked)}
					></Checkbox>
				),
				name: 'isAdditAttr',
			},
		],
	]
}
