import React, { useContext, useEffect, useState } from 'react'
import { useInput } from '../../../utils'
import { addData, deleteData, editData } from '../../../services/api/requests'
import Wrapper from '../Wrapper/Wrapper'
import { columns } from './columns'
import Form from './Form'
import { getDataForVocs } from '../AdditionalSemiType/AdditionalSemiType'
import { SyncDepsContext } from '../../../contexts'

const vocUrl = '/voc/editable/block-cause'

const BlockCause = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, setState] = useState([])
	const [recordId, setRecordId] = useState(null)
	const [statusVal, setStatusVal] = useState()
	const [label, resetLabel] = useInput('')

	useEffect(() => {
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
	}, [syncDepsCtx.state.reloadUuids['office-ms']])

	const status = {
		onChange: (e) => {
			let statusVal
			if (e.target?.value) statusVal = e.target.value
			else {
				statusVal = e
			}
			setStatusVal(statusVal)
		},
		value: statusVal,
	}

	const resetFields = () => {
		resetLabel()
		setStatusVal()
	}

	const addItem = async (successFn = (fn) => fn) => {
		const response =
			(await addData(vocUrl, {
				data: {
					label: label.value,
					status: status.value,
				},
			})) || {}
		setState((prev) => [...prev, response.data.result.data])
		successFn()
	}

	const editItem = async (successFn = (fn) => fn) => {
		const response = await editData(vocUrl, {
			recordId,
			data: {
				id: recordId,
				label: label.value,
				status: status.value,
			},
		})
		setState((prev) => {
			const foundIndex = prev.findIndex((el) => el.id === response.data.result.data.id)
			return [
				...prev.slice(0, foundIndex),
				response.data.result.data,
				...prev.slice(foundIndex + 1),
			]
		})
		successFn()
	}

	const deleteItem = async (id) => {
		const response = await deleteData(vocUrl, {
			id,
		})
		setState((prev) => {
			const foundIndex = prev.findIndex((el) => el.id === +response.data.result.id)
			return [...prev.slice(0, foundIndex), ...prev.slice(foundIndex + 1)]
		})
		setRecordId(null)
	}

	return (
		<Wrapper
			rights={{
				Справочники: {
					'Причины блокировки партии в отчете о производстве': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Причины блокировки партии в отчете о производстве"
			model="voc_editable_block_cause"
			canCreate
			canUpdate
			canDelete
			data={state}
			recordId={recordId}
			setRecordId={setRecordId}
			addData={addItem}
			editData={editItem}
			deleteData={deleteItem}
			columns={columns}
			resetFields={resetFields}
			fields={[{ label }, { status }]}
			validateFields={[{ label }, { status }]}
			uniqueFields={[]}
			form={<Form label={label} status={status} id={recordId} />}
			vocUrl={vocUrl}
			isCheckDepsOnEdit={false}
		/>
	)
}

export default BlockCause
