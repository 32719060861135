import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { axios } from '../../utils'
import { AuthContext } from '../index'

export const reducer = (state) => {
	return {
		...state,
	}
}

const url = '/user-data'
const initialState = { isLoading: false, rights: {}, match: {}, isInitialized: false }

const UserDataContext = React.createContext()
UserDataContext.displayName = 'UserDataContext'

const Provider = (props) => {
	const { children } = props
	const {
		state: { isAuthenticated },
	} = useContext(AuthContext)
	const isInitialMount = useRef(true)
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...stateRef.current, ...newState }
		dispatch(newState)
	}

	const getAll = useCallback(() => {
		const getRights = async () => {
			try {
				executeDispatch({
					...stateRef.current,
					isLoading: true,
				})
				const {
					data: { rights, match, fullName, isAdmin, isDevAdmin },
				} = await axios(url)

				if (isAuthenticated) {
					executeDispatch({
						isLoading: false,
						isInitialized: true,
						rights,
						match,
						fullName,
						isAdmin,
						isDevAdmin,
					})
				}
			} catch (err) {
				if (isAuthenticated) {
					executeDispatch(initialState)
				}
			}
		}
		try {
			getRights()
		} catch (err) {
			console.log(err)
		}
	}, [isAuthenticated])

	// каждый раз когда происходит обновление зависимостей ,
	// исключая инициализационный маунт
	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false
		} else {
			const dropAll = () => {
				executeDispatch(initialState)
			}
			dropAll()
		}
	}, [isAuthenticated])

	// Каждый раз когда происходит обновление зависимостей
	// вместе с инициализационным маунтом
	useEffect(() => {
		const autoGetAll = () => {
			if (isAuthenticated) {
				getAll()
			}
		}
		autoGetAll()
	}, [getAll, isAuthenticated])

	/**
	 * Обновляет полностью объект
	 * @param {Object} inData - обновленный объект
	 * @param {{url:string}} params - параметры
	 */
	const updateUserData = (inData, params) => {
		const { rights, match, fullName, isAdmin, isDevAdmin } = inData
		executeDispatch({
			...stateRef.current,
			[params.url]: {
				...stateRef.current[params.url],
				rights,
				match,
				fullName,
				isAdmin,
				isDevAdmin,
			},
		})
	}
	const value = {
		state: stateRef.current,
		getAll,
		updateUserData,
	}
	return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>
}

export { Provider, UserDataContext }
