import { useEffect } from 'react'

const useSetZoom = () => {
	useEffect(() => {
		let zoom = 1
		const percentOS = window.devicePixelRatio * 100
		if (percentOS > 100) {
			zoom = zoom - (percentOS * 100 - 10000) / percentOS / 100
		}
		document.body.style.zoom = zoom
	}, [])
}

export default useSetZoom
