module.exports = [
	{
		id: 1,
		label: 'Цех',
	},
	{
		id: 2,
		label: 'Склад',
	},
	{
		id: 3,
		label: 'Лаборатория',
	},
	{
		id: 4,
		label: 'Инженерная служба',
	},
]
