const entityToModelMap = {
	blockedEntity: 'blocked_entity',
	rmRpContact: 'rm_rp_contact',
	application: 'application',
	contrProvOrder: 'contr_prov_order',
	rpRwContract: 'rp_rw_contract',
	contrCustomerQuota: 'contr_customer_quota',
	cstmrQuotaMnthLstng: 'cstmr_quota_mnth_lstng',
	provQuotaMnthLstng: 'prov_quota_mnth_lstng',
	contrProviderQuota: 'contr_provider_quota',
	consBalProd: 'cons_bal_prod',
	consRepBal: 'cons_rep_bal',
	consRepEventHistory: 'cons_rep_event_history',
	consRepProd: 'cons_rep_prod',
	consRepRep: 'cons_rep_rep',
	giverReportProduced: 'giver_report_produced',
	implmnt: 'implmnt',
	implmntEventHistory: 'implmnt_event_history',
	implmntProd: 'implmnt_prod',
	otherData: 'other_data',
	rpRmPlatform: 'rp_rm_platform',
	prodCatAdditAttr: 'prod_cat_addit_attr',
	prodCatCaliber: 'prod_cat_caliber',
	prodCatKindRawMat: 'prod_cat_kind_raw_mat',
	prodCatKindReadyProd: 'prod_cat_kind_ready_prod',
	prodCatKindSemif: 'prod_cat_kind_semif',
	prodCatPackage: 'prod_cat_package',
	productCatalog: 'product_catalog',
	prodCatQuality: 'prod_cat_quality',
	prdctnTaskComplect: 'prdctn_task_complect',
	prdctnTaskEventHistory: 'prdctn_task_event_history',
	prodRepBlockCause: 'prod_rep_block_cause',
	prdctnTaskReport: 'prdctn_task_report',
	prdctnTaskReportBi: 'prdctn_task_report_bi',
	prdctnTask: 'prdctn_task',
	prdctnTaskNum: 'prdctn_task_num',
	rmProviderProd: 'rm_provider_prod',
	rmProvider: 'rm_provider',
	rmSupplDoc: 'rm_suppl_doc',
	rmSupplInvoice: 'rm_suppl_invoice',
	supplLabIndicPhoto: 'suppl_lab_indic_photo',
	supplLabIndic: 'suppl_lab_indic',
	rmSupplyEventHistory: 'rm_supply_event_history',
	rmSupplProdUnloadPalDistr: 'rm_suppl_prod_unload_pal_distr',
	suplProdEventHistory: 'supl_prod_event_history',
	rmSupplProd: 'rm_suppl_prod',
	rmSupply: 'rm_supply',
	rmSupplProdUnloadStorage: 'rm_suppl_prod_unload_storage',
	rmSupplyDocFile: 'rm_supply_doc_file',
	rmSupplyReqDoc: 'rm_supply_req_doc',
	rmSupplReqProd: 'rm_suppl_req_prod',
	rmSupplyRequest: 'rm_supply_request',
	rpCustomer: 'rp_customer',
	specEventHistory: 'spec_event_history',
	paramPhoto: 'param_photo',
	specParam: 'spec_param',
	specComment: 'spec_comment',
	regSpecification: 'reg_specification',
	regStaff: 'reg_staff',
	relocRemoteWhEventHistory: 'reloc_remote_wh_event_history',
	relocRemoteWarehouseProd: 'reloc_remote_warehouse_prod',
	relocRemoteWarehouse: 'reloc_remote_warehouse',
	relocDefectEventHistory: 'reloc_defect_event_history',
	relocDefectProd: 'reloc_defect_prod',
	relocDefect: 'reloc_defect',
	disposalEventHistory: 'disposal_event_history',
	disposalProd: 'disposal_prod',
	disposal: 'disposal',
	stockRawMatEventHistory: 'stock_raw_mat_event_history',
	stockRawMatStorageBlockCause: 'stock_raw_mat_storage_block_cause',
	stockRawMatCostPrice: 'stock_raw_mat_cost_price',
	stockLabIndicPhoto: 'stock_lab_indic_photo',
	stockRawMatLabIndic: 'stock_raw_mat_lab_indic',
	stockRawMatStorage: 'stock_raw_mat_storage',
	stockRawMat: 'stock_raw_mat',
	stockReadyProdEventHistory: 'stock_ready_prod_event_history',
	stockReadyProdStorageBlockCause: 'stock_ready_prod_storage_block_cause',
	stockReadyProdStorage: 'stock_ready_prod_storage',
	stockReadyProd: 'stock_ready_prod',
	stockReadyProdResaleEventHistory: 'stock_ready_prod_resale_event_history',
	stockReadyProdResaleCostPrice: 'stock_ready_prod_resale_cost_price',
	stockReadyProdResaleLabIndicPhoto: 'stock_ready_prod_resale_lab_indic_photo',
	stockReadyProdResaleLabIndic: 'stock_ready_prod_resale_lab_indic',
	stockReadyProdResaleStorageBlockCause: 'stock_ready_prod_resale_storage_block_cause',
	stockReadyProdResaleStorage: 'stock_ready_prod_resale_storage',
	stockReadyProdResale: 'stock_ready_prod_resale',
	stockSampleEventHistory: 'stock_sample_event_history',
	stockSampleStorage: 'stock_sample_storage',
	stockSample: 'stock_sample',
	stockSemifEventHistory: 'stock_semif_event_history',
	stockSemifStorageBlockCause: 'stock_semif_storage_block_cause',
	stockSemifStorage: 'stock_semif_storage',
	stockSemif: 'stock_semif',
	stockWasteEventHistory: 'stock_waste_event_history',
	stockWasteParty1c: 'stock_waste_party_1c',
	stockWasteStorage: 'stock_waste_storage',
	stockWaste: 'stock_waste',
	vocCommonMeasure: 'voc_common_measure',
	vocEditableAdditionalSemiType: 'voc_editable_additional_semi_type',
	vocEditableBlockCause: 'voc_editable_block_cause',
	vocCarState: 'voc_car_state',
	vocEditableCauseOfRejectRep: 'voc_editable_cause_of_reject_rep',
	vocEditableContainerType: 'voc_editable_container_type',
	vocEditableDeliveryCondition: 'voc_editable_delivery_condition',
	vocEditableOutdoorWarehouse: 'voc_editable_outdoor_warehouse',
	vocDocumentation: 'voc_documentation',
	vocEditableEquipment: 'voc_editable_equipment',
	vocMainSemiKindKind: 'voc_main_semi_kind_kind',
	vocEditableMainSemiKind: 'voc_editable_main_semi_kind',
	vocEditableManufactoryPurpose: 'voc_editable_manufactory_purpose',
	vocEditableMinorJob: 'voc_editable_minor_job',
	vocEditableOperation: 'voc_editable_operation',
	vocEditablePkgType: 'voc_editable_pkg_type',
	vocEditablePaymentCondition: 'voc_editable_payment_condition',
	vocEditablePositionType: 'voc_editable_position_type',
	vocEditableProcess: 'voc_editable_process',
	vocEditableProduct1C: 'voc_editable_product_1C',
	vocEditableProductGroup: 'voc_editable_product_group',
	vocEditableProductionArea: 'voc_editable_production_area',
	vocEditableQuality: 'voc_editable_quality',
	vocEditableRawMatCountry: 'voc_editable_raw_mat_country',
	vocEditableRawMatManuf: 'voc_editable_raw_mat_manuf',
	vocEditableRawMatShelfLife: 'voc_editable_raw_mat_shelf_life',
	vocEditableRawMatState: 'voc_editable_raw_mat_state',
	vocEditableReadyProductForm: 'voc_editable_ready_product_form',
	vocEditableRoom: 'voc_editable_room',
	vocSpecParamParam: 'voc_spec_param_param',
	vocSpecParam: 'voc_spec_param',
	vocEditableTemperatureRegime: 'voc_editable_temperature_regime',
	vocEditableTimelyExport: 'voc_editable_timely_export',
	vocEditableWarehouseType: 'voc_editable_warehouse_type',
	vocEditableWasteType: 'voc_editable_waste_type',
	vocWorkingTimeBreak: 'voc_working_time_break',
	vocWorkingTime: 'voc_working_time',
	vocStaticFacilityType: 'voc_static_facility_type',
	vocStaticMatchStatus: 'voc_static_match_status',
	vocStaticProductType: 'voc_static_product_type',
	vocStaticStaffStatus: 'voc_static_staff_status',
	vocStaticSupplyStatus: 'voc_static_supply_status',
	vocStaticTypeReasBrk: 'voc_static_type_reas_brk',
	rmSupplyUnload: 'rmSupplyUnload',
	rmSupplyInputCtrl: 'rmSupplyInputCtrl',
}

const modelMapToEntity = Object.entries(entityToModelMap).reduce((acc, [k, v]) => {
	return { ...acc, [v]: k }
}, {})

const aggregateToModelsMap = {
	supplyReq: {
		from: 'rm_supply_request',
		include: ['rm_suppl_req_prod', 'rm_supply_req_doc'],
	},
	supply: {
		from: 'rm_supply',
		include: ['rm_suppl_prod', 'rm_suppl_invoice'],
	},
	contract: {
		from: 'rp_rw_contract',
		include: ['contr_provider_quota', 'application', 'contr_prov_order'],
	},
	staff: {
		from: 'reg_staff',
		include: [],
	},
	provider: {
		from: 'rm_provider',
		include: ['rp_rm_platform'],
	},

	customer: {
		from: 'rp_customer',
		include: ['rp_rm_platform'],
	},
	vocCarState: {
		from: 'voc_car_state',
		include: [],
	},
	vocContType: {
		from: 'voc_editable_container_type',
		include: [],
	},
	vocRawMatManuf: {
		from: 'voc_editable_raw_mat_manuf',
		include: [],
	},
	vocRawMatCountry: {
		from: 'voc_editable_raw_mat_country',
		include: [],
	},
	vocRoom: {
		from: 'voc_editable_room',
		include: [],
	},
	specification: {
		from: 'reg_specification',
		include: ['spec_param', 'reg_specification'],
	},
}
module.exports = { entityToModelMap, aggregateToModelsMap, modelMapToEntity }
