import { basicValidator, isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'

/**
 * проверяет число на правильность и сетит его если оно корректно
 * @param {String} inVal - новое число
 * @param {String} inOldVal - старое число
 * @param {Function} setterFunc - сеттер
 * @param {('float' | 'int')} numType - сеттер
 * @param {Array<Number>} numConstraints - сеттер
 * @param {{_uuid_: string}} options - доп опции
 */
const setNumField = (
	inVal,
	inOldVal,
	setterFunc,
	numType,
	numConstraints,
	options = { isOnlyNums: false, allowZero: true }
) => {
	const correctSetter = (val) => {
		const v = basicValidator(val) ? val : null
		const result = options._uuid_ ? setterFunc(options._uuid_, v) : setterFunc(v)
		if (!options.allowZero && +inVal === 0) {
			if (options.errorSetter) {
				options.errorSetter()
			}
		}
		return result
	}
	if (inVal === '-' && !options.isOnlyNums) return correctSetter(inVal)
	if (
		!isValidNum(inVal) ||
		!isValidNumberDigits(inVal, numType, numConstraints) ||
		(Object.values(inVal).some((el) => isNaN(el) && el !== '.') && options.isOnlyNums) ||
		inVal.includes(' ')
	) {
		if (options.errorSetter) {
			errorSetter()
		}
		return correctSetter(inOldVal)
	}
	return correctSetter(inVal)
}

/**
 * проверяет строку на длинк и сетит ее если оно корректно
 * @param {String} inVal - новое строка
 * @param {String} inOldVal - старая строка
 * @param {Function} setterFunc - сеттер
 * @param {{_uuid_: string}} options - доп опции
 */
const setTextField = (inVal, inOldVal, setterFunc, inLength, options = {}) => {
	const correctSetter = (val) => {
		if (options._uuid_) {
			return setterFunc(options._uuid_, basicValidator(val) ? val : null)
		}
		return setterFunc(basicValidator(val) ? val : null)
	}

	if (inVal?.length && inVal.length > inLength) {
		return correctSetter(inOldVal)
	}
	return correctSetter(inVal)
}

export { setNumField, setTextField }
