import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import { Card, Form, Row } from 'antd'
import {
	ModContext,
	ModProvider,
	FileProvider,
	UserDataContext,
	AuthContext,
} from '../../../contexts'
import { Provider, SpecificationItemContext } from './provider/main'
import { Main } from '../../../layouts'
import {
	CommonMatchActionButtons,
	CustomForm,
	HeaderMenu,
	showConfirmModal,
	TabList,
} from '../../../components'
import EventHistoryTab from './EventHistoryTab'
import ParameterTab from './ParameterTab'
import {
	actBtnOnClose,
	actBtnOnDelete,
	modes,
	openNewTab,
	actBtnOnSaveDraft,
	axios,
} from '../../../utils'
import { getFormItems, dataUrl as pageUrl } from './specification-item'

import { getBreadcrumbsText } from '../../../utils/helpers/generators'
import { showIsBLockedModalForDeps } from '../../../utils/constants/for-components'
const SpecificationItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<FileProvider>
				<Provider params={{ id: props.match.params.id }}>
					<Main title="Протоколы контроля">
						<InfoComponent />
					</Main>
				</Provider>
			</FileProvider>
		</ModProvider>
	)
}
const InfoComponent = () => {
	const mainCtx = useContext(SpecificationItemContext)
	const {
		state: { data },
	} = mainCtx
	const modCtx = useContext(ModContext)
	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const history = useHistory()
	if (mainCtx.state.data.__notFound) {
		history.replace(pageUrl)
	}
	const isNew = modCtx.mod === modes.new
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalItem, setModalItem] = useState(null)
	const [form] = Form.useForm()
	const bread = getBreadcrumbsText(pageUrl, data)
	return (
		<>
			<HeaderMenu
				breadcrumbsTitle="Протоколы контроля"
				search={mainCtx.setSearch}
				recordLabel={isNew ? 'Новый протокол контроля' : bread.label}
			>
				<Row>
					<CommonMatchActionButtons
						ctx={mainCtx}
						isPendingReq={mainCtx.isPendingReq}
						matchObject="spec"
						rights={{
							'АРМ офиса': {
								'Протоколы контроля': {
									edit: true,
								},
							},
						}}
						canEdit={mainCtx.state.data.status !== 'Утверждено'}
						formName="specification"
						canDelete={modCtx.mod !== modes.new}
						onDelete={async () => {
							if (await mainCtx.checkIsBlocked()) {
								axios.post(`/logs`, {
									time: new Date().toISOString(),
									action: 'Delete',
									isBlockUnblockLogic: true,
									entity: 'regSpecification',
									idEntity: mainCtx.state.id,
								})
								showConfirmModal(showIsBLockedModalForDeps)
								return
							}
							await actBtnOnDelete({
								history,
								pageUrl,
								showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								getDeps: mainCtx.getDepsOn,
								width: 640,
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
								currentRights,
							})
						}}
						onEdit={() => modCtx.set(modes.edit)}
						onCancel={() => {
							form.resetFields()
							mainCtx.reset()
						}}
						onCopy={mainCtx.copySpecification}
						onClose={() => {
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							if (await mainCtx.checkIsBlocked()) {
								axios.post(`/logs`, {
									time: new Date().toISOString(),
									action: 'Edit',
									isBlockUnblockLogic: true,
									entity: 'regSpecification',
									idEntity: mainCtx.state.id,
								})
								showConfirmModal(showIsBLockedModalForDeps)
								return
							}

							const result = await actBtnOnSaveDraft({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								pageUrl: pageUrl,
								history: history,
								isShowErrorModal: true,
								showConfirmModal,
								form,
								canSaveWithErrors: true,
								confirmDraft: mainCtx.confirmDraft,
								draft: true,
							})
							if (result) {
								const allFields = form.getFieldsError()
								allFields.forEach((f) => {
									f.errors = []
								})
								form.setFields(allFields)
							}
						}}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<CustomForm
					name="specification"
					form={form}
					items={getFormItems(mainCtx, modCtx)}
					columns={[
						{ size: { xs: 24, md: 12, lg: 8, xl: 8 }, num: 0 },
						{ size: { xs: 24, md: 12, lg: 8, xl: 10 }, num: 1 },
						{ size: { xs: 24, md: 12, lg: 8, xl: 6 }, num: 2 },
					]}
					fields={[
						{ name: 'vocProdType', value: data.vocProdType },
						{ name: 'prodCat', value: data.prodCat },
						{
							name: 'prodKind',
							value: data.kindRawMat || data.kindReadyProd,
						},
						{ name: 'label', value: data.label },
						{
							name: 'vocQuality',
							value: data.vocQuality ? data.vocQuality?.displayVal : null,
						},
						{ name: 'shelfLife', value: data.shelfLife },
						{ name: 'vocMeasure', value: data.vocMeasure },
						{ name: 'status', value: data.status },
						{ name: 'dateOfApprove', value: data.dateOfApprove },
						{ name: 'version', value: data.version },
					]}
				/>
			</Card>
			<TabList
				tabs={[
					{ key: 0, title: 'Параметры', component: <ParameterTab /> },
					{
						key: 1,
						title: 'История событий',
						component: (
							<EventHistoryTab
								modalItem={modalItem}
								setModalItem={setModalItem}
								isModalOpen={isModalOpen}
								setIsModalOpen={setIsModalOpen}
							/>
						),
					},
				]}
			/>
		</>
	)
}

export default SpecificationItem
