module.exports = [
	{ id: 1, label: 'Новая' },
	{ id: 2, label: 'В лаборатории' },
	{ id: 3, label: 'Одобрено лабораторией' },
	{ id: 4, label: 'На согласовании' },
	{ id: 5, label: 'Приемка согласована' },
	{ id: 6, label: 'Принято' },
	{ id: 7, label: 'Согласовано ответственное хранение' },
	{ id: 8, label: 'Ответственное хранение ' },
	{ id: 9, label: 'Согласован возврат' },
	{ id: 10, label: 'Возвращено' },
]
