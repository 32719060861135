import React, { useContext } from 'react'
import { Form, Input, Button, Row, Col, Layout } from 'antd'
import { Helmet } from 'react-helmet'
import { AuthContext } from '../../contexts/auth/auth'
import { Link } from 'react-router-dom'
import AuthError from '../../components/AuthError/AuthError'
import Logo from './../../components/Logo/Logo'
import '../../index.css'
const { Content } = Layout

const Login = (props) => {
	const {
		state: { error },
		signIn,
	} = useContext(AuthContext)
	let inputStyle = { width: '500px', height: '50px', borderRadius: '5px' }
	if (error) {
		inputStyle = { width: '500px', height: '50px', borderRadius: '5px', border: '1px solid red' }
	}
	return (
		<Layout>
			<Helmet>
				<title>Вход</title>
			</Helmet>
			<Logo />
			<Content
				style={{
					background: 'url(/login.jpg) no-repeat center fixed',
					backgroundSize: 'cover',
					display: 'flex',
					flexDirection: 'column-reverse',
					justifyContent: 'space-around',
					height: '100vh',
				}}
			>
				<Row justify="center" align="middle" style={{ position: 'relative', bottom: '20px' }}>
					<Col>
						<Col>
							<Row
								justify="center"
								style={{
									fontSize: 25,
									fontWeight: 'bold',
									paddingBottom: '10px',
									color: 'rgb(3 52 97)',
								}}
							>
								АВТОРИЗАЦИЯ
							</Row>
							<Row
								justify="center"
								style={{
									fontSize: 20,
									fontWeight: 700,
									textAlign: 'center',
									paddingBottom: '30px',
									color: 'rgb(3 52 97)',
								}}
							>
								Введите логин и пароль
							</Row>
						</Col>
						<Form colon={false} onFinish={signIn}>
							<Row justify="center">
								<Col
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
									}}
								>
									<Form.Item name="login">
										<Input style={inputStyle} placeholder="Логин" />
									</Form.Item>
									<Form.Item name="pwd">
										<Input.Password
											className="password-input"
											type="password"
											style={inputStyle}
											placeholder="Пароль"
										/>
									</Form.Item>
									{error && <AuthError error={error} />}
									<Button
										htmlType="submit"
										type="btn"
										style={{
											fontWeight: 600,
											width: '500px',
											height: '50px',
											alignSelf: 'center',
											background: 'rgb(3 52 97)',
											color: 'white',
											borderRadius: '5px',
										}}
									>
										ВОЙТИ
									</Button>
								</Col>
							</Row>
							<Col
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									height: '80px',
									fontWeight: '600',
								}}
							>
								<Link style={{ color: 'rgb(3 52 97)' }} to="/change-password">
									Сменить пароль?
								</Link>
								<Link style={{ color: 'rgb(3 52 97)' }} to="/forgot-password">
									Забыли пароль?
								</Link>
							</Col>
						</Form>
					</Col>
				</Row>
			</Content>
		</Layout>
	)
}

export default Login
