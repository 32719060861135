import { Col, DatePicker, Input, Select } from 'antd'
import { modes } from '../../../utils'
import moment from 'moment'
import { setNumField } from '@berry/front-common/for-fields'
import { calcSupplyInpCtrlFactWeight } from '@berry/common-functions/cross-project-functions'
import { CommonSelect, InputError, showConfirmModal } from '../../../components'
import { dateFormat } from '../../../utils/constants/common'

const layout1Col = {
	labelCol: { xs: 24, sm: 9, xl: 11, xxl: 9 },
	wrapperCol: { xs: 24, sm: 14, xl: 11, xxl: 11 },
}
const layout2Col = {
	labelCol: { xs: 24, sm: 9, xl: { offset: 2, span: 10 }, xxl: 7 },
	wrapperCol: { xs: 24, sm: 14, xl: 11, xxl: 11 },
}
const layout3Col = {
	labelCol: { xs: 24, sm: 9, xl: { offset: 2, span: 10 }, xxl: 8 },
	wrapperCol: { xs: 24, sm: 14, xl: 13, xxl: 13 },
}

export const getFormItems1 = (mainCtx, modCtx, userDataCtx) => {
	const isDisabled = modCtx.mod === modes.view
	const {
		state: { isDevAdmin },
	} = userDataCtx
	return [
		[
			{
				layout: layout1Col,
				label: 'Статус партии',
				field: mainCtx.state.ctrlStatus,
				key: 'ctrlStatus',
			},
			{
				layout: layout1Col,
				label: 'ID',
				field: mainCtx.state.supply.id,
				key: 'id',
			},
			{
				layout: layout1Col,
				label: 'Дата поставки',
				field: mainCtx.state.supply.date
					? moment(mainCtx.state.supply.date).format(dateFormat)
					: null,
				key: 'date',
			},
			{
				layout: layout1Col,
				label: 'Номер заявки',
				field: mainCtx.state.supply.supplyReq.displayCode,
				key: 'supplyReqDisplayCode',
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Продукт',
				field: mainCtx.state.rmProvProd.displayVal,
				key: 'rmProvProd',
			},
			{
				layout: layout2Col,
				label: 'Поставщик',
				field: mainCtx.state.supply.supplyReq
					? mainCtx.state.supply.supplyReq.products[0]?.contrQuota?.contract.provider.label ||
					  mainCtx.state.supply.supplyReq.products[0]?.order?.contract.provider.label
					: mainCtx.state.supply.contract.provider.label,
				key: 'provider',
			},
			{
				layout: layout2Col,
				label: 'Присв. № партии',
				field: isDevAdmin ? (
					<Input
						style={{ minWidth: '100px' }}
						value={mainCtx.state.assignedPartyNum}
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setAssignedPartyNum(e.target.value)
						}}
					/>
				) : mainCtx.state.ctrlDate ? (
					mainCtx.state.assignedPartyNum
				) : (
					''
				),
				key: 'assignedPartyNum',
			},
		],
		[
			{
				key: 'ctrlStaff',
				layout: layout3Col,
				label: 'ФИО проверяющего',
				name: 'ctrlStaff',
				rules: [{ required: true }],
				required: true,
				field: (
					<CommonSelect
						value={mainCtx.state.ctrlStaff}
						setValue={mainCtx.stateFunctions.setCtrlStaff}
						isDisabled={isDisabled}
						dataArr={mainCtx.additional.allCtrlStaff}
					/>
				),
			},
			{
				layout: layout3Col,
				label: 'Артикул',
				key: 'kindRawMat',
				field: mainCtx.state.articul,
			},
			{
				layout: layout3Col,
				label: 'Артикул 1С',
				field: mainCtx.state.articul1C,
				key: 'vendorCode',
			},
		],
	]
}

export const getFormItems2 = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				key: 'ctrlRawMatManuf',
				layout: layout1Col,
				label: 'Производитель',
				name: 'ctrlRawMatManuf',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.ctrlRawMatManuf}
						setValue={mainCtx.stateFunctions.setCtrlRawMatManuf}
						isDisabled={isDisabled}
						dataArr={mainCtx.selectors.ctrlRawMatManuf}
						showSearch
					/>
				),
			},
			{
				layout: layout1Col,
				key: 'ctrlRawMatCountry',
				label: 'Страна происхождения',
				name: 'ctrlRawMatCountry',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.ctrlRawMatCountry}
						setValue={mainCtx.stateFunctions.setCtrlRawMatCountry}
						isDisabled={isDisabled}
						dataArr={mainCtx.selectors.ctrlRawMatCountry}
						showSearch
					/>
				),
			},
		],
		[
			{
				layout: layout2Col,
				key: 'ctrlDate',
				label: 'Дата изготовления',
				name: 'ctrlDate',
				rules: [{ required: true }],
				field: (
					<DatePicker
						style={{ width: '100%' }}
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format="DD.MM.YYYY"
						allowClear={false}
						suffixIcon={null}
						onChange={(e) => {
							mainCtx.stateFunctions.setCtrlDate(e)
						}}
						disabled={isDisabled}
					/>
				),
			},
			[
				{
					key: 'ctrlShelfLife',
					layout: {
						labelCol: { xs: 24, sm: 8, xl: { span: 0, offset: 4 }, xxl: { span: 11, offset: 4 } },
						wrapperCol: {
							xs: 24,
							sm: 14,
							xl: { span: 1, offset: 4 },
							xxl: { span: 2, offset: 3 },
						},
					},
					label: 'Срок годности',
					name: 'ctrlShelfLife',
					rules: [{ required: true }],
					field: (
						<Input
							style={{ minWidth: '85px' }}
							value={mainCtx.state.ctrlShelfLife}
							disabled={isDisabled}
							onChange={(e) => {
								return setNumField(
									e.target.value,
									mainCtx.state.ctrlShelfLife,
									mainCtx.stateFunctions.setCtrlShelfLife,
									'int',
									[10, 0]
								)
							}}
						/>
					),
				},
				{
					key: 'ctrlMeasure',
					layout: {
						labelCol: { xs: 24, sm: 8, xl: { span: 0, offset: 6 }, xxl: { span: 4, offset: 4 } },
						wrapperCol: {
							xs: 24,
							sm: { span: 14, pull: 2 },
							xxl: { span: 8, offset: 2 },
						},
					},
					label: 'ЕИ',
					name: 'ctrlMeasure',
					rules: [{ required: true }],
					field: (
						<Select
							value={mainCtx.state.ctrlMeasure?.labelShort}
							onChange={(el) => mainCtx.stateFunctions.setCtrlMeasure(el)}
							disabled={isDisabled}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors.ctrlMeasure.map((it) => (
								<Select.Option key={it.id} value={it.id}>
									{it.labelShort}
								</Select.Option>
							))}
						</Select>
					),
				},
			],
		],
		[
			{
				layout: layout3Col,
				label: 'Год урожая',
				field: moment(mainCtx.state.reqProd?.harvestYear).format('YYYY'),
				key: 'harvestYear',
			},
			{
				layout: layout3Col,
				label: 'Масса по наклад., кг',
				field: mainCtx.state.weightInv,
				key: 'weightInv',
			},
			{
				layout: layout3Col,
				label: 'Масса факт., кг',
				field: calcSupplyInpCtrlFactWeight(mainCtx.state),
				key: 'weightFact',
			},
		],
	]
}

export const getFormItems3 = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				key: 'ctrlControlWeight',
				layout: layout1Col,
				label: 'Контролир. масса, кг',
				name: 'ctrlControlWeight',
				rules: [{ required: !mainCtx.state.isReadyProdResale }],
				field: (
					<InputError
						hasError={mainCtx.formErrors?.[`factText.${mainCtx.state.ctrlControlWeight}`]}
						style={{ width: '100%' }}
						value={mainCtx.state.ctrlControlWeight}
						disabled={isDisabled}
						onChange={(e) => {
							return setNumField(
								e.target.value,
								mainCtx.state.ctrlControlWeight,
								mainCtx.stateFunctions.setCtrlControlWeight,
								'float',
								[6, 1]
							)
						}}
					/>
				),
			},
		],
		[
			{
				key: 'ctrlExampleWeight',
				layout: layout2Col,
				label: 'Масса образца, кг',
				name: 'ctrlExampleWeight',
				rules: [{ required: !mainCtx.state.isReadyProdResale }],
				field: (
					<Input
						style={{ width: '100%' }}
						value={mainCtx.state.ctrlExampleWeight}
						disabled={isDisabled}
						onChange={(e) => {
							return setNumField(
								e.target.value,
								mainCtx.state.ctrlExampleWeight,
								mainCtx.stateFunctions.setCtrlExampleWeight,
								'float',
								[6, 1]
							)
						}}
					/>
				),
			},
		],
		[
			{
				key: 'ctrlPkgCount',
				layout: layout3Col,
				label: 'Кол-во упаковок, шт',
				name: 'ctrlPkgCount',
				field: (
					<Input
						style={{ width: '100%' }}
						value={mainCtx.state.ctrlPkgCount}
						disabled={isDisabled}
						onChange={(e) => {
							return setNumField(
								e.target.value,
								mainCtx.state.ctrlPkgCount,
								mainCtx.stateFunctions.setCtrlPkgCount,
								'int',
								[10, 0]
							)
						}}
					/>
				),
			},
		],
	]
}

export const getFormItems4 = (mainCtx, modCtx, showQualitySelect, setShowQualitySelect) => {
	const isDisabled = modCtx.mod === modes.view
	let qualityVal = null
	if (mainCtx.state.ctrlSpec) {
		if (mainCtx.state.ctrlSpec?.vocQuality?.displayVal)
			qualityVal = mainCtx.state.ctrlSpec.vocQuality.displayVal
		else qualityVal = 'Отсутствует'
	}
	return [
		[
			{
				layout: layout1Col,
				key: 'ctrlControlDate',
				label: 'Дата контроля',
				name: 'ctrlControlDate',
				rules: [{ required: !mainCtx.state.isReadyProdResale }],
				field: (
					<DatePicker
						style={{ width: '100%' }}
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format="DD.MM.YYYY"
						allowClear={false}
						suffixIcon={null}
						onChange={(e) => {
							mainCtx.stateFunctions.setCtrlControlDate(e)
						}}
						disabled={isDisabled}
					/>
				),
			},
			{
				key: 'ctrlTestReportNum',
				layout: layout1Col,
				label: '№ протокола испытаний',
				field: mainCtx.state.ctrlControlDate ? mainCtx.state.ctrlTestReportNum : null,
			},
		],
		[
			{
				key: 'vocQuality',
				layout: layout2Col,
				label: 'Качество',
				name: 'vocQuality',
				required: !mainCtx.state.isReadyProdResale,
				rules: [{ required: !mainCtx.state.isReadyProdResale }],
				field: (
					<Select
						open={showQualitySelect}
						dropdownStyle={!showQualitySelect && { display: 'none' }}
						onBlur={() => {
							if (showQualitySelect) setShowQualitySelect(false)
						}}
						value={qualityVal}
						style={{ width: '100%', minWidth: '120px' }}
						onChange={(el) => {
							mainCtx.stateFunctions.setCtrlSpec(el)
							setShowQualitySelect(false)
						}}
						onClick={() => {
							if (!showQualitySelect && !isDisabled) {
								if (
									mainCtx.state.ctrlLabIndicators?.some((item) => item.photos?.length) ||
									mainCtx.state.ctrlLabIndicators?.some((item) => item.factVal || item.factText)
								) {
									showConfirmModal({
										title:
											'При смене спецификации все внесенные данные в табличной вкладке Лабораторные показатели будут удалены',
										okText: 'Ок',
										width: '34.7%',
										cancelText: 'Отмена',
										onOk: () => {
											setShowQualitySelect(true)
										},
									})
								} else {
									setShowQualitySelect(true)
								}
							}
						}}
						disabled={isDisabled}
						notFoundContent={<Col>Не найдено</Col>}
					>
						{mainCtx.selectors.ctrlSpec.map((it) => {
							return (
								<Select.Option key={it.id} value={it.id}>
									{it.vocQuality?.displayVal || 'Отсутствует'}
								</Select.Option>
							)
						}) || []}
					</Select>
				),
			},
			{
				key: 'ctrlSpec',
				layout: layout2Col,
				label: 'Спецификация',
				field: mainCtx.state.ctrlSpec?.label,
			},
		],
		// [
		// 	{
		// 		key: 'qualityIndic',
		// 		layout: layout3Col,
		// 		label: 'Показатель качества',
		// 		field: mainCtx.state.ctrlStatus === 'Исследование завершено' ? mainCtx.qualityIndic : null,
		// 	},
		// ],
	]
}
