import { sorter } from '../../../utils'

export const columns = () => [
	{
		title: 'Наименование',
		dataIndex: 'label',
		key: 'label',
		width: '90%',
		sorter: sorter('string', 'label'),
	},
]
