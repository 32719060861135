import { diffNonPrimitive, parseRGBA } from '../../utils'

export const vocabularies = [
	// {
	// 	link: 'type-reason-break',
	// 	title: 'Причины поломок оборудования',
	// 	root: 'Справочники',
	// 	editable: false,
	// },
	{
		link: 'product-1c',
		title: 'Продукция из 1С',
		root: 'Справочники',
		editable: false,
	},
	{
		link: 'staff-status',
		title: 'Статусы персонала',
		root: 'Справочники',
		editable: false,
	},
	// {
	// 	link: 'supply-status',
	// 	title: 'Статусы поставки',
	// 	root: 'Справочники',
	// 	editable: false,
	// },
	// {
	// 	link: 'matching-status',
	// 	title: 'Статусы согласования',
	// 	root: 'Справочники',
	// 	editable: false,
	// },
	// {
	// 	link: 'facility-type',
	// 	title: 'Типы объектов производства и вспомогательных служб',
	// 	root: 'Справочники',
	// 	editable: false,
	// },
	{
		link: 'product-type',
		title: 'Типы продукта',
		root: 'Справочники',
		editable: false,
	},
	{
		link: 'additional-semi-type',
		title: 'Виды дополнительных ПФ',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'main-semifinished-kind',
		title: 'Виды основных ПФ',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'waste-type',
		title: 'Виды НО',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'outdoor-warehouse',
		title: 'Внешние склады',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'product-group',
		title: 'Группы продукции',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'documentation',
		title: 'Документация',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'quality',
		title: 'Качество',
		root: 'Справочники',
		editable: true,
	},

	{
		link: 'room',
		title: 'Помещения',
		root: 'Справочники',
		editable: true,
	},
	// {
	// 	link: 'manufactory-purpose',
	// 	title: 'Назначение цехов',
	// 	root: 'Справочники',
	// 	editable: true,
	// },
	{
		link: 'raw-material-manufacturer',
		title: 'Производители сырья',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'cause-of-reject-rep',
		title: 'Причины отклонения отчета о производстве при согласовании',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'block-cause',
		title: 'Причины блокировки партии в отчете о производстве',
		root: 'Справочники',
		editable: true,
	},
	// {
	// 	link: 'production-area',
	// 	title: 'Производственные участки',
	// 	root: 'Справочники',
	// 	editable: true,
	// },
	{
		link: 'process',
		title: 'Процессы',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'specification-parameter',
		title: 'Параметры спецификации',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'working-time',
		title: 'Смены',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'car-state',
		title: 'Состояние машины',
		root: 'Справочники',
		editable: true,
	},

	{
		link: 'raw-mat-state',
		title: 'Состояния сырья',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'raw-material-country',
		title: 'Страны происхождения "Сырья"',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'raw-mat-shelf-life',
		title: 'Срок годности сырья',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'temperature-regime',
		title: 'Температурный режим',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'position-type',
		title: 'Типы должностей',
		root: 'Справочники',
		editable: true,
	},
	// {
	// 	link: 'package-type',
	// 	title: 'Типы упаковки',
	// 	root: 'Справочники',
	// 	editable: true,
	// },
	// {
	// 	link: 'warehouse-type',
	// 	title: 'Типы складов',
	// 	root: 'Справочники',
	// 	editable: true,
	// },
	{
		link: 'container-type',
		title: 'Типы тары',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'delivery-condition',
		title: 'Условия доставки',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'payment-condition',
		title: 'Условия оплаты',
		root: 'Справочники',
		editable: true,
	},
	{
		link: 'ready-product-form',
		title: 'Формы ГП',
		root: 'Справочники',
		editable: true,
	},
]

export const differentFields = (fields, data, id) => {
	let isDifferent = false
	const record = data.find((elem) => elem.id === id)

	for (const field of Object.values(fields)) {
		const key = Object.keys(field)

		const dataValue = record[key]
		const fieldValue = key[0] === 'color' ? parseRGBA(field[key].value) : field[key].value

		if (dataValue !== fieldValue) {
			if (diffNonPrimitive(dataValue, fieldValue)) continue

			isDifferent = true
			break
		}
	}

	return isDifferent
}

export const checkValidate = (validateFields = []) => {
	if (validateFields.length === 0) return true
	let isValid = true

	for (const field of Object.values(validateFields)) {
		const key = Object.keys(field)

		if (Array.isArray(field[key].value)) {
			if (!field[key].value.length) {
				isValid = false
				break
			}
		}
		if (typeof field[key] === 'function') {
			if (!field[key]()) {
				isValid = false
				break
			}
		} else {
			if (!field[key].value) {
				isValid = false
				break
			}
		}
	}

	return isValid
}

export const checkCustomValidate = (customValidateFields = []) => {
	for (const func of customValidateFields) {
		const validateResult = func()
		if (validateResult !== '') return validateResult
	}

	return ''
}

export const checkUniqueFields = (uniqueFields = [], data, recordId) => {
	if (data.length === 0 || uniqueFields.length === 0) return true
	let isUnique = true

	for (const field of Object.values(uniqueFields)) {
		const key = Object.keys(field)
		const record = data.find(
			(elem) =>
				elem[key].toLowerCase() ===
				(key[0] === 'color'
					? parseRGBA(field[key].value.toLowerCase())
					: field[key].value.toLowerCase())
		)

		if (record && record.id !== recordId) {
			isUnique = false
			break
		}
	}

	return isUnique
}
