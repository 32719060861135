const rgbaToHex = (channels) => {
	const hexChannels = channels.map((entry) => `0${entry.toString(16)}`.slice(-2))

	return `#${hexChannels.join('')}`
}

export const parseRGBA = (rgb) => {
	return rgbaToHex([rgb.r, rgb.g, rgb.b])
}

export const parseHex = (hex, alpha = 1) => {
	if (!hex) return null
	const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
	return {
		r,
		g,
		b,
		a: alpha,
	}
}
