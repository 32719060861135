import React, { useContext } from 'react'

import Wrapper from '../Wrapper/Wrapper'
import { DataServerContext } from '../../../contexts'
import { columns } from './columns'

const vocUrl = '/voc/static/type-reason-break'

const TypeBreak = () => {
	const { state } = useContext(DataServerContext)
	return (
		<Wrapper
			rights={{
				Справочники: {
					'Типы причин поломок оборудования': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Типы причин поломок оборудования"
			data={state[vocUrl].data}
			columns={columns}
			vocUrl={vocUrl}
		/>
	)
}

export default TypeBreak
