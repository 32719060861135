import { Checkbox, Input } from 'antd'
import { axios, modes } from '../../../utils'
import { CommonSelect, ImageUploader } from '../../../components'

const layout = {
	labelCol: { xs: 24, sm: 8, lg: { span: 12 } },
	wrapperCol: { xs: 24, sm: 14, lg: { span: 10 } },
}

const staffValid = (e) => {
	if (
		(!e.key.match(/^[а-яА-Яё-]+$/) &&
			e.key !== 'Backspace' &&
			e.key !== 'Delete' &&
			e.key !== 'ArrowLeft' &&
			e.key !== 'ArrowRight' &&
			e.key !== 'Tab') ||
		(e.target.value.length === 0 && e.key.match(/[^\s]*-/))
	) {
		return e.preventDefault()
	}
}

export const getFormItems = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	const isUser = mainCtx.state?.posType?.isUser
	return [
		[
			{
				key: 'photo',
				field: (
					<ImageUploader
						data={mainCtx.state}
						setPhoto={mainCtx.stateFunctions.setPhoto}
						isShowConfirmModal
					/>
				),
			},
		],
		[
			{
				layout,
				label: 'Фамилия',
				name: 'surname',
				required: true,
				rules: [{ required: true }],
				field: (
					<Input
						value={mainCtx.state.surname}
						disabled={isDisabled}
						onKeyDown={(e) => staffValid(e)}
						onContextMenu={(e) => e.preventDefault()}
						autoComplete="off"
						onChange={(e) => mainCtx.stateFunctions.setSurname(e.target.value)}
					/>
				),
			},
			{
				layout,
				label: 'Имя',
				name: 'name',
				required: true,
				rules: [{ required: true }],
				field: (
					<Input
						value={mainCtx.state.name}
						disabled={isDisabled}
						onKeyDown={(e) => staffValid(e)}
						onContextMenu={(e) => e.preventDefault()}
						autoComplete="off"
						onChange={(e) => mainCtx.stateFunctions.setName(e.target.value)}
					/>
				),
			},
			{
				layout,
				label: 'Отчество',
				name: 'patronymic',
				required: !mainCtx.state.isWithoutPtrnmc,
				dependencies: ['isWithoutPtrnmc'],
				rules: [{ required: !mainCtx.state.isWithoutPtrnmc }],
				field: (
					<Input
						value={mainCtx.state.patronymic}
						disabled={isDisabled || mainCtx.state.isWithoutPtrnmc}
						onKeyDown={(e) => staffValid(e)}
						onContextMenu={(e) => e.preventDefault()}
						autoComplete="off"
						onChange={(e) => mainCtx.stateFunctions.setPatronymic(e.target.value)}
					/>
				),
			},
			{
				layout,
				label: 'Нет отчества',
				name: 'isWithoutPtrnmc',
				field: (
					<Checkbox
						disabled={isDisabled}
						checked={mainCtx.state.isWithoutPtrnmc}
						onChange={(e) => {
							mainCtx.stateFunctions.setPatronymic(null)
							mainCtx.stateFunctions.setIsWithoutPtrnmc(e.target.checked)
						}}
					/>
				),
			},
		],
		[
			{
				layout,
				label: 'Должность',
				name: 'posType',
				required: true,
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.posType}
						setValue={mainCtx.stateFunctions.setPosType}
						isDisabled={isDisabled}
						dataArr={mainCtx.additional.allPosTypes}
					/>
				),
			},
			{
				layout,
				label: 'Моб. телефон',
				name: 'phoneNum',
				required: isUser,
				rules: [{ required: isUser }],
				field: (
					<Input
						value={mainCtx.state.phoneNum}
						disabled={isDisabled}
						onPaste={(e) => e.preventDefault()}
						onKeyPress={(event) => {
							if (!/[0-9]/.test(event.key)) {
								event.preventDefault()
							}
						}}
						addonBefore="+"
						onChange={(e) => mainCtx.stateFunctions.setPhoneNum(e.target.value)}
						placeholder="X XXX XXX XX XX"
					/>
				),
			},
			{
				layout,
				label: 'E-mail',
				name: 'email',
				required: isUser,
				rules: [
					{ required: isUser },
					({ getFieldValue }) => ({
						validator: async (_, value) => {
							const email = getFieldValue('email')
							if (email?.length > 3) {
								const response = await axios('/reg/staff/check-email', {
									params: { id: mainCtx.state.id, email },
								})
								const isUnique = response.data?.result
								if (isUnique) {
									return Promise.resolve()
								}
								return Promise.reject(new Error('Данный E-mail уже используется'))
							}
						},
					}),
				],
				field: (
					<Input
						value={mainCtx.state.email}
						disabled={isDisabled}
						onChange={(e) => mainCtx.stateFunctions.setEmail(e.target.value)}
					/>
				),
			},
			{
				layout,
				label: 'Статус',
				name: 'status',
				required: true,
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.status}
						setValue={mainCtx.stateFunctions.setStatus}
						isDisabled={isDisabled}
						dataArr={mainCtx.additional.allStatuses}
					/>
				),
			},
		],
		[
			{
				layout,
				label: 'ГПХ',
				name: 'gph',
				field: (
					<Checkbox
						disabled={isDisabled}
						checked={mainCtx.state.isGph}
						onChange={(e) => mainCtx.stateFunctions.setIsGph(e.target.checked)}
					/>
				),
			},
			...(mainCtx.state.posType?.isUser
				? [
						{
							layout,
							label: 'Логин',
							name: 'login',
							rules: [
								{ required: true },
								({ getFieldValue }) => ({
									validator: async (_, value) => {
										const login = getFieldValue('login')
										if (login) {
											const response = await axios('/reg/staff/check-login', {
												params: { id: mainCtx.state.id, login },
											})
											const isUnique = response.data?.result
											if (isUnique) {
												return Promise.resolve()
											}
											return Promise.reject(new Error('Данный логин уже используется'))
										}
									},
								}),
							],
							field: (
								<Input
									value={mainCtx.state.login}
									disabled={isDisabled}
									onKeyDown={(e) => (!e.key.match(/[a-zA-Z0-9]/) ? e.preventDefault() : undefined)}
									onChange={(e) => mainCtx.stateFunctions.setLogin(e.target.value)}
								/>
							),
						},
						{
							layout,
							label: 'Администратор системы',
							name: 'isAdmin',
							field: (
								<Checkbox
									disabled={isDisabled}
									checked={mainCtx.state.isAdmin}
									onChange={(e) => {
										mainCtx.stateFunctions.setIsAdmin(e.target.checked)
									}}
								/>
							),
						},
				  ]
				: []),
		],
	]
}
