import { DownOutlined } from '@ant-design/icons'
import './custom-expand-icon.css'

const CustomExpandIcon = ({ dataSource, record, expanded, onExpand }) => {
	if (record.children?.length) {
		return (
			<div className={expanded ? 'table-tree table-tree__close' : 'table-tree table-tree__open'}>
				<DownOutlined
					onClick={(e) => {
						onExpand(record, e)
					}}
				/>
			</div>
		)
	}
	return <span className="empty-span" />
}

export default CustomExpandIcon
