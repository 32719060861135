import { ErrorToFront } from '@berry/common-functions'
import axiosHttp from 'axios'
import { services, getText } from '../../index'

export const refreshToken = async () => {
	try {
		const refresh = localStorage.getItem('refresh')
		if (refresh) {
			const result = await axiosHttp.post(services['auth-ms'] + '/pub/refresh', {
				refreshToken: refresh,
			})
			if (result.data?.result) {
				localStorage.setItem('token', result?.data?.result.accessToken)
				localStorage.setItem('refresh', result?.data?.result.refreshToken)
				return true
			}
		}
	} catch (err) {
		localStorage.removeItem('token')
		localStorage.removeItem('refresh')
		window.name = ''
	}
}

const defaultOptions = {
	baseURL: `${services['office-ms']}/pub`,
	headers: {
		'Content-Type': 'application/json',
	},
}

const instance = axiosHttp.create(defaultOptions)
instance.CancelToken = axiosHttp.CancelToken

instance.interceptors.request.use(
	(config) => {
		const accessToken = localStorage.getItem('token')
		config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : ''
		if (window.name) config.headers['X-user-uuid'] = window.name
		return config
	},
	(error) => console.log(error)
)

instance.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response?.status === 400) {
			if (error.response.data.text) {
				throw new ErrorToFront(error.response.data.text)
			}
			throw new Error(
				error.response.data.err ? error.response.data.err : getText(error.response.data.code)
			)
		}

		const originalRequest = error.config
		if (error.response?.status === 401 && !originalRequest.retry) {
			originalRequest.retry = true
			try {
				if (!instance.refreshReq) {
					instance.refreshReq = refreshToken()
				}
				await instance.refreshReq
				instance.refreshReq = null
				return instance(originalRequest)
			} catch (err) {
				instance.refreshReq = null
				throw err
			}
		}
		throw error
	}
)

export default instance
