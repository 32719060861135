import { Table } from 'antd'
import React, { useContext, useState } from 'react'
import { RecordItemModal, TabItem, CustomForm } from '../../../../components'
import { AuthContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns, getFormItems } from './caliber-tab'
import { ProductCatalogItemMainContext } from '../provider/main'
import lodash from 'lodash'
import { prepareForOpenedModal } from '../../../../utils/helpers/for-modal'

export const CaliberTab = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(ProductCatalogItemMainContext)

	const stateFunctions = mainCtx.stateFunctions.calibers
	let stateData = mainCtx.state.calibers || []

	const authCtx = useContext(AuthContext)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [itemForModal, setItemForModal] = useState(null)
	stateData = prepareForOpenedModal(stateData, itemForModal)
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const newUuid = stateFunctions.create()
					setItemForModal({ _uuid_: newUuid, isNew: true })
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						width={'45%'}
						isOpen={isModalOpen}
						showErrorModal={true}
						title="Калибр"
						item={itemForModal}
						deleteFun={stateFunctions.delete}
						setItem={setItemForModal}
						setModalState={setIsModalOpen}
						editFun={stateFunctions.edit}
						beforeClose={() => {
							mainCtx.errorFields.calibers.forEach((e) => {
								mainCtx.setError([], [], e, undefined)
							})
						}}
						validate={() => {
							return mainCtx.validators.calibers(itemForModal._uuid_)
						}}
						form={() => {
							if (!itemForModal) return null

							return (
								<CustomForm
									name="calibre"
									fields={[
										{ name: 'valueOne', value: stateFunctions.get(itemForModal._uuid_).valueOne },
										{ name: 'symbol', value: stateFunctions.get(itemForModal._uuid_).symbol },
										{ name: 'valueTwo', value: stateFunctions.get(itemForModal._uuid_).valueTwo },
										{
											name: 'vocMeasure',
											value: stateFunctions.get(itemForModal._uuid_).vocMeasure,
										},
									]}
									columns={[
										{ size: { xs: 24, lg: 7 }, num: 0 },
										{ size: { xs: 24, lg: 5 }, num: 1 },
										{ size: { xs: 24, lg: 7 }, num: 2 },
										{ size: { xs: 24, lg: 5 }, num: 3 },
									]}
									items={getFormItems(mainCtx, modCtx, itemForModal)}
								/>
							)
						}}
					/>
				)
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(
							mainCtx,
							modCtx,
							(record) => {
								if (modCtx.mod === modes.view) return
								setItemForModal({ _uuid_: record._uuid_, prevData: lodash.cloneDeep(record) })
								setIsModalOpen(true)
							},
							authCtx
						)}
						dataSource={stateData
							.slice()
							.sort((a, b) =>
								+a.displayCode > +b.displayCode ? 1 : +a.displayCode < +b.displayCode ? -1 : 0
							)}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)
			}}
		/>
	)
}
