import React from 'react'
import { Space, Button, Typography } from 'antd'
import { sliceStringAndAddThreeDots } from '@berry/front-common'
import { openNewTab } from '../../utils'

const ContentForDepsModal = ({ deps, onClick }) => {
	return (
		<Space direction="vertical">
			{deps.slice(0, 10).map((e) => (
				<Button key={e.title} type="link" onClick={() => onClick(e.link)}>
					{sliceStringAndAddThreeDots(e.title, 50)}
				</Button>
			))}
			{deps.length > 10 && <Typography.Text>...связей еще {deps.length - 10} шт</Typography.Text>}
		</Space>
	)
}

export default ContentForDepsModal
