import React, { useContext, useEffect, useRef } from 'react'
import { ListContext, SyncDepsContext } from '../../../../../contexts'
import moment from 'moment'
import lodash from 'lodash'
import { getCommonProviderModalFunctions } from '../../../../../utils/helpers/generators'
import { calcProdTaskIsTolling } from '../../../../../utils/helpers/cross-pages-funcs'
import {
	startUseEffectHandlerForList,
	everyOtherTimeUseEffectHandlerForList,
	axios,
} from '../../../../../utils'
import {
	getStockCalculatedParams,
	numToFixed,
} from '@berry/common-functions/cross-project-functions'
import { getTaskWithStockRawMatCompl } from '../../../../../utils/helpers/cross-pages-funcs'

const dataUrl = '/stock/wastes'

const filterStatuses = [
	'Бронь',
	'Хранение',
	'Отклонено 1С',
	'На оформлении в 1С',
	'Отправляется в 1С',
	'Ошибка отправки в 1С',
	'К отгрузке',
]

export const reducer = (state) => {
	return {
		...state,
	}
}

const StockWasteListMainContext = React.createContext()
StockWasteListMainContext.displayName = 'StockWasteListMainContext'

const Provider = (props) => {
	const {
		state: { page, pageSize, mainFilter, filters, sorter, isInStock, weightFrom, weightTo },
		setTotal,
	} = useContext(ListContext)
	const { children } = props
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: {
			idOrder: [],
			partyNum: [],
			articul: [],
			articul1C: [],
			prodCat: [],
			prodCatKind: [],
			pkgType: [],
			contType: [],
			nettoPkgWeight: [],
			roomNum: [],
			status: [],
		},
		isInitialized: false,
		isLoading: false,
		relocationModal: { __isOpen: false },
		utilizationModal: { __isOpen: false },
		fromServerSelectors: {},
		isModalsServerEditSend: false,
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = newState
		dispatch({ ...state })
	}

	const resetModals = () => {
		executeDispatch({
			...stateRef.current,
			relocationModal: { __isOpen: false },
			utilizationModal: { __isOpen: false },
		})
	}

	const modalSetOpen = (val, modalName) => {
		executeDispatch({ ...stateRef.current, [modalName]: { isOpen: val } })
	}

	const selectors = {
		utilizationModal: {
			customer: lodash.sortBy(stateRef.current.fromServerSelectors.customers, ['label']),
		},
		relocationModal: {
			roomNum: stateRef.current.fromServerSelectors.room?.filter((room) => {
				return room.type === 'Складское'
			}),
		},
	}

	useEffect(() => {
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				isInStock,
				sorter,
				mainFilter,
				filters,
				offset: pageSize * page - pageSize,
				limit: pageSize,
				weightFrom,
				weightTo,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [])
	const everyOtherTimeUseEffectHandlerForListFunc = () =>
		everyOtherTimeUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				offset: pageSize * page - pageSize,
				limit: pageSize,
				sorter,
				mainFilter,
				filters,
				search: stateRef.current.search,
				isInStock,
				isSelectorsLoaded: true,
				weightFrom,
				weightTo,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	useEffect(() => {
		everyOtherTimeUseEffectHandlerForListFunc()
	}, [
		page,
		sorter,
		mainFilter,
		filters,
		stateRef.current.search,
		syncDepsCtx.state.reloadUuids['office-ms'],
		isInStock,
		stateRef.current.search,
		weightFrom,
		weightTo,
	])

	const modalsServerEdit = async () => {
		let options = { url: dataUrl, method: 'PUT' }
		let item
		if (stateRef.current.utilizationModal.storage) {
			item = stateRef.current.utilizationModal.storage.storages.find(
				(el) => el.id === stateRef.current.utilizationModal.storage.idStorage
			)
		}
		if (stateRef.current.relocationModal.storage) {
			item = stateRef.current.relocationModal.storage.storages.find(
				(el) => el.id === stateRef.current.relocationModal.storage.idStorage
			)
		}

		let body = {}
		if (stateRef.current.utilizationModal.storage) {
			if (
				stateRef.current.utilizationModal.weight ===
				stateRef.current.utilizationModal.storage.weight.toString()
			) {
				body = {
					id: stateRef.current.utilizationModal.storage.id,
					storages: [
						{
							id: stateRef.current.utilizationModal.storage.idStorage,
							weight: stateRef.current.utilizationModal.weight,
							status: 'Утилизировано',
							date: moment(),
						},
					],
					to1cData: { customer: stateRef.current.utilizationModal.customer },
				}
			}
			if (
				Number(stateRef.current.utilizationModal.weight) <
				Number(stateRef.current.utilizationModal.storage.weight)
			) {
				body = {
					id: stateRef.current.utilizationModal.storage.id,
					storages: [
						{
							id: stateRef.current.utilizationModal.storage.idStorage,
							weight:
								stateRef.current.utilizationModal.storage.weight -
								stateRef.current.utilizationModal.weight,
						},
						{
							...item,
							id: null,
							weight: stateRef.current.utilizationModal.weight,
							date: moment(),
							status: 'Утилизировано',
						},
					],
					to1cData: { customer: stateRef.current.utilizationModal.customer },
				}
				delete body.storages[1].id
				if (stateRef.current.utilizationModal.refund) delete body.to1cData
			}
		}
		if (stateRef.current.relocationModal.storage) {
			body = {
				id: stateRef.current.relocationModal.storage.id,
				storages: [
					{
						id: stateRef.current.relocationModal.storage.idStorage,
						room: stateRef.current.relocationModal.roomNum,
						date: moment(),
					},
				],
			}
		}

		executeDispatch({ ...stateRef.current, isModalsServerEditSend: true })
		const resp = await axios.put(options.url, body)
		executeDispatch({ ...stateRef.current, isModalsServerEditSend: false })
		everyOtherTimeUseEffectHandlerForListFunc()
		resetModals()
		return { method: options.method, resp: resp }
	}

	const sendUtilizationToServer = async () => {
		let options = { dataUrl, url: `${dataUrl}/utilization`, method: 'PUT' }
		const groupedByIds = stateRef.current.utilizationModal.items.reduce((acc, cur) => {
			if (acc[String(cur.id)]) {
				acc[String(cur.id)].push(cur)
			} else {
				acc[String(cur.id)] = [cur]
			}
			return acc
		}, {})
		let body = []
		Object.entries(groupedByIds).forEach(([id, storages]) => {
			body.push({
				id: +id,
				articul: stateRef.current.utilizationModal.items.find((e) => +e.id === +id).articul,
				storages: storages.map((e) => {
					return {
						id: e.idStorage,
						oldStatus: e.status,
						status: 'На оформлении в 1С',
						newStatus: 'Утилизировано',
					}
				}),
			})
		})
		const resp = await axios.put(options.url, body)
		everyOtherTimeUseEffectHandlerForListFunc()
		resetModals()
		return { method: options.method, id: resp }
	}

	const dropStatus = async (el) => {
		const resp = await axios.put(
			dataUrl,
			{
				id: el.id,
				storages: [
					{
						id: el.idStorage,
						status: el.storages.find((s) => s.id === el.idStorage).oldStatus,
						oldStatus: null,
					},
				],
			},
			{
				method: 'PUT',
				url: dataUrl,
			}
		)
		return { method: 'PUT', resp }
	}

	const toDisplayDataList = () => {
		return stateRef.current.fromServer
	}

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		selectors,
		[
			{ field: 'weight', updateVal: 'common', modalName: 'utilizationModal' },
			{ field: 'weight', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'utilizationModal' },
			{ field: 'items', updateVal: 'common', modalName: 'utilizationModal' },

			{ field: 'storage', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'roomNum', updateVal: 'obj', modalName: 'relocationModal' },
		]
	)

	const value = {
		state: stateRef.current,
		toDisplayDataList: toDisplayDataList(),
		selectors,
		fromServerTotalSum: stateRef.current.fromServerTotalSum,
		isModalsServerEditSend: stateRef.current.isModalsServerEditSend,
		utilizationModal: lodash.cloneDeep(stateRef.current.utilizationModal),
		relocationModal: lodash.cloneDeep(stateRef.current.relocationModal),
		repackingModal: lodash.cloneDeep(stateRef.current.repackingModal),
		modalsServerEdit,
		resetModals,
		modalSetOpen,
		modalFunctions,
		sendUtilizationToServer,
		dropStatus,
		filterStatuses,
		setSearch,
	}

	return (
		<StockWasteListMainContext.Provider value={value}>
			{children}
		</StockWasteListMainContext.Provider>
	)
}

export const prepareList = (data) => {
	let preparedData = []

	data
		.filter((it) => it.isPermanent)
		.forEach((it) => {
			const { articul, articul1C, prodCatKind, prodCat, partyNum, isTolling, ctrlDate, pkgType } =
				getStockCalculatedParams(it)
			it.storages.forEach((el) => {
				if (+el.weight === 0) return
				it = {
					...it,
					vocContType: el.vocContType,
					weight: numToFixed(Number(el.weight), 2),
					partyNum: it.taskRep ? it.taskRep?.partyNum : partyNum,
					partyNumForSorter: it.taskRep ? it.taskRep?.partyNum.slice(5, 9) : partyNum.slice(5, 9),
					articul: articul,
					articul1C: articul1C,
					prodCat: it.taskRep ? it.taskRep.task.prodCat : prodCat,
					ctrlDate: it.taskRep ? it.taskRep.task.date : ctrlDate,
					prodCatKind: prodCatKind,
					pkgType: el.vocContType?.labelShort ? el.vocContType?.labelShort : pkgType,
					isTolling: it.taskRep?.task ? calcProdTaskIsTolling(it.taskRep?.task) : isTolling,
					roomNum: el.room?.displayCode,
					status: el.status,
					idStorage: el.id,
					idOrder:
						it.idContrOrder ||
						getTaskWithStockRawMatCompl(it.taskRep?.task?.complectations?.[0], {})?.stockRawMatStor
							?.stockRawMat.supplProd?.order?.id ||
						'',
				}
				preparedData = [...preparedData, it]
			})
		})
	return preparedData
}
export { Provider, StockWasteListMainContext }
