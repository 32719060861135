import { getColumnSearchProps, openNewTab } from '../../../../../utils'
import { pageUrl as taskPageUrl } from '../../../../ProductionTask/ProductionTaskList/ProductionTaskList'
import { frontUrl as implmntPageUrl } from '../../../../Implementation/ImplementationItem/provider/main'

export const getDisplayColumns = ({ fromServerFilters, className, setClassName, authCtx }) => {
	return [
		{
			title: 'Тип',
			dataIndex: 'type',
			key: 'type',
			width: '4.09%',
			...getColumnSearchProps(fromServerFilters?.['type'], undefined, 'type'),
		},
		{
			title: 'ID задачи',
			dataIndex: 'taskId',
			key: 'taskId',
			width: '7.81%',
			sorter: true,
			onCell: (record) => ({
				className: `tab-link ${className}`,
				onClick: (e) => {
					e.stopPropagation()
					openNewTab(`${taskPageUrl}/${record.taskId}`, { authCtx })
				},
				onMouseEnter: () => {
					setClassName('')
				},
				onMouseLeave: () => {
					setClassName('highlight')
				},
			}),
			...getColumnSearchProps(fromServerFilters?.['taskId'], undefined, 'taskId'),
		},
		{
			title: 'Дата посл. опер.',
			dataIndex: 'date',
			key: 'date',
			width: '11.08%',
			sorter: true,
			...getColumnSearchProps(fromServerFilters?.['date'], undefined, 'date'),
		},
		{
			title: '№ партии',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '7.49%',
			...getColumnSearchProps(fromServerFilters?.['partyNum'], undefined, 'partyNum'),
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			key: 'articul',
			width: '7.3%',
			...getColumnSearchProps(fromServerFilters?.['articul'], undefined, 'articul'),
		},
		{
			title: 'Артикул 1С',
			dataIndex: 'articul1C',
			key: 'articul1C',
			width: '7.3%',
			...getColumnSearchProps(fromServerFilters?.['articul1C'], undefined, 'articul1C'),
		},
		{
			title: 'Вид',
			dataIndex: 'prodCatKind',
			key: 'prodCatKind',
			width: '17.87%',
			...getColumnSearchProps(fromServerFilters?.['prodCatKind'], undefined, 'prodCatKind'),
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '6.34%',
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '8.96%',
			...getColumnSearchProps(fromServerFilters?.['status'], undefined, 'status'),
			onCell: (record) => ({
				className,
				...(['Продажа', 'Сработано'].includes(record.status) && {
					onMouseEnter: () => {
						setClassName('')
					},
					onMouseLeave: () => {
						setClassName('highlight')
					},
				}),
			}),
			render: (status, record) =>
				['Продажа', 'Сработано'].includes(record.status) ? (
					<div
						className={`tab-link ${className}`}
						onClick={(e) => {
							const url = status === 'Продажа' ? implmntPageUrl : taskPageUrl
							const id = status === 'Продажа' ? record.implmntId : record.taskId
							e.stopPropagation()
							openNewTab(`${url}/${id}`, { authCtx })
						}}
					>
						{status}
					</div>
				) : (
					status
				),
		},
		{
			title: 'Причина блокировки',
			dataIndex: 'blockCause',
			key: 'blockCause',
			width: '11.26%',
		},
	]
}
