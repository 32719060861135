import { getColumnSearchProps, sorter, renderPossiblyEmptyString } from '../../../utils'

export const getColumns = (props) => {
	const { fromServerFilters } = props
	return [
		{
			title: 'Заказчик',
			dataIndex: 'label',
			key: 'label',
			...getColumnSearchProps(fromServerFilters.label),
			sorter: sorter('string', 'label'),
			width: '60%',
			render: renderPossiblyEmptyString,
		},
		{
			title: 'ИНН',
			dataIndex: 'inn',
			key: 'inn',
			width: '20%',
			render: renderPossiblyEmptyString,
		},
		{
			title: 'КПП',
			dataIndex: 'kpp',
			key: 'kpp',
			width: '20%',
			render: renderPossiblyEmptyString,
		},
	]
}
