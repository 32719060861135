import { Table } from 'antd'
import React, { useContext } from 'react'
import { StockOpsDisposalItemMainContext } from '../provider/main'
import { TabItem } from '../../../../../components'
import { prepareData } from '../../../../../utils'
import { getDisplayColumns } from './utils'

const EventHistoryTab = ({ modalItem, setModalItem, isModalOpen, setIsModalOpen }) => {
	const mainCtx = useContext(StockOpsDisposalItemMainContext)
	const columns = getDisplayColumns()
	const dataSource = prepareData(mainCtx.state.eventHistories || [])
	return (
		<TabItem
			addButton={{ show: false }}
			table={() => (
				<Table
					size="small"
					rowKey="_uuid_"
					columns={columns}
					dataSource={dataSource}
					pagination={false}
					scroll={{ x: 800 }}
				/>
			)}
		/>
	)
}

export default EventHistoryTab
