import React, { useContext, useState } from 'react'
import { Card } from 'antd'
import { UserDataContext, ListProvider } from '../../contexts'
import { HeaderMenu, StatefulTable } from '..'
import { vocabularies } from './vocabularies'
import { getColumnSearchProps, applyFilterForList } from '../../utils'
import SetNotEditIcon from './SetNotEditIcon'
import { checkRole } from '@berry/common-functions/role'

const searchFields = ['title']

const VocabulariesList = () => {
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const [state, setState] = useState({
		search: null,
		fromServer: vocabularies.filter((voc) =>
			checkRole(
				{
					Справочники: {
						[voc.title]: {
							view: true,
						},
					},
				},
				currentRights
			)
		),
	})

	const setSearch = (value) => {
		const newState = { ...state, search: value?.toString() || '' }
		setState(newState)
	}

	const toDisplayDataList = applyFilterForList(state.fromServer, {
		search: state.search,
		searchFields,
	})

	return (
		<ListProvider>
			<HeaderMenu search={setSearch} />
			<Card>
				<StatefulTable
					dataSource={toDisplayDataList}
					url="vocabularies"
					columns={() => [
						{
							title: 'Наименование',
							dataIndex: 'title',
							key: 'title',
							...getColumnSearchProps(
								toDisplayDataList.map((e) => e.title),
								'title'
							),
							onFilter: (value, record) => value === record.title,
							render: (text, record) => SetNotEditIcon(record),
						},
					]}
					rowKey={'link'}
					pagination={{ pageSize: 17 }}
					size="small"
					scroll={{ x: 800 }}
				/>
			</Card>
		</ListProvider>
	)
}

export default VocabulariesList
