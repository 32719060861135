import {
	checkInputCtrlLabIndicatorColor,
	getSpecParamDeviation,
} from '@berry/common-functions/cross-project-functions'
import { setNumField } from '@berry/front-common/for-fields'
import { Col, Select, Upload } from 'antd'
import { modes, getRowSpan } from '../../../../../utils'
import { getEtalon } from '../../../../Specification/SpecificationItem/specification-item'
import { Carousel, InputError } from '../../../../../components'
import { ReactComponent as AddPhotoIcon } from '../../../../../assets/svg/addPhoto.svg'
import { basicValidator } from '@berry/common-functions/validators'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 * @param {Object} modCtx
 * @param {function} editHandler - обработка нажатия на карандаш
 */

/**
 * Группирует данные по разделу параметров спецификации
 * @param {Array<Object>} inLabIndics - лабораторные показатели
 */
export const prepareData = (inLabIndics) => {
	const groupedBySection = inLabIndics.reduce((acc, cur, i) => {
		let foundIndx = acc.findIndex(
			(e) => String(cur.specParam.vocSpecParamParam.spec.id) === String(e.idSection)
		)
		const result = [...acc]
		if (foundIndx !== -1) {
			result[foundIndx].children.push(cur)
		} else {
			result.push({
				idSection: cur.specParam.vocSpecParamParam.spec.id,
				sectionLabel: cur.specParam.vocSpecParamParam.spec.label,
				children: [
					{ ...cur, key: `${cur.specParam.vocSpecParamParam.spec.id} + ${cur.specParam.id}` },
				],
				key: `${cur.specParam.vocSpecParamParam.spec.id}`,
			})
		}
		return result
	}, [])
	return groupedBySection
}

export const getDisplayColumns = (mainCtx, modCtx, addPhoto, removePhoto) => {
	const stateFunctions = mainCtx.stateFunctions.labIndics
	let isDisabled = false
	if (modCtx.mod === modes.view) isDisabled = true

	return [
		{
			title: 'Раздел',
			key: 'param',
			width: '13.01%',
			dataIndex: ['ctrlLabIndic', 'specParam', 'vocSpecParamParam', 'spec', 'label'],
			onCell: (rec) => ({
				rowSpan: getRowSpan(rec, rec.ctrlLabIndicators.length),
			}),
		},
		{
			title: 'Параметр',
			key: 'param',
			width: '13.01%',
			render: (_, rec) => {
				return rec.ctrlLabIndic.sectionLabel || rec.ctrlLabIndic.specParam.vocSpecParamParam.label
			},
		},
		{
			title: 'ЕИ',
			dataIndex: ['ctrlLabIndic', 'specParam', 'vocSpecParamParam', 'vocMeasure', 'labelShort'],
			key: 'vocMeasure',
			width: '3.92%',
		},
		{
			title: 'Факт. значение',
			key: 'fact',
			width: '10.14%',
			rules: [{ required: true }],
			render: (_, rec) => {
				if (!rec.ctrlLabIndic.specParam) return null
				if (rec.ctrlLabIndic.factText) {
					let className = null
					if (rec.ctrlLabIndic.factText === 'NC' && basicValidator(rec.ctrlLabIndic.factText))
						className = 'red-field-selector'
					return (
						<InputError
							hasError={mainCtx.formErrors?.[`factText.${rec.ctrlLabIndic._uuid_}`]}
							component={
								<Select
									value={rec.ctrlLabIndic.factText}
									className={className}
									style={{ width: '100%', minWidth: '120px' }}
									onChange={(el) => {
										stateFunctions.setFactText(rec.ctrlLabIndic._uuid_, el)
									}}
									disabled={isDisabled}
									notFoundContent={<Col>Не найдено</Col>}
								>
									{mainCtx.selectors.labIndics.factText.map((it) => (
										<Select.Option key={it} value={it}>
											{it}
										</Select.Option>
									))}
								</Select>
							}
						/>
					)
				}

				let backgroundColor = 'inherit'
				if (
					checkInputCtrlLabIndicatorColor(rec.ctrlLabIndic, 'etalon') ||
					!basicValidator(rec.ctrlLabIndic.factVal) ||
					isNaN(rec.ctrlLabIndic.factVal)
				) {
					backgroundColor = 'inherit'
				} else if (checkInputCtrlLabIndicatorColor(rec.ctrlLabIndic, 'deviation')) {
					backgroundColor = 'rgba(255, 250, 204, 1)'
				} else {
					backgroundColor = 'rgba(255, 168, 168, 1)'
				}
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`factVal.${rec.ctrlLabIndic._uuid_}`]}
						style={{ width: '100%', backgroundColor }}
						value={rec.ctrlLabIndic.factVal}
						disabled={isDisabled}
						onChange={(e) => {
							setNumField(
								e.target.value,
								rec.ctrlLabIndic.factVal,
								stateFunctions.setFactVal,
								'float',
								[14, 5],
								{
									_uuid_: rec.ctrlLabIndic._uuid_,
								}
							)
						}}
					/>
				)
			},
		},
		{
			title: 'Цель',
			key: 'etalon',
			width: '21.86%',
			render: (_, rec) => {
				if (rec.ctrlLabIndic?.specParam) return getEtalon(rec.ctrlLabIndic?.specParam)
				return ''
			},
		},
		{
			title: 'Допустимое отклонение',
			key: 'deviation',
			width: '21.21%',
			render: (_, rec) => {
				if (rec.ctrlLabIndic?.specParam) return getSpecParamDeviation(rec.ctrlLabIndic?.specParam)
				return ''
			},
		},
		{
			title: 'Фото эталон',
			dataIndex: 'etalonPhotos',
			key: 'etalonPhotos',
			width: '9.62%',
			render: (_, record) =>
				record.ctrlLabIndic.specParam?.photos?.length ? (
					<Carousel
						files={record.ctrlLabIndic.specParam.photos}
						title={record.ctrlLabIndic.specParam.vocSpecParamParam.label}
						disabled={true}
						path={'photoPath'}
						previewStyle={{
							display: 'flex',
							maxHeight: '3vh',
							height: '3vh',
						}}
					/>
				) : (
					''
				),
			onCell: (record) => ({
				style: {
					padding: 4,
					overflow: 'hidden',
				},
			}),
		},
		{
			title: 'Фото',
			dataIndex: 'photos',
			key: 'photos',
			width: '9.62%',
			render: (_, record) =>
				record.ctrlLabIndic.photos?.length ? (
					<Carousel
						files={record.ctrlLabIndic.photos}
						delFn={(photo) => removePhoto(record.ctrlLabIndic._uuid_, photo)}
						title={record.ctrlLabIndic.specParam.vocSpecParamParam.label}
						disabled={isDisabled}
						path={'photoPath'}
						previewStyle={{
							display: 'flex',
							maxHeight: '3vh',
							height: '3vh',
						}}
					/>
				) : (
					''
				),
			onCell: (record) => ({
				style: {
					padding: 4,
					overflow: 'hidden',
				},
			}),
		},
		{
			title: null,
			key: 'photoAction',
			width: '4.61%',
			render: (record) => {
				if (isDisabled || !record.ctrlLabIndic.specParam) return null
				const disable = isDisabled || record.ctrlLabIndic.photos?.length >= 10
				return (
					<Upload
						multiple
						accept=".png,.jpg,.bmp,.gif,.tif"
						onChange={({ file, fileList }) => {
							if (fileList?.length > 10) return
							if (!file?.type?.includes('image')) {
								return
							}
							if (file.status === 'uploading') addPhoto(record.ctrlLabIndic._uuid_, fileList)

							addPhoto(record.ctrlLabIndic._uuid_, fileList)
						}}
						customRequest={({ onSuccess, onError, file }) => onSuccess(null)}
						fileList={record.ctrlLabIndic.photos || []}
						showUploadList={false}
						disabled={disable}
					>
						<AddPhotoIcon
							style={{
								fontSize: 20,
								opacity: disable ? 0.3 : 1,
								cursor: disable ? 'not-allowed' : 'pointer',
							}}
							className="ant-btn ant-btn-text ant-btn-icon-only"
						/>
					</Upload>
				)
			},
		},
	]
}
