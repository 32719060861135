import { Checkbox } from 'antd'
import { Status } from '../../../components'
import { getColumnSearchProps, sorter } from '../../../utils'
import { dateFormat } from '../../../utils/constants/common'
import moment from 'moment'

/**
 * @param {Object} fromServerFilters - массив с записями таблицы
 */
export const getColumns = (props) => {
	const { fromServerFilters } = props

	return [
		{
			title: 'Бесплатные образцы',
			dataIndex: 'isFreeSamples',
			key: 'isFreeSamples',
			width: '11.34%',
			sorter: sorter('boolean', 'isFreeSamples'),
			render: (isFreeSamples) => <Checkbox disabled checked={isFreeSamples} />,
		},
		{
			title: 'ID реализации',
			dataIndex: 'id',
			key: 'id',
			width: '8.98%',
			sorter: sorter('number', 'id'),
			...getColumnSearchProps(fromServerFilters.id),
		},
		{
			title: 'Дата реализации',
			dataIndex: 'date',
			key: 'date',
			width: '10.32%',
			render: (date) => (date ? moment(date).format(dateFormat) : null),
			sorter: sorter('date', 'date'),
			...getColumnSearchProps(fromServerFilters.date, undefined, null, true),
		},
		{
			title: 'ID ДЗ',
			dataIndex: 'idContrOrder',
			key: 'idContrOrder',
			width: '5.73%',
			sorter: sorter('number', 'idContrOrder'),
			...getColumnSearchProps(fromServerFilters.idContrOrder),
		},
		{
			title: 'Заказчик',
			key: 'customerLabel',
			width: '17.84%',
			...getColumnSearchProps(fromServerFilters.customerLabel),
			render: (record) => record.customerLabel || record.providerLabel,
		},
		{
			title: 'Продукт',
			dataIndex: 'prodCat',
			key: 'prodCat',
			width: '31%',
			sorter: sorter('string', 'prodCatForSorter'),
			...getColumnSearchProps(fromServerFilters.prodCat || []),
			render: (_, rec) => rec.prodCat.join(', '),
		},

		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '14.79%',
			render: (_, record) => <Status status={record.status} />,
			...getColumnSearchProps(fromServerFilters.status),
		},
	]
}
