import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Typography, Button } from 'antd'

const AccessDenied = (props) => {
	const history = useHistory()
	return (
		<Card
			style={{
				display: 'flex',
				height: '100%',
				justifyContent: 'center',
			}}
			bodyStyle={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<Typography.Title level={3}>Недостаточно прав для доступа к странице</Typography.Title>
			<Button style={{ alignSelf: 'center' }} onClick={() => history.push('/')}>
				На главную страницу
			</Button>
		</Card>
	)
}

export default AccessDenied
