module.exports = [
	{
		id: 1,
		okei: 163,
		label: 'Грамм',
		labelShort: 'г',
		coefficient: 0.001,
		characteristic: 'Масса',
		idTargetUnit: 2,
	},
	{
		id: 2,
		okei: 166,
		label: 'Килограмм',
		labelShort: 'кг',
		coefficient: 1,
		characteristic: 'Масса',
		idTargetUnit: 2,
	},
	{
		id: 3,
		okei: 168,
		label: 'Тонна',
		labelShort: 'т',
		coefficient: 1000,
		characteristic: 'Масса',
		idTargetUnit: 2,
	},
	{
		id: 4,
		okei: 3,
		label: 'Миллиметр',
		labelShort: 'мм',
		coefficient: 1,
		characteristic: 'Длина',
		idTargetUnit: 4,
	},
	{
		id: 5,
		okei: 355,
		label: 'минута',
		labelShort: 'мин',
		coefficient: 1,
		characteristic: 'Время',
		idTargetUnit: 5,
	},
	{
		id: 6,
		okei: 356,
		label: 'час',
		labelShort: 'ч',
		coefficient: 60,
		characteristic: 'Время',
		idTargetUnit: 5,
	},
	{
		id: 7,
		okei: 359,
		label: 'сутки',
		labelShort: 'сут',
		coefficient: 24,
		characteristic: 'Время',
		idTargetUnit: 6,
	},
	{
		id: 8,
		okei: 362,
		label: 'месяц',
		labelShort: 'мес',
		coefficient: 30,
		characteristic: 'Время',
		idTargetUnit: 7,
	},
	{
		id: 9,
		okei: 366,
		label: 'год',
		labelShort: 'г',
		coefficient: 365,
		characteristic: 'Время',
		idTargetUnit: 7,
	},
	{
		id: 10,
		okei: 796,
		label: 'штука',
		labelShort: 'шт',
		coefficient: 1,
		characteristic: 'Экономические единицы',
		idTargetUnit: 10,
	},
	{
		id: 11,
		okei: 4,
		label: 'сантиметр',
		labelShort: 'см',
		coefficient: 10,
		characteristic: 'Длина',
		idTargetUnit: 4,
	},
	{
		id: 12,
		okei: 162,
		label: 'Миллиграмм',
		labelShort: 'мг',
		coefficient: 0.000001,
		characteristic: 'Масса',
		idTargetUnit: 2,
	},
	{
		id: 13,
		okei: 280,
		label: 'градус Цельсия',
		labelShort: '°C',
		coefficient: 1,
		characteristic: 'Технические единицы',
		idTargetUnit: 13,
	},
	{
		id: 14,
		okei: 323,
		label: 'Беккерель',
		labelShort: 'Бк',
		coefficient: 1,
		characteristic: 'Технические единицы',
		idTargetUnit: 14,
	},
	{
		id: 15,
		okei: null,
		label: 'Процент',
		labelShort: '%',
		coefficient: null,
		characteristic: 'Экономические единицы',
		idTargetUnit: null,
	},
	{
		id: 16,
		okei: null,
		label: 'Градус Брикс',
		labelShort: '°Bx',
		coefficient: 1,
		characteristic: 'Спецификация',
		idTargetUnit: 16,
	},
	{
		id: 17,
		okei: null,
		label: 'Колониеобразующие единицы на грамм',
		labelShort: 'КОЕ/г',
		coefficient: null,
		characteristic: 'Спецификация',
		idTargetUnit: null,
	},
	{
		id: 18,
		okei: null,
		label: 'Миллиграм на килограмм',
		labelShort: 'мг/кг',
		coefficient: null,
		characteristic: 'Спецификация',
		idTargetUnit: null,
	},
	{
		id: 19,
		okei: null,
		label: 'Беккерель на килограмм',
		labelShort: 'Бк/кг',
		coefficient: null,
		characteristic: 'Спецификация',
		idTargetUnit: null,
	},
	{
		id: 20,
		okei: null,
		label: 'Килокалории',
		labelShort: 'ккал',
		coefficient: null,
		characteristic: 'Спецификация',
		idTargetUnit: null,
	},
	{
		id: 21,
		okei: null,
		label: 'Килограмм на сантиметр квадратный',
		labelShort: 'кг/см²',
		coefficient: null,
		characteristic: 'Спецификация',
		idTargetUnit: null,
	},
	{
		id: 22,
		okei: null,
		label: 'Безразмерная величина',
		labelShort: '-',
		coefficient: null,
		characteristic: 'Спецификация',
		idTargetUnit: null,
	},
	{
		id: 23,
		okei: null,
		label: 'Миллилитр на литр',
		labelShort: 'мл/л',
		coefficient: null,
		characteristic: 'Спецификация',
		idTargetUnit: 23,
	},
]
