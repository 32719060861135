import React from 'react'
import { Redirect } from 'react-router-dom'
import { UserDataContext } from '../../../contexts'
import { LoadingPage } from '../../../pages'

const IsUserDataLoaded = ({ children, ...props }) => {
	const {
		state: { isLoading, isInitialized },
	} = React.useContext(UserDataContext)
	if (isLoading || !isInitialized) return <LoadingPage />
	if (!isInitialized) return <Redirect push to="/access-denied" />
	return React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, { ...props })
		}
		return child
	})
}

export default IsUserDataLoaded
