import { Table } from 'antd'
import React, { useContext, useState } from 'react'
import { RequestItemMainContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { AuthContext } from '../../../../contexts'
import { prepareData } from '../../../../utils'
import { getDisplayColumns } from './supply-tab'
import rowClassName from '../../../../utils/helpers/rowClassName'
import clickableRow from '../../../../utils/helpers/clickableRow'

const SupplyTab = () => {
	const mainCtx = useContext(RequestItemMainContext)
	const authCtx = useContext(AuthContext)
	const [rowId, setRowId] = useState('')

	return (
		<TabItem
			addButton={{ show: false }}
			table={() => {
				return (
					<Table
						rowClassName={rowClassName(rowId)}
						size="small"
						columns={getDisplayColumns()}
						dataSource={prepareData(mainCtx.state.supplies, 'products', 'product')}
						pagination={false}
						scroll={{ x: 800 }}
						onRow={clickableRow(setRowId, authCtx, '/rm/supplies')}
					/>
				)
			}}
		/>
	)
}

export default SupplyTab
