import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import lodash from 'lodash'
import { ModContext } from '../../../../contexts'
import {
	getCommonProviderFunctions,
	getCommonProviderModalFunctions,
} from '../../../../utils/helpers/generators'
import { prepareObjFromServer, modes, useItemFetchers, axios, goToItem } from '../../../../utils'
import { useHistory } from 'react-router-dom'
import { v4 } from 'uuid'

const dataUrl = '/giver-prod/consolidated-reports'
const tabs = {
	reports: 'reports',
	'event-histories': 'eventHistories',
	produceds: 'produceds',
	balances: 'balances',
	supplies: 'supplies',
}
export const reducer = (state) => {
	return {
		...state,
	}
}

const ConsolidatedReportItemMainContext = React.createContext()
ConsolidatedReportItemMainContext.displayName = 'ConsolidatedReportItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const initialState = useMemo(
		() => ({
			data: { supplies: [], produceds: [], reports: [], balances: [], eventHistories: [] },
			oldData: { supplies: [], produceds: [], reports: [], balances: [], eventHistories: [] },
			formErrors: {},
			additional: {
				allSelectVocProdTypes: [],
				allSelectProdCats: [],
				allQualities: [],
				allMeasures: [],
				allSpecParams: [],
				allSpecNames: [],
				specParamFilters: {},
				allRequests: {},
			},
			addTo1cProduced: {
				__isOpen: false,
				addTo1cProducedReadyProds: [],
				addTo1cProducedWastes: [],
			},
		}),
		[]
	)
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))

	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			formErrors: [],
		})
	}, [])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * подготавливает данные которые нужно выбират
	 */

	const validate = () => {
		return true
	}

	const selectors = {
		vocMainSemiKind: stateRef.current.additional.vocMainSemiKinds,
	}

	const completeReport = async () => {
		let body = {
			id: stateRef.current.data.id,
			produceds: stateRef.current.data.produceds,
			idContract: stateRef.current.data.contract.id,
		}
		let options = {
			dataUrl,
			url: `${dataUrl}/complete-order`,
			method: 'PUT',
		}
		await axios.put(options.url, body)
	}

	const { isEdited, stateFunctions, serverEdit, serverDelete, setError } =
		getCommonProviderFunctions(
			stateRef,
			stateRef.current.oldData,
			executeDispatch,
			{ modCtx, dataUrl, pageUrl: '/reports/consolidated-reports', history, params },
			{},
			{},
			{
				labIndics: {
					factVal: 'common',
					factText: 'common',
				},
				storages: {
					blockCause: 'common',
					status: 'common',
				},
			}
		)

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		{},
		[
			{
				field: 'readyProds',
				updateVal: 'common',
				modalName: 'addTo1cProduced',
			},
			{
				field: 'wastes',
				updateVal: 'common',
				modalName: 'addTo1cProduced',
			},
		],
		[
			{
				mainField: 'wastes',
				field: 'wastes',
				updateVal: 'obj',
				modalName: 'addTo1cProduced',
			},
			{
				mainField: 'readyProds',
				field: 'readyProds',
				updateVal: 'obj',
				modalName: 'addTo1cProduced',
			},
		]
	)

	const depsFunctions = {}

	const sendTo1c = async () => {
		const body = {
			id: stateRef.current.data.id,
			readyProds: stateRef.current.addTo1cProduced.addTo1cProducedReadyProds,
			wastes: stateRef.current.addTo1cProduced.addTo1cProducedWastes,
			partyNums: [
				...stateRef.current.addTo1cProduced.addTo1cProducedReadyProds,
				...stateRef.current.addTo1cProduced.addTo1cProducedWastes,
			].map((e) => {
				const produced = stateRef.current.data.produceds.find((p) => p.id === e.id)
				return { id: e.id, partyNum: produced.partyNum }
			}),
		}
		await axios.put(dataUrl + '/produced-send-to-1c', body)
		executeDispatch({ ...stateRef.current, reloadUuid: v4() })
		goToItem(history, { url: '/reports/consolidated-reports', id: stateRef.current.data.id })
	}

	const balSendTo1c = async () => {
		const body = {
			id: stateRef.current.data.id,
			idContrOrder: stateRef.current.data.id,
			produceds: stateRef.current.addTo1cProduced.addTo1cProducedWastes.map((p) => {
				const prod = stateRef.current.data.produceds.find((prod) => prod.id === p.id)
				return {
					id: p.id,
					partyNum: prod.partyNum,
				}
			}),
			partyNums: stateRef.current.data.produceds.map((e) => {
				return { id: e.id, partyNum: e.partyNum }
			}),
		}
		await axios.put(dataUrl + '/prod-bal-send-to-1c', body)
		executeDispatch({ ...stateRef.current, reloadUuid: v4() })
		goToItem(history, { url: '/reports/consolidated-reports', id: stateRef.current.data.id })
	}

	const endOrderTo1c = async () => {
		const body = {
			id: stateRef.current.data.id,
			partyNums: stateRef.current.data.produceds.map((e) => {
				return { id: e.id, partyNum: e.partyNum }
			}),
		}
		await axios.put(dataUrl + '/end-order-send-to-1c', body)
		executeDispatch({ ...stateRef.current, reloadUuid: v4() })
		goToItem(history, { url: '/reports/consolidated-reports', id: stateRef.current.data.id })
	}

	const value = {
		state: lodash.cloneDeep(stateRef.current.data),
		additional: stateRef.current.additional,
		isPendingReq: stateRef.current.isPendingReq,
		selectors,
		addTo1cProduced: stateRef.current.addTo1cProduced,
		modalFunctions,
		stateFunctions,
		serverEdit,
		serverDelete,
		isEdited,
		reset,
		formErrors: stateRef.current.formErrors,
		sendTo1c,
		endOrderTo1c,
		balSendTo1c,
		completeReport,
		setError,
		depsFunctions,
		validate,
	}

	return (
		<ConsolidatedReportItemMainContext.Provider value={value}>
			{children}
		</ConsolidatedReportItemMainContext.Provider>
	)
}

export { Provider, ConsolidatedReportItemMainContext }
