import React from 'react'
import { Form as FormAntd, Input } from 'antd'

const formItemLayout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

const Form = (props) => {
	const { label, address, state } = props

	return (
		<FormAntd labelAlign="left">
			<FormAntd.Item
				{...formItemLayout}
				label="Наименование"
				getValueProps={(value) => value}
				name="label"
				onChange={label.onChange}
				rules={[
					{
						required: true,
					},
					{
						validator: async (_, value) => {
							if (state.some((e) => e.label.toLowerCase() === value.toLowerCase()))
								throw Error('Данное наименование уже добавлено')
						},
					},
				]}
			>
				<Input value={label.value} />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Адрес"
				getValueProps={(value) => value}
				name="address"
				onChange={address.onChange}
			>
				<Input value={address.value} />
			</FormAntd.Item>
		</FormAntd>
	)
}

export default Form
