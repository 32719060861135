import { InputError } from '../../../../../components'
import { Col, Select } from 'antd'
import { modes } from '../../../../../utils'
import { paintTextByStatus } from '../../../RawMat/RawMatItem/StorageTab/storage-tab'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { colorizeIntegrationListStatus } from './../../../../../utils/helpers/for-integration'
import { dateFormat } from '../../../../../utils/constants/common'
import moment from 'moment'
import clickableStatus from '../../../../../utils/helpers/clickableStatus'

export const getDisplayColumns = (mainCtx, modCtx, authCtx, userDataCtx) => {
	const stateFunctions = mainCtx.stateFunctions.storages
	const isDisabled = modCtx.mod === modes.view
	return [
		{
			title: '№ помещения',
			dataIndex: ['room', 'displayCode'],
			key: 'displayCode',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '12.48%',
			render: (_, record) => paintTextByStatus(numToFixed(record.weight, 2), record),
		},
		{
			title: 'Тип упаковки',
			dataIndex: ['vocContType', 'labelShort'],
			key: 'vocContType',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Масса нетто ед. упак., кг',
			dataIndex: 'nettoPkgWeight',
			key: 'nettoPkgWeight',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Дата',
			dataIndex: 'date',
			key: 'date',
			width: '7.41%',
			render: (date, record) =>
				paintTextByStatus(date ? moment(date).format(dateFormat) : null, record),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '6.24%',
			render: (_, record) => {
				if (record.status === 'Ошибка отправки в 1С')
					return colorizeIntegrationListStatus(record.status, true)
				return record.status
			},
			onCell: clickableStatus(authCtx, {}, userDataCtx),
		},
		{
			title: 'Причина блокировки',
			dataIndex: 'blockCause',
			key: 'blockCause',
			width: '12.71%',
			render: (_, record) => {
				return record.blockCauses?.map((e) => e.blockCause.label).join(', ')
			},
		},
		{
			title: 'Комментарий',
			dataIndex: 'comment',
			key: 'comment',
			width: '11.24%',
		},
	]
}
