import React, { useContext, useState } from 'react'
import { Drawer as AntDrawer, Space, Typography } from 'antd'
import { CloseOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { MenuContext, menuPositions } from '../../contexts'
import { ReactComponent as SettingsIcon } from '../../assets/svg/settings.svg'
import './drawer.css'

const Drawer = () => {
	const { position, togglePosition } = useContext(MenuContext)
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)

	return (
		<AntDrawer
			width={350}
			closable={false}
			onClose={() => setIsDrawerOpen(false)}
			visible={isDrawerOpen}
			handler={
				<div
					className={`settings-icon ${isDrawerOpen ? ' opened' : ''}`}
					onClick={() => setIsDrawerOpen(!isDrawerOpen)}
				>
					{isDrawerOpen ? <CloseOutlined /> : <SettingsIcon />}
				</div>
			}
		>
			<Space direction="vertical">
				<Typography.Text>Режим отображения меню</Typography.Text>
				<Space direction="horizontal">
					<div className="layout-icon left" onClick={() => togglePosition(menuPositions.left)}>
						{position === menuPositions.left && (
							<span
								style={{
									position: 'absolute',
									right: 0,
									bottom: 0,
									color: '#3b90f7',
								}}
							>
								<CheckCircleOutlined />
							</span>
						)}
					</div>
					<div className="layout-icon top" onClick={() => togglePosition('top')}>
						{position === menuPositions.top && (
							<span
								style={{
									position: 'absolute',
									right: 0,
									bottom: 0,
									color: '#3b90f7',
								}}
							>
								<CheckCircleOutlined />
							</span>
						)}
					</div>
				</Space>
			</Space>
		</AntDrawer>
	)
}

export default Drawer
