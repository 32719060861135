import React, { useContext } from 'react'
import { AuthContext, ModContext, ModProvider } from '../../../contexts'
import { Main } from '../../../layouts'
import { Provider, SupplyUnloadContext } from './provider/main'
import { Card, Row, Col, Form } from 'antd'
import {
	ActionButtons,
	CustomForm,
	HeaderMenu,
	TabList,
	showConfirmModal,
	CustomBreadcrumbItem,
} from '../../../components'
import {
	actBtnOnClose,
	actBtnOnDelete,
	actBtnOnSaveV2,
	axios,
	modes,
	openNewTab,
} from '../../../utils'
import {
	calcDiffPkgCount,
	getFormItems1,
	getFormItems2,
	getFormItems3,
	getFormItems4,
} from './supply-unload-item'
import { useHistory } from 'react-router'
import PalDistribTab from './PalDistribTab/PalDistribTab'
import { calcTotalPalCount } from './supply-unload-item'
import StorageTab from './StorageTab/StorageTab'
import { getBreadcrumbsText } from '../../../utils/helpers/generators'
import Title from 'antd/lib/typography/Title'
import { UserDataContext } from '../../../contexts'
import {
	supplyCalcAvgNettoPkgWeight,
	calcSupplyInpCtrlDiffWeight,
} from '@berry/common-functions/cross-project-functions'
import { entityToModelMap } from '@berry/static-data/all-models'
import { showIsBLockedModalForDeps } from '../../../utils/constants/for-components'
import {
	EDIT_NOT_PERMIT_BECAUSE_OF_BLOCK,
	INACTION_AWARE,
} from '../../../utils/constants/plain-text'

const pageUrl = '/rm/supply-unloads'

const SupplyUnloadItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Разгрузка">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(SupplyUnloadContext)
	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	let history = useHistory()

	const [form] = Form.useForm()
	const breadCrumb = getBreadcrumbsText(pageUrl, mainCtx.state)
	const customBreadcrumbItems = [
		{ title: 'Главная', link: '/' },
		{ title: 'Сырье', link: null },
		{ title: 'Поставки сырья', link: '/rm/supplies' },
		{ title: breadCrumb.head, link: `/rm/supplies/${mainCtx.state?.supply?.id}` },
		{ title: breadCrumb.label, link: null },
	].map((e, i) => (
		<CustomBreadcrumbItem key={i} link={e.link} title={e.title}></CustomBreadcrumbItem>
	))
	if (mainCtx.state.__notFound) {
		history.replace('/rm/supplies')
		return null
	}
	return (
		<>
			<HeaderMenu customBreadcrumbItems={customBreadcrumbItems}>
				{modCtx.mod === modes.edit && (
					<Row
						style={{
							color: `rgba(207, 9, 9, 1)`,
						}}
					>
						{INACTION_AWARE}
					</Row>
				)}
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						canEdit={!['Отправлено в 1С', 'Принято в 1С'].includes(mainCtx.state.invoice?.status)}
						rights={{
							'АРМ офиса': {
								'Поставки сырья': {
									Разгрузка: { edit: true },
								},
							},
						}}
						onClose={() => {
							actBtnOnClose({
								history: history,
								pageUrl: `/rm/supplies/${mainCtx.state.supply.id}`,
								showConfirmModal: showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onEdit={async () => {
							if (await mainCtx.checkIsBlocked()) {
								axios.post(`/logs`, {
									time: new Date().toISOString(),
									action: 'Edit',
									isBlockUnblockLogic: true,
									entity: 'rmSupplProd',
									idEntity: mainCtx.state.id,
								})
								showConfirmModal({
									...showIsBLockedModalForDeps,
									title: EDIT_NOT_PERMIT_BECAUSE_OF_BLOCK,
								})
								return
							}
							if (await mainCtx.blockForEditing()) {
								modCtx.set(modes.edit)
								mainCtx.rerender()
							}
						}}
						canDelete={false}
						onDelete={async () => {
							if (await mainCtx.checkIsBlocked()) {
								axios.post(`/logs`, {
									time: new Date().toISOString(),
									action: 'Delete',
									isBlockUnblockLogic: true,
									entity: 'rmSupplProd',
									idEntity: mainCtx.state.id,
								})
								showConfirmModal({
									...showIsBLockedModalForDeps,
									title: EDIT_NOT_PERMIT_BECAUSE_OF_BLOCK,
								})
								return
							}
							await actBtnOnDelete({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								getDeps: mainCtx.getDepsOn,
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
								currentRights,
							})
						}}
						onSave={async () => {
							try {
								await actBtnOnSaveV2({
									serverEdit: mainCtx.serverEdit,
									validate: () => mainCtx.validate(form),
									pageUrl: pageUrl,
									history: history,
									showConfirmModal,
								})
								await mainCtx.unblockForEditing()
							} catch (err) {
								console.log(err)
							}
						}}
						onCancel={async () => {
							if (await mainCtx.unblockForEditing()) {
								form.resetFields()
								mainCtx.reset()
							}
						}}
					/>
				</Row>
			</HeaderMenu>

			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<Title level={5}>Основные данные</Title>
						<CustomForm
							form={form}
							name="supplyUnload1"
							fields={[
								{ name: 'unldStatus', value: mainCtx.state.unldStatus },
								{
									name: 'displayCode',
								},
								{
									name: 'date',
								},
								{
									name: 'provider',
								},
								{
									name: 'rmProvProd',
								},
								{ name: 'assignedPartyNum', value: mainCtx.state.assignedPartyNum },
								{
									name: 'prodCatKindRawMat',
								},
								{
									name: 'vocProd1c',
								},
								{
									name: 'unldStaff',
									value: mainCtx.state.unldStaff,
								},
							]}
							items={getFormItems1(mainCtx, modCtx)}
						/>
						<Title level={5}>Упаковка</Title>
						<CustomForm
							form={form}
							name="supplyUnload2"
							fields={[
								{ name: 'vocContTypeFact', value: mainCtx.state.vocContTypeFact },
								{ name: 'unldVNaval', value: mainCtx.state.unldVNaval },
								{ name: 'unldIsStandPkg', value: mainCtx.state.unldIsStandPkg },
								{ name: 'totalPalCount', value: calcTotalPalCount(mainCtx) },
								mainCtx.state.unldIsStandPkg
									? { name: 'unldNettoPkgWeight', value: mainCtx.state.unldNettoPkgWeight }
									: {
											name: 'unldAvgNettoPkgWeight',
											value: supplyCalcAvgNettoPkgWeight(mainCtx.state),
									  },
							]}
							columns={[
								{ size: { xs: 24, lg: 8 }, num: 0 },
								{ size: { xs: 24, lg: 8 }, num: 1 },
								{ size: { xs: 24, lg: 8 }, num: 2 },
							]}
							items={getFormItems2(mainCtx, modCtx)}
						/>
						<Title level={5}>Приемка</Title>
						<CustomForm
							form={form}
							name="supplyUnload3"
							fields={[
								{ name: 'packageCountInv', value: mainCtx.state.packageCountInv },
								{ name: 'factPkgCount' },
								{ name: 'weightInv', value: mainCtx.state.weightInv },
								{ name: 'factWeight' },
							]}
							columns={[
								{ size: { xs: 24, lg: 8 }, num: 0 },
								{ size: { xs: 24, lg: 8 }, num: 1 },
							]}
							items={getFormItems3(mainCtx, modCtx)}
						/>
						<Title level={5}>Расхождение</Title>
						<CustomForm
							form={form}
							name="supplyUnload4"
							fields={[
								{
									name: 'diffPkgCount',
									value: calcDiffPkgCount(mainCtx.state),
								},
								{
									name: 'diffWeight',
									value: calcSupplyInpCtrlDiffWeight(mainCtx.state),
								},
								{ name: 'unldComment', value: mainCtx.state.unldComment },
							]}
							columns={[
								{ size: { xs: 24, lg: 8 }, num: 0 },
								{ size: { xs: 24, lg: 8 }, num: 1 },
								{ size: { xs: 24, lg: 8 }, num: 2 },
							]}
							items={getFormItems4(mainCtx, modCtx)}
						/>
					</Col>
				</Row>
			</Card>

			<TabList
				tabs={[
					{ key: 0, title: 'Распределение по паллетам', component: <PalDistribTab /> },
					{ key: 1, title: 'Хранение', component: <StorageTab /> },
				]}
			/>
		</>
	)
}
export default SupplyUnloadItem
