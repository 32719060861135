import { Checkbox } from 'antd'
import { getColumnSearchProps, sorter, renderPossiblyEmptyString } from '../../../utils'

export const getToXlsx = (dataSource, columns) => {
	dataSource = dataSource.map((ds) => {
		return {
			...ds,
			isAdditionalProduct: ds.isAdditionalProduct ? 'Да' : 'Нет',
		}
	})
	return {
		dataSource,
		columns: columns.reduce((all, col) => [...all, ...col.children], []),
		additionalHeaders: [
			{
				title: 'Реестр продуктов',
				colSpan: 5,
			},
			{
				title: '1С',
				colSpan: 2,
			},
		],
		tableName: 'Реестр продуктов',
		fileName: 'Реестр_продуктов.xlsx',
	}
}

/**
 * @param {Object} fromServerFilters - массив с Фильтрами
 */

export const getColumns = (props = {}) => {
	const { fromServerFilters = {} } = props

	return [
		{
			title: 'Реестр продуктов',
			children: [
				{
					title: 'Тип',
					dataIndex: 'type',
					key: 'type',
					width: '13%',
					...getColumnSearchProps(fromServerFilters.type),
					render: renderPossiblyEmptyString,
				},
				{
					title: 'Группа',
					dataIndex: 'prodGroup',
					key: 'prodGroup',
					width: '6%',
					...getColumnSearchProps(fromServerFilters.prodGroup),
				},
				{
					title: 'Артикул',
					dataIndex: 'displayCode',
					...getColumnSearchProps(fromServerFilters.displayCode),
					sorter: sorter('number', 'displayCodeForSorter'),
					key: 'displayCode',
					width: '10%',
				},
				{
					title: 'Продукт',
					dataIndex: 'label',
					key: 'label',
					...getColumnSearchProps(fromServerFilters.label),
					sorter: sorter('string', 'label'),
					width: '10%',
					render: renderPossiblyEmptyString,
				},
				{
					title: 'Вид',
					dataIndex: 'prodType',
					width: '26%',
					...getColumnSearchProps(fromServerFilters.prodType),
					render: renderPossiblyEmptyString,
				},
			],
		},
		{
			title: '1С',
			children: [
				{
					title: 'Артикул',
					dataIndex: 'vendorCode',
					key: 'vendorCode',
					width: '5%',
					...getColumnSearchProps(fromServerFilters.vendorCode),
					sorter: sorter('string', 'vendorCode'),
					render: renderPossiblyEmptyString,
				},
				{
					title: 'Продукт',
					dataIndex: 'description',
					key: 'description',
					width: '30%',
					sorter: sorter('string', 'description'),
					...getColumnSearchProps(fromServerFilters.description),
					render: renderPossiblyEmptyString,
				},
				{
					title: 'Доп. продукт',
					dataIndex: 'isAdditionalProduct',
					key: 'forFilterIsAdditionalProduct',
					width: '10%',
					...getColumnSearchProps(fromServerFilters.forFilterIsAdditionalProduct),
					render: (val) => <Checkbox disabled checked={val} />,
				},
			],
		},
	]
}
