import React, { useContext, useEffect, useRef } from 'react'
import { ListContext, SyncDepsContext } from '../../../../contexts'
import {
	everyOtherTimeUseEffectHandlerForList,
	startUseEffectHandlerForList,
} from '../../../../utils'

const dataUrl = `/product-catalogs`

export const reducer = (state) => {
	return {
		...state,
	}
}

const ProductCatalogListMainContext = React.createContext()
ProductCatalogListMainContext.displayName = 'ProductCatalogListMainContext'

const Provider = (props) => {
	const { children } = props
	const syncDepsCtx = useContext(SyncDepsContext)

	const {
		state: { page, pageSize, mainFilter, filters, sorter },
		setTotal,
	} = useContext(ListContext)
	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: {
			label: [],
		},
		isInitialized: false,
		isLoading: false,
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	useEffect(() => {
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				sorter,
				mainFilter,
				filters,
				offset: pageSize * page - pageSize,
				limit: pageSize,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [])
	useEffect(() => {
		everyOtherTimeUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				offset: pageSize * page - pageSize,
				limit: pageSize,
				sorter,
				mainFilter,
				filters,
				search: stateRef.current.search,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [
		page,
		sorter,
		mainFilter,
		filters,
		stateRef.current.search,
		syncDepsCtx.state.reloadUuids['office-ms'],
		stateRef.current.search,
	])

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}

	const value = {
		state: stateRef.current,
		toDisplayDataList: stateRef.current.fromServer,
		setSearch,
	}

	return (
		<ProductCatalogListMainContext.Provider value={value}>
			{children}
		</ProductCatalogListMainContext.Provider>
	)
}

export { Provider, ProductCatalogListMainContext }
