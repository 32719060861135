import React, { useContext, useEffect, useRef, useCallback } from 'react'
import lodash from 'lodash'
import { ModContext } from '../../../../contexts'
import { useItemFetchers } from '../../../../utils'
import { modes, prepareObjFromServer } from '../../../../utils'
import { basicValidator, isEmail } from '@berry/common-functions/validators'
import { formValidateUniq } from '../../../../utils'
import { getCommonProviderFunctions } from '../../../../utils/helpers/generators'
import { useHistory } from 'react-router-dom'

const dataUrl = '/rm/providers'
const tabs = {
	contacts: 'contacts',
	contracts: 'contracts',
	platforms: 'platforms',
	products: 'products',
}

export const reducer = (state) => {
	return {
		...state,
	}
}

export const constants = {
	qualityManagement: [0, 1, 2, 3, 4, 5],
	deliveryTime: [0, 1, 2, 3, 4, 5],
	deliverCondition: [0, 1, 2, 3, 4, 5],
	paymentCondition: [0, 1, 2, 3, 4, 5],
	quality: [0, 1, 2, 3, 4, 5],
}

const ProviderItemMainContext = React.createContext()
ProviderItemMainContext.displayName = 'ProviderItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)

	const [state, dispatch] = React.useReducer(reducer, {
		data: {},
		oldData: {},
		additional: {},
		formErrors: [],
		isInitializedMain: false,
		isInitializedAdditional: false,
		tabsLoading: {
			contacts: false,
			contracts: false,
			platforms: false,
			products: false,
		},
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			formErrors: [],
		})
	}, [])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
		return
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	const validate = () => {
		const state = stateRef.current
		if (state.contacts?.some((contact) => !contact.email && !contact.phoneNum))
			return 'Укажите номер телефона или электронную почту для всех контактов'
		if (
			state.contacts?.reduce((acc, c) => {
				if (c.email && !isEmail(c.email)) {
					return [...acc, c]
				}
				return acc
			}, []).length
		) {
			return 'Неверно указан адрес электронной почты'
		}
		return ''
	}

	const validators = {
		contacts: (inUuid) => {
			const found = stateFunctions.contacts.get(inUuid)
			if (!basicValidator(found.fullName)) throw Error()
			if (!basicValidator(found.position)) throw Error()
			if (found.email && !isEmail(found.email)) throw Error()
			if (found.phoneNum && found.phoneNum.replace(/\D/g, '').length !== 10) throw Error()
			if (!found.email && !found.phoneNum) {
				throw Error()
			}
		},
		platforms: (inUuid) => {
			const found = stateFunctions.platforms.get(inUuid)
			if (!basicValidator(found.label) & !basicValidator(found.address)) {
				throw Error()
			}
			if (!basicValidator(found.label)) throw Error()
			if (!basicValidator(found.address)) throw Error()
			if (!formValidateUniq(stateRef.current.data.platforms, found, 'label')) throw Error()
		},
		products: (inUuid) => {
			const found = stateFunctions.products.get(inUuid)
			if (!basicValidator(found.prodCatKindRawMat)) throw Error()
		},
	}

	const { stateFunctions, serverEdit, isEdited, commonDeepFieldUpdate } =
		getCommonProviderFunctions(
			stateRef,
			stateRef.current.oldData,
			executeDispatch,
			{ modCtx, dataUrl, params, pageUrl: dataUrl, history },
			{},
			{
				label: 'common',
				code: 'common',
				inn: 'common',
				kpp: 'common',
				address: 'common',
				quality: 'common',
				qualityManagement: 'common',
				deliveryTime: 'common',
				deliverCondition: 'common',
				paymentCondition: 'common',
			},
			{
				contacts: {
					fullName: 'common',
					position: 'common',
					phoneNum: 'common',
					email: 'common',
				},
				products: {
					prodCatKindRawMat: 'common',
					prodCat: 'common',
				},
				platforms: {
					label: 'common',
					address: 'common',
				},
			}
		)

	const value = {
		state: stateRef.current.data,
		isPendingReq: stateRef.current.isPendingReq,
		additional: stateRef.current.additional,
		stateFunctions,
		validators,
		serverEdit,
		isEdited,
		validate,
		commonDeepFieldUpdate,
		reset,
	}

	return (
		<ProviderItemMainContext.Provider value={value}>{children}</ProviderItemMainContext.Provider>
	)
}

export { Provider, ProviderItemMainContext }
