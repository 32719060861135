import React, { useContext } from 'react'
import { ModContext, ModProvider, UserDataContext } from '../../../contexts'
import { Main } from '../../../layouts'
import { CustomerItemMainContext, Provider } from './provider/main'
import { Card, Row, Col, Form } from 'antd'
import { ActionButtons, CustomForm, HeaderMenu, TabList } from '../../../components'
import { actBtnOnClose, actBtnOnSaveV2, modes } from '../../../utils'
import { showConfirmModal } from '../../../components'
import { getFormItems } from './customer-item'
import { useHistory } from 'react-router'
import ContactTab from './ContactTab/ContactTab'
import PlatformTab from './PlatformTab/PlatformTab'
import ContractTab from './ContractTab/ContractTab'
import { checkRole } from '@berry/common-functions/role'

const pageUrl = '/rp/customers'

const CustomerItem = (props) => {
	return (
		<ModProvider>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Заказчик">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(CustomerItemMainContext)
	const userDataCtx = useContext(UserDataContext)
	let history = useHistory()
	const [form] = Form.useForm()
	const showContractTab = checkRole(
		{
			'АРМ офиса': {
				'Договоры. Заказчики': {
					view: true,
				},
			},
		},
		userDataCtx.state.rights
	)
	return (
		<>
			<HeaderMenu breadcrumbsTitle="Заказчики" recordLabel={mainCtx.state.label}>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						rights={{
							'АРМ офиса': {
								Заказчики: {
									edit: true,
								},
							},
						}}
						formName={'customer'}
						onClose={() => {
							actBtnOnClose({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							await actBtnOnSaveV2({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								showConfirmModal,
								isShowErrorModal: true,
							})
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						onDelete={async () => {
							// showConfirmationWindow()
						}}
						onCancel={() => {
							const allFields = form.getFieldsError()
							allFields.forEach((f) => {
								f.errors = []
							})
							form.setFields(allFields)
							mainCtx.reset()
						}}
					/>
				</Row>
			</HeaderMenu>

			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							form={form}
							name="customer"
							fields={[
								{ name: 'label', value: mainCtx.state.label },
								{ name: 'code', value: mainCtx.state.code },
								{ name: 'inn', value: mainCtx.state.inn },
								{ name: 'kpp', value: mainCtx.state.kpp },
								{ name: 'address', value: mainCtx.state.address },
								{ name: 'vocPayCondit', value: mainCtx.state.vocPayCondit },
								{ name: 'timelyExport', value: mainCtx.state.timelyExport },
							]}
							columns={[
								{ size: { xs: 24, md: 12, lg: 6 }, num: 0 },
								{ size: { xs: 24, md: 12, lg: 6 }, num: 1 },
								{ size: { xs: 24, md: 12, lg: 12 }, num: 2 },
							]}
							items={getFormItems(mainCtx, modCtx)}
						/>
					</Col>
				</Row>
			</Card>

			<TabList
				tabs={[
					{ key: 0, title: 'Контакты', component: <ContactTab /> },
					{ key: 1, title: 'Площадки', component: <PlatformTab /> },
					...(showContractTab ? [{ key: 2, title: 'Договоры', component: <ContractTab /> }] : []),
				]}
			/>
		</>
	)
}
export default CustomerItem
