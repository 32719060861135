export const columns = [
	{
		title: 'Наименование',
		dataIndex: 'label',
		key: 'label',
		width: '30%',
	},
	{
		title: 'Краткое наименование',
		dataIndex: 'labelShort',
		key: 'labelShort',
		width: '30%',
	},
	{
		title: 'Номер',
		key: 'displayCode',
		width: '30%',
		dataIndex: 'displayCode',
	},
]
