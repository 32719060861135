import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import { FileContext, ModContext, UserDataContext } from '../../../../../contexts'
import { getCommonProviderFunctions } from '../../../../../utils/helpers/generators'
import {
	prepareObjFromServer,
	modes,
	useItemFetchers,
	axios,
	appendFiles,
	goToItem,
} from '../../../../../utils'
import { basicValidator, isValidNum } from '@berry/common-functions/validators'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { calcTestReportNum } from '../../../../../utils/helpers/cross-pages-funcs'
import { v4 } from 'uuid'
import { addFile as addNewFile } from '../../../../../utils/helpers/for-files'

const dataUrl = '/stock/ready-prod-resales'

export const reducer = (state) => {
	return {
		...state,
	}
}

const initialData = {
	storages: [],
	eventHistories: [],
	costs: [],
	__notFound: false,
}
const tabs = {
	'event-histories': 'eventHistories',
	storages: 'storages',
	costs: 'costs',
	'lab-indics': 'labIndics',
}

const StockReadyProdResaleItemMainContext = React.createContext()
StockReadyProdResaleItemMainContext.displayName = 'StockReadyProdResaleItemMainContext'

const Provider = (props) => {
	const { children, params } = props

	const {
		state: { isDevAdmin },
	} = useContext(UserDataContext)

	const modCtx = useContext(ModContext)
	const fileCtx = useContext(FileContext)
	const [state, dispatch] = React.useReducer(reducer, {
		data: initialData,
		oldData: initialData,
		additional: {
			allSelectBlockCauses: [],
			allSelectsProdTasks: [],
			allCtrlSpecs: [],
		},
		formErrors: [],
		isInitializedMain: false,
		isInitializedAdditional: false,
		tabsLoading: {
			actionHistories: false,
			storages: false,
		},
	})
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))
	const getRequiredFields = (rec) => ({
		storages: ['status'],
		labIndics: rec.isText ? ['factText'] : ['factVal'],
	})
	const validate = () => {
		const blockCauseHasError = (storage) =>
			['Удержано', 'Заблокировано'].includes(storage.status) && !storage.blockCauses.length

		if (stateRef.current.data.storages.some(blockCauseHasError)) {
			for (const storage of stateRef.current.data.storages) {
				if (blockCauseHasError(storage)) setError([], [], `blockCauses.${storage._uuid_}`, true)
			}
			return 'Не заполнены обязательные поля'
		}
		if (
			stateRef.current.data.labIndics.some(
				(ind) => !basicValidator(ind.factVal) && !basicValidator(ind.factText)
			)
		) {
			for (const i of stateRef.current.data.labIndics) {
				if (!basicValidator(i.factVal) && !basicValidator(i.factText))
					setError([], [], `factVal.${i._uuid_}`, true)
			}
			return 'Не заполнены обязательные поля'
		}
		if (
			stateRef.current.data.labIndics.some(
				(ind) => basicValidator(ind.factVal) && !isValidNum(ind.factVal)
			)
		) {
			for (const i of stateRef.current.data.labIndics) {
				if (basicValidator(i.factVal) && !isValidNum(i.factVal))
					setError([], [], `factVal.${i._uuid_}`, true)
			}
			return 'Не заполнены обязательные поля'
		}
		return ''
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			formErrors: {},
		})
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * подготавливает данные которые нужно выбират
	 */

	const selectors = {
		tasksForComplectations: lodash.cloneDeep(stateRef.current.additional.allSelectsProdTasks),
		storages: {
			blockCause: stateRef.current.additional.allSelectBlockCauses.filter(
				(bc) => bc.status === 'Действующая'
			),
			status: [
				{ label: 'Допущено' },
				{ label: 'Удержано' },
				{ label: 'Ответ. хранение' },
				{ label: 'Заблокировано' },
				...(isDevAdmin ? [{ label: 'Сработано' }] : []),
			],
		},
		ctrlSpec: stateRef.current.additional.allCtrlSpecs.filter(
			(e) =>
				e.vocProdType.label === 'Сырье' &&
				e.idProdCat === stateRef.current.data.supplProd.contrQuota.rmProvProd.prodCat.id &&
				e.kindRawMat.id ===
					stateRef.current.data.supplProd.contrQuota.rmProvProd.prodCatKindRawMat.id
		),
		labIndics: {
			factText: ['C', 'NC'],
		},
	}

	const {
		getEditedData,
		commonDeepFieldUpdate,
		isEdited,
		stateFunctions,
		serverDelete,
		setError,
		commonObjUpdate,
		commonFieldUpdate,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{
			modCtx,
			dataUrl,
			params,
			pageUrl: '/stock/ready-prod-resales',
			history,
			requiredFields: getRequiredFields,
		},
		selectors,
		{
			// ctrlSpec: 'common',
		},
		{
			storages: {
				blockCauses: {
					blockCause: 'common',
				},
			},
			labIndics: {
				factVal: 'common',
				factText: 'common',
			},
		}
	)
	stateFunctions.setCtrlSpec = (val) => {
		commonFieldUpdate('labIndics', [])
		const params = stateRef.current.additional.allCtrlSpecs.find((e) => e.id === val)?.parameters
		params.forEach((e) => {
			stateFunctions.labIndics.create({ specParam: e })
		})
		commonObjUpdate('ctrlSpec', val)
	}
	stateFunctions.labIndics.addPhoto = (inUuid, vals) => {
		addNewFile(vals, 'photoPath', 'lab_ind')
		commonDeepFieldUpdate(['labIndics'], [inUuid], 'photos', vals)
		fileCtx.addFiles(lodash.cloneDeep(vals), 'photoPath')
	}
	stateFunctions.labIndics.removePhoto = (inUuid, photo) => {
		let newFiles = stateFunctions.labIndics.get(inUuid)?.photos.filter((p) => {
			return photo.photoPath
				? p.photoPath !== photo.photoPath
				: p.photoPath !== photo.originFileObj?.photoPath
		})
		commonDeepFieldUpdate(['labIndics'], [inUuid], 'photos', newFiles)
	}
	stateFunctions.storages.setStatus = (inUuid, val) => {
		commonDeepFieldUpdate(['storages'], [inUuid], 'status', val)
		if (val === 'Допущено') {
			commonDeepFieldUpdate(['storages'], [inUuid], 'blockCauses', [])
		}
	}

	const serverEdit = async () => {
		try {
			const formData = appendFiles(stateRef.current.data.labIndics, 'photos', '_uuid_', 'lab_ind', {
				key: 'photoPath',
			})
			let body
			let options = { url: dataUrl }
			if (params.id === 'new') {
				body = lodash.cloneDeep(stateRef.current.data)
				options.method = 'POST'
			} else {
				body = getEditedData()
				options.method = 'PUT'
			}
			body.storages?.forEach((s) => {
				if (s.status) {
					const oldFound = stateRef.current.oldData.storages.find((st) => +st.id === +s.id)
					s.oldStatus = oldFound.status
					s.newStatus = s.status
					s.status = 'На оформлении в 1С'
				}
			})
			if (body.labIndics) {
				body.repeatDate = moment().format('YYYY-MM-DD')
				body.repeatTestRepNum = await calcTestReportNum([])
			}
			formData.append('data', JSON.stringify(body))
			executeDispatch({ ...stateRef.current, isPendingReq: true })
			const res = (await axios[options.method.toLowerCase()](dataUrl, formData))?.data || {}
			executeDispatch({ ...stateRef.current, isPendingReq: false })
			if (res?.data?.id) {
				if (options.method === 'POST') {
					goToItem(history, { url: dataUrl, id: res?.data?.id })
				}
				modCtx.set(modes.view)
				executeDispatch({ ...stateRef.current, reloadUuid: v4() })
			}
			modCtx.set(modes.view)
			return { method: options.method, id: res.data?.id }
		} catch (err) {
			message.error('Ошибка при сохранении')
			console.log(err.message)
		}
	}

	const value = {
		state: lodash.cloneDeep(stateRef.current.data),
		additional: stateRef.current.additional,
		tabsLoading: stateRef.current.tabsLoading,
		stateFunctions,
		serverEdit,
		serverDelete,
		isEdited,
		validate,
		reset,
		formErrors: stateRef.current.formErrors,
		selectors,
		setError,
	}

	return (
		<StockReadyProdResaleItemMainContext.Provider value={value}>
			{children}
		</StockReadyProdResaleItemMainContext.Provider>
	)
}

export { Provider, StockReadyProdResaleItemMainContext }
