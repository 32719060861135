import React, {useContext} from 'react';
import { Col} from 'antd'
import Icon, { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import {MenuContext} from "../../contexts";

const CollapsedIcon = () => {
    const { collapsed, toggleCollapsed } = useContext(MenuContext)

    const handleCollapsed = () => {
        toggleCollapsed()
    }

    return (
        <Col>
            {collapsed ? (
                <Icon
                    style={{ fontSize: '24px' }}
                    component={MenuUnfoldOutlined}
                    onClick={handleCollapsed}
                />
            ) : (
                <Icon
                    style={{ fontSize: '24px' }}
                    component={MenuFoldOutlined}
                    onClick={handleCollapsed}
                />
            )}
        </Col>
    );
};

export default CollapsedIcon;
