import React, { useContext, useState } from 'react'
import { Table } from 'antd'
import { AuthContext, ModContext } from '../../../contexts'
import { ContractItemMainContext } from '../provider/item'
import { TabItem } from '../../../components'
import { getColumns } from './utils'
import { modes, openNewTab } from '../../../utils'

const OrderTab = () => {
	const mainCtx = useContext(ContractItemMainContext)
	const modCtx = useContext(ModContext)
	const stateFunctions = mainCtx.stateFunctions.orders
	const [className, setClassName] = useState('')

	const authCtx = useContext(AuthContext)
	const isDisabled = modCtx.mod === modes.view

	let stateData = mainCtx.state.orders

	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					stateFunctions.create()
				},
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={isDisabled ? 'clickable' : null}
						columns={getColumns(mainCtx, modCtx, authCtx, className)}
						dataSource={stateData}
						pagination={false}
						scroll={{ x: 800 }}
						onRow={(record) => ({
							onClick: () => {
								if (!isDisabled) return
								const { id } = record
								openNewTab(`/reports/consolidated-reports/${id}`, {
									authCtx: authCtx.state,
								})
							},
							onMouseEnter: () => isDisabled && setClassName('highlight'),
							onMouseLeave: () => setClassName(''),
						})}
					/>
				)
			}}
		/>
	)
}

export default OrderTab
