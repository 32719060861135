import { getSpecLabel } from '@berry/common-functions/cross-project-functions'
import { getColumnSearchProps, sorter } from '../../../utils'
import { dateFormat } from '../../../utils/constants/common'
import moment from 'moment'
import { Status } from '../../../components'

export const getColumns = (props) => {
	const { fromServerFilters } = props
	return [
		{
			title: 'Тип',
			dataIndex: ['vocProdType', 'label'],
			key: 'vocProdTypeLabel',
			...getColumnSearchProps(fromServerFilters.vocProdTypeLabel),
			width: '10%',
		},
		{
			title: 'Наименование',
			dataIndex: 'label',
			key: 'label',
			...getColumnSearchProps(fromServerFilters.label),
			sorter: sorter('string', 'label'),
			width: '20%',
		},
		{
			title: 'Версия',
			dataIndex: 'version',
			key: 'version',
			render: (text) => text || '',
			width: '10%',
		},
		{
			title: 'Дата утв.',
			dataIndex: 'dateOfApprove',
			key: 'dateOfApprove',
			...getColumnSearchProps(fromServerFilters.dateOfApprove, undefined, null, true),
			sorter: sorter('string', 'dateOfApprove'),
			width: '10%',
			render: (dateOfApprove) => (dateOfApprove ? moment(dateOfApprove).format(dateFormat) : null),
		},
		{
			title: 'Продукт',
			dataIndex: 'prodCatLabel',
			key: 'prodCatLabel',
			width: '10%',
			...getColumnSearchProps(fromServerFilters.prodCatLabel),
			sorter: sorter('string', 'prodCat.label'),
			render: (text) => text || '',
		},
		{
			title: 'Вид продукта',
			dataIndex: 'prodKind',
			key: 'prodKind',
			width: '10%',
			...getColumnSearchProps(fromServerFilters.prodKind),
			render: (_, record) => getSpecLabel(record),
		},
		{
			title: 'Качество',
			dataIndex: ['vocQuality', 'label'],
			key: 'vocQualityLabel',
			...getColumnSearchProps(fromServerFilters.vocQualityLabel),
			width: '10%',
			render: (text) => text || '',
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			...getColumnSearchProps(fromServerFilters.status),
			width: '10%',
			render: (text) => <Status status={text} />,
		},
	]
}
