import { getNewObj } from '.'
import lodash from 'lodash'
export const renderPossiblyEmptyString = (text) => text || 'Не указано'

/**
 * определяет сколько строк обьединять
 * @param {Object} inRecord
 * @returns {number}
 */
export const getRowSpan = (inRecord, inLength) => {
	if (inRecord.indxMain === 0) {
		return inLength || 1
	} else {
		return 0
	}
}

/**
 * подготавливает данные для отрисовки таблицы
 * @param {Object} inArr - рсновной контекст
 * @param {string} field - поле по которому будет разбиваться на строки
 * @param {string} shortField - поле - сокращение по которому будут лежать данные в каждой записи
 */
export const prepareData = (inArr, field, shortField, options = { withoutUuidCheck: false }) => {
	const result = inArr.reduce((acc, cur) => {
		const rows =
			cur[field]?.map((el, indx) => {
				if (!options.withoutUuidCheck) {
					if (!el._uuid_) {
						return getNewObj({ ...cur, indxMain: indx, [shortField]: el })
					}
				}
				return { ...cur, indxMain: indx, [shortField]: el }
			}) || []
		if (!rows.length) return [...acc, { ...cur, indxMain: 0 }]
		return [...acc, ...rows]
	}, [])
	return result
}

/**
 * подготавливает данные для отрисовки таблицы
 * @param {Object} inArr - рсновной контекст
 * @param {string} filed - поле по которому будет разбиваться на строки
 * @param {string} shortField - поле - сокращение по которому будут лежать данные в каждой записи
 */
export const prepareDataOld = (inArr, filed, shortField) => {
	const result = inArr.reduce((acc, cur) => {
		const rows = cur[filed].map((el, indx) => {
			return { ...cur, indxMain: indx, [shortField]: el }
		})
		if (!rows.length) return [...acc, { ...cur, indxMain: 0 }]
		return [...acc, ...rows]
	}, [])
	return result
}

/**
 * Получить данные в зависимости от страницы
 * @param {Array<Object>} inArr - масств данных
 * @param {number} filed - номер страницы
 * @param {{elPerPage: number}} options - поле - сокращение по которому будут лежать данные в каждой записи
 */
export const getDataByPage = (inArr, page, options = { elPerPage: 10 }) => {
	let realPage = page - 1
	if (inArr.length < page * options.elPerPage) {
		return inArr.slice(options.elPerPage * realPage, inArr.length)
	} else {
		return inArr.slice(
			options.elPerPage * realPage,
			options.elPerPage * realPage + options.elPerPage
		)
	}
}

/**
 * Получить количество страниц
 * @param {Array<Object>} inArr - масств данных
 */
export const getPageLength = (inArr, elPerPage = 10) => {
	return Math.ceil(inArr.length / elPerPage)
}

/**
 * Получить выводимое значение для элемента
 * @param {Object} inEl - элемент
 * @param {Object} [options]
 * @param {function} [options.fn] - функция для получения составного значения
 * @param {Object} [options.field] - поле, по которому должно лежать значение через дот-нотацию
 */
export const getDisplayValue = (inEl, options = {}) => {
	if ([undefined, null].includes(inEl)) {
		return ''
	}
	let value
	if (options.fn) {
		value = options.fn(inEl)
	}
	if (options.field) {
		value = lodash.get(inEl, options.field)
	}
	if (typeof inEl === 'object' && Object.keys(inEl).includes('label')) {
		return inEl.label
	}
	if (typeof inEl === 'string') {
		value = inEl
	}
	if (typeof inEl === 'number') {
		value = inEl.toString()
	}
	if ([undefined, null, ''].includes(value)) {
		throw Error(`getDisplayValue error. obj: ${JSON.stringify(inEl)}`)
	}
	return value
}
