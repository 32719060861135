import { Checkbox } from 'antd'
import { modes } from '../../../../../utils'

const staticVocs = ['Продукция из 1С', 'Статусы персонала', 'Типы продукта']

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 *  @param {Object} modCtx
 *  @param {function} editHandler
 */
export const getDisplayColumns = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	const vocParams = mainCtx.state?.params['Справочники'] || {}
	return [
		{
			title: 'Справочники',
			key: 'label',
			width: '30%',
			render: (_, label) => label,
		},
		{
			title: 'Просмотр',
			key: 'view',
			width: '20%',
			render: (text) => (
				<Checkbox
					disabled={isDisabled}
					checked={vocParams[text]?.view}
					onChange={(e) =>
						mainCtx.stateFunctions.setParams(text, 'view', e.target.checked, 'Справочники')
					}
				/>
			),
		},
		{
			title: 'Редактирование',
			key: 'edit',
			render: (text, record) => (
				<Checkbox
					disabled={isDisabled || staticVocs.includes(text) || text === 'Типы должностей'}
					checked={vocParams[text]?.edit}
					onChange={(e) =>
						mainCtx.stateFunctions.setParams(text, 'edit', e.target.checked, 'Справочники')
					}
				/>
			),
		},
	]
}
