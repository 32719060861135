import { Table } from 'antd'
import React, { useContext } from 'react'
import { StockWasteItemMainContext } from '../provider/main'
import { prepareData } from '../../../../../utils'
import { getDisplayColumns } from './parties-1c-tab'
import { TabItem } from '../../../../../components'
import { AuthContext } from '../../../../../contexts'
import { checkRole } from '@berry/common-functions/role'

const implRights = {
	'АРМ офиса': {
		Реализации: {
			view: true,
		},
	},
}

const disposalRights = {
	'АРМ офиса': {
		Утилизации: {
			view: true,
		},
	},
}

const Parties1cTab = () => {
	const mainCtx = useContext(StockWasteItemMainContext)
	const dataSource = prepareData(mainCtx.state.parties1Cs || [])
	const authCtx = useContext(AuthContext)
	const canViewImpl = checkRole(implRights)
	const canViewDisposal = checkRole(disposalRights)
	return (
		<TabItem
			addButton={{ show: false }}
			table={() => (
				<Table
					size="small"
					rowKey="_uuid_"
					columns={getDisplayColumns(authCtx, { canViewImpl, canViewDisposal })}
					loading={mainCtx.tabsLoading.parties1Cs}
					dataSource={dataSource}
					pagination={false}
					scroll={{ x: 800 }}
				/>
			)}
		/>
	)
}

export default Parties1cTab
