import { Route, Switch } from 'react-router-dom'
import { RouteComponentWrapper } from '..'
import React from 'react'
import { ListProvider } from '../../contexts'
import { v4 } from 'uuid'

const ListPageWrapper = (props) => {
	const { item, list, path, location } = props
	const key = location.state?.refresh ? `${path}${v4()}` : location.pathName
	return (
		<ListProvider path={path}>
			<Switch>
				{item && (
					<Route
						key={key}
						path={`${path}/:id`}
						render={(props) => (
							<RouteComponentWrapper
								{...props}
								child={(props) => React.cloneElement(item, props)}
							/>
						)}
					/>
				)}
				{list && (
					<Route
						key={`${path}insideListProvider`}
						path={path}
						render={(props) => (
							<RouteComponentWrapper
								{...props}
								child={(props) => React.cloneElement(list, props)}
							/>
						)}
					/>
				)}
			</Switch>
		</ListProvider>
	)
}

export default ListPageWrapper
