import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { setNumField } from '@berry/front-common/for-fields'
import { Col, Select } from 'antd'
import { InputError } from '../../../../components'
import { forSearchInFieldProps } from '../../../../utils'

const layout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

export const getFormItems = (mainCtx) => {
	let key = null
	const result = [[]]
	if (mainCtx.saleModal.__isOpen) {
		key = 'saleModal'
		result[0].push(
			...[
				{
					layout: layout,
					label: 'Заказчик',
					name: 'customer',
					rules: [{ required: true }],
					field: (
						<Select
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								mainCtx.modalFunctions.setSaleModalCustomer(el)
							}}
							notFoundContent={<Col>Не найдено</Col>}
							{...forSearchInFieldProps}
						>
							{mainCtx.selectors.saleModal.customer.map((it) => {
								return (
									<Select.Option key={it.id} value={it.id}>
										{it.label}
									</Select.Option>
								)
							}) || []}
						</Select>
					),
				},
				{
					layout: layout,
					label: 'Масса, кг',
					name: 'weight',
					rules: [
						{ required: true },
						{
							validator: (_, value) => {
								if (Number(value) > mainCtx.saleModal.storage.weight) {
									return Promise.reject('Масса не должна превышать допущенную')
								}
								return Promise.resolve()
							},
						},
					],
					field: (
						<InputError
							hasError={mainCtx.formErrors?.[`saleModal.weight`]}
							value={mainCtx.saleModal?.weight}
							onChange={(e) => {
								setNumField(
									e.target.value,
									mainCtx.saleModal?.weight,
									mainCtx.modalFunctions.setSaleModalWeight,
									'float',
									[15, 2]
								)
							}}
							style={{ width: '100%' }}
						/>
					),
				},
			]
		)
	}
	if (mainCtx.relocationModal.__isOpen) {
		key = 'relocationModal'
		result[0].push(
			...[
				{
					layout: layout,
					label: 'Масса, кг',
					name: 'weight',
					field: numToFixed(mainCtx.relocationModal.storage.weight, 2),
				},
				{
					layout: layout,
					label: 'Помещение №',
					name: 'room',
					rules: [{ required: true }],
					field: (
						<Select
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								mainCtx.modalFunctions.setRelocationModalRoom(el)
							}}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors.relocationModal.room
								?.filter(({ roomType }) => roomType !== 'Внешний')
								.map((it) => {
									return (
										<Select.Option key={it.id} value={it.id}>
											{it.displayCode}
										</Select.Option>
									)
								}) || []}
						</Select>
					),
				},
			]
		)
	}
	if (mainCtx.utilizationModal.__isOpen) {
		return [[]]
	}

	result[0].unshift(
		...[
			{
				layout: layout,
				label: 'Продукт',
				name: 'prodCat',
				field:
					(mainCtx[key]?.storage &&
						`${mainCtx[key]?.storage?.prodCat} ${mainCtx[key]?.storage?.prodCatKind}`) ||
					'',
			},
			{
				layout: layout,
				label: 'Партия №',
				name: 'partyNum',
				field: mainCtx[key]?.storage?.partyNum,
			},
			{
				layout: layout,
				label: 'Артикул',
				name: 'articul',
				field: mainCtx[key]?.storage && mainCtx[key].storage?.articul,
			},
		]
	)
	return result
}
