import { Typography, Select, Input } from 'antd'
import { modes } from '../../../utils'
import { getClassification, getSumScore } from '../utils'
import ContactTab from './ContactTab/ContactTab'
import ContractTab from './ContractTab/ContractTab'
import PlatformTab from './PlatformTab/PlatformTab'
import ProductTab from './ProductTab/ProductTab'
import { QuestionCircleOutlined } from '@ant-design/icons'
import LeftTooltip from './../../../components/LeftTooltip/LeftTooltip'
import { checkRole } from '@berry/common-functions/role'
import { constants } from './provider/main'

const { Title } = Typography

const layout1 = {
	labelCol: { xs: 24, sm: 10, xxl: 10 },
	wrapperCol: { xs: 24, sm: 10, xxl: 14 },
}

const layoutMiddle = {
	labelCol: { xs: 24, sm: 8, lg: 6, xxl: { span: 3, offset: 6 } },
	wrapperCol: { xs: 24, sm: 8, lg: 14, xxl: { span: 14, offset: 0 } },
}
const layout3 = {
	labelCol: { xs: 24, sm: 8, lg: 10, xxl: { span: 18, pull: 0 } },
	wrapperCol: { xs: 24, sm: 14, lg: 10, xxl: { span: 6, pull: 0 } },
}

const layout4 = {
	labelCol: { xs: 24, sm: 8, lg: 6, xxl: { span: 14, pull: 0 } },
	wrapperCol: { xs: 24, sm: 14, lg: 14, xxl: { span: 6, pull: 0 } },
}

export const getFormItems = (props) => {
	const {
		mainCtx,
		modCtx,
		userDataCtx: {
			state: { isDevAdmin },
		},
	} = props
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				field: <Title level={5}>Общая информация</Title>,
			},
			{
				layout: layout1,
				label: 'Поставщик',
				field: isDevAdmin ? (
					<Input
						style={{ minWidth: '185px' }}
						value={mainCtx.state.label}
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setLabel(e.target.value)
						}}
					/>
				) : (
					mainCtx.state.label
				),
			},
			{
				layout: layout1,
				label: 'Код 1С',
				field: isDevAdmin ? (
					<Input
						style={{ minWidth: 185 }}
						value={mainCtx.state.code}
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setCode(e.target.value)
						}}
					/>
				) : (
					mainCtx.state.code
				),
			},
			{
				layout: layout1,
				label: 'Общий балл',
				field: getSumScore(mainCtx.state).toString(),
			},
			{
				layout: layout1,
				label: 'Классификация',
				field: getClassification(mainCtx.state),
			},
		],
		[
			{},
			{
				layout: layoutMiddle,
				label: 'ИНН',
				field: isDevAdmin ? (
					<Input
						style={{ minWidth: '100px' }}
						value={mainCtx.state.inn}
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setInn(e.target.value)
						}}
					/>
				) : (
					mainCtx.state.inn
				),
			},
			{
				layout: layoutMiddle,
				label: 'КПП',
				field: isDevAdmin ? (
					<Input
						style={{ minWidth: '100px' }}
						value={mainCtx.state.kpp}
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setKpp(e.target.value)
						}}
					/>
				) : (
					mainCtx.state.kpp
				),
			},
			{
				layout: layoutMiddle,
				label: 'Адрес',
				field: isDevAdmin ? (
					<Input
						style={{ minWidth: '100px' }}
						value={mainCtx.state.address}
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setAddress(e.target.value)
						}}
					/>
				) : (
					mainCtx.state.address || '-'
				),
			},
		],
		[
			{
				field: <Title level={5}>Параметры рейтинга</Title>,
				layout: { span: 24 },
			},
			{
				layout: layout3,
				name: 'qualityManagement',
				label: (
					<LeftTooltip
						label={'Менеджмент качества'}
						tooltip={
							<span>
								Менеджмент качества
								<br />
								<br />
								Наличие сертификата - 5 баллов
								<br />
								Нет сертификата - 1 балл
							</span>
						}
					/>
				),
				rules: [{ required: true }],
				field: (
					<Select
						value={mainCtx.additional.qualityManagement}
						disabled={isDisabled}
						onChange={(value) => {
							mainCtx.stateFunctions.setQualityManagement(value)
						}}
						options={constants.qualityManagement.map((e) => ({
							key: e,
							value: e,
						}))}
					/>
				),
			},
			{
				layout: layout3,
				name: 'deliveryTime',
				rules: [{ required: true }],
				label: (
					<LeftTooltip
						label={'Сроки доставки'}
						tooltip={
							<span>
								Сроки доставки
								<br />
								<br /> Соблюдение сроков доставки - 5 баллов <br />
								Соблюдение сроков доставки с отдельными отклонениями - 3 балла
								<br /> Не соблюдение сроков доставки - 0 баллов
							</span>
						}
					></LeftTooltip>
				),
				field: (
					<Select
						style={{ width: '100%' }}
						value={mainCtx.state.deliveryTime}
						disabled={isDisabled}
						onChange={(value) => {
							mainCtx.stateFunctions.setDeliveryTime(value)
						}}
						options={constants.deliveryTime.map((e) => ({
							key: e,
							value: e,
						}))}
					/>
				),
			},
			{
				layout: layout3,
				name: 'deliverCondition',
				rules: [{ required: true }],
				label: (
					<LeftTooltip
						label={'Условия доставки'}
						tooltip={
							<span>
								Условия доставки
								<br />
								<br /> Автотранспорт поставщика - 5 баллов <br />
								Самовывоз - 4 балла
							</span>
						}
					></LeftTooltip>
				),
				field: (
					<Select
						style={{ width: '100%' }}
						value={mainCtx.state.deliverCondition}
						disabled={isDisabled}
						onChange={(value) => {
							mainCtx.stateFunctions.setDeliverCondition(value)
						}}
						options={constants.deliverCondition.map((e) => ({
							key: e,
							value: e,
						}))}
					/>
				),
			},
		],
		[
			{},
			{
				layout: layout4,
				name: 'abc',
				field: 'A',
				label: (
					<LeftTooltip
						label="АВС-анализ"
						tooltip={
							<span>
								АВС-анализ
								<br />А - включает сырье, комплектующие изделия и вспомогательные материалы
								основного производства, непосредственно участвующие в технологическом процессе
								(сырье, пищевые добавки) - 5 баллов
							</span>
						}
					>
						<QuestionCircleOutlined style={{ paddingLeft: '5px' }} />
					</LeftTooltip>
				),
			},
			{
				layout: layout4,
				name: 'paymentCondition',
				rules: [{ required: true }],
				label: (
					<LeftTooltip
						label={'Условия оплаты'}
						tooltip={
							<span>
								Условия оплаты
								<br />
								<br /> Отсрочка св. 30 дней - 5 баллов Отсрочка 8-29 дней - 4 балла Отсрочка 7 дней
								- 3 балла Предоплата до 50% включительно - 1 балл
								<br /> Предоплата свыше 50% - 0 баллов
							</span>
						}
					></LeftTooltip>
				),
				field: (
					<Select
						style={{ width: '100%' }}
						value={mainCtx.state.paymentCondition}
						disabled={isDisabled}
						onChange={(value) => {
							mainCtx.stateFunctions.setPaymentCondition(value)
						}}
						options={constants.paymentCondition.map((e) => ({
							key: e,
							value: e,
						}))}
					/>
				),
			},
			{
				layout: layout4,
				name: 'quality',
				rules: [{ required: true }],
				label: (
					<LeftTooltip
						label={'Качество'}
						tooltip={
							<span>
								Качество
								<br />
								<br /> Качество соответствует - 5 баллов Качество соответствует с отдельными
								незначительными отклонениями - 4 балла
							</span>
						}
					></LeftTooltip>
				),
				field: (
					<Select
						style={{ width: '100%' }}
						value={mainCtx.state.quality}
						disabled={isDisabled}
						onChange={(value) => {
							mainCtx.stateFunctions.setQuality(value)
						}}
						options={constants.quality.map((e) => ({
							key: e,
							value: e,
						}))}
					/>
				),
			},
		],
	]
}

/**
 * возвращает вкладки которые должны быть отображены
 * @param {Object} mainCtx
 */
export const getMinorTabs = (mainCtx, userDataCtx) => {
	const result = []
	result.push({ key: 0, title: 'Контакты', component: <ContactTab /> })
	/* if (mainCtx.state.contracts.some((c) => c.tolling)) */
	result.push({ key: 1, title: 'Площадки', component: <PlatformTab /> })
	result.push({ key: result.length, title: 'Продукты', component: <ProductTab /> })
	if (
		checkRole(
			{
				'АРМ офиса': {
					'Договоры. Поставщики': {
						view: true,
					},
				},
			},
			userDataCtx.state.rights
		)
	) {
		result.push({ key: result.length, title: 'Договоры', component: <ContractTab /> })
	}
	// if (
	// 	checkRole(
	// 		{
	// 			'АРМ офиса': {
	// 				'Поставки сырья': {
	// 					' Основные данные': {
	// 						view: true,
	// 					},
	// 				},
	// 			},
	// 		},
	// 		userDataCtx.state.rights
	// 	)
	// ) {
	// 	result.push({ key: result.length, title: 'Поставки', component: <SupplyTab /> })
	// }
	return result
}
