import React, { useContext, useState } from 'react'
import { Table } from 'antd'
import lodash from 'lodash'
import { AuthContext, ModContext } from '../../../contexts'
import { ContractItemMainContext } from '../provider/item'
import { RecordItemModal, TabItem, CustomForm } from '../../../components'
import { getColumns, getFormItems } from './utils'
import { modes } from '../../../utils'
import { prepareForOpenedModal } from '../../../utils/helpers/for-modal'
import moment from 'moment'

const ApplicationTab = () => {
	const mainCtx = useContext(ContractItemMainContext)
	const modCtx = useContext(ModContext)
	const stateFunctions = mainCtx.stateFunctions.applications

	const authCtx = useContext(AuthContext)
	const isDisabled = modCtx.mod === modes.view
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [itemForModal, setItemForModal] = useState(null)

	let stateData = mainCtx.state.applications
	const {
		stateFunctions: {
			applications: { delete: delFunc },
		},
	} = mainCtx
	stateData = prepareForOpenedModal(stateData, itemForModal)

	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const { id, _uuid_ } = stateFunctions.create()
					setItemForModal({ id, _uuid_, isNew: true })
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						isOpen={isModalOpen}
						title="Приложение"
						// formName="applications"
						item={itemForModal}
						deleteFun={delFunc}
						setItem={setItemForModal}
						setModalState={setIsModalOpen}
						width={850}
						editFun={stateFunctions.edit}
						beforeSave={() => {
							stateFunctions.updateMontlyListingByUpdatingDates(itemForModal._uuid_)
						}}
						validate={() => mainCtx.validators.applications(itemForModal._uuid_)}
						beforeClose={() => {
							mainCtx.errorFields.application.forEach((e) => {
								mainCtx.setError([], [], e, undefined)
							})
						}}
						form={() => {
							if (!itemForModal) return null
							const found = stateFunctions.get(itemForModal._uuid_)
							return (
								<CustomForm
									name="applications"
									fields={[
										{
											name: 'displayCode',
											value: found.displayCode,
										},
										{
											name: 'dateStart',
											value: found.dateStart ? moment(found.dateStart) : null,
										},
										{
											name: 'dateEnd',
											value: found.dateEnd ? moment(found.dateEnd) : null,
										},
										{
											name: 'platform',
											value: found.platform,
										},
										{
											name: 'paymentCondition',
											value: found.paymentCondition,
										},
										{
											name: 'deliveryCondition',
											value: found.deliveryCondition,
										},
									]}
									columns={[
										{ size: { xs: 24, lg: 12 }, num: 0 },
										{ size: { xs: 24, lg: 12 }, num: 1 },
									]}
									items={getFormItems(mainCtx, modCtx, itemForModal)}
								/>
							)
						}}
					/>
				)
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={!isDisabled && 'clickable'}
						columns={getColumns(mainCtx, modCtx, authCtx, (record) => {
							if (modCtx.mod === modes.view) return
							setItemForModal({ _uuid_: record._uuid_, prevData: lodash.cloneDeep(record) })
							setIsModalOpen(true)
						})}
						dataSource={stateData}
						pagination={false}
						scroll={{ x: 800 }}
					/>
				)
			}}
		/>
	)
}

export default ApplicationTab
