import { Table } from 'antd'
import React, { useState, useContext } from 'react'
import lodash from 'lodash'
import { SupplyUnloadContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { AuthContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns } from './storage-tab'

const StorageTab = () => {
	const mainCtx = useContext(SupplyUnloadContext)
	const authCtx = useContext(AuthContext)
	const stateFunctions = mainCtx.stateFunctions.unldStorages
	const modCtx = useContext(ModContext)
	const [, setIsModalOpen] = useState(false)

	/**
	 *  {_uuid_:string,isNew:boolean } itemForModal
	 */
	const [, setItemForModal] = useState(null)

	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const newUuid = stateFunctions.create()
					setItemForModal({ _uuid_: newUuid, isNew: true })
					setIsModalOpen(true)
				},
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(mainCtx, modCtx, authCtx, (record) => {
							if (modCtx.mod === modes.view) return
							setItemForModal({ _uuid_: record._uuid_, prevData: lodash.cloneDeep(record) })
							setIsModalOpen(true)
						})}
						dataSource={mainCtx.state.unldStorages}
						pagination={false}
						scroll={{ x: 800 }}
					/>
				)
			}}
		></TabItem>
	)
}

export default StorageTab
