import React, { createContext, useEffect, useReducer, useRef } from 'react'
import { useHistory } from 'react-router-dom'

const BroadcastContext = createContext()
BroadcastContext.displayName = 'BroadcastContext'

const reducer = (state) => {
	return {
		...state,
	}
}
const initialState = { bc: null }

const Provider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()

	useEffect(() => {
		const bc = new BroadcastChannel('common')
		bc.onmessage = (e) => {
			if (e.data === 'reload') {
				window.location.replace('/')
			}
		}
		executeDispatch({ bc })
		return () => {
			bc.close()
		}
	}, [history])

	const value = {
		state: stateRef.current,
	}
	return <BroadcastContext.Provider value={value}>{children}</BroadcastContext.Provider>
}

export { Provider, BroadcastContext }
