import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import { ModContext } from '../../../../contexts'
import { prepareObjFromServer, modes, getObjDiff, isEdited, axios } from '../../../../utils'

const dataUrl = '/other-data'

const reducer = (state) => ({
	...state,
})

export const rights = {
	'АРМ офиса': {
		'Настройки системы': {
			edit: true,
		},
	},
}

const StorageCostContext = React.createContext()
StorageCostContext.displayName = 'StorageCostContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)

	useEffect(() => {
		const getData = async () => {
			const {
				data: { result = [] },
			} = await axios(dataUrl)
			const rec = result.find((el) => el.type === 'storage-cost') || { data: {} }
			executeDispatch({
				...stateRef.current,
				data: { id: rec.id, ...rec.data },
				oldData: { id: rec.id, ...rec.data },
			})
		}
		getData()
	}, [])

	const [state, dispatch] = React.useReducer(reducer, {
		data: {
			storageCost: 0,
		},
		oldData: {
			storageCost: 0,
		},
		formErrors: [],
	})
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	const commonFieldUpdate = (inField, value) => {
		let newState = lodash.cloneDeep(stateRef.current.data)
		newState[inField] = value
		executeDispatch({
			...stateRef.current,
			data: {
				...newState,
			},
		})
	}
	const stateFunctions = {
		fieldSetters: {
			setStorageCost: (val) => {
				commonFieldUpdate('storageCost', val)
			},
		},
	}

	const serverEdit = async () => {
		const body = getEditedData()
		const options = { url: `${dataUrl}/storage-cost`, method: 'PUT' }
		if (!isEdited(body)) {
			return modCtx.set(modes.view)
		}
		await axios[options.method.toLowerCase()](options.url, body)
		modCtx.set(modes.view)
	}

	const getEditedData = () => {
		return getObjDiff(stateRef.current.oldData, stateRef.current.data)
	}
	const isItemEdited = () => {
		if (modCtx.mod === modes.view) {
			return false
		}
		if (modCtx.mod === modes.new && Object.keys(stateRef.current.data).length) {
			return true
		}
		const editedFields = getEditedData()
		return isEdited(editedFields)
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */

	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
		})
	}, [stateRef.current.oldData])

	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * подготавливает данные которые нужно выбират
	 */
	const selectors = {}

	const value = {
		state: stateRef.current,
		selectors,
		stateFunctions,
		serverEdit,
		isEdited: isItemEdited,
		reset,
	}

	return <StorageCostContext.Provider value={value}>{children}</StorageCostContext.Provider>
}

export { Provider, StorageCostContext }
