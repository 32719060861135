import React, { useContext } from 'react'
import Wrapper from '../Wrapper/Wrapper'
import { DataServerContext } from '../../../contexts'
import { getColumns } from './columns'

const vocUrl = '/voc/common/measure'

const Measure = () => {
	const { state } = useContext(DataServerContext)
	const data = state[vocUrl].data.sort((a, b) =>
		!a.okei ? 1 : !b.okei ? -1 : a.okei > b.okei ? 1 : a.okei < b.okei ? -1 : 0
	)

	return (
		<Wrapper
			rights={{
				Справочники: {
					'Единицы измерения': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Единицы измерения"
			data={data}
			columns={getColumns(data)}
			vocUrl={vocUrl}
		/>
	)
}

export default Measure
