import moment from 'moment'
/**
 * @param {Object} inArr - массив с записями таблицы
 */
export const getColumns = () => {
	return [
		{
			title: 'Смена',
			dataIndex: 'label',
			key: 'label',
			width: '33%',
		},
		{
			title: 'Начало смены',
			dataIndex: 'startTime',
			key: 'startTime',
			width: '33%',
			render: (startTime) => moment(startTime).format('HH:mm'),
		},
		{
			title: 'Окончание смены',
			dataIndex: 'endTime',
			key: 'endTime',
			width: '33%',
			render: (endTime) => moment(endTime).format('HH:mm'),
		},
	]
}
