import React, { createContext, useCallback, useContext, useEffect, useReducer, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { services, axios } from '../../utils'
import { checkIsNot } from '@berry/common-functions/obj-arr'
import { BroadcastContext } from '../broadcast/broadcast'
export const AuthContext = createContext()
AuthContext.displayName = 'AuthContext'

const reducer = (state) => {
	return {
		...state,
	}
}
const initialState = {
	isAuthenticated: false,
	isLoading: false,
	isLoaded: false,
	error: '',
}

export const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState /* {}, getInitialState */)
	const {
		state: { bc },
	} = useContext(BroadcastContext)

	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	const location = useLocation()

	useEffect(() => {
		if (localStorage.getItem('authCtx')) {
			const prevState = JSON.parse(localStorage.getItem('authCtx'))
			if (checkIsNot(prevState, Object.keys(initialState), [undefined])) {
				localStorage.removeItem('authCtx')
				executeDispatch(prevState)
			}
		}
	}, [])

	useEffect(() => {
		const logoutWindowIfTokensLosts = () => {
			if (!localStorage.getItem('token') && !localStorage.getItem('refresh')) {
				if (
					![
						'/login',
						'/access-denied',
						'/change-password',
						'/forgot-password',
						'/reset-password',
						'/send-mail',
					].some((url) => location.pathname.includes(url))
				) {
					history.push('/login')
				}
			}
		}
		const interval = setInterval(logoutWindowIfTokensLosts, 3000)
		return () => {
			if (interval) clearInterval(interval)
		}
	}, [history, location.pathname])

	const handleCheckLogIn = useCallback(async () => {
		executeDispatch({
			...stateRef.current,
			isLoading: true,
		})
		let newState = { ...stateRef.current }
		try {
			const result = await axios.get(services['auth-ms'] + '/pub/check-log-in')
			if (result) {
				newState = {
					isAuthenticated: true,
				}
			}
		} catch (err) {
			newState = {
				isAuthenticated: false,
			}
		}
		executeDispatch({ ...newState, isLoading: false, isLoaded: true, error: false })
	}, [])
	useEffect(() => {
		handleCheckLogIn()
	}, [handleCheckLogIn])

	const handleAutoLogOut = useCallback(async () => {
		if (location.pathname.includes('/login')) {
			executeDispatch({
				isLoading: false,
				isAuthenticated: false,
				isLoaded: true,
				error: false,
			})
		}
		return
	}, [location.pathname])
	useEffect(() => {
		handleAutoLogOut()
	}, [handleAutoLogOut, location.pathname])

	const signIn = async ({ login, pwd }) => {
		executeDispatch({
			...stateRef.current,
			isLoading: true,
		})
		try {
			const res = await axios(services['auth-ms'] + '/pub/sign-in', {
				method: 'POST',
				data: { login, pwd },
			})
			if (res.data?.result) {
				localStorage.setItem('token', res.data.result.accessToken)
				localStorage.setItem('refresh', res.data.result.refreshToken)
				executeDispatch({
					isAuthenticated: true,
					isLoading: false,
					isLoaded: true,
					error: false,
				})
				history.push('/')
			}
		} catch (err) {
			executeDispatch({
				isAuthenticated: false,
				isLoading: false,
				isLoaded: true,
				error: err.message,
			})
		}
		bc.postMessage('reload')
	}

	const signOut = async () => {
		try {
			localStorage.removeItem('token')
			localStorage.removeItem('refresh')
			window.name = ''
			executeDispatch({
				isAuthenticated: false,
				isLoading: false,
				isLoaded: true,
				error: false,
			})
			history.push('/login')
		} catch (err) {
			console.log(err)
		}
		return
	}

	const changePwd = async ({ login, oldPwd, newPwd1, newPwd2 }) => {
		try {
			const res = await axios(services['auth-ms'] + '/pub/change-pwd', {
				method: 'PUT',
				data: { login, oldPwd, newPwd1, newPwd2 },
			})
			if (res.data?.result) {
				history.push('/login')
			}
		} catch (err) {
			executeDispatch({
				isAuthenticated: false,
				isLoading: false,
				isLoaded: true,
			})
			if (err.isHumanReadable) {
				throw Error(err.message)
			}
			throw Error('Ошибка')
		}
		return true
	}

	const forgotPwd = async ({ email }) => {
		try {
			const res = await axios(services['auth-ms'] + '/pub/forgot-pwd', {
				method: 'POST',
				data: { email },
			})
			if (res.data?.result) {
				history.push('/login')
			}
		} catch (err) {
			executeDispatch({
				isAuthenticated: false,
				isLoading: false,
				isLoaded: true,
			})
			if (err.isHumanReadable) {
				throw Error(err.message)
			}
			throw Error('Ошибка')
		}
		return true
	}

	const resetPwd = async ({ login, token, newPwd1, newPwd2 }) => {
		try {
			const res = await axios(services['auth-ms'] + '/pub/reset-pwd', {
				method: 'PUT',
				data: { login, token, newPwd1, newPwd2 },
			})
			if (res.data?.result) {
				history.push('/login')
			}
		} catch (err) {
			executeDispatch({
				isAuthenticated: false,
				isLoading: false,
				isLoaded: true,
			})
			throw Error('Ошибка во время сброса пароля')
		}
		return true
	}

	const value = {
		state: stateRef.current,
		signIn,
		signOut,
		changePwd,
		resetPwd,
		forgotPwd,
	}
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
