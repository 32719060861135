import React, { useContext } from 'react'

import { Main } from '../../../layouts'
import { Provider, CustomerListMainContext } from './provider/main'
import { HeaderMenu, StatefulTable } from '../../../components'
import { getColumns } from './customer-list'
import { Card } from 'antd'

const pageUrl = '/rp/customers'

const CustomerList = () => {
	return (
		<Provider>
			<Main title={'Заказчики'}>
				<CustomerListTable />
			</Main>
		</Provider>
	)
}

const CustomerListTable = () => {
	const mainCtx = useContext(CustomerListMainContext)
	return (
		<>
			<HeaderMenu breadcrumbsTitle={'Заказчики'} search={mainCtx.setSearch} />
			<Card>
				<StatefulTable
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					url={pageUrl}
					// searchFields={mainCtx.searchFields}
				/>
			</Card>
		</>
	)
}
export default CustomerList
