import { useState, useEffect } from 'react'

const useSearchFilter = (initialData) => {
	const [searchValue, setSearchValue] = useState(null)
	const [filters, setFilters] = useState(initialData)

	const onSearch = (e) => {
		setSearchValue(e.target.value)
	}

	useEffect(() => {
		const resetFilters = () => {
			setFilters(initialData)
		}
		const filterData = () => {
			return initialData.filter((filterValue) =>
				String(filterValue.text).toLowerCase().includes(String(searchValue).toLowerCase())
			)
		}
		searchValue ? setFilters(filterData()) : resetFilters()
	}, [initialData, searchValue])

	return {
		filters,
		searchValue,
		onSearch,
		setSearchValue,
	}
}

export default useSearchFilter
