import React from 'react'
import { Breadcrumb as BreadcrumbAntd } from 'antd'
import { useLocation } from 'react-router-dom'
import { getBreadcrumbsItems } from './breadcrumbs'

const Breadcrumb = (props) => {
	const { title, record, isClickble, customItems } = props
	const { pathname } = useLocation()
	return customItems ? (
		<nav className="ant-breadcrumb">
			<ol>{customItems}</ol>
		</nav>
	) : (
		<BreadcrumbAntd>
			{customItems || getBreadcrumbsItems(pathname, title, record, isClickble)}
		</BreadcrumbAntd>
	)
}

export default Breadcrumb
