import { Row, Col, Typography } from 'antd'

const AuthError = (props) => {
	const { error } = props
	return (
		error && (
			<Row
				justify="center"
				align="middle"
				style={{
					position: 'relative',
					height: '20px',
					bottom: '15px',
					textAlign: 'center',
					fontWeight: 700,
				}}
			>
				<Col style={{ width: '500px' }}>
					<Typography.Text type="danger">{error}</Typography.Text>
				</Col>
			</Row>
		)
	)
}

export default AuthError
