import { Input, Select, Col, Typography } from 'antd'
import { modes } from '../../../../../utils'
import { getEtalon, isTextParam } from '../../specification-item'
import { isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'
import { getSpecParamDeviation } from '@berry/common-functions/cross-project-functions'
export const getDisplayColumns = (stateFunctions) => {
	return [
		{
			title: 'Параметр',
			dataIndex: 'label',
			key: 'label',
			width: '25%',
		},
		{
			title: 'ЕИ',
			dataIndex: ['vocMeasure', 'labelShort'],
			key: 'vocMeasure',
			width: '25%',
		},
		{
			title: 'Цель',
			key: 'etalon',
			render: (value, record) => {
				return getEtalon(record)
			},
			width: '25%',
		},
		{
			title: 'Допустимое отклонение',
			key: 'deviation',
			render: (value, record) => {
				return getSpecParamDeviation(record)
			},
			width: '25%',
		},
	]
}

const textItem = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}
const dashLabel = {
	labelCol: { offset: 8, span: 12 },
}
const layoutSelect = {
	wrapperCol: { span: 18 },
}
const colSpanInputRow = 3
const colSpanSelectRow = 6

/**
 * поля формы в модальном окне
 * @param {Object} mainCtx - главный контекст
 * @param {Object} modCtx - контекст мода
 * @param {{_uuid_: string}} params - отбьект с функциями изменения состояния вкладки
 */
export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []
	const { _uuid_ } = params
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.parameters
	const found = stateFunctions.get(_uuid_)
	const result = [[]]
	const devItem1 = {
		colSpan: colSpanInputRow,
		name: 'deviationVal1',
		required: found.deviationSymbol === '-',
		rules: [
			{ required: found.deviationSymbol === '-' },
			({ getFieldValue, setFields }) => ({
				validator(_, value) {
					const symbol = getFieldValue('deviationSymbol')
					setFields([{ name: 'deviationVal2', errors: [] }])
					if (symbol === '-') {
						if (found.deviationVal2 && +found.deviationVal1 >= found.deviationVal2) {
							return Promise.reject(new Error('Значение "от" должно быть меньше значения "до"'))
						}
					}
					return Promise.resolve()
				},
			}),
		],
		field: (
			<Input
				value={found.deviationVal1}
				disabled={isDisabled}
				onChange={(e) => {
					if (
						e.target.value !== '-' &&
						(!isValidNum(e.target.value) || !isValidNumberDigits(e.target.value, 'float', [14, 5]))
					) {
						return stateFunctions.setDeviationVal1(_uuid_, found.deviationVal1)
					}
					stateFunctions.setDeviationVal1(_uuid_, e.target.value)
				}}
			/>
		),
	}
	const devItem2 = {
		colSpan: colSpanInputRow,
		name: 'deviationVal2',
		required: ['>', '<', '-', '='].includes(found.deviationSymbol),
		rules: [
			{ required: ['>', '<', '-', '='].includes(found.deviationSymbol) },
			({ getFieldValue, setFields }) => ({
				validator(_, value) {
					const symbol = getFieldValue('deviationSymbol')
					setFields([{ name: 'deviationVal1', errors: [] }])
					if (symbol === '-') {
						if (
							found.deviationVal2 &&
							found.deviationVal1 &&
							+found.deviationVal2 <= found.deviationVal1
						) {
							return Promise.reject(new Error('Значение "от" должно быть меньше значения "до"'))
						}
					}
					return Promise.resolve()
				},
			}),
		],
		field: (
			<Input
				value={found.deviationVal2}
				disabled={isDisabled}
				onChange={(e) => {
					if (
						e.target.value !== '-' &&
						(!isValidNum(e.target.value) || !isValidNumberDigits(e.target.value, 'float', [14, 5]))
					) {
						return stateFunctions.setDeviationVal2(_uuid_, found.deviationVal2)
					}
					stateFunctions.setDeviationVal2(_uuid_, e.target.value)
				}}
			/>
		),
	}
	const etalonItem1 = {
		name: 'etalonVal1',
		colSpan: colSpanInputRow,
		required: found.etalonSymbol === '-',
		rules: [
			{ required: found.etalonSymbol === '-' },
			({ getFieldValue, setFields }) => ({
				validator(_, value) {
					const symbol = getFieldValue('etalonSymbol')
					setFields([{ name: 'etalonVal2', errors: [] }])
					if (symbol === '-') {
						if (found.etalonVal1 && found.etalonVal2 && +found.etalonVal1 >= found.etalonVal2) {
							return Promise.reject(new Error('Значение "от" должно быть меньше значения "до"'))
						}
					}
					return Promise.resolve()
				},
			}),
		],
		field: (
			<Input
				value={found.etalonVal1}
				disabled={isDisabled}
				onChange={(e) => {
					if (
						e.target.value !== '-' &&
						(!isValidNum(e.target.value) || !isValidNumberDigits(e.target.value, 'float', [14, 5]))
					) {
						return stateFunctions.setEtalonVal1(_uuid_, found.etalonVal1)
					}
					stateFunctions.setEtalonVal1(_uuid_, e.target.value)
				}}
			/>
		),
	}
	const etalonItem2 = {
		name: 'etalonVal2',
		required: found.etalonSymbol !== 'не доп.',
		colSpan: colSpanInputRow,
		rules: [
			{ required: ['>', '<', '-', '='].includes(found.etalonSymbol) },
			({ getFieldValue, setFields }) => ({
				validator(_, value) {
					const symbol = getFieldValue('etalonSymbol')
					setFields([{ name: 'etalonVal1', errors: [] }])
					if (symbol === '-') {
						if (found.etalonVal2 && found.etalonVal1 && +found.etalonVal2 <= found.etalonVal1) {
							return Promise.reject(new Error('Значение "от" должно быть меньше значения "до"'))
						}
					}
					return Promise.resolve()
				},
			}),
		],
		field: (
			<Input
				value={found.etalonVal2}
				disabled={isDisabled}
				onChange={(e) => {
					if (
						e.target.value !== '-' &&
						(!isValidNum(e.target.value) || !isValidNumberDigits(e.target.value, 'float', [14, 5]))
					) {
						return stateFunctions.setEtalonVal2(_uuid_, found.etalonVal2)
					}
					stateFunctions.setEtalonVal2(_uuid_, e.target.value)
				}}
			/>
		),
	}
	if (!isTextParam(found.vocSpecParamParam?.id, mainCtx.selectors.parameters.vocSpecParamParam)) {
		result[0].push(
			[
				{
					layout: {
						labelCol: { span: 23 },
						wrapperCol: { span: 1 },
					},
					required: true,
					label: 'Допустимое отклонение',
					colSpan: 6,
					field: null,
				},
				{
					layout: layoutSelect,
					colSpan: colSpanSelectRow,
					name: 'deviationSymbol',
					validateFirest: true,
					rules: [
						{ required: true },
						({ getFieldValue, setFields }) => ({
							validator(_, value) {
								if (value === null) {
									return Promise.reject()
								}
								setFields([
									{ name: 'deviationVal1', errors: [] },
									{ name: 'deviationVal2', errors: [] },
								])
							},
						}),
					],
					field: (
						<Select
							value={found.deviationSymbol}
							style={{ width: '100%' }}
							onChange={(el) => {
								stateFunctions.setDeviationSymbol(_uuid_, el)
							}}
							disabled={isDisabled}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors.parameters.deviationSymbol(_uuid_).map((it) => {
								return (
									<Select.Option key={it.value} value={it.value}>
										{it.value ? it.text : <Typography.Text italic>{it.text}</Typography.Text>}
									</Select.Option>
								)
							}) || []}
						</Select>
					),
				},
				...(found.deviationSymbol === '-'
					? [
							devItem1,
							{
								layout: dashLabel,
								label: <>&#8212;</>,
								colSpan: 3,
								colon: false,
							},
							devItem2,
					  ]
					: []),
				...(['>', '<', '='].includes(found.deviationSymbol) ? [devItem2] : []),
			],
			[
				{
					layout: {
						labelCol: { span: 15 },
						wrapperCol: { span: 1 },
					},
					label: 'Цель',
					colSpan: 6,
					field: null,
				},
				{
					layout: layoutSelect,
					name: 'etalonSymbol',
					colSpan: colSpanSelectRow,
					rules: [
						({ getFieldValue, setFields }) => ({
							validator(_, value) {
								setFields([
									{ name: 'etalonVal1', errors: [] },
									{ name: 'etalonVal2', errors: [] },
								])
							},
						}),
					],
					field: (
						<Select
							value={found.etalonSymbol}
							style={{ width: '100%' }}
							onChange={(el) => stateFunctions.setEtalonSymbol(_uuid_, el)}
							disabled={isDisabled}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors.parameters.etalonSymbol(_uuid_).map((it) => {
								return (
									<Select.Option key={it.value} value={it.value}>
										{it.value ? it.text : <Typography.Text italic>{it.text}</Typography.Text>}
									</Select.Option>
								)
							}) || []}
						</Select>
					),
				},
				...(found.etalonSymbol === '-'
					? [
							etalonItem1,
							{
								layout: dashLabel,
								label: <>&#8212;</>,
								colSpan: 3,
								colon: false,
							},
							etalonItem2,
					  ]
					: []),
				...(['>', '<', '='].includes(found.etalonSymbol) ? [etalonItem2] : []),
			]
		)
	}
	if (isTextParam(found.vocSpecParamParam?.id, mainCtx.selectors.parameters.vocSpecParamParam)) {
		result[0].push([
			{
				layout: textItem,
				label: 'Допустимое отклонение',
				name: 'deviationText',
				colSpan: 24,
				required: true,
				rules: [{ required: true }],
				field: (
					<Input.TextArea
						rows={2}
						style={{ width: '100%' }}
						value={found.deviationText}
						disabled={isDisabled}
						onChange={(e) => stateFunctions.setDeviationText(_uuid_, e.target.value)}
					/>
				),
			},
			{
				layout: textItem,
				label: 'Цель',
				name: 'etalonText',
				colSpan: 24,
				field: (
					<Input.TextArea
						rows={2}
						maxLength={5000}
						style={{ width: '100%' }}
						value={found.etalonText}
						disabled={isDisabled}
						onChange={(e) => stateFunctions.setEtalonText(_uuid_, e.target.value)}
					/>
				),
			},
		])
	}
	return result
}
