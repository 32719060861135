import { Input } from 'antd'
import { modes } from '../../../../utils'

const layout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
}

export const getFormItems = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view

	return [
		[
			{
				layout: layout,
				label: 'Раздел',
				name: 'label',
				rules: [
					{ required: true },
					{
						validator: async (_, value) => {
							if (
								mainCtx.additional.allSpecParams.some(
									(s) => s.label.toLowerCase() === value.toLowerCase()
								)
							)
								throw Error('Такой раздел уже существует')
						},
					},
				],
				field: (
					<Input
						style={{ width: '100%' }}
						value={mainCtx.state.label}
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setLabel(e.target.value)
						}}
					/>
				),
			},
		],
	]
}
