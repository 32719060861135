import React, { useContext } from 'react'
import { Image, Typography } from 'antd'
import styles from './menu.module.scss'
import { MenuContext, menuPositions } from '../../contexts'
import { logo } from '../../assets'

const Logo = ({ logoWidth = 60, children }) => {
	const { collapsed, position } = useContext(MenuContext)
	return (
		<div className={styles.logoWithText}>
			<Image src={logo} width={logoWidth} preview={false} />
			{collapsed && position === menuPositions.left ? null : (
				<Typography.Text className={collapsed ? styles.logoTextCollapsed : styles.logoText}>
					system
				</Typography.Text>
			)}
		</div>
	)
}


export default Logo
