import React, { useContext } from 'react'
import {
	AuthContext,
	FileProvider,
	ModContext,
	ModProvider,
	UserDataContext,
} from '../../../../contexts'
import { Main } from '../../../../layouts'
import { Provider, StockRawMatItemMainContext } from './provider/main'
import { Card, Row, Col, Form, Typography } from 'antd'
import {
	ActionButtons,
	CustomForm,
	HeaderMenu,
	TabList,
	showConfirmModal,
} from '../../../../components'
import { actBtnOnClose, actBtnOnDelete, actBtnOnSaveV2, modes, openNewTab } from '../../../../utils'
import { getFormItemsMain, getFormItemsSupply, getFormItemsControl } from './raw-mat-item'
import { useHistory } from 'react-router'
import { getBreadcrumbsText } from '../../../../utils/helpers/generators'
import StorageTab from './StorageTab/StorageTab'
import LabIndicatorTab from './LabIndicatorTab/LabIndicatorTab'
import CostTab from './CostTab/CostTab'
import EventHistoryTab from './EventHistoryTab'
import { checkRole } from '@berry/common-functions/role'
import ProducedPartyTab from './ProducedPartyTab/ProducedPartyTab'
const pageUrl = '/stock/raw-mats'

const { Title } = Typography

const costRights = {
	'АРМ офиса': {
		'Карточка Запасы': {
			Себестоимость: {
				view: true,
			},
		},
	},
}

const StockRawMatItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<FileProvider>
				<Provider params={{ id: props.match.params.id }}>
					<Main title="Запасы">
						<InfoComponent />
					</Main>
				</Provider>
			</FileProvider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(StockRawMatItemMainContext)
	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights, isDevAdmin },
	} = useContext(UserDataContext)
	let history = useHistory()

	const [form] = Form.useForm()

	if (mainCtx.state.__notFound) {
		history.replace('/stock/stock-rm-list')
		return null
	}
	return (
		<>
			<HeaderMenu {...getBreadcrumbsText(pageUrl, mainCtx.state)}>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						rights={{
							'АРМ офиса': {
								'Карточка Запасы': {
									edit: true,
								},
							},
						}}
						formName="stock-raw-mat"
						onClose={() => {
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						canDelete={isDevAdmin}
						onDelete={async () => {
							await actBtnOnDelete({
								history,
								pageUrl,
								showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								currentRights,
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
							})
						}}
						onSave={async () => {
							try {
								await actBtnOnSaveV2({
									pageUrl,
									history,
									form,
									showConfirmModal,
									isShowErrorModal: true,
									canSaveWithErrors: true,
									serverEdit: mainCtx.serverEdit,
									validate: mainCtx.validate,
								})
							} catch (err) {
								console.log(err)
							}
						}}
						onCancel={() => {
							form.resetFields()
							mainCtx.reset()
						}}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<Title level={5}>Основные данные</Title>
						<CustomForm form={form} name="main" items={getFormItemsMain(mainCtx, modCtx)} />
						<Title level={5}>Данные поставки</Title>
						<CustomForm form={form} name="supply" items={getFormItemsSupply(mainCtx, modCtx)} />
						<Title level={5}>Лабораторный контроль</Title>
						<CustomForm form={form} name="control" items={getFormItemsControl(mainCtx, modCtx)} />
					</Col>
				</Row>
			</Card>
			<TabList
				tabs={[
					{ key: 0, title: 'Хранение', component: <StorageTab /> },
					{ key: 1, title: 'Произведенные партии', component: <ProducedPartyTab /> },
					{ key: 2, title: 'Лабораторные показатели', component: <LabIndicatorTab /> },
					...(checkRole(costRights, currentRights)
						? [{ key: 3, title: 'Себестоимость', component: <CostTab /> }]
						: []),
					{ key: 4, title: 'История событий', component: <EventHistoryTab /> },
				]}
			/>
		</>
	)
}
export default StockRawMatItem
