import { Table } from 'antd'
import React, { useContext } from 'react'
import { RequestItemMainContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns } from './product-tab'
import SelectContractModal from './SelectContractModal'
import SelectContrQuotaModal from './SelectContrQuotaModal'
import SelectOrderModal from './SelectOrderModal'

const ProductTab = () => {
	const mainCtx = useContext(RequestItemMainContext)
	const modCtx = useContext(ModContext)
	const stateFunctions = mainCtx.stateFunctions.products
	const modalFunctions = mainCtx.modalFunctions.contractModalFunctions
	return (
		<>
			<TabItem
				addButton={{
					show: true,
					onClick: () => modalFunctions.openAddContract(),
				}}
				modal={() => {
					if (mainCtx.addContrQuota.__isOpen) {
						return <SelectContrQuotaModal />
					}
					if (mainCtx.addOrder.__isOpen) {
						return <SelectOrderModal />
					}
					return <SelectContractModal />
				}}
				table={() => {
					return (
						<Table
							size="small"
							rowKey="_uuid_"
							rowClassName={modCtx.mod !== modes.view && 'clickable'}
							columns={getDisplayColumns(modCtx, mainCtx, stateFunctions)}
							dataSource={mainCtx.state.products}
							pagination={false}
							scroll={{ x: 800 }}
						/>
					)
				}}
			/>
		</>
	)
}

export default ProductTab
