import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Modal as ModalAntd, Space, Typography, Button } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import HeaderMenu from '../../HeaderMenu/HeaderMenu'
import Body from './Body'
import Modal from './Modal'

import {
	AuthContext,
	ListProvider,
	ModContext,
	ModProvider,
	UserDataContext,
} from '../../../contexts'

import modes from '../../../utils/constants/modes'
import { addData, editData, deleteData } from '../../../services/api/requests'
import { useForm } from 'antd/lib/form/Form'
import showConfirmModal from '../../ConfirmModal/ConfirmModal'
import { showIsBLockedModalForDeps } from '../../../utils/constants/for-components'
import { axios, openNewTab } from '../../../utils'
import { getDepsList } from '../../../utils/helpers/for-deps'
import { sliceStringAndAddThreeDots } from '@berry/front-common'
import ContentForDepsModal from '../../ContentForDepsModal/ContentForDepsModal'

const Wrapper = (props) => {
	return (
		<ModProvider>
			<ListProvider>
				<WrapperBody {...props} />
			</ListProvider>
		</ModProvider>
	)
}

const WrapperBody = ({ apiUrl, vocUrl, ...props }) => {
	const { state, setState } = props

	const vocMode = useContext(ModContext)

	const memoData = useMemo(() => state, [state, vocUrl])
	const [visible, setVisible] = useState(false)
	const [delVisible, setDelVisible] = useState(false)
	const [record, setRecord] = useState(null)
	const [deleteRecord, setDeleteRecord] = useState(null)
	const {
		state: { rights: currentRights, isDevAdmin },
	} = useContext(UserDataContext)
	const authCtx = useContext(AuthContext)
	const [form] = useForm()
	useEffect(() => {
		setDelVisible(false)
		setVisible(false)
	}, [memoData])

	const handleAddOnMenu = () => {
		vocMode.set(modes.new)
		setRecord(null)
		setVisible(true)
	}

	const addItem = async (values) => {
		try {
			const response = await addData(apiUrl, { data: values })
			if (response?.data) setState((prev) => [...prev, response.data.result.data])
			setVisible(false)
		} catch (err) {
			console.log(err.message)
		}
	}

	const editItem = async (values) => {
		try {
			if (props.checkIsBlocked) {
				if (await props.checkIsBlocked(record.id)) {
					axios.post(`/logs`, {
						time: new Date().toISOString(),
						action: 'Edit',
						isBlockUnblockLogic: true,
						entity: props.entity,
						idEntity: record.id,
					})
					showConfirmModal(showIsBLockedModalForDeps)
					return
				}
			}
			if (!isDevAdmin) {
				const depsRes = await getDepsList({ id: record.id, url: vocUrl, model: props.model })
				if (depsRes.length) {
					showConfirmModal({
						title: 'Для того, чтобы отредактировать данный элемент необходимо удалить все связи',
						onOk: () => {},
						width: '40%',
						okText: 'OK',
						content: (
							<ContentForDepsModal
								deps={depsRes}
								onClick={(link) => {
									return openNewTab(link, {
										authCtx: authCtx.state,
									})
								}}
							></ContentForDepsModal>
						),
						showCancel: false,
					})
					return true
				}
			}

			const response = await editData(apiUrl, { data: { ...values, id: record.id } })
			if (response?.data)
				setState((prev) => {
					const foundIndex = prev.findIndex((el) => el.id === response.data.result.data.id)
					return [
						...prev.slice(0, foundIndex),
						response.data.result.data,
						...prev.slice(foundIndex + 1),
					]
				})
			setVisible(false)
		} catch (err) {
			console.log(err)
		}
	}
	const deleteItem = async (id) => {
		if (props.checkIsBlocked) {
			if (await props.checkIsBlocked(id)) {
				axios.post(`/logs`, {
					time: new Date().toISOString(),
					action: 'Delete',
					entity: props.entity,
					isBlockUnblockLogic: true,
					idEntity: record.id,
				})
				showConfirmModal(showIsBLockedModalForDeps)
				return
			}
		}
		const response = await deleteData(apiUrl, { id })
		if (response?.data)
			setState((prev) => {
				const foundIndex = prev.findIndex((el) => el.id === +response.data.result.id)
				return [...prev.slice(0, foundIndex), ...prev.slice(foundIndex + 1)]
			})
		setDelVisible(false)
		setRecord(null)
	}

	return (
		<>
			<HeaderMenu handleAddOnMenu={handleAddOnMenu} {...props} />
			<Body
				vocUrl={vocUrl}
				handleEditRow={(record) => {
					vocMode.set(modes.edit)
					setRecord(record)
					setVisible(true)
				}}
				handleDeleteRow={(record) => {
					setDeleteRecord(record)
					setDelVisible(true)
				}}
				data={memoData}
				{...props}
			/>
			<Modal
				visible={visible}
				vocUrl={vocUrl}
				setState={setState}
				setVisible={setVisible}
				closeModal={() => setVisible(false)}
				vocMode={vocMode}
				record={record}
				setRecord={setRecord}
				form={form}
				data={memoData}
				mod={vocMode.mod}
				addItem={addItem}
				editItem={editItem || props.editItem}
				deleteItem={deleteItem || props.deleteItem}
				{...props}
			/>
			<ModalAntd
				title={
					<>
						<InfoCircleOutlined style={{ color: '#faad14' }} /> Вы действительно хотите удалить этот
						элемент?
					</>
				}
				visible={delVisible}
				closable={false}
				centered
				onOk={() => {
					deleteItem(deleteRecord.id)
					setVisible(false)
				}}
				onCancel={() => {
					setRecord(null)
					setDelVisible(false)
				}}
				okText="Удалить"
				cancelText="Отменить"
			>
				При удалении этот элемент удалится навсегда
			</ModalAntd>
		</>
	)
}

export default Wrapper
