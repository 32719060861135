import { paintTextByStatus } from '../../../RawMat/RawMatItem/StorageTab/storage-tab'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { colorizeIntegrationListStatus } from './../../../../../utils/helpers/for-integration'
import { dateFormat } from '../../../../../utils/constants/common'
import moment from 'moment'
import clickableStatus from '../../../../../utils/helpers/clickableStatus'

export const getDisplayColumns = (mainCtx, modCtx, authCtx, userDataCtx) => {
	return [
		{
			title: '№ помещения',
			dataIndex: ['room', 'displayCode'],
			key: 'displayCode',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '12.48%',
			render: (_, record) => paintTextByStatus(numToFixed(record.weight, 2), record),
		},
		{
			title: 'Тип упаковки',
			dataIndex: ['vocContType', 'labelShort'],
			key: 'vocContType',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Масса нетто ед. упак., кг',
			dataIndex: 'nettoPkgWeight',
			key: 'nettoPkgWeight',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Дата',
			dataIndex: 'date',
			key: 'date',
			width: '7.41%',
			render: (date, record) =>
				paintTextByStatus(date ? moment(date).format(dateFormat) : null, record),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '8.48%',
			render: (_, record) => {
				if (record.status === 'Ошибка отправки в 1С')
					return colorizeIntegrationListStatus(record.status, true)
				return record.status
				// return (
				// 	<InputError
				// 		hasError={mainCtx.formErrors?.[`status.${record._uuid_}`]}
				// 		component={
				// 			<Select
				// 				value={record.status}
				// 				style={{ width: '100%', minWidth: '120px' }}
				// 				onChange={(el) => {
				// 					stateFunctions.setStatus(record._uuid_, el)
				// 				}}
				// 				disabled={
				// 					isDisabled ||
				// 					!mainCtx.selectors.storages.status.map((st) => st.label).includes(record.status)
				// 				}
				// 				notFoundContent={<Col>Не найдено</Col>}
				// 			>
				// 				{mainCtx.selectors.storages.status.map((it) => {
				// 					return (
				// 						<Select.Option key={it.label} value={it.label}>
				// 							{it.label}
				// 						</Select.Option>
				// 					)
				// 				}) || []}
				// 			</Select>
				// 		}
				// 	/>
				// )
			},
			onCell: clickableStatus(authCtx, mainCtx.state, userDataCtx),
		},
		{
			title: 'Причина блокировки',
			dataIndex: 'blockCause',
			key: 'blockCause',
			width: '11.71%',
			render: (_, record) => {
				return record.blockCauses?.map((e) => e.blockCause.label).join(', ')
				// (
				// 	<InputError
				// 		hasError={
				// 			['Удержано', 'Заблокировано'].includes(record.status)
				// 				? mainCtx.formErrors?.[`blockCauses.${record._uuid_}`]
				// 				: false
				// 		}
				// 		mode="multiple"
				// 		component={
				// 			<Select
				// 				disabled={isDisabled}
				// 				mode="multiple"
				// 				placeholder="Выберите причины"
				// 				style={{ width: '100%', minWidth: '120px' }}
				// 				value={record.blockCauses?.map((bk) => bk.blockCause?.id)}
				// 			>
				// 				{mainCtx.selectors.storages.blockCause?.map((it) => {
				// 					return (
				// 						<Select.Option key={it.id} value={it.id}>
				// 							{it.label}
				// 						</Select.Option>
				// 					)
				// 				}) || []}
				// 			</Select>
				// 		}
				// 		disabled={isDisabled || !['Заблокировано', 'Удержано'].includes(record.status)}
				// 		value={record.blockCauses?.map((bk) => bk.blockCause?.id)}
				// 		onChange={(e) => {
				// 			stateFunctions.blockCauses.setBlockCause(record._uuid_, record, e)
				// 		}}
				// 	/>
				// )
			},
		},
		{
			title: 'Комментарий',
			dataIndex: 'comment',
			key: 'comment',
			width: '5%',
		},
	]
}
