import { ColourPicker, Status } from '../../../components'
import { getColumnSearchProps, sorter, renderPossiblyEmptyString, parseHex } from '../../../utils'

/**
 * @param {Object} fromServerFilters - массив с записями таблицы
 */
export const getColumns = (props) => {
	const { fromServerFilters } = props
	return [
		{
			title: 'Артикул',
			dataIndex: 'displayCode',
			key: 'displayCode',
			sorter: sorter('number', 'displayCode'),
			width: '10%',
			render: renderPossiblyEmptyString,
		},
		{
			title: 'Наименование',
			dataIndex: 'label',
			key: 'label',
			...getColumnSearchProps(fromServerFilters.label),
			sorter: sorter('string', 'label'),
			width: '25%',
			render: renderPossiblyEmptyString,
		},
		{
			title: 'Краткое наименование',
			dataIndex: 'labelShort',
			key: 'labelShort',
			width: '15%',
			render: renderPossiblyEmptyString,
		},
		{
			title: 'Группа',
			dataIndex: ['vocProdGroup', 'label'],
			key: 'vocProdGroupLabel',
			width: '18%',
			...getColumnSearchProps(fromServerFilters.vocProdGroupLabel),
			render: renderPossiblyEmptyString,
		},
		{
			title: 'Цветовое обозначение',
			dataIndex: 'color',
			key: 'color',
			width: '12%',
			render: (color) => {
				return (
					<ColourPicker
						style={{ pointerEvents: 'none' }}
						colour={{
							value: parseHex(color),
						}}
					/>
				)
			},
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '20%',
			...getColumnSearchProps(fromServerFilters.status),
			render: (status) => <Status status={status} />,
		},
	]
}
