import React, { useState, useEffect, useContext } from 'react'

import Wrapper from '../Wrapper/Wrapper'
import { useInput } from '../../../utils'

import Form from './Form'
import { columns } from './columns'
import { addData, deleteData, editData } from '../../../services/api/requests'
import { getAvailableCode } from '../../../utils/helpers/generators'
import { getDataForVocs } from '../AdditionalSemiType/AdditionalSemiType'
import { SyncDepsContext } from '../../../contexts'

const vocUrl = '/voc/editable/product-group'

const ProductGroup = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, setState] = useState([])
	useEffect(() => {
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
	}, [syncDepsCtx.state.reloadUuids['office-ms']])

	const [recordId, setRecordId] = useState(null)

	const [label, resetLabel] = useInput('')

	const resetFields = () => {
		resetLabel()
	}

	const addItem = async (successFn = (fn) => fn) => {
		await addData(vocUrl, {
			data: {
				label: label.value,
				displayCode: String(getAvailableCode(state.map((e) => +e.displayCode))),
			},
		})
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
		successFn()
	}

	const editItem = async (successFn = (fn) => fn) => {
		await editData(vocUrl, {
			recordId,
			data: {
				id: recordId,
				label: label.value,
			},
		})
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
		successFn()
	}

	const deleteItem = async (id) => {
		await deleteData(vocUrl, {
			id,
		})
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
		setRecordId(null)
	}

	return (
		<Wrapper
			rights={{
				Справочники: {
					'Группы продукции': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Группы продукции"
			model="voc_editable_product_group"
			canCreate
			canUpdate
			canDelete
			data={state}
			recordId={recordId}
			setRecordId={setRecordId}
			addData={addItem}
			editData={editItem}
			deleteData={deleteItem}
			columns={columns}
			resetFields={resetFields}
			fields={[{ label }]}
			validateFields={[{ label }]}
			uniqueFields={[{ label }]}
			form={<Form label={label} />}
			vocUrl={vocUrl}
		/>
	)
}

export default ProductGroup
