import React, { useContext, useEffect, useRef, useCallback } from 'react'
import lodash from 'lodash'
import { ModContext } from '../../../../../contexts'

import {
	modes,
	prepareObjFromServer,
	getObjDiff,
	useItemFetchers,
	isAvailableToAddToArr,
	getNewObj,
} from '../../../../../utils'
import {
	getAvailableCode,
	getCommonProviderFunctions,
} from '../../../../../utils/helpers/generators'
import { basicValidator } from '@berry/common-functions/validators'
import { useHistory } from 'react-router-dom'

const dataUrl = '/voc/editable/main-semifinished-kind'
const tabs = { kinds: 'kinds' }
export const reducer = (state) => {
	return {
		...state,
	}
}

const MainSemiKindItemContext = React.createContext()
MainSemiKindItemContext.displayName = 'MainSemiKindItemContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const [state, dispatch] = React.useReducer(reducer, {
		data: {},
		oldData: {},
		additional: {
			allProcesses: [],
			allMainSemiKinds: [],
		},
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))

	const { stateFunctions, serverEdit, serverDelete, isEdited, commonFieldUpdate } =
		getCommonProviderFunctions(
			stateRef,
			stateRef.current.oldData,
			executeDispatch,
			{ modCtx, dataUrl, params, pageUrl: '/vocabularies/main-semifinished-kind', history },
			{},
			{
				vocProcess: 'common',
			},
			{
				kinds: {
					label: 'common',
					isCaliber: 'common',
					isAdditAttr: 'common',
				},
			}
		)

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
		})
		return
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
		return
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	const getEditedData = () => {
		if (modCtx.mod === modes.new) {
			return lodash.cloneDeep(stateRef.current.data)
		}
		return getObjDiff(stateRef.current.oldData, stateRef.current.data)
	}

	/**
	 * Валидация всех полей в соответствии с конфлюенсом
	 * @returns {string} - если вернулась строка и не пустая значит есть ошибка валидации
	 */
	const validate = () => {
		return ''
	}

	const validators = {
		kinds: (inUuid) => {
			const found = stateFunctions.kinds.get(inUuid)
			if (!basicValidator(found.label)) throw Error()
		},
	}

	/**
	 * подготавливает данные которые нужно выбират
	 */
	const selectors = {
		vocProcess: stateRef.current.additional.allProcesses.filter((e) => {
			return !stateRef.current.additional.allMainSemiKinds
				.filter((msk) => +msk.id !== +stateRef.current.id)
				.some((msk) => +msk.vocProcess.id === +e.id)
		}),
	}

	stateFunctions.kinds.create = (inData = {}) => {
		let newKinds
		inData.displayCode = getAvailableCode(
			(stateRef.current.data.kinds || []).map((e) => +e.displayCode)
		)
		if (isAvailableToAddToArr(inData)) {
			newKinds = [...(stateRef.current.data.kinds || []), getNewObj(inData)]
		} else {
			newKinds = [...(stateRef.current.data.kinds || []), getNewObj()]
		}
		commonFieldUpdate('kinds', newKinds)
		return newKinds.slice(-1)[0]._uuid_
	}

	const value = {
		state: stateRef.current.data,
		delEditDeps: stateRef.current.delEditDeps,
		stateFunctions,
		selectors,
		validators,
		serverEdit,
		getEditedData,
		isEdited: isEdited,
		validate: validate,
		serverDelete,
		reset: reset,
	}

	return (
		<MainSemiKindItemContext.Provider value={value}>{children}</MainSemiKindItemContext.Provider>
	)
}

export { Provider, MainSemiKindItemContext }
