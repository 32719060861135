import { checkRole } from '@berry/common-functions/role'
import { openNewTab } from '../../utils'
import showConfirmModal from '../ConfirmModal/ConfirmModal'

const MainCardClickableAttr = ({ text, pageRights, url, formProps }) => {
	const {
		authCtx,
		userDataCtx: {
			state: { rights },
		},
	} = formProps
	const handleProviderClick = () => {
		if (checkRole(pageRights, rights)) {
			return openNewTab(url, {
				authCtx: authCtx.state,
			})
		}
		return showConfirmModal({
			title: 'Ограничены права доступа к данному объекту. Обратитесь к администратору системы',
			okText: 'Ок',
			width: 450,
			showCancel: false,
		})
	}
	return (
		<span
			className="clickable"
			onClick={handleProviderClick}
			style={{ color: 'rgb(24 144 255)', borderBottom: '1px solid' }}
		>
			{text}
		</span>
	)
}

export default MainCardClickableAttr
