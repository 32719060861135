import { Input, TimePicker } from 'antd'
import { modes } from '../../../../utils'
import moment from 'moment'

const layoutSmall = {
	labelCol: { span: 7 },
	wrapperCol: { span: 14 },
}

export const getFormItems = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				layout: layoutSmall,
				label: 'Смена:',
				name: 'label',
				required: true,
				value: mainCtx.state?.label,
				rules: [
					{ required: true },
					{
						validator: async (_, value) => {
							if (
								mainCtx.additional.allShifts.some(
									(e) => e.label === String(value).toLowerCase() && e?.id !== mainCtx.state?.id
								)
							)
								throw Error('Данная смена уже существует')
							return
						},
					},
				],
				field: (
					<Input
						disabled={isDisabled}
						value={mainCtx.state?.label}
						maxLength={120}
						onChange={(e) => {
							mainCtx.stateFunctions.setLabel(e.target.value)
						}}
					/>
				),
			},
			{
				layout: layoutSmall,
				label: 'Начало смены:',
				name: 'startTime',
				required: true,
				rules: [{ required: true }],
				field: (
					<TimePicker
						inputReadOnly
						defaultValue={mainCtx.state?.startTime ? moment(mainCtx.state?.startTime) : null}
						placeholder="ХХ:ХХ"
						format="HH:mm"
						onChange={(e) => {
							mainCtx.stateFunctions.setStartTime(e)
						}}
						style={{ width: '100%' }}
						disabled={isDisabled}
					/>
				),
			},
			{
				layout: layoutSmall,
				label: 'Окончание смены:',
				name: 'endTime',
				required: true,
				rules: [{ required: true }],
				field: (
					<TimePicker
						inputReadOnly
						defaultValue={mainCtx.state?.endTime ? moment(mainCtx.state.endTime) : null}
						placeholder="ХХ:ХХ"
						format="HH:mm"
						onChange={(e) => {
							mainCtx.stateFunctions.setEndTime(e)
						}}
						style={{ width: '100%' }}
						disabled={isDisabled}
					/>
				),
			},
		],
	]
}
