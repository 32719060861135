/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = () => {
	return [
		{
			title: 'ФИО',
			dataIndex: 'employee',
			key: 'employee',
			width: '15%',
		},
		{
			title: 'Операция',
			dataIndex: 'action',
			key: 'action',
			width: '10%',
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			width: '10%',
		},
		{
			title: 'Дата',
			dataIndex: 'date',
			key: 'date',
			width: '5%',
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '10%',
		},
		{
			title: 'Комментарий',
			dataIndex: 'comment',
			key: 'comment',
			width: '10%',
		},
	]
}
