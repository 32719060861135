import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import Spinner from '../Spinner/Spinner'

const getSections = () => {
	return {
		reg: { title: 'Объекты учета', link: null },
		vocabularies: { title: 'Справочники', link: '/vocabularies' },
		stock: { title: 'Запасы', link: null },
		'stock-operations': { title: 'Операции с запасами', link: null },
		rp: { title: 'Готовая продукция', link: null },
		rm: { title: 'Сырье', link: null },
		pv: { title: 'Справочник продукции', link: null },
		'tech-card': { title: 'Технологические карты', link: '/tech-card' },
		production: { title: 'Производство', link: null },
		analytics: { title: 'Аналитика', link: null },
		reports: { title: 'Давальческая продукция', link: null },
	}
}

export const getBreadcrumbsItems = (pathname, title, record, clickble = true) => {
	const pathParts = pathname.split('/')
	const sections = getSections()
	return pathParts.map((part, index) => {
		const isLast = index === pathParts.length - 1

		const link = pathname
			.split('/')
			.splice(0, index + 1)
			.join('/')

		if (part === '') {
			return clickble ? (
				<Breadcrumb.Item key={index}>
					<Link to="/">Главная</Link>
				</Breadcrumb.Item>
			) : (
				<Breadcrumb.Item key={index}>Главная</Breadcrumb.Item>
			)
		}

		if (sections[part]) {
			if (sections[part].link && !isLast) {
				return (
					<Breadcrumb.Item key={index}>
						<Link to={sections[part]?.link || link}>{sections[part]?.title || title || part}</Link>
					</Breadcrumb.Item>
				)
			}

			return <Breadcrumb.Item key={index}>{sections[part]?.title || title || part}</Breadcrumb.Item>
		}

		if (!isLast) {
			return clickble ? (
				<Breadcrumb.Item key={index}>
					<Link to={link}>{title || part}</Link>
				</Breadcrumb.Item>
			) : (
				<Breadcrumb.Item key={index}>{title || part}</Breadcrumb.Item>
			)
		}

		if (isLast && !record) {
			return <Breadcrumb.Item key={index}>{title}</Breadcrumb.Item>
		}
		if (record.toString().includes('undefined')) return <Spinner />

		return <Breadcrumb.Item key={index}>{record || part}</Breadcrumb.Item>
	})
}
