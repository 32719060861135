import { EditDelBtns } from '../..'
import { openNewTab } from '../../../utils'

export const tableColumns = (
	handleEditRow = (fn) => fn,
	handleDeleteRow = (fn) => fn,
	hoverRecord = {},
	authCtx,
	notEditableFields = [],
	ignoreDeps,
	isDevAdmin
) => {
	const { id = null, canUpdate = false, canDelete = false } = hoverRecord || {}

	const handleEdit = (record) => {
		handleEditRow(record)
	}

	const handleDelete = (record) => {
		handleDeleteRow(record)
	}

	return [
		{
			key: 'edit',
			width: '6%',
			render: (record) => {
				const cannotEditRec = notEditableFields.some(
					(el) => Object.values(el)[0] === record[Object.keys(el)[0]]
				)
				if (cannotEditRec) {
					return null
				}
				return (
					<EditDelBtns
						record={record}
						canDelete={canDelete}
						canUpdate={canUpdate}
						onDelete={handleDelete}
						onEdit={handleEdit}
						ignoreDeps={ignoreDeps}
						withMouseDetect
						selectedId={id}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					/>
				)
			},
		},
	]
}
