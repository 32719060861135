import React, { useContext } from 'react'
import { AuthContext, ModContext, ModProvider, UserDataContext } from '../../../../contexts'
import { Main } from '../../../../layouts'
import { SpecParamContext, Provider } from './provider/main'
import { Card, Row, Col, Form } from 'antd'
import { ActionButtons, CustomForm, HeaderMenu, TabList } from '../../../../components'
import { actBtnOnClose, actBtnOnDelete, actBtnOnSave, modes, openNewTab } from '../../../../utils'
import { showConfirmModal } from '../../../../components'
import { getFormItems } from './spec-param-item'
import { useHistory } from 'react-router'
import ParamTab from './ParamTab/ParamTab'

const pageUrl = '/vocabularies/specification-parameter'

const SpecParamItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Параметры спецификации">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(SpecParamContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const authCtx = useContext(AuthContext)
	const history = useHistory()
	if (mainCtx.state.__notFound) {
		history.replace(pageUrl)
	}
	const [form] = Form.useForm()

	return (
		<>
			<HeaderMenu
				breadcrumbsTitle="Параметры спецификации"
				recordLabel={mainCtx.state?.label || 'Новый раздел'}
			>
				<Row>
					<ActionButtons
						rights={{
							Справочники: {
								'Параметры спецификации': {
									edit: true,
								},
							},
						}}
						formName="voc-spec-param"
						onClose={() => {
							actBtnOnClose({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							mainCtx.validate()
							const res = await actBtnOnSave({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								history: history,
								pageUrl: pageUrl,
							})
							if (res) {
								const allFields = form.getFieldsError()
								allFields.forEach((f) => {
									f.errors = []
								})
								form.setFields(allFields)
							}
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						canDelete={modCtx.mod !== modes.new}
						onDelete={async () => {
							await actBtnOnDelete({
								history,
								pageUrl,
								showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								deps: mainCtx.delEditDeps?.['voc_spec_param'][mainCtx.state.id],
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
								currentRights,
							})
						}}
						onCancel={() => {
							const allFields = form.getFieldsError()
							allFields.forEach((f) => {
								f.errors = []
							})
							form.setFields(allFields)
							mainCtx.reset()
						}}
					/>
				</Row>
			</HeaderMenu>

			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							name="voc-spec-param"
							form={form}
							fields={[{ name: 'label', value: mainCtx.state.label }]}
							columns={[{ size: { xs: 24, lg: 12 }, num: 0 }]}
							items={getFormItems(mainCtx, modCtx)}
						/>
					</Col>
				</Row>
			</Card>

			<TabList tabs={[{ key: 0, title: 'Параметры', component: <ParamTab /> }]} />
		</>
	)
}
export default SpecParamItem
