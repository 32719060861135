import { isValidNum } from '@berry/common-functions/validators'
import axios from './axios/axios'

const commonBlockForEditing = async (dataUrl, id) => {
	const res = await axios.post(`${dataUrl}/block/${id}`, {})
	if (res.status !== 200) {
		return false
	}
	return true
}

const commonUnblockForEditing = async (depsBlocked, dataUrl, id) => {
	if (depsBlocked?.length) {
		const promises = [
			axios.post(
				`/unblock-entity`,
				depsBlocked.map((e) => {
					return { entity: e.entity, id: e.id }
				})
			),
		]
		if (isValidNum(id)) {
			promises.push(axios.post(`${dataUrl}/unblock/${id}`, {}))
		}
		const results = await Promise.all(promises)
		return results.every((r) => r.status === 200)
	} else {
		if (isValidNum(id)) {
			const result = await axios.post(`${dataUrl}/unblock/${id}`, {})
			if (result.status !== 200) {
				return false
			}
		}
	}
	return true
}

const commonCheckIsBlocked = async (inArr) => {
	const toSend = inArr.filter((e) => e.id && e.id !== 'new')
	if (!toSend.length)
		return inArr.map((e) => {
			return { ...e, isBlocked: false }
		})
	const res = await axios.get(`/check-block`, {
		params: {
			params: inArr.filter((e) => e.id && e.id !== 'new'),
		},
	})
	res.data.mainData.push(
		...inArr
			.filter((e) => !e.id)
			.map((e) => {
				return { ...e, isBlocked: false }
			})
	)

	return res.data.mainData
}

const commonCheckIsBlockedAtLeastOneEntity = async (inArr) => {
	const results = await commonCheckIsBlocked(inArr)
	return results.some((e) => e.isBlocked)
}

export {
	commonBlockForEditing,
	commonUnblockForEditing,
	commonCheckIsBlocked,
	commonCheckIsBlockedAtLeastOneEntity,
}
