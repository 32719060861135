import { Table } from 'antd'
import React, { useContext } from 'react'
import { SpecificationItemContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { ModContext } from '../../../../contexts'
import { modes, prepareData } from '../../../../utils'
import { getDisplayColumns } from './utils'

const EventHistoryTab = ({ modalItem, setModalItem, isModalOpen, setIsModalOpen }) => {
	const mainCtx = useContext(SpecificationItemContext)
	const modCtx = useContext(ModContext)
	const columns = getDisplayColumns()
	const dataSource = prepareData(mainCtx.state.data.eventHistories || [])
	return (
		<TabItem
			addButton={{
				show: false,
			}}
			table={() => (
				<Table
					size="small"
					rowKey="_uuid_"
					rowClassName={modCtx.mod !== modes.view && 'clickable'}
					columns={columns}
					dataSource={dataSource}
					scroll={{ x: 800 }}
					pagination={false}
					onRow={(record) => ({
						onDoubleClick: () => {
							if (modCtx.mod === modes.view) return
							setModalItem({ _uuid_: record._uuid_, record })
							isModalOpen(true)
						},
					})}
				/>
			)}
		/>
	)
}

export default EventHistoryTab
