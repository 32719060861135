import { Checkbox } from 'antd'
import { calcSpan, sortWithSpec } from '../utils'
import { getColumnSearchProps } from '../../../../../utils'
import { getDeviation, getEtalon } from '../../../../Vocabulary/SpecParam/spec-param'
import '../../../../../index.css'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (mainCtx, dataSource, params, setParams) => {
	const {
		selectors: {
			parameters: { vocSpecParam: vocSpecParamSelectors },
		},
		state: {
			data: { parameters },
		},
		additional,
	} = mainCtx
	return [
		{
			title: null,
			key: 'sectionCheckbox',
			width: '3%',
			className: 'elemAlignTop',
			render: (record) => {
				const { params: specParams } = mainCtx.selectors.vocSpecParam.find((p) =>
					p.params.some((param) => param.id === record.id)
				)
				const checked = specParams.every((sp) =>
					[...params, ...(parameters || [])]?.some((p) =>
						[p.vocSpecParamParam?.id, p.id].includes(sp.id)
					)
				)
				const disabled = vocSpecParamSelectors
					.find((p) => p.params.find((param) => param.id === record.id))
					.params.every((recParam) =>
						(parameters || []).find((p) => p.vocSpecParamParam?.id === recParam.id)
					)
				const handleCheck = (e) => {
					if (e.target.checked) {
						setParams([
							...params,
							...specParams.filter(
								(sp) =>
									![...params, ...(parameters || [])].some(
										(p) => p.vocSpecParamParam?.id === sp.id || p.id === sp.id
									)
							),
						])
					} else {
						setParams(
							[...params, ...(parameters || [])].filter(
								(p) => !specParams.find((sp) => sp.id === p.id)
							)
						)
					}
				}
				return <Checkbox checked={checked} onChange={handleCheck} disabled={disabled} />
			},
			onCell: (record) => ({
				rowSpan: calcSpan(dataSource, 'spec.displayVal', record),
			}),
		},
		{
			title: 'Раздел',
			dataIndex: ['spec', 'displayVal'],
			key: 'displayVal',
			width: '20%',
			className: 'elemAlignTop',
			onCell: (record) => ({
				rowSpan: calcSpan(dataSource, 'spec.displayVal', record),
			}),
			...getColumnSearchProps(
				additional.allSpecParams.map((spec) => spec.displayVal),
				undefined,
				'spec.displayVal'
			),
		},
		{
			title: null,
			dataIndex: 'id',
			key: 'paramCheckbox',
			width: '3%',
			render: (id, record) => (
				<Checkbox
					checked={
						mainCtx.state.data.parameters?.find((param) => param.vocSpecParamParam?.id === id) ||
						params.find((p) => p.id === id)
					}
					onChange={(e) =>
						e.target.checked
							? setParams([
									...params,
									mainCtx.selectors.vocSpecParam
										.find((p) => p.params.find((param) => param.id === record.id))
										['params'].find((param) => param.id === id),
							  ])
							: setParams(params.filter((param) => param.id !== id))
					}
					disabled={parameters?.find((p) => p.vocSpecParamParam?.id === record.id)}
				/>
			),
		},
		{
			title: 'Параметр',
			dataIndex: 'displayVal',
			key: 'section',
			width: '30%',
			...getColumnSearchProps(
				additional.allSpecParams.flatMap((spec) => spec.params.map((p) => p.displayVal)),
				undefined,
				'displayVal'
			),
			sorter: sortWithSpec,
		},
		{
			title: 'ЕИ',
			dataIndex: ['vocMeasure', 'displayVal'],
			key: 'vocMeasure',
			width: '5%',
		},
		{
			title: 'Цель',
			key: 'etalon',
			render: (value, record) => {
				return (
					<div style={{ wordWrap: 'break-word', wordBreak: 'break-word', ellipsis: true }}>
						{getEtalon(record)}
					</div>
				)
			},
			width: '15%',
		},
		{
			title: 'Допустимое отклонение',
			key: 'deviation',
			render: (value, record) => {
				return getDeviation(record)
			},
			width: '25%',
		},
	]
}
