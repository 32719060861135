import React, { useContext } from 'react'

import { Main } from '../../../layouts'
import { Provider, ProductCatalogListMainContext } from './provider/main'
import { HeaderMenu, StatefulTable } from '../../../components'
import { getColumns } from './product-catalog-list'
import { Card } from 'antd'
import { goToItem } from '../../../utils'
import { useHistory } from 'react-router'

const pageUrl = '/pv/product-catalog'

const ProductCatalogList = () => {
	return (
		<Provider>
			<Main title={'Продуктовый каталог'}>
				<ProductCatalogListTable />
			</Main>
		</Provider>
	)
}

const ProductCatalogListTable = () => {
	const mainCtx = useContext(ProductCatalogListMainContext)
	const history = useHistory()

	return (
		<>
			<HeaderMenu
				rights={{
					'АРМ офиса': {
						'Продуктовый каталог': { edit: true },
					},
				}}
				breadcrumbsTitle={'Продуктовый каталог'}
				search={mainCtx.setSearch}
				canCreate={true}
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					url={pageUrl}
					// searchFields={mainCtx.searchFields}
				/>
			</Card>
		</>
	)
}
export default ProductCatalogList
