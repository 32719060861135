import React from 'react'
import { Col, Image, Typography } from 'antd'
import styles from '../../components/Menu/menu.module.scss'
import { logo } from '../../assets'
import CollapsedIcon from './CollapsedIcon'
import { Menu } from '../../components'

const TopMenu = ({ show }) => {
	return show ? (
		<>
			<Col
				style={{
					width: 250,
					background: '#07213E',
					display: 'flex',
					justifyContent: 'space-evenly',
					alignItems: 'center',
				}}
			>
				<Image
					src={logo}
					width={60}
					height={64}
					preview={false}
					style={{ position: 'relative', background: '#07213E' }}
				/>
				<Typography.Text
					className={styles.logoText}
					style={{
						fontSize: 21,
						fontWeight: 700,
						lineHeight: 1.2,
						letterSpacing: 6,
						fontFamily: 'Exo',
						color: '#fff',
						textTransform: 'uppercase',
						verticalAlign: 'middle',
						height: '100%',
					}}
				>
					system
				</Typography.Text>
			</Col>
			<Col
				flex="1 1 0%"
				style={{
					height: 64,
				}}
			>
				<Menu />
			</Col>
		</>
	) : (
		<CollapsedIcon />
	)
}

export default TopMenu
