import { Checkbox, Row } from 'antd'
import LeftTooltip from './../../../../components/LeftTooltip/LeftTooltip'
import moment from 'moment'
import { dateFormat } from '../../../../utils/constants/common'
import { modes } from '../../../../utils'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */

export const getDisplayColumns = (mainCtx, modCtx) => {
	const isCheckedAsWaste = (record) =>
		mainCtx.state.reports
			.filter(({ status }) => status === 'Принято в 1С')
			.flatMap((rep) => rep.prods)
			.filter((produced) => mainCtx.state.produceds.find((p) => p.id === produced.idProd))
			.find(
				(produced) =>
					produced.stockSemifStor?.idStockSemif &&
					produced.stockSemifStor.idStockSemif === record.stockWasteStor?.stockWaste?.idStockSemif
			)
	return [
		{
			title: 'ГП в 1С',
			dataIndex: 'isReadyProdIn1c',
			key: 'isReadyProdIn1c',
			width: '4.49%',
			render: (_, record) => {
				return (
					<Checkbox
						onChange={(e) => {
							if (e.target.checked) {
								mainCtx.modalFunctions.addTo1cProducedReadyProds.create({
									id: record.id,
								})
							} else {
								mainCtx.modalFunctions.addTo1cProducedReadyProds.delete(
									mainCtx.addTo1cProduced.addTo1cProducedReadyProds.find((q) => q.id === record.id)
										._uuid_
								)
							}
						}}
						disabled={!['ГП', 'ДГП'].includes(record.type) || record.status !== 'Допущено'}
						checked={mainCtx.addTo1cProduced.addTo1cProducedReadyProds?.some(
							(el) => el.id === record.id
						)}
					/>
				)
			},
		},
		{
			title: (
				<Row>
					<Row style={{ marginRight: '5px' }}>{'Отход в 1С'}</Row>
					<LeftTooltip tooltip={'Не может быть использован в дальнейшем производстве'} label={''} />
				</Row>
			),
			dataIndex: 'isWasteIn1c',
			key: 'isWasteIn1c',
			width: '6.46%',
			render: (_, record) => {
				return (
					<Checkbox
						onChange={(e) => {
							if (e.target.checked) {
								mainCtx.modalFunctions.addTo1cProducedWastes.create({
									id: record.id,
								})
							} else {
								mainCtx.modalFunctions.addTo1cProducedWastes.delete(
									mainCtx.addTo1cProduced.addTo1cProducedWastes.find((q) => q.id === record.id)
										._uuid_
								)
							}
						}}
						disabled={
							record.type !== 'ПФ' ||
							(!(
								// условие 1 из кф
								(
									['Допущено', 'Хранение'].includes(record.status) &&
									mainCtx.state.status === 'В работе'
								)
							) &&
								!(
									// условие 2 из кф
									(
										mainCtx.state.status === 'Выполнен' &&
										mainCtx.state.wasteManagement === 'Утилизация за счет производства' &&
										'К отгрузке' === record.status
									)
								)) ||
							modCtx.mod !== modes.view
						}
						checked={mainCtx.addTo1cProduced.addTo1cProducedWastes?.some(
							(el) => el.id === record.id
						)}
						style={{ marginRight: '10px' }}
					/>
				)
			},
		},
		{
			title: '№ партии',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '6.78%',
		},
		{
			title: 'Артикул',
			dataIndex: 'displayCode',
			key: 'displayCode',
			width: '10%',
			render: (_, record) => {
				return record.articul
			},
		},
		{
			title: 'Артикул 1С',
			dataIndex: 'vendorCode',
			key: 'vendorCode',
			width: '10%',
			render: (_, record) => {
				return record.articul1C
			},
		},
		{
			title: 'Продукт',
			dataIndex: 'product',
			key: 'product',
			width: '28.22%',
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '6.51%',
		},
		{
			title: 'Дата операции',
			dataIndex: 'date',
			key: 'date',
			width: '6.51%',
			render: (date) => (date ? moment(date).format(dateFormat) : null),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '5.51%',
		},
		{
			title: 'Принято в 1С',
			dataIndex: 'accepted1c',
			key: 'accepted1c',
			width: '6.26%',
			render: (_, record) => {
				return (
					<Checkbox
						checked={
							record.reps.some(
								(report) => report.idProd === record.id && report.rep.status === 'Принято в 1С'
							) ||
							record.status === 'К отгрузке' ||
							isCheckedAsWaste(record)
						}
						disabled
					/>
				)
			},
		},
		{
			title: 'Дата приемки в 1С',
			dataIndex: 'dateSendTo1c',
			key: 'dateSendTo1c',
			width: '8.26%',
			render: (_, record) => {
				if (isCheckedAsWaste(record)) {
					return record.stockWasteStor?.date || null
				}
				const reps = mainCtx.state.produceds.flatMap((p) => p.reps)
				const date = reps.find(
					(report) =>
						(report.idProd === record.id && report.rep.status === 'Принято в 1С') ||
						record.status === 'К отгрузке'
				)?.rep.date
				if (date) {
					return moment(date).format(dateFormat)
				}
				return null
			},
		},
	]
}
