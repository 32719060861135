import { getColumnSearchProps, sorter } from '../../../utils'

/**
 * @param {Object} fromServerFilters - массив с записями таблицы
 */

export const getColumns = (props) => {
	const { fromServerFilters } = props
	return [
		{
			title: 'ID ДЗ',
			dataIndex: 'id',
			key: 'id',
			width: '8.03%',
			sorter: sorter('number', 'id'),
			...getColumnSearchProps(fromServerFilters.id),
		},
		{
			title: 'Целевой продукт',
			key: 'targetProduct',
			width: '30.84%',
			dataIndex: 'targetProduct',
			sorter: sorter('string', 'targetProduct'),
			...getColumnSearchProps(fromServerFilters.targetProduct),
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			...getColumnSearchProps(fromServerFilters.articul),
			key: 'articul',
			width: '6.75%',
		},
		{
			title: 'Артикул 1C',
			dataIndex: 'articul1C',
			...getColumnSearchProps(fromServerFilters.articul1C),
			key: 'articul1C',
			width: '6.75%',
		},
		{
			title: 'Масса план, кг',
			dataIndex: 'supplyPlanWeight',
			key: 'supplyPlanWeight',
			width: '8.15%',
		},
		{
			title: 'Поставлено, кг',
			dataIndex: 'supplyFactWeight',
			key: 'supplyFactWeight',
			width: '8.15%',
		},
		{
			title: 'Отгружено, кг',
			dataIndex: 'unloadWeight',
			key: 'unloadWeight',
			width: '8.15%',
		},
		{
			title: 'Дата отгрузки план.',
			dataIndex: 'formatedUnloadDatePlan',
			key: 'formatedUnloadDatePlan',
			width: '12.42%',
			sorter: sorter('date', 'formatedUnloadDatePlan'),
			...getColumnSearchProps(fromServerFilters.formatedUnloadDatePlan),
		},
		{
			title: 'Статус',
			dataIndex: 'calcedStatus',
			key: 'calcedStatus',
			width: '10.76%',
			...getColumnSearchProps(fromServerFilters.calcedStatus),
		},
	]
}
