import { Select, Radio, Input, Button } from 'antd'
import { EditDelBtns, InputError, CommonSelect } from '../../../../components'

import { modes, openNewTab } from '../../../../utils'
import { setNumField } from '@berry/front-common/for-fields'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { CopyOutlined } from '@ant-design/icons'
/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (mainCtx, modCtx, authCtx, userCtx) => {
	const stateFunctions = mainCtx.stateFunctions.reports
	const isDisabled = modCtx.mod === modes.view
	const getPercentageWeightFact = (rec) => {
		const curProdCatKind = rec.prodCatKindSemif?.prodCatKind || rec.prodCatPkg?.prodCatKind
		if (rec.weightPlan && rec.weight) {
			return numToFixed(
				(mainCtx.state.reports?.reduce((acc, cur) => {
					if (
						curProdCatKind === (cur.prodCatKindSemif?.prodCatKind || cur.prodCatPkg?.prodCatKind)
					) {
						if (!isNaN(cur.weight) && cur.weight) return +cur.weight + acc
						return 0 + acc
					}
					return 0 + acc
				}, 0) /
					rec.weightPlan) *
					100,
				1
			)
		}
		return ''
	}

	const getTypeOptions = () => {
		return (
			mainCtx.state.taskKind === 'Производство ГП' &&
			!mainCtx.state.isSavePartyNum &&
			!mainCtx.state.isFabricatedParty
				? ['ГП', 'ОК', 'ПФ', 'ДГП']
				: mainCtx.state.taskKind === 'Производство ГП'
				? ['ГП', 'ОК', 'ПФ']
				: ['ОК', 'ПФ']
		).map((el) => ({
			value: el,
			label: el,
			key: el,
		}))
	}

	const isNeedFactWeightField = (rec) => {
		const allReadyProdTypeReps = mainCtx.state.reports?.filter(
			(rep) =>
				['ГП', 'ДГП'].includes(rep.type) &&
				rec.prodCatPkg?.prodCatKind === rep.prodCatPkg?.prodCatKind
		)
		if (rec._uuid_ === allReadyProdTypeReps?.[0]?._uuid_) return true
		return false
	}

	return [
		{
			title: '№ партии',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '8.46%',
			defaultSordOrder: 'ascend',
			render: (text, rec) => {
				if (!userCtx.state.isDevAdmin && !userCtx.state.isAdmin) {
					return text
				}
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`partyNum.${rec._uuid_}`]}
						style={{ width: '100%' }}
						value={rec.partyNum}
						disabled={isDisabled || mainCtx.isDisabledByChangeStatusBtn}
						onChange={(e) => {
							stateFunctions.setPartyNum(rec._uuid_, e.target.value)
						}}
					/>
				)
			},
		},
		{
			title: 'Тип',
			dataIndex: 'type',
			key: 'type',
			width: '3.92%',
			render: (type, rec) => {
				if (type === 'ПФ') {
					return (
						<Input
							value={type}
							disabled
							style={{ width: '100%', columnWidth: '70px', minWidth: '70px' }}
						/>
					)
				}
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`type.${rec._uuid_}`]}
						style={{ width: '100%', columnWidth: '70px', minWidth: '70px' }}
						component={
							<Select
								options={getTypeOptions()}
								showSearch={true}
								value={rec.type}
								onSelect={(value) => {
									stateFunctions.setType(rec._uuid_, value)
									stateFunctions.setPartyNum(
										rec._uuid_,
										mainCtx.calcReportPartyNum(rec._uuid_),
										false
									)
								}}
								disabled={isDisabled || rec.isFirst || mainCtx.isDisabledByChangeStatusBtn}
							/>
						}
					/>
				)
			},
		},
		{
			title: 'Артикул',
			key: 'articul',
			width: '6.71%',
			render: (_, rec) => {
				if (['ПФ', 'ДГП'].includes(rec.type)) {
					const dataArr =
						rec.type === 'ПФ'
							? mainCtx.selectors.allProdCatKindSemifs
									.filter((e) => e.articul)
									.map((e) => ({
										...e,
										displayVal: e.articul,
									}))
							: mainCtx.selectors.allProdCatPkg
									.filter((e) => e.articul)
									.map((e) => ({
										...e,
										displayVal: e.articul,
									}))
					return (
						<InputError
							hasError={mainCtx.formErrors?.[`prodCatKindSemif.articul.${rec._uuid_}`]}
							style={{ width: '100%', columnWidth: '74px', minWidth: '74x' }}
							component={
								<CommonSelect
									showSearch
									plainValue={rec.prodCatKindSemif?.articul || rec.prodCatPkg?.articul}
									setValue={(data) => {
										if (rec.type === 'ПФ') {
											stateFunctions.setProdCatKindSemif(rec._uuid_, data)
										} else {
											stateFunctions.setProdCatPkg(rec._uuid_, data)
										}
									}}
									isDisabled={
										isDisabled ||
										rec.isFirst ||
										rec.type === 'ГП' ||
										mainCtx.isDisabledByChangeStatusBtn
									}
									dataArr={dataArr}
								/>
							}
						/>
					)
				}
				return rec.prodCatPkg?.articul || rec.prodCatKindSemif?.articul
			},
		},
		{
			title: 'Артикул 1С',
			key: 'articul1C',
			width: '5.38%',
			render: (_, rec) => {
				if (['ПФ', 'ДГП'].includes(rec.type)) {
					const dataArr =
						rec.type === 'ПФ'
							? mainCtx.selectors.allProdCatKindSemifs
									.filter((e) => e.articul1C)
									.map((e) => ({
										...e,
										displayVal: e.articul1C,
									}))
							: mainCtx.selectors.allProdCatPkg
									.filter((e) => e.articul1C)
									.map((e) => ({
										...e,
										displayVal: e.articul1C,
									}))
					return (
						<InputError
							hasError={mainCtx.formErrors?.[`prodCatKindSemif.articul1C.${rec._uuid_}`]}
							style={{ width: '100%', columnWidth: '75px', minWidth: '75px' }}
							component={
								<CommonSelect
									showSearch={true}
									plainValue={rec.prodCatKindSemif?.articul1C || rec.prodCatPkg?.articul1C}
									setValue={(data) => {
										if (rec.type === 'ПФ') {
											stateFunctions.setProdCatKindSemif(rec._uuid_, data)
										} else {
											stateFunctions.setProdCatPkg(rec._uuid_, data)
										}
									}}
									isDisabled={
										isDisabled ||
										rec.isFirst ||
										rec.type === 'ГП' ||
										mainCtx.isDisabledByChangeStatusBtn
									}
									dataArr={dataArr}
								/>
							}
						/>
					)
				}
				return rec.prodCatPkg?.articul1C || rec.prodCatKindSemif?.articul1C
			},
		},
		{
			title: 'Вид',
			key: 'prodCatKindSemif',
			width: '12.54%',
			render: (_, rec) => {
				if (rec.type === 'ОК') {
					return rec.prodCatKindSemif?.displayVal || rec.prodCatPkg?.displayVal
				}
				const dataArr =
					rec.type === 'ПФ'
						? mainCtx.selectors.allProdCatKindSemifs
						: mainCtx.selectors.allProdCatPkg
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`prodCatKindSemif.prodCatKind.${rec._uuid_}`]}
						style={{ width: '100%', columnWidth: '200px', minWidth: '200px' }}
						component={
							<CommonSelect
								showSearch={true}
								value={rec.prodCatPkg}
								plainValue={rec.prodCatKindSemif?.prodCatKind || rec.prodCatPkg?.prodCatKind}
								setValue={(data) => {
									if (rec.type === 'ПФ') {
										stateFunctions.setProdCatKindSemif(rec._uuid_, data)
									} else {
										stateFunctions.setProdCatPkg(rec._uuid_, data)
									}
								}}
								isDisabled={
									isDisabled ||
									rec.isFirst ||
									rec.type === 'ГП' ||
									mainCtx.isDisabledByChangeStatusBtn
								}
								dataArr={dataArr}
							/>
						}
					/>
				)
			},
		},
		{
			title: 'Тип упаковки',
			key: 'contType',
			width: '6.71%',
			render: (_, rec) => {
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`contType.${rec._uuid_}`]}
						component={
							<CommonSelect
								showSearch={true}
								value={rec.contType}
								plainValue={rec.contType?.labelShort}
								setValue={(data) => {
									stateFunctions.setContType(rec._uuid_, data)
								}}
								isDisabled={isDisabled || mainCtx.isDisabledByChangeStatusBtn}
								dataArr={mainCtx.additional.allSelectContTypes}
							></CommonSelect>
						}
					/>
				)
			},
		},
		{
			title: 'Масса нетто ед. упак., кг',
			dataIndex: 'nettoPkgWeight',
			key: 'nettoPkgWeight',
			rules: [{ required: true }],
			width: '6.27%',
			render: (_, rec) => {
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`nettoPkgWeight.${rec._uuid_}`]}
						style={{ width: '100%' }}
						value={rec.nettoPkgWeight}
						disabled={isDisabled || mainCtx.isDisabledByChangeStatusBtn}
						onChange={(e) => {
							setNumField(
								e.target.value,
								rec.nettoPkgWeight,
								stateFunctions.setNettoPkgWeight,
								'float',
								[5, 2],
								{
									_uuid_: rec._uuid_,
									isOnlyNums: true,
								}
							)
						}}
					/>
				)
			},
		},
		{
			title: 'Масса, кг',
			key: 'weight',
			children: [
				{
					title: 'План, кг',
					dataIndex: '',
					key: 'weightPlan',
					width: '5%',
					render: (_, rec) => {
						if (!isNeedFactWeightField(rec)) return null
						return ['ГП', 'ДГП'].includes(rec.type) ? (
							<Input
								style={{ width: '100%' }}
								value={rec.weightPlan}
								disabled={isDisabled || mainCtx.isDisabledByChangeStatusBtn}
								onChange={(e) => {
									setNumField(
										e.target.value,
										rec.weightPlan,
										stateFunctions.setWeightPlan,
										'float',
										[9, 2],
										{
											_uuid_: rec._uuid_,
										}
									)
								}}
							/>
						) : null
					},
				},
				{
					title: 'Факт, кг',
					dataIndex: '',
					key: 'weightFact',
					rules: [{ required: true }],
					width: '5%',
					render: (_, rec) => {
						return (
							<InputError
								hasError={mainCtx.formErrors?.[`weight.${rec._uuid_}`]}
								message={mainCtx.formErrors?.[`weight.${rec._uuid_}`]}
								style={{ width: '100%' }}
								value={rec.weight}
								disabled={isDisabled}
								onChange={(e) => {
									setNumField(
										e.target.value,
										rec.weight,
										stateFunctions.setWeight,
										'float',
										[9, 2],
										{
											_uuid_: rec._uuid_,
											allowZero: false,
											errorSetter: () => mainCtx.setError([], [], `weight.${rec._uuid_}`, true),
										}
									)
								}}
							/>
						)
					},
				},
				{
					title: 'Факт, %',
					dataIndex: 'weightPers',
					key: 'weightPers',
					width: '5%',
					render: (_, rec) => getPercentageWeightFact(rec),
				},
			],
		},
		{
			title: '№ пом.',
			key: 'room',
			width: '3.73%',
			render: (_, rec) => {
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`room.${rec._uuid_}`]}
						component={
							<CommonSelect
								showSearch={true}
								value={rec.room}
								setValue={(data) => {
									stateFunctions.setRoom(rec._uuid_, data)
								}}
								isDisabled={isDisabled}
								dataArr={mainCtx.additional.allRooms}
							></CommonSelect>
						}
					/>
				)
			},
		},
		{
			title: 'Контроль МД',
			key: 'isControlMd',
			width: '5.35%',
			render: (_, rec) => {
				return (
					<Radio.Group
						disabled={isDisabled || mainCtx.isDisabledByChangeStatusBtn}
						defaultValue={rec?.isControlMd || false}
						onChange={(e) => {
							mainCtx.stateFunctions.reports.setIsControlMd(rec._uuid_, e.target.value)
						}}
					>
						<Radio value={true}>Да</Radio>
						<Radio value={false}>Нет</Radio>
					</Radio.Group>
				)
			},
		},
		{
			title: 'Статус',
			key: 'status',
			width: '5.83%',
			render: (_, rec) => {
				const dataArr = ['Допущено', 'Заблокировано', 'Удержано']
				if (rec.type === 'ПФ' && !mainCtx.isDisabledByChangeStatusBtn) {
					dataArr.push('Брак')
				}
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`status.${rec._uuid_}`]}
						style={{ width: '100%', columnWidth: '110px', minWidth: '110px' }}
						component={
							<CommonSelect
								plainValue={rec.status}
								setValue={(data) => {
									stateFunctions.setStatus(rec._uuid_, data)
									mainCtx.dropError(`blockCauses.${rec._uuid_}`)
								}}
								isDisabled={isDisabled || rec.prodCatKindSemif?.vocProdType?.labelShort === 'НО'}
								dataArr={dataArr}
							/>
						}
					/>
				)
			},
		},
		{
			title: 'Причина блокировки',
			dataIndex: 'blockCause',
			key: 'blockCause',
			rules: [{ required: true }],
			width: '8.75%',
			render: (_, rec) => {
				return (
					<InputError
						style={{ width: '100%', columnWidth: '100px', minWidth: '100px' }}
						hasError={mainCtx.formErrors?.[`blockCauses.${rec._uuid_}`]}
						component={
							<Select
								disabled={isDisabled}
								placeholder="Выберите причины"
								mode="multiple"
								value={rec.blockCauses?.map((bk) => bk.blockCause.id)}
								options={mainCtx.additional.allBlockCauses.map((it) => ({
									label: it.label,
									value: it.id,
								}))}
							/>
						}
						disabled={isDisabled || rec.status === 'Допущено'}
						onChange={(e) => {
							stateFunctions.blockCauses.setBlockCause(
								rec._uuid_,
								rec,
								e,
								rec.status === 'Заблокировано'
							)
						}}
					/>
				)
			},
		},
		{
			title: 'Комментарий',
			dataIndex: '',
			key: 'comment',
			width: '8.11%',
			render: (_, rec) =>
				['Заблокировано', 'Удержано'].includes(rec.status) ? (
					<Input
						disabled={isDisabled}
						value={rec?.comment}
						onChange={(e) => stateFunctions.setComment(rec._uuid_, e.target.value)}
					/>
				) : null,
		},
		...(mainCtx.isDisabledByChangeStatusBtn && modCtx.mod === modes.edit
			? [
					{
						title: '',
						key: 'reportCopy',
						width: '2.61%',
						render: (_, record) => {
							return (
								<Button
									disabled={isDisabled}
									key="copy"
									type="text"
									style={{
										display: 'flex',
										alignItems: 'center',
										padding: 0,
										lineHeight: 0.8,
										paddingRight: 5,
									}}
									icon={<CopyOutlined style={{ fontSize: 20, color: 'black' }} />}
									onClick={async () => {
										await mainCtx.reportCopy(record)
									}}
									title="Создать копию"
								/>
							)
						},
					},
			  ]
			: []),

		{
			title: null,
			key: 'action',
			width: '3.24%',
			render: (_, rec) => {
				return (
					<EditDelBtns
						record={rec}
						canDelete={
							mainCtx.isDisabledByChangeStatusBtn
								? !isDisabled && mainCtx.isDisabledByChangeStatusBtn && rec.isCopy
								: !isDisabled && !rec.isFirst
						}
						canUpdate={false}
						onDelete={(rec) => {
							stateFunctions.delete(rec._uuid_)
						}}
						deps={[]}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					></EditDelBtns>
				)
			},
		},
	]
}
