export const rights = {
	'/vocabularies/raw-mat-shelf-life': {
		Справочники: {
			'Срок годности сырья': {
				view: true,
			},
		},
	},
	'/rp/customers': {
		'АРМ офиса': {
			Заказчики: {
				view: true,
			},
		},
	},
	'/rp/contracts-customers': {
		'АРМ офиса': {
			'Договоры. Заказчики': {
				view: true,
			},
		},
	},
	'/rm/providers': {
		'АРМ офиса': {
			Поставщики: {
				view: true,
			},
		},
	},
	'/rm/contracts-providers': {
		'АРМ офиса': {
			'Договоры. Поставщики': {
				view: true,
			},
		},
	},
	'/rm/supply-requests': {
		'АРМ офиса': {
			'Заявки на поставку сырья': {
				view: true,
			},
		},
	},
	'/rm/supplies': {
		'АРМ офиса': {
			'Поставки сырья': {
				view: true,
			},
		},
	},
	'/reg/specifications': {
		'АРМ офиса': {
			'Протоколы контроля': {
				view: true,
			},
		},
	},
	'/reg/staff': {
		'АРМ офиса': {
			Персонал: {
				view: true,
			},
		},
	},
	'/pv/product-catalog': {
		'АРМ офиса': {
			'Продуктовый каталог': {
				view: true,
			},
		},
	},
	'/pv/displaycodes-vocabulary': {
		'АРМ офиса': {
			'Справочник артикулов': {
				view: true,
			},
		},
	},
	'/system-settings': {
		'АРМ офиса': {
			'Настройки системы': {
				view: true,
			},
		},
	},
	'/rm/supply-input-controls': {
		'АРМ офиса': {
			'Поставки сырья': {
				'Входной контроль': { view: true },
			},
		},
	},
	'/rm/supply-unloads': {
		'АРМ офиса': {
			'Поставки сырья': {
				Разгрузка: { view: true },
			},
		},
	},
	'/production/tasks': {
		'АРМ офиса': {
			'Задачи на производство': {
				view: true,
			},
		},
	},
	'/production/giver-orders': {
		'АРМ офиса': {
			'Заказы давальца': {
				view: true,
			},
		},
	},
	'/reports/consolidated-reports': {
		'АРМ офиса': {
			'Сводные отчеты': {
				view: true,
			},
		},
	},
	'/stock/ready-prods': {
		'АРМ офиса': {
			'Карточка Запасы': { view: true },
		},
	},
	'/stock/ready-prod-resales': {
		'АРМ офиса': {
			'Карточка Запасы': { view: true },
		},
	},
	'/stock/wastes': {
		'АРМ офиса': {
			'Карточка Запасы': { view: true },
		},
	},
	'/stock/semifs': {
		'АРМ офиса': {
			'Карточка Запасы': { view: true },
		},
	},
	'/stock/raw-mats': {
		'АРМ офиса': {
			'Карточка Запасы': { view: true },
		},
	},
	'/stock/samples': {
		'АРМ офиса': {
			'Карточка Запасы': { view: true },
		},
	},
}
