import { Table } from 'antd'
import React, { useContext } from 'react'
import { ImplementationItemMainContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { AuthContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns } from './production-tab'
import SelectProdModal from './SelectProdModal'

const ProductionTab = () => {
	const mainCtx = useContext(ImplementationItemMainContext)
	const modCtx = useContext(ModContext)
	const authCtx = useContext(AuthContext)
	const stateData = mainCtx.state.productions
	const getProdName = () => {
		if (mainCtx.state.isFreeSamples) {
			const firstProd = stateData[0]
			if (firstProd?.idStockRawMatStor) {
				return 'rawMat'
			}
			if (firstProd?.idStockSemif) {
				return 'semif'
			}
			if (firstProd?.idStockWaste) {
				return 'waste'
			}
		}
		return 'readyProd'
	}
	return (
		<TabItem
			modal={() => <SelectProdModal />}
			addButton={{
				show: true,
				onClick: () => {
					mainCtx.modalFunctions.openAddProd()
					mainCtx.modalFunctions.setAddProd__name(getProdName())
				},
			}}
			table={() => (
				<Table
					size="small"
					rowClassName={modCtx.mod !== modes.view && 'clickable'}
					columns={getDisplayColumns(mainCtx, modCtx, authCtx)}
					dataSource={stateData}
					pagination={false}
					scroll={{ x: 800 }}
				/>
			)}
		/>
	)
}

export default ProductionTab
