const XLSX = require('xlsx-js-style')
const isNumber = (num) => typeof num === 'number' && !isNaN(num)
const borderStyle = {
	style: 'thin',
	color: { rgb: '444444' },
}
const headerFontStyle = { bold: true, color: { rgb: '444444' } }

const getHeaderStyle = (isLastCell) => ({
	font: headerFontStyle,
	fill: { patternType: 'solid', fgColor: { rgb: 'D6D6D6' } },
	alignment: { horizontal: 'center' },
	border: {
		top: borderStyle,
		bottom: borderStyle,
		left: borderStyle,
		...(isLastCell && {
			right: borderStyle,
		}),
	},
})

const getMaxWidth = (data, key) => {
	const min = 10
	const max = 40
	return data.reduce(
		(w, r) => (r[key] ? Math.max(w, r[key]?.length > max ? max : r[key].length) : min),
		min
	)
}

const prepareData = (dataSource, columns) => {
	return dataSource.map((el) => {
		const obj = {}
		for (const f of columns) {
			if (isNumber(el[f.dataIndex])) {
				el[f.dataIndex] = String(el[f.dataIndex])
				const dotIdx = el[f.dataIndex].lastIndexOf('.')
				if (dotIdx !== -1) {
					obj[f.dataIndex] =
						el[f.dataIndex].slice(0, dotIdx) +
						',' +
						el[f.dataIndex].slice(dotIdx + 1, el[f.dataIndex].length)
				} else {
					obj[f.dataIndex] = String(el[f.dataIndex]) || ''
				}
			} else {
				obj[f.dataIndex] = String(el[f.dataIndex]) || ''
			}
		}
		return obj
	})
}

const saveAsXlsx = ({ dataSource, columns, additionalHeaders = [], tableName, fileName }) => {
	const FMT = '0.00'
	const workbook = XLSX.utils.book_new()
	const data = prepareData(dataSource, columns)
	const worksheet = XLSX.utils.json_to_sheet([])
	worksheet['!pageSetup'] = { orientation: 'landscape' }
	if (additionalHeaders.length) {
		additionalHeaders.forEach((h, index) => {
			const colSpanOffset = index === 0 ? 0 : additionalHeaders[index - 1].colSpan
			const currentColSpanWithOffset =
				index === 0 ? h.colSpan : additionalHeaders[index - 1].colSpan + h.colSpan
			const origin = `${index === 0 ? 'A' : String.fromCharCode(colSpanOffset + 65)}1`
			XLSX.utils.sheet_add_json(worksheet, [{ title: h.title }], {
				header: ['title'],
				skipHeader: true,
				origin,
			})
			const initialIndex = colSpanOffset
			const lastIndex = currentColSpanWithOffset
			for (let i = initialIndex; i < lastIndex; i++) {
				const char = String.fromCharCode(i + 65)
				if (!worksheet[`${char}1`]) {
					worksheet[`${char}1`] = { t: 's', v: '' }
				}
				worksheet[`${char}1`].s = {
					font: headerFontStyle,
					fill: { patternType: 'solid', fgColor: { rgb: 'BCBCBC' } },
					alignment: {
						horizontal: 'center',
						...(i === lastIndex - 1 && {
							wrapText: true,
						}),
					},
					border: {
						top: borderStyle,
						...(i === initialIndex && {
							left: borderStyle,
						}),
						...(i === lastIndex - 1 && {
							right: borderStyle,
						}),
					},
				}
			}
			worksheet['!merges'] = [
				...(worksheet['!merges'] || []),
				{ s: { r: 0, c: colSpanOffset }, e: { r: 0, c: currentColSpanWithOffset - 1 } },
			]
		})
	}
	const originDigit = additionalHeaders.length ? 2 : 1
	XLSX.utils.sheet_add_aoa(worksheet, [columns.map((c) => c.title)], {
		origin: `A${originDigit}`,
		skipHeader: true,
	})
	XLSX.utils.sheet_add_json(worksheet, data, {
		origin: `A${originDigit + 1}`,
		skipHeader: true,
	})
	for (const i in worksheet) {
		if (typeof worksheet[i] != 'object') continue
		if (worksheet[i].s !== undefined) continue
		let cell = XLSX.utils.decode_cell(i)
		const isLastCell = cell.c === columns.length - 1
		if (cell.r === originDigit - 1) {
			worksheet[i].s = getHeaderStyle(isLastCell)
		} else {
			if (
				isNumber(+worksheet[i].v) ||
				(worksheet[i].v &&
					isNumber(
						Number(
							worksheet[i].v.slice(0, worksheet[i].v.lastIndexOf(',')) +
								worksheet[i].v.slice(worksheet[i].v.lastIndexOf(',') + 1, worksheet[i].v.length)
						)
					))
			) {
				worksheet[i].z = FMT
			}
			worksheet[i].s = {
				border: {
					bottom: borderStyle,
					left: borderStyle,
					...(isLastCell && {
						right: borderStyle,
					}),
				},
				...(isLastCell && {
					alignment: {
						wrapText: true,
					},
				}),
			}
		}
	}
	worksheet['!cols'] = Object.keys(data[0]).map((key) => ({
		wch: getMaxWidth(data, key),
	}))
	XLSX.utils.book_append_sheet(workbook, worksheet, tableName)
	XLSX.writeFile(workbook, fileName)
}

module.exports = saveAsXlsx
