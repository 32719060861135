import { Table } from 'antd'
import React, { useContext } from 'react'
import { TabItem } from '../../../../../components'
import { ModContext } from '../../../../../contexts'
import { modes, prepareData } from '../../../../../utils'
import { StockRawMatItemMainContext } from '../provider/main'
import { getDisplayColumns } from './lab-indicator-tab'
import { groupData } from '../../../../InputControl/InputControlItem/LabIndicatorTab/lab-indicator-tab'
import lodash from 'lodash'

const LabIndicatorTab = () => {
	const mainCtx = useContext(StockRawMatItemMainContext)
	const modCtx = useContext(ModContext)
	const { addPhoto, removePhoto } = mainCtx.stateFunctions.labIndics

	const dataSource = lodash.sortBy(
		prepareData(groupData(mainCtx.state.labIndics), 'ctrlLabIndicators', 'ctrlLabIndic'),
		['id']
	)

	return (
		<TabItem
			addButton={{ show: false }}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						loading={mainCtx.tabsLoading.labIndics}
						columns={getDisplayColumns(mainCtx, modCtx, addPhoto, removePhoto)}
						bordered
						dataSource={dataSource}
						pagination={false}
						scroll={{ x: 800 }}
						defaultExpandAllRows={true}
					/>
				)
			}}
		></TabItem>
	)
}

export default LabIndicatorTab
