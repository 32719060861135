import { calcSupplyInpCtrlFactWeight } from '@berry/common-functions/cross-project-functions'
/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 */

const getRowSpan = (inRecord, inLength) => {
	if (inRecord.indxMain === 0) {
		return inLength || 1
	} else {
		return 0
	}
}

export const getDisplayColumns = () => {
	return [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Дата поставки',
			dataIndex: 'date',
			key: 'date',
		},
		{
			dataIndex: ['product', 'displayVal'],
			title: 'Продукт',
			key: 'product',
		},
		{
			title: 'Масса, кг',
			dataIndex: ['product', 'weightInv'],
			key: 'weightInv',
			render: (_, record) => calcSupplyInpCtrlFactWeight(record.product),
		},
		{
			title: 'Статус',
			dataIndex: ['product', 'invoice', 'status'],
			key: 'status',
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.products?.length),
			}),
		},
	]
}
