import { CommonSelect, EditDelBtns } from '../../../../components'
import { modes, openNewTab } from '../../../../utils'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (mainCtx, modCtx, authCtx, editHandler) => {
	const stateFunctions = mainCtx.stateFunctions.products
	let isDisabled = false
	if (modCtx.mod === modes.view) isDisabled = true
	return [
		{
			title: 'Продукт',
			dataIndex: 'displayVal',
			key: 'displayVal',
			width: '94%',
			render: (displayVal, record) =>
				displayVal ||
				record.prodCat?.displayVal + ' ' + record.prodCatKindRawMat?.displayVal ||
				null,
		},
		{
			title: null,
			key: 'action',
			width: '6%',
			render: (text, record) => (
				<EditDelBtns
					disabled={record.canDelete}
					record={record}
					canDelete={!isDisabled}
					canUpdate={!isDisabled}
					onDelete={(record) => stateFunctions.delete(record._uuid_)}
					onEdit={editHandler}
					deps={[]}
					openNewPage={(url) =>
						openNewTab(url, {
							authCtx: authCtx.state,
						})
					}
				/>
			),
		},
	]
}

const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 },
}

/**
 * поля формы в модальном окне
 * @param {Object} mainCtx - главный контекст
 * @param {Object} modCtx - контекст мода
 * @param {{_uuid_: string}} params - отбьект с функциями изменения состояния вкладки
 */
export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []
	const isDisabled = modCtx.mod === modes.view
	const { setProdCat, setProdCatKindRawMat, get: getProduct } = mainCtx.stateFunctions.products
	const kindRawMatsIds = mainCtx.state.products?.map((e) => e.prodCatKindRawMat?.id) || []
	return [
		[
			{
				layout: layout,
				label: 'Продукт',
				name: 'prodCat',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.prodCat}
						setValue={(val) => setProdCat(params._uuid_, val)}
						isDisabled={isDisabled}
						showSearch={true}
						dataArr={mainCtx.additional.allSelectProdCats.filter(
							(prodCat) => !prodCat.kindRawMats.every((rm) => kindRawMatsIds.includes(rm.id))
						)}
					/>
				),
			},
			{
				layout: layout,
				label: 'Вид',
				name: 'prodCatKind',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.prodCatKindRawMat}
						setValue={(val) => setProdCatKindRawMat(params._uuid_, val)}
						isDisabled={isDisabled}
						showSearch={true}
						dataArr={mainCtx.additional.allSelectKindRawMats.filter(
							(rm) =>
								!mainCtx.state.products.some(
									(e) => e.prodCatKindRawMat && e.prodCatKindRawMat.id === rm.id
								) &&
								getProduct(params._uuid_).prodCat &&
								rm.prodCat.id === getProduct(params._uuid_).prodCat.id
						)}
					/>
				),
			},
		],
	]
}
