import { Checkbox, Input } from 'antd'
import { CommonSelect, EditDelBtns, InputError } from '../../../../components'
import { frontSorter, getColumnSearchProps, modes, openNewTab } from '../../../../utils'
import {
	getProdCatKindReadyProdLabel,
	getProdCatPackageLabel,
} from '@berry/common-functions/cross-project-functions'
import { getProdCatPkgDisplayCode } from '../../../../utils/helpers/cross-pages-funcs'
import { isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} mainCtx - основной контекст
 * @param {Object} modCtx
 * @param {function} editHandler
 */
export const getDisplayColumns = (mainCtx, modCtx, editHandler, authCtx) => {
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.packages
	const isDisabledByArchive = (record) => {
		const sameArrs = mainCtx.state.packages.filter(
			(e) =>
				e.vocQuality?.id === record.vocQuality?.id &&
				e.kindReadyProd?.id === record.kindReadyProd?.id &&
				e.vocContType?.id === record.vocContType?.id &&
				String(e.weight) === String(record.weight)
		)
		if (sameArrs.length > 1) {
			if (sameArrs.some((el) => !el.isArchive)) {
				return sameArrs
					.filter((el) => el.isArchive)
					.map((el) => el._uuid_)
					.includes(record._uuid_)
			}
			return sameArrs.at(-1)._uuid_ !== record._uuid_
		}
		return false
	}
	return [
		{
			title: 'Артикул',
			key: 'displayCode',
			width: '13%',
			render: (record) =>
				record.kindReadyProd
					? getProdCatPkgDisplayCode(record, mainCtx.state, mainCtx.additional.allProdCats)
					: null,
		},
		{
			title: 'Наименование',
			dataIndex: 'label',
			key: 'label',
			width: '26.5%',
			sorter: frontSorter('string', 'label'),
			render: (label, record) => label || getProdCatPackageLabel(record),
		},
		{
			title: 'Артикул 1С',
			dataIndex: ['vocProd1c', 'vendorCode'],
			key: 'displayCode1c',
			width: '8%',
		},
		{
			title: 'Наименование 1С',
			dataIndex: '',
			key: 'label1c',
			width: '48.5%',
			render: (record) => {
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`vocProd1c.${record._uuid_}`]}
						component={
							<CommonSelect
								value={record.vocProd1c}
								setValue={(data) => {
									stateFunctions.setVocProd1c(record._uuid_, data)
								}}
								isDisabled={
									isDisabled ||
									mainCtx.delEditDeps?.packages[
										mainCtx.state.packages.find((kind) => kind._uuid_ === record._uuid_)?.id
									]
								}
								showSearch
								dataArr={[
									{ id: null, description: '', isEmpty: true },
									...mainCtx.selectors.allVocProd1CsFiltered,
								]}
							/>
						}
					/>
				)
			},
		},
		{
			title: 'Доп. продукт',
			dataIndex: 'isAdditionalProduct',
			key: 'isAdditionalProduct',
			render: (value, record) => (
				<Checkbox
					checked={value}
					disabled={isDisabled}
					onChange={(e) => {
						stateFunctions.setIsAdditionalProduct(record._uuid_, e.target.checked)
					}}
				/>
			),
		},
		{
			title: 'Архив',
			dataIndex: 'isArchive',
			key: 'isArchiveForFilter',
			width: '3%',
			render: (_, record) => (
				<Checkbox
					disabled={isDisabled || isDisabledByArchive(record)}
					checked={record.isArchive}
					onChange={(e) => {
						stateFunctions.setIsArchive(record._uuid_, e.target.checked)
					}}
				/>
			),
			...getColumnSearchProps(
				[...new Set(mainCtx.state.packages?.map((e) => (e.isArchive ? 'Да' : 'Нет')))],
				undefined,
				'isArchive'
			),
			onFilter: (value, record) => (value === 'Да' ? record.isArchive : !record.isArchive),
		},
		{
			title: null,
			key: 'action',
			width: '4%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={false}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
						onEdit={editHandler}
						deps={mainCtx.delEditDeps?.packages?.[record.id]}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					/>
				)
			},
		},
	]
}

const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 },
}

const commonSelectFormFieldLayout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14, offset: 0 },
}
export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []

	const { _uuid_ } = params
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.packages
	const found = stateFunctions.get(_uuid_)
	return [
		[
			{
				layout: layout,
				label: 'Продукт',
				name: 'product',
				field: mainCtx.state.label,
			},

			{
				layout: commonSelectFormFieldLayout,
				label: 'Вид',
				name: 'kindReadyProd',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						plainValue={
							!found.kindReadyProd ? '' : getProdCatKindReadyProdLabel(found.kindReadyProd)
						}
						setValue={(data) => {
							stateFunctions.setKindReadyProd(_uuid_, data)
						}}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.selectors.packages.kindReadyProd}
					/>
				),
			},
			{
				layout: commonSelectFormFieldLayout,
				label: 'Качество',
				rules: [{ required: true }],
				name: 'vocQuality',
				field: (
					<CommonSelect
						value={found.vocQuality}
						setValue={(data) => {
							stateFunctions.setVocQuality(_uuid_, data)
						}}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allVocQualities}
					/>
				),
			},
			{
				layout: commonSelectFormFieldLayout,
				label: 'Тип упаковки',
				name: 'vocContType',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={found.vocContType}
						setValue={(data) => {
							stateFunctions.setVocContType(_uuid_, data)
						}}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allVocContTypes}
					/>
				),
			},
			{
				layout: layout,
				label: 'Вес нетто ед. упак., кг',
				name: 'weight',
				rules: [{ required: true }],
				field: (
					<Input
						style={{ width: '100%' }}
						value={found.weight}
						disabled={isDisabled}
						onChange={(e) => {
							if (
								!isValidNum(e.target.value) ||
								!isValidNumberDigits(e.target.value, 'float', [4, 1])
							) {
								return stateFunctions.setWeight(_uuid_, found.weight)
							}
							stateFunctions.setWeight(_uuid_, e.target.value)
						}}
					/>
				),
			},
		],
	]
}
