import { Table } from 'antd'
import React, { useState, useContext } from 'react'
import lodash from 'lodash'
import { CustomerItemMainContext } from '../provider/main'
import { RecordItemModal, TabItem, CustomForm } from '../../../../components'
import { AuthContext, ModContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns, getFormItems } from './platform-tab'
import { prepareForOpenedModal } from '../../../../utils/helpers/for-modal'

const PlatformTab = () => {
	const mainCtx = useContext(CustomerItemMainContext)

	const authCtx = useContext(AuthContext)
	const stateFunctions = mainCtx.stateFunctions.platforms
	let stateData = mainCtx.state.platforms
	const modCtx = useContext(ModContext)
	const [isModalOpen, setIsModalOpen] = useState(false)

	/**
	 *  {_uuid_:string,isNew:boolean } itemForModal
	 */
	const [itemForModal, setItemForModal] = useState(null)
	stateData = prepareForOpenedModal(stateData, itemForModal)
	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const newUuid = stateFunctions.create()
					setItemForModal({ _uuid_: newUuid, isNew: true })
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						width={'50%'}
						isOpen={isModalOpen}
						title="Площадки"
						formName="platform"
						item={itemForModal}
						deleteFun={stateFunctions.delete}
						setItem={setItemForModal}
						setModalState={setIsModalOpen}
						editFun={stateFunctions.edit}
						validate={() => {
							return mainCtx.validators.platforms(itemForModal._uuid_)
						}}
						form={() => {
							if (!itemForModal) return null
							return (
								<CustomForm
									name="platform"
									fields={[
										{ name: 'label', value: stateFunctions.get(itemForModal._uuid_).label },
										{ name: 'address', value: stateFunctions.get(itemForModal._uuid_).address },
									]}
									columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
									items={getFormItems(mainCtx, modCtx, itemForModal)}
								/>
							)
						}}
					/>
				)
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(mainCtx, modCtx, authCtx, (record) => {
							if (modCtx.mod === modes.view) return
							setItemForModal({ _uuid_: record._uuid_, prevData: lodash.cloneDeep(record) })
							setIsModalOpen(true)
						})}
						loading={mainCtx.tabsLoading.platforms}
						dataSource={stateData}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)
			}}
		></TabItem>
	)
}

export default PlatformTab
