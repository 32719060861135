import moment from 'moment'
/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = () => {
	return [
		{
			title: 'Дата и время',
			dataIndex: 'date',
			key: 'date',
			width: '15%',
			render: (date) => (date ? moment(date).format('DD.MM.YYYY HH:mm') : null),
		},
		{
			title: 'Пользователь',
			dataIndex: 'employee',
			key: 'employee',
			width: '15%',
		},
		{
			title: 'Событие',
			dataIndex: 'event',
			key: 'event',
			width: '15%',
		},
		{
			title: 'Причина',
			dataIndex: 'cause',
			key: 'cause',
			width: '15%',
		},
	]
}
