import React, { useContext } from 'react'
import { ModContext, ModProvider } from '../../../contexts'
import { Main } from '../../../layouts'
import { ConsolidatedReportItemMainContext, Provider } from './provider/main'
import { Card, Row, Col, Form, Typography } from 'antd'
import {
	ActionButtons,
	CustomForm,
	HeaderMenu,
	showConfirmModal,
	TabList,
	LoadingButton,
} from '../../../components'
import { actBtnOnClose, modes, actBtnOnSaveV2 } from '../../../utils'
import { useHistory } from 'react-router'
import { getBreadcrumbsText } from '../../../utils/helpers/generators'
import { getFormItems } from './consolidated-report-item'
import SupplyTab from './SupplyTab/SupplyTab'
import ProducedTab from './ProducedTab/ProducedTab'
import ReportTab from './ReportTab/ReportTab'
import {
	produceBalanceSendTo1cModal,
	produceSendTo1cModal,
} from './ProducedSendTo1cModal/produced-send-to1c-modal'
import ToBalanceTab from './ToBalanceTab/ToBalanceTab'
import EventHistoryTab from './EventHistoryTab/EventHistoryTab'

const pageUrl = '/reports/consolidated-reports'

const { Title } = Typography

const ConsolidatedReportItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Сводные отчеты по ДЗ">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(ConsolidatedReportItemMainContext)
	const history = useHistory()
	const [form] = Form.useForm()
	if (mainCtx.state.__notFound) {
		history.replace(pageUrl)
		return null
	}

	const sendProdTo1cBtnVisible =
		mainCtx.state.status === 'В работе' && mainCtx.addTo1cProduced.addTo1cProducedReadyProds.length
	const completeOrderBtnVisible =
		mainCtx.state.status === 'В работе' &&
		mainCtx.state.produceds.length &&
		mainCtx.state.produceds.every((prod) =>
			['Допущено', 'Хранение', 'К отгрузке', 'Сработано', 'Списано'].includes(prod.status)
		) &&
		mainCtx.state.supplies.every((supply) =>
			supply.product.stockRawMats.every((stock) =>
				stock.storages.every((stor) => ['Допущено', 'Сработано'].includes(stor.status))
			)
		)
	const sendBalTo1cBtnVisible =
		mainCtx.state.status === 'Выполнен' &&
		mainCtx.state.wasteManagement === 'Утилизация за счет производства' &&
		mainCtx.addTo1cProduced?.addTo1cProducedWastes?.length

	return (
		<>
			<HeaderMenu {...getBreadcrumbsText(pageUrl, mainCtx.state)}>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						rights={{
							'АРМ офиса': {
								'Сводные отчеты': {
									edit: true,
								},
							},
						}}
						formName="consolidated-report"
						onClose={() => {
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: () => {},
							})
						}}
						onSave={async () => {
							await actBtnOnSaveV2({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								pageUrl: pageUrl,
								history: history,
								isShowErrorModal: true,
								showConfirmModal,
							})
						}}
						canEdit={false}
						onCancel={() => {
							mainCtx.reset()
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						additionalButtonsPre={[
							...(sendProdTo1cBtnVisible
								? [
										<LoadingButton key="send-to-1c" onClick={() => produceSendTo1cModal(mainCtx)}>
											Отправить в 1С
										</LoadingButton>,
								  ]
								: []),
							...(completeOrderBtnVisible
								? [
										<LoadingButton
											key="complete-order"
											onClick={() => produceSendTo1cModal(mainCtx, true)}
										>
											Завершить заказ
										</LoadingButton>,
								  ]
								: []),
							...(sendBalTo1cBtnVisible
								? [
										<LoadingButton
											key="to-balance"
											onClick={() => produceBalanceSendTo1cModal(mainCtx)}
										>
											Перевести на баланс
										</LoadingButton>,
								  ]
								: []),
						]}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<Title level={5}>Основные данные</Title>
						<CustomForm
							form={form}
							name="main"
							items={getFormItems(mainCtx)}
							columns={[
								{ size: { xs: 24, lg: 7 }, num: 0 },
								{ size: { xs: 24, lg: 10 }, num: 1 },
								{ size: { xs: 24, lg: 7 }, num: 2 },
							]}
						/>
					</Col>
				</Row>
			</Card>
			{
				<TabList
					tabs={[
						{ key: 0, title: 'Поставлено', component: <SupplyTab /> },
						{ key: 1, title: 'Произведено', component: <ProducedTab /> },
						{ key: 2, title: 'Отчеты', component: <ReportTab /> },
						{ key: 3, title: 'На баланс', component: <ToBalanceTab /> },
						{ key: 4, title: 'История событий', component: <EventHistoryTab /> },
					]}
				/>
			}
		</>
	)
}
export default ConsolidatedReportItem
