/**
 * Ищет объекты в процессе согласования и возвращает список с ссылками на них в системе
 * @param {}
 */

import { isValidNum } from '@berry/common-functions/validators'
import { Form, Input, Modal, Select } from 'antd'
import { generateObjWithUrlAndText } from './for-deps'

export const getMatchingItems = (items, url, statusArr, statusField) => {
	const matchingItems = items.filter((i) =>
		statusArr.map((status) => status.toLowerCase()).includes(i[statusField].toLowerCase())
	)
	return generateObjWithUrlAndText(matchingItems, url, true)
}

/**
 * вычисляет максимальный уровень
 * @param {}
 */
export const calcMaxLvl = (allVocPositionTypes, key) => {
	return Math.max(...allVocPositionTypes.map((e) => e[key]).filter((e) => isValidNum(e)))
}

/**
 * обработчик нажатия на отмену согласования показывающий мод окно с комментом
 * @param {}
 */
export const handleCancelMatch = (serverMatch, form, onFinish, ctx, matchObject) => {
	if (['task', 'taskPf'].includes(matchObject)) {
		return Modal.confirm({
			title: 'Причина отклонения',
			width: '40%',
			bodyStyle: {
				padding: '16px 32px',
			},
			okButtonProps: { htmlType: 'submit', form: 'reject' },
			centered: true,
			icon: null,
			onCancel: () => form.resetFields(),
			onOk: async () => {
				const { errorFields = [] } = await form.validateFields()
				if (errorFields.length) {
					return true
				}
			},
			content: (
				<Form
					name="reject"
					form={form}
					onFinish={(values) => {
						const cause = ctx.additional.allCauseOfRejReps.find(
							(e) => e.id === values.idVocRejectReason
						)
						values.cause = values.cause
							? `${cause.causeOfReject} - ${values.cause}`
							: cause.causeOfReject
						onFinish(values)
						form.resetFields()
					}}
					labelAlign="left"
				>
					<Form.Item
						name="idVocRejectReason"
						label="Причина"
						rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}
						labelCol={{ xs: 24, sm: 8, xl: 4 }}
						wrapperCol={{ xs: 24, sm: 16, xl: 20 }}
					>
						<Select>
							{ctx.additional.allCauseOfRejReps
								.filter((it) => it.status === 'Действующая')
								.map((it) => (
									<Select.Option key={it.id} value={it.id}>
										{it.causeOfReject}
									</Select.Option>
								)) || []}
						</Select>
					</Form.Item>
					<Form.Item
						name="cause"
						label="Комментарий"
						labelCol={{ xs: 24, sm: 8, xl: 4 }}
						wrapperCol={{ xs: 24, sm: 16, xl: 20 }}
					>
						<Input.TextArea
							maxLength={1000}
							rows={10}
							style={{
								display: 'block',
							}}
						/>
					</Form.Item>
				</Form>
			),
		})
	}
	Modal.confirm({
		title: 'Комментарий',
		width: '40%',
		bodyStyle: {
			padding: '16px 32px',
		},
		okButtonProps: { htmlType: 'submit', form: 'comment' },
		centered: true,
		icon: null,
		onCancel: () => {
			form.resetFields()
		},
		onOk: async () => {
			const { errorFields = [] } = await form.validateFields()
			if (errorFields.length) {
				return true
			}
		},
		content: (
			<Form
				name="comment"
				form={form}
				colon={false}
				onFinish={(values) => {
					onFinish(values)
					form.resetFields()
				}}
			>
				<Form.Item
					name="cause"
					label=" "
					rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}
				>
					<Input.TextArea
						maxLength={1000}
						rows={10}
						style={{
							display: 'block',
						}}
					/>
				</Form.Item>
			</Form>
		),
	})
}
