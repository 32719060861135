import React, { useContext } from 'react'
import { ModContext, ModProvider } from '../../../../contexts'
import { Main } from '../../../../layouts'
import { WorkingTimeItemMainContext, Provider } from './provider/main'
import { Card, Row, Col, Form } from 'antd'
import { ActionButtons, CustomForm, HeaderMenu, TabList } from '../../../../components'
import { actBtnOnClose, actBtnOnSaveV2, modes, actBtnOnDelete } from '../../../../utils'
import { showConfirmModal } from '../../../../components'
import { getFormItems } from './working-time-item'
import { useHistory } from 'react-router'
import moment from 'moment'
import BreaksTab from './BreaksTab'

const pageUrl = '/vocabularies/working-time'

const WorkingTimeItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Смены">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(WorkingTimeItemMainContext)
	const history = useHistory()
	const [form] = Form.useForm()
	return (
		<>
			<HeaderMenu
				rights={{
					Справочники: {
						Смены: {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle={'Смены'}
				recordLabel={mainCtx.state.id ? mainCtx.state.label : 'Новая'}
			>
				<Row>
					<ActionButtons
						rights={{
							Справочники: {
								Смены: {
									edit: true,
								},
							},
						}}
						formName={'workingTime'}
						onClose={() => {
							actBtnOnClose({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						canDelete={modCtx.mod !== modes.new}
						onSave={async () => {
							await actBtnOnSaveV2({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								pageUrl: pageUrl,
								history: history,
								isShowErrorModal: true,
								showConfirmModal,
								form,
							})
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						onDelete={async () => {
							await actBtnOnDelete({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								deps: mainCtx.delEditDeps?.['voc_working_time'][mainCtx.state.id],
							})
						}}
						onCancel={() => {
							const allFields = form.getFieldsError()
							allFields.forEach((f) => {
								f.errors = []
							})
							form.setFields(allFields)
							mainCtx.reset()
						}}
					/>
				</Row>
			</HeaderMenu>

			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							form={form}
							name="workingTime"
							items={getFormItems(mainCtx, modCtx)}
							fields={[
								{ name: 'label', value: mainCtx.state.label },
								{
									name: 'startTime',
									value: mainCtx.state.startTime ? moment(mainCtx.state.startTime) : null,
								},
								{
									name: 'endTime',
									value: mainCtx.state.endTime ? moment(mainCtx.state.endTime) : null,
								},
							]}
						/>
					</Col>
				</Row>
			</Card>
			<TabList tabs={[{ key: 0, title: 'Перерывы', component: <BreaksTab /> }]} />
		</>
	)
}
export default WorkingTimeItem
