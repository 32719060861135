import { Button, Checkbox, Col, Select, Tooltip } from 'antd'
import { CommonSelect, EditDelBtns, InputError, showConfirmModal } from '../../../../components'
import { modes, openNewTab, getRowSpan, actBtnOnDelete, isNum } from '../../../../utils'
import { FileTextOutlined, CopyOutlined } from '@ant-design/icons'
import { setNumField } from '@berry/front-common/for-fields'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { dataUrl as pageUrl } from '../provider/main'
import { checkRole } from '@berry/common-functions/role'

const layoutLeft = {
	labelCol: { xs: { span: 12 }, sm: { span: 10 }, lg: { span: 13 } },
	wrapperCol: { xs: { span: 12 }, sm: { span: 14 }, lg: { span: 10 } },
}

export const getDisplayColumns = (mainCtx, modCtx, authCtx, userDataCtx) => {
	const stateFunctions = mainCtx.stateFunctions.products
	const isDisabledByMod = modCtx.mod === modes.view
	const canViewCtrl = checkRole(
		{
			'АРМ офиса': {
				'Поставки сырья': {
					'Входной контроль': {
						view: true,
					},
				},
			},
		},
		userDataCtx.state.rights
	)
	const canViewUnld = checkRole(
		{
			'АРМ офиса': {
				'Поставки сырья': {
					Разгрузка: {
						view: true,
					},
				},
			},
		},
		userDataCtx.state.rights
	)
	return [
		{
			title: 'Поставщик',
			dataIndex: 'provider',
			key: 'provider',
			width: '7.45%',
			render: (_, record) => {
				return record?.contract?.provider?.label
			},
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.totalProductsLength),
			}),
		},
		{
			title: '№ договора',
			dataIndex: 'contractNum',
			key: 'contractNum',
			width: '5.82%',
			render: (_, record) => {
				return record?.contract?.num
			},
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.totalProductsLength),
			}),
		},
		{
			title: '№ ДС',
			dataIndex: 'app',
			key: 'app',
			width: '3.63%',
			render: (_, record) => (record.contrQuota || record.order).application?.displayCode,
		},
		...(mainCtx.state.products?.some((p) => p.order?.contract?.tolling)
			? [
					{
						title: 'ID ДЗ',
						dataIndex: 'displayCode',
						key: 'displayCode',
						width: '3.63%',
						render: (_, record) => record.order?.id,
					},
			  ]
			: []),
		{
			title: '№ накладной',
			key: 'invoice',
			width: '5.40%',
			render: (record) => {
				const invoices = mainCtx.state.invoices
					.filter(
						(inv) =>
							!inv.products ||
							inv.products.every(
								(p) =>
									(p.contrQuota || p.order).contract.id ===
									(record.contrQuota || record.order).contract.id
							)
					)
					.filter(
						(inv) =>
							!record.invoice ||
							inv.id !== record.invoice.id ||
							inv._uuid_ !== record.invoice._uuid_
					)
					.filter((inv) => {
						return (
							inv.displayCode &&
							record.isReadyProdResale === inv.isReadyProdResale &&
							['Новая', 'Отклонено 1С', 'Возврат'].includes(inv.status)
						)
					})
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`invoice.${record._uuid_}`]}
						component={
							<Select
								value={record?.invoice?.displayCode || null}
								style={{ width: '100%', minWidth: '80px' }}
								onChange={(id) => stateFunctions.setInvoice(record._uuid_, id, record)}
								disabled={
									isDisabledByMod ||
									(record.invoice && !['Новая', 'Отклонено 1С'].includes(record.invoice.status))
								}
								notFoundContent={<Col>Не найдено</Col>}
							>
								{invoices.map((it) => (
									<Select.Option key={it.id} value={it.id}>
										{it.displayCode}
									</Select.Option>
								))}
							</Select>
						}
					/>
				)
			},
		},
		{
			title: 'ГПП',
			dataIndex: 'isReadyProdResale',
			key: 'isReadyProdResale',
			width: '5%',
			render: (_, record) => (
				<Checkbox
					disabled={isDisabledByMod || record.contract?.tolling}
					checked={record.isReadyProdResale}
					onChange={(e) => {
						if (
							[
								'Исследование завершено',
								'Приемка разрешена',
								'Приемка на согласовании',
								'Возврат',
								'Ответственное хранение',
							].includes(record.ctrlStatus) &&
							e.target.checked === false
						) {
							return showConfirmModal({
								title:
									'При снятии флага ГПП необходимо дополнить информацией карточку Входного контроля',
								okText: 'Ок',
								width: 450,
								onOk: () => {
									mainCtx.stateFunctions.products.setIsReadyProdResale(
										e.target.checked,
										record._uuid_,
										record
									)
									mainCtx.stateFunctions.products.setCtrlStatus(record._uuid_, 'Черновик')
								},
							})
						} else {
							mainCtx.stateFunctions.products.setIsReadyProdResale(
								e.target.checked,
								record._uuid_,
								record
							)
						}
					}}
				/>
			),
		},
		{
			title: 'Продукт',
			dataIndex: 'displayVal',
			key: 'product',
			width: mainCtx.state.products?.some((p) => p.order?.contract?.tolling) ? '7.92%' : '10%',
			onCell: (record) => {
				if (!record.invoice) {
					return {
						rowSpan: record.indxProd === 0 ? record.productsLength : 0,
					}
				}
				return {
					rowSpan: record.indxProd === 0 ? record.invoicesLength : 0,
				}
			},
		},
		{
			title: 'Масса, кг',
			key: 'weight',
			children: [
				{
					title: 'План',
					dataIndex: ['reqProd', 'weight'],
					key: 'weightPlan',
					width: '5.09%',
					render: (weight) => weight || 0,
				},
				{
					title: 'Наклад.',
					key: 'weightInv',
					width: '6.09%',
					render: (_, record) => {
						let isDisabled = isDisabledByMod
						if (record.unldStatus === 'Разгрузка завершена') isDisabled = true
						return (
							<Tooltip placement="top" title={record?.weightInv}>
								<div>
									<InputError
										hasError={mainCtx.formErrors?.[`weightInv.${record._uuid_}`]}
										disabled={
											isDisabled ||
											(record.invoice &&
												!['Новая', 'Отклонено 1С'].includes(record.invoice?.status))
										}
										value={record?.weightInv}
										onKeyDown={(e) => {
											if (e.key !== '.') return isNum(e)
										}}
										onChange={(e) => {
											return setNumField(
												e.target.value,
												record.weightInv,
												stateFunctions.setWeightInv,
												'float',
												[9, 2],
												{ _uuid_: record._uuid_ }
											)
										}}
										style={{
											width: '100%',
											textOverflow: 'ellipsis',
											padding: '5px 0px 5px 2px',
											fontSize: '14px',
										}}
									/>
								</div>
							</Tooltip>
						)
					},
				},
				{
					title: 'Факт',
					dataIndex: 'weightFact',
					key: 'weightFact',
					width: '5.09%',
				},
			],
		},
		{
			title: 'Тип упаковки',
			key: 'type',
			children: [
				{
					title: 'План',
					dataIndex: ['reqProd', 'vocContType', 'labelShort'],
					key: 'typePlan',
					width: '4.09%',
				},
				{
					layout: layoutLeft,
					title: 'Факт',
					key: 'typeInv',
					width: '4.09%',
					render: (_, record) => {
						let isDisabled = isDisabledByMod
						if (record.unldStatus === 'Разгрузка завершена') isDisabled = true
						return (
							<InputError
								style={{ width: '100%', minWidth: '80px' }}
								hasError={mainCtx.formErrors?.[`vocContTypeFact.${record._uuid_}`]}
								component={
									<CommonSelect
										value={record.vocContTypeFact}
										setValue={(val) => stateFunctions.setVocContTypeFact(record._uuid_, val)}
										isDisabled={isDisabled}
										showSearch
										dataArr={mainCtx.additional.allSelectContTypes}
									/>
								}
							/>
						)
					},
				},
			],
		},

		{
			title: 'Масса нетто ед. упак., кг	',
			key: 'containerWeight',
			children: [
				{
					title: 'План',
					key: 'containerWeightPlan',
					width: '5.31%',
					render: (_, record) => {
						return numToFixed(record.reqProd?.containerWeight, 2)
					},
				},
				{
					title: 'Факт',
					key: 'containerWeightInv',
					width: '5.31%',
					render: (_, record) => {
						const foundSupplyProd = mainCtx.additional.supplyProds.find(
							(item) => item.id === record.id
						)
						return foundSupplyProd?.containerWeightInv || null
					},
				},
			],
		},

		{
			title: '№ партии',
			key: 'partyNum',
			children: [
				{
					title: 'Изготовителя',
					key: 'partyNumManuf',
					width: '6.85%',
					render: (_, record) => {
						return (
							<InputError
								hasError={mainCtx.formErrors?.[`partyNumManuf.${record._uuid_}`]}
								disabled={
									isDisabledByMod ||
									(record.invoice && !['Новая', 'Отклонено 1С'].includes(record.invoice?.status))
								}
								value={record?.partyNumManuf}
								onChange={(e) => {
									stateFunctions.setPartyNumManuf(record._uuid_, e.target.value)
								}}
							/>
						)
					},
				},
				{
					title: 'Присвоенный',
					dataIndex: 'assignedPartyNum',
					key: 'partyNumInv',
					width: '6.85%',
					render: (assignedPartyNum, record) => {
						let isDisabled = isDisabledByMod
						if (record.unldStatus === 'Разгрузка завершена') isDisabled = true
						return (
							<InputError
								hasError={mainCtx.formErrors?.[`assignedPartyNum.${record._uuid_}`]}
								style={{ minWidth: '100px' }}
								value={assignedPartyNum}
								disabled={
									isDisabled ||
									(record.invoice && !['Новая', 'Отклонено 1С'].includes(record.invoice?.status))
								}
								onChange={(e) => {
									stateFunctions.setAssignedPartyNum(record._uuid_, e.target.value, record)
								}}
							/>
						)
					},
				},
			],
		},
		{
			title: 'Статус',
			key: 'status',
			children: [
				{
					title: 'Вх. контроля',
					key: 'statusInputCtrl',
					width: '6.85%',
					render: (_, record) => (
						<Button
							type="text"
							icon={<FileTextOutlined className={'pictogram'} style={{ fontSize: 20 }} />}
							onClick={() => {
								if (!record.id || !isDisabledByMod) return
								if (!canViewCtrl) {
									return showConfirmModal({
										title:
											'Ограничены права доступа к данному объекту. Обратитесь к администратору системы',
										okText: 'Ок',
										width: 450,
										showCancel: false,
									})
								}
								return openNewTab(`/rm/supply-input-controls/${record?.id}`, {
									authCtx: authCtx.state,
								})
							}}
						>
							{record.ctrlStatus}
						</Button>
					),
				},
				{
					title: 'Разгрузки',
					key: 'statusUnload',
					width: '6.85%',
					render: (_, record) => (
						<Button
							type="text"
							icon={<FileTextOutlined className={'pictogram'} style={{ fontSize: 20 }} />}
							style={{
								display: 'flex',
								alignItems: 'center',
								padding: 0,
								paddingRight: 5,
								whiteSpace: 'break-spaces',
								pointerEvents: isDisabledByMod ? 'auto' : 'none',
							}}
							onClick={() => {
								if (!record?.id || !isDisabledByMod) return
								if (!canViewUnld) {
									return showConfirmModal({
										title:
											'Ограничены права доступа к данному объекту. Обратитесь к администратору системы',
										okText: 'Ок',
										width: 450,
										showCancel: false,
									})
								}
								return openNewTab(`/rm/supply-unloads/${record?.id}`, {
									authCtx: authCtx.state,
								})
							}}
						>
							{record?.unldStatus}
						</Button>
					),
				},
			],
		},
		{
			title: '',
			key: 'productCopy',
			width: '2.61%',
			render: (_, record) => {
				if (modCtx.mod === modes.view) return null
				return (
					<Button
						disabled={
							isDisabledByMod ||
							(record.invoice && !['Новая', 'Отклонено 1С'].includes(record.invoice?.status))
						}
						key="copy"
						type="text"
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: 0,
							lineHeight: 0.8,
							paddingRight: 5,
						}}
						icon={<CopyOutlined style={{ fontSize: 20, color: 'black' }} />}
						onClick={async () => {
							await mainCtx.productCopy(record)
						}}
						title="Создать копию"
					/>
				)
			},
		},
		{
			title: '',
			key: 'action',
			width: '3%',
			render: (text, record) =>
				record.unldStatus === 'Новая' ? (
					<EditDelBtns
						style={{ display: 'none', justifyContent: 'right', color: 'red' }}
						record={record}
						canDelete={!isDisabledByMod}
						onDelete={async (record) => {
							await actBtnOnDelete({
								pageUrl,
								showConfirmModal,
								deleteFun: () => mainCtx.stateFunctions.products.delete(record?._uuid_, record?.id),
								title:
									'Все данные карточек Входной контроль и Разгрузка будут потеряны, будут аннулированы все согласования',
							})
						}}
					/>
				) : null,
		},
	]
}
