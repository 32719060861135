/** ширина тексты кнопок для мод окна предупреждения удаления */
const showHasDepsModal = {
	width: '33%',
	okText: 'Ок',
	cancelText: 'Отмена',
	isCancelBtnPrimary: false,
}

const showUpdateModal = {
	width: '34.7%',
	okText: 'Ок',
	showCancel: false,
	centered: true,
	title: 'Внесены изменения другим пользователем, данные обновлены',
}

const showIsBLockedModalForDeps = {
	title: 'Сохранение недоступно, вносятся изменения другим пользователем',
	onOk: () => {},
	showCancel: false,
	okText: 'Ок',
}

const showSendNotifyModal = {
	width: '34.7%',
	okText: 'Ок',
	cancelText: 'Отмена',
	isCancelBtnPrimary: false,
	showCancel: true,
}

const showCancelMatchModal = {
	title: (
		<>
			<span>Процесс согласования будет прерван.</span>
			<br />
			<span>Вы действительно хотите прервать процесс?</span>
		</>
	),
	width: '25%',
	okText: 'Ок',
	isCancelBtnPrimary: true,
}

const showSelectModalForList = {
	width: '100%',
	centered: true,
	destroyOnClose: true,
	maskClosable: false,
	closable: false,
	cancelButtonProps: { style: { display: 'none' } },
	okButtonProps: { style: { display: 'none' } },
	footer: null,
}

const showSaveAsDraft = {
	width: '34.7%',
	okText: 'Ок',
	cancelText: 'Отмена',
	isCancelBtnPrimary: false,
	showCancel: true,
	title: (
		<>
			<span>Не заполнены обязательные поля.</span>
			<br></br>
			<span>При сохранении карточка будет сохранена со статусом "Черновик".</span>
			<br></br>
			<span>При отмене продолжится редактирование карточки.</span>
		</>
	),
}
export {
	showHasDepsModal,
	showSendNotifyModal,
	showSaveAsDraft,
	showCancelMatchModal,
	showUpdateModal,
	showSelectModalForList,
	showIsBLockedModalForDeps,
}
