import { Route } from 'react-router'
import { ListPageWrapper } from '../../components'
import MainSemiKindItem from './MainSemiKind/MainSemiKindItem/MainSemiKindItem.jsx'
import MainSemiKindList from './MainSemiKind/MainSemiKindList/MainSemiKindList.jsx'
import SpecParamItem from './SpecParam/SpecParamItem/SpecParamItem'
import SpecParamList from './SpecParam/SpecParamList/SpecParamList'
import PositionTypeItem from './PositionType/PositionTypeItem/PosTypeItem'
import PositionTypeList from './PositionType/PositionTypeList/PosTypeList'
import WorkingTimeItem from './WorkingTime/WorkingTimeItem/WorkingTimeItem'
import WorkingTimeList from './WorkingTime/WorkingTimeList/WorkingTimeList'

const specialVocs = [
	<Route
		path="/vocabularies/specification-parameter"
		render={(props) => (
			<ListPageWrapper
				{...props}
				item={<SpecParamItem {...props} />}
				list={<SpecParamList {...props} />}
				path="/vocabularies/specification-parameter"
			/>
		)}
	/>,
	<Route
		path="/vocabularies/main-semifinished-kind"
		render={(props) => (
			<ListPageWrapper
				{...props}
				item={<MainSemiKindItem {...props} />}
				list={<MainSemiKindList {...props} />}
				path="/vocabularies/main-semifinished-kind"
			/>
		)}
	/>,
	<Route
		path="/vocabularies/position-type"
		render={(props) => (
			<ListPageWrapper
				{...props}
				item={<PositionTypeItem {...props} />}
				list={<PositionTypeList {...props} />}
				path="/vocabularies/position-type"
			/>
		)}
	/>,
	<Route
		path="/vocabularies/working-time"
		render={(props) => (
			<ListPageWrapper
				{...props}
				item={<WorkingTimeItem {...props} />}
				list={<WorkingTimeList {...props} />}
				path="/vocabularies/working-time"
			/>
		)}
	/>,
]

export default specialVocs
