import { getColumnSearchProps, sorter } from '../../../../utils'
import moment from 'moment'
import { dateFormat } from '../../../../utils/constants/common'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (inArr = []) => {
	return [
		{
			title: 'Смена',
			dataIndex: 'workingTime',
			key: 'workingTime',
			width: '8.5%',
			...getColumnSearchProps(inArr.map((e) => e.workingTime)),
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			width: '8.5%',
			sorter: sorter('number', 'id'),
			...getColumnSearchProps(inArr.map((e) => e.id)),
		},
		{
			title: 'Дата производства',
			dataIndex: 'date',
			key: 'date',
			width: '8.5%',
			sorter: sorter('date', 'date'),
			render: (date) => (date ? moment(date).format(dateFormat) : null),
			...getColumnSearchProps(
				inArr.map((e) => e.date),
				undefined,
				null,
				true
			),
		},
		{
			title: 'ID ДЗ',
			dataIndex: 'idContrOrder',
			key: 'idContrOrder',
			width: '8.5%',
			sorter: sorter('number', 'idContrOrder'),
			...getColumnSearchProps(inArr.map((e) => e.idContrOrder).filter((e) => e)),
		},
		{
			title: 'Вид работ',
			dataIndex: 'taskKind',
			key: 'taskKind',
			width: '8.5%',
			...getColumnSearchProps(inArr.map((e) => e.taskKind)),
		},
		{
			title: 'Операция',
			dataIndex: 'operation',
			key: 'operation',
			width: '8.5%',
			...getColumnSearchProps(inArr.map((e) => e.operation)),
		},
		{
			title: 'Партия №',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '8.5%',
			sorter: sorter('string', 'partyNum'),
			...getColumnSearchProps(inArr.map((e) => e.partyNum)),
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			key: 'articul',
			width: '8.5%',
			...getColumnSearchProps(inArr.map((e) => e.articul)),
		},
		{
			title: 'Артикул 1С',
			dataIndex: 'articul1C',
			key: 'articul1C',
			width: '8.5%',

			...getColumnSearchProps(inArr.map((e) => e.articul1C)),
		},
		{
			title: 'Продукт',
			dataIndex: 'productLabel',
			key: 'productLabel',
			width: '8.5%',
		},
		{
			title: 'Вид',
			dataIndex: 'prodCatKind',
			key: 'prodCatKind',
			width: '8.5%',
			sorter: sorter('string', 'prodCatKind'),
			...getColumnSearchProps(inArr.map((e) => e.prodCatKind)),
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '8.5%',
			sorter: sorter('number', 'weight'),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '8.5%',
			...getColumnSearchProps(inArr.map((e) => e.status)),
		},
	]
}
