import { Col, DatePicker, Input, Select, Typography } from 'antd'
import { isNum, modes } from '../../../utils'
import moment from 'moment'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { dateFormat } from '../../../utils/constants/common'
import { Status } from '../../../components'
const { Title } = Typography

const layout1 = {
	labelCol: { xs: 24, sm: 8, xl: 7, xxl: 6 },
	wrapperCol: { xs: 24, sm: 14, xl: 10 },
}

const layout2 = {
	labelCol: { xs: 24, sm: 8, xl: 7 },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}

const layout3 = {
	labelCol: { xs: 24, sm: 8, xl: 7 },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}

/**
 * Получить значение поля площадка
 * @param {Object} inContract
 */
export const getPlatform = (products) => {
	return products
		.reduce((all, cur) => {
			if (!cur.contrQuota && !cur.order) {
				return all
			}
			if (cur.contract.frame) {
				return [
					...all,
					cur.contrQuota?.application.platform?.label || cur.order?.application?.platform?.label,
				]
			}
			return [...all, cur.contract?.platform?.label]
		}, [])
		.filter((el, index, self) => self.indexOf(el) === index)
		.join(', ')
}
export const getFormItems = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	const isNew = modCtx.mod === modes.new
	const getPlatformText = () => {
		return getPlatform(mainCtx.state.products)
	}
	const platformText = getPlatformText()
	return [
		[
			{
				key: 'req-title',
				field: <Title level={5}>Заявка</Title>,
			},
			{
				key: 'status',
				layout: layout1,
				label: 'Статус',
				field: <Status status={mainCtx.state.status} />,
			},
			...(isNew
				? []
				: [
						{
							key: 'display-code',
							layout: layout1,
							label: 'Номер заявки',
							field: mainCtx.state.displayCode,
						},
				  ]),
			{
				key: 'date',
				layout: layout1,
				label: 'Дата заявки',
				field: mainCtx.state.date ? moment(mainCtx.state.date).format(dateFormat) : null,
			},
		],
		[
			{ key: 'mid-column' },
			{
				key: 'supplyDatePlan',
				layout: layout2,
				label: 'Дата поставки план.',
				name: 'supplyDatePlan',
				rules: [{ required: true }],
				field: (
					<DatePicker
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format="DD.MM.YYYY"
						allowClear={false}
						suffixIcon={null}
						onChange={(e) => {
							const date = moment(e).format('YYYY-MM-DD')
							mainCtx.stateFunctions.setSupplyDatePlan(date)
						}}
						style={{ width: '100%' }}
						disabled={isDisabled}
					/>
				),
			},
			[
				{
					key: 'double',
					label: '№ машины/прицепа',
					colSpan: 7,
					required: true,
				},
				{
					key: 'vehicleNum',
					layout: {
						wrapperCol: {
							md: { span: 21, offset: 3 },
							xl: { span: 22, offset: 0 },
						},
					},
					colSpan: 7,
					name: 'vehicleNum',
					rules: [{ required: true }],
					field: (
						<Input
							value={mainCtx.state.vehicleNum}
							disabled={isDisabled}
							onChange={(e) => {
								mainCtx.stateFunctions.setVehicleNum(e.target.value)
							}}
						/>
					),
				},
				{
					key: 'trailerNum',
					layout: {
						wrapperCol: {
							md: { offset: 3, span: 18 },
							xl: { offset: 1, span: 23 },
						},
					},
					colSpan: 7,
					name: 'trailerNum',
					rules: [{ required: true }],
					field: (
						<Input
							value={mainCtx.state.trailerNum}
							disabled={isDisabled}
							onChange={(e) => {
								mainCtx.stateFunctions.setTrailerNum(e.target.value)
							}}
						/>
					),
				},
			],
			{
				key: 'driver-phone',
				layout: layout2,
				label: 'Телефон водителя',
				field: (
					<Input
						value={
							mainCtx.state.driverPhone?.includes(' / ') ||
							mainCtx.state.driverPhone?.split('/')[1] === ''
								? mainCtx.state.driverPhone
								: mainCtx.state.driverPhone?.split('/').join(' / ')
						}
						disabled={isDisabled}
						onKeyDown={(e) => {
							const isCanPaste = (e) => {
								//Рарзрешать использовать ctrl+v, ctrl+c...
								if (e.ctrlKey === true || e.metaKey === true) return true
							}
							if (e.key === '/' && mainCtx.state.driverPhone?.includes('/')) e.preventDefault()
							if (e.key !== '/' && !isCanPaste(e)) return isNum(e)
						}}
						addonBefore="+"
						onChange={(e) => {
							mainCtx.stateFunctions.setDriverPhone(e.target.value)
						}}
						placeholder="X XXX XXX XX XX / X XXX XXX XX XX"
					/>
				),
			},
			{
				key: 'outdoorWh',
				layout: layout2,
				label: 'Склад приемки',
				name: 'outdoorWh',
				rules: [{ required: true }],
				field: (
					<Select
						disabled={isDisabled}
						value={mainCtx.state.outdoorWh?.label}
						onChange={(el) => {
							mainCtx.stateFunctions.setOutdoorWh(el)
						}}
						notFoundContent={<Col>Не найдено</Col>}
					>
						{mainCtx.additional.allOutdoorWhs.map((it) => {
							return (
								<Select.Option key={it.id} value={it.id}>
									{it.label}
								</Select.Option>
							)
						}) || []}
					</Select>
				),
			},
		],
		[
			{ key: 'last-column' },
			{
				key: 'platform',
				layout: layout3,
				label: 'Площадка',
				field: (
					<Typography.Text title={platformText} ellipsis>
						{platformText}
					</Typography.Text>
				),
			},
			{
				key: 'comment',
				name: 'comment',
				layout: layout3,
				label: 'Комментарий',
				field: (
					<Input
						maxLength={500}
						value={mainCtx.state?.comment}
						onChange={(e) => mainCtx.stateFunctions.setComment(e.target.value)}
						disabled={isDisabled}
					/>
				),
			},
			{
				key: 'totalWeight',
				layout: layout3,
				label: 'Общая масса',
				field: numToFixed(
					mainCtx.state?.products?.reduce((acc, cur) => acc + (+cur.weight || 0), 0),
					2
				),
			},
		],
	]
}
