import { Table, Button, Modal, Select, Col, Row } from 'antd'
import React, { useContext } from 'react'
import { InputControlContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { AuthContext, ModContext } from '../../../../contexts'
import { modes, prepareData } from '../../../../utils'
import { getDisplayColumns, groupData } from './lab-indicator-tab'
import lodash from 'lodash'

const LabIndicatorTab = () => {
	const mainCtx = useContext(InputControlContext)
	const authCtx = useContext(AuthContext)
	const modCtx = useContext(ModContext)
	const { addPhoto, removePhoto } = mainCtx.stateFunctions.ctrlLabIndicators
	const isDisabled = modCtx.mod === modes.view
	if (mainCtx.tabsLoading.ctrlLabIndicators) {
		return null
	}

	const dataSource = lodash.sortBy(
		prepareData(groupData(mainCtx.state.ctrlLabIndicators), 'ctrlLabIndicators', 'ctrlLabIndic'),
		['id']
	)
	const showCopyButton =
		!isDisabled &&
		!!mainCtx.state.ctrlSpec &&
		mainCtx.state.supply.products.some((p) => {
			return p.ctrlSpec?.id === mainCtx.state.ctrlSpec.id && p.id !== mainCtx.state.id
		})
	return (
		<TabItem
			afterButtonComponent={
				showCopyButton ? (
					<Button type="primary" onClick={() => mainCtx.modalFunctions.openCopyModal()}>
						Копировать из
					</Button>
				) : null
			}
			addButton={{ show: false }}
			table={() => {
				return (
					<>
						<Modal
							onOk={() => {
								mainCtx.stateFunctions.setCtrlSpec(mainCtx.copyModal.prod.ctrlSpec.id, true)
								mainCtx.modalFunctions.closeCopyModal()
								mainCtx.downloadPhotos()
							}}
							onCancel={() => {
								mainCtx.modalFunctions.closeCopyModal()
							}}
							maskClosable={false}
							cancelButtonProps={{ style: { display: 'none' } }}
							title={'Копирование данных контроля'}
							showCancel={false}
							width={'20%'}
							centered={true}
							okText={'ОК'}
							visible={mainCtx.copyModal.__isOpen}
							destroyOnClose
						>
							<Row style={{ alignItems: 'center' }}>
								<Row>Копировать из:</Row>
								<Select
									value={mainCtx.copyModal?.prod?.assignedPartyNum}
									style={{ width: '20%', minWidth: '120px', marginLeft: '20px' }}
									onChange={(id) => {
										const { ctrlLabIndicators = [], ...product } =
											mainCtx.state.supply.products.find((p) => p.id === id)
										mainCtx.modalFunctions.setCopyModalProd(product)
										mainCtx.modalFunctions.setCopyModalCtrlLabIndicators(ctrlLabIndicators)
									}}
									notFoundContent={<Col>Не найдено</Col>}
									options={mainCtx.state.supply.products
										.filter(
											(p) =>
												p.ctrlSpec?.id === mainCtx.state.ctrlSpec?.id && p.id !== mainCtx.state?.id
										)
										.map((it) => ({
											value: it.id,
											label: it.assignedPartyNum,
										}))}
								/>
							</Row>
						</Modal>
						<Table
							rowKey={(rec) => rec.ctrlLabIndic?._uuid_}
							size="small"
							scroll={{ x: 800 }}
							rowClassName={modCtx.mod !== modes.view && 'clickable'}
							columns={getDisplayColumns(mainCtx, modCtx, authCtx, addPhoto, removePhoto)}
							dataSource={dataSource}
							pagination={false}
							bordered
						/>
					</>
				)
			}}
		></TabItem>
	)
}

export default LabIndicatorTab
