export const columns = [
	{
		title: 'Вид дополнительных ПФ',
		dataIndex: 'label',
		key: 'label',
		width: '40%',
	},
	{
		title: 'Артикул',
		key: 'displayCode',
		width: '40%',
		dataIndex: 'displayCode',
	},
]
