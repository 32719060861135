import React, { useContext } from 'react'
import lodash from 'lodash'
import { CustomForm, RecordItemModal } from '../../../../../components'
import { ModContext } from '../../../../../contexts'
import { getFormItems } from './utils'
import { SpecificationItemContext } from '../../provider/main'

const ParameterEditModal = ({ isVisible, setIsVisible, modalItem, setModalItem }) => {
	const mainCtx = useContext(SpecificationItemContext)
	const modCtx = useContext(ModContext)
	const stateFunctions = mainCtx.stateFunctions.parameters
	if (modalItem === null) {
		return null
	}
	return (
		<RecordItemModal
			isOpen={isVisible}
			width="60%"
			title="Редактирование параметра"
			formName="param"
			validate={() => mainCtx.validators.parameters(modalItem._uuid_)}
			setModalState={setIsVisible}
			editFun={stateFunctions.edit}
			deleteFun={stateFunctions.delete}
			setItem={setModalItem}
			form={() => (
				<CustomForm
					name="param"
					fields={[
						{
							name: 'etalonVal1',
							value: stateFunctions.get(modalItem._uuid_).etalonVal1,
						},
						{
							name: 'etalonSymbol',
							value: stateFunctions.get(modalItem._uuid_).etalonSymbol,
						},
						{
							name: 'etalonVal2',
							value: stateFunctions.get(modalItem._uuid_).etalonVal2,
						},
						{
							name: 'etalonText',
							value: stateFunctions.get(modalItem._uuid_).etalonText,
						},
						{
							name: 'deviationVal1',
							value: stateFunctions.get(modalItem._uuid_).deviationVal1,
						},
						{
							name: 'deviationSymbol',
							value: stateFunctions.get(modalItem._uuid_).deviationSymbol,
						},
						{
							name: 'deviationVal2',
							value: stateFunctions.get(modalItem._uuid_).deviationVal2,
						},
						{
							name: 'deviationText',
							value: stateFunctions.get(modalItem._uuid_).deviationText,
						},
					]}
					columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
					items={getFormItems(mainCtx, modCtx, modalItem)}
				/>
			)}
			item={{ _uuid_: modalItem._uuid_, prevData: lodash.cloneDeep(modalItem) }}
		/>
	)
}

export default ParameterEditModal
