import React, { useState, useContext } from 'react'
import { Form as AntForm } from 'antd'

import Wrapper from '../Wrapper/Wrapper'
import { useInput } from '../../../utils'
import { DataServerContext } from '../../../contexts'

import Form from './Form'
import { columns } from './columns'
import { addData, deleteData, editData } from '../../../services/api/requests'
import { getAvailableCode } from '../../../utils/helpers/generators'

const vocUrl = '/voc/editable/production-area'

const ProductionArea = () => {
	const { state, updateData } = useContext(DataServerContext)
	const [form] = AntForm.useForm()

	const [recordId, setRecordId] = useState(null)

	const [label, resetLabel] = useInput('')
	const [labelShort, resetLabelShort] = useInput('')

	const resetFields = () => {
		resetLabel()
		resetLabelShort()
	}

	const addItem = async (successFn = (fn) => fn) => {
		const response =
			(await addData(vocUrl, {
				data: {
					label: label.value,
					displayCode: String(getAvailableCode(state[vocUrl].data.map((e) => +e.displayCode))),
				},
			})) || {}
		updateData(response.data, { url: vocUrl, method: 'POST' })
		successFn()
	}

	const editItem = async (successFn = (fn) => fn) => {
		const response = await editData(vocUrl, {
			recordId,
			data: {
				id: recordId,
				label: label.value,
			},
		})
		updateData(response.data, { url: vocUrl, method: 'PUT' })
		successFn()
	}

	const deleteItem = async (id) => {
		const response = await deleteData(vocUrl, {
			id,
		})
		updateData(response.data, { url: vocUrl, method: 'DELETE' })
		setRecordId(null)
	}

	return (
		<Wrapper
			rights={{
				Справочники: {
					'Производственные участки': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Производственные участки"
			model="voc_editable_production_area"
			canCreate
			canUpdate
			canDelete
			data={state[vocUrl].data}
			recordId={recordId}
			setRecordId={setRecordId}
			addData={addItem}
			editData={editItem}
			deleteData={deleteItem}
			columns={columns}
			resetFields={resetFields}
			fields={[{ label }, { labelShort }]}
			validateFields={[{ label }, { labelShort }]}
			uniqueFields={[{ label }]}
			form={<Form form={form} label={label} labelShort={labelShort} />}
			vocUrl={vocUrl}
		/>
	)
}

export default ProductionArea
