import { setNumField } from '@berry/front-common/for-fields'
import { Col, Select, Checkbox } from 'antd'
import { InputError } from '../../../../components'
import { getDataFromSupplyProdRmProvProd } from '@berry/common-functions/cross-project-functions'
const layout = {
	labelCol: { span: 13 },
	wrapperCol: { span: 12 },
}

export const getFormItems = (mainCtx) => {
	if (mainCtx.removeRegisterModal.__isOpen) {
		let items = [
			[
				{
					layout: layout,
					label: 'Поставщик',
					name: 'provider',
					field: mainCtx.removeRegisterModal.storage?.provider,
				},
				{
					layout: layout,
					label: 'Продукт',
					name: 'prodCat',
					field:
						(mainCtx.removeRegisterModal.storage &&
							`${mainCtx.removeRegisterModal.storage?.prodCat} ${
								getDataFromSupplyProdRmProvProd(mainCtx.removeRegisterModal.storage?.supplProd)
									?.prodCatKindLabel
							}`) ||
						'',
				},
				{
					layout: layout,
					label: 'Партия №',
					name: 'partyNum',
					field: mainCtx.removeRegisterModal.storage?.partyNum,
				},
				{
					layout: layout,
					label: 'Артикул',
					name: 'displayCode',
					field:
						mainCtx.removeRegisterModal.storage &&
						getDataFromSupplyProdRmProvProd(mainCtx.removeRegisterModal.storage.supplProd)?.articul,
				},

				{
					layout: layout,
					label: 'Масса, кг',
					name: 'weight',
					rules: [
						{ required: true },
						{
							validator: (_, value) => {
								if (Number(value) > mainCtx.removeRegisterModal.storage.weight) {
									return Promise.reject('Масса не должна превышать допущенную')
								}
								return Promise.resolve()
							},
						},
					],
					field: (
						<InputError
							hasError={mainCtx.formErrors?.[`removeRegisterModal.weight`]}
							value={mainCtx.removeRegisterModal?.weight}
							onChange={(e) => {
								setNumField(
									e.target.value,
									mainCtx.removeRegisterModal?.weight,
									mainCtx.modalFunctions.setRemoveRegisterModalWeight,
									'float',
									[9, 2],
									{ isOnlyNums: true }
								)
							}}
							style={{ width: '100%' }}
						/>
					),
				},
			],
		]
		return items
	}
	if (mainCtx.relocationModal.__isOpen) {
		return [
			[
				{
					layout: layout,
					label: 'Продукт',
					name: 'prodCat',
					field:
						(mainCtx.relocationModal.storage &&
							`${mainCtx.relocationModal.storage?.prodCat} ${
								getDataFromSupplyProdRmProvProd(mainCtx.relocationModal.storage?.supplProd)
									?.prodCatKindLabel
							}`) ||
						'',
				},
				{
					layout: layout,
					label: 'Партия №',
					name: 'partyNum',
					field: mainCtx.relocationModal.storage?.partyNum,
				},
				{
					layout: layout,
					label: 'Артикул',
					name: 'displayCode',
					field:
						mainCtx.relocationModal.storage &&
						getDataFromSupplyProdRmProvProd(mainCtx.relocationModal.storage.supplProd)?.articul,
				},
				{
					layout: layout,
					label: 'Тек. масса, кг',
					name: 'storWeight',
					field: mainCtx.relocationModal.storage?.weight,
				},
				{
					layout: layout,
					label: 'Тек. помещение №',
					name: 'roomNumber',
					field: mainCtx.relocationModal.storage?.roomNum,
				},
				{
					layout: layout,
					label: `Переместить на дефростацию`,
					name: 'isDefrost',
					field: (
						<Checkbox
							checked={mainCtx.relocationModal.isDefrost}
							onChange={(e) => {
								mainCtx.modalFunctions.setRelocationModalIsDefrost(e.target.checked)
								mainCtx.modalFunctions.setRelocationModalIsWriteOff(false)
								mainCtx.modalFunctions.setRelocationModalRoomNum(null)
							}}
						/>
					),
				},
				// {
				// 	layout: layout,
				// 	label: `Перемещение в брак`,
				// 	name: 'isWriteOff',
				// 	field: (
				// 		<Checkbox
				// 			checked={mainCtx.relocationModal.isWriteOff}
				// 			onChange={(e) => {
				// 				mainCtx.modalFunctions.setRelocationModalIsWriteOff(e.target.checked)
				// 				mainCtx.modalFunctions.setRelocationModalIsDefrost(false)
				// 				mainCtx.modalFunctions.setRelocationModalRoomNum(null)
				// 			}}
				// 		/>
				// 	),
				// },
				{
					layout: layout,
					label: 'Масса, кг',
					name: 'weight',
					rules: [
						{ required: true },
						{
							validator: (_, value) => {
								if (Number(value) > mainCtx.relocationModal.storage.weight) {
									return Promise.reject('Масса не должна превышать допущенную')
								}
								return Promise.resolve()
							},
						},
					],
					field: (
						<InputError
							hasError={mainCtx.formErrors?.[`relocationModal.weight`]}
							value={mainCtx.relocationModal?.weight}
							onChange={(e) => {
								setNumField(
									e.target.value,
									mainCtx.relocationModal?.weight,
									mainCtx.modalFunctions.setRelocationModalWeight,
									'float',
									[9, 2],
									{ isOnlyNums: true }
								)
							}}
							style={{ width: '100%' }}
						/>
					),
				},
				{
					layout: layout,
					label: 'Помещение №',
					name: 'roomNums',
					rules: [{ required: true }],
					field: (
						<Select
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								mainCtx.modalFunctions.setRelocationModalRoomNum(el)
							}}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{getRoomNumsArray(mainCtx)?.map((it) => {
								return (
									<Select.Option key={it.id} value={it.id}>
										{it.displayCode}
									</Select.Option>
								)
							}) || []}
						</Select>
					),
				},
			],
		]
	}
	if (mainCtx.repackModal.__isOpen) {
		return [
			[
				{
					layout: layout,
					label: 'Продукт',
					name: 'prodCat',
					field:
						(mainCtx.repackModal.storage &&
							`${mainCtx.repackModal.storage?.prodCat} ${
								getDataFromSupplyProdRmProvProd(mainCtx.repackModal.storage?.supplProd)
									?.prodCatKindLabel
							}`) ||
						'',
				},
				{
					layout: layout,
					label: 'Партия №',
					name: 'partyNum',
					field: mainCtx.repackModal.storage?.partyNum,
				},
				{
					layout: layout,
					label: 'Артикул',
					name: 'displayCode',
					field:
						mainCtx.repackModal.storage &&
						getDataFromSupplyProdRmProvProd(mainCtx.repackModal.storage.supplProd)?.articul,
				},
				{
					layout: layout,
					label: 'Тек. тип упаковки',
					name: 'roomNumber',
					field: mainCtx.repackModal.storage?.pkgType,
				},
				{
					layout: layout,
					label: 'Масса, кг',
					name: 'weight',
					rules: [
						{ required: true },
						{
							validator: (_, value) => {
								if (Number(value) > mainCtx.repackModal.storage.weight) {
									return Promise.reject('Масса не должна превышать допущенную')
								}
								return Promise.resolve()
							},
						},
					],
					field: (
						<InputError
							hasError={mainCtx.formErrors?.[`relocationModal.weight`]}
							value={mainCtx.repackModal?.weight}
							onChange={(e) => {
								setNumField(
									e.target.value,
									mainCtx.repackModal?.weight,
									mainCtx.modalFunctions.setRepackModalWeight,
									'float',
									[9, 2],
									{ isOnlyNums: true }
								)
							}}
							style={{ width: '100%' }}
						/>
					),
				},
				{
					layout: layout,
					label: 'Целевая упаковка',
					name: 'vocContType',
					rules: [{ required: true }],
					field: (
						<Select
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								mainCtx.modalFunctions.setRepackModalVocContType(el)
							}}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors.repackModal.vocContType.map((it) => {
								return (
									<Select.Option key={it.id} value={it.id}>
										{it.labelShort}
									</Select.Option>
								)
							}) || []}
						</Select>
					),
				},
				{
					layout: layout,
					label: `Нестандарт. уп.`,
					name: 'isStandPkg',
					field: (
						<Checkbox
							checked={mainCtx.repackModal.isStandPkg}
							onChange={(e) => {
								mainCtx.modalFunctions.setRepackModalIsStandPkg(e.target.checked)
							}}
						/>
					),
				},
				{
					layout: layout,
					label: 'Масса нетто ед. упак., кг',
					name: 'nettoPkgWeight',
					rules: [{ required: true }],
					field: (
						<InputError
							hasError={mainCtx.formErrors?.[`repackModal.nettoPkgWeight`]}
							value={mainCtx.repackModal?.weight}
							onChange={(e) => {
								setNumField(
									e.target.value,
									mainCtx.repackModal?.nettoPkgWeight,
									mainCtx.modalFunctions.setRepackModalNettoPkgWeight,
									'float',
									[5, 2],
									{ isOnlyNums: true }
								)
							}}
							style={{ width: '100%' }}
						/>
					),
				},
			],
		]
	}
	if (mainCtx.changeStatusModal.__isOpen) {
		return [
			[
				{
					layout: layout,
					label: 'Продукт',
					name: 'prodCat',
					field:
						(mainCtx.changeStatusModal.storage &&
							`${mainCtx.changeStatusModal.storage?.prodCat} ${
								getDataFromSupplyProdRmProvProd(mainCtx.changeStatusModal.storage?.supplProd)
									?.prodCatKindLabel
							}`) ||
						'',
				},
				{
					layout: layout,
					label: 'Партия №',
					name: 'partyNum',
					field: mainCtx.changeStatusModal.storage?.partyNum,
				},
				{
					layout: layout,
					label: 'Артикул',
					name: 'displayCode',
					field:
						mainCtx.changeStatusModal.storage &&
						getDataFromSupplyProdRmProvProd(mainCtx.changeStatusModal.storage.supplProd)?.articul,
				},
				{
					layout: layout,
					label: 'Тек. масса, кг',
					name: 'storWeight',
					field: mainCtx.changeStatusModal.storage?.weight,
				},
				{
					layout: layout,
					label: 'Тек. статус',
					name: 'roomNumber',
					field: mainCtx.changeStatusModal.storage?.status,
				},
				{
					layout: layout,
					label: `Статус`,
					name: 'status',
					rules: [{ required: true }],
					field: (
						<Select
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								mainCtx.modalFunctions.setChangeStatusModalStatus(el)
							}}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{['Допущено', 'Заблокировано', 'Удержано']
								.filter((s) => s !== mainCtx.changeStatusModal.storage?.status)
								.map((it) => {
									return (
										<Select.Option key={it} value={it}>
											{it}
										</Select.Option>
									)
								}) || []}
						</Select>
					),
				},
				{
					layout: layout,
					label: 'Масса, кг',
					name: 'weight',
					rules: [
						{ required: true },
						{
							validator: (_, value) => {
								if (Number(value) > mainCtx.changeStatusModal.storage.weight) {
									return Promise.reject('Масса не должна превышать допущенную')
								}
								return Promise.resolve()
							},
						},
					],
					field: (
						<InputError
							hasError={mainCtx.formErrors?.[`changeStatusModal.weight`]}
							value={mainCtx.changeStatusModal?.weight}
							onChange={(e) => {
								setNumField(
									e.target.value,
									mainCtx.changeStatusModal?.weight,
									mainCtx.modalFunctions.setChangeStatusModalWeight,
									'float',
									[9, 2],
									{ isOnlyNums: true }
								)
							}}
							style={{ width: '100%' }}
						/>
					),
				},

				...(['Заблокировано', 'Удержано'].includes(mainCtx.changeStatusModal.status)
					? [
							{
								layout: layout,
								label: 'Причина блокировки',
								name: 'blockCauses',
								rules: [
									{
										required: ['Заблокировано', 'Удержано'].includes(
											mainCtx.changeStatusModal.status
										),
									},
								],
								field: (
									<Select
										mode="multiple"
										placeholder="Выберите причины"
										style={{ width: '100%', minWidth: '120px' }}
										value={mainCtx.changeStatusModal?.blockCauses?.map((bk) => bk?.id)}
										onChange={(e) => {
											mainCtx.modalFunctions.setChangeStatusModalBlockCauses([
												...e.map((id) => {
													const bk = mainCtx.selectors.changeStatusModal.blockCauses.find(
														(bk) => bk.id === id
													)
													return bk
												}),
											])
										}}
									>
										{mainCtx.selectors.changeStatusModal.blockCauses?.map((it) => {
											return (
												<Select.Option key={it.id} value={it.id}>
													{it.label}
												</Select.Option>
											)
										}) || []}
									</Select>
								),
							},
					  ]
					: []),
			],
		]
	}
}

export const getRoomNumsArr = (mainCtx, type) => {
	return mainCtx.selectors.relocationModal.roomNum?.filter(({ roomType }) => roomType !== 'Внешний')
}

export const getRoomNumsArray = (mainCtx, storType) => {
	let storageStr = ''
	let typeStr = ''
	switch (storType) {
		case 'ПФ':
			storageStr = 'Полуфабрикат'
			typeStr = 'Производственное'
			break
		case 'ГП':
			storageStr = 'Готовая продукция'
			typeStr = 'Складское'
			break
		default:
			storageStr = 'Сырье'
			typeStr = 'Производственное'
	}

	return mainCtx.selectors.relocationModal.roomNum?.filter(({ idVocOutdoorWh, storage, type }) => {
		const rawMatStor = storage
			.split(',')
			.filter((s) => s === storageStr)
			?.join()
		const manufType = type
			.split(',')
			.filter((s) => s === typeStr)
			?.join()
		const isSameIdOutdoorWh = idVocOutdoorWh === mainCtx.relocationModal.storage.idVocOutdoorWh
		if (mainCtx.relocationModal.isDefrost) {
			return isSameIdOutdoorWh && manufType === 'Производственное'
		}
		if (storType === 'ГП') {
			if (!mainCtx.relocationModal.storage.typeOfRoom.split(',').includes(manufType)) return false
		}
		return (
			isSameIdOutdoorWh &&
			mainCtx.relocationModal.storage.roomStorage.split(',').includes(rawMatStor)
		)
	})
}
