import moment from 'moment'
/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = () => {
	return [
		{
			title: 'Дата и время',
			dataIndex: 'date',
			key: 'date',
			width: '5%',
			render: (_, rec) => `${moment(rec.date).format('DD.MM.YYYY HH:mm')}`,
		},
		{
			title: 'Пользователь',
			dataIndex: 'employee',
			key: 'employee',
			width: '13%',
		},
		{
			title: 'ID операции',
			dataIndex: 'idOperation',
			key: 'idOperation',
			width: '2%',
		},
		{
			title: 'Событие',
			dataIndex: 'event',
			key: 'event',
			width: '10%',
		},
		{
			title: 'Операция',
			dataIndex: 'operation',
			key: 'operation',
			width: '10%',
		},
		{
			title: 'Причина',
			dataIndex: 'cause',
			key: 'cause',
			width: '10%',
		},
	]
}
