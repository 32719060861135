import React, { useContext, useState } from 'react'
import { checkRole } from '@berry/common-functions/role'
import moment from 'moment'
import { AuthContext, ModContext, ModProvider, UserDataContext } from '../../../contexts'
import { Main } from '../../../layouts'
import { Button, Card, Col, Form, Row, Space } from 'antd'
import {
	ActionButtons,
	CustomForm,
	TabList,
	showConfirmModal,
	HeaderMenu,
} from '../../../components'
import { actBtnOnClose, actBtnOnSaveV2, axios, modes } from '../../../utils'
import { getFormItems, getMinorTabs } from './contract-provider-item'
import { useHistory } from 'react-router'
import { ContractItemMainContext, Provider } from '../provider/item'
import { SupplyReqModalContext, Provider as SuplReqModalProvider } from '../provider/supply-req'
import { isContractActive } from '../utils'
import SupplyRequestModal from './SupplyRequestModal'
import { getBreadcrumbsText } from '../../../utils/helpers/generators'
import { showIsBLockedModalForDeps } from '../../../utils/constants/for-components'

const pageUrl = '/rm/contracts-providers'

const rights = {
	'АРМ офиса': {
		'Договоры. Поставщики': {
			edit: true,
		},
	},
}

const supReqRights = {
	'АРМ офиса': {
		'Заявки на поставку сырья': {
			edit: true,
		},
	},
}

const ContractProviderItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id, contractType: 'provider' }}>
				<SuplReqModalProvider params={{ idContract: props.match.params.id }}>
					<Main title="Договоры. Поставщики">
						<InfoComponent></InfoComponent>
					</Main>
				</SuplReqModalProvider>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const [className, setClassName] = useState('')
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(ContractItemMainContext)
	const supplReqCtx = useContext(SupplyReqModalContext)
	const authCtx = useContext(AuthContext)
	const userDataCtx = useContext(UserDataContext)
	const history = useHistory()
	const [form] = Form.useForm()
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const breadCrumb = getBreadcrumbsText('/contracts', mainCtx.state, { provider: true })
	return (
		<>
			<HeaderMenu breadcrumbsTitle={breadCrumb.head} recordLabel={breadCrumb.label}>
				<Row>
					<Space size="middle">
						{checkRole(supReqRights, currentRights) &&
							modCtx.mod === modes.view &&
							!mainCtx.state?.tolling && (
								<Button
									type="primary"
									onClick={() => {
										supplReqCtx.stateFunctions.fieldSetters.setIsModalOpen(true)
									}}
								>
									Создать заявку
								</Button>
							)}
						<ActionButtons
							isPendingReq={mainCtx.isPendingReq}
							rights={rights}
							formName={'contractProvider'}
							onClose={() => {
								actBtnOnClose({
									history: history,
									pageUrl: pageUrl,
									showConfirmModal: showConfirmModal,
									isEdited: mainCtx.isEdited,
								})
							}}
							onSave={async () => {
								if (await mainCtx.checkIsBlockedProviderQuota()) {
									axios.post(`/logs`, {
										time: new Date().toISOString(),
										action: 'Edit',
										isBlockUnblockLogic: true,
										entity: 'rpRwContract',
										idEntity: mainCtx.state.id,
									})
									showConfirmModal(showIsBLockedModalForDeps)

									return
								}
								await actBtnOnSaveV2({
									serverEdit: mainCtx.serverEdit,
									validate: mainCtx.validate,
									isShowErrorModal: true,
									showConfirmModal,
									history: history,
									pageUrl: pageUrl,
									form,
								})
							}}
							onEdit={() => {
								modCtx.set(modes.edit)
							}}
							onCancel={() => {
								form.resetFields()
								mainCtx.reset()
							}}
						/>
					</Space>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: '10px' }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							form={form}
							name="contractProvider"
							items={getFormItems({
								mainCtx,
								authCtx,
								modCtx,
								userDataCtx,
								className,
								setClassName,
							})}
							fields={[
								{ name: 'num', value: mainCtx.state.num },
								{
									name: 'dateStart',
									value: mainCtx.state.dateStart ? moment(mainCtx.state.dateStart) : null,
								},
								{
									name: 'dateEnd',
									value: mainCtx.state.dateEnd ? moment(mainCtx.state.dateEnd) : null,
								},
								{ name: 'provider', value: mainCtx.state.provider },
								{ name: 'tolling', value: mainCtx.state.tolling },
								{ name: 'frame', value: mainCtx.state.frame },
								{ name: 'autoProlongation', value: mainCtx.state.autoProlongation },
								{ name: 'isProtDisagr', value: mainCtx.state.isProtDisagr },
								{
									name: 'status',
									value: isContractActive(mainCtx.state) ? 'Действующий' : 'Закрытый',
								},
								{ name: 'platform', value: mainCtx.state.platform },
								{ name: 'vocDeliverCondit', value: mainCtx.state.vocDeliverCondit },
								{ name: 'vocPayCondit', value: mainCtx.state.vocPayCondit },
							]}
						/>
					</Col>
				</Row>
			</Card>
			<TabList tabs={getMinorTabs(mainCtx)} />
			<SupplyRequestModal />
		</>
	)
}

export default ContractProviderItem
