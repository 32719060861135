import { Checkbox, Select, Col } from 'antd'
import { modes } from '../../../utils'
import lodash from 'lodash'

export const getDisplayColumns = (mainCtx, modCtx, editHandler) => {
	let isDisabled = false
	if (modCtx.mod === modes.view) isDisabled = true
	const usersLength = lodash.cloneDeep(mainCtx.state).filter((i) => i.isUser).length
	const numbersOfApproveAndMatch = Array.from(
		{
			length: usersLength === 2 ? 2 : usersLength - 1,
		},
		(v, k) => k
	)
	numbersOfApproveAndMatch.shift()
	numbersOfApproveAndMatch.unshift('-')

	return [
		{
			title: 'Должность',
			dataIndex: 'label',
			key: 'position',
			width: '23%',
		},
		{
			title: 'Инициатор',
			// dataIndex: 'label',
			key: 'position',
			width: '23%',
			render: (_, record) => {
				return (
					<Checkbox
						disabled={isDisabled}
						checked={record.isInitiatorSpec}
						onChange={(e) => {
							mainCtx.stateFunctions.setIsInitiatorSpec(record._uuid_, e.target.checked)
						}}
					></Checkbox>
				)
			},
		},
		{
			title: 'Согласующий',
			dataIndex: 'lvlMatchSpec',
			key: 'position',
			width: '23%',
			render: (_, record) => {
				return (
					<Select
						defaultValue="-"
						value={record.lvlMatchSpec}
						style={{ width: '20%' }}
						onChange={(e) => {
							mainCtx.stateFunctions.setLvlMatchSpec(record._uuid_, e)
						}}
						disabled={isDisabled || usersLength <= 2}
						notFoundContent={<Col>Не найдено</Col>}
					>
						{numbersOfApproveAndMatch.map((it) => {
							return (
								<Select.Option key={it} value={it}>
									{it}
								</Select.Option>
							)
						}) || []}
					</Select>
				)
			},
		},
		{
			title: 'Утверждающий',
			dataIndex: 'lvlApproveSpec',
			key: 'position',
			width: '23%',
			render: (_, record) => {
				return (
					<Select
						defaultValue="-"
						value={record.lvlApproveSpec}
						style={{ width: '20%' }}
						onChange={(e) => {
							mainCtx.stateFunctions.setLvlApproveSpec(record._uuid_, e)
						}}
						disabled={isDisabled}
						notFoundContent={<Col>Не найдено</Col>}
					>
						{numbersOfApproveAndMatch.map((it) => {
							return (
								<Select.Option key={it} value={it}>
									{it}
								</Select.Option>
							)
						}) || []}
					</Select>
				)
			},
		},
	]
}
