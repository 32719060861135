import React, { useContext } from 'react'
import { Main } from '../../../layouts'
import { Provider, ContractListMainContext } from '../provider/list-customer'
import { HeaderMenu, StatefulTable } from '../../../components'
import { getColumns } from './contract-customer-list'
import { Card } from 'antd'

const pageUrl = '/rp/contracts-customers'

const ContractsCustomersList = () => {
	return (
		<Provider>
			<Main title={'Договоры. Заказчики'}>
				<ContractsCustomersTable />
			</Main>
		</Provider>
	)
}

const ContractsCustomersTable = () => {
	const mainCtx = useContext(ContractListMainContext)
	return (
		<>
			<HeaderMenu breadcrumbsTitle={'Договоры. Заказчики'} search={mainCtx.setSearch} />
			<Card>
				<StatefulTable
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					dataSource={mainCtx.toDisplayDataList}
					columns={getColumns}
					url={pageUrl}
					// searchFields={mainCtx.searchFields}
				/>
			</Card>
		</>
	)
}
export default ContractsCustomersList
