import React, { useState, useEffect, useContext } from 'react'
import { Form as AntForm } from 'antd'

import Wrapper from '../Wrapper/Wrapper'
import { useInput, useCheckbox } from '../../../utils'
import { getDataForVocs } from '../AdditionalSemiType/AdditionalSemiType'

import Form from './Form'
import { columns } from './columns'
import { addData, deleteData, editData } from '../../../services/api/requests'
import { SyncDepsContext } from '../../../contexts'

const vocUrl = '/voc/editable/quality'

const Quality = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, setState] = useState([])
	useEffect(() => {
		getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
	}, [syncDepsCtx.state.reloadUuids['office-ms']])
	const [form] = AntForm.useForm()

	const [recordId, setRecordId] = useState(null)
	const [label, resetLabel] = useInput('')
	const [displayCode, resetDisplayCode] = useInput('')
	const [isRawMat, resetIsRawMatVal] = useCheckbox(false)
	const [isReadyProd, resetIsReadyProdVal] = useCheckbox(false)

	const resetFields = () => {
		resetLabel()
		resetIsRawMatVal()
		resetIsReadyProdVal()
		resetDisplayCode()
	}

	const addItem = async (successFn = (fn) => fn) => {
		const response =
			(await addData(vocUrl, {
				data: {
					label: label.value,
					displayCode: displayCode.value,
					isRawMat: isRawMat.value,
					isReadyProd: isReadyProd.value,
				},
			})) || {}
		setState((prev) => [...prev, response.data.result.data])
		successFn()
	}

	const editItem = async (successFn = (fn) => fn) => {
		const response = await editData(vocUrl, {
			recordId,
			data: {
				id: recordId,
				label: label.value,
				displayCode: displayCode.value,
				isRawMat: isRawMat.value,
				isReadyProd: isReadyProd.value,
			},
		})
		setState((prev) => {
			const foundIndex = prev.findIndex((el) => el.id === response.data.result.data.id)
			return [
				...prev.slice(0, foundIndex),
				response.data.result.data,
				...prev.slice(foundIndex + 1),
			]
		})

		successFn()
	}

	const deleteItem = async (id) => {
		const response = await deleteData(vocUrl, {
			id,
		})
		setState((prev) => {
			const foundIndex = prev.findIndex((el) => el.id === +response.data.result.id)
			return [...prev.slice(0, foundIndex), ...prev.slice(foundIndex + 1)]
		})
		setRecordId(null)
	}

	return (
		<Wrapper
			rights={{
				Справочники: {
					Качество: {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Качество"
			model="voc_editable_quality"
			canCreate
			canUpdate
			canDelete
			data={state}
			recordId={recordId}
			setRecordId={setRecordId}
			addData={addItem}
			editData={editItem}
			deleteData={deleteItem}
			columns={columns()}
			resetFields={resetFields}
			fields={[{ label }, { displayCode }, { isRawMat }, { isReadyProd }]}
			validateFields={[{ label }, { displayCode }]}
			customValidateFields={[
				() => {
					if (isReadyProd.value || isRawMat.value) {
						return ''
					}
					return 'Установите флаг Сырье или ГП'
				},
			]}
			uniqueFields={[{ label }, { displayCode }]}
			form={
				<Form
					form={form}
					label={label}
					displayCode={displayCode}
					isRawMat={isRawMat}
					isReadyProd={isReadyProd}
				/>
			}
			vocUrl={vocUrl}
		/>
	)
}

export default Quality
