const getText = (code) => {
    switch (code) {
        /* 100 - реестр персонала */
        case 101:
            return 'Адрес электронной почты неправильный'
        /* 200 - План производства */
        case 201:
            return 'Наряд заказ не может быть выполнен за сутки'
        default:
            return 'Неизвестная ошибка'
    }
}

export { getText }
