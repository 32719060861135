import { useState } from 'react'

const useInput = (initialValue, options) => {
	const [value, setValue] = useState(initialValue)

	const onChange = (e) => {
		const value = e instanceof Object ? e.target.value : e
		if (value === '' && options?.dropIfEmpty) {
			setValue(null)
		} else {
			setValue(value)
		}
	}

	return [{ value, onChange }, () => setValue(initialValue)]
}

export default useInput
