import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import { Card, Col, Row, Form } from 'antd'
import { ModContext, ModProvider, FileProvider } from '../../../contexts'
import { Provider, RawMaterialSupplyItemMainContext } from './provider/main'
import { Main } from '../../../layouts'
import moment from 'moment'
import {
	ActionButtons,
	CustomForm,
	HeaderMenu,
	showConfirmModal,
	TabList,
} from '../../../components'
import { getFormItems } from './raw-material-supply-item'
import ProductTab from './ProductTab'
import EventHistoryTab from './EventHistoryTab'
import InvoicesTab from './InvoicesTab'
import { getBreadcrumbsText } from '../../../utils/helpers/generators'
import { actBtnOnClose, actBtnOnDelete, actBtnOnSaveV2, axios, modes } from '../../../utils'
import { showIsBLockedModalForDeps } from '../../../utils/constants/for-components'
import {
	EDIT_NOT_PERMIT_BECAUSE_OF_BLOCK,
	INACTION_AWARE,
} from '../../../utils/constants/plain-text'

const pageUrl = '/rm/supplies'

const RawMaterialSupplyItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<FileProvider>
				<Provider params={{ id: props.match.params.id }}>
					<Main title="Поставки сырья">
						<InfoComponent />
					</Main>
				</Provider>
			</FileProvider>
		</ModProvider>
	)
}
const InfoComponent = () => {
	const mainCtx = useContext(RawMaterialSupplyItemMainContext)
	const modCtx = useContext(ModContext)
	const history = useHistory()
	if (mainCtx.state.__notFound) {
		history.replace(pageUrl)
	}
	const [form] = Form.useForm()
	const [supReqItem, setSupReqItem] = useState({
		data: { ...mainCtx.state.supplyReq, checked: true },
	})
	const bread = getBreadcrumbsText(pageUrl, mainCtx.state)

	return (
		<>
			<HeaderMenu breadcrumbsTitle={bread.head} recordLabel={bread.label}>
				{modCtx.mod === modes.edit && (
					<Row
						style={{
							color: `rgba(207, 9, 9, 1)`,
						}}
					>
						{INACTION_AWARE}
					</Row>
				)}
				<Row justify="space-between">
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						canEdit={
							(!mainCtx.state.invoices?.length &&
								!['Выполнена', 'Принято в 1С'].includes(mainCtx.state.status)) ||
							mainCtx.state.invoices?.some((inv) =>
								[
									'Новая',
									'Ожидает отправки в 1С',
									'Отклонено 1С',
									'Отправляется в 1С',
									'Ошибка отправки в 1С',
									'Выполнена',
								].includes(inv.status)
							)
						}
						rights={{
							'АРМ офиса': {
								'Поставки сырья': {
									'Основные данные': {
										edit: true,
									},
								},
							},
						}}
						formName="supplyRequest"
						canDelete={
							modCtx.mod !== modes.new &&
							mainCtx.state.products?.every((p) => ['Новая', 'Черновик'].includes(p.unldStatus))
						}
						onClose={async () => {
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								onClose: async () => {
									await mainCtx.unblockForEditing()
									history.replace(pageUrl)
								},
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							await actBtnOnSaveV2({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								pageUrl,
								history,
								isShowErrorModal: true,
								showConfirmModal,
								form,
							})
							await mainCtx.unblockForEditing()
						}}
						onEdit={async () => {
							if (await mainCtx.checkIsBlocked()) {
								axios.post(`/logs`, {
									time: new Date().toISOString(),
									action: 'Delete',
									isBlockUnblockLogic: true,
									entity: 'rmSupply',
									idEntity: mainCtx.state.id,
								})
								showConfirmModal({
									...showIsBLockedModalForDeps,
									title: EDIT_NOT_PERMIT_BECAUSE_OF_BLOCK,
								})
								return
							}
							if (await mainCtx.blockForEditing()) {
								modCtx.set(modes.edit)
								mainCtx.rerender()
							}
						}}
						onDelete={async () => {
							if (await mainCtx.checkIsBlocked()) {
								axios.post(`/logs`, {
									time: new Date().toISOString(),
									action: 'Delete',
									isBlockUnblockLogic: true,
									entity: 'rmSupply',
									idEntity: mainCtx.state.id,
								})
								showConfirmModal({
									...showIsBLockedModalForDeps,
									title: 'Удаление недоступно, вносятся изменения другим пользователем',
								})
								return
							}
							await actBtnOnDelete({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								title:
									'Все данные карточек Входной контроль и Разгрузка будут потеряны, будут аннулированы все согласования',
							})
						}}
						onCancel={async () => {
							if (await mainCtx.unblockForEditing()) {
								const allFields = form.getFieldsError()
								allFields.forEach((f) => {
									f.errors = []
								})
								form.setFields(allFields)
								mainCtx.reset()
							}
						}}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							form={form}
							name="supplyRequest"
							fields={[
								{ name: 'status', value: mainCtx.state.status },
								{ name: 'id', value: mainCtx.state.id },
								{ name: 'date' },
								{ name: 'provider', value: mainCtx.state.provider },
								{
									name: 'arriveTime',
									value: mainCtx.state.arriveTime ? moment(mainCtx.state.arriveTime) : null,
								},
								{
									name: 'unldTimeStart',
									value: mainCtx.state.unldTimeStart ? moment(mainCtx.state.unldTimeStart) : null,
								},
								{
									name: 'unldTimeStop',
									value: mainCtx.state.unldTimeStop ? moment(mainCtx.state.unldTimeStop) : null,
								},
								{ name: 'vehicleNum', value: mainCtx.state.vehicleNum },
								{ name: 'trailerNum', value: mainCtx.state.trailerNum },
								{ name: 'driverPhone', value: mainCtx.state.driverPhone },
								{ name: 'supplyReq', value: mainCtx.state.supplyReq?.displayCode },
								{
									name: 'staff',
									value: mainCtx.state.staff,
								},
								{ name: 'sealNum', value: mainCtx.state.sealNum },
								{ name: 'dateStart', value: mainCtx.state.dateStart },
								{ name: 'tolling', value: mainCtx.state.tolling },
								{ name: 'temperSymb', value: mainCtx.state.temperSymb },
								{ name: 'temperVal', value: mainCtx.state.temperVal },
								{ name: 'noCooling', value: mainCtx.state.noCooling },
								{ name: 'defrost', value: mainCtx.state.defrost },
								{ name: 'carState', value: mainCtx.state.carState },
							]}
							columns={[
								{ size: { xs: 24, xl: 8 }, num: 0 },
								{ size: { xs: 24, xl: 9 }, num: 1 },
								{ size: { xs: 24, xl: 7 }, num: 2 },
							]}
							items={getFormItems(mainCtx, modCtx, form, supReqItem, setSupReqItem)}
						/>
					</Col>
				</Row>
			</Card>
			<TabList
				tabs={[
					{ key: 0, title: 'Накладные', component: <InvoicesTab /> },
					{ key: 2, title: 'Наименование продукции', component: <ProductTab /> },
					{ key: 3, title: 'История событий', component: <EventHistoryTab /> },
				]}
			/>
		</>
	)
}

export default RawMaterialSupplyItem
