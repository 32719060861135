import { Checkbox } from 'antd'
import { changeDateOutput, isContractActive } from '../utils'
import { getColumnSearchProps, sorter } from '../../../utils'
import { dateFormat } from '../../../utils/constants/common'
import moment from 'moment'
import { Status } from '../../../components'

export const getColumns = (props) => {
	const { fromServerFilters } = props

	return [
		{
			title: 'Р',
			dataIndex: 'frame',
			key: 'frame',
			sorter: sorter('boolean', 'frame'),
			render: (value) => {
				return <Checkbox disabled checked={value}></Checkbox>
			},
			width: '2.5%',
		},
		{
			title: 'Номер договора',
			dataIndex: 'num',
			key: 'num',
			width: '15%',
			sorter: sorter('string', 'num'),
		},
		{
			title: 'Дата заключения',
			dataIndex: 'dateStart',
			key: 'dateStart',
			...getColumnSearchProps(fromServerFilters.dateStart, undefined, null, true),
			render: (dateStart) => (dateStart ? moment(dateStart).format(dateFormat) : null),
			sorter: sorter('date', 'dateStart', 'YYYY-MM-DD'),
			width: '15%',
		},
		{
			title: 'Заказчик',
			dataIndex: ['customer', 'label'],
			key: 'customerLabel',
			...getColumnSearchProps(fromServerFilters.customerLabel),
			width: '20%',
		},
		{
			title: 'Продукт',
			dataIndex: 'product',
			key: 'product',
			...getColumnSearchProps(fromServerFilters.product),
			width: '27.5%',
		},
		{
			title: 'Срок договора',
			dataIndex: 'dateEnd',
			key: 'dateEnd',
			render: (_, record) =>
				changeDateOutput(record)
					? moment(changeDateOutput(record)).format(dateFormat)
					: changeDateOutput(record),
			...getColumnSearchProps(fromServerFilters.dateEnd, undefined, null, true),
			width: '10%',
		},
		{
			title: 'Статус',
			dataIndex: '',
			key: 'status',
			render: (record) => <Status status={isContractActive(record) ? 'Действующий' : 'Закрытый'} />,
			...getColumnSearchProps(fromServerFilters.status),
			width: '10%',
		},
	]
}
