import React from 'react'

import Router from './pages/Router'
import { useSetZoom } from './utils'
import {
	DataServerProvider,
	SyncDepsProvider,
	AuthProvider,
	MenuProvider,
	MenuContext,
	UserDataContextProvider,
} from './contexts'

import 'antd/dist/antd.css'
import { AxiosInstanceProvider } from './contexts/axios/axios'

const App = () => {
	useSetZoom()
	return (
		<AxiosInstanceProvider>
			<AuthProvider>
				<UserDataContextProvider>
					<MenuProvider value={MenuContext}>
						<SyncDepsProvider>
							<DataServerProvider>
								<Router />
							</DataServerProvider>
						</SyncDepsProvider>
					</MenuProvider>
				</UserDataContextProvider>
			</AuthProvider>
		</AxiosInstanceProvider>
	)
}

export default App
