import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Row, Card, Col, Form } from 'antd'
import { ModContext, ModProvider } from '../../../contexts'
import { Provider, MatchSupplySettingsItemMainContext } from './provider/main'
import { Main } from '../../../layouts'
import { ActionButtons, HeaderMenu, showConfirmModal, CustomForm } from '../../../components'
import { getFormItems } from './settings-1c'
import { actBtnOnClose, modes } from '../../../utils'

const pageUrl = '/system-settings'

const Settings1c = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<Provider params={{ id: props.match.params.id }}>
				<Main title="Настройки системы">
					<InfoComponent />
				</Main>
			</Provider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const mainCtx = useContext(MatchSupplySettingsItemMainContext)
	const modCtx = useContext(ModContext)
	const history = useHistory()
	const [form] = Form.useForm()
	return (
		<>
			<HeaderMenu breadcrumbsTitle="Настройки системы" recordLabel={'Настройки 1С'}>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						rights={{
							'АРМ офиса': {
								'Настройки системы': {
									edit: true,
								},
							},
						}}
						addBtn={() => {}}
						onClose={() =>
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
								isCancelBtnPrimary: true,
							})
						}
						onSave={async () => await mainCtx.serverEdit()}
						onEdit={() => modCtx.set(modes.edit)}
						onCancel={() => mainCtx.reset()}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm form={form} name="main" items={getFormItems(mainCtx, modCtx)} />
					</Col>
				</Row>
			</Card>
		</>
	)
}

export default Settings1c
