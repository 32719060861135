import { sorter, getColumnSearchProps } from '../../../utils'
import { dateFormat } from '../../../utils/constants/common'
import { colorizeIntegrationListStatus } from '../../../utils/helpers/for-integration'
import moment from 'moment'

export const url = '/rm/supplies'

export const rights = {
	'АРМ офиса': {
		'Поставки сырья': {
			'Основные данные': {
				edit: true,
			},
		},
	},
}

/**
 * получить продукты заявки
 * @param {Object} products
 * @returns {String}
 */

const getInvRowSpan = (inRecord) => ({ rowSpan: inRecord.invRowSpan })
const getSupplyRowSpan = (inRecord) => ({ rowSpan: inRecord.supplyRowSpan })
const getProdRowSpan = (inRecord) => ({ rowSpan: inRecord.prodRowSpan })

/**
 * @param {Object} fromServerFilters - массив с записями таблицы
 */
export const getColumns = ({ fromServerFilters }) => {
	return [
		{
			title: 'ID',
			dataIndex: 'supplyId',
			key: 'supplyId',
			width: '5.1%',
			sorter: sorter('number', 'supplyId'),
			onCell: getSupplyRowSpan,
			...getColumnSearchProps(fromServerFilters.supplyId),
		},
		{
			title: 'Номер заявки',
			dataIndex: 'supplyReqDisplayCode',
			key: 'supplyReqDisplayCode',
			width: '8.32%',
			onCell: getSupplyRowSpan,
			sorter: sorter('number', 'supplyReqDisplayCode'),
			...getColumnSearchProps(fromServerFilters.supplyReqDisplayCode),
		},
		{
			title: '№ машины',
			dataIndex: 'supplyReqVehicleNum',
			key: 'supplyReqVehicleNum',
			width: '6.62%',
			onCell: getSupplyRowSpan,
			...getColumnSearchProps(fromServerFilters.supplyReqVehicleNum),
		},
		{
			title: 'Дата поставки',
			dataIndex: 'supplyDate',
			key: 'supplyDate',
			width: '9.7%',
			render: (supplyDate) => (supplyDate ? moment(supplyDate).format(dateFormat) : null),
			sorter: sorter('date', 'date'),
			onCell: getSupplyRowSpan,
			...getColumnSearchProps(fromServerFilters.supplyDate, undefined, null, true),
		},
		{
			title: 'Поставщик',
			dataIndex: 'providerLabel',
			key: 'providerLabel',
			width: '12.16%',
			onCell: getInvRowSpan,
			...getColumnSearchProps(fromServerFilters.providerLabel || []),
		},
		{
			title: 'ID накл.',
			dataIndex: 'invoiceId',
			key: 'invoiceId',
			width: '6.74%',
			sorter: sorter('number', 'invoiceId'),
			onCell: getInvRowSpan,
			...getColumnSearchProps(fromServerFilters.invoiceId || []),
		},
		{
			title: '№ накладной',
			dataIndex: 'invoiceDisplayCode',
			key: 'invoiceDisplayCode',
			width: '8.63%',
			onCell: getInvRowSpan,
			sorter: sorter('string', 'invoiceDisplayCode'),
			...getColumnSearchProps(fromServerFilters.invoiceDisplayCode || []),
		},
		{
			title: 'Партия №',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '6.68%',
			...getColumnSearchProps(fromServerFilters.partyNum),
		},
		{
			title: 'Продукт',
			key: 'prodLabel',
			dataIndex: ['prodLabel', 'displayVal'],
			width: '15.57%',
			onCell: getProdRowSpan,
			...getColumnSearchProps(fromServerFilters.prodLabel),
		},
		{
			title: 'Масса факт., кг',
			dataIndex: 'weightFact',
			key: 'supplyDatePlan',
			width: '7.8%',
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '9.47%',
			onCell: getInvRowSpan,
			...getColumnSearchProps(fromServerFilters.status),
			render: (val) => colorizeIntegrationListStatus(val),
		},
	]
}
