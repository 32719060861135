import React, { useContext, useEffect, useRef } from 'react'
import {
	everyOtherTimeUseEffectHandlerForList,
	startUseEffectHandlerForList,
} from '../../../../utils'
import { ListContext } from '../../../../contexts'
import { SyncDepsContext } from '../../../../contexts/global/sync-deps-context'

const dataUrl = '/production/tasks'
export const reducer = (state) => {
	return {
		...state,
	}
}

const ProductionTaskListMainContext = React.createContext()
ProductionTaskListMainContext.displayName = 'ProductionTaskListMainContext'

const Provider = (props) => {
	const { children } = props
	const {
		state: { page, pageSize, mainFilter, filters, sorter, dateFrom, dateTo, posType, taskKind },
		setTotal,
	} = useContext(ListContext)
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, dispatch] = React.useReducer(reducer, {
		fromServerSelectors: { allPosTypes: [] },
		fromServer: [],
		fromServerFilters: {
			workingTime: [],
			id: [],
			date: [],
			displayCode: [],
			idContrOrder: [],
			taskKind: [],
			operation: [],
			partyNum: [],
			articul: [],
			articul1C: [],
			productLabel: [],
			prodCatKind: [],
			status: [],
		},
		isInitialized: false,
		isLoading: false,
		fromServerTotalSum: 0,
	})

	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = newState
		dispatch({ ...state })
	}

	const setDateFrom = (val) => {
		executeDispatch({ ...stateRef.current, dateFrom: val })
	}

	const setDateTo = (val) => {
		executeDispatch({ ...stateRef.current, dateTo: val })
	}

	const getPosTypeFilters = () => {
		const matchLevel = []
		const matchPfLevel = []
		const approveLevel = []
		const approvePfLevel = []
		for (const f in posType) {
			if (f.includes('Match') && posType[f] !== '-') {
				if (f.includes('Pf') && posType[f] !== '-') {
					matchPfLevel.push(+posType[f])
				} else {
					matchLevel.push(+posType[f])
				}
			}
			if (f.includes('Approve') && posType[f] !== '-') {
				if (f.includes('Pf') && posType[f] !== '-') {
					approvePfLevel.push(+posType[f])
				} else {
					approveLevel.push(+posType[f])
				}
			}
		}
		return { matchLevel, matchPfLevel, approveLevel, approvePfLevel }
	}

	useEffect(() => {
		const posTypeFilters = getPosTypeFilters()
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				sorter,
				mainFilter,
				filters,
				offset: pageSize * page - pageSize,
				limit: pageSize,
				additionalFilters: {
					dateFrom,
					dateTo,
					...(taskKind?.value && { taskKind: [taskKind.value] }),
					...posTypeFilters,
				},
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [])
	useEffect(() => {
		const posTypeFilters = getPosTypeFilters()
		everyOtherTimeUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				offset: pageSize * page - pageSize,
				limit: pageSize,
				sorter,
				mainFilter,
				filters,
				additionalFilters: {
					dateFrom,
					dateTo,
					...(taskKind?.value && { taskKind: [taskKind.value] }),
					...posTypeFilters,
				},
				search: stateRef.current.search,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [
		page,
		sorter,
		mainFilter,
		filters,
		dateFrom,
		dateTo,
		taskKind,
		posType,
		stateRef.current.search,
		syncDepsCtx.state.reloadUuids['office-ms'],
	])
	const selectors = {
		allPosTypes: [
			{ displayVal: 'Не выбрано', value: null, id: null, isEmpty: true },
			...stateRef.current.fromServerSelectors.allPosTypes,
		],
		allTaskKinds: [
			{ displayVal: 'Не выбрано', value: null, id: null, isEmpty: true },
			{ id: 1, displayVal: 'Производство ГП', value: 'Производство ГП' },
			{ id: 2, displayVal: 'Производство ПФ', value: 'Производство ПФ' },
		],
	}
	const toDisplayDataList = () => {
		return stateRef.current.fromServer
	}

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}

	const finishList = toDisplayDataList()
	const listTotalSum = finishList.reduce(
		(sum, cur) => sum + cur.complectations.reduce((sum2, cur2) => sum2 + cur2.weight, 0),
		0
	)

	const value = {
		state: stateRef.current,
		toDisplayDataList,
		setSearch,
		fromServerTotalSum: stateRef.current.fromServerTotalSum,
		setDateFrom,
		setDateTo,
		listTotalSum,
		selectors,
	}

	return (
		<ProductionTaskListMainContext.Provider value={value}>
			{children}
		</ProductionTaskListMainContext.Provider>
	)
}

export { Provider, ProductionTaskListMainContext }
