import React, { useContext } from 'react'
import { Layout } from 'antd'
import Logo from './Logo'
import Items from './Items'
import { MenuContext, menuPositions } from '../../contexts'

const { Sider } = Layout

const Menu = ({ collapsed }) => {
	const { position } = useContext(MenuContext)
	return position === menuPositions.left ? (
		<Sider
			width={250}
			collapsed={collapsed}
			id="leftMenu"
			style={{
				overflow: 'auto',
				position: 'sticky',
				top: 0,
				left: 0,
			}}
		>
			<Logo />
			<Items />
		</Sider>
	) : (
		<Items />
	)
}

export default Menu
