import React from 'react'
import { WarningRed, WarningYellow } from '../../assets'
import { Col, Row, Space, Tooltip } from 'antd'
import moment from 'moment'
import { dateFormat } from '../../utils/constants/common'

const getShelfLifeInDays = (prod) => {
	return prod.ctrlMeasure.labelShort === 'мес'
		? moment(prod.ctrlDate, 'YYYY-MM-DD')
				.add(prod.ctrlShelfLife, 'months')
				.diff(moment(prod.ctrlDate, 'YYYY-MM-DD'), 'days')
		: prod.ctrlShelfLife
}

const ShelfLifeDateCell = ({ date, stockNotify, supplProd }) => {
	const shelfLifeInDays = supplProd ? getShelfLifeInDays(supplProd) : 730
	const dateForRed = moment(date).subtract(
		(shelfLifeInDays * Number(stockNotify.stockNotifyRed)) / 100,
		'days'
	)
	const dateForYellow = moment(date).subtract(
		(shelfLifeInDays * Number(stockNotify.stockNotifyYellow)) / 100,
		'days'
	)
	const component = moment(dateForRed).isBefore(moment()) ? (
		<WarningRed
			style={{
				verticalAlign: 'middle',
			}}
		/>
	) : moment(dateForYellow).isBefore(moment()) ? (
		<WarningYellow
			style={{
				verticalAlign: 'middle',
			}}
		/>
	) : null
	const diffMonth = moment(date).diff(moment(), 'month')
	const diffDay = moment(date).diff(moment().add(diffMonth, 'months'), 'day')
	const title = (
		<Space direction="vertical">
			<Row justify="center">
				<Col>Остаточный срок годности:</Col>
			</Row>
			<Row justify="center">
				<Col>{`${diffMonth} мес ${diffDay} суток`}</Col>
			</Row>
		</Space>
	)
	return (
		<Space
			style={{
				verticalAlign: 'middle',
			}}
		>
			{component && (
				<Tooltip style={{ justifyContent: 'center' }} title={title}>
					{component}
				</Tooltip>
			)}
			{moment(date).format(dateFormat)}
		</Space>
	)
}

export default ShelfLifeDateCell
