import React, { useContext } from 'react'
import { Card } from 'antd'
import { Provider, StockOpsDisposalListMainContext } from './provider/main'
import { Main } from '../../../../layouts'
import { HeaderMenu, StatefulTable } from '../../../../components'
import { getColumns } from './stock-ops-disposal-list'
import { goToItem } from '../../../../utils'
import { useHistory } from 'react-router-dom'

const pageUrl = '/stock-operations/disposals'

const StockOpsDisposalList = () => (
	<Provider>
		<Main title="Утилизации">
			<StockOpsDisposalListTable />
		</Main>
	</Provider>
)

const StockOpsDisposalListTable = () => {
	const mainCtx = useContext(StockOpsDisposalListMainContext)
	const history = useHistory()
	return (
		<>
			<HeaderMenu
				rights={{
					'АРМ офиса': {
						Утилизации: {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle="Утилизации"
				search
				canCreate={true}
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					columnProps={{ fromServerFilters: mainCtx.state.fromServerFilters }}
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					searchFields={mainCtx.searchFields}
					url={pageUrl}
				/>
			</Card>
		</>
	)
}

export default StockOpsDisposalList
