import React, { useContext, useEffect, useRef } from 'react'
import { ListContext, SyncDepsContext } from '../../../../../contexts'
import {
	startUseEffectHandlerForList,
	everyOtherTimeUseEffectHandlerForList,
} from '../../../../../utils'

const dataUrl = '/voc/editable/main-semifinished-kind'

export const reducer = (state) => {
	return {
		...state,
	}
}

const MainSemiKindListContext = React.createContext()
MainSemiKindListContext.displayName = 'MainSemiKindListContext'

const Provider = (props) => {
	const { children } = props
	const {
		state: { page, pageSize, mainFilter, filters, sorter },
		setTotal,
	} = useContext(ListContext)
	const syncDepsCtx = useContext(SyncDepsContext)

	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: {},
		isInitialized: false,
		isLoading: false,
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	useEffect(() => {
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: { pageLimit: pageSize, sorter, mainFilter, filters },
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [])
	useEffect(() => {
		everyOtherTimeUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				offset: pageSize * page - pageSize,
				limit: pageSize,
				sorter,
				mainFilter,
				filters,
				search: stateRef.current.search,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [
		page,
		sorter,
		mainFilter,
		filters,
		stateRef.current.search,
		syncDepsCtx.state.reloadUuids['office-ms'],
		stateRef.current.search,
	])

	const toDisplayDataList = () => {
		return stateRef.current.fromServer
	}

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}

	const value = {
		state: stateRef.current,
		toDisplayDataList: toDisplayDataList,
		setSearch: setSearch,
	}

	return (
		<MainSemiKindListContext.Provider value={value}>{children}</MainSemiKindListContext.Provider>
	)
}

export { Provider, MainSemiKindListContext }
