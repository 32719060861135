import { EditDelBtns, InputError } from '../../../../../components'
import { modes } from '../../../../../utils'
import { setNumField } from '@berry/front-common/for-fields'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */

export const getDisplayColumns = (modCtx, mainCtx, productionFunctions) => {
	const disabled = modCtx.mod === modes.view
	return [
		{
			title: '№ партии',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '10%',
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			key: 'articul',
			width: '10%',
		},
		{
			title: 'Артикул 1С',
			dataIndex: 'articul1C',
			key: 'articul1C',
			width: '10%',
		},
		{
			title: 'Продукт',
			dataIndex: 'prodCat',
			key: 'prodCat',
			width: '15%',
		},
		{
			title: 'Вид',
			dataIndex: 'prodCatKind',
			key: 'prodCatKind',
			width: '20%',
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '10%',
			render: (val, record) => (
				<InputError
					showCaption={!!record.prodCat}
					hasError={!!mainCtx.formErrors?.[`weight.${record._uuid_}`]}
					message={`Доступная масса ${mainCtx.getAvailableWeight(record)} кг`}
					disabled={disabled}
					style={{ width: '100%' }}
					value={val || ''}
					onChange={(e) => {
						setNumField(e.target.value, val, productionFunctions.setWeight, 'float', [9, 2], {
							_uuid_: record._uuid_,
							isOnlyNums: true,
						})
					}}
				/>
			),
		},
		...(modCtx.mod !== modes.view
			? [
					{
						title: null,
						key: 'action',
						width: '3.5%',
						render: (record) => {
							return (
								<EditDelBtns
									record={record}
									canDelete={!disabled}
									onDelete={(record) => {
										productionFunctions.delete(record._uuid_)
										mainCtx.setDeletedProds(record)
									}}
								/>
							)
						},
					},
			  ]
			: []),
	]
}
