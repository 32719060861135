import { useContext } from 'react'
import { Button, Card, Col, Modal, Row, Table, Typography } from 'antd'
import { RequestItemMainContext } from '../provider/main'
import { ReactComponent as CloseIcon } from '../../../../assets/svg/close.svg'
import { getColumns } from './select-order-modal'
import { showConfirmModal } from '../../../../components'
import { validateRequired } from '../../../../utils'
import { getOrderStatus } from '../../../../utils/helpers/cross-pages-funcs'

const SelectOrderModal = () => {
	const mainCtx = useContext(RequestItemMainContext)
	let tableData = []
	let columns = []
	if (mainCtx.addOrder.__isOpen) {
		const foundProd = mainCtx.state.products.find((e) => e._uuid_ === mainCtx.addOrder.uuidProd)
		if (!foundProd) return null
		const toDisplayData = foundProd.contract.orders
			.map((or) => {
				or.status = getOrderStatus(or, mainCtx.additional.allRequests)
				return or
			})
			?.filter((e) => e.status === 'Новый')
		tableData = toDisplayData
		columns = getColumns(mainCtx, toDisplayData)
	}

	return (
		<Modal
			visible={mainCtx.addOrder.__isOpen}
			destroyOnClose
			centered
			bodyStyle={{ paddingTop: '10px' }}
			footer={null}
			closable={false}
			width="90%"
		>
			<Card
				bodyStyle={{
					margin: 0,
					padding: 0,
				}}
				bordered={false}
			>
				<Row gutter={[16, 16]} style={{ padding: '0 0 10px 0' }}>
					<Col flex={1}>
						<Typography.Title level={4} style={{ margin: 0 }}>
							Заказы
						</Typography.Title>
					</Col>
					<Col>
						<Button
							type="primary"
							onClick={() => {
								try {
									mainCtx.validateOrder()
									for (const [index, order] of mainCtx.addOrder.addOrderOrders.entries()) {
										const foundProduct = mainCtx.state.products.find(
											(prod) => prod._uuid_ === mainCtx.addOrder.uuidProd
										)
										if (index === 0) {
											mainCtx.stateFunctions.products.edit(foundProduct._uuid_, {
												...foundProduct,
												weight: order.supplyPlanWeight,
												order: order,
											})
											validateRequired(mainCtx.setError, `order.${foundProduct._uuid_}`, '', order)
										} else {
											mainCtx.stateFunctions.products.create({
												...foundProduct,
												weight: order.supplyPlanWeight,
												order: order,
											})
										}
									}
									mainCtx.modalFunctions.orderModalFunctions.resetAddOrder()
								} catch (err) {
									return showConfirmModal({
										title: err.message,
										showCancel: false,
										okText: 'Ок',
									})
								}
							}}
						>
							Сохранить
						</Button>
					</Col>
					<Col>
						<Button
							type="text"
							style={{ lineHeight: 0.8 }}
							icon={<CloseIcon />}
							onClick={() => {
								mainCtx.modalFunctions.orderModalFunctions.resetAddOrder()
							}}
						/>
					</Col>
				</Row>
			</Card>
			<Table
				rowKey={(rec) => {
					return `${rec.id || rec._uuid_}_${rec.indxMain}_${
						rec.isDiplayedRowBySpan ? 'displ' : 'not_displ'
					}`
				}}
				dataSource={tableData}
				columns={columns}
				pagination={false}
				scroll={{ x: 800 }}
				size="small"
			/>
		</Modal>
	)
}
export default SelectOrderModal
