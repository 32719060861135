import { getColumnSearchProps } from '../../../../utils'
import {
	BlockOutlined,
	CloseSquareOutlined,
	RightSquareOutlined,
	UndoOutlined,
	RotateRightOutlined,
} from '@ant-design/icons'
import { Row } from 'antd'
import { colorizeIntegrationListStatus } from '../../../../utils/helpers/for-integration'
import { LeftTooltip, ShelfLifeDateCell, Status } from '../../../../components'
import clickableStatus from '../../../../utils/helpers/clickableStatus'

export const getModalTitle = (mainCtx) => {
	if (mainCtx.removeRegisterModal.__isOpen) {
		return 'Снять с учета'
	}
	if (mainCtx.relocationModal.__isOpen) {
		return 'Перемещение'
	}
	if (mainCtx.repackModal.__isOpen) {
		return 'Перетарка'
	}
	return 'Смена статуса партии'
}

/**
 * @param {Object} inArr - массив с записями таблицы
 */

export const getColumns = (props) => {
	const {
		mainCtx,
		canEdit,
		fromServerFilters,
		frontFilters,
		sorter,
		authCtx,
		userDataCtx,
		stockNotify,
	} = props
	let items = [
		{
			title: 'ID ДЗ',
			sorter: true,
			sortOrder: sorter.columnKey === 'idContrOrder' && sorter.order,
			dataIndex: 'idContrOrder',
			key: 'idContrOrder',
			width: '2%',
			...getColumnSearchProps(fromServerFilters.idContrOrder, frontFilters, 'idContrOrder'),
		},
		{
			title: 'Партия №',
			dataIndex: 'assignedPartyNumForSorter',
			key: 'partyNum',
			width: '8.1%',
			sorter: true,
			sortOrder: sorter.columnKey === 'partyNum' && sorter.order,
			render: (_, record) => record.partyNum,
			...getColumnSearchProps(fromServerFilters.partyNum, frontFilters, 'partyNum'),
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			...getColumnSearchProps(fromServerFilters.articul, frontFilters, 'articul'),
			key: 'articul',
			width: '5%',
		},
		{
			title: 'Артикул 1C',
			dataIndex: 'articul1C',
			...getColumnSearchProps(fromServerFilters.articul1C, frontFilters, 'articul1C'),
			key: 'articul1C',
			width: '5%',
		},
		{
			title: 'Продукт',
			dataIndex: 'prodCat',
			key: 'prodCat',
			...getColumnSearchProps(fromServerFilters.prodCat, frontFilters, 'prodCat'),
			sorter: true,
			sortOrder: sorter.columnKey === 'prodCat' && sorter.order,
			width: '5%',
		},
		{
			title: 'Вид',
			dataIndex: 'prodCatKind',
			key: 'prodCatKind',
			width: '5%',
			sorter: true,
			sortOrder: sorter.columnKey === 'prodCatKind' && sorter.order,
			...getColumnSearchProps(fromServerFilters.prodCatKind, frontFilters, 'prodCatKind'),
		},
		{
			title: 'Тип уп.',
			dataIndex: 'pkgType',
			key: 'pkgType',
			width: '6%',
			...getColumnSearchProps(fromServerFilters.pkgType, frontFilters, 'pkgType'),
		},
		{
			title: 'Масса нетто ед. уп., кг',
			dataIndex: 'nettoPkgWeight',
			key: 'nettoPkgWeight',
			width: '7%',
			...getColumnSearchProps(fromServerFilters.nettoPkgWeight, frontFilters, 'nettoPkgWeight'),
		},
		{
			title: 'Поставщик',
			dataIndex: 'provider',
			key: 'provider',
			width: '14%',
			...getColumnSearchProps(fromServerFilters.provider, frontFilters, 'provider'),
		},
		{
			title: 'Производитель',
			dataIndex: 'manuf',
			key: 'manuf',
			width: '6.9%',
			...getColumnSearchProps(fromServerFilters.manuf, frontFilters, 'manuf'),
		},
		{
			title: 'Годен до',
			dataIndex: 'sellBy',
			key: 'sellBy',
			width: '5.87%',
			render: (date, record) => (
				<ShelfLifeDateCell date={date} stockNotify={stockNotify} supplProd={record.supplProd} />
			),
			sorter: true,
			sortOrder: sorter.columnKey === 'sellBy' && sorter.order,
		},
		{
			title: '№ пом',
			dataIndex: 'roomNum',
			key: 'roomNum',
			width: '6%',
			...getColumnSearchProps(fromServerFilters.roomNum, frontFilters, 'roomNum'),
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '6%',
			sorter: true,
			sortOrder: sorter.columnKey === 'weight' && sorter.order,
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '6%',
			...getColumnSearchProps(fromServerFilters.status, frontFilters, 'status'),
			render: (status, record) => {
				if (['Удержано', 'Заблокировано'].includes(status)) {
					return (
						<div style={{ display: 'flex' }}>
							<LeftTooltip
								label={''}
								tooltip={
									<span style={{ whiteSpace: 'pre-wrap', wdith: '100%' }}>
										{record.blockCauses.map((p) => p.blockCause.label + '\n').join('')}
									</span>
								}
							/>
							<Status style={{ marginLeft: '3px' }} status={status} />
						</div>
					)
				}
				return colorizeIntegrationListStatus(record.status, false, {
					marginLeft: '17px',
				})
			},
			onCell: clickableStatus(authCtx, {}, userDataCtx),
		},
		...(canEdit
			? [
					{
						title: '',
						dataIndex: 'removeRegister',
						key: 'removeRegister',
						width: '8%',
						render: (_, record) =>
							[
								'Допущено',
								'Удержано',
								'Заблокировано',
								'Ответ. хранение',
								'Неполный комплект документов',
								'Дефростация',
								'Отклонено 1С',
								'Ошибка отправки в 1С',
							].includes(record.status) && canEdit ? (
								<Row style={{ justifyContent: 'space-around', fontSize: 21, flexWrap: 'nowrap' }}>
									{['Допущено', 'Удержано', 'Заблокировано'].includes(record.status) && (
										<RotateRightOutlined
											title="Смена статуса партии"
											className={'pictogram'}
											onClick={(e) => {
												e.stopPropagation()
												mainCtx.modalFunctions.openChangeStatusModal()
												mainCtx.modalFunctions.setChangeStatusModalStorage(record)
											}}
										/>
									)}
									{['Отклонено 1С', 'Ошибка отправки в 1С'].includes(record.status) ? (
										<UndoOutlined
											title="Вернуть статус"
											className={'pictogram'}
											onClick={(e) => {
												e.stopPropagation()
												mainCtx.dropStatus(record)
											}}
										/>
									) : (
										<>
											<CloseSquareOutlined
												title="Возврат"
												className={'pictogram'}
												onClick={(e) => {
													e.stopPropagation()
													mainCtx.modalFunctions.openRemoveRegisterModal()
													mainCtx.modalFunctions.setRemoveRegisterModalStorage(record)
													mainCtx.modalFunctions.setRemoveRegisterModalSale(false)
													mainCtx.modalFunctions.setRemoveRegisterModalRefund(true)
												}}
											/>
											<BlockOutlined
												title="Перетарка"
												className={'pictogram'}
												onClick={(e) => {
													e.stopPropagation()
													mainCtx.modalFunctions.openRepackModal()
													mainCtx.modalFunctions.setRepackModalStorage(record)
												}}
											/>
											<RightSquareOutlined
												title="Перемещение"
												className={'pictogram'}
												onClick={(e) => {
													e.stopPropagation()
													mainCtx.modalFunctions.openRelocationModal()
													mainCtx.modalFunctions.setRelocationModalStorage(record)
												}}
											/>
										</>
									)}
								</Row>
							) : null,
					},
			  ]
			: []),
	]
	return items
}

const xlsxColumnTitles = ['Партия №', 'Артикул', 'Артикул 1C', 'Продукт', 'Вид', 'Масса, кг']

export const getToXlsx = (dataSource, columns = [], renameColumnDataIndexToKey = []) => {
	if (renameColumnDataIndexToKey.length)
		columns.forEach((c) => {
			if (renameColumnDataIndexToKey.includes(c.dataIndex)) c.dataIndex = c.key
		})
	return {
		dataSource,
		columns: columns.filter((c) => xlsxColumnTitles.includes(c.title)),
		tableName: 'Реестр запасов сырья',
		fileName: 'Реестр_запасов_Сырье.xlsx',
	}
}
