import { Form, Table } from 'antd'
import React, { useState, useContext } from 'react'
import lodash from 'lodash'
import { MainSemiKindItemContext } from '../provider/main'
import { RecordItemModal, TabItem, CustomForm } from '../../../../../components'
import { AuthContext, ModContext } from '../../../../../contexts'
import { modes } from '../../../../../utils'
import { getDisplayColumns, getFormItems } from './kind-tab'

const KindTab = () => {
	const mainCtx = useContext(MainSemiKindItemContext)
	const authCtx = useContext(AuthContext)
	const stateFunctions = mainCtx.stateFunctions.kinds
	const modCtx = useContext(ModContext)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [form] = Form.useForm()

	/**
	 *  {_uuid_:string,isNew:boolean } itemForModal
	 */
	const [itemForModal, setItemForModal] = useState(null)

	return (
		<TabItem
			addButton={{
				disabled: false,
				show: true,
				onClick: () => {
					const newUuid = stateFunctions.create()
					setItemForModal({ _uuid_: newUuid, isNew: true })
					setIsModalOpen(true)
				},
			}}
			modal={() => {
				return (
					<RecordItemModal
						formInstance={form}
						formName="kind"
						isOpen={isModalOpen}
						title="Вид основного ПФ"
						item={itemForModal}
						deleteFun={stateFunctions.delete}
						setItem={setItemForModal}
						setModalState={setIsModalOpen}
						editFun={stateFunctions.edit}
						form={() => {
							if (!itemForModal) return null
							const found = stateFunctions.get(itemForModal._uuid_)
							return (
								<CustomForm
									name="kind"
									form={form}
									fields={[
										{ name: 'label', value: found.label },
										{ name: 'isCaliber', value: found.isCaliber },
										{
											name: 'isAdditAttr',
											value: found.isAdditAttr,
										},
									]}
									columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
									items={getFormItems(mainCtx, modCtx, itemForModal)}
								/>
							)
						}}
					/>
				)
			}}
			table={() => {
				return (
					<Table
						size="small"
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(mainCtx, modCtx, authCtx, (record) => {
							if (modCtx.mod === modes.view) return
							setItemForModal({ _uuid_: record._uuid_, prevData: lodash.cloneDeep(record) })
							setIsModalOpen(true)
						})}
						dataSource={mainCtx.state.kinds}
						pagination={false}
						scroll={{ x: 800 }}
					/>
				)
			}}
		></TabItem>
	)
}

export default KindTab
