import {
	getProdNameByProviderQuota,
	numToFixed,
} from '@berry/common-functions/cross-project-functions'
import { Checkbox } from 'antd'
import { getColumnSearchProps, getRowSpan } from '../../../../utils'
import { getRemainder, renderPlanFactCellV2 } from '../../../Contract/utils'

export const getTotalProdWeight = (products, record) =>
	products
		.filter((p) => p.contrQuota?.id === record.id)
		.reduce((sum, cur) => sum + (+cur.weight || 0), 0)

/**
 * Столбцы для таблицы Распределение квоты пм Сырье/Договоры.Поставщики
 * @param {Object} mainCtx - главный контекст
 * @param {Object} modCtx - контекст мода
 * @param {Array<Object>} preparedData - данные которые идут на вход таблице
 */
export const getDisplayColumnsV2 = (mainCtx, preparedData, filters) => {
	const foundProduct = mainCtx.state.products.find(
		(p) => p._uuid_ === mainCtx.addContrQuota.uuidProd
	)
	const modalFunctions = mainCtx.modalFunctions.contrQuotaModalFunctions
	const result = [
		{
			key: 'checkbox',
			width: '2%',
			onCell: (record) => ({
				rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
			}),
			render: (_, record) => (
				<Checkbox
					checked={mainCtx.addContrQuota.addContrQuotaContrQuotas.find((q) => q.id === record.id)}
					disabled={!getRemainder(record)}
					onChange={(e) => {
						if (e.target.checked) {
							modalFunctions.addContrQuotaContrQuotas.create(record)
						} else {
							modalFunctions.addContrQuotaContrQuotas.delete(
								mainCtx.addContrQuota.addContrQuotaContrQuotas.find((q) => q.id === record.id)
									._uuid_
							)
						}
					}}
				/>
			),
		},
	]
	if (foundProduct.contract.frame) {
		result.push({
			title: '№ ДС',
			dataIndex: ['application', 'displayCode'],
			key: 'applicationNum',
			width: '5%',
			onCell: (record, index) => ({
				rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
			}),
			...getColumnSearchProps(filters['applicationNum']),
		})
	}
	result.push(
		...[
			{
				title: 'Продукт',
				key: 'rmProvProd',
				width: '13%',
				ellipsis: true,
				render: (record) =>
					record.rmProvProd?.displayVal || getProdNameByProviderQuota(record.rmProvProd),
				onCell: (record, index) => ({
					rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
				}),
				...getColumnSearchProps([...new Set(preparedData?.map((e) => e.prodLabel))], 'prodLabel'),
			},
			{
				title: 'Остаток, кг',
				key: 'remainder',
				width: '6%',
				render: (_, record) =>
					numToFixed(getRemainder(record) - getTotalProdWeight(mainCtx.state.products, record), 2),
				onCell: (record) => ({
					rowSpan: record.isDiplayedRowBySpan ? getRowSpan(record, record.years.length) * 2 : 0,
				}),
			},
		]
	)

	result.push(
		...[
			{
				title: 'Год',
				dataIndex: 'year',
				key: 'year',
				width: '4%',
				...getColumnSearchProps([...new Set(preparedData.map((d) => d.year.toString()))], 'year'),
				onCell: (record) => ({
					rowSpan: record.isDiplayedRowBySpan ? 2 : 0,
				}),
			},
			{
				title: null,
				key: 'monthlyStart',
				width: '5%',
				render: (_, record) => {
					if (record.isPlan) return 'П'
					if (record.isFact) return 'Ф'
				},
			},

			{
				title: 'Янв',
				width: '5%',
				dataIndex: ['monthlyListing', 'month0'],
				key: 'monthlyListingMonth0',
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '0', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Фев',
				width: '5%',
				dataIndex: ['monthlyListing', 'month1'],
				key: 'monthlyListingMonth1',
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '1', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Мар',
				width: '5%',
				key: 'monthlyListingMonth2',
				dataIndex: ['monthlyListing', 'month2'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '2', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Апр',
				width: '5%',
				key: 'monthlyListingMonth3',
				dataIndex: ['monthlyListing', 'month3'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '3', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Май',
				width: '5%',
				key: 'monthlyListingMonth4',
				dataIndex: ['monthlyListing', 'month4'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '4', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Июн',
				width: '5%',
				key: 'monthlyListingMonth5',
				dataIndex: ['monthlyListing', 'month5'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '5', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Июл',
				width: '5%',
				key: 'monthlyListingMonth6',
				dataIndex: ['monthlyListing', 'month6'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '6', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Авг',
				width: '5%',
				key: 'monthlyListingMonth7',
				dataIndex: ['monthlyListing', 'month7'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '7', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Сен',
				width: '5%',
				key: 'monthlyListingMonth8',
				dataIndex: ['monthlyListing', 'month8'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '8', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Окт',
				width: '5%',
				key: 'monthlyListingMonth9',
				dataIndex: ['monthlyListing', 'month9'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '9', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Ноя',
				width: '5%',
				key: 'monthlyListingMonth10',
				dataIndex: ['monthlyListing', 'month10'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '10', {
						onlyView: true,
					})
				},
			},
			{
				title: 'Дек',
				width: '5%',
				key: 'monthlyListingMonth11',
				dataIndex: ['monthlyListing', 'month11'],
				render: (val, rec) => {
					return renderPlanFactCellV2(val, rec, mainCtx, {}, 'providerQuotas', '11', {
						onlyView: true,
					})
				},
			},
		]
	)
	return result
}
