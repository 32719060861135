import lodash from 'lodash'
import { axios } from '.'
import { deepFind } from './for-provider'
import { getBreadcrumbsText } from './generators'
/**
 * Можно ли удалить данный элемент
 * @param {Array<object>} inParams
 * @param {object} dataServerCtx
 */
export const canDelete = (inParams, dataDeps) => {
	if (!inParams) throw Error('Неправильные параметры')
	if (!Object.keys(inParams).length) throw Error('пустые модели')
	if (Object.values(inParams).every((m) => !m?.length)) throw Error('пустые айдишники')
	const result = Object.entries(inParams).every(([m, ids]) => {
		const modelRes = ids.every((id) => {
			const deps = lodash.get(dataDeps, [m, String(id)])
			if (deps) {
				for (let v of Object.values(deps)) {
					if (v.length) return false
				}
			}
			return true
		})
		return modelRes
	})
	return result
}

/**
 * Можно ли удалить данный элемент
 * @param {{id: string,url: string} } inParams
 */
export const getDepsList = async ({ id, url, model }) => {
	if (!id || !url || !model) throw Error('Неправильные параметры')
	const res = await axios(`${url}/delete-deps`, {
		params: {
			id,
			model,
		},
	})
	return res.data?.result?.[model]?.[id] || []
}

/**
 * Получить массив зависимостей для элемента вкладки
 * @param {string | number} inId
 * @param {string } field - поле с данными
 * @param {string } model - название модели
 * @param {object } inOldData - обьект откуда брать старые значения
 * @param {object} dataServerCtx
 */
export const getDepsForTab = (inId, field, model, inOldData, dataServerCtx, options) => {
	if (!inId) return []
	try {
		const foundFromServer = deepFind([field], [inId], inOldData, { key: 'id' })
		if (!foundFromServer) return []
	} catch (err) {
		// новый обьект и его нет на секрвере значит от
		// него ничего не зависит
		return []
	}
	const result = getDepsList(
		{
			[model]: [inId],
		},
		dataServerCtx,
		options
	)
	return result
}

// на каждую моджекль по ссылке
export const getUrlsAndLabelsForDeps = (inDeps = {}, dataServerCtx, options) => {
	return Object.entries(inDeps)
		.map(([m, ids]) => {
			return getUrlsAndLabelsForModel(m, ids, dataServerCtx, options)
		})
		.filter((e) => e !== null)
		.flat()
}

/**
 * На основе модели понять в каком поле с данными она хранится и получить для айдишников
 * этой модели все ссылки
 * @param {string } inModel
 * @param {Array<string> } ids - обьект с данными конкретного элемента пункта менбю
 * @param {object} dataServerCtx
 */
const getUrlsAndLabelsForModel = (inModel, ids, dataServerCtx, options) => {
	switch (inModel) {
		case 'prod_cat_addit_attr': {
			const urlData = getDataForLink('additAttrs', ids, '/product-catalog', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/pv/product-catalog')
		}
		case 'rm_suppl_doc': {
			const urlData = getDataForLink('additAttrs', ids, '/rm/supplies', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rm/supplies')
		}
		case 'prod_cat_caliber': {
			const urlData = getDataForLink('calibers', ids, '/product-catalog', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/pv/product-catalog')
		}
		case 'prod_cat_kind_ready_prod': {
			const urlData = getDataForLink('kindReadyProds', ids, '/product-catalog', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/pv/product-catalog')
		}
		case 'prod_cat_kind_semif': {
			const urlData = getDataForLink('kindSemifs', ids, '/product-catalog', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/pv/product-catalog')
		}
		case 'prod_cat_kind_raw_mat': {
			const urlData = getDataForLink('kindRawMats', ids, '/product-catalog', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/pv/product-catalog')
		}
		case 'prod_cat_package': {
			const urlData = getDataForLink('packages', ids, '/product-catalog', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/pv/product-catalog')
		}
		case 'product_catalog': {
			const urlData = getDataForLink('', ids, '/product-catalog', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/pv/product-catalog')
		}

		case 'voc_editable_main_semi_kind': {
			const urlData = getDataForLink('', ids, '/voc/editable/main-semifinished-kind', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/vocabularies/main-semifinished-kind')
		}
		case 'voc_main_semi_kind_kind': {
			const urlData = getDataForLink(
				'kinds',
				ids,
				'/voc/editable/main-semifinished-kind',
				dataServerCtx
			)
			return generateObjWithUrlAndText(urlData, '/vocabularies/main-semifinished-kind')
		}

		case 'voc_spec_param': {
			const urlData = getDataForLink(
				'',
				ids,
				'/voc/editable/specification-parameter',
				dataServerCtx
			)
			return generateObjWithUrlAndText(urlData, '/vocabularies/specification-parameter')
		}
		case 'voc_spec_param_param': {
			const urlData = getDataForLink(
				'params',
				ids,
				'/voc/editable/specification-parameter',
				dataServerCtx
			)
			return generateObjWithUrlAndText(urlData, '/vocabularies/specification-parameter')
		}

		case 'rp_rw_contract': {
			const dataFieldsUrls = [
				{
					field: '',
					url: '/rm/contracts-providers',
					dataUrl: '/contract',
					filter: (e) => e.provider,
				},
				{
					field: '',
					url: '/rp/contracts-customers',
					dataUrl: '/contract',
					filter: (e) => e.customer,
				},
			]
			return findForMultipleUrlFields(dataFieldsUrls, ids, dataServerCtx)
		}
		case 'contr_customer_quota': {
			const urlData = getDataForLink('customerQuotas', ids, '/contract', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rp/contracts-customers')
		}
		case 'contr_provider_quota': {
			const urlData = getDataForLink('providerQuotas', ids, '/contract', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rm/contracts-providers')
		}
		case 'application': {
			const dataFieldsUrls = [
				{
					field: 'applications',
					url: '/rm/contracts-providers',
					dataUrl: '/contract',
				},
				{
					field: 'applications',
					url: '/rp/contracts-customers',
					dataUrl: '/contract',
				},
			]
			return findForMultipleUrlFields(dataFieldsUrls, ids, dataServerCtx)
		}
		case 'contr_prov_order': {
			const urlData = getDataForLink('orders', ids, '/contract', dataServerCtx, {
				provider: true,
			})
			return generateObjWithUrlAndText(urlData, '/rm/contracts-providers')
		}
		case 'prov_quota_mnth_lstng': {
			const urlData = getDataForLink(
				['providerQuotas', 'monthlyListings'],
				ids,
				'/contract',
				dataServerCtx
			)
			return generateObjWithUrlAndText(urlData, '/rm/contracts-providers')
		}
		case 'cstmr_quota_mnth_lstng': {
			const urlData = getDataForLink(
				['customerQuotas', 'monthlyListings'],
				ids,
				'/contract',
				dataServerCtx
			)
			return generateObjWithUrlAndText(urlData, '/rp/contracts-customers')
		}

		case 'rm_provider': {
			const urlData = getDataForLink('platform', ids, '/rm/provider', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rm/providers')
		}
		case 'rm_rp_contact': {
			const dataFieldsUrls = [
				{
					field: 'contacts',
					url: '/rm/providers',
					dataUrl: '/rm/providers',
				},
				{
					field: 'contacts',
					url: '/rp/customers',
					dataUrl: '/rp/customer',
				},
			]
			return findForMultipleUrlFields(dataFieldsUrls, ids, dataServerCtx)
		}
		case 'rp_rm_platform': {
			const dataFieldsUrls = [
				{
					field: 'platforms',
					url: '/rm/providers',
					dataUrl: '/rm/providers',
				},
				{
					field: 'platforms',
					url: '/rp/customers',
					dataUrl: '/rp/customer',
				},
			]
			return findForMultipleUrlFields(dataFieldsUrls, ids, dataServerCtx)
		}
		case 'rm_provider_prod': {
			const urlData = getDataForLink('products', ids, '/rm/providers', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rm/providers')
		}
		case 'rm_supply_request': {
			const urlData = getDataForLink('', ids, '/rm/request', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rm/supply-requests')
		}
		case 'rm_suppl_req_prod': {
			const urlData = getDataForLink('products', ids, '/rm/request', dataServerCtx, {
				pageUrl: '/rm/supply-requests',
			})
			return generateObjWithUrlAndText(urlData, '/rm/supply-requests')
		}
		case 'rm_supply_req_doc': {
			const urlData = getDataForLink('documents', ids, '/rm/request', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rm/supply-requests')
		}
		case 'rp_customer': {
			const urlData = getDataForLink('', ids, '/rp/customer', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rp/customers')
		}

		case 'reg_specification': {
			const urlData = getDataForLink('', ids, '/reg/specifications', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/reg/specifications')
		}
		case 'spec_param': {
			const urlData = getDataForLink('parameters', ids, '/reg/specifications', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/reg/specifications')
		}
		case 'spec_comment': {
			const urlData = getDataForLink('comments', ids, '/reg/specifications', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/reg/specifications')
		}
		case 'reg_staff': {
			const urlData = getDataForLink('', ids, '/reg/staff', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/reg/staff')
		}
		case 'rm_suppl_prod_unload_storage': {
			const urlData = getDataForLink('unldStorages', ids, '/rm/supply-unloads', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rm/supply-unloads')
		}
		case 'rm_supply': {
			const urlData = getDataForLink('', ids, '/rm/supplies', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rm/supplies')
		}
		case 'rm_suppl_prod': {
			if (options.modelForCheck === 'reg_staff') {
				const foundInputCtrls = lodash.uniqBy(
					findBySpecificFieldAdPrepare(
						options.id,
						'ctrlStaff',
						'/rm/supply-input-controls',
						dataServerCtx
					),
					'id'
				)
				const foundUnloads = lodash.uniqBy(
					findBySpecificFieldAdPrepare(
						options.id,
						'unldStaff',
						'/rm/supply-unloads',
						dataServerCtx
					),
					'id'
				)
				if (foundInputCtrls.length || foundUnloads.length)
					return [...foundInputCtrls, ...foundUnloads]
			}
			const urlData = getDataForLink('products', ids, '/rm/supplies', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rm/supplies')
		}
		case 'supl_prod_event_history': {
			const urlData = getDataForLink(
				'eventHistories',
				ids,
				'/rm/supply-input-controls',
				dataServerCtx
			)
			return generateObjWithUrlAndText(urlData, '/rm/supply-input-controls')
		}
		case 'spec_event_history': {
			const urlData = getDataForLink('eventHistories', ids, '/reg/specifications', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/reg/specifications')
		}
		case 'stock_raw_mat': {
			return []
		}
		case 'stock_raw_mat_storage': {
			return []
		}
		case 'stock_lab_indic_photo': {
			return []
		}
		case 'stock_raw_mat_lab_indic': {
			return []
		}
		case 'stock_raw_mat_match_history': {
			return []
		}
		case 'stock_semif': {
			return []
		}
		case 'stock_semif_storage': {
			return []
		}
		case 'stock_semif_match_history': {
			return []
		}
		case 'stock_waste': {
			return []
		}
		case 'stock_waste_storage': {
			return []
		}
		case 'stock_waste_match_history': {
			return []
		}
		case 'stock_sample': {
			return []
		}
		case 'stock_sample_storage': {
			return []
		}
		case 'stock_sample_match_history': {
			return []
		}
		case 'stock_ready_prod': {
			return []
		}
		case 'stock_ready_prod_storage': {
			return []
		}
		case 'stock_ready_prod_match_history': {
			return []
		}
		case 'res_lab_indic_photo': {
			return []
		}
		case 'prod_rep_block_cause': {
			const urlData = getDataForLink(
				['reports', 'blockCauses'],
				ids,
				'/production/tasks',
				dataServerCtx,
				true
			)
			return generateObjWithUrlAndText(urlData, '/production/tasks')
		}
		case 'stock_ready_prod_storage_block_cause': {
			const urlData = getDataForLink(
				['storages', 'blockCauses'],
				ids,
				'/stock/ready-prods',
				dataServerCtx,
				true
			)
			return generateObjWithUrlAndText(urlData, '/stock/ready-prods')
		}
		case 'stock_semif_storage_block_cause': {
			const urlData = getDataForLink(
				['storages', 'blockCauses'],
				ids,
				'/stock/semif',
				dataServerCtx,
				true
			)
			return generateObjWithUrlAndText(urlData, '/stock/semif')
		}
		case 'prdctn_task_plan': {
			const urlData = getDataForLink('plans', ids, '/production/tasks', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/production/tasks')
		}
		case 'prdctn_task': {
			const urlData = getDataForLink('', ids, '/production/tasks', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/production/tasks')
		}
		case 'prdctn_task_report': {
			const urlData = getDataForLink('reports', ids, '/production/tasks', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/production/tasks')
		}
		case 'prdctn_task_complect': {
			const urlData = getDataForLink('complectations', ids, '/production/tasks', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/production/tasks')
		}
		case 'prdctn_task_event_history': {
			const urlData = getDataForLink('eventHistories', ids, '/production/tasks', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/production/tasks')
		}
		case 'implmnt': {
			const urlData = getDataForLink('', ids, '/rp/implementation', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rp/implementation')
		}

		case 'implmnt_prod': {
			const urlData = getDataForLink('productions', ids, '/rp/implementation', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rp/implementation')
		}

		case 'implmnt_event_history': {
			const urlData = getDataForLink('eventHistories', ids, '/rp/implementation', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/rp/implementation')
		}

		case 'suppl_lab_indic': {
			const urlData = getDataForLink(
				'ctrlLabIndicators',
				ids,
				'/rm/supply-input-controls',
				dataServerCtx
			)
			return generateObjWithUrlAndText(urlData, '/rm/supplies')
		}
		case 'param_photo': {
			const urlData = getDataForLink('photos', ids, '/reg/specifications', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/reg/specifications')
		}
		case 'voc_editable_raw_mat_shelf_life': {
			const urlData = getDataForLink('', ids, '/voc/editable/raw-mat-shelf-life', dataServerCtx)
			return generateObjWithUrlAndText(urlData, '/vocabularies/raw-mat-shelf-life')
		}
		default:
			return []
	}
}

/**
 * вызывает getDataForLink для каждого урла и филда чтобы попытаться найти искомый обьект
 * в том или ином месте дата сервер контекста
 * нужна для моделей которые юзщаются в разных пунктах менб
 * например как контакты, связь с контактом может вести
 * как на заказчика так и неа поставщика
 * эта функция определяет на кого нужно вести в данном случяае
 * @param {Array<{field: string, url: string }> } inFieldsUrls
 * @param {Array<string> } ids
 */
const findForMultipleUrlFields = (inFieldsUrls, ids, dataServerCtx) => {
	for (let e of inFieldsUrls) {
		let options
		if (e.filter) options = { filter: e.filter }
		const urlData = getDataForLink(e.field, ids, e.dataUrl, dataServerCtx, options)

		if (urlData.length) return generateObjWithUrlAndText(urlData, e.url)
	}
	return null
	// throw Error('dependency not found')
}
/**
 * функция возвращающая конечную структуру на основе которой можно создать ссылку
 * @param {string } inUrl
 * @param {string } inObj - обьект с данными конкретного элемента пункта менбю
 */
export const generateObjWithUrlAndText = (inArr, url, withLink = false) => {
	return inArr.map((e) => {
		return {
			mainUrl: `${url}`,
			id: `${e.id}`,
			url: `${url}/${e.id}`,
			label: withLink ? generateLinkText(url, e) : e.label,
		}
	})
}

/**
 * Подготавливает обьекты с урлом и лейблом для каждого айдишника из параметров
 * @param {string } inUrl
 * @param {string } inObj - обьект с данными конкретного элемента пункта менбю
 */
const getDataForLink = async (
	inField,
	ids,
	url,
	dataServerCtx,
	manyToHasMany = false,
	options = { filter: (e) => e, pageUrl: null }
) => {
	const data = await axios(url)
	const allMainObjects = ids
		.map((id) => {
			return data.filter(options.filter || ((e) => e)).find((e) => {
				if (inField === '') {
					return String(e.id) === String(id)
				}
				if (Array.isArray(inField)) {
					const arrInFind = findDeepFieldValues(inField, [e], manyToHasMany)
					return !!arrInFind.find((de) => String(de?.id) === String(id))
				}
				return !!e[inField]?.find((de) => String(de.id) === String(id))
			})
		})
		.filter((e) => !!e)
	const uniq = lodash.uniqBy(allMainObjects, 'id')
	return uniq.map((e) => {
		const bread = getBreadcrumbsText(options.pageUrl || url, e, options)
		return {
			id: e.id,
			label: `${bread.head ? bread.head : bread.breadcrumbsTitle}/${
				bread.label ? bread.label : bread.recordLabel
			}`,
		}
	})
}

/**
 * Получить склеенное название пункта менб и наименование элемента этого пункита
 * @param {string } inUrl
 * @param {string } inObj - обьект с данными конкретного элемента пункта менбю
 */
const generateLinkText = (inUrl, inObj) => {
	switch (inUrl) {
		case '/product-catalog':
			return `Продуктовый каталог/${inObj.label}`
		case '/reg/specifications':
			return `Спецификации/${inObj?.vocProdType?.label || ''} - ${inObj?.label || ''}`
		case '/rm/supply-input-controls':
			return `Поставки сырья/Входной контроль - партия ${inObj?.assignedPartyNum || ''}`
		case '/production/tasks':
			return `Задачи на производство/${inObj.displayCode} от ${inObj.date}`
		default:
			return '-'
	}
}

const findDeepFieldValues = (inFields, inArr, manyToHasMany) => {
	try {
		if (inFields.length === 0 && manyToHasMany) return inArr
		if (inFields.length === 1 && !manyToHasMany) return inArr[inFields[0]]
		return inArr
			.map((e) => {
				return findDeepFieldValues(inFields.slice(1), e[inFields[0]], manyToHasMany)
			})
			.flat()
	} catch (err) {
		console.log(inFields)
		console.log(inArr)
		throw Error('findDeepFieldValues err')
	}
}

const findBySpecificFieldAdPrepare = (id, field, url, dataServerCtx) => {
	const foundInputCtrls = dataServerCtx.state[url].data.filter((e) => String(e[field]?.id) === id)
	const toGenerateUrl = foundInputCtrls.map((e) => {
		const bread = getBreadcrumbsText(url, e)
		return {
			id: e.id,
			label: `${bread.head}/${bread.label}`,
		}
	})
	return generateObjWithUrlAndText(toGenerateUrl, url)
}
