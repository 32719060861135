import { Checkbox, Typography } from 'antd'
import { modes } from '../../../utils'
const { Title } = Typography

export const getFormItems = (mainCtx, modCtx) => [
	[
		{
			key: 'title',
			field: <Title level={5}>Редактирование данных в 1С</Title>,
		},
		{
			label: 'Статус',
			key: 'status',
			field: mainCtx.state.data.status,
		},
		{
			label: 'Разрешить редактирование',
			key: 'isAllowEdit',
			field: (
				<Checkbox
					disabled={modCtx.mod === modes.view}
					checked={mainCtx.state.data.isAllowEdit}
					onChange={(e) => mainCtx.stateFunctions.fieldSetters.setIsAllowEdit(e.target.checked)}
				/>
			),
		},
	],
]
