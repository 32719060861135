import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import { ModContext, FileContext, UserDataContext } from '../../../../../contexts'
import {
	getCommonProviderFunctions,
	getCommonProviderModalFunctions,
} from '../../../../../utils/helpers/generators'
import {
	prepareObjFromServer,
	modes,
	appendFiles,
	validateRequired,
	useItemFetchers,
	axios,
	goToItem,
} from '../../../../../utils'
import { basicValidator, isValidNum } from '@berry/common-functions/validators'
import { message } from 'antd'
import { addFile as addNewFile } from '../../../../../utils/helpers/for-files'
import moment from 'moment'
import { calcTestReportNum } from '../../../../../utils/helpers/cross-pages-funcs'
import { useHistory } from 'react-router-dom'
import { v4 } from 'uuid'

const dataUrl = '/stock/raw-mats'
const pageUrl = '/stock/raw-mat'

export const reducer = (state) => {
	return {
		...state,
	}
}

const initialData = {
	storages: [],
	labIndics: [],
	eventHistories: [],
	costs: [],
}
const tabList = {
	'event-histories': 'eventHistories',
	'lab-indics': 'labIndics',
	storages: 'storages',
	costs: 'costs',
}

const StockRawMatItemMainContext = React.createContext()
StockRawMatItemMainContext.displayName = 'StockRawMatItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const fileCtx = useContext(FileContext)
	const {
		state: { isDevAdmin },
	} = useContext(UserDataContext)
	const [state, dispatch] = React.useReducer(reducer, {
		data: initialData,
		oldData: initialData,
		additional: {
			allSelectStockRawMats: [],
			allSelectProdCats: [],
			allRegSpecs: [],
			allRawMatSupplies: [],
		},
		formErrors: [],
		isInitializedMain: false,
		isInitializedAdditional: false,
		tabsLoading: {
			eventHistories: false,
			producedParties: false,
			storages: false,
			labIndics: false,
			costs: false,
		},
		peresort: { __isOpen: false },
	})
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabList, stateRef, useCallback(executeDispatch, []))
	useEffect(() => {
		const fn = async () => {
			const labIndicsPhotos = lodash.cloneDeep(stateRef.current.data.labIndics)
			const labIndicsPhotosAllPhotos =
				labIndicsPhotos
					?.map((item) => item.photos)
					.flat()
					.filter((el) => el) || []
			await fileCtx.addFiles(labIndicsPhotosAllPhotos, 'photoPath')
			const specPhotos = lodash.cloneDeep(stateRef.current.data.labIndics)
			const specAllPhotos = specPhotos?.map((item) => item.specParam.photos || []).flat() || []
			await fileCtx.addFiles(specAllPhotos, 'photoPath')
		}
		fn()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateRef.current.additional.allSelectStockRawMats])

	const getRequiredFields = (rec) => ({
		storages: ['status'],
		labIndics: rec.isText ? ['factText'] : ['factVal'],
	})

	const validate = () => {
		const blockCauseHasError = (storage) =>
			['Удержано', 'Заблокировано'].includes(storage.status) && !basicValidator(storage.blockCause)
		if (stateRef.current.data.storages.some(blockCauseHasError)) {
			for (const storage of stateRef.current.data.storages) {
				if (blockCauseHasError(storage)) setError([], [], `blockCause.${storage._uuid_}`, true)
			}
			return 'Не заполнены обязательные поля'
		}
		if (
			stateRef.current.data.labIndics.some(
				(ind) => !basicValidator(ind.factVal) && !basicValidator(ind.factText)
			)
		) {
			return 'Не заполнены обязательные поля'
		}
		if (
			stateRef.current.data.labIndics.some(
				(ind) => basicValidator(ind.factVal) && !isValidNum(ind.factVal)
			)
		) {
			for (const i of stateRef.current.data.labIndics) {
				if (basicValidator(i.factVal) && !isValidNum(i.factVal))
					setError([], [], `factVal.${i._uuid_}`, true)
			}
			return 'Не заполнены обязательные поля'
		}
		return ''
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			formErrors: {},
			peresort: {
				__isOpen: false,
			},
		})
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * подготавливает данные которые нужно выбират
	 */

	const selectors = {
		storages: {
			status: [
				{ label: 'Допущено' },
				{ label: 'Заблокировано' },
				{ label: 'Удержано' },
				...(isDevAdmin ? [{ label: 'Сработано' }] : []),
			],
		},
		labIndics: {
			factText: ['C', 'NC'],
		},
		peresort: {
			prodCat: stateRef.current.additional.allSelectProdCats,
			prodCatKindRawMat:
				stateRef.current.additional.allSelectProdCats.find(
					(e) => String(e.id) === String(stateRef.current.peresort.prodCat?.id)
				)?.kindRawMats || [],
			spec: stateRef.current.additional.allRegSpecs.filter((e) => {
				if (e.vocProdType?.label !== 'Сырье') return false
				if (String(stateRef.current.peresort.prodCatKindRawMat?.id) !== String(e.kindRawMat?.id))
					return false
				if (e.status !== 'Утверждено') return false
				return true
			}),
		},
	}

	const { getEditedData, commonDeepFieldUpdate, isEdited, stateFunctions, serverDelete, setError } =
		getCommonProviderFunctions(
			stateRef,
			stateRef.current.oldData,
			executeDispatch,
			{
				modCtx,
				dataUrl,
				params,
				pageUrl: '/stock/raw-mat',
				history,
				requiredFields: getRequiredFields,
			},
			selectors,
			{},
			{
				labIndics: {
					factVal: 'common',
					factText: 'common',
				},
				storages: {
					blockCause: 'common',
					status: 'common',
				},
			}
		)

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		selectors,
		[
			{
				field: 'prodCat',
				updateVal: 'obj',
				modalName: 'peresort',
			},
			{ field: 'prodCatKindRawMat', updateVal: 'obj', modalName: 'peresort' },
			{ field: 'spec', updateVal: 'obj', modalName: 'peresort' },
			{ field: 'weight', updateVal: 'common', modalName: 'peresort' },
		]
	)

	stateFunctions.labIndics.addPhoto = (inUuid, vals) => {
		addNewFile(vals, 'photoPath', 'lab_ind')
		commonDeepFieldUpdate(['labIndics'], [inUuid], 'photos', vals)
		fileCtx.addFiles(lodash.cloneDeep(vals), 'photoPath')
	}
	stateFunctions.labIndics.removePhoto = (inUuid, photo) => {
		let newFiles = stateFunctions.labIndics.get(inUuid)?.photos.filter((p) => {
			return photo.photoPath
				? p.photoPath !== photo.photoPath
				: p.photoPath !== photo.originFileObj?.photoPath
		})
		commonDeepFieldUpdate(['labIndics'], [inUuid], 'photos', newFiles)
	}
	stateFunctions.storages.setBlockCause = (inUuid, val) => {
		commonDeepFieldUpdate(['storages'], [inUuid], 'blockCause', val)
		if (!['Допущено', 'Сработано'].includes(stateFunctions.storages.get(inUuid)?.status)) {
			validateRequired(setError, `blockCause.${inUuid}`, undefined, val)
		} else {
			setError([], [], `blockCause.${inUuid}`, false)
		}
	}
	const serverEdit = async () => {
		try {
			const formData = appendFiles(stateRef.current.data.labIndics, 'photos', '_uuid_', 'lab_ind', {
				key: 'photoPath',
			})
			let body
			let options = { url: dataUrl }
			if (params.id === 'new') {
				body = lodash.cloneDeep(stateRef.current.data)
				options.method = 'POST'
			} else {
				body = getEditedData()
				options.method = 'PUT'
			}
			if (body.labIndics) {
				body.repeatDate = moment().format('YYYY-MM-DD')
				body.repeatTestRepNum = await calcTestReportNum([])
			}
			formData.append('data', JSON.stringify(body))
			executeDispatch({ ...stateRef.current, isPendingReq: true })
			const res = (await axios[options.method.toLowerCase()](dataUrl, formData))?.data || {}
			executeDispatch({ ...stateRef.current, isPendingReq: false })
			if (res?.data?.id) {
				if (options.method === 'POST') {
					goToItem(history, { url: pageUrl, id: res?.data?.id })
				}
				modCtx.set(modes.view)
				executeDispatch({ ...stateRef.current, reloadUuid: v4() })
			}
			modCtx.set(modes.view)
			return { method: options.method, id: res.data?.id }
		} catch (err) {
			message.error('Ошибка при сохранении')
			console.log(err.message)
		}
	}

	const value = {
		state: lodash.cloneDeep(stateRef.current.data),
		stateFunctions,
		isPendingReq: stateRef.current.isPendingReq,
		tabsLoading: stateRef.current.tabsLoading,
		serverEdit,
		serverDelete,
		isEdited,
		validate,
		reset,
		formErrors: stateRef.current.formErrors,
		selectors,
		setError,
		modalFunctions,
		peresort: lodash.cloneDeep(stateRef.current.peresort),
	}

	return (
		<StockRawMatItemMainContext.Provider value={value}>
			{children}
		</StockRawMatItemMainContext.Provider>
	)
}

export { Provider, StockRawMatItemMainContext }
