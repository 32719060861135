import React, { useRef, useReducer } from 'react'
import { modes } from '../../utils'

export const changeMode = (state) => {
	return { ...state }
}

const getInitialState = (mod) => {
	if (localStorage.getItem('modCtx')) {
		try {
			const mod = JSON.parse(localStorage.getItem('modCtx'))
			localStorage.removeItem('modCtx')
			return mod
		} catch (err) {
			console.log(err)
		}
	}
	return { mod: mod || modes.view }
}

const ModContext = React.createContext()
ModContext.displayName = 'ModContext'

const Provider = (props) => {
	const { children, mod } = props
	const [state, dispatch] = useReducer(changeMode, getInitialState(mod))

	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	const set = (val) => {
		if (!Object.values(modes).includes(val)) throw Error('Val is not in modes')
		executeDispatch({ mod: val })
	}

	const value = {
		mod: stateRef.current.mod,
		set: set,
	}

	return <ModContext.Provider value={value}>{children}</ModContext.Provider>
}

export { Provider, ModContext }
