/**
 * Проверка конкретных полей на то что они не равны чемуто
 * @param {Object} inData
 * @param {Array<String>} inFields
 * @param {Array<String>} inValues
 */
module.exports.checkIsNot = (inData, inFields, inValues) => {
	if (!inData || !inFields?.length || !inValues?.length) throw Error('Параметры неверны')
	for (let f of inFields) {
		if (inValues.includes(inData[f])) return false
	}
	return true
}

/**
 * Удаляет все поля вложенные. с соответствующим названием поля
 * @param {Object} obj
 * @param {Array<String>} inKey
 */
 module.exports.deepRemoveField = (obj, inKey) => {
	for (var i in obj) {
		if (!obj.hasOwnProperty(i)) continue
		if (typeof obj[i] == 'object') {
			deepRemoveField(obj[i], inKey)
		} else if (i == inKey) {
			delete obj[i]
		}
	}
	return obj
}

/**
 * удаляет все пустые вложенные обьекты из свойств
 * @param {Object} obj - откуда удаляется
 */
 module.exports.deepRemoveEmpty = (obj) => {
	for (var i in obj) {
		if (!obj[i] || !obj.hasOwnProperty(i)) continue
		if (typeof obj[i] == 'object') {
			deepRemoveEmpty(obj[i])
			if (Object.keys(obj[i]).length === 0) {
				delete obj[i]
			}
		}
	}
	return obj
}
