import { MacCommandOutlined } from '@ant-design/icons'
import { Checkbox, Col, Select } from 'antd'
import { InputError } from '../../../../../components'
import { modes } from '../../../../../utils'
import { dateFormat } from '../../../../../utils/constants/common'
import { colorizeIntegrationListStatus } from '../../../../../utils/helpers/for-integration'
import moment from 'moment'
import clickableStatus from '../../../../../utils/helpers/clickableStatus'

export const paintTextByStatus = (text, record) => {
	return (
		<span
			style={{
				color: ['Списано', 'Сработано'].includes(record?.status)
					? 'rgba(122, 122, 122, 1)'
					: record.status === 'Ошибка отправки в 1С'
					? 'rgb(207, 9, 9)'
					: 'black',
			}}
		>
			{text}
		</span>
	)
}

export const getDisplayColumns = (mainCtx, modCtx, authCtx, userDataCtx) => {
	const stateFunctions = mainCtx.stateFunctions.storages
	let isDisabled = false
	if (modCtx.mod === modes.view) isDisabled = true
	return [
		{
			title: '№ помещения',
			dataIndex: ['room', 'displayCode'],
			key: 'displayCode',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Тип упаковки',
			dataIndex: ['vocContType', 'labelShort'],
			key: 'vocContType',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Масса нетто ед. упак., кг',
			dataIndex: 'nettoPkgWeight',
			key: 'nettoPkgWeight',
			width: '12.48%',
			render: paintTextByStatus,
		},
		{
			title: 'Нестандартная упаковка',

			key: 'isStandPkg',
			width: '12.48%',
			render: (_, rec) => {
				return <Checkbox disabled={true} checked={!rec.isStandPkg}></Checkbox>
			},
		},

		{
			title: 'Дата',
			dataIndex: 'date',
			key: 'date',
			width: '7.41%',
			render: (date, record) =>
				paintTextByStatus(date ? moment(date).format(dateFormat) : null, record),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '12.48%',
			onCell: clickableStatus(authCtx, mainCtx.state, userDataCtx),
		},
		{
			title: 'Причина блокировки',
			key: 'blockCause',
			width: '12.71%',
			render: (_, record) => {
				return record.blockCauses?.map((e) => e.blockCause.label).join(', ')
			},
		},
		{
			key: 'peresort',
			width: '5%',
			render: (_, record) => {
				if (!mainCtx.selectors.storages.status.includes(record.status)) return null
				return (
					<MacCommandOutlined
						title="Пересорт"
						style={{ fontSize: 18 }}
						onClick={() => {
							mainCtx.modalFunctions.openPeresort()
						}}
					/>
				)
			},
		},
	]
}
