import React, { useContext, useEffect, useState } from 'react'
import { useInput } from '../../../utils'
import { addData, deleteData, editData } from '../../../services/api/requests'
import Wrapper from '../Wrapper/Wrapper'
import { columns } from './columns'
import Form from './Form'
import { getAvailableCode } from '../../../utils/helpers/generators'
import { getDataForVocs } from '../AdditionalSemiType/AdditionalSemiType'
import { SyncDepsContext } from '../../../contexts'

const vocUrl = '/voc/editable/payment-condition'

const Payment = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, setState] = useState([])

	useEffect(async () => {
		await getDataForVocs(setState, syncDepsCtx.setDepsInfo, vocUrl)
	}, [syncDepsCtx.state.reloadUuids['office-ms']])
	const [recordId, setRecordId] = useState(null)

	const [label, resetLabel] = useInput('')

	const resetFields = () => {
		resetLabel()
	}

	const addItem = async (successFn = (fn) => fn) => {
		const response =
			(await addData(vocUrl, {
				data: {
					label: label.value,
					displayCode: String(getAvailableCode(state.map((e) => +e.displayCode))),
				},
			})) || {}
		if (response.data.result.data) {
			setState((prev) => [...prev, response.data.result.data])
			successFn()
		}
	}

	const editItem = async (successFn = (fn) => fn) => {
		const response = await editData(vocUrl, {
			recordId,
			data: {
				id: recordId,
				label: label.value,
			},
		})
		if (response.data.result.data) {
			setState((prev) => {
				const foundIndex = prev.findIndex((el) => el.id === response.data.result.data.id)
				return [
					...prev.slice(0, foundIndex),
					response.data.result.data,
					...prev.slice(foundIndex + 1),
				]
			})
			successFn()
		}
	}

	const deleteItem = async (id) => {
		const response = await deleteData(vocUrl, {
			id,
		})
		setState((prev) => {
			const foundIndex = prev.findIndex((el) => el.id === +response.data.result.id)
			return [...prev.slice(0, foundIndex), ...prev.slice(foundIndex + 1)]
		})
		setRecordId(null)
	}

	return (
		<Wrapper
			rights={{
				Справочники: {
					'Условия оплаты': {
						edit: true,
					},
				},
			}}
			breadcrumbsTitle="Условия оплаты"
			model="voc_editable_payment_condition"
			canCreate
			canUpdate
			canDelete
			data={state}
			recordId={recordId}
			setRecordId={setRecordId}
			addData={addItem}
			editData={editItem}
			deleteData={deleteItem}
			columns={columns}
			resetFields={resetFields}
			fields={[{ label }]}
			validateFields={[{ label }]}
			uniqueFields={[{ label }]}
			form={<Form label={label} />}
			vocUrl={vocUrl}
		/>
	)
}

export default Payment
