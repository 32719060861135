import { setNumField } from '@berry/front-common/for-fields'
import { CommonSelect, EditDelBtns, InputError } from '../../../../components'
import { modes, openNewTab } from '../../../../utils'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 * @param {Object} modCtx
 * @param {function} editHandler - обработка нажатия на карандаш
 */

export const getDisplayColumns = (mainCtx, modCtx, authCtx, editHandler) => {
	const stateFunctions = mainCtx.stateFunctions.unldStorages
	let isDisabled = false
	if (modCtx.mod === modes.view) isDisabled = true
	return [
		{
			title: 'Номер помещения',
			key: 'room',
			rules: [{ required: true }],
			width: !mainCtx.state.unldIsStandPkg ? '14.91%' : '17.67%',
			render: (_, rec) => {
				const rooms = mainCtx.selectors.unldStorages.room.filter((r) => {
					return r.outdoorWh.id === mainCtx.state.supply.supplyReq.idVocOutdoorWh
				})
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`room.${rec._uuid_}`]}
						style={{ width: '100%' }}
						component={
							<CommonSelect
								value={rec.room}
								setValue={(val) => stateFunctions.setRoom(rec._uuid_, val)}
								isDisabled={isDisabled}
								dataArr={rooms}
							/>
						}
					/>
				)
			},
		},
		{
			title: 'Масса, кг',
			key: 'weight',
			width: '11.55%',
			render: (_, rec) => {
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`weight.${rec._uuid_}`]}
						style={{ width: '100%' }}
						value={rec.weight}
						disabled={isDisabled}
						onChange={(e) => {
							setNumField(e.target.value, rec.weight, stateFunctions.setWeight, 'float', [9, 2], {
								_uuid_: rec._uuid_,
							})
							mainCtx.checkStorageSum()
						}}
					/>
				)
			},
		},
		{
			title: null,
			key: 'action',
			width: '4%',
			render: (_, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={false}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
							mainCtx.checkStorageSum()
						}}
						onEdit={editHandler}
						deps={mainCtx.delEditDeps?.unldStorages?.[record.id]}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					/>
				)
			},
		},
	]
}
