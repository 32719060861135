const hello = () => {
	console.log('Hello common front')
}

/**
 * обрезает стрпоку и добавляет ... в конце
 * @param {string} inStr - основное число
 * @param {number} inLen - неточное число
 */
const sliceStringAndAddThreeDots = (inStr, inLen) => {
	if (inStr && inStr.length > inLen) {
		return inStr.slice(0, inLen) + '...'
	}
	return inStr
}

module.exports = { hello, sliceStringAndAddThreeDots }
