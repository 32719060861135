import { getColumnSearchProps, openNewTab } from '../../../../../utils'
import { dataUrl as supplyPageUrl } from '../../../../RawMaterialSupply/RawMaterialSupplyItem/provider/main'
import { dateFormat } from '../../../../../utils/constants/common'
import moment from 'moment'

export const getDisplayColumns = ({ fromServerFilters, className, setClassName, authCtx }) => {
	return [
		{
			title: 'ID поставки',
			dataIndex: 'supplyId',
			key: 'supplyId',
			sorter: true,
			width: '9.67%',
			onCell: (record) => ({
				className: `tab-link ${className}`,
				onClick: (e) => {
					e.stopPropagation()
					openNewTab(`${supplyPageUrl}/${record.supplyId}`, { authCtx })
				},
				onMouseEnter: () => {
					setClassName('')
				},
				onMouseLeave: () => {
					setClassName('highlight')
				},
			}),
			...getColumnSearchProps(fromServerFilters?.['supplyId'], undefined, 'supplyId'),
		},
		{
			title: 'Дата поставки',
			dataIndex: 'supplyDate',
			key: 'supplyDate',
			width: '9.71%',
			sorter: true,
			render: (date) => moment(date, 'YYYY-MM-DD').format(dateFormat),
			...getColumnSearchProps(fromServerFilters?.['supplyDate'], undefined, 'supplyDate', true),
		},
		{
			title: '№ партии',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '8.33%',
			...getColumnSearchProps(fromServerFilters?.['partyNum'], undefined, 'partyNum'),
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			key: 'articul',
			width: '7.43%',
			...getColumnSearchProps(fromServerFilters?.['articul'], undefined, 'articul'),
		},
		{
			title: 'Артикул 1С',
			dataIndex: 'articul1C',
			key: 'articul1C',
			width: '7.43%',
			...getColumnSearchProps(fromServerFilters?.['articul1C'], undefined, 'articul1C'),
		},
		{
			title: 'Вид',
			dataIndex: 'prodCatKindLabel',
			key: 'prodCatKindLabel',
			width: '12.68%',
			...getColumnSearchProps(
				fromServerFilters?.['prodCatKindLabel'],
				undefined,
				'prodCatKindLabel'
			),
		},
		{
			title: 'Доля, %',
			dataIndex: 'percent',
			key: 'percent',
		},
	]
}
