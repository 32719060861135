import {
	getProdCatKindReadyProdLabel,
	getProdCatPackageLabel,
} from '@berry/common-functions/cross-project-functions'
import { basicValidator } from '@berry/common-functions/validators'
import { Checkbox } from 'antd'
import { CommonSelect, EditDelBtns } from '../../../../components'
import { modes, openNewTab } from '../../../../utils'
import {
	getProdCatKindReadyProdCode,
	getProdCatPrecalculatedCaliber,
} from '../../../../utils/helpers/cross-pages-funcs'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} mainCtx - основной контекст
 */
export const getDisplayColumns = (mainCtx, modCtx, authCtx) => {
	const stateFunctions = mainCtx.stateFunctions.kindReadyProds
	const isDisabled = modCtx.mod === modes.view
	return [
		{
			title: 'Артикул',
			dataIndex: 'articul',
			key: 'code',
			width: '43%',
			render: (articul, record) =>
				articul ||
				getProdCatKindReadyProdCode(
					mainCtx.state.displayCode || mainCtx.additional.allProdCats.length,
					record
				),
		},
		{
			title: 'Наименование',
			dataIndex: 'label',
			key: 'label',
			width: '43%',
			render: (text, record) => {
				return getProdCatKindReadyProdLabel(record)
			},
		},
		{
			title: null,
			key: 'action',
			width: '14%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={false}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
						deps={(mainCtx.state.packages || [])
							.filter(
								(pkg) =>
									(pkg.kindReadyProd?.vocReadyProdForm &&
										pkg.kindReadyProd?.vocReadyProdForm.id === record.vocReadyProdForm.id) ||
									(pkg.kindReadyProd?.caliber &&
										pkg.kindReadyProd.caliber.id === record.caliber?.id)
							)
							.map((el) => ({
								link: `/pv/product-catalog/${mainCtx.state.id}`,
								title: `Продуктовый каталог / ${getProdCatPackageLabel(el)}`,
							}))}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					/>
				)
			},
		},
	]
}

const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 24 },
}

/**
 * поля формы в модальном окне
 * @param {Object} mainCtx - главный контекст
 * @param {function} setAdditAttr - функция изменения доп признака
 * @param {{_uuid_: string}} additAttr - доп признак
 */
export const getFormItems = (mainCtx, setAdditAttr, additAttr) => {
	const { _uuid_ } = additAttr || {}
	const isDisabled = !mainCtx.state.isAdditAttr
	return [
		[
			{
				layout: layout,
				label: 'Доп.признак: ',
				name: 'additAttr',
				rules: [],
				field: (
					<CommonSelect
						plainValue={_uuid_ ? mainCtx.stateFunctions.additAttrs.get(_uuid_).label : 'Не выбрано'}
						setValue={(data) => {
							setAdditAttr(data)
						}}
						isDisabled={isDisabled || !mainCtx.selectors.kindReadyProds.additAttr.length}
						showSearch
						dataArr={[
							{ id: null, displayVal: 'Не выбрано', isEmpty: true },
							...(mainCtx.selectors.kindReadyProds.additAttr || []),
						]}
					/>
				),
			},
		],
	]
}

/**
 * Подготаввливает параметры для поиска вида гп из множества, на основе галки "Для всех доп признаков"
 * @param {Object} inMainCtx
 *  @param {{additAttr:{id:string}}} inData
 *  @param {{exclude: Array<string>, where: Object}} initialData
 */
const getFindParams = (
	inMainCtx,
	inData,
	initialData = {
		exclude: [],
		where: [],
	}
) => {
	if (initialData.exclude === undefined) initialData.exclude = []
	if (initialData.where === undefined) initialData.where = {}
	initialData.where['additAttr.id'] = inData.additAttr?.id
	return initialData
}
/**
 * поля таблицы формы модального окна
 * @param {Object} inMainCtx - основной контекст
 * @param {Object} inDataFromServerCtx - контекст данных с сервера
 */
export const getDisplayModalProdFormColumns = (inMainCtx) => {
	return [
		{
			title: 'Форма',
			dataIndex: ['vocReadyProdForm', 'label'],
			key: 'vocReadyProdForm',
			width: '70%',
		},
		{
			title: 'Используется',
			key: 'isUsed',
			width: '30%',
			render: (record) => {
				const { where, exclude } = getFindParams(inMainCtx, record, {
					where: {
						'vocReadyProdForm.id': record.vocReadyProdForm.id,
					},
					exclude: ['caliber.id'],
				})

				const datasWithForm = inMainCtx.stateFunctions.kindReadyProds.get(where, {
					exclude: exclude,
				})
				const isChecked = !!datasWithForm.length
				const canDelete =
					!isChecked || inMainCtx.canDeleteFunctions.kindReadyProds(datasWithForm.map((e) => e.id))

				return (
					<Checkbox
						onChange={(e) => {
							if (e.target.checked) {
								let calibers = inMainCtx.stateFunctions.kindReadyProds.getCalibers(record.additAttr)
								if (!record.vocReadyProdForm.isBindCaliber || !calibers.length) {
									calibers = [null]
								}
								const forAdditAttrs = []
								if (record.additAttr) {
									forAdditAttrs.push(record.additAttr)
								} else {
									forAdditAttrs.push(null)
								}
								calibers.forEach((k) => {
									forAdditAttrs.forEach((attr) => {
										if (
											!inMainCtx.state.kindReadyProds?.find((rp) => rp._uuid_ === record._uuid_)
										) {
											inMainCtx.stateFunctions.kindReadyProds.create({
												vocReadyProdForm: record.vocReadyProdForm,
												additAttr: attr,
												caliber: k,
											})
										} else {
											inMainCtx.stateFunctions.kindReadyProds?.edit(record._uuid_, {
												...record,
												vocReadyProdForm: record.vocReadyProdForm,
												additAttr: attr,
												caliber: k,
											})
										}
									})
								})
							} else {
								// если удаляем
								const { where, exclude } = getFindParams(inMainCtx, record, {
									where: {
										'vocReadyProdForm.id': record.vocReadyProdForm.id,
									},
									exclude: ['caliber.id'],
								})
								const found = inMainCtx.stateFunctions.kindReadyProds.get(where, {
									exclude: exclude,
								})
								found.forEach((e) => {
									inMainCtx.stateFunctions.kindReadyProds.delete(e._uuid_)
								})
								if (!inMainCtx.stateFunctions.kindReadyProds.isBindCaliber(record.additAttr)) {
									// если удалили последнюю форму которая связывала калибр
									const { exclude, where } = getFindParams(inMainCtx, record, {
										exclude: ['caliber.id', 'vocReadyProdForm.id'],
									})
									const found = inMainCtx.stateFunctions.kindReadyProds.get(where, {
										exclude: exclude,
									})
									found.forEach((e) => {
										inMainCtx.stateFunctions.kindReadyProds.setCaliber(e._uuid_, null)
									})
								}
							}
						}}
						checked={isChecked}
						disabled={
							isChecked &&
							(!canDelete ||
								inMainCtx.delEditDeps?.packages[
									inMainCtx.state.packages.find(
										(pkg) =>
											pkg.kindReadyProd?.vocReadyProdForm &&
											pkg.kindReadyProd.vocReadyProdForm.id === record.vocReadyProdForm?.id &&
											pkg.kindReadyProd.additAttr?.id === record.additAttr?.id
									)?.id
								] ||
								!!(inMainCtx.state.packages || []).filter(
									(pkg) =>
										pkg.kindReadyProd?.vocReadyProdForm?.id === record.vocReadyProdForm?.id &&
										pkg.kindReadyProd?.additAttr?.id === record.additAttr?.id
								).length)
						}
					></Checkbox>
				)
			},
		},
	]
}

/**
 * поля таблицы калибра модального  окна
 * @param {Object} inMainCtx - основной контекст
 * @param {Object} inDataFromServerCtx - контекст данных с сервера
 */
export const getDisplayModalCaliberColumns = (inMainCtx, additAttr) => {
	return [
		{
			title: 'Калибровка',
			key: 'caliber',
			dataIndex: 'caliber',
			width: '70%',
			render: (record) => {
				return record.caliber?.id === -1 || record.isNotCalibratedCaliber
					? 'некалиброван.'
					: getProdCatPrecalculatedCaliber(record)
			},
		},
		{
			title: 'Используется',
			key: 'isUsed2',
			width: '30%',
			render: (record) => {
				let isChecked = false

				const { where, exclude } = getFindParams(inMainCtx, record, {
					where: {
						'caliber.id': record.caliber?.id,
					},
					exclude: ['vocReadyProdForm.id'],
				})

				const dataWithCaliber = inMainCtx.stateFunctions.kindReadyProds.get(where, {
					exclude: exclude,
				})
				isChecked = !!dataWithCaliber.length
				const canDelete =
					!isChecked ||
					inMainCtx.canDeleteFunctions.kindReadyProds(dataWithCaliber.map((e) => e.id))
				return (
					<Checkbox
						onChange={(e) => {
							if (e.target.checked) {
								const { exclude, where } = getFindParams(inMainCtx, record, {
									exclude: ['vocReadyProdForm.id', 'caliber.id'],
								})
								let found = inMainCtx.stateFunctions.kindReadyProds
									.get(where, {
										exclude: exclude,
									})
									.filter((e) => e.vocReadyProdForm.isBindCaliber)
								const withNullCaliber = found.filter((el) => !basicValidator(el.caliber))
								found = found
									.filter((el) => basicValidator(el.caliber))
									.map((el) => {
										el.caliber = null
										return el
									})
								found = inMainCtx.stateFunctions.kindReadyProds.makeUniq(found)
								withNullCaliber.forEach((e) => {
									inMainCtx.stateFunctions.kindReadyProds.setCaliber(e._uuid_, record.caliber)
								})
								found.forEach((el) => {
									inMainCtx.stateFunctions.kindReadyProds.create({
										vocReadyProdForm: el.vocReadyProdForm,
										additAttr: el.additAttr,
										caliber: record.caliber,
									})
								})
							} else {
								// если удаляем
								const { exclude, where } = getFindParams(inMainCtx, record, {
									exclude: [],
								})
								const toDelete = inMainCtx.stateFunctions.kindReadyProds.get(
									{ ...where, 'caliber.id': record.caliber?.id },
									{
										exclude: [...exclude, 'vocReadyProdForm.id'],
									}
								)

								const allForThisAdditAttr = inMainCtx.stateFunctions.kindReadyProds.get(where, {
									exclude: [...exclude, 'vocReadyProdForm.id', 'caliber.id'],
								})
								if (toDelete.length === allForThisAdditAttr.length) {
									toDelete.forEach((e) => {
										inMainCtx.stateFunctions.kindReadyProds.setCaliber(e._uuid_, null)
									})
								} else {
									toDelete.forEach((e) => {
										inMainCtx.stateFunctions.kindReadyProds.delete(e._uuid_)
									})
								}
							}
						}}
						checked={isChecked}
						disabled={
							!canDelete ||
							inMainCtx.delEditDeps?.kindReadyProds[
								inMainCtx.state.kindReadyProds
									.filter((pgk) => pgk.additAttr?.id === additAttr?.id)
									.find((kind) => kind.caliber && kind.caliber.id === record.caliber.id)?.id
							] ||
							!!(inMainCtx.state.packages || [])
								.filter((pgk) => pgk.kindReadyProd.additAttr?.id === additAttr?.id)
								.find(
									(pkg) =>
										pkg.kindReadyProd?.caliber &&
										pkg.kindReadyProd.caliber.id === record.caliber?.id
								)
						}
					/>
				)
			},
		},
	]
}

/**
 * подготавливает данные для отрисовки таблицы
 * @param {Object} inMainCtx - рсновной контекст
 *
 */
export const prepareData = (inMainCtx, additAttr) => {
	const from = inMainCtx.additional.allVocReadyProdForms.map((e) => {
		return {
			additAttr: additAttr,
			vocReadyProdForm: e,
		}
	})
	const caliber = inMainCtx.selectors.kindReadyProds.caliber.map((e) => {
		return {
			additAttr: additAttr,
			caliber: e,
		}
	})

	return {
		form: from,
		caliber: caliber,
	}
}
