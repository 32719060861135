import { Table } from 'antd'
import React, { useContext } from 'react'
import { ProductionTaskItemMainContext } from '../provider/main'
import { TabItem } from '../../../../components'
import { AuthContext, ModContext, UserDataContext } from '../../../../contexts'
import { modes } from '../../../../utils'
import { getDisplayColumns } from './report-tab'

const ReportTab = () => {
	const mainCtx = useContext(ProductionTaskItemMainContext)
	const authCtx = useContext(AuthContext)
	const modCtx = useContext(ModContext)
	const userCtx = useContext(UserDataContext)

	return (
		<TabItem
			addButton={{
				disabled: mainCtx.isDisabledByChangeStatusBtn,
				show: true,
				onClick: () => {
					const uuid = mainCtx.stateFunctions.reports.create()
					mainCtx.stateFunctions.reports.setPartyNum(uuid, mainCtx.calcReportPartyNum(uuid), false)
					if (mainCtx.state.taskKind === 'Производство ПФ') {
						mainCtx.stateFunctions.reports.setType(uuid, 'ПФ')
					}
				},
			}}
			table={() => {
				return (
					<Table
						bordered
						rowKey="_uuid_"
						size="small"
						loading={mainCtx.tabsLoading.reports}
						rowClassName={modCtx.mod !== modes.view && 'clickable'}
						columns={getDisplayColumns(mainCtx, modCtx, authCtx, userCtx)}
						dataSource={mainCtx.state.reports}
						pagination={false}
						scroll={{ x: 800 }}
					/>
				)
			}}
		></TabItem>
	)
}

export default ReportTab
