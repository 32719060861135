import React from 'react'
import { Row, Col, Modal, Button } from 'antd'

/**
 * Обертка для модального окна открывающегося при клике на элемент таблицы
 * @param {Object} props
 * @param {function} props.form - функция возвращающая компонент форму
 * @param {boolean} props.isOpen - открыта ли модалка
 * @param {string} props.title - тайтл
 * @param {function} props.close - функция закрытия
 * @param {function} props.save - функция сохранения изменений
 * @param {function} props.setModalState - функция изменения состояния модального окна
 * @param {function} props.setItem - функция изменения элемента модального окна
 * @param {function} props.editFun - функция изменения записив главном контексте
 * @param {function} props.validate - функция валидации
 * @param {{_uuid_:string,isNew:boolean, prevData:Object }} props.item - элемент окна
 * @param {function} props.deleteFun - функция удаления элемента из главного контекста
 */
// Добавляемс временность когда открываем окно, удаляем временность когда окно закрываем
const RecordItemModal = (props) => {
	const {
		form,
		formName,
		isOpen,
		title,
		setModalState,
		item,
		deleteFun,
		setItem,
		validate = () => {},
		openNewTab = () => {},
		editFun /* close, save */,
		beforeClose = () => {},
		beforeSave = () => {},
		showErrorModal = false,
		maskClosable = false,
		formInstance,
	} = props
	const close = () => {
		beforeClose()
		setModalState(false)
		if (item.isNew) {
			deleteFun(item._uuid_)
		} else {
			editFun(item._uuid_, item.prevData)
		}
		setItem(null)
	}
	const save = async () => {
		try {
			if (formInstance) await formInstance.validateFields()
			const res = await validate()
			if (res?.length) throw Error(res)
			beforeSave()
			await openNewTab()
			setModalState(false)
			setItem(null)
		} catch (err) {
			if (err.message) {
				if (showErrorModal) {
					Modal.warning({
						centered: true,
						closable: false,
						closeIcon: null,
						content: err.message,
						keyboard: true,
					})
				}
			}
		}
	}

	const getForm = () => {
		try {
			return form()
		} catch (err) {
			console.log(err)
			setModalState(false)
			setItem(null)
		}
	}

	return (
		<Modal
			{...props}
			title={title}
			visible={isOpen}
			onCancel={close}
			centered
			destroyOnClose
			maskClosable={maskClosable}
			footer={[
				<Button
					key="ok-footer-button"
					type="primary"
					htmlType="submit"
					onClick={save}
					form={formName}
					autoFocus
				>
					Ок
				</Button>,
			]}
		>
			<Row>
				<Col span="24">{getForm()}</Col>
			</Row>
		</Modal>
	)
}

export default RecordItemModal
