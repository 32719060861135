import { getColumnSearchProps } from '../../../../utils'
import { RightSquareOutlined, UndoOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import { colorizeIntegrationListStatus } from '../../../../utils/helpers/for-integration'
import { dateFormat } from '../../../../utils/constants/common'
import moment from 'moment'
import clickableStatus from '../../../../utils/helpers/clickableStatus'

/**
 * @param {Object} fromServerFilters - массив с записями таблицы
 */

export const getColumns = (props) => {
	const { mainCtx, canEdit, fromServerFilters, frontFilters, sorter, authCtx, userDataCtx } = props
	return [
		{
			title: 'ID ДЗ',
			dataIndex: 'idOrder',
			sortOrder: sorter.columnKey === 'idOrder' && sorter.order,
			sorter: true,
			key: 'idOrder',
			width: '2.81%',
			...getColumnSearchProps(fromServerFilters.idOrder, frontFilters, 'idOrder'),
		},
		{
			title: 'Партия №',
			dataIndex: 'partyNumForSorter',
			key: 'partyNum',
			width: '8%',
			sorter: true,
			sortOrder: sorter.columnKey === 'partyNum' && sorter.order,
			render: (_, rec) => rec.partyNum,
			...getColumnSearchProps(fromServerFilters.partyNum, frontFilters, 'partyNum'),
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			...getColumnSearchProps(fromServerFilters.articul, frontFilters, 'articul'),
			key: 'articul',
			width: '10%',
		},
		{
			title: 'Артикул 1C',
			dataIndex: 'articul1C',
			...getColumnSearchProps(fromServerFilters.articul1C, frontFilters, 'articul1C'),
			key: 'articul1C',
			width: '10%',
		},
		{
			title: 'Продукт',
			dataIndex: 'prodCat',
			key: 'prodCat',
			...getColumnSearchProps(fromServerFilters.prodCat, frontFilters, 'prodCat'),
			sortOrder: sorter.columnKey === 'prodCat' && sorter.order,
			sorter: true,
			width: '10%',
		},
		{
			title: 'Вид',
			dataIndex: 'prodCatKind',
			width: '10%',
			sortOrder: sorter.columnKey === 'prodCatKind' && sorter.order,
			sorter: true,
			...getColumnSearchProps(fromServerFilters.prodCatKind, frontFilters, 'prodCatKind'),
		},
		{
			title: 'Тип упаковки',
			dataIndex: 'pkgType',
			key: 'pkgType',
			width: '10%',
			...getColumnSearchProps(fromServerFilters.pkgType, frontFilters, 'pkgType'),
		},

		{
			title: 'Годен до',
			dataIndex: 'shelfLifeDate',
			key: 'shelfLifeDate',
			width: '5.87%',
			render: (shelfLifeDate) => (shelfLifeDate ? moment(shelfLifeDate).format(dateFormat) : null),
			sortOrder: sorter.columnKey === 'shelfLifeDate' && sorter.order,
			sorter: true,
		},

		{
			title: '№ пом',
			dataIndex: 'roomNum',
			key: 'roomNum',
			width: '7%',
			...getColumnSearchProps(fromServerFilters.roomNum, frontFilters, 'roomNum'),
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '8%',
			sorter: true,
			sortOrder: sorter.columnKey === 'weight' && sorter.order,
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '10%',
			...getColumnSearchProps(fromServerFilters.status, frontFilters, 'status'),
			render: (_, record) => {
				return colorizeIntegrationListStatus(record.status)
			},
			onCell: clickableStatus(authCtx, {}, userDataCtx),
		},
		...(canEdit
			? [
					{
						title: '',
						dataIndex: 'sale',
						key: 'sale',
						width: '5%',
						render: (_, record) =>
							['Хранение', 'Отклонено 1С', 'Ошибка отправки в 1С'].includes(record.status) &&
							canEdit ? (
								<Row style={{ justifyContent: 'space-around', fontSize: 21, flexWrap: 'nowrap' }}>
									{['Отклонено 1С', 'Ошибка отправки в 1С'].includes(record.status) ? (
										<UndoOutlined
											title="Вернуть статус"
											className={'pictogram'}
											onClick={(e) => {
												e.stopPropagation()
												mainCtx.dropStatus(record)
											}}
										/>
									) : (
										<RightSquareOutlined
											title="Перемещение"
											className={'pictogram'}
											onClick={(e) => {
												e.stopPropagation()
												mainCtx.modalFunctions.openRelocationModal()
												mainCtx.modalFunctions.setRelocationModalStorage(record)
											}}
										/>
									)}
								</Row>
							) : null,
					},
			  ]
			: []),
	]
}

const xlsxColumnTitles = ['Партия №', 'Артикул', 'Артикул 1C', 'Продукт', 'Вид', 'Масса, кг']

export const getToXlsx = (dataSource, columns, renameColumnDataIndexToKey = []) => {
	if (renameColumnDataIndexToKey.length)
		columns.forEach((c) => {
			if (renameColumnDataIndexToKey.includes(c.dataIndex)) c.dataIndex = c.key
		})
	return {
		dataSource,
		columns: columns.filter((c) => xlsxColumnTitles.includes(c.title)),
		tableName: 'Реестр запасов НО',
		fileName: 'Реестр_запасов_НО.xlsx',
	}
}
