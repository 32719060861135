import React from 'react'
import { Select, Col } from 'antd'
import { prepareOptions } from '../../utils/helpers/for-select'

const CommonSelect = ({
	value,
	plainValue,
	setValue,
	isDisabled,
	showSearch,
	isMultiple,
	dataArr,
	...rest
}) => {
	const additionalParams = {}
	if (showSearch) {
		additionalParams.showSearch = true
		additionalParams.filterOption = (input, option) =>
			(option?.strLabel ?? '').toLowerCase().includes(input.toLowerCase())
	}
	if (isMultiple) {
		additionalParams.mode = 'multiple'
	}
	return (
		<Select
			value={plainValue || value?.displayVal}
			style={{ width: '100%' }}
			onSelect={(_, { data }) => setValue(data)}
			disabled={isDisabled}
			notFoundContent={<Col>Не найдено</Col>}
			options={prepareOptions(dataArr)}
			{...additionalParams}
			{...rest}
		/>
	)
}

export default CommonSelect
