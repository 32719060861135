import { Table } from 'antd'
import React, { useContext, useMemo, useState } from 'react'
import { ListLoaderContext, Provider } from '../../contexts/list/list-loader'

const TableWithListLoader = (props) => {
	const { calcColumns, ...otherProps } = props
	return (
		<Provider {...otherProps}>
			<TableComponent {...props} />
		</Provider>
	)
}

const TableComponent = (props) => {
	const {
		state: {
			pageSize,
			page,
			total,
			isLoading,
			isInitialized,
			fromServer,
			fromServerFilters,
			mainFilter,
			filters,
			sorter,
			stockNotify,
		},
		setPage,
		setFilters,
		setSorter,
	} = useContext(ListLoaderContext)
	const [className, setClassName] = useState('highlight')
	const memoColumns = useMemo(
		() =>
			props
				.calcColumns({
					fromServerFilters,
					frontFilters: { ...mainFilter, ...filters },
					sorter,
					stockNotify,
					setClassName,
					className,
					authCtx: props.authCtx,
				})
				.map((c) =>
					props.highlight
						? { ...c, setClassName, className, onCell: c.onCell ? c.onCell : () => ({ className }) }
						: c
				),
		[className, filters, fromServerFilters, mainFilter, props, sorter, stockNotify]
	)
	return (
		<Table
			rowKey={(record) => (record.idStorage ? `${record.id}-${record.idStorage}` : record.id)}
			size="small"
			rowClassName="clickable"
			dataSource={fromServer}
			loading={isLoading || !isInitialized}
			scroll={{ x: 800 }}
			pagination={{
				pageSize,
				showSizeChanger: false,
				current: page,
				total: total,
			}}
			onChange={(pagination, filters, sorter) => {
				setPage(pagination.current)
				setFilters(filters)
				setSorter(sorter)
			}}
			columns={memoColumns}
			onRow={(data) => ({
				...props.onRow,
				...(props.highlight && {
					onMouseEnter: () => setClassName('highlight'),
					onMouseLeave: () => setClassName(''),
				}),
			})}
			{...props}
		/>
	)
}
export default TableWithListLoader
