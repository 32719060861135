import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import moment from 'moment'
import { AxiosContext, ModContext } from '../../../../contexts'
import {
	getCommonProviderFunctions,
	getCommonProviderModalFunctions,
} from '../../../../utils/helpers/generators'
import {
	prepareObjFromServer,
	modes,
	getNewObj,
	useItemFetchers,
	goToItem,
	removeByUuid,
} from '../../../../utils'
import { basicValidator } from '@berry/common-functions/validators'
import { useHistory } from 'react-router-dom'
import { v4 } from 'uuid'
const dataUrl = '/rp/implementations'
export const frontUrl = '/stock-operations/implementations'
const tabs = { productions: 'productions', 'event-histories': 'eventHistories' }

export const reducer = (state) => {
	return {
		...state,
	}
}

const initData = {
	date: moment(),
	status: 'Новая',
	productions: [],
	eventHistories: [],
}

const initialState = {
	data: initData,
	oldData: initData,
	formErrors: [],
	additional: {
		allCustomers: [],
		allProviders: [],
		allOutdoorWhs: [],
	},
	addProd: {
		__isOpen: false,
		__name: null,
		addProdStockRawMatStor: [],
		addProdStockSemifStor: [],
		addProdStockReadyProdStor: [],
		addProdStockReadyProdResaleStor: [],
		addProdStockWasteStor: [],
	},
	deletedProds: [],
}

const ImplementationItemMainContext = React.createContext()
ImplementationItemMainContext.displayName = 'ImplementationItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const { axios } = useContext(AxiosContext)
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))

	const requiredFields = {
		productions: ['weight'],
	}

	const isItemEdited = () => {
		const editedFields = getEditedData()
		return isEdited(editedFields)
	}

	const dropError = (err) => {
		let newErrors = lodash.cloneDeep(stateRef.current.formErrors || {})
		delete newErrors[err]
		executeDispatch({
			...stateRef.current,
			formErrors: newErrors,
		})
	}

	const setDeletedProds = (val) => {
		executeDispatch({
			...stateRef.current,
			deletedProds: val ? [...stateRef.current.deletedProds, val] : [],
		})
	}

	const validators = {}
	const getAvailableWeight = (product) => {
		if (!isNaN(product.availableWeight)) {
			return product.availableWeight || 0
		}
		let key = null
		if (product.stockRawMatStor) {
			key = 'stockRawMatStor'
		} else if (product.stockSemifStor) {
			key = 'stockSemifStor'
		} else if (product.stockReadyProdStor) {
			key = 'stockReadyProdStor'
		} else if (product.stockReadyProdResaleStor) {
			key = 'stockReadyProdResaleStor'
		} else if (product.stockWasteStor) {
			key = 'stockWasteStor'
		}
		const storage = product.storages?.find((e) => product[key].idStorage === e.id)
		return storage.status === 'Бронь' && storage.parent?.status === 'Допущено'
			? +storage.weight + (+storage.parent?.weight || 0)
			: +storage.weight
	}
	const checkAvailable = () => {
		let errors = []
		for (let p of stateRef.current.data.productions) {
			const availableWeight = getAvailableWeight(p)
			let formErrors = { ...stateRef.current.formErrors }
			if (basicValidator(p.weight) && availableWeight < +p.weight) {
				formErrors[`weight.${p._uuid_}`] = `Доступная масса = ${availableWeight} кг`
				errors.push(p)
			} else {
				formErrors[`weight.${p._uuid_}`] = false
			}
			executeDispatch({
				...stateRef.current,
				formErrors: {
					...stateRef.current.formErrors,
					...formErrors,
				},
			})
		}
		if (errors.length) {
			return false
		}
		return true
	}
	const validate = async ({ pre = false } = {}) => {
		let newFormErrors = {}
		const data = stateRef.current.data
		if (!data.productions.length) {
			return 'Не добавлена ни одна строка в табличную вкладку.'
		}
		if (!checkAvailable()) {
			return 'В продукции указана масса, превышающая доступную. Карточка не может быть сохранена'
		}
		data.productions.forEach((p) => {
			requiredFields.productions.forEach((f) => {
				if (!basicValidator(p[f]) || +p[f] === 0) {
					newFormErrors = { ...newFormErrors, [f + '.' + p._uuid_]: true }
				}
			})
		})
		if (Object.keys(newFormErrors).length) {
			if (!pre) {
				executeDispatch({ ...stateRef.current, formErrors: newFormErrors })
				throw Error()
			} else {
				return 'Не заполнены обязательные поля или не добавлена ни одна строка в табличной вкладке.При сохранении карточка будет сохранена со статусом "Черновик".При отмене продолжится редактирование карточки'
			}
		}
		return ''
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({ ...stateRef.current, data: recordFromDataSrvCtx })
	}, [])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	/**
	 * подготавливает данные которые нужно выбират
	 */

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	const selectors = {
		customer: stateRef.current.additional.allCustomers,
		provider: stateRef.current.additional.allProviders,
		outdoorWh: stateRef.current.additional.allOutdoorWhs,
	}

	const {
		commonFieldUpdate,
		getEditedData,
		isEdited,
		stateFunctions,
		serverDelete,
		setError,
		commonObjUpdate,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{
			modCtx,
			dataUrl,
			params,
			requiredFields,
			pageUrl: '/stock-operations/implementations',
			history,
		},
		selectors,
		{
			date: 'common',
			customer: 'obj',
			provider: 'obj',
			status: 'common',
		},
		{
			productions: {
				weight: 'common',
			},
		}
	)

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		selectors,
		[
			{ field: 'stockRawMatStor', updateVal: 'common', modalName: 'addProd' },
			{ field: 'stockSemifStor', updateVal: 'common', modalName: 'addProd' },
			{ field: 'stockReadyProdStor', updateVal: 'common', modalName: 'addProd' },
			{ field: 'stockReadyProdResaleStor', updateVal: 'common', modalName: 'addProd' },
			{ field: 'stockWasteStor', updateVal: 'common', modalName: 'addProd' },
			{ field: '__name', updateVal: 'common', modalName: 'addProd' },
		],
		[
			{
				mainField: 'stockRawMatStor',
				field: 'stockRawMatStor',
				updateVal: 'common',
				modalName: 'addProd',
			},
			{
				mainField: 'stockSemifStor',
				field: 'stockSemifStor',
				updateVal: 'common',
				modalName: 'addProd',
			},
			{
				mainField: 'stockReadyProdStor',
				field: 'stockReadyProdStor',
				updateVal: 'common',
				modalName: 'addProd',
			},
			{
				mainField: 'stockReadyProdResaleStor',
				field: 'stockReadyProdResaleStor',
				updateVal: 'common',
				modalName: 'addProd',
			},
			{
				mainField: 'stockWasteStor',
				field: 'stockWasteStor',
				updateVal: 'common',
				modalName: 'addProd',
			},
		]
	)

	stateFunctions.setIsFreeSamples = (val) => {
		if (stateRef.current.data.productions.length > 1 && val) {
			const firstProd = stateRef.current.data.productions[0]
			let key = null
			if (firstProd.idStockRawMat) {
				key = 'idStockRawMat'
			}
			if (firstProd.idStockSemif) {
				key = 'idStockSemif'
			}
			if (firstProd.idStockWaste) {
				key = 'idStockWaste'
			}
			if (firstProd.idStockReadyProd || firstProd.idStockReadyProdResale) {
				key = 'idStockReadyProd'
			}
			if (
				!stateRef.current.data.productions.every(
					(p) => p[key] || (key === 'idStockReadyProd' && p.idStockReadyProdResale)
				) ||
				firstProd.idContrOrder
			) {
				commonFieldUpdate('productions', [])
			}
		}
		commonFieldUpdate('isFreeSamples', val)
	}

	stateFunctions.setDate = (m) => {
		commonFieldUpdate('date', moment(m).format('YYYY-MM-DD'))
	}
	stateFunctions.setOutdoorWh = (val) => {
		if (stateRef.current.data.productions.length) {
			commonFieldUpdate('productions', [])
		}
		commonObjUpdate('outdoorWh', val)
	}
	stateFunctions.productions.create = (inData, field) => {
		let newArr = [
			...lodash.cloneDeep(stateRef.current.data.productions),
			...inData.map((el) => getNewObj({ ...el, [field]: el, weight: null, id: null })),
		]
		commonFieldUpdate('productions', newArr)
		commonFieldUpdate('idContrOrder', inData[0]?.idContrOrder || null)
		if (inData[0]?.idContrOrder) {
			commonFieldUpdate('customer', null)
			commonFieldUpdate('provider', inData[0]?._fullProvider)
		}
	}
	stateFunctions.productions.delete = (inUuid) => {
		const newProducts = removeByUuid(inUuid, stateRef.current.data.productions)
		commonFieldUpdate('productions', newProducts)
		commonFieldUpdate('idContrOrder', newProducts[0]?.idContrOrder || null)
		if (!newProducts[0]?.idContrOrder) {
			commonFieldUpdate('provider', null)
		}
	}
	const getDepsOn = () => []

	const depsFunctions = {
		productions: (inId) => [],
	}
	const serverSendTo1c = async () => {
		let body = { id: stateRef.current.data.id }
		const productionsArticuls = stateRef.current.data.productions.map((e) => {
			return {
				id: e.id,
				articul: e.articul,
			}
		})
		body.productionsArticuls = productionsArticuls
		executeDispatch({ ...stateRef.current, isPendingReq: true })
		const { data } = (await axios.put(`${dataUrl}/send-to-1c`, body)) || {}
		executeDispatch({ ...stateRef.current, isPendingReq: false })
		if (data?.data?.id) {
			goToItem(history, { id: data.data.id, url: frontUrl }, { refresh: true })
		}
	}

	const serverEdit = async () => {
		if (!isEdited()) return modCtx.set(modes.view)
		let body = {}
		let method = ''
		let axiosMethod = ''
		if (params.id === 'new') {
			body = stateRef.current.data
			method = 'POST'
			axiosMethod = 'post'
		} else {
			method = 'PUT'
			axiosMethod = 'put'
			body = getEditedData()
		}
		if (body.date) {
			body.date = moment(body.date).format('YYYY-MM-DD')
		}
		executeDispatch({ ...stateRef.current, isPendingReq: true })
		let res = null
		try {
			res = await axios[axiosMethod](dataUrl, body)
			executeDispatch({ ...stateRef.current, isPendingReq: false })
			if (res.data?.data?.id) {
				if (method === 'POST') {
					goToItem(history, { url: frontUrl, id: res.data.data.id })
				}
				executeDispatch({ ...stateRef.current, reloadUuid: v4() })
				modCtx.set(modes.view)
				return { method: method, id: res.data.data.id }
			}
		} catch (err) {
			executeDispatch({ ...stateRef.current, isPendingReq: false })
		}

		return res
	}

	const value = {
		commonFieldUpdate,
		isPendingReq: stateRef.current.isPendingReq,
		state: lodash.cloneDeep(stateRef.current.data),
		additional: stateRef.current.additional,
		stateFunctions,
		serverEdit,
		serverDelete,
		isEdited: isItemEdited,
		validate,
		reset,
		validators: validators,
		formErrors: stateRef.current.formErrors,
		selectors,
		getDepsOn,
		depsFunctions,
		setError,
		modalFunctions,
		addProd: lodash.cloneDeep(stateRef.current.addProd),
		deletedProds: lodash.cloneDeep(stateRef.current.deletedProds),
		serverSendTo1c,
		dropError,
		checkAvailable,
		getAvailableWeight,
		setDeletedProds,
	}

	return (
		<ImplementationItemMainContext.Provider value={value}>
			{children}
		</ImplementationItemMainContext.Provider>
	)
}

export { Provider, ImplementationItemMainContext }
