export const getColumns = (measure) => [
	{
		title: 'Код ОКЕИ',
		dataIndex: 'okei',
		key: 'okei',
		render: (text) => (text ? text.toString().padStart(3, '0') : ''),
	},
	{
		title: 'Наименование',
		dataIndex: 'label',
		key: 'label',
	},
	{
		title: 'Обозначение',
		dataIndex: 'labelShort',
		key: 'labelShort',
	},
	{
		title: 'Коэффициент для перевода',
		dataIndex: 'coefficient',
		key: 'coefficient',
	},
	{
		title: 'Целевая единица',
		dataIndex: 'idTargetUnit',
		key: 'idTargetUnit',
		render: (idTargetUnit) =>
			measure ? measure.find((m) => m.id === idTargetUnit)?.label : idTargetUnit,
	},
	{
		title: 'Характеристика',
		dataIndex: 'characteristic',
		key: 'characteristic',
	},
]
