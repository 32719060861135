import { Modal, DatePicker, Checkbox } from 'antd'
import { EditDelBtns, InputError, LoadingButton } from '../../../../components'
import { modes, getRowSpan } from '../../../../utils'
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { dateFormat } from '../../../../utils/constants/common'
import { checkRole } from '@berry/common-functions/role'
import { colorizeIntegrationListStatus } from '../../../../utils/helpers/for-integration'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 */
export const getDisplayColumns = (modCtx, stateFunctions, mainCtx, currentRights) => {
	const isDisabled = modCtx.mod === modes.view

	return [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			width: '7%',
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.products.length),
			}),
		},

		{
			title: '№ накладной',
			dataIndex: 'displayCode',
			key: 'displayCode',
			width: '10%',
			render: (_, record) => (
				<InputError
					hasError={mainCtx.formErrors?.[`displayCode.${record._uuid_}`]}
					value={record.displayCode}
					disabled={isDisabled}
					onChange={(e) => {
						stateFunctions.setDisplayCode(record._uuid_, e.target.value)
					}}
				/>
			),
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.products.length),
			}),
		},
		{
			title: 'Дата накладной',
			dataIndex: 'date',
			key: 'date',
			width: '10%',
			render: (_, record) => (
				<InputError
					hasError={mainCtx.formErrors[`date.${record._uuid_}`]}
					component={
						<DatePicker
							disabled={isDisabled}
							name="date"
							placeholder="ДД.ММ.ГГГГ"
							showToday={false}
							format={dateFormat}
							allowClear={false}
							suffixIcon={null}
							value={record.date ? moment(record.date) : null}
							onChange={(e) => {
								stateFunctions.setDate(record._uuid_, e)
							}}
						/>
					}
				/>
			),
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.products.length),
			}),
		},
		{
			title: 'ГПП',
			dataIndex: 'isReadyProdResale',
			key: 'isReadyProdResale',
			width: '3%',
			render: (_, record) => (
				<Checkbox
					disabled={
						isDisabled || record.products.some((p) => p.unldStatus === 'Разгрузка завершена')
					}
					checked={record.isReadyProdResale}
					onChange={(e) => {
						mainCtx.stateFunctions.invoices.setIsReadyProdResale(
							record._uuid_,
							e.target.checked,
							record
						)
					}}
				/>
			),
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.products.length),
			}),
		},
		{
			title: 'Поставщик',
			dataIndex: 'provider',
			key: 'provider',
			width: '10%',
			render: (_, record) =>
				(record.prod?.contrQuota || record.prod?.order)?.contract.provider.label,
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.products.length),
			}),
		},
		{
			title: '№ договора',
			dataIndex: 'contractNum',
			key: 'contractNum',
			width: '10%',
			render: (_, record) => (record.prod?.contrQuota || record.prod?.order)?.contract.num,
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.products.length),
			}),
		},
		{
			title: '№ ДС',
			dataIndex: 'app',
			key: 'app',
			width: '10%',
			render: (_, record) =>
				(record.prod?.contrQuota || record.prod?.order)?.application?.displayCode,
		},
		{
			title: 'Продукт',
			dataIndex: 'product',
			key: 'product',
			width: '10%',
			render: (_, record) => record.prod?.displayVal,
		},
		{
			title: '№ партии',
			dataIndex: 'assignedPartyNum',
			key: 'assignedPartyNum',
			width: '10%',
			render: (_, record) => record.prod?.assignedPartyNum,
		},
		{
			title: 'Статус',
			dataIndex: ['status'],
			key: 'status',
			width: '10%',
			render: (status, record) => colorizeIntegrationListStatus(status, true),
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.products.length),
			}),
		},
		{
			title: '',
			key: 'sendTo1c',
			width: '10%',
			render: (_, record) => {
				return checkRole(
					{
						'АРМ офиса': {
							'Поставки сырья': {
								'Основные данные': {
									edit: true,
								},
							},
						},
					},
					currentRights
				) &&
					record.products?.length &&
					['Отклонено 1С', 'Ошибка отправки в 1С', 'Ожидает отправки в 1С'].includes(
						record.status
					) &&
					!record.products
						?.filter((p) => p?.idRmSupply === record.idRmSupply)
						.every((prod) => prod.ctrlStatus === 'Возврат') &&
					record.products
						?.filter((p) => p?.idRmSupply === record.idRmSupply)
						.every(
							(prod) =>
								(['Приемка разрешена', 'Ответственное хранение'].includes(prod.ctrlStatus) &&
									prod.unldStatus === 'Разгрузка завершена') ||
								['Возврат'].includes(prod.ctrlStatus)
						) &&
					isDisabled ? (
					<LoadingButton
						key={record._uuid_}
						onClick={async () => {
							await mainCtx.serverSendTo1c(record.id)
						}}
					>
						Отправить в 1С
					</LoadingButton>
				) : null
			},
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.products?.length),
			}),
		},
		{
			title: '',
			key: 'action',
			width: '6%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled && record.status === 'Новая'}
						onDelete={(record) => {
							Modal.confirm({
								title:
									'При удалении этот элемент удалится навсегда. Вы действительно хотите удалить этот элемент?',
								icon: <ExclamationCircleOutlined />,
								content: '',
								okText: 'Ок',
								cancelText: 'Отмена',
								centered: true,
								async onOk() {
									try {
										stateFunctions.delete(record._uuid_, record.id)
									} catch (err) {
										console.log(err)
									}
								},
							})
						}}
					/>
				)
			},
			onCell: (record) => ({
				rowSpan: getRowSpan(record, record.products.length),
			}),
		},
	]
}
