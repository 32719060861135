import { useContext } from 'react'
import { Button, Card, Checkbox, Col, Modal, Row, Table, Typography } from 'antd'
import { RawMaterialSupplyItemMainContext } from '../provider/main'
import { ReactComponent as CloseIcon } from '../../../../assets/svg/close.svg'
import { getDisplayColumns } from './select-sup-req-prods.js'
import { showConfirmModal } from '../../../../components'

const SelectSupplyReqProdsModal = () => {
	const mainCtx = useContext(RawMaterialSupplyItemMainContext)
	const modalFunctions = mainCtx.modalFunctions
	const dataSource = mainCtx.state.supplyReq?.products
		.filter((reqProd) => !mainCtx.state.products.some((prod) => prod.reqProd.id === reqProd.id))
		.sort((a, b) => a.contract.id - b.contract.id)
	const columns = [
		{
			key: 'checkbox',
			width: '2%',
			render: (_, record) => (
				<Checkbox
					checked={mainCtx.addContrQuota.addContrQuotaContrQuota?.find((c) => c.id === record.id)}
					onChange={(e) => {
						if (e.target.checked) {
							return modalFunctions.addContrQuotaContrQuota.create(record)
						}
						return modalFunctions.addContrQuotaContrQuota.delete(
							mainCtx.addContrQuota.addContrQuotaContrQuota.find((c) => c.id === record.id)?._uuid_
						)
					}}
				/>
			),
		},
		...getDisplayColumns(dataSource),
	]

	return (
		<Modal
			visible={mainCtx.addContrQuota.__isOpen}
			destroyOnClose
			centered
			bodyStyle={{ paddingTop: '10px' }}
			footer={null}
			closable={false}
			width="90%"
		>
			<Card
				bodyStyle={{
					margin: 0,
					padding: 0,
				}}
				bordered={false}
			>
				<Row gutter={[16, 16]} style={{ padding: '0 0 10px 0' }}>
					<Col flex={1}>
						<Typography.Title level={4} style={{ margin: 0 }}>
							Продукт
						</Typography.Title>
					</Col>
					<Col>
						<Button
							type="primary"
							onClick={() => {
								try {
									mainCtx.validateContrQuota()
									for (const contrQuota of mainCtx.addContrQuota.addContrQuotaContrQuota) {
										mainCtx.stateFunctions.products.create([
											{
												...contrQuota,
												reqProd: contrQuota,
												unldVNaval: !!contrQuota.vNaval,
											},
										])
									}
									mainCtx.modalFunctions.resetAddContrQuota()
								} catch (err) {
									return showConfirmModal({
										title: err.message,
										showCancel: false,
										okText: 'Ок',
									})
								}
							}}
						>
							Сохранить
						</Button>
					</Col>
					<Col>
						<Button
							type="text"
							style={{ lineHeight: 0.8 }}
							icon={<CloseIcon />}
							onClick={() => mainCtx.modalFunctions.resetAddContrQuota()}
						/>
					</Col>
				</Row>
			</Card>
			<Table
				rowKey={(rec) =>
					`${rec.id || rec._uuid_}_${rec.indxMain}_${
						rec.isDiplayedRowBySpan ? 'displ' : 'not_displ'
					}`
				}
				dataSource={dataSource}
				columns={columns}
				pagination={false}
				scroll={{ x: 800 }}
				size="small"
			/>
		</Modal>
	)
}
export default SelectSupplyReqProdsModal
