export const card = {
	height: 64,
}

export const cardBody = {
	height: 64,
	display: 'flex',
	alignItems: 'center',
}

export const withoutTopMenu = {
	padding: '10px 20px',
	...cardBody,
}

export const withTopMenu = {
	padding: '0 20px 0 0',
	...cardBody,
	backgroundColor: '#001529',
}
