import { v4 } from 'uuid'

const addFile = (vals, path, prefix) => {
	for (const file of vals) {
		if (file.originFileObj && !file.originFileObj?.[path]) {
			file.originFileObj[path] = `${prefix}_${v4()}.${file.originFileObj.name?.split('.').pop()}`
			if (!file[path]) file[path] = file.originFileObj[path]
		}
	}
}

export { addFile }
