import lodash from 'lodash';

export const diffArray = (array1, array2) => {
    return array1.filter(i => !array2.includes(i)).length !== 0
}

export const diffNonPrimitive = (value1, value2) => {
    if (
        typeof value1 === 'object' &&
        typeof value2 === 'object' &&
        value1 !== null &&
        value2 !== null &&
        lodash.isEqual(value1, value2
        ))
    {
        return true
    }

    return Array.isArray(value1) && Array.isArray(value2) && diffArray(value1, value2);
}