import React, { useContext, useEffect, useRef } from 'react'
import { ListContext, SyncDepsContext } from '../../../contexts'
import { startUseEffectHandlerForList, everyOtherTimeUseEffectHandlerForList } from '../../../utils'
const dataUrl = '/rp/contracts-customers'
const searchFields = [
	'num',
	'customer.label',
	'dateStart',
	'dateEnd',
	'product',
	'provider.label',
	'dateEnd',
]

export const reducer = (state) => {
	return {
		...state,
	}
}

const ContractListMainContext = React.createContext()
ContractListMainContext.displayName = 'ContractListMainContext'

const Provider = (props) => {
	const { children } = props
	const syncDepsCtx = useContext(SyncDepsContext)
	const {
		state: { page, pageSize, mainFilter, filters, sorter },
		setTotal,
	} = useContext(ListContext)
	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: {
			dateStart: [],
			providerLabel: [],
			product: [],
			dateEnd: [],
			status: [],
		},
		isInitialized: false,
		isLoading: false,
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	useEffect(() => {
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				sorter,
				filters,
				mainFilter,
				offset: pageSize * page - pageSize,
				limit: pageSize,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [])
	useEffect(() => {
		everyOtherTimeUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				offset: pageSize * page - pageSize,
				limit: pageSize,
				sorter,
				filters,
				mainFilter,
				search: stateRef.current.search,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [
		page,
		sorter,
		filters,
		mainFilter,
		stateRef.current.search,
		syncDepsCtx.state.reloadUuids['office-ms'],
		stateRef.current.search,
	])

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}

	const value = {
		state: stateRef.current,
		toDisplayDataList: stateRef.current.fromServer,
		searchFields,
		setSearch,
	}

	return (
		<ContractListMainContext.Provider value={value}>{children}</ContractListMainContext.Provider>
	)
}

export { Provider, ContractListMainContext }
