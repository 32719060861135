import lodash from 'lodash'
import { modes, sorter } from '../../../utils'
import { Col, Input, Select, Typography } from 'antd'

import { isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'
import { CommonSelect, Status } from '../../../components'
import { dateFormat } from '../../../utils/constants/common'
import moment from 'moment'

export const dataUrl = '/reg/specifications'

export const isTextParam = (id, vocSpecParamParamData) =>
	vocSpecParamParamData.find((param) => param.id === id)?.isText === true

export const getEtalon = (inData = {}) => {
	if (inData.etalonText) return inData.etalonText || ''
	return `${[null, undefined].includes(inData.etalonVal1) ? '' : inData.etalonVal1} ${
		(inData.etalonSymbol === 'не доп.'
			? 'Не допускается'
			: inData.etalonSymbol !== '='
			? inData.etalonSymbol
			: '') || ''
	} ${[null, undefined].includes(inData.etalonVal2) ? '' : inData.etalonVal2}`
}

export const getDeviation = (inData = {}) => {
	if (inData.deviationText) return inData.deviationText || ''
	if (inData.deviationSymbol === 'не доп.') {
		return 'Не допускается'
	}
	return `${[null, undefined].includes(inData.deviationVal1) ? '' : inData.deviationVal1} ${
		(inData.deviationSymbol === 'не доп.'
			? 'Не допускается'
			: inData.deviationSymbol !== '='
			? inData.deviationSymbol
			: '') || ''
	} ${[null, undefined].includes(inData.deviationVal2) ? '' : inData.deviationVal2}`
}

const layoutLeft = {
	labelCol: { span: 8 },
	wrapperCol: { span: 14 },
}

const layoutMid = {
	labelCol: {
		sm: { span: 8, offset: 0 },
		md: { span: 10, offset: 0 },
		lg: { span: 8, offset: 8 },
		xl: { span: 8, offset: 4 },
		xxl: { span: 8, offset: 2 },
	},
	wrapperCol: {
		sm: { span: 16 },
		md: { span: 12, offset: 0 },
		lg: 8,
		xl: 10,
	},
}

const layoutRight = {
	labelCol: { sm: { span: 8, offset: 0 }, lg: { span: 8, offset: 0 }, xl: { span: 8, offset: 8 } },
	wrapperCol: { sm: { span: 8, offset: 0 }, lg: { span: 8 }, xl: { span: 6, offset: 0 } },
}

export const getFormItems = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	const {
		state: { data },
		additional,
		stateFunctions,
		selectors,
	} = mainCtx
	let kindSelectors = []
	if (data.vocProdType?.displayVal === 'Сырье') {
		kindSelectors = selectors.kindRawMat
	}
	if (data.vocProdType?.displayVal === 'Готовая продукция') {
		kindSelectors = selectors.kindReadyProd
	}
	return [
		[
			{
				layout: layoutLeft,
				label: 'Тип',
				name: 'vocProdType',
				rules: [{ required: true, message: 'Не выбран тип продукта' }],
				field: (
					<CommonSelect
						value={data.vocProdType}
						setValue={stateFunctions.setProductType}
						isDisabled={isDisabled}
						dataArr={selectors.vocProdType}
					/>
				),
			},
			{
				layout: layoutLeft,
				label: 'Продукт',
				name: 'prodCat',
				rules: [{ required: true, message: 'Не выбран продукт' }],
				field: (
					<CommonSelect
						value={data.prodCat}
						setValue={stateFunctions.setProduct}
						isDisabled={isDisabled}
						dataArr={selectors.prodCat}
					/>
				),
			},
			{
				layout: layoutLeft,
				label: 'Вид продукта',
				name: 'prodKind',
				rules: [{ required: true, message: 'Не выбран вид продукта' }],
				field: (
					<CommonSelect
						value={data.kindRawMat || data.kindReadyProd}
						setValue={stateFunctions.setProductKind}
						isDisabled={isDisabled || !data.prodCat}
						dataArr={kindSelectors}
					/>
				),
			},
		],
		[
			{
				layout: layoutMid,
				label: 'Наименование',
				name: 'label',
				rules: [
					{ required: true },
					{
						validator: async (_, value) => {
							if (value && additional.allSpecNames.includes(value.toLowerCase())) {
								throw Error('Такое наименование уже используется')
							}
						},
					},
				],
				field: (
					<Input
						style={{ width: '100%' }}
						value={data.label}
						disabled={isDisabled}
						maxLength={50}
						onChange={(e) => stateFunctions.setLabel(e.target.value)}
					/>
				),
			},
			{
				layout: layoutMid,
				label: 'Качество',
				name: 'vocQuality',
				rules: [
					{
						required: data.vocProdType?.displayVal === 'Готовая продукция',
						message: "При выбранном типе продукта 'Готовая продукция' поле должно быть заполнено",
					},
				],
				field: (
					<Select
						style={{ width: '100%' }}
						onChange={(el) => stateFunctions.setVocQuality(el)}
						disabled={isDisabled}
						notFoundContent={<Col>Не найдено</Col>}
						options={selectors.vocQuality.map((it) => ({
							value: it.id || null,
							label: (
								<Typography.Text type={it.id ? '' : 'secondary'} italic={!it.id}>
									{it.displayVal}
								</Typography.Text>
							),
						}))}
					/>
				),
			},
			[
				{
					layout: {
						labelCol: {
							sm: { span: 12, offset: 0 },
							md: { span: 12 },
							lg: { span: 12, offset: 0 },
							xl: { span: 12, offset: 6 },
							xxl: { span: 8, offset: 3 },
						},
						wrapperCol: {
							sm: { span: 8, offset: 4 },
							md: { span: 5, pull: 1 },
							lg: { span: 8, offset: 4, pull: 0 },
							xl: { span: 12, offset: 0 },
							xxl: { span: 6, offset: 4 },
						},
					},
					colSpan: 16,
					label: 'Срок годности',
					name: 'shelfLife',
					rules: [{ required: true, message: 'Не указан срок годности' }],
					field: (
						<Input
							controls={false}
							value={data.shelfLife}
							disabled={isDisabled}
							onChange={(e) => {
								if (
									!isValidNum(e.target.value) ||
									!isValidNumberDigits(e.target.value, 'int', [10, 0]) ||
									+e.target.value > 2147483647
								) {
									return stateFunctions.setShelfLife(mainCtx.state.data.shelfLife)
								}
								stateFunctions.setShelfLife(e.target.value)
							}}
						/>
					),
				},
				{
					layout: {
						labelCol: {
							sm: { span: 4, offset: 4 },
							md: { span: 8, offset: 0, pull: 4 },
							lg: { span: 4, offset: 6, pull: 0 },
							xl: { span: 2, offset: 2 },
							xxl: { span: 4, offset: 0, pull: 1 },
						},
						wrapperCol: {
							sm: { span: 12, offset: 0 },
							md: { span: 12, pull: 2 },
							lg: { span: 8, offset: 2, pull: 0 },
							xl: { span: 8, offset: 6 },
							xxl: { span: 8, offset: 0 },
						},
					},
					colSpan: 8,
					label: 'ЕИ',
					name: 'vocMeasure',
					rules: [{ required: true, message: 'Не выбрана единица измерения' }],
					field: (
						<CommonSelect
							value={data.vocMeasure}
							setValue={stateFunctions.setVocMeasure}
							isDisabled={isDisabled}
							dataArr={selectors.vocMeasure}
						/>
					),
				},
			],
		],
		[
			{
				layout: layoutRight,
				label: 'Статус',
				field: <Status status={data.status} />,
			},
			{
				layout: layoutRight,
				label: 'Дата утв.',
				field: (
					<Typography.Text>
						{data.dateOfApprove ? moment(data.dateOfApprove).format(dateFormat) : '-'}
					</Typography.Text>
				),
			},
			{
				layout: layoutRight,
				label: 'Версия',
				field: <Typography.Text>{data.version || '-'}</Typography.Text>,
			},
		],
	]
}
/**
 * Группирует массив по указанному полю
 * @param {Array} inArr - массив для группировки
 * @param {string} field - поле в дот-нотации, по которому производится группировка
 * @returns {Array}
 */
export const groupBy = (inArr, field) => {
	return inArr.reduce((result, cur) => {
		return result.find((el) => lodash.get(el, field) === lodash.get(cur, field))
			? result
			: [
					...result,
					...inArr
						.filter((el) => lodash.get(el, field) === lodash.get(cur, field))
						.sort(sorter('string', 'vocSpecParamParam.label')),
			  ]
	}, [])
}
