import { Table } from 'antd'
import React, { useContext } from 'react'
import { StockWasteItemMainContext } from '../provider/main'
import { TabItem } from '../../../../../components'
import { ModContext } from '../../../../../contexts'
import { modes, prepareData } from '../../../../../utils'
import { getDisplayColumns } from './utils'

const EventHistoryTab = ({ modalItem, setModalItem, isModalOpen, setIsModalOpen }) => {
	const mainCtx = useContext(StockWasteItemMainContext)
	const modCtx = useContext(ModContext)
	const columns = getDisplayColumns()
	const dataSource = prepareData(mainCtx.state.eventHistories || [])
	return (
		<TabItem
			addButton={{ show: false }}
			table={() => (
				<Table
					size="small"
					rowKey="_uuid_"
					columns={columns}
					dataSource={dataSource}
					loading={mainCtx.tabsLoading.eventHistories}
					pagination={false}
					scroll={{ x: 800 }}
					onRow={(record) => ({
						onDoubleClick: () => {
							if (modCtx.mod === modes.view) return
							setModalItem({ _uuid_: record._uuid_, record })
							isModalOpen(true)
						},
					})}
				/>
			)}
		/>
	)
}

export default EventHistoryTab
