import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Row, Space, Typography } from 'antd'
import React from 'react'
import { showConfirmModal } from '..'
import { sliceStringAndAddThreeDots } from '@berry/front-common'
const styleIcons = {
	fontSize: '20px',
	color: '#000',
}

/**
 * двые кнопки возле элемента таблицы для изменения и для удаления
 * @param {Object} props
 * @param {Object} props.record - выбранный элемент
 * @param {string} props.selectedId - на что наведена мыш
 * @param {boolean} props.withMouseDetect - скрывать если не наведены мышь
 * @param {function} props.onEdit - обработчик нажатия на карандаш
 * @param {function} props.onDelete - обработчик нажатия на корзину
 * @param {boolean} props.canUpdate - можно ли обнеовлятьь
 * @param {boolean} props.canDelete - можно ли удалять
 * @param {Array<{url: String, label: string}} props.deps - можно ли удалять
 */
const EditDelBtns = (props) => {
	const {
		record,
		onOk = () => {},
		onEdit = (fn) => fn,
		onDelete = (fn) => fn,
		canUpdate = false,
		canDelete = false,
		deps = [],
		openNewPage,
		ignoreDeps,
	} = props

	const styleForUpdate = {}
	const styleForDelete = {}
	if (!canDelete) {
		styleForDelete.display = 'none'
	}
	if (!canUpdate) {
		styleForUpdate.display = 'none'
	}
	const isHasDeps = (action) => {
		const title =
			action === 'edit'
				? 'Для того, чтобы отредактировать данный элемент необходимо удалить все связи'
				: 'Для того, чтобы удалить данный элемент необходимо удалить все связи'
		if (deps.length) {
			showConfirmModal({
				title,
				onOk,
				width: '40%',
				okText: 'OK',
				content: (
					<Space direction="vertical">
						{deps.slice(0, 10).map((e) => (
							<Button key={e.title} type="link" onClick={() => openNewPage(e.link)}>
								{sliceStringAndAddThreeDots(e.title, 50)}
							</Button>
						))}
						{deps.length > 10 && (
							<Typography.Text>...связей еще {deps.length - 10} шт</Typography.Text>
						)}
					</Space>
				),
				showCancel: false,
			})
			return true
		}
		return false
	}
	return (
		<Row justify="space-between" align="middle">
			<EditOutlined
				style={{
					...styleIcons,
					...styleForUpdate,
				}}
				onClick={() => {
					if (ignoreDeps || !isHasDeps('edit', deps)) {
						onEdit(record)
					}
				}}
			/>
			<DeleteOutlined
				style={{
					...styleIcons,
					...styleForDelete,
				}}
				onClick={() => {
					if (ignoreDeps || !isHasDeps('delete', deps)) {
						onDelete(record)
					}
				}}
			/>
		</Row>
	)
}

export default EditDelBtns
