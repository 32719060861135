import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'antd'

const LoadingButton = (props) => {
	const { onClick, disabled = false, children, isPendingReq, ...other } = props
	if (!onClick) {
		console.error('onClick handler is required!')
	}
	const [loading, setLoading] = useState(false)
	const isMounted = useRef(true)
	useEffect(() => {
		return () => (isMounted.current = false)
	}, [])
	return (
		<Button
			{...other}
			type={!props.icon ? 'primary' : 'text'}
			loading={loading}
			disabled={disabled || isPendingReq}
			onClick={async () => {
				try {
					setLoading(true)
					await onClick()
				} catch (err) {
					console.log(err)
				} finally {
					if (isMounted.current) {
						setLoading(false)
					}
				}
			}}
		>
			{children}
		</Button>
	)
}

export default LoadingButton
