import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useContext } from 'react'
import { ModContext } from '../../contexts'
import { modes } from '../../utils'

/**
 * рисует кнопку удаления записи таблицы на вкладке
 * @param {Object} props
 * @param {Object} props.record - удаляемая запись таблицы
 * @param {function} props.delFun - функция удаления
 * @param {function} props.disabled - заблокирована ли кнопка
 */
const RecordInfoItemDelBtn = (props) => {
	const { delFun, disabled } = props
	const modCtx = useContext(ModContext)
	if (modCtx.mod === modes.view) return null
	return <Button disabled={!!disabled} type="text" icon={<DeleteOutlined />} onClick={delFun} />
}
export default RecordInfoItemDelBtn
