import React, { useRef, useReducer } from 'react'
import { axios, services } from '../../utils'
import lodash from 'lodash'
import getBase64 from '@berry/front-common/getBase64'
import viewIsNotAvailable from '../../assets/viewIsNotAvailable'

export const reducer = (state) => {
	return { ...state }
}

const initialState = { data: [], loading: [] }
const FileContext = React.createContext()
FileContext.displayName = 'FileContext'

const Provider = (props) => {
	const { children } = props
	const [state, dispatch] = useReducer(reducer, initialState)
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const getFilesWithBase64Path = (files, path) => {
		return getActualState().data?.filter((item) => {
			const newFiles = files?.filter((el) => {
				return el[path] === item[path] || el?.originFileObj?.[path] === item[path]
			})
			if (newFiles.length) return newFiles
			return false
		})
	}
	const downloadFiles = async (files, path) => {
		if (!Array.isArray(files)) files = new Array(files)
		const getDownloadFiles = async (files) => {
			let downloadFiles = getFilesWithBase64Path(files, path)
			if (downloadFiles.length) return downloadFiles
			else {
				await addFiles(files, path)
			}
		}
		let filesToDownload = await getDownloadFiles(files)
		if (!filesToDownload?.length) filesToDownload = await getDownloadFiles(files)
		const urls = filesToDownload.map((item) => item)
		for (const [index, url] of urls.entries()) {
			const link = document.createElement('a')
			link.href = url.downloadSrc
			link.download = `file-${index + 1}.${url[path].split('.')[1]}`
			link.click()
		}
	}
	const addFiles = async (files, path) => {
		let filesToUpdate = []
		files.forEach((el, i) => {
			if (
				stateRef.current.data.length &&
				stateRef.current.data?.some((element) => {
					return element?.[path] === files[i]?.[path]
				})
			) {
				return
			} else {
				filesToUpdate = [...filesToUpdate, el]
			}
		})

		if (!filesToUpdate.length) {
			return
		}
		executeDispatch({ ...stateRef.current, loading: files })

		const config = { responseType: 'blob' }
		let downloadSrc
		if (files.some((file) => file.originFileObj)) {
			let downloadedFiles = []
			files.forEach((f) => {
				if (stateRef.current.data?.every((file) => file[path] !== f[path])) {
					downloadedFiles = [...downloadedFiles, f]
				}
			})
			if (!stateRef.current.data?.length) downloadedFiles = files
			if (stateRef.current.data.some((file) => file[path] === downloadedFiles[path])) return
			let results
			let newElements = []
			for (const el of downloadedFiles) {
				if (!el.type.includes('image')) {
					results = viewIsNotAvailable
					downloadSrc = await getBase64([el.originFileObj], el[path])
				} else results = await getBase64([el.originFileObj], el[path])

				el.srcPath = results
				el.downloadSrc = downloadSrc || results
				downloadSrc = ''
				if (getActualState().data.some((file) => file[path] === el.originFileObj[path])) {
					executeDispatch({ ...stateRef.current, loading: [] })
					return
				}
				newElements = [...newElements, el]
			}
			executeDispatch({ data: [...stateRef.current.data, ...newElements], loading: [] })
			return
		}

		const reqs = files.map((item) => {
			return axios
				.get(`${services['office-ms']}/pub/uploads?photo=${item[path]}`, config)
				.catch((err) => console.log(err))
		})

		const responses = await Promise.all(reqs)
		const results = await Promise.all(
			responses.filter((res) => !!res).map((item, idx) => getBase64([item.data], files[idx][path]))
		)

		let newFiles = []
		results.forEach((el, i) => {
			let newElement = files[i]
			if (
				!['.jpg', '.png', '.bmp', '.gif', '.tiff'].some((item) =>
					newElement[path]?.toLowerCase().includes(item)
				)
			) {
				newElement.srcPath = viewIsNotAvailable
				downloadSrc = el
			} else newElement.srcPath = el
			if (
				stateRef.current.data.length &&
				stateRef.current.data?.some((element) => {
					return element?.[path] === newElement?.[path]
				})
			) {
			} else {
				newElement.loading = false
				newElement.downloadSrc = downloadSrc || el
				downloadSrc = ''
				newFiles.push(newElement)
			}
		})
		executeDispatch({ data: [...stateRef.current.data, ...newFiles], loading: [] })

		return
	}

	const getActualState = () => {
		const result = lodash.cloneDeep(stateRef.current)
		return result
	}
	const value = {
		getActualState,
		addFiles,
		downloadFiles,
		getFilesWithBase64Path,
	}

	return <FileContext.Provider value={value}>{children}</FileContext.Provider>
}

export { Provider, FileContext }
