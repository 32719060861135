import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../contexts'

const useAppUpdate = () => {
	const {
		state: { isAuthenticated },
	} = useContext(AuthContext)
	const history = useHistory()

	useEffect(() => {
		if (isAuthenticated && JSON.parse(localStorage.getItem('needToUpdate'))) {
			history.push('/')
			localStorage.setItem('needToUpdate', false)
			window.location.reload()
		}
	}, [history, isAuthenticated])
}

export default useAppUpdate
