import React, { createContext, useCallback, useContext, useEffect, useReducer, useRef } from 'react'
import { SyncDepsContext } from '../global/sync-deps-context'

const ListContext = createContext()

ListContext.displayName = 'ListContext'

const reducer = (state) => {
	return {
		...state,
	}
}

const initialState = {
	page: 1,
	pageSize: 16,
	total: 0,
	mainFilter: {},
	filters: {},
	sorter: {},
	isInStock: true,
	textFilter: null,
	dateFrom: null,
	dateTo: null,
	weightFrom: null,
	weightTo: null,
	posType: null,
	taskKind: null,
}

const Provider = ({ children, path }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const syncDepsCtx = useContext(SyncDepsContext)
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...stateRef.current, ...newState }
		dispatch(newState)
	}
	const setTextFilter = (val) => executeDispatch({ textFilter: val })
	const setPage = (page) => {
		executeDispatch({ page })
		syncDepsCtx.closeNotifyWindow()
		return
	}
	const setPageSize = (pageSize) => executeDispatch({ pageSize })
	const setMainFilter = (filter) => executeDispatch({ mainFilter: filter })
	const setFilters = (filters) => {
		const newFilters = Object.keys(filters).reduce((all, cur) => {
			if (!Object.keys(stateRef.current.mainFilter).length) {
				if (filters[cur]?.length) {
					setMainFilter({ [cur]: filters[cur] })
					return all
				}
			} else {
				if (stateRef.current.mainFilter[cur]) {
					if (filters[cur]?.length) {
						setMainFilter({ [cur]: filters[cur] })
						return all
					}
					setMainFilter({})
					return all
				}
			}
			if (
				Object.keys(stateRef.current.mainFilter).length &&
				Object.keys(stateRef.current.mainFilter).some((key) => key === cur)
			) {
				if (filters[cur] === null) {
					setMainFilter({})
				}
				setMainFilter({ [cur]: filters[cur] })
				return all
			}
			if (filters[cur]?.length) {
				return { ...all, [cur]: filters[cur] }
			}
			return all
		}, {})
		return executeDispatch({ filters: newFilters })
	}
	const setSorter = (sorter) => executeDispatch({ sorter })
	const setIsInStock = (isInStock) => executeDispatch({ isInStock })
	const setDateFrom = (dateFrom) => executeDispatch({ dateFrom })
	const setDateTo = (dateTo) => executeDispatch({ dateTo })
	const setPosType = (posType) => executeDispatch({ posType })
	const setTaskKind = (taskKind) => executeDispatch({ taskKind })
	const setTotal = (total) => executeDispatch({ total })
	const setWeightFrom = (weightFrom) => executeDispatch({ weightFrom })
	const setWeightTo = (weightTo) => executeDispatch({ weightTo })

	useEffect(() => {
		executeDispatch(initialState)
	}, [path])

	const value = {
		state: stateRef.current,
		setPage,
		setPageSize,
		setFilters,
		setSorter,
		setIsInStock,
		setTextFilter,
		setDateFrom,
		setDateTo,
		setPosType,
		setTaskKind,
		setTotal: useCallback(setTotal, []),
		setWeightFrom,
		setWeightTo,
		setMainFilter,
	}
	return <ListContext.Provider value={value}>{children}</ListContext.Provider>
}

export { Provider, ListContext }
