import React from 'react'
import { Form, Button, Row, Col, Layout } from 'antd'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import Logo from './../../components/Logo/Logo'

const { Content } = Layout

const SendMail = (props) => {
	const history = useHistory()

	return (
		<Layout>
			<Helmet>
				<title>Смена пароля</title>
			</Helmet>
			<Logo />
			<Content
				style={{
					background: 'url(/login.jpg) no-repeat center center fixed',
					backgroundSize: 'cover',
					display: 'flex',
					justifyContent: 'space-around',
					height: '100vh',
				}}
			>
				<Row justify="center" align="middle" style={{ position: 'relative', bottom: '80px' }}>
					<Col style={{ width: '500px' }}>
						<Col>
							<Row
								justify="center"
								style={{
									fontSize: 25,
									fontWeight: '700',
									paddingBottom: '10px',
									color: 'rgb(3 52 97)',
								}}
							>
								ВОССТАНОВЛЕНИЕ ПАРОЛЯ
							</Row>
							<Row
								justify="center"
								style={{
									fontSize: 23,
									fontWeight: 700,
									textAlign: 'center',
									paddingBottom: '30px',
									color: 'rgb(3 52 97)',
									lineHeight: '1',
								}}
							>
								На вашу почту отправлено письмо со ссылкой на смену пароля.
							</Row>
							<Row
								justify="center"
								style={{
									fontSize: 23,
									fontWeight: 700,
									textAlign: 'center',
									paddingBottom: '30px',
									color: 'rgb(3 52 97)',
									lineHeight: '1',
								}}
							>
								Если Вы не получили такое письмо, проверьте папку спам.
							</Row>
						</Col>
						<Form>
							<Row justify="center">
								<Col
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
									}}
								>
									<Button
										onClick={() => history.push('/login')}
										htmlType="submit"
										type="btn"
										style={{
											fontWeight: 600,
											width: '500px',
											height: '50px',
											alignSelf: 'center',
											background: 'rgb(3 52 97)',
											color: 'white',
											marginBottom: '20px',
											borderRadius: '5px',
										}}
									>
										На главную
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Content>
		</Layout>
	)
}

export default SendMail
