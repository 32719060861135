import React, { useCallback, useContext, useMemo, useRef } from 'react'
import lodash from 'lodash'
import { ModContext } from '../../../contexts'
import moment from 'moment'
import {
	getNewObj,
	modes,
	prepareObjFromServer,
	deepFind,
	validateRequired,
	useItemFetchers,
} from '../../../utils'
import { getCommonProviderFunctions } from '../../../utils/helpers/generators'
import { genMonthlyListing, getRemainder } from '../utils'
import { basicValidator, isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'
import { getAccuracy, roundCompare } from '@berry/common-functions/math'
import { checkIsNot } from '@berry/common-functions/obj-arr'
import { useHistory } from 'react-router-dom'
import { entityToModelMap } from '@berry/static-data/all-models'
import {
	commonCheckIsBlocked,
	commonCheckIsBlockedAtLeastOneEntity,
} from '../../../utils/helpers/for-block-unblock'

export const reducer = (state) => ({
	...state,
})

const ContractItemMainContext = React.createContext()
ContractItemMainContext.displayName = 'ContractItemMainContext'

/**
 * @param {{children: React.Component, params: {id:string, contractType:('provider'|'customer')} }} props
 */
const Provider = (props) => {
	const { children, params } = props
	const tabs = useMemo(
		() =>
			params.contractType === 'provider'
				? { orders: 'orders', applications: 'applications', 'provider-quotas': 'providerQuotas' }
				: { applications: 'applications', 'customer-quotas': 'customerQuotas' },
		[params.contractType]
	)
	const modCtx = useContext(ModContext)
	const [state, dispatch] = React.useReducer(reducer, {
		data: {
			applications: [],
			orders: [],
			providerQuotas: [],
			customerQuotas: [],
		},
		oldData: {},
		additional: {
			allSelectPayConditions: [],
			allSelectDeliveryConditions: [],
			allProviders: [],
			allApplications: [],
			allPlatforms: [],
			allProducts: [],
			allProdCatPkgs: [],
			wasteManagement: [
				'Возврат давальцу в полном объёме',
				'Утилизация за счет производства',
				'Утилизация за счет давальца',
			],
			maxApplicationId: null,
		},
		formErrors: {},
		isInitializedMain: false,
		isInitializedAdditional: false,
		tabsLoading: {
			applications: false,
			orders: false,
			providerQuotas: false,
			customerQuotas: false,
		},
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const dataUrl = useMemo(() => {
		if (params.contractType === 'provider') {
			return '/rm/contracts-providers'
		}
		return '/rp/contracts-customers'
	}, [params.contractType])
	const history = useHistory()

	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			formErrors: {},
		})
		return
	}, [])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
		return
	}

	const getErrPlanFields = (quota) => {
		return quota.monthlyListings.reduce((acc, m) => {
			if (['', null, undefined].includes(m.plan)) {
				return [...acc, m]
			}
			return acc
		}, [])
	}

	const checkQuota = (quota) => {
		const errFields = getErrPlanFields(quota)
		if (!errFields.length) {
			const accuracy = getAccuracy(quota.quota)
			if (
				!roundCompare(
					+quota.quota,
					quota.monthlyListings.reduce((sum, listing) => sum + +listing.plan, 0),
					accuracy
				)
			) {
				return setError([], [], quota._uuid_, 'Сумма полей П не равна Квоте')
			}
		}
		for (const f of errFields) {
			setError([], [], f._uuid_, 'Не заполнены обязательные поля на вкладке Распределение квоты')
		}
	}

	const validate = () => {
		stateRef.current.formErrors = {}
		const curState = stateRef.current.data
		const quotas = curState.customerQuotas?.length
			? curState.customerQuotas
			: curState.providerQuotas?.length
			? curState.providerQuotas
			: []
		if (curState.providerQuotas) {
			for (const prov of curState.providerQuotas) {
				if (curState.frame) {
					validateRequired(
						setError,
						`providerQuotas.application.${prov._uuid_}`,
						'',
						prov.application
					)
				}
				validateRequired(setError, `providerQuotas.rmProvProd.${prov._uuid_}`, '', prov.rmProvProd)
				validateRequired(setError, `providerQuotas.quota.${prov._uuid_}`, '', prov.quota || null)
			}
		}
		if (curState.customerQuotas) {
			for (const customer of curState.customerQuotas) {
				if (curState.frame) {
					validateRequired(
						setError,
						`customerQuotas.application.${customer._uuid_}`,
						'',
						customer.application
					)
				}
				validateRequired(
					setError,
					`customerQuotas.prodCat.${customer._uuid_}`,
					'',
					customer.prodCat
				)
				validateRequired(
					setError,
					`customerQuotas.prodCatPkg.${customer._uuid_}`,
					'',
					customer.prodCatPkg
				)
				validateRequired(
					setError,
					`customerQuotas.quota.${customer._uuid_}`,
					'',
					customer.quota || null
				)
			}
		}
		if (Object.values(stateRef.current.formErrors).some((err) => err !== undefined)) {
			return 'Не заполнены обязательные поля на вкладке Распределение квоты'
		}
		if (
			quotas.some((c) => !isValidNum(c.quota) || !isValidNumberDigits(c.quota, 'float', [9, 2]))
		) {
			return 'Поле Квота заполнена неверно'
		}

		for (const q of quotas) {
			checkQuota(q)
		}
		for (const q of quotas) {
			if (q.monthlyListings.some((m) => stateRef.current.formErrors[m._uuid_])) {
				return 'Не заполнены обязательные поля на вкладке Распределение квоты'
			}
			if (stateRef.current.formErrors[q._uuid_]) {
				return 'Сумма полей П не равна Квоте'
			}
		}
		if (!curState.frame && !basicValidator(curState.vocDeliverCondit)) {
			validateRequired(setError, 'provider.vocDeliverCondit', '', curState.vocDeliverCondit)
		}
		if (!curState.frame && !basicValidator(curState.vocPayCondit)) {
			validateRequired(setError, 'provider.vocPayCondit', '', curState.vocPayCondit)
		}
		if (!curState.applications?.length && curState.frame) {
			return 'Добавьте приложение'
		}
		const customerQuotaFields = stateRef.current.frame
			? ['application', 'prodCat', 'quota']
			: ['prodCat', 'quota']
		if (
			curState.customerQuotas?.some(
				(quota) => !checkIsNot(quota, customerQuotaFields, [undefined, null])
			) ||
			curState.providerQuotas.some(
				(quota) => !checkIsNot(quota, ['rmProvProd', 'quota'], [undefined, null])
			)
		) {
			return 'Не заполнены обязательные поля на вкладке Распределение квоты'
		}
		if (
			curState.data?.customerQuotas.some((c) => {
				return c.monthlyListings.some((m) => {
					if (!isValidNum(m.plan)) return false
					return !isValidNumberDigits(m.plan, 'float', [9, 2])
				})
			}) ||
			curState.data?.providerQuotas.some((c) => {
				return c.monthlyListings.some((m) => {
					if (!isValidNum(m.plan)) return false
					return !isValidNumberDigits(m.plan, 'float', [9, 2])
				})
			})
		) {
			return 'Поле П заполнено неверно'
		}
		if (
			curState.data?.customerQuotas.some((c) => !isValidNumberDigits(c.quota, 'float', [9, 2])) ||
			curState.data?.providerQuotas.some((p) => !isValidNumberDigits(p.quota, 'float', [9, 2]))
		) {
			return 'Поле Квота заполнена неверно'
		}
		for (const order of curState.orders) {
			checkAvailable(order)
			for (const field of errorFields.order) {
				if (!basicValidator(order[field])) {
					setError([], [], `${field}.${order._uuid_}`, true)
				}
			}
		}
		if (Object.values(stateRef.current.formErrors).some((val) => !!val))
			return 'Не заполнены обязательные поля'
		if (!curState.providerQuotas?.length && !curState.customerQuotas?.length)
			return 'Не заполнены обязательные поля на вкладке Распределение квоты'
		return stateRef.current.formErrors
	}

	const validators = {
		applications: (inUuid) => {
			const found = stateFunctions.applications.get(inUuid)
			if (
				stateRef.current.data.applications.filter(
					(item) => String(item.displayCode) === String(found.displayCode) && item.id !== found.id
				).length > 0
			) {
				setError([], [], 'application.displayCode', 'Такой номер ДС уже существует')
			} else {
				validateRequired(setError, 'application.displayCode', '', found.displayCode)
			}
			validateRequired(setError, 'application.dateStart', '', found.dateStart)
			validateRequired(setError, 'application.dateEnd', '', found.dateEnd)
			validateRequired(setError, 'application.platform', '', found.platform)
			validateRequired(setError, 'application.paymentCondition', '', found.paymentCondition)
			validateRequired(setError, 'application.deliveryCondition', '', found.deliveryCondition)
			if (errorFields.application.some((e) => stateRef.current.formErrors[e])) throw Error()
		},
		quotas: (inUuid) => {
			const fields = stateRef.current.data.providerQuotas?.length
				? { prod: 'rmProvProd', quotas: 'providerQuotas' }
				: stateRef.current.data.customerQuotas?.length
				? { prod: 'prodCat', quotas: 'customerQuotas' }
				: null
			if (!fields) return
			const { prod, quotas } = fields
			const found = stateFunctions[fields.quotas].get(inUuid)
			validateRequired(setError, `${quotas}.application`, '', found.application)
			validateRequired(setError, `${quotas}.prodCat`, '', found[prod])
			validateRequired(setError, `${quotas}.quota`, '', found.quota)
			if (errorFields[quotas].some((e) => stateRef.current.formErrors[e])) throw Error()
		},
	}

	const generateMonthlyListing = (quota = 0, uuidQuota, provOrCustKey) => {
		let { dateStart, dateEnd } = stateRef?.current?.data
		let oldMonthlyListings = undefined
		let foundQuota = null
		if (uuidQuota) {
			foundQuota = stateFunctions[provOrCustKey].get(uuidQuota)
			if (modCtx.mod !== modes.new && uuidQuota) {
				oldMonthlyListings = foundQuota.monthlyListings
			}
		}
		if (stateRef.current.data.frame) {
			if (!foundQuota || !foundQuota.application) {
				dateStart = moment().startOf('year')
				dateEnd = moment().endOf('year')
			} else {
				dateStart = foundQuota.application.dateStart
				dateEnd = foundQuota.application.dateEnd
			}
		}
		const monthlyListing = genMonthlyListing(
			dateStart?.toString(),
			dateEnd,
			Number(quota),
			oldMonthlyListings
		)
		return monthlyListing.map((m) => getNewObj(m))
	}

	const getRemainderForOrder = (record) => {
		const quota = stateRef.current.data.providerQuotas.find(
			(q) =>
				(q.rmProvProd?.id || q.idRmProvProd) === record.rmProvProd?.id &&
				(q.application?.id || q.idApplication || null) === (record.application?.id || null)
		)
		return quota ? getRemainder(quota) : 0
	}
	const checkAvailable = (record) => {
		const arrToCheck = record
			? [stateRef.current.data.orders.find((o) => o.id === record.id)]
			: stateRef.current.data.orders
		for (const order of arrToCheck) {
			const remainder = getRemainderForOrder(order)
			if (remainder < +order.supplyPlanWeight && !['В работе', 'Выполнен'].includes(order.status)) {
				setError([], [], `supplyPlanWeight.${order._uuid_}`, `Доступная масса ${remainder} кг`)
			} else {
				setError([], [], `supplyPlanWeight.${order._uuid_}`, undefined)
			}
		}
	}

	const errorFields = {
		application: [
			'application.displayCode',
			'application.dateStart',
			'application.dateEnd',
			'application.platform',
			'application.paymentCondition',
			'application.deliveryCondition',
		],
		platform: ['provider.platform', 'provider.vocDeliverCondit', 'provider.vocPayCondit'],
		order: [
			...(stateRef.current.data.frame ? ['application'] : []),
			'rmProvProd',
			'prodCatPkg',
			'wasteManagement',
			'supplyPlanWeight',
			'unloadDatePlan',
		],
	}
	const selectors = {
		providerQuotas: { application: stateRef.current.data.applications },
		customerQuotas: { application: stateRef.current.data.applications },
	}
	const {
		stateFunctions,
		serverEdit,
		isEdited,
		commonFieldUpdate,
		commonDeepObjUpdate,
		commonDeepFieldUpdate,
		setError,
		serverRemove,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{
			modCtx,
			dataUrl,
			pageUrl: dataUrl,
			history,
			params,
		},
		selectors,
		{
			vocDeliverCondit: 'common',
			vocPayCondit: 'common',
			platform: 'common',
			provider: 'common',
			customer: 'common',
			isProtDisagr: 'common',
			num: 'common',
			dateStart: 'common',
			dateEnd: 'common',
			tolling: 'common',
			frame: 'common',
			autoProlongation: 'common',
		},
		{
			applications: {
				displayCode: 'common',
				dateStart: 'common',
				dateEnd: 'common',
				paymentCondition: 'common',
				deliveryCondition: 'common',
				platform: 'common',
			},
			orders: {
				application: 'common',
				unloadDatePlan: 'common',
				comment: 'common',
				rmProvProd: 'common',
				wasteManagement: 'common',
				supplyPlanWeight: 'common',
				prodCatPkg: 'common',
			},
			customerQuotas: {
				monthlyListings: 'common',
				application: 'common',
				prodCat: 'common',
				prodCatPkg: 'common',
				quota: 'common',
				plan: 'common',
				fact: 'common',
			},
			providerQuotas: {
				monthlyListings: 'common',
				application: 'common',
				rmProvProd: 'common',
				quota: 'common',
				plan: 'common',
				fact: 'common',
			},
		}
	)

	stateFunctions.applications.updateMontlyListingByUpdatingDates = (inUuid) => {
		const applic = stateFunctions.applications.get(inUuid)
		const key = params.contractType === 'customer' ? 'customerQuotas' : 'providerQuotas'
		stateRef.current.data[key].forEach((q) => {
			if (String(q?.application?.id) === String(applic?.id)) {
				commonDeepObjUpdate([key], [q._uuid_], 'application', applic)
				commonDeepFieldUpdate([key], [q._uuid_], 'monthlyListings', [])
				commonDeepFieldUpdate(
					[key],
					[q._uuid_],
					'monthlyListings',
					generateMonthlyListing(q.quota, q._uuid_, key)
				)
			}
		})
	}
	const getInitialQuota = () => {
		return {
			residue: 0,
			monthlyListings: generateMonthlyListing(),
		}
	}
	stateFunctions.applications.create = () => {
		const id =
			Math.max(
				...stateRef.current.data.applications.map((a) => a.id),
				stateRef.current.additional.maxApplicationId
			) + 1
		const newApps = [...stateRef.current.data.applications, getNewObj({ id })]
		commonFieldUpdate('applications', newApps)
		return { id: newApps.slice(-1)[0].id, _uuid_: newApps.slice(-1)[0]._uuid_ }
	}
	stateFunctions.customerQuotas.create = () => {
		const newQuotas = [getNewObj(getInitialQuota()), ...stateRef.current.data.customerQuotas]
		commonFieldUpdate('customerQuotas', newQuotas)
		return newQuotas.slice(-1)[0]._uuid_
	}
	stateFunctions.customerQuotas.monthlyListings = {
		dropPlan: (inUuid) => {
			const foundQuota = lodash.cloneDeep(deepFind(['customerQuotas'], [inUuid], stateRef.current))
			foundQuota.monthlyListings.forEach((ml) => {
				ml.plan = 0
			})
			commonDeepFieldUpdate(
				['customerQuotas'],
				[inUuid],
				'monthlyListings',
				foundQuota.monthlyListings
			)
		},
		fieldSetters: {
			setPlan: (inParams, val) => {
				const { quotaUuid, monthUuid } = inParams
				delete stateRef.current.formErrors[quotaUuid]
				commonDeepFieldUpdate(
					['customerQuotas', 'monthlyListings'],
					[quotaUuid, monthUuid],
					'plan',
					val
				)
				if (!val ?? !isValidNum(val)) {
					return setError([], [], monthUuid, true)
				} else {
					delete stateRef.current.formErrors[monthUuid]
				}
				if (
					stateFunctions.customerQuotas
						.get(quotaUuid)
						.monthlyListings.some(
							(l) => l._uuid_ !== monthUuid && stateRef.current.formErrors[l._uuid_]
						)
				) {
					return
				}
				const quota = stateFunctions.customerQuotas.get(quotaUuid)
				const accuracy = getAccuracy(quota.quota)
				if (
					roundCompare(
						+quota.quota,
						quota.monthlyListings.reduce((sum, listing) => +sum + +listing.plan, 0),
						+accuracy
					)
				) {
					delete stateRef.current.formErrors[quotaUuid]
				} else {
					setError([], [], quotaUuid, true)
				}
			},
			setFact: (inParams, val) => {
				const { quotaUuid, monthUuid } = inParams
				commonDeepFieldUpdate(
					['customerQuotas', 'monthlyListings'],
					[quotaUuid, monthUuid],
					'fact',
					val
				)
			},
		},
	}
	stateFunctions.providerQuotas.create = () => {
		const newQuotas = [getNewObj(getInitialQuota()), ...stateRef.current.data.providerQuotas]
		commonFieldUpdate('providerQuotas', newQuotas)
		return newQuotas.slice(-1)[0]._uuid_
	}
	stateFunctions.providerQuotas.monthlyListings = {
		dropPlan: (inUuid) => {
			const foundQuota = lodash.cloneDeep(deepFind(['providerQuotas'], [inUuid], stateRef.current))
			foundQuota.monthlyListings.forEach((ml) => {
				ml.plan = 0
			})
			commonDeepFieldUpdate(
				['providerQuotas'],
				[inUuid],
				'monthlyListings',
				foundQuota.monthlyListings
			)
		},
		fieldSetters: {
			setPlan: (inParams, val) => {
				const { quotaUuid, monthUuid } = inParams
				delete stateRef.current.formErrors[quotaUuid]
				commonDeepFieldUpdate(
					['providerQuotas', 'monthlyListings'],
					[quotaUuid, monthUuid],
					'plan',
					val
				)
				if (!val ?? !isValidNum(val)) {
					return setError([], [], monthUuid, true)
				} else {
					delete stateRef.current.formErrors[monthUuid]
				}
				if (
					stateFunctions.providerQuotas
						.get(quotaUuid)
						.monthlyListings.some(
							(l) => l._uuid_ !== monthUuid && stateRef.current.formErrors[l._uuid_]
						)
				) {
					return
				}
				const quota = stateFunctions.providerQuotas.get(quotaUuid)
				const accuracy = getAccuracy(quota.quota)
				if (
					roundCompare(
						+quota.quota,
						quota.monthlyListings.reduce((sum, listing) => +sum + +listing.plan, 0),
						+accuracy
					)
				) {
					delete stateRef.current.formErrors[quotaUuid]
				} else {
					setError([], [], quotaUuid, true)
				}
			},
			setFact: (inParams, val) => {
				const { quotaUuid, monthUuid } = inParams
				commonDeepFieldUpdate(
					['providerQuotas', 'monthlyListings'],
					[quotaUuid, monthUuid],
					'fact',
					val
				)
			},
		},
	}
	stateFunctions.setApplication = (inUuid, val, key) => {
		commonDeepObjUpdate([key], [inUuid], 'application', val)
		commonDeepFieldUpdate([key], [inUuid], 'monthlyListings', null)
		const foundQuota = stateFunctions[key].get(inUuid)
		commonDeepFieldUpdate(
			[key],
			[inUuid],
			'monthlyListings',
			generateMonthlyListing(foundQuota.quota, inUuid, key)
		)
		validateRequired(setError, `${key}.application.${inUuid}`, '', val)
	}
	stateFunctions.setQuota = (inUuid, val, key) => {
		commonDeepFieldUpdate([key], [inUuid], 'quota', val)
		commonDeepFieldUpdate(
			[key],
			[inUuid],
			'monthlyListings',
			generateMonthlyListing(val, inUuid, key)
		)
		validateRequired(setError, `${key}.quota.${inUuid}`, '', val)
	}
	stateFunctions.providerQuotas.setApplication = (inUuid, val) => {
		stateFunctions.setApplication(inUuid, val, 'providerQuotas')
	}
	stateFunctions.customerQuotas.setApplication = (inUuid, val) => {
		stateFunctions.setApplication(inUuid, val, 'customerQuotas')
	}
	stateFunctions.providerQuotas.setQuota = (inUuid, val) => {
		stateFunctions.setQuota(inUuid, val, 'providerQuotas')
	}
	stateFunctions.customerQuotas.setQuota = (inUuid, val) => {
		stateFunctions.setQuota(inUuid, val, 'customerQuotas')
	}

	const checkIsBlockedProviderQuota = async () => {
		if (
			!stateRef.current.data.providerQuotas?.length &&
			!stateRef.current.oldData.providerQuotas?.length
		)
			return false

		return await commonCheckIsBlockedAtLeastOneEntity([
			...stateRef.current.data.providerQuotas?.map((q) => {
				return { entity: 'contrProviderQuota', id: q.id }
			}),
			...stateRef.current.oldData.providerQuotas?.map((q) => {
				return { entity: 'contrProviderQuota', id: q.id }
			}),
		])
	}

	const value = {
		state: stateRef.current.data,
		isPendingReq: stateRef.current.isPendingReq,
		additional: stateRef.current.additional,
		checkIsBlockedProviderQuota,
		delEditDeps: stateRef.current.delEditDeps,
		stateFunctions,
		checkAvailable,
		reset,
		serverEdit,
		serverRemove,
		generateMonthlyListing,
		validate,
		isEdited,
		validators,
		setError,
		selectors,
		errorFields,
		formErrors: stateRef.current.formErrors,
		getRemainderForOrder,
	}
	return (
		<ContractItemMainContext.Provider value={value}>{children}</ContractItemMainContext.Provider>
	)
}

export { Provider, ContractItemMainContext }
