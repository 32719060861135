import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

const LeftTooltip = (props) => {
	const { tooltip, label } = props
	return (
		<Tooltip title={tooltip}>
			<QuestionCircleOutlined></QuestionCircleOutlined>
			{` ${label}`}
		</Tooltip>
	)
}

export default LeftTooltip
