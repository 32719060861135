import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import moment from 'moment'
import { ModContext, AuthContext, FileContext } from '../../../../contexts'
import { addFile as addNewFile } from '../../../../utils/helpers/for-files'
import {
	getCommonProviderFunctions,
	getCommonProviderModalFunctions,
} from '../../../../utils/helpers/generators'
import {
	prepareObjFromServer,
	modes,
	openNewTab,
	useItemFetchers,
	goToItem,
	appendFiles,
	axios,
} from '../../../../utils'
import { getRemainder } from '../../../Contract/utils'
import { basicValidator } from '@berry/common-functions/validators'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
import { getSupplyFactWeight } from '../../../Contract/OrderTab/utils'
import { useHistory } from 'react-router-dom'
import { commonCheckIsBlocked } from '../../../../utils/helpers/for-block-unblock'
import { message } from 'antd'
import { v4 } from 'uuid'

const dataUrl = '/rm/requests'
const tabs = { documents: 'documents', supplies: 'supplies', products: 'products' }

export const reducer = (state) => ({
	...state,
})

const requiredFields = {
	products: ['contrQuota', 'order', 'vocContType', 'weight', 'harvestYear', 'date'],
	documents: ['label'],
}
const transformProductWeight = (obj, symbol) => {
	obj?.products?.forEach((p) => {
		if (symbol === '/') {
			p.weight = Math.round(p.weight * 10000) / 10000
		}
		if (symbol === '*') {
			p.weight = Math.round(p.weight * 100) / 100
		}
	})
}

const getInitialState = () => {
	let data = {
		date: moment(),
		status: 'Новая',
		supplies: [],
		products: [],
		documents: [],
	}
	let supplyReqCopy = null
	if (localStorage.getItem('supplyReqCopy')) {
		supplyReqCopy = JSON.parse(localStorage.getItem('supplyReqCopy'))
		if (supplyReqCopy) {
			localStorage.removeItem('supplyReqCopy')
			data = supplyReqCopy
		}
	}
	transformProductWeight(data, '*')
	prepareObjFromServer(data)
	return {
		supplyReqCopy,
		data: data,
		oldData: data,
		additional: {
			allContracts: [],
			allSelectContainerTypes: [],
			contractProviderFilters: {},
			allRequests: [],
			allOutdoorWhs: [],
		},
		formErrors: [],
		isInitializedMain: false,
		isInitializedAdditional: false,
		tabsLoading: {
			supplies: false,
			documents: false,
			products: false,
		},
		addContrQuota: {
			__isOpen: false,
			uuidProd: null,
			addContrQuotaContrQuotas: [],
		},
		addContract: {
			__isOpen: false,
			addContractContracts: [],
		},
		addOrder: {
			__isOpen: false,
			uuidProd: null,
			addOrderOrders: [],
		},
	}
}

const RequestItemMainContext = React.createContext()
RequestItemMainContext.displayName = 'RequestItemMainContext'

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const fileCtx = useContext(FileContext)
	const authCtx = useContext(AuthContext)
	const [state, dispatch] = React.useReducer(reducer, getInitialState())
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))
	useEffect(() => {
		const documents = lodash.cloneDeep(stateRef.current.data.documents || [])
		const allFiles = documents.filter((item) => item.files).flatMap((item) => item.files)
		fileCtx.addFiles(allFiles, 'filePath')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const _reset = useCallback(() => {
		// проверка с id нужна для того чтобы не подумать что мы создаем копию на этой же странице
		if (stateRef.current.supplyReqCopy && params?.id === 'new') {
			executeDispatch({
				supplyReqCopy: stateRef.current.supplyReqCopy,
				data: lodash.cloneDeep(stateRef.current.supplyReqCopy),
				addContrQuota: lodash.cloneDeep(stateRef.current.addContrQuota),
				addContract: lodash.cloneDeep(stateRef.current.addContract),
				addOrder: lodash.cloneDeep(stateRef.current.addOrder),
			})
		} else {
			const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
			prepareObjFromServer(recordFromDataSrvCtx)
			transformProductWeight(recordFromDataSrvCtx, '*')
			executeDispatch({
				...stateRef.current,
				data: recordFromDataSrvCtx,
				formErrors: {},
				addContrQuota: {
					__isOpen: false,
					uuidProd: null,
					addContrQuotaContrQuotas: stateRef.current.products?.map((p) => p.contrQuota) || [],
				},
				addContract: {
					__isOpen: false,
					addContractContracts: stateRef.current.products?.map((p) => p.contract) || [],
				},
				addOrder: {
					__isOpen: false,
					uuidProd: null,
					addOrderOrders: [],
				},
			})
		}
	}, [params.id])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
		return
	}

	useEffect(() => {
		if (params.id !== 'new') {
			_reset()
		}
	}, [_reset, stateRef.current.oldData, params.id])

	const checkAvailable = (prod) => {
		for (let prod of stateRef.current.data.products) {
			if (prod.contrQuota) {
				const remainder = getRemainder(prod.contrQuota)
				const filteredByContrQuota = prod.contrQuota
					? stateRef.current.data.products.filter(
							(p) => p.contrQuota?.id && p.contrQuota.id === prod.contrQuota.id
					  )
					: null
				const filteredByOrder = prod.order
					? stateRef.current.data.products.filter(
							(p) => p.order?.id && p.order.id === prod.order.id
					  )
					: null
				const filteredByContract =
					(filteredByContrQuota || filteredByOrder)?.filter(
						(p) => p.contract?.id === prod.contract?.id
					) || []
				const noWeightErrorProducts = filteredByContract.reduce((acc, cur) => {
					if (+cur.weight > remainder) {
						return acc
					}
					if (
						(+cur.weight + acc.reduce((sum, p) => sum + (+p.weight || 0), 0)).toFixed(2) > remainder
					) {
						return acc
					}
					return [...acc, cur]
				}, [])
				const reservedWeight = noWeightErrorProducts.reduce(
					(sum, cur) => sum + (+cur.weight || 0),
					0
				)
				const actualRemainder = remainder - reservedWeight
				for (const p of filteredByContract) {
					if (
						p.contract?.id === prod.contract?.id &&
						!noWeightErrorProducts.find((pr) => pr._uuid_ === p._uuid_) &&
						+p.weight > actualRemainder
					) {
						setError([], [], p._uuid_, `Доступная масса = ${numToFixed(actualRemainder, 2)} кг`)
					} else {
						setError([], [], p._uuid_, null)
					}
				}
				if (noWeightErrorProducts.length !== filteredByContract.length)
					return `Масса не должна превышать остаток`
			} else if (prod.order) {
				const totalWeight = stateRef.current.data.products
					.filter((p) => p.order?.id === prod.order?.id)
					.reduce((sum, cur) => sum + (+cur.weight || 0), 0)
				const availableWeight = getAvailableWeightForProd(prod)
				if (totalWeight > availableWeight) {
					setError([], [], prod._uuid_, `Доступная масса = ${numToFixed(availableWeight, 2)} кг`)
					return 'Масса не должна превышать остаток'
				} else {
					setError([], [], prod._uuid_, stateRef.current.formErrors[`weight.${prod._uuid_}`])
				}
			}
		}
		return ''
	}
	const dropProdErrorsByUuid = (uuidProd) => {
		let newErrors = {}
		for (const k of Object.keys(stateRef.current.formErrors)) {
			if (!k.includes(uuidProd)) {
				newErrors[k] = stateRef.current.formErrors[k]
			}
		}
		executeDispatch({
			...stateRef.current,
			formErrors: newErrors,
		})
	}
	const validators = {
		product: (inUuid) => {
			const found = stateFunctions.products.get(inUuid)
			if (!basicValidator(found.weight) || +found.weight === 0) {
				setError([], [], found._uuid_, true)
			}
		},
	}
	const validate = () => {
		executeDispatch({
			...stateRef.current,
			formErrors: {},
		})
		const data = stateRef.current.data
		if (!data.products.length) return 'Не добавлен ни один продукт'
		for (const field of Object.keys(requiredFields)) {
			requiredFields[field].forEach((requiredField) => {
				if (data[field]) {
					data[field].forEach((el) => {
						if (requiredField === 'contrQuota' && (el.order?.id || el.idContrOrder)) {
							return
						}
						if (requiredField === 'order' && (el.contrQuota?.id || el.idContrQuota)) {
							return
						}
						if (!basicValidator(el[requiredField])) {
							setError([], [], `${requiredField}.${el._uuid_}`, true)
						}
					})
				}
			})
		}
		if (checkAvailable()) {
			throw Error()
		}
		if (Object.values(stateRef.current.formErrors).some((val) => !!val)) {
			return 'Не заполнены обязательные поля'
		}
		return ''
	}
	const validateContrQuota = () => {
		dropProdErrorsByUuid(stateRef.current.addContrQuota.uuidProd)
		if (!stateRef.current.addContrQuota.addContrQuotaContrQuotas?.length) {
			throw Error('Не выбрана строка')
		}
	}
	const validateContract = () => {
		if (!stateRef.current.addContract.addContractContracts?.length) {
			throw Error('Не выбран договор')
		}
	}
	const validateOrder = () => {
		if (!stateRef.current.addOrder.addOrderOrders?.length) {
			throw Error('Не выбрана строка')
		}
	}
	const getAvailableWeightForProd = (prod) => {
		if (!prod.order && !prod.contrQuota) return 0
		if (prod.contrQuota) {
			const allProds = stateRef.current.data.products.filter((p) => {
				return p.contrQuota?.id === prod.contrQuota?.id
			})
			const remainder = numToFixed(getRemainder(prod.contrQuota), 2)
			return numToFixed(remainder - +allProds.reduce((acc, cur) => acc + (+cur.weight || 0), 0), 2)
		}
		const planWeight = prod.order?.supplyPlanWeight || numToFixed(getRemainder(prod.contrQuota), 2)
		const factWeight = numToFixed(
			prod.order?.id
				? getSupplyFactWeight(prod.order.id, stateRef.current.data.supplies)
				: getSupplyFactWeight(prod.contrQuota.id, stateRef.current.data.supplies, 'quota'),
			2
		)
		return planWeight ? planWeight - factWeight : 0
	}
	const {
		stateFunctions,
		getEditedData,
		serverDelete,
		isEdited,
		commonFieldUpdate,
		commonObjUpdate,
		commonDeepFieldUpdate,
		setError,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{ modCtx, dataUrl, params, pageUrl: '/rm/supply-requests', history, requiredFields },
		{ outdoorWh: stateRef.current.additional.allOutdoorWhs },
		{
			vehicleNum: 'common',
			trailerNum: 'common',
			driverPhone: 'common',
			comment: 'common',
			supplyDatePlan: 'common',
		},
		{
			products: {
				contrQuota: 'common',
				order: 'common',
				vocContType: 'common',
				vNaval: 'common',
				containerWeight: 'common',
				harvestYear: 'common',
				weight: 'common',
				date: 'common',
				isReadyProdResale: 'common',
			},
			documents: {
				label: 'common',
				date: 'common',
			},
		}
	)
	const { modalFunctions: contrQuotaModalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		{},
		[
			{
				field: 'uuidProd',
				updateVal: 'common',
				modalName: 'addContrQuota',
			},
			{
				field: 'contrQuotas',
				updateVal: 'common',
				modalName: 'addContrQuota',
			},
		],
		[
			{
				mainField: 'contrQuotas',
				field: 'contrQuotas',
				updateVal: 'obj',
				modalName: 'addContrQuota',
			},
			{
				mainField: 'contrQuotas',
				field: 'weight',
				updateVal: 'common',
				modalName: 'addContrQuota',
			},
		]
	)
	const { modalFunctions: contractModalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		{},
		[
			{
				field: 'uuidContract',
				updateVal: 'common',
				modalName: 'addContract',
			},
			{
				field: 'contracts',
				updateVal: 'obj',
				modalName: 'addContract',
			},
		],
		[
			{
				mainField: 'contracts',
				field: 'contracts',
				updateVal: 'obj',
				modalName: 'addContract',
			},
		]
	)
	const { modalFunctions: orderModalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		{},
		[
			{
				field: 'uuidProd',
				updateVal: 'common',
				modalName: 'addOrder',
			},
			{
				field: 'orders',
				updateVal: 'common',
				modalName: 'addOrder',
			},
		],
		[
			{
				mainField: 'orders',
				field: 'orders',
				updateVal: 'obj',
				modalName: 'addOrder',
			},
		]
	)

	stateFunctions.setOutdoorWh = (val) => {
		commonFieldUpdate('idVocOutdoorWh', val)
		commonObjUpdate('outdoorWh', val)
	}

	const copyRequest = () => {
		const fieldsToDrop = [
			'status',
			'id',
			'displayCode',
			'date',
			'supplyDatePlan',
			'vehicleNum',
			'trailerNum',
			'driverPhone',
			'supply',
			'idVocOutdoorWh',
			'outdoorWh',
		]
		let supplReqCopyData = lodash.cloneDeep(stateRef.current.oldData)
		for (const field of fieldsToDrop) {
			supplReqCopyData[field] = null
		}
		supplReqCopyData.products.forEach((e) => {
			delete e.id
		})
		supplReqCopyData.documents.forEach((e) => {
			e.files.forEach((file) => {
				delete file.id
			})
			delete e.id
		})
		supplReqCopyData.supplies = []
		supplReqCopyData['status'] = 'Новая'
		supplReqCopyData['date'] = moment().format('YYYY-MM-DD')
		supplReqCopyData['supplyDatePlan'] = null
		openNewTab('/rm/supply-requests/new', {
			authCtx: authCtx.state,
			modCtx: {
				mod: modes.new,
			},
			supplyReqCopy: supplReqCopyData,
		})
	}
	const getActualState = () => {
		const result = lodash.cloneDeep(stateRef.current.data)
		const products = result.products
			.sort((a, b) => a.contract.id - b.contract.id)
			.map((prod) => {
				const harvestYear = !prod.harvestYear
					? null
					: moment.isMoment(prod.harvestYear)
					? prod.harvestYear
					: moment(prod.harvestYear, 'YYYY-MM-DD')
				return {
					...prod,
					harvestYear,
				}
			})
		return { ...result, products }
	}
	stateFunctions.documents.addFile = async (inUuid, vals) => {
		addNewFile(vals, 'filePath', 'sup_req')
		commonDeepFieldUpdate(['documents'], [inUuid], 'files', vals)
		await fileCtx.addFiles(lodash.cloneDeep(vals.filter((e) => e.originFileObj)), 'filePath')
	}
	stateFunctions.documents.removePhoto = (inUuid, photo) => {
		let newFiles
		newFiles = stateFunctions.documents.get(inUuid)?.files.filter((p) => {
			return photo.filePath
				? p.filePath !== photo.filePath
				: p.filePath !== photo.originFileObj?.filePath
		})
		commonDeepFieldUpdate(['documents'], [inUuid], 'files', newFiles)
	}

	const checkIsBlocked = async () => {
		const result = await commonCheckIsBlocked([{ entity: 'rmSupplyRequest', id: params.id }])
		return result[0]?.isBlocked
	}

	const serverEdit = async () => {
		try {
			const formData = appendFiles(
				stateRef.current.data.documents,
				'files',
				'_uuid_',
				'sup_req_doc',
				{
					key: 'filePath',
				}
			)
			let body
			let options = { url: dataUrl }
			if (params.id === 'new') {
				body = lodash.cloneDeep(stateRef.current.data)
				options.method = 'POST'
			} else {
				body = getEditedData()
				options.method = 'PUT'
			}
			formData.append('data', JSON.stringify(body))
			executeDispatch({ ...stateRef.current, isPendingReq: true })
			const res = await axios[options.method.toLowerCase()](options.url, formData)
			executeDispatch({ ...stateRef.current, isPendingReq: false })
			modCtx.set(modes.view)
			if (res.data?.data?.id) {
				if (options.method === 'POST') {
					goToItem(history, { url: dataUrl, id: res.data.data.id })
				}
				executeDispatch({ ...stateRef.current, reloadUuid: v4() })
				modCtx.set(modes.view)
				return { method: options.method, id: res.data.data.id }
			}
			return res
		} catch (err) {
			message.error('Ошибка при сохранении')
			console.log(err.message)
		}
	}

	const value = {
		state: getActualState(),
		checkIsBlocked,
		additional: stateRef.current.additional,
		isPendingReq: stateRef.current.isPendingReq,
		stateFunctions,
		validators,
		serverEdit,
		serverDelete,
		isEdited,
		validate,
		commonDeepFieldUpdate,
		reset,
		addContract: lodash.cloneDeep(stateRef.current.addContract),
		addContrQuota: lodash.cloneDeep(stateRef.current.addContrQuota),
		addOrder: lodash.cloneDeep(stateRef.current.addOrder),
		modalFunctions: {
			contrQuotaModalFunctions,
			contractModalFunctions,
			orderModalFunctions,
		},
		validateContrQuota,
		validateContract,
		validateOrder,
		copyRequest,
		formErrors: stateRef.current.formErrors,
		setError,
		getAvailableWeightForProd,
		checkAvailable,
	}

	return <RequestItemMainContext.Provider value={value}>{children}</RequestItemMainContext.Provider>
}

export { Provider, RequestItemMainContext }
