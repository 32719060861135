import { Checkbox } from 'antd'
import { modes } from '../../../utils'
import { CommonSelect, Status } from '../../../components'
import ApplicationTab from '../ApplicationTab'
import QuotaTab from '../QuotaTab/QuotaTab'
import { isContractActive, changeDateOutput } from '../utils'
import { dateFormat } from '../../../utils/constants/common'
import moment from 'moment'
import MainCardClickableAttr from '../../../components/MainCardClickableAttr/MainCardClickableAttr'

const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 },
}

const layoutRight = {
	labelCol: {
		sm: 8,
		lg: 8,
		xl: { span: 9, offset: 10 },
	},
	wrapperCol: { lg: 6, xl: 14 },
}

const layoutMid = {
	labelCol: { sm: 8, lg: 8, xl: { span: 10, offset: 2 } },
	wrapperCol: { sm: 14, lg: 14, xl: 12 },
}
const pageRights = {
	'АРМ офиса': {
		Поставщики: {
			view: true,
		},
	},
}
export const getFormItems = (props) => {
	const { mainCtx, modCtx } = props
	const isDisabled = modCtx.mod === modes.view

	const items = [
		[
			{ layout: layout, label: 'Номер договора', field: mainCtx.state.num },
			{
				layout: layout,
				label: 'Дата заключения',
				field: mainCtx.state.dateStart ? moment(mainCtx.state.dateStart).format(dateFormat) : null,
			},
			{
				layout: layout,
				label: 'Срок договора',
				field: changeDateOutput(mainCtx.state)
					? moment(changeDateOutput(mainCtx.state)).format(dateFormat)
					: changeDateOutput(mainCtx.state),
			},
		],
		[
			{
				layout: mainCtx.state.frame ? layoutMid : layout,
				label: 'Рамочный',
				field: <Checkbox disabled checked={mainCtx.state.frame} />,
			},
			{
				layout: mainCtx.state.frame ? layoutMid : layout,
				label: 'Автопродление договора',
				field: <Checkbox disabled checked={mainCtx.state.autoProlongation} />,
			},
		],
		[],
	]
	if (!mainCtx.state.frame) {
		items[0].push({
			layout: layout,
			label: 'Заказчик',
			field: (
				<MainCardClickableAttr
					text={mainCtx.state.customer?.displayVal}
					pageRights={pageRights}
					url={`/rp/customers/${mainCtx.state.customer?.id}`}
					formProps={props}
				/>
			),
		})
		items[1].push(
			{
				layout: layout,
				label: 'Протокол разногласий',
				field: (
					<Checkbox
						disabled={isDisabled}
						onChange={(e) => mainCtx.stateFunctions.setIsProtDisagr(e.target.checked)}
						checked={mainCtx.state.isProtDisagr}
					/>
				),
			},
			{
				layout: layout,
				label: 'Статус',
				field: <Status status={isContractActive(mainCtx.state) ? 'Действующий' : 'Закрытый'} />,
			}
		)
		items[2].push(
			{
				layout: layout,
				label: 'Площадка',
				required: true,
				name: 'platform',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.platform}
						setValue={mainCtx.stateFunctions.setPlatform}
						isDisabled={isDisabled}
						showSearch
						dataArr={mainCtx.additional.allPlatforms.filter(
							(p) => mainCtx.state.customer && mainCtx.state.customer.id === p.customer?.id
						)}
					/>
				),
			},
			{
				layout: layout,
				label: 'Условия доставки',
				required: true,
				name: 'vocDeliverCondit',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.vocDeliverCondit}
						setValue={mainCtx.stateFunctions.setVocDeliverCondit}
						isDisabled={isDisabled}
						dataArr={mainCtx.additional.allSelectDeliveryConditions}
					/>
				),
			},
			{
				layout: layout,
				label: 'Условия оплаты',
				required: true,
				name: 'vocPayCondit',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.setVocPayCondit}
						setValue={mainCtx.stateFunctions.setVocPayCondit}
						isDisabled={isDisabled}
						dataArr={mainCtx.additional.allSelectPayConditions}
					/>
				),
			}
		)
	} else {
		items[1].unshift({
			layout: mainCtx.state.frame ? layoutMid : layout,
			label: 'Заказчик',
			field: (
				<MainCardClickableAttr
					text={mainCtx.state.customer?.displayVal}
					pageRights={pageRights}
					url={`/rp/customers/${mainCtx.state.customer?.id}`}
					formProps={props}
				/>
			),
		})
		items[2].push(
			{
				layout: layoutRight,
				label: 'Протокол разногласий',
				field: (
					<Checkbox
						disabled={isDisabled}
						onChange={(e) => mainCtx.stateFunctions.setIsProtDisagr(e.target.checked)}
						checked={mainCtx.state.isProtDisagr}
					/>
				),
				name: 'isProtDisagr',
			},
			{
				layout: layoutRight,
				label: 'Статус',
				field: <Status status={isContractActive(mainCtx.state) ? 'Действующий' : 'Закрытый'} />,
				name: 'status',
			}
		)
	}

	return items
}

/**
 * возвращает вкладки которые должны быть отображены
 * @param {Object} mainCtx
 */
export const getMinorTabs = (mainCtx) => {
	const result = []
	if (mainCtx.state.frame)
		result.push({ key: 0, title: 'Приложения', component: <ApplicationTab /> })
	result.push({
		key: result.length,
		title: 'Распределение квоты',
		component: <QuotaTab provOrCustKey="customerQuotas" />,
	})

	return result
}
