import React from 'react'
import { Form as FormAntd, Input, Checkbox, Select } from 'antd'

const formItemLayout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

const Form = (props) => {
	const { label, form, isSupplyRaw, isMandatAvail, type } = props

	return (
		<FormAntd form={form} labelAlign="left">
			<FormAntd.Item
				{...formItemLayout}
				label="Наименование"
				getValueProps={(value) => value}
				name="label"
				onChange={label.onChange}
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Input value={label.value} />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Поставка сырья"
				name="isReadyProd"
				valuePropName="isReadyProd"
				getValueProps={(value) => value}
				onChange={isSupplyRaw.onChange}
			>
				<Checkbox checked={isSupplyRaw.value} />
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Обязательное наличие"
				name="isMandatAvail"
				valuePropName="isMandatAvail"
				getValueProps={(value) => value}
				onChange={isMandatAvail.onChange}
			>
				<Checkbox checked={isMandatAvail.value} />
			</FormAntd.Item>

			<FormAntd.Item
				{...formItemLayout}
				label="Тип"
				getValueProps={(value) => value}
				name="type"
				onChange={type.onChange}
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Select
					value={type.value}
					onChange={(el) => type.onChange(el)}
					style={{ width: '100%', minWidth: '120px' }}
				>
					{[
						{ label: 'Качественный', id: 1, key: 1 },
						{ label: 'Бухгалтерский', id: 2, key: 2 },
					].map((it) => {
						return (
							<Select.Option key={it.id} value={it.label}>
								{it.label}
							</Select.Option>
						)
					}) || []}
				</Select>
			</FormAntd.Item>
		</FormAntd>
	)
}

export default Form
