import { calSupplyInpCtrlPalDistrWeightNetto } from '@berry/common-functions/cross-project-functions'
import { setNumField } from '@berry/front-common/for-fields'
import { Col, Select } from 'antd'
import { EditDelBtns, InputError } from '../../../../components'
import { modes, openNewTab } from '../../../../utils'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 * @param {Object} modCtx
 * @param {function} editHandler - обработка нажатия на карандаш
 */

export const getDisplayColumns = (mainCtx, modCtx, authCtx, editHandler) => {
	const stateFunctions = mainCtx.stateFunctions.unldPalDistribs
	let isDisabled = false
	if (modCtx.mod === modes.view) isDisabled = true
	const result = []
	if (!mainCtx.state.unldIsStandPkg) {
		result.push({
			title: '№ паллеты',
			key: 'displayCode',
			width: '6.57%',
			render: (_, __, indx) => {
				return indx + 1
			},
		})
		result.push({
			title: 'Масса брутто, кг',
			key: 'brutto',
			width: '8.17%',
			rules: [{ required: true }],
			render: (_, rec) => {
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`brutto.${rec._uuid_}`]}
						style={{ width: '100%' }}
						value={rec.brutto}
						disabled={isDisabled}
						onChange={(e) => {
							stateFunctions.setBrutto(rec._uuid_, e.target.value)
							// return setNumField(
							// 	e.target.value,
							// 	rec.brutto,
							// 	stateFunctions.setBrutto,
							// 	'float',
							// 	[6, 2],
							// 	{ _uuid_: rec._uuid_ }
							// )
						}}
					/>
				)
			},
		})
	} else {
		result.push({
			title: 'Кол-во паллет, шт',
			key: 'palCount',
			width: '10.76%',
			rules: [{ required: true }],
			render: (_, rec) => {
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`palCount.${rec._uuid_}`]}
						style={{ width: '100%' }}
						value={rec.palCount}
						disabled={isDisabled}
						onChange={(e) => {
							return setNumField(
								e.target.value,
								rec.palCount,
								stateFunctions.setPalCount,
								'int',
								[10, 0],
								{ _uuid_: rec._uuid_ }
							)
						}}
					/>
				)
			},
		})
	}
	result.push({
		title: 'Кол-во упак. на паллете, шт',
		key: 'palPkgCount',
		width: !mainCtx.state.unldIsStandPkg ? '9.94%' : '15.79%',
		render: (_, rec) => {
			return (
				<InputError
					hasError={mainCtx.formErrors?.[`palPkgCount.${rec._uuid_}`]}
					style={{ width: '100%' }}
					value={rec.palPkgCount}
					disabled={isDisabled}
					onChange={(e) => {
						return setNumField(
							e.target.value,
							rec.palPkgCount,
							stateFunctions.setPalPkgCount,
							'int',
							[10, 0],
							{ _uuid_: rec._uuid_ }
						)
					}}
				/>
			)
		},
	})
	if (!mainCtx.state.unldIsStandPkg) {
		result.push({
			title: 'Масса упак., кг',
			key: 'pkgWeight',
			width: '8.58%',
			rules: [{ required: true }],
			render: (_, rec) => {
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`pkgWeight.${rec._uuid_}`]}
						style={{ width: '100%' }}
						value={rec.pkgWeight}
						disabled={isDisabled}
						onChange={(e) => {
							return setNumField(
								e.target.value,
								rec.pkgWeight,
								stateFunctions.setPkgWeight,
								'float',
								[4, 2],
								{ _uuid_: rec._uuid_ }
							)
						}}
					/>
				)
			},
		})

		result.push({
			title: 'Масса паллеты, кг',
			key: 'palWeight',
			width: '8.58%',
			rules: [{ required: true }],
			render: (_, rec) => {
				return (
					<InputError
						hasError={mainCtx.formErrors?.[`palWeight.${rec._uuid_}`]}
						style={{ width: '100%' }}
						value={rec.palWeight}
						disabled={isDisabled}
						onChange={(e) => {
							return setNumField(
								e.target.value,
								rec.palWeight,
								stateFunctions.setPalWeight,
								'float',
								[4, 2],
								{ _uuid_: rec._uuid_ }
							)
						}}
					/>
				)
			},
		})
	}
	result.push({
		title: 'Масса нетто, кг',
		key: 'nettoWeight',
		width: !mainCtx.state.unldIsStandPkg ? '6.57%' : '9.46%',
		render: (_, rec) => {
			return calSupplyInpCtrlPalDistrWeightNetto(mainCtx.state, rec)
		},
	})
	result.push({
		title: 'Состояние паллет',
		key: 'palState',
		width: !mainCtx.state.unldIsStandPkg ? '14.91%' : '17.67%',
		rules: [{ required: true }],
		render: (_, rec) => {
			if (+rec.palCount === 0 && rec.palCount !== null) {
				return
			}
			return (
				<InputError
					hasError={mainCtx.formErrors?.[`palState.${rec._uuid_}`]}
					component={
						<Select
							value={rec.palState}
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								stateFunctions.setPalState(rec._uuid_, el)
							}}
							disabled={isDisabled}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors.unldPalDistribs.palState.map((it) => {
								return (
									<Select.Option key={it} value={it}>
										{it}
									</Select.Option>
								)
							}) || []}
						</Select>
					}
				/>
			)
		},
	})

	result.push({
		title: 'Прокладной лист',
		key: 'postList',
		rules: [{ required: true }],
		width: !mainCtx.state.unldIsStandPkg ? '7.21%' : '10.82%',
		render: (_, rec) => {
			if (+rec.palCount === 0 && rec.palCount !== null) {
				return
			}
			return (
				<InputError
					hasError={mainCtx.formErrors?.[`postList.${rec._uuid_}`]}
					component={
						<Select
							value={rec.postList}
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								stateFunctions.setPostList(rec._uuid_, el)
							}}
							disabled={isDisabled}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors.unldPalDistribs.postList.map((it) => {
								return (
									<Select.Option key={it} value={it}>
										{it}
									</Select.Option>
								)
							}) || []}
						</Select>
					}
				/>
			)
		},
	})
	result.push({
		title: 'Целостность упак.',
		key: 'pkgIntegrity',
		rules: [{ required: true }],
		width: !mainCtx.state.unldIsStandPkg ? '7.85%' : '10.82%',
		render: (_, rec) => {
			return (
				<InputError
					hasError={mainCtx.formErrors?.[`pkgIntegrity.${rec._uuid_}`]}
					component={
						<Select
							value={rec.pkgIntegrity}
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								stateFunctions.setPkgIntegrity(rec._uuid_, el)
							}}
							disabled={isDisabled}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors.unldPalDistribs.pkgIntegrity.map((it) => {
								return (
									<Select.Option key={it} value={it}>
										{it}
									</Select.Option>
								)
							}) || []}
						</Select>
					}
				/>
			)
		},
	})
	result.push({
		title: 'Маркировка',
		key: 'mark',
		rules: [{ required: true }],
		width: !mainCtx.state.unldIsStandPkg ? '7.37%' : '10.82%',
		render: (_, rec) => {
			return (
				<InputError
					hasError={mainCtx.formErrors?.[`pkgIntegrity.${rec._uuid_}`]}
					component={
						<Select
							value={rec.mark}
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								stateFunctions.setMark(rec._uuid_, el)
							}}
							disabled={isDisabled}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors.unldPalDistribs.mark.map((it) => {
								return (
									<Select.Option key={it} value={it}>
										{it}
									</Select.Option>
								)
							}) || []}
						</Select>
					}
				/>
			)
		},
	})
	result.push({
		title: 'Паллетизация',
		key: 'palletization',
		rules: [{ required: true }],
		width: !mainCtx.state.unldIsStandPkg ? '11.94%' : '10.82%',
		render: (_, rec) => {
			if (+rec.palCount === 0 && rec.palCount !== null) {
				return
			}
			return (
				<InputError
					hasError={mainCtx.formErrors?.[`pkgIntegrity.${rec._uuid_}`]}
					component={
						<Select
							value={rec.palletization}
							style={{ width: '100%', minWidth: '120px' }}
							onChange={(el) => {
								stateFunctions.setPalletization(rec._uuid_, el)
							}}
							disabled={isDisabled}
							notFoundContent={<Col>Не найдено</Col>}
						>
							{mainCtx.selectors.unldPalDistribs.palletization.map((it) => {
								return (
									<Select.Option key={it} value={it}>
										{it}
									</Select.Option>
								)
							}) || []}
						</Select>
					}
				/>
			)
		},
	})

	result.push({
		title: null,
		key: 'action',
		width: !mainCtx.state.unldIsStandPkg ? '2.96%' : '3.04%',
		render: (_, record) => {
			return (
				<EditDelBtns
					record={record}
					canDelete={!isDisabled}
					canUpdate={false}
					onDelete={(record) => {
						stateFunctions.delete(record._uuid_)
					}}
					onEdit={editHandler}
					deps={mainCtx.delEditDeps?.unldPalDistribs?.[record.id]}
					openNewPage={(url) => {
						return openNewTab(url, {
							authCtx: authCtx.state,
						})
					}}
				></EditDelBtns>
			)
		},
	})
	if (mainCtx.state.unldVNaval)
		return result.filter((el) => !['palState', 'postList', 'palletization'].includes(el.key))
	return result
}
