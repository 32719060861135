import React, { useContext } from 'react'
import {
	Col,
	Dropdown,
	Space,
	Typography,
	Menu,
	// Image
} from 'antd'
import Icon from '@ant-design/icons'
import {
	// notify,
	user,
	user_dark,
} from '../../assets'
import { getUserRole } from '../../utils'
import { AuthContext, MenuContext, menuPositions } from '../../contexts'

const Info = () => {
	const { signOut } = useContext(AuthContext)
	const { position } = useContext(MenuContext)
	const topMenu = position === menuPositions.top
	const menu = (
		<Menu>
			<Menu.Item
				key="0"
				style={{
					width: 144,
					textAlign: 'center',
				}}
				onClick={signOut}
			>
				Выйти
			</Menu.Item>
		</Menu>
	)
	return (
		<Col
			style={{
				marginRight: 50,
				height: '64px',
			}}
		>
			<Space
				size="large"
				style={{
					height: '100%',
				}}
			>
				{/* <a href="https://web.telegram.org/k/#@IntevoBerryBot" target="_blank" rel="noreferrer">
					<img src="/telegram-icon.png" width="30" height="30" alt="telegram-icon" />
				</a> */}
				{/* <Icon
					component={notify}
					style={{
						fontSize: 24,
						filter: topMenu ? 'invert(0.7)' : '',
					}}
				/> */}
				<Dropdown overlay={menu} trigger={['click']} className="clickable">
					<Icon
						style={{
							fontSize: 42,
						}}
						component={topMenu ? user_dark : user}
					/>
				</Dropdown>
				<Typography.Text
					style={{
						fontSize: 14,
						color: topMenu ? 'rgba(255, 255, 255, 0.65)' : 'initial',
					}}
				>
					{getUserRole()}
				</Typography.Text>
			</Space>
		</Col>
	)
}

export default Info
