import { Checkbox, Input, Radio } from 'antd'
import { modes } from '../../../utils'
import { setNumField, setTextField } from '@berry/front-common/for-fields'
import { isValidNum } from '@berry/common-functions/validators'
import {
	supplyCalcAvgNettoPkgWeight,
	supplyCalcFactPkgCount,
	calcSupplyInpCtrlDiffWeight,
	numToFixed,
	calcSupplyInpCtrlFactWeight,
} from '@berry/common-functions/cross-project-functions'
import { CommonSelect, LeftTooltip } from '../../../components'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { dateFormat } from '../../../utils/constants/common'
import moment from 'moment'

const layout1Col = {
	labelCol: { span: 11 },
	wrapperCol: { span: 13 },
}
const layout2Col = {
	labelCol: { span: 11 },
	wrapperCol: { span: 13 },
}
const layout3Col = {
	labelCol: { span: 11 },
	wrapperCol: { span: 13 },
}

/**
 * Расхождение в упаковках, шт
 * @param {Object} mainCtx
 */
export const calcDiffPkgCount = (state) => {
	const result = Math.round((supplyCalcFactPkgCount(state) - state.packageCountInv) * 100) / 100
	if (isValidNum(result)) return result
	return ''
}
/**
 * рассчет Итого паллет, шт
 * @param {Object} mainCtx
 */
export const calcTotalPalCount = (mainCtx) => {
	if (mainCtx.state.unldIsStandPkg) {
		const result =
			mainCtx.state.unldPalDistribs?.reduce((acc, cur) => {
				return acc + +cur.palCount
			}, 0) || 0
		if (isValidNum(result)) return result
	}
	return mainCtx.state?.unldPalDistribs?.length || 0
}

/**
 * получает Допустимое отклонение, кг
 * @param {Object} mainCtx
 */
const calcSupplyUnloadDeviation = (mainCtx) => {
	let prodCat =
		(mainCtx.state.contrQuota || mainCtx.state.order)?.rmProvProd?.prodCatKindRawMat?.prodCat ||
		(mainCtx.state.contrQuota || mainCtx.state.order)?.rmProvProd?.prodCatPkg?.prodCat
	return ((prodCat?.deviation || 0) * mainCtx.state.weightInv) / 100
}
export const getFormItems1 = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				layout: layout1Col,
				label: 'Статус разгрузки',
				key: 'unldStatus',
				field: mainCtx.state.unldStatus,
			},
			{
				layout: layout1Col,
				label: 'ID',
				field: mainCtx.state.supply?.id,
				key: 'id',
			},
			{
				layout: layout1Col,
				label: 'Дата поставки',
				key: 'date',
				field: mainCtx.state.supply?.date
					? moment(mainCtx.state.supply.date).format(dateFormat)
					: null,
			},
			{ key: 'col-one-gap-one' },
		],
		[
			{
				layout: layout1Col,
				label: 'Поставщик',
				key: 'provider',
				field: mainCtx.state.provider,
			},
			{
				layout: layout2Col,
				label: 'Продукт',
				key: 'rmProvProd',
				field: mainCtx.state.rmProvProd?.displayVal,
			},
			{
				layout: layout2Col,
				label: 'Присв. № партии',
				key: 'assignedPartyNum',
				field: mainCtx.state.assignedPartyNum,
			},
		],
		[
			{
				layout: layout3Col,
				label: 'ФИО проверяющего',
				name: 'unldStaff',
				rules: [{ required: true }],
				field: (
					<CommonSelect
						value={mainCtx.state.unldStaff}
						setValue={mainCtx.stateFunctions.setUnldStaff}
						isDisabled={isDisabled}
						dataArr={mainCtx.selectors.unldStaff}
					/>
				),
			},
			{
				layout: layout2Col,
				label: 'Артикул',
				key: 'prodCatKindRawMat',
				field: mainCtx.state.articul,
			},
			{
				layout: layout2Col,
				label: 'Артикул 1С',
				key: 'vocProd1c',
				field: mainCtx.state.articul1C,
			},
		],
	]
}

export const getFormItems2 = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				layout: layout1Col,
				label: 'Тип упаковки факт.',
				key: 'vocContTypeFact',
				field: mainCtx.state.vocContTypeFact?.labelShort,
			},
			{
				layout: layout1Col,
				label: 'В навал',
				key: 'unldVNaval',
				field: (
					<Checkbox
						disabled={isDisabled}
						checked={mainCtx.state.unldVNaval}
						onChange={(e) => mainCtx.stateFunctions.setUnldVNaval(e.target.checked)}
					></Checkbox>
				),
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Cтандартная упаковка',
				key: 'unldIsStandPkg',
				field: (
					<Radio.Group
						disabled={isDisabled}
						value={mainCtx.state.unldIsStandPkg}
						onChange={(e) => {
							mainCtx.stateFunctions.setUnldIsStandPkg(e.target.value)
						}}
					>
						<Radio value={true}>Да</Radio>
						<Radio value={false}>Нет</Radio>
					</Radio.Group>
				),
			},
			{
				layout: layout2Col,
				label: 'Итого паллет, шт',
				key: 'totalPalCount',
				field: calcTotalPalCount(mainCtx),
			},
		],
		[
			mainCtx.state.unldIsStandPkg
				? {
						layout: layout3Col,
						label: 'Масса нетто ед. упак. факт., кг',
						name: 'unldNettoPkgWeight',
						rules: [{ required: mainCtx.state.unldIsStandPkg }],
						field: (
							<Input
								style={{ width: '100%' }}
								value={mainCtx.state.unldNettoPkgWeight}
								disabled={isDisabled}
								onChange={(e) => {
									return setNumField(
										e.target.value,
										mainCtx.state.unldNettoPkgWeight,
										mainCtx.stateFunctions.setUnldNettoPkgWeight,
										'float',
										[5, 2]
									)
								}}
							/>
						),
				  }
				: {
						layout: layout3Col,
						label: 'Ср. масса нетто ед. упак. факт.,кг',
						key: 'unldAvgNettoPkgWeight',
						field: supplyCalcAvgNettoPkgWeight(mainCtx.state).toString(),
				  },
		],
	]
}

export const getFormItems3 = (mainCtx) => {
	return [
		[
			{
				layout: layout1Col,
				label: 'Масса по наклд., кг',
				key: 'weightInv',
				field: numToFixed(mainCtx.state.weightInv, 2),
			},
			{
				layout: layout1Col,
				label: 'Масса факт., кг',
				key: 'factWeight',
				field: calcSupplyInpCtrlFactWeight(mainCtx.state),
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Кол-во упак. факт., шт',
				key: 'factPkgCount',
				field: supplyCalcFactPkgCount(mainCtx.state),
			},
		],
	]
}

export const getFormItems4 = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view
	return [
		[
			{
				layout: layout1Col,
				label: 'Расхождение в массе, кг',
				key: 'diffWeight',
				field: calcSupplyInpCtrlDiffWeight(mainCtx.state),
			},
		],
		[
			{
				layout: layout2Col,
				key: 'deviation',
				field: numToFixed(calcSupplyUnloadDeviation(mainCtx), 2),
				label: (
					<LeftTooltip
						label="Допустимое отклонение, кг"
						tooltip={
							<span>
								Допустимое отклонение ={' '}
								{mainCtx.state.contrQuota?.rmProvProd?.prodCatKindRawMat?.prodCat?.deviation ||
									mainCtx.state.contrQuota?.rmProvProd?.prodCatPkg?.prodCat?.deviation}
								%
							</span>
						}
					>
						<QuestionCircleOutlined style={{ paddingLeft: '5px' }} />
					</LeftTooltip>
				),
			},
		],
		[
			{
				layout: {
					labelCol: { span: 2 },
					wrapperCol: { span: 22 },
				},
				label: 'К',
				key: 'unldComment',
				field: (
					<Input
						style={{ width: '100%' }}
						value={mainCtx.state.unldComment}
						disabled={isDisabled}
						onChange={(e) => {
							return setTextField(
								e.target.value,
								mainCtx.state.unldComment,
								mainCtx.stateFunctions.setUnldComment,
								255
							)
						}}
					/>
				),
			},
		],
	]
}
