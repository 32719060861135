import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Modal as ModalAntd, Space, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { HeaderMenu } from '../../'
import Body from './Body'
import Modal from './Modal'
import {
	ModContext,
	ModProvider,
	ListProvider,
	AuthContext,
	UserDataContext,
} from '../../../contexts'
import modes from '../../../utils/constants/modes'
import { actBtnOnDelete, actBtnOnEdit, axios, openNewTab } from '../../../utils'
import { useHistory } from 'react-router-dom'
import { showConfirmModal } from '../../'
import { applyFilterForList } from '@berry/common-functions/cross-project-functions'

const Wrapper = (props) => {
	return (
		<ModProvider>
			<ListProvider>
				<WrapperBody {...props} />
			</ListProvider>
		</ModProvider>
	)
}

const WrapperBody = (props) => {
	const {
		fields,
		resetFields,
		setRecordId,
		deleteData,
		data,
		model,
		vocUrl,
		ignoreDeps,
		searchStr = '',
		searchFields,
		isCheckDepsOnEdit = true,
	} = props
	const vocMode = useContext(ModContext)
	const authCtx = useContext(AuthContext)
	const userContext = useContext(UserDataContext)
	const memoData = useMemo(() => {
		const res = applyFilterForList(data, {
			search: searchStr,
			searchFields: searchFields,
		})
		return res
	}, [data, searchStr])
	const [isShowModal, setShowModal] = useState(false)
	const [isDeleteModal, setIsDeleteModal] = useState(false)
	const [deleteRecord, setDeleteRecord] = useState(null)
	const [dataDeps, setDataDeps] = useState({})
	const history = useHistory()

	useEffect(() => {
		setIsDeleteModal(false)
		setShowModal(false)
	}, [memoData])

	useEffect(() => {
		const getDeps = async () => {
			try {
				const res = await axios(`${vocUrl}/delete-deps`, {
					params: { model, id: data.map((el) => el.id) },
				})
				setDataDeps(res.data.result)
			} catch {}
		}
		if (data.length) {
			getDeps()
		}
	}, [data, model, vocUrl])

	const handleAddOnMenu = () => {
		vocMode.set(modes.new)
		setShowModal(true)
	}

	const handleEditRow = async (record) => {
		vocMode.set(modes.edit)
		setRecordId(record.id)
		let isHasDeps = false
		if (isCheckDepsOnEdit) {
			isHasDeps = await actBtnOnEdit({
				history,
				pageUrl: history.location.pathname,
				showConfirmModal,
				deps: ignoreDeps ? [] : dataDeps[model]?.[record.id],
				currentRights: userContext.state.rights,
				openNewPage: (url) =>
					openNewTab(url, {
						authCtx: authCtx.state,
					}),
			})
		}
		if (!isHasDeps) {
			for (const field of Object.values(fields)) {
				const key = Object.keys(field)

				field[key].onChange({
					target: {
						value: record[key],
						checked: record[key],
						id: record[key],
					},
				})
			}
			setShowModal(true)
		}
	}

	const handleDeleteRow = async (record) => {
		setDeleteRecord(record)
		await actBtnOnDelete({
			history,
			pageUrl: history.location.pathname,
			showConfirmModal,
			deleteFun: handleOkDelete,
			delRecord: record,
			deps: ignoreDeps ? [] : dataDeps[model]?.[record.id],
			currentRights: userContext.state.rights,
			openNewPage: (url) =>
				openNewTab(url, {
					authCtx: authCtx.state,
				}),
		})
	}

	const handleCloseModal = () => {
		resetFields()
		setShowModal(false)
		vocMode.set(modes.view)
	}

	const handleOkDelete = (delRecord) => {
		setIsDeleteModal(false)
		deleteData(delRecord.id)
	}

	const handleCancelDelete = () => {
		setDeleteRecord(null)
		setIsDeleteModal(false)
	}

	return (
		<>
			<HeaderMenu handleAddOnMenu={handleAddOnMenu} {...props} />
			<Body
				handleEditRow={handleEditRow}
				handleDeleteRow={handleDeleteRow}
				memoData={memoData}
				{...props}
			/>
			<Modal
				isShow={isShowModal}
				closeModal={handleCloseModal}
				vocMode={vocMode}
				dataDeps={dataDeps}
				{...props}
			/>

			<ModalAntd
				title={
					<>
						<InfoCircleOutlined style={{ color: '#faad14' }} /> Вы действительно хотите удалить этот
						элемент?
					</>
				}
				visible={isDeleteModal}
				closable={false}
				centered
				onOk={handleOkDelete}
				onCancel={handleCancelDelete}
				okText="Удалить"
				cancelText="Отменить"
			>
				При удалении этот элемент удалится навсегда
			</ModalAntd>
		</>
	)
}

export default Wrapper
