import { Checkbox, DatePicker, Input, Spin } from 'antd'
import { CommonSelect, EditDelBtns, InputError } from '../../../../components'
import { modes } from '../../../../utils'
import { sliceStringAndAddThreeDots } from '@berry/front-common'
import { getProdNameByProviderQuota } from '@berry/common-functions/cross-project-functions'
import { CopyOutlined } from '@ant-design/icons'
import moment from 'moment'
import lodash from 'lodash'
import { setNumField } from '@berry/front-common/for-fields'
import { useContext } from 'react'
import { RequestItemMainContext } from '../provider/main'
import useDataFromServer from '../../../../utils/hooks/useDataFromServer'
/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */

export const getDisplayColumns = (modCtx, mainCtx, stateFunctions) => {
	const disabled = modCtx.mod === modes.view
	const renderWithRowSpan = (record, index) => {
		const foundIndex = mainCtx.state.products.findIndex(
			(p) => p.contract?.id === record.contract?.id
		)
		const length = mainCtx.state.products.filter(
			(p) => p.contract?.id === record.contract?.id
		).length
		return {
			rowSpan: index === foundIndex ? length || 1 : 0,
		}
	}
	return [
		{
			title: 'Поставщик',
			dataIndex: ['contract', 'provider', 'displayVal'],
			key: 'provider',
			width: '13.53%',
			onCell: renderWithRowSpan,
		},
		{
			title: '№ договора',
			dataIndex: ['contract', 'displayVal'],
			key: 'num',
			width: '7.6%',
			onCell: renderWithRowSpan,
		},
		{
			title: '№ ДС',
			dataIndex: ['contrQuota', 'application', 'displayCode'],
			key: 'displayCode',
			width: '5.16%',
			render: (text, record) =>
				sliceStringAndAddThreeDots(
					record.contrQuota?.application?.displayCode || record.order?.application?.displayCode,
					10
				),
		},
		...(mainCtx.state.products.some((p) => p.order?.contract?.tolling)
			? [
					{
						title: 'ID ДЗ',
						dataIndex: 'orderId',
						key: 'orderId',
						width: '3.63%',
						render: (_, record) => {
							return record.order?.id
						},
					},
			  ]
			: []),
		{
			title: 'Продукт',
			key: 'description',
			width: '13.61%',
			render: (record) => {
				let value = !record?.contrQuota?.rmProvProd?.prodCat
					? ''
					: record.contrQuota.rmProvProd?.displayVal ||
					  getProdNameByProviderQuota(record.contrQuota.rmProvProd)
				if (record.order) {
					value = getProdNameByProviderQuota(record.order.rmProvProd)
				}
				return (
					<InputError
						hasError={
							!!mainCtx.formErrors?.[`contrQuota.${record._uuid_}`] ||
							!!mainCtx.formErrors?.[`order.${record._uuid_}`]
						}
						disabled={disabled || !record.contract}
						placeholder="Выберите продукт"
						value={value}
						onClick={() => {
							if (record.contract) {
								if (record.contract.tolling) {
									mainCtx.modalFunctions.orderModalFunctions.openAddOrder()
									mainCtx.modalFunctions.orderModalFunctions.setAddOrderUuidProd(record._uuid_)
								} else {
									mainCtx.modalFunctions.contrQuotaModalFunctions.openAddContrQuota()
									mainCtx.modalFunctions.contrQuotaModalFunctions.setAddContrQuotaUuidProd(
										record._uuid_
									)
								}
							}
						}}
					/>
				)
			},
		},
		{
			title: 'ГПП',
			dataIndex: 'isReadyProdResale',
			key: 'isReadyProdResale',
			width: '5%',
			render: (_, record) => (
				<Checkbox
					disabled={disabled || record.contract?.tolling}
					checked={record.isReadyProdResale}
					onChange={(e) => {
						mainCtx.stateFunctions.products.setIsReadyProdResale(record._uuid_, e.target.checked)
					}}
				/>
			),
		},
		{
			title: 'Год урожая',
			dataIndex: 'harvestYear',
			key: 'harvestYear',
			width: '8.9%',
			render: (val, record) => (
				<InputError
					hasError={!!mainCtx.formErrors?.[`harvestYear.${record._uuid_}`]}
					disabled={disabled}
					component={
						<DatePicker
							style={{ width: '100%', minWidth: 200 }}
							placeholder="ГГГГ"
							showToday={false}
							placement="topLeft"
							format="YYYY"
							allowClear={false}
							suffixIcon={null}
							onChange={(e) => stateFunctions.setHarvestYear(record._uuid_, e)}
							picker="year"
							disabled={disabled}
							defaultValue={val}
						/>
					}
				/>
			),
		},
		{
			title: 'Дата изготовления',
			dataIndex: 'date',
			key: 'date',
			width: '11.44%',
			render: (val, record) => {
				const value = val ? moment(val) : null
				return (
					<InputError
						hasError={!!mainCtx.formErrors?.[`date.${record._uuid_}`]}
						disabled={disabled}
						style={{ width: '100%' }}
						component={
							<DatePicker
								style={{ width: '100%', minWidth: !record?.contrQuota ? 200 : null }}
								value={value}
								showToday={false}
								placeholder="ДД.ММ.ГГГГ"
								placement="topLeft"
								format="DD.MM.YYYY"
								allowClear={false}
								suffixIcon={null}
								onChange={(e) => {
									const date = moment(e).format('YYYY-MM-DD')
									stateFunctions.setDate(record._uuid_, date)
								}}
								disabled={disabled}
							/>
						}
					/>
				)
			},
		},
		{
			title: 'Тип упаковки',
			dataIndex: ['vocContType', 'labelShort'],
			key: 'vocContType',
			width: '8.24%',
			render: (value, record) => (
				<InputError
					hasError={!!mainCtx.formErrors?.[`vocContType.${record._uuid_}`]}
					disabled={disabled}
					component={
						<CommonSelect
							value={record.vocContType}
							setValue={(value) => stateFunctions.setVocContType(record._uuid_, value)}
							isDisabled={disabled}
							showSearch
							dataArr={mainCtx.additional.allSelectContainerTypes}
						/>
					}
				/>
			),
		},
		{
			title: 'Масса нетто ед. упак., кг',
			dataIndex: 'containerWeight',
			key: 'containerWeight',
			width: '6.16%',
			render: (value, record) => (
				<Input
					value={value}
					disabled={disabled}
					onChange={(e) =>
						setNumField(e.target.value, value, stateFunctions.setContainerWeight, 'float', [5, 2], {
							_uuid_: record._uuid_,
							isOnlyNums: true,
						})
					}
				/>
			),
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '13.2%',
			render: (val, record) => (
				<InputError
					showCaption={
						!!record.rmProvProd || !!record.order?.rmProvProd || !!record.contrQuota?.rmProvProd
					}
					hasError={
						!!mainCtx.formErrors?.[`weight.${record._uuid_}`] ||
						!!mainCtx.formErrors?.[record._uuid_]
					}
					message={`Доступная масса ${mainCtx.getAvailableWeightForProd(record)} кг`}
					disabled={disabled || (!record.contrQuota && !record.order)}
					style={{ width: '100%' }}
					value={val || ''}
					onChange={(e) => {
						setNumField(e.target.value, val, stateFunctions.setWeight, 'float', [9, 2], {
							_uuid_: record._uuid_,
							isOnlyNums: true,
						})
						mainCtx.checkAvailable()
					}}
				/>
			),
		},
		...(modCtx.mod !== modes.view
			? [
					{
						key: 'copy',
						width: '3.5%',
						render: (record) => (
							<CopyOutlined
								title="Создать копию"
								style={{
									cursor: 'pointer',
									fontSize: 20,
									color: '#000',
								}}
								onClick={() => {
									const recordCopy = {
										contract: lodash.cloneDeep(record.contract),
										contrQuota: lodash.cloneDeep(record.contrQuota),
										order: lodash.cloneDeep(record.order),
									}
									mainCtx.stateFunctions.products.create(recordCopy)
								}}
							/>
						),
					},
					{
						title: null,
						key: 'action',
						width: '3.5%',
						render: (record) => {
							return (
								<EditDelBtns
									record={record}
									canDelete={!disabled}
									onDelete={(record) => stateFunctions.delete(record._uuid_)}
								/>
							)
						},
					},
			  ]
			: []),
	]
}

const Weight = (props) => {
	const { record, disabled } = props
	const mainCtx = useContext(RequestItemMainContext)
	const stateFunctions = mainCtx.stateFunctions.products
	const { data: availWeight, isLoaded: isLoadedAvailWeight } = useDataFromServer({
		url: '/rm/requests/get-available-weight-for-prod',
		params: {
			idContrQuota: record.contrQuota?.id,
			idContrOrder: record.order?.id,
		},
	})
	return (
		<InputError
			showCaption={
				!!record.rmProvProd || !!record.order?.rmProvProd || !!record.contrQuota?.rmProvProd
			}
			hasError={
				!!mainCtx.formErrors?.[`weight.${record._uuid_}`] || !!mainCtx.formErrors?.[record._uuid_]
			}
			message={
				(record.contrQuota || record.order) && (
					<div>Доступная масса {isLoadedAvailWeight ? availWeight : <Spin />} кг</div>
				)
			}
			disabled={disabled || (!record.contrQuota && !record.order)}
			style={{ width: '100%' }}
			value={record.weight || ''}
			onChange={(e) => {
				setNumField(e.target.value, record.weight, stateFunctions.setWeight, 'float', [9, 2], {
					_uuid_: record._uuid_,
					isOnlyNums: true,
				})
				mainCtx.checkAvailable()
			}}
		/>
	)
}
