import React from 'react'
import { Input, Typography } from 'antd'

const InputError = ({ hasError, showCaption, message = null, component = <Input />, ...props }) => (
	<div
		style={{
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			top: showCaption && !props.disabled ? 9 : 0,
		}}
	>
		{React.cloneElement(component, {
			...props,
			style: {
				...props.style,
				border: hasError ? '1px solid red' : '',
				boxShadow: hasError ? 'none' : '',
				minHeight: hasError || showCaption ? 32 : 0,
			},
		})}
		{(showCaption || hasError) && !props.disabled && (
			<Typography.Text
				style={{
					color: hasError ? 'red' : 'rgb(122,122,122)',
					whiteSpace: 'nowrap',
					fontSize: 12,
					zIndex: 1,
					...props.captionStyle,
				}}
				ellipsis={{
					tooltip: message,
				}}
			>
				{message}
			</Typography.Text>
		)}
	</div>
)

export default InputError
