/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} stateFunctions - отбьект с функциями изменения состояния вкладки
 */
export const getDisplayColumns = (mainCtx) => {
	return [
		{
			title: '№ партии',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '14.28%',
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			key: 'articul',
			width: '14.28%',
		},
		{
			title: 'Артикул 1С',
			dataIndex: 'articul1C',
			key: 'articul1C',
			width: '14.28%',
		},
		{
			title: 'Продукт',
			dataIndex: ['product', 'displayVal'],
			key: 'product',
			width: '14.32%',
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weightFact',
			key: 'weight',
			width: '14.28%',
		},
		{
			title: 'Сработано, кг',
			dataIndex: 'workedOutWeight',
			key: 'workedOutWeight',
			width: '14.28%',
		},
		{
			title: 'Остаток, кг',
			dataIndex: 'remainderWeight',
			key: 'remainderWeight',
			width: '14.28%',
		},
	]
}
