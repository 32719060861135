import { Table } from 'antd'
import React, { useContext } from 'react'
import { RelocDefectItemMainContext } from '../provider/main'
import { TabItem } from '../../../../../components'
import { AuthContext, ModContext } from '../../../../../contexts'
import { modes } from '../../../../../utils'
import { getDisplayColumns } from './production-tab'
import SelectProdModal from './SelectProdModal'

const ProductionTab = () => {
	const mainCtx = useContext(RelocDefectItemMainContext)
	const modCtx = useContext(ModContext)
	const authCtx = useContext(AuthContext)
	const stateData = mainCtx.state.productions

	return (
		<TabItem
			modal={() => <SelectProdModal />}
			addButton={{
				show: true,
				onClick: () => {
					mainCtx.modalFunctions.openAddProd()
					mainCtx.modalFunctions.setAddProd__name(
						mainCtx.state.productions?.length
							? mainCtx.state.productions[0]?.idStockRawMat
								? 'rawMat'
								: mainCtx.state.productions[0]?.idStockSemif
								? 'semif'
								: 'readyProd'
							: 'rawMat'
					)
				},
			}}
			table={() => (
				<Table
					size="small"
					rowClassName={modCtx.mod !== modes.view && 'clickable'}
					columns={getDisplayColumns(mainCtx, modCtx, authCtx)}
					dataSource={stateData}
					pagination={false}
					scroll={{ x: 800 }}
				/>
			)}
		/>
	)
}

export default ProductionTab
