import React, { useContext, useState } from 'react'
import {
	ModContext,
	ModProvider,
	FileProvider,
	FromChildToParentProvider,
	UserDataContext,
} from '../../../contexts'
import { Main } from '../../../layouts'
import { InputControlContext, Provider } from './provider/main'
import { Card, Row, Col, Form } from 'antd'
import {
	CustomForm,
	HeaderMenu,
	TabList,
	showConfirmModal,
	CustomBreadcrumbItem,
	asyncShowConfirmModal,
} from '../../../components'
import { actBtnOnClose, actBtnOnSaveV2, axios, modes } from '../../../utils'
import { getFormItems1, getFormItems2, getFormItems3, getFormItems4 } from './input-control-item'
import { useHistory } from 'react-router'

import { getBreadcrumbsText } from '../../../utils/helpers/generators'
import LabIndicatorTab from './LabIndicatorTab/LabIndicatorTab'
import moment from 'moment'
import Title from 'antd/lib/typography/Title'
import ActionButtons from './ActionButtons'
import { showIsBLockedModalForDeps, showSaveAsDraft } from '../../../utils/constants/for-components'
import EventHistoryTab from './EventHistoryTab'
import { getDataFromSupplyProdRmProvProd } from '@berry/common-functions/cross-project-functions'
import {
	EDIT_NOT_PERMIT_BECAUSE_OF_BLOCK,
	INACTION_AWARE,
} from '../../../utils/constants/plain-text'

const pageUrl = '/rm/supply-input-controls'

const InputControlItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<FromChildToParentProvider>
				<FileProvider>
					<Provider params={{ id: props.match.params.id }}>
						<Main title="Входной контроль">
							<InfoComponent />
						</Main>
					</Provider>
				</FileProvider>
			</FromChildToParentProvider>
		</ModProvider>
	)
}

const InfoComponent = () => {
	const modCtx = useContext(ModContext)
	const mainCtx = useContext(InputControlContext)
	const userDataCtx = useContext(UserDataContext)

	let history = useHistory()

	const [form] = Form.useForm()
	const [showQualitySelect, setShowQualitySelect] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalItem, setModalItem] = useState(null)
	const breadCrumb = getBreadcrumbsText(pageUrl, mainCtx.state)
	const customBreadcrumbItems = [
		{ title: 'Главная', link: '/' },
		{ title: 'Сырье', link: null },
		{ title: 'Поставки сырья', link: '/rm/supplies' },
		{ title: breadCrumb.head, link: `/rm/supplies/${mainCtx.state?.supply?.id}` },
		{ title: breadCrumb.label, link: null },
	].map((e, i) => <CustomBreadcrumbItem key={i} link={e.link} title={e.title} />)
	const ctrlDate = mainCtx.state?.ctrlDate ? moment(mainCtx.state?.ctrlDate) : null
	const ctrlControlDate = mainCtx.state?.ctrlControlDate
		? moment(mainCtx.state?.ctrlControlDate)
		: null
	if (mainCtx.state.__notFound) {
		history.replace('/rm/supplies')
		return null
	}
	if (!mainCtx.isInitializedMain) {
		return null
	}
	return (
		<>
			<HeaderMenu customBreadcrumbItems={customBreadcrumbItems}>
				{modCtx.mod === modes.edit && (
					<Row
						style={{
							color: `rgba(207, 9, 9, 1)`,
						}}
					>
						{INACTION_AWARE}
					</Row>
				)}
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						allVocPosTypes={mainCtx.additional.allVocPosTypes}
						rights={{
							'АРМ офиса': {
								'Поставки сырья': {
									'Входной контроль': { edit: true },
								},
							},
						}}
						formName={'inputCtrl'}
						onClose={() => {
							actBtnOnClose({
								history,
								pageUrl: `/rm/supplies/${mainCtx.state.supply.id}`,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onEdit={async () => {
							if (await mainCtx.checkIsBlocked()) {
								axios.post(`/logs`, {
									time: new Date().toISOString(),
									action: 'Delete',
									isBlockUnblockLogic: true,
									entity: 'rmSupplProd',
									idEntity: mainCtx.state.id,
								})
								showConfirmModal({
									...showIsBLockedModalForDeps,
									title: EDIT_NOT_PERMIT_BECAUSE_OF_BLOCK,
								})
								return
							}

							if (
								['Приемка разрешена', 'Возврат', 'Ответственное хранение'].includes(
									mainCtx.state.ctrlStatus
								) &&
								!userDataCtx.state.isDevAdmin
							) {
								return showConfirmModal({
									title: (
										<>
											<span>
												При внесении изменений в карточку, необходимо будет повторно ее согласовать.
											</span>
											<br />
											<span>Вы действительно хотите отредактировать карточку?</span>
										</>
									),
									width: '38.25%',
									okText: 'Ок',
									isCancelBtnPrimary: true,
									onOk: async () => {
										try {
											const { id } = await mainCtx.serverMatch({
												ctrlStatus: 'Исследование завершено',
												event: 'Отмена согласования',
											})
											if (id) {
												if (await mainCtx.blockForEditing()) {
													modCtx.set(modes.edit)
													mainCtx.rerender()
												}
											}
										} catch (err) {
											console.log(err.message)
										}
									},
								})
							}
							if (await mainCtx.blockForEditing()) {
								modCtx.set(modes.edit)
								mainCtx.rerender()
							}
						}}
						canDelete={false}
						onSave={async () => {
							if (!mainCtx.validateBeforeSave()) {
								const modalResult = await asyncShowConfirmModal({
									...showSaveAsDraft,
									title: (
										<>
											<span>Вы не сможете принять партию, не заполнены обязательные поля.</span>
											<br></br>
											<span>Сохранить карточку для последующего редактирования?</span>
										</>
									),
								})
								if (modalResult) {
									mainCtx.stateFunctions.setCtrlStatus('Черновик')
								} else {
									mainCtx.validate()
									form.validateFields()
									return
								}
							} else {
								mainCtx.stateFunctions.setCtrlStatus('Исследование завершено')
							}
							await actBtnOnSaveV2({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								pageUrl: pageUrl,
								history: history,
								isShowErrorModal: true,
								showConfirmModal,
								form,
								canSaveWithErrors: true,
							})
							await mainCtx.unblockForEditing()
						}}
						onCancel={async () => {
							if (await mainCtx.unblockForEditing()) {
								form.resetFields()
								mainCtx.reset()
							}
						}}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<Title level={5}>Основные данные</Title>
						<CustomForm
							form={form}
							name="supplyInputCtrl1"
							fields={[
								{ name: 'ctrlStaff', value: mainCtx.state.ctrlStaff },
								{ name: 'ctrlStatus' },
								{ name: 'displayCode' },
								{ name: 'date' },
								{ name: 'rmProvProd' },
								{ name: 'provider' },
								{ name: 'assignedPartyNum' },
								{ name: 'ctrlStaff' },
								{ name: 'prodCatKindRawMat' },
								{
									name: 'vocProd1c',
									value: getDataFromSupplyProdRmProvProd(mainCtx.state)?.articul1C,
								},
							]}
							items={getFormItems1(mainCtx, modCtx, userDataCtx)}
						/>
						<Title level={5}>Происхождение продукта</Title>
						<CustomForm
							form={form}
							name="supplyInputCtrl2"
							fields={[
								{ name: 'ctrlRawMatManuf', value: mainCtx.state.ctrlRawMatManuf },
								{ name: 'ctrlRawMatCountry', value: mainCtx.state.ctrlRawMatCountry },
								{
									name: 'ctrlDate',
									value: ctrlDate,
								},
								{ name: 'ctrlShelfLife', value: mainCtx.state.ctrlShelfLife },
								{ name: 'ctrlMeasure', value: mainCtx.state.ctrlMeasure?.labelShort },
							]}
							items={getFormItems2(mainCtx, modCtx)}
						/>
						<Title level={5}>Входной контроль</Title>
						<CustomForm
							form={form}
							name="supplyInputCtrl3"
							fields={[
								{ name: 'ctrlControlWeight', value: mainCtx.state.ctrlControlWeight },
								{ name: 'ctrlExampleWeight', value: mainCtx.state.ctrlExampleWeight },
								{ name: 'ctrlPkgCount', value: mainCtx.state.ctrlPkgCount },
							]}
							items={getFormItems3(mainCtx, modCtx)}
						/>
						<Title level={5}>Лабораторные испытания</Title>
						<CustomForm
							form={form}
							name="supplyInputCtrl4"
							fields={[
								{
									name: 'ctrlControlDate',
									value: ctrlControlDate,
								},
								{
									name: 'ctrlTestReportNum',
									value: mainCtx.state.ctrlTestReportNum,
								},
								{
									name: 'vocQuality',
									value: mainCtx.state.ctrlSpec
										? mainCtx.state.ctrlSpec?.vocQuality?.displayVal
											? mainCtx.state.ctrlSpec?.vocQuality?.displayVal
											: 'Отсутствует'
										: null,
								},
								{ name: 'spec', value: mainCtx.state.ctrlSpec?.label },
							]}
							items={getFormItems4(mainCtx, modCtx, showQualitySelect, setShowQualitySelect)}
						/>
					</Col>
				</Row>
			</Card>

			<TabList
				tabs={[
					{ key: 0, title: 'Лабораторные показатели', component: <LabIndicatorTab /> },
					{
						key: 1,
						title: 'История событий',
						component: (
							<EventHistoryTab
								modalItem={modalItem}
								setModalItem={setModalItem}
								isModalOpen={isModalOpen}
								setIsModalOpen={setIsModalOpen}
							/>
						),
					},
				]}
			/>
		</>
	)
}
export default InputControlItem
