import { Space } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

export const columns = [
	{
		title: 'Тип продукта',
		dataIndex: 'label',
		key: 'label',
		width: '25%',
	},
	{
		title: 'Краткое наименование',
		dataIndex: 'labelShort',
		key: 'labelShort',
		width: '25%',
	},
	{
		title: 'Доп. продукты и ПФ',
		dataIndex: 'isAdditionalProd',
		key: 'isAdditionalProd',
		width: '25%',
		render: (isUser) =>
			isUser && (
				<Space size="middle">
					<CheckOutlined />
				</Space>
			),
	},
	{
		title: 'Спецификации',
		dataIndex: 'isSpec',
		key: 'isSpec',
		width: '25%',
		render: (isUser) =>
			isUser && (
				<Space size="middle">
					<CheckOutlined />
				</Space>
			),
	},
]
