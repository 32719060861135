import { Input } from 'antd'
import { EditDelBtns } from '../../../../components'
import { modes, openNewTab } from '../../../../utils'
import { getProdCatKindSemifDisplayVal } from '@berry/common-functions/cross-project-functions'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} mainCtx - основной контекст
 * @param {Object} modCtx
 * @param {function} editHandler
 */
export const getDisplayColumns = (mainCtx, modCtx, editHandler, authCtx) => {
	const stateFunctions = mainCtx.stateFunctions.qualities
	const isDisabled = modCtx.mod === modes.view
	return [
		{
			title: 'Номер',
			dataIndex: 'displayCode',
			key: 'index',
			width: '25%',
		},
		{
			title: 'Качество',
			dataIndex: 'label',
			key: 'label',
			width: '65%',
		},

		{
			title: null,
			key: 'action',
			width: '10%',
			render: (text, record) => {
				const semifDeps = (mainCtx.state.kindSemifs || [])
					.filter((semif) => semif.quality && semif.quality.id === record.id)
					.map((el) => ({
						link: `/pv/product-catalog/${mainCtx.state.id}`,
						title: `Продуктовый каталог / ${getProdCatKindSemifDisplayVal(el)}`,
					}))
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={!isDisabled}
						onDelete={(record) => stateFunctions.delete(record._uuid_)}
						deps={semifDeps}
						onEdit={editHandler}
						openNewPage={(url) =>
							openNewTab(url, {
								authCtx: authCtx.state,
							})
						}
					/>
				)
			},
		},
	]
}

const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 },
}

export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []

	const { _uuid_ } = params
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.qualities

	return [
		[
			{
				layout: layout,
				label: 'Качество',
				name: 'label',
				rules: [
					{ required: true },
					({ getFieldValue }) => ({
						validator: async (_, value) => {
							if (value) {
								const err = mainCtx.validators.quality(params._uuid_)
								if (err) {
									return Promise.reject(err)
								}
							}
							return Promise.resolve()
						},
					}),
				],
				field: (
					<Input
						style={{ width: '100%' }}
						value={stateFunctions.get(_uuid_).label}
						disabled={isDisabled}
						onChange={(e) => {
							if (e.target.value?.length && e.target.value?.length > 100) {
								stateFunctions.setLabel(_uuid_, stateFunctions.get(_uuid_).label)
								return
							}
							stateFunctions.setLabel(_uuid_, e.target.value)
						}}
					/>
				),
			},
		],
	]
}
