import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Card } from 'antd'
import { Provider, RelocDefectListMainContext } from './provider/main'
import { Main } from '../../../../layouts'
import { goToItem } from '../../../../utils'
import { HeaderMenu, StatefulTable } from '../../../../components'
import { getColumns } from './reloc-defect-list'

const pageUrl = '/stock-operations/reloc-defect'

const RelocDefectList = () => (
	<Provider>
		<Main title="Перемещения в брак">
			<RelocDefectListTable />
		</Main>
	</Provider>
)

const RelocDefectListTable = () => {
	const mainCtx = useContext(RelocDefectListMainContext)
	const history = useHistory()
	return (
		<>
			<HeaderMenu
				rights={{
					'АРМ офиса': {
						'Перемещения в брак': {
							edit: true,
						},
					},
				}}
				breadcrumbsTitle="Перемещения в брак"
				search={mainCtx.setSearch}
				canCreate
				handleAddOnMenu={() => {
					goToItem(history, { url: pageUrl, id: 'new' })
				}}
			/>
			<Card>
				<StatefulTable
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columns={getColumns}
					searchFields={mainCtx.searchFields}
					columnProps={{ mainCtx, fromServerFilters: mainCtx.state.fromServerFilters }}
					url={pageUrl}
				/>
			</Card>
		</>
	)
}

export default RelocDefectList
