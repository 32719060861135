import React from 'react'
import Icon from '@ant-design/icons'
import { noteditable } from '../../assets'

const Icons = (props) => {
	const { editable } = props

	return editable ? (
		<span style={{ marginLeft: '20px' }}></span>
	) : (
		<Icon style={{ fontSize: '18px' }} component={noteditable} />
	)
}

const SetNotEditIcon = (props) => {
	const { editable, title } = props

	return (
		<div style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
			<Icons editable={editable} />
			<p style={{ margin: ' 0 0 0 5px' }}>{title}</p>
		</div>
	)
}

export default SetNotEditIcon
