import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../WrapperTemp/Wrapper'
import { getColumns } from './columns'
import Form from './Form'
import { getDataForVocs } from '../AdditionalSemiType/AdditionalSemiType'
import { SyncDepsContext, UserDataContext } from '../../../contexts'
import { commonCheckIsBlocked } from '../../../utils/helpers/for-block-unblock'
import { axios } from '../../../utils'
export const vocUrl = '/voc/editable/room'

const Room = () => {
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, setState] = useState([])
	const [outdoorWhVoc, setOutdoorWhVoc] = useState([])

	useEffect(async () => {
		const {
			data: { result: outdoorWhVoc },
		} = await axios('/voc/editable/outdoor-warehouse')
		setOutdoorWhVoc(outdoorWhVoc)
		let {
			data: { result, syncDeps },
		} = await axios(vocUrl)

		result = result.map((e) => {
			e.outdoorWh = outdoorWhVoc.find((wh) => wh.id === e.idVocOutdoorWh)?.label
			return e
		})
		setState(
			result.map((r) => {
				return {
					...r,
					storagesForFilter: r.storage.split(','),
				}
			})
		)
		syncDepsCtx.setDepsInfo({ deps: syncDeps })
	}, [syncDepsCtx.state.reloadUuids['office-ms']])

	const {
		state: { isDevAdmin },
	} = useContext(UserDataContext)

	const checkIsBlocked = async (id) => {
		const result = await commonCheckIsBlocked([{ entity: 'vocEditableRoom', id }])
		return result[0]?.isBlocked
	}

	return (
		<Wrapper
			breadcrumbsTitle="Помещения"
			model="voc_editable_room"
			entity="vocEditableRoom"
			checkIsBlocked={checkIsBlocked}
			canCreate
			canUpdate
			canDelete
			state={state}
			setState={setState}
			getDataForVocs={getDataForVocs}
			apiUrl={vocUrl}
			vocUrl={vocUrl}
			columns={getColumns(state, isDevAdmin)}
			getForm={(data, recordId, form, action, options) => (
				<Form
					data={data}
					recordId={recordId}
					form={form}
					action={action}
					options={options}
					outdoorWhVoc={outdoorWhVoc}
					isDevAdmin={isDevAdmin}
				/>
			)}
			rights={{
				Справочники: {
					Помещения: {
						edit: true,
					},
				},
			}}
		/>
	)
}

export default Room
