import { Col, Row } from 'antd'
import React, { useContext } from 'react'
import { StockWasteListMainContext } from './provider/main'
import { CustomForm } from '../../../../components'
import { getFormItems } from './modals-for-list'
import { getProdCatKindRawMatLabel } from '@berry/common-functions/cross-project-functions'
const ModalsForList = () => {
	const mainCtx = useContext(StockWasteListMainContext)
	let key = null
	if (mainCtx.utilizationModal.__isOpen) {
		key = 'utilizationModal'
	}
	if (mainCtx.relocationModal.__isOpen) {
		key = 'relocationModal'
	}

	return (
		<Row>
			<Col span="24">
				<CustomForm
					onFinish={mainCtx.modalsServerEdit}
					name="sale"
					fields={[
						{ name: 'refund', value: mainCtx.utilizationModal?.refund },
						{ name: 'sale', value: mainCtx.utilizationModal?.sale },
						{ name: 'prodCat', value: mainCtx.utilizationModal?.prodCat?.label },
						{ name: 'prodCat', value: mainCtx[key]?.storage.prodCat },
						{ name: 'partyNum', value: mainCtx[key]?.storage.partyNum },
						{ name: 'articul', value: mainCtx[key]?.storage.articul },
						{
							name: 'prodCatKindRawMat',
							value: getProdCatKindRawMatLabel(mainCtx.utilizationModal?.prodCatKindRawMat),
						},
						{
							name: 'weight',
							value: mainCtx.utilizationModal?.weight || mainCtx.relocationModal?.weight,
						},
						{ name: 'roomNums', value: mainCtx.relocationModal?.roomNum?.displayCode },
					]}
					columns={[{ size: { xs: 24, lg: 24 }, num: 0 }]}
					items={getFormItems(mainCtx)}
				/>
			</Col>
		</Row>
	)
}

export default ModalsForList
