import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Card, Col, Row, Form } from 'antd'
import {
	ModContext,
	ModProvider,
	FileProvider,
	AuthContext,
	UserDataContext,
	AxiosContext,
} from '../../../contexts'
import { ImplementationItemMainContext, Provider } from './provider/main'
import { Main } from '../../../layouts'
import {
	ActionButtons,
	CustomForm,
	HeaderMenu,
	LoadingButton,
	showConfirmModal,
	TabList,
} from '../../../components'
import { getBreadcrumbsText } from '../../../utils/helpers/generators'
import { actBtnOnClose, actBtnOnDelete, actBtnOnSaveV2, modes, openNewTab } from '../../../utils'
import { calcTotalWeight, getFormItems } from './implementation-item'
import ProductionTab from './ProductionTab/ProductionTab'

import { checkRole } from '@berry/common-functions/role'
import EventHistoryTab from './EventHistoryTab'

const pageUrl = '/stock-operations/implementations'
const rights = {
	'АРМ офиса': {
		Реализации: {
			edit: true,
		},
	},
}

const ImplementationItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<FileProvider>
				<Provider params={{ id: props.match.params.id }}>
					<Main title="Реализации">
						<InfoComponent />
					</Main>
				</Provider>
			</FileProvider>
		</ModProvider>
	)
}
const InfoComponent = () => {
	const mainCtx = useContext(ImplementationItemMainContext)
	const modCtx = useContext(ModContext)
	const authCtx = useContext(AuthContext)
	const userDataCtx = useContext(UserDataContext)
	const history = useHistory()
	const {
		state: { loading },
	} = useContext(AxiosContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const [form] = Form.useForm()
	if (mainCtx.state.__notFound) {
		history.replace(pageUrl)
		return null
	}
	const totalWeight = calcTotalWeight(mainCtx)
	const bread = getBreadcrumbsText(pageUrl, mainCtx.state)
	return (
		<>
			<HeaderMenu
				breadcrumbsTitle={bread.head}
				recordLabel={mainCtx.state.id ? bread.label : 'Новая'}
			>
				<Row>
					<ActionButtons
						isPendingReq={mainCtx.isPendingReq}
						formName="impl"
						loading={loading}
						rights={rights}
						onClose={() => {
							actBtnOnClose({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							mainCtx.setDeletedProds()
							await actBtnOnSaveV2({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								pageUrl: pageUrl,
								history: history,
								isShowErrorModal: true,
								showConfirmModal,
								form,
							})
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						canDelete={modCtx.mod !== modes.new}
						canEdit={['Новая', 'Отклонено 1С', 'Ошибка отправки в 1С'].includes(
							mainCtx.state.status
						)}
						onDelete={async () => {
							await actBtnOnDelete({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal: showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								getDeps: mainCtx.getDepsOn,
								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
								currentRights,
							})
						}}
						onCancel={() => {
							mainCtx.setDeletedProds()
							const allFields = form.getFieldsError()
							allFields.forEach((f) => {
								f.errors = []
							})
							form.setFields(allFields)
							mainCtx.reset()
						}}
						additionalButtonsPre={
							modCtx.mod === modes.view &&
							['Новая', 'Отклонено 1С', 'Ошибка отправки в 1С'].includes(mainCtx.state.status) &&
							checkRole(rights, currentRights)
								? [
										<LoadingButton
											type="primary"
											key="send-to-1c"
											onClick={mainCtx.serverSendTo1c}
											loading={loading}
										>
											Отправить в 1С
										</LoadingButton>,
								  ]
								: []
						}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							form={form}
							name="impl"
							fields={[
								{ name: 'displayCode', value: mainCtx.state.displayCode },
								{ name: 'customer', value: mainCtx.state.customer },
								{ name: 'totalWeight', value: totalWeight },
								{ name: 'status', value: mainCtx.state.status },
								{ name: 'isTolling', value: true },
								{ name: 'outdoorWh', value: mainCtx.state.outdoorWh?.label },
							]}
							columns={[
								{ size: { xs: 24, md: 12, xl: 8 }, num: 0 },
								{ size: { xs: 24, md: 12, xl: 9 }, num: 1 },
								{ size: { xs: 24, md: 12, xl: 7 }, num: 2 },
							]}
							items={getFormItems(mainCtx, modCtx, userDataCtx)}
						/>
					</Col>
				</Row>
			</Card>
			<TabList
				tabs={[
					{ key: 0, title: 'Продукция', component: <ProductionTab /> },
					{ key: 2, title: 'История событий', component: <EventHistoryTab /> },
				]}
			/>
		</>
	)
}

export default ImplementationItem
